import {isStorageAvailable} from "../utils/windowStorage";
import moment from "moment";
import store from "../redux/store";

const storageKey = "lenco-card-reminder";

function getCardReminderData(): {[key: string]: string} {
	if (!isStorageAvailable) {
		return {};
	}
	const dataString = window.localStorage.getItem(storageKey);
	try {
		return dataString === null ? {} : (JSON.parse(dataString) as {[key: string]: string});
	} catch (err) {
		return {};
	}
}

export function saveBannerSkippedDate(): void {
	if (isStorageAvailable) {
		const prev = getCardReminderData();
		const currentDate = moment().format("YYYY-MM-DD");
		const data = {isBannerSkipped: currentDate, ...prev};
		window.localStorage.setItem(storageKey, JSON.stringify(data));
	}
}

export function saveCardModalSkipped(): void {
	if (isStorageAvailable) {
		const prev = getCardReminderData();
		const data = {isModalSkipped: true, ...prev};
		window.localStorage.setItem(storageKey, JSON.stringify(data));
	}
}

export function canShowCardBanner(): boolean {
	const skippedData = getCardReminderData();
	const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
	const hasMadeRequest = store.getState().init.main?.meta.card.hasMadeRequest;

	if (hasMadeRequest || !isNigeria) {
		return false;
	}
	if (Object.keys(skippedData).length > 0) {
		const isBannerSkipped = skippedData.isBannerSkipped;
		if (isBannerSkipped) {
			const currentDate = moment().format("YYYY-MM-DD");
			const formattedStartDate = moment(isBannerSkipped, "YYYY-MM-DD");
			const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");
			const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asDays();
			return !!(daysBetweenStart !== 0 && daysBetweenStart % 7 === 0);
		}
		return true;
	} else {
		return true;
	}
}

export function canShowCardModal(): boolean {
	const skippedData = getCardReminderData();
	const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
	const hasMadeRequest = store.getState().init.main?.meta.card.hasMadeRequest;
	if (hasMadeRequest || !isNigeria) {
		return false;
	}
	if (Object.keys(skippedData).length > 0) {
		const isModalSkipped = skippedData.isModalSkipped;
		return !isModalSkipped;
	} else {
		return true;
	}
}

export function canShowNewCard(): boolean {
	const hasMadeRequest = store.getState().init.main?.meta.card.hasMadeRequest;
	const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;

	if (hasMadeRequest || !isNigeria) {
		return false;
	}
	const currentDate = moment().format("YYYY-MM-DD");
	const formattedStartDate = moment("2023-11-13", "YYYY-MM-DD");
	const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");

	const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asMonths();
	return !!(daysBetweenStart < 3);
}

export function canShowNewTerminals(): boolean {
	const isNigeria = store.getState().init.main?.companyDetails?.company.isNigeria;
	if (!isNigeria) {
		return false;
	}
	const currentDate = moment().format("YYYY-MM-DD");
	const formattedStartDate = moment("2023-12-15", "YYYY-MM-DD");
	const formattedCurrentDate = moment(currentDate, "YYYY-MM-DD");

	const daysBetweenStart = moment.duration(formattedCurrentDate.diff(formattedStartDate)).asMonths();
	return !!(daysBetweenStart < 1);
}
