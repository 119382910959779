import {AddMoneyFundingLink, AddMoneyFundingType, AddMoneyFundingTypes} from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import React, {useEffect, useState} from "react";
import {setAddMoneyFundingType, setAddMoneySelectedId} from "../../../../../../redux/payments/addMoney/slice/addMoneySlice";
import {useDispatch, useSelector} from "react-redux";

import AddMoneyHeader from "../../Layouts/AddMoney/Components/AddMoneyHeader";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CustomSelect from "../../../../../../components/General/Dropdown/CustomSelect";
import {IRootState} from "../../../../../../redux/rootReducer";
import UserAccountDropdown from "../../DropDowns/UserAccountDropdown";
import {useHistory} from "react-router";

interface Props {
	fundingType: AddMoneyFundingType | null;
	selectedAccountId: string;
}

function TypeOfAddMoney(props: Props): JSX.Element {
	const history = useHistory();
	const dispatch = useDispatch();
	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
	const [accountId, setAccountId] = useState(props.selectedAccountId || "");
	const [fundingType, setFundingType] = useState<AddMoneyFundingType | null>(props.fundingType || null);

	useEffect(() => {
		setAccountId(props.selectedAccountId);
	}, [props.selectedAccountId]);

	useEffect(() => {
		setFundingType(props.fundingType);
	}, [props.fundingType]);

	return (
		<>
			<div className="max-w-sm w-full flex flex-col">
				<AddMoneyHeader title="Add Money" subtitle="Select an account to fund and payment method" />

				<div className="w-full flex flex-col items-center justify-start space-y-16">
					<div className="flex w-full flex-col space-y-4">
						<UserAccountDropdown
							placeholder="Account to Fund"
							value={accountId || ""}
							options={accounts || null}
							onSelect={(_value) => setAccountId(_value)}
							isDisabled={!!(accounts && accounts.length < 2)}
							// showBalance
							initiatorCanSelect
						/>
						<CustomSelect
							placeholder="Payment Method"
							value={fundingType}
							options={AddMoneyFundingTypes.map((item) => ({
								text: item.name,
								value: item.id,
								icon: item.icon,
							}))}
							onSelect={(_value) => _value && setFundingType(_value)}
							big
						/>
					</div>
					<div className="flex justify-start items-center flex-col space-y-8">
						<ButtonComp
							type="button"
							color="blue"
							ripple="light"
							buttonType="primary"
							disable={!(accountId && fundingType)}
							func={() => {
								if (fundingType && accountId) {
									dispatch(setAddMoneySelectedId(accountId));
									dispatch(setAddMoneyFundingType(fundingType));
									history.push(AddMoneyFundingLink[fundingType].link);
								}
							}}
						>
							<span>Next</span>
						</ButtonComp>
						<ButtonComp
							type="button"
							color="blue"
							ripple="light"
							buttonType="tertiary"
							size="sm"
							func={() => history.push("/payments/add-money/history")}
						>
							<span>Add Money History</span>
						</ButtonComp>
					</div>
				</div>
			</div>
		</>
	);
}

export default TypeOfAddMoney;
