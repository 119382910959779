import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import Fee from "../../../../../../components/General/Fee";
import {IRootState} from "../../../../../../redux/rootReducer";
import {ReactComponent as Lock} from "../../../../../../assets/images/yellowlock.svg";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import formatNumber from "../../../../../../utils/formatNumber";
import getBankName from "../../../../../../helpers/getBankName";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import titleCase from "../../../../../../hooks/titleCase";
import {useMoneyToNumber} from "../../../../../../hooks/useMoneyToNumber";
import useSingleTransfer from "../../../Hooks/State/SendMoney/SingleTransfer/useSingleTransfer";

function ReviewPayment(): JSX.Element {
	const dispatch = useDispatch();

	const {isSinglePaymentLoading, singlePaymentResponse, handleSingleTransfer} = useSingleTransfer();

	const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
	const selectedUserAccount = useSelector((state: IRootState) => state.singleTransfer.selectedUserAccount);
	const singleTransferDetails = useSelector((state: IRootState) => state.singleTransfer.singleTransferDetails);
	const recipientBankAccountName = useSelector((state: IRootState) => state.singleTransfer.recipientBankAccountName);

	const [canApprove, setCanApprove] = useState(false);

	useEffect(() => {
		if (!userAccountsMeta || !selectedUserAccount) return;
		const doesAccountExist = userAccountsMeta.some((userAccountMeta) => userAccountMeta.userAccountId === selectedUserAccount.id);
		if (!isNullOrUndefined(doesAccountExist)) {
			const userAccountMeta = userAccountsMeta.find((_el) => _el.userAccountId === selectedUserAccount.id) as UserAccountMeta;
			if (userAccountMeta.isApprover) {
				if (userAccountMeta.maxApprovalAmount) {
					if (useMoneyToNumber(singleTransferDetails.amount) <= userAccountMeta.maxApprovalAmount) {
						setCanApprove(true);
						return;
					} else {
						setCanApprove(false);
						return;
					}
				} else {
					setCanApprove(true);
					return;
				}
			} else {
				setCanApprove(false);
			}
		}
	}, [userAccountsMeta, selectedUserAccount, singleTransferDetails.amount]);

	useEffect(() => {
		if (canApprove || !singlePaymentResponse) return;
		dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
	}, [canApprove, singlePaymentResponse]);

	return (
		<>
			<div className="flex h-full w-full flex-col pb-4 ">
				<div className="flex w-full flex-row">
					<div className="mx-auto flex w-full max-w-md flex-col lg:mx-0">
						<div>
							<SinglePaymentHeaders />
							<div className="flex w-full flex-col space-y-8">
								<div className="w-full ">
									<div className="flex flex-col items-center justify-center ">
										<span className="text-sm font-normal text-black-tertiary"> You’re sending </span>
										<div className="flex flex-row">
											<div className="text-4xl font-medium text-black">
												{""} <CurrencyCode />
												{formatNumber(singleTransferDetails && singleTransferDetails.amount)}
											</div>
										</div>
										{selectedUserAccount?.bankCode !== singleTransferDetails.recipient?.bankCode && (
											<span className="pt-2 text-sm font-normal text-black-tertiary">
												Fee: <span> {<Fee value={singleTransferDetails?.amount || ""} />}</span>
											</span>
										)}
									</div>
								</div>
								<div className="">
									<div className="box-border flex-col space-y-7 rounded-lg bg-blue-backdrop p-5">
										<div className="flex flex-row justify-between">
											<span className="text-sm font-normal text-black-tertiary"> From</span>
											<div className="flex flex-col">
												<span className="text-sm">
													{" "}
													{titleCase((selectedUserAccount && selectedUserAccount?.bankAccount?.accountName) || "")}
												</span>
												<span className="text-right text-sm font-normal">
													{" "}
													{selectedUserAccount && selectedUserAccount?.bankAccount?.accountNumber}
												</span>
											</div>
										</div>
										<div className="flex flex-row justify-between">
											<span className="text-sm font-normal text-black-tertiary">To</span>
											<div className="flex flex-col">
												<span className="text-right text-sm">{titleCase(recipientBankAccountName || "")}</span>
												<span className="text-right text-sm font-normal">
													{singleTransferDetails.recipient.accountNumber}
												</span>
												<span className="text-right text-sm font-normal">
													{titleCase(getBankName(singleTransferDetails.recipient.bankCode))}
												</span>
											</div>
										</div>
										<div className="flex flex-row justify-between">
											<span className=" text-sm font-normal text-black-tertiary ">Purpose of payment</span>
											<span className=" text-right text-sm font-normal ">
												{singleTransferDetails && singleTransferDetails.purpose}
											</span>
										</div>
									</div>
								</div>
								<div className="min-w-min pt-4">
									<div className=" box-border flex w-full flex-row  items-center justify-center space-x-2  rounded-lg bg-warning-backdrop px-4">
										<div>
											<Lock />
										</div>
										<p className="py-4   text-left  text-sm font-normal  text-warning ">
											{" "}
											Ensure you verify that the recipient is genuine as payments can not be reversed after approval.
										</p>
									</div>
								</div>

								<div className="flex w-full flex-row items-center justify-center space-x-4 pb-10 pt-4">
									<ButtonComp
										type="button"
										ripple="light"
										buttonType="secondary"
										color="grey"
										func={() => dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS))}
									>
										<span>Back</span>
									</ButtonComp>
									<ButtonComp
										type="submit"
										color="blue"
										ripple="light"
										buttonType="primary"
										func={() => {
											if (canApprove) {
												dispatch(setPaymentStage(PaymentStageType.MAKE_PAYMENT));
											} else {
												void handleSingleTransfer(singleTransferDetails);
											}
										}}
										isLoading={isSinglePaymentLoading}
									>
										{canApprove && "Make Payment"}
										{!canApprove && "Initiate Payment"}
									</ButtonComp>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ReviewPayment;
