import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";

import {ReactComponent as ApplicationIcon} from "../../../assets/svg/DashboardLayout/Sections/applicationIcon.svg";
import ButtonComp from "../../General/Buttons/ButtonComp";
import {Routes} from "../../../routes/routes.constants";

interface ApplicationSectionProps {
	onClick?: (e: React.MouseEvent) => void;
}

function ApplicationSection({onClick}: ApplicationSectionProps): JSX.Element {
	const [active, setActive] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setActive(location.pathname.includes(Routes.ACCOUNT_OPENING.STATUS));
	}, [location.pathname]);

	return (
		<Link to={Routes.ACCOUNT_OPENING.STATUS} onClick={(e) => (onClick ? onClick(e) : null)} data-type="section">
			<div className={`${!active ? "hover:bg-white" : ""}`} data-type="section" tabIndex={-1}>
				<ButtonComp
					ripple="dark"
					color="transparent"
					type="button"
					buttonType="primary"
					data-type="section"
					className="pl-6"
					noTabIndex
					fullWidth
				>
					<div
						className={`relative flex h-9 w-full items-center justify-start ` + `${active ? "font-normal text-blue antialiased" : ""}`}
						data-type="section"
						tabIndex={-1}
					>
						<ApplicationIcon className="stroke-current" data-type="section" tabIndex={-1} />
						<div className="ml-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm" data-type="section" tabIndex={-1}>
							Application
						</div>
					</div>
				</ButtonComp>
			</div>
		</Link>
	);
}

export default ApplicationSection;
