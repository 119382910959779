import {DropdownItem} from "../../../../../../helpers/types";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";

export enum PaymentsType {
	SINGLE_TRANSFER = "single",
	TRANSFER_BETWEEN_ACCOUNT = "accounts",
	BULK_TRANSFER = "bulk",
	RECIPIENT = "recipient",
	TYPE_OF_TRANSFERS = "typeoftransfer",
	FUND_CARD = "cards",
}
export enum StageType {
	INITIAL = 1,
	PAYMENT_DETAILS = 2,
	REVIEW_PAYMENT = 3,
	MAKE_PAYMENT = 4,
	PROCESS_PAYMENT = 5,
}

export const SingleTransferSections: {
	[value in PaymentStageType]: DropdownItem<PaymentStageType>;
} = {
	[PaymentStageType.INITIAL]: {
		value: PaymentStageType.INITIAL,
		text: "",
		subtext: "",
	},
	[PaymentStageType.PAYMENT_DETAILS]: {
		value: PaymentStageType.PAYMENT_DETAILS,
		text: "Make a single transfer",
		subtext: "Send money to recipient",
	},
	[PaymentStageType.REVIEW_PAYMENT]: {
		value: PaymentStageType.REVIEW_PAYMENT,
		text: "Review your payment",
		subtext: "Confirm the transaction details",
	},
	[PaymentStageType.MAKE_PAYMENT]: {
		value: PaymentStageType.MAKE_PAYMENT,
		text: "Authorize payment",
		subtext: "Give authorization for payment",
	},
	[PaymentStageType.PROCESS_PAYMENT]: {
		value: PaymentStageType.PROCESS_PAYMENT,
		text: "",
		subtext: "",
	},
};

export const TransferBetweenAccountSections: {
	[value in StageType]: DropdownItem<StageType>;
} = {
	[StageType.INITIAL]: {
		value: StageType.INITIAL,
		text: "",
		subtext: "",
	},
	[StageType.PAYMENT_DETAILS]: {
		value: StageType.PAYMENT_DETAILS,
		text: "Make a transfer between accounts",
		subtext: "Send money to only a sub-account",
	},
	[StageType.REVIEW_PAYMENT]: {
		value: StageType.REVIEW_PAYMENT,
		text: "Review your payment",
		subtext: "Confirm the transaction details",
	},
	[StageType.MAKE_PAYMENT]: {
		value: StageType.MAKE_PAYMENT,
		text: "Authorize payment",
		subtext: "Give approval to this transaction",
	},
	[StageType.PROCESS_PAYMENT]: {
		value: StageType.PROCESS_PAYMENT,
		text: "",
		subtext: "",
	},
};

export const FundCardSections: {
	[value in StageType]: DropdownItem<StageType>;
} = {
	[StageType.INITIAL]: {
		value: StageType.INITIAL,
		text: "",
		subtext: "",
	},
	[StageType.PAYMENT_DETAILS]: {
		value: StageType.PAYMENT_DETAILS,
		text: "Fund Card",
		subtext: "Fund your Prepaid Card",
	},
	[StageType.REVIEW_PAYMENT]: {
		value: StageType.REVIEW_PAYMENT,
		text: "Review your card funding",
		subtext: "Confirm the card funding details",
	},
	[StageType.MAKE_PAYMENT]: {
		value: StageType.MAKE_PAYMENT,
		text: "Authorize card funding",
		subtext: "Give approval to fund this card",
	},
	[StageType.PROCESS_PAYMENT]: {
		value: StageType.PROCESS_PAYMENT,
		text: "",
		subtext: "",
	},
};

export const BulkTransferSections: {
	[value in PaymentStageType]: DropdownItem<PaymentStageType>;
} = {
	[PaymentStageType.INITIAL]: {
		value: PaymentStageType.INITIAL,
		text: "",
		subtext: "",
	},
	[PaymentStageType.PAYMENT_DETAILS]: {
		value: PaymentStageType.PAYMENT_DETAILS,
		text: "Make a bulk transfer",
		subtext: "Send money to multiple recipients or sub-accounts all at once",
	},
	[PaymentStageType.REVIEW_PAYMENT]: {
		value: PaymentStageType.REVIEW_PAYMENT,
		text: "Review your payments",
		subtext: "Confirm the transaction details",
	},
	[PaymentStageType.MAKE_PAYMENT]: {
		value: PaymentStageType.MAKE_PAYMENT,
		text: "Authorize payments",
		subtext: "Give approval to these transactions",
	},
	[PaymentStageType.PROCESS_PAYMENT]: {
		value: PaymentStageType.PROCESS_PAYMENT,
		text: "",
		subtext: "",
	},
};

// export const PaymentsSections: {
// [value in PaymentsType]: DropdownItem<PaymentsType>;
// } = {
// [PaymentsType.SINGLE_TRANSFER]: {
// value: PaymentsType.SINGLE_TRANSFER,
// text: "Make a single transfer",
// subtext: "Send money to recipient or a sub-account",
// },
// [PaymentsType.TRANSFER_BETWEEN_ACCOUNT]: {
// value: PaymentsType.TRANSFER_BETWEEN_ACCOUNT,
// text: "Make a transfer between accounts",
// subtext: "Send money to only a sub-account",
// },
// [PaymentsType.BULK_TRANSFER]: {
// value: PaymentsType.BULK_TRANSFER,
// text: "Make a bulk transfer",
// subtext: "Send money to multiple recipients or sub-accounts at once",
// },
// };
