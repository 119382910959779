import {Link, useLocation} from "react-router-dom";

import ButtonComp from "../../General/Buttons/ButtonComp";
import React from "react";
import {canShowPreReleaseFeatures} from "../../../utils/preReleaseConfig";

interface Props {
	onClick(e: React.MouseEvent): void;
	path: string;
	text: string;
	optionalPath?: string;
	isPreRelease?: boolean;
	isParentOpen: boolean;
}

function MenuItemChild({onClick, path, text, isPreRelease = false, optionalPath, isParentOpen}: Props): JSX.Element {
	if (isPreRelease && !canShowPreReleaseFeatures) {
		return <></>;
	}

	const location = useLocation();

	const active = location.pathname.includes(optionalPath || path) || location.pathname.includes(path);

	return (
		<Link
			to={{
				pathname: path,
				state: {
					pageFrom: location.pathname,
				},
			}}
			onClick={onClick}
			tabIndex={isParentOpen ? 0 : -1}
		>
			<div className={`${active ? "" : "hover:bg-white"}`} tabIndex={-1}>
				<ButtonComp ripple="dark" color="transparent" type="button" buttonType="primary" data-type="section" className="pl-6" noTabIndex>
					<div className="relative flex h-9 w-full items-center justify-start" tabIndex={-1}>
						<div
							className={
								`ml-6 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm  tracking-normal ` +
								`${active ? "font-medium text-blue  antialiased" : ""}`
							}
							tabIndex={-1}
						>
							{text}
						</div>
					</div>
				</ButtonComp>
			</div>
		</Link>
	);
}

export default MenuItemChild;
