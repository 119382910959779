import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {SinglePaymentInternalStage, SinglePaymentResponse, SingleTransferState} from "./singleTransferSlice.types";

import BankAccount from "../../../../models/bankAccount";
import UserAccount from "../../../../models/userAccount";

const initialState: SingleTransferState = {
	singleTransferDetails: {
		originatingAccountId: "",
		recipient: {
			bankAccountId: "",
			customerAccountId: "",
			accountNumber: "",
			bankCode: "",
		},
		amount: "",
		purpose: "",
		otp: "",
	},
	verifiedAccount: null,
	selectedUserAccount: null,
	canVerifyRecipientAccountDetails: true,
	recipientBankAccountName: "",
	singleTransferResponse: null,
	singlePaymentInternalStage: SinglePaymentInternalStage.PAYMENT_DETAILS_STAGE,
};

export const singleTransferSlice = createSlice({
	name: "singleTransfer",
	initialState,
	reducers: {
		setSingleTransferOriginatingAccount: (state: SingleTransferState, action: PayloadAction<string>) => {
			state.singleTransferDetails.originatingAccountId = action.payload;
		},
		setSingleTransferAmount: (state: SingleTransferState, action: PayloadAction<string>) => {
			state.singleTransferDetails.amount = action.payload;
		},
		setSingleTransferPurpose: (state: SingleTransferState, action: PayloadAction<string>) => {
			state.singleTransferDetails.purpose = action.payload;
		},
		setSingleTransferOtp: (state: SingleTransferState, action: PayloadAction<string>) => {
			state.singleTransferDetails.otp = action.payload;
		},
		setSingleTransferRecipient: (state: SingleTransferState, action: PayloadAction<BankAccount>) => {
			state.singleTransferDetails.recipient.accountNumber = action.payload.accountNumber;
			state.singleTransferDetails.recipient.bankAccountId = action.payload.id;
			state.singleTransferDetails.recipient.bankCode = action.payload.bankCode;
			state.singleTransferDetails.recipient.customerAccountId = "";
			state.recipientBankAccountName = action.payload.accountName;
		},
		setSingleTransferRecipientAccountNumber: (state: SingleTransferState, action: PayloadAction<string>) => {
			state.singleTransferDetails.recipient.accountNumber = action.payload;
		},
		setSingleTransferRecipientBankCode: (state: SingleTransferState, action: PayloadAction<string>) => {
			state.singleTransferDetails.recipient.bankCode = action.payload;
		},
		setSelectedUserAccount: (state: SingleTransferState, action: PayloadAction<UserAccount>) => {
			state.selectedUserAccount = action.payload;
		},
		setCanVerifyRecipientAccountDetails: (state: SingleTransferState, action: PayloadAction<boolean>) => {
			state.canVerifyRecipientAccountDetails = action.payload;
		},
		setVerifiedAccount: (state: SingleTransferState, action: PayloadAction<BankAccount>) => {
			state.verifiedAccount = action.payload;
		},

		setSingleTransferResponse: (state: SingleTransferState, action: PayloadAction<SinglePaymentResponse>) => {
			state.singleTransferResponse = action.payload;
		},
		setSinglePaymentInternalStage: (state: SingleTransferState, action: PayloadAction<SinglePaymentInternalStage>) => {
			state.singlePaymentInternalStage = action.payload;
		},
		resetVerifiedAccount: (state: SingleTransferState) => {
			state.verifiedAccount = null;
		},
		resetSingleTransferRecipient: (state: SingleTransferState) => {
			state.verifiedAccount = null;
			state.singleTransferDetails.recipient.accountNumber = "";
			state.singleTransferDetails.recipient.bankAccountId = "";
			state.singleTransferDetails.recipient.bankCode = "";
			state.singleTransferDetails.recipient.customerAccountId = "";
		},
		resetAllSingleTransferData: (state: SingleTransferState) => {
			state.verifiedAccount = null;
			state.selectedUserAccount = null;
			state.canVerifyRecipientAccountDetails = true;
			state.recipientBankAccountName = "";
			state.singleTransferResponse = null;
			state.singlePaymentInternalStage = SinglePaymentInternalStage.PAYMENT_DETAILS_STAGE;
			state.singleTransferDetails = {
				originatingAccountId: "",
				recipient: {
					bankAccountId: "",
					customerAccountId: "",
					accountNumber: "",
					bankCode: "",
				},
				amount: "",
				purpose: "",
				otp: "",
			};
		},
	},
});

export const {
	setVerifiedAccount,
	setSingleTransferOriginatingAccount,
	setSingleTransferAmount,
	setSingleTransferPurpose,
	setSingleTransferOtp,
	setSingleTransferRecipient,
	setSelectedUserAccount,
	setSingleTransferRecipientAccountNumber,
	setSingleTransferRecipientBankCode,
	setCanVerifyRecipientAccountDetails,
	setSingleTransferResponse,
	setSinglePaymentInternalStage,
	resetSingleTransferRecipient,
	resetVerifiedAccount,
	resetAllSingleTransferData,
} = singleTransferSlice.actions;

export default singleTransferSlice.reducer;
