import {BulkPaymentResponse, BulkTransferState, TransferDetail} from "./bulkTransferSlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import BankAccount from "../../../../models/bankAccount";
import {TransactionStatus} from "../../../../models/transaction.constants";
import UserAccount from "../../../../models/userAccount";
import produce from "immer";

const initialState: BulkTransferState = {
	transferDetailArray: [],
	transferDetail: {
		originatingAccountId: "",
		recipient: {
			externalAccount: {
				accountNumber: "",
				bankCode: "",
				bankAccountId: "",
				customerAccountId: "",
			},
			internalAccountId: "", // if recipient is an internal account (i.e. transfer between accounts)
			cardId: "", // if recipient is a card (i.e. card funding)
		},
		transactionId: "",
		status: TransactionStatus.PROCESSING,
		amount: "",
		purpose: "",
		canApprove: true,
		selectedUserAccount: null,
		recipientBankAccount: null,
		fee: 0,
		key: 0,
	},
	isUpdatingTransferDetail: false,
	canVerifyRecipientAccountDetails: true,
	bulkTransferResponse: null,
	bulkTransfersAmountTotal: 0,
};

export const bulkTransferSlice = createSlice({
	name: "bulkTransfer",
	initialState,
	reducers: {
		addMultipleTransferDetailToTransferList: (state: BulkTransferState, action: PayloadAction<TransferDetail[]>) => {
			const updatedArray = produce(state.transferDetailArray, (draft) => {
				action.payload.forEach((_t) => draft.push(_t));
			});
			state.transferDetailArray = updatedArray;
		},
		addTransferDetailToTransferList: (state: BulkTransferState, action: PayloadAction<TransferDetail>) => {
			const updatedArray = produce(state.transferDetailArray, (draft) => {
				draft.push(action.payload);
			});
			state.transferDetailArray = updatedArray;
		},
		updateTransferDetailToTransferList: (state: BulkTransferState, action: PayloadAction<TransferDetail>) => {
			const updatedArray = produce(state.transferDetailArray, (draft) => {
				const index = draft.findIndex((_el) => _el.key === action.payload.key);
				if (index !== -1) draft[index] = action.payload;
			});
			state.transferDetailArray = updatedArray;
		},
		updateTransferDetailStatus: (
			state: BulkTransferState,
			action: PayloadAction<{key: number; transactionId: string; status: TransactionStatus}>
		) => {
			const updatedArray = produce(state.transferDetailArray, (draft) => {
				const index = draft.findIndex((_el) => _el.key === action.payload.key);
				if (index !== -1) {
					draft[index].status = action.payload.status;
					draft[index].transactionId = action.payload.transactionId;
				}
			});
			state.transferDetailArray = updatedArray;
		},
		editTransferDetailToTransferList: (state: BulkTransferState, action: PayloadAction<TransferDetail>) => {
			const updatedArray = produce(state.transferDetailArray, (draft) => {
				const index = draft.findIndex((_el) => _el.key === action.payload.key);
				if (index !== -1) draft[index] = action.payload;
			});
			state.transferDetailArray = updatedArray;
		},
		removeTransferDetailToTransferList: (state: BulkTransferState, action: PayloadAction<number>) => {
			state.transferDetailArray = state.transferDetailArray.filter((_el) => _el.key !== action.payload);
		},
		setTransferDetail: (state: BulkTransferState, action: PayloadAction<TransferDetail>) => {
			state.transferDetail = action.payload;
		},
		setIsUpdatingTransferDetail: (state: BulkTransferState, action: PayloadAction<boolean>) => {
			state.isUpdatingTransferDetail = action.payload;
		},
		setTransferDetailOriginatingAccount: (state: BulkTransferState, action: PayloadAction<string>) => {
			state.transferDetail.originatingAccountId = action.payload;
		},
		setTransferDetailAmount: (state: BulkTransferState, action: PayloadAction<string>) => {
			state.transferDetail.amount = action.payload;
		},
		setTransferDetailFee: (state: BulkTransferState, action: PayloadAction<number>) => {
			state.transferDetail.fee = action.payload;
		},
		setTransferDetailPurpose: (state: BulkTransferState, action: PayloadAction<string>) => {
			state.transferDetail.purpose = action.payload;
		},
		// setTransferDetailRecipient: (state: BulkTransferState, action: PayloadAction<BankAccount>) => {
		// state.transferDetail.recipient.accountNumber = action.payload.accountNumber;
		// state.transferDetail.recipient.bankAccountId = action.payload.id;
		// state.transferDetail.recipient.bankCode = action.payload.bankCode;
		// state.transferDetail.recipient.customerAccountId = "";
		// state.transferDetail.recipientBankAccount = action.payload;
		// },

		setTransferDetailUserAccountRecipient: (state: BulkTransferState, action: PayloadAction<string>) => {
			state.transferDetail.recipient.externalAccount = {
				accountNumber: "",
				bankCode: "",
				bankAccountId: "",
				customerAccountId: "",
			};
			state.transferDetail.recipient.cardId = "";
			state.transferDetail.recipientBankAccount = null;
			state.transferDetail.recipient.internalAccountId = action.payload;
		},
		setTransferDetailCustomerAccountRecipient: (state: BulkTransferState, action: PayloadAction<BankAccount>) => {
			state.transferDetail.recipient.externalAccount = {
				accountNumber: action.payload.accountNumber,
				bankCode: action.payload.bankCode,
				bankAccountId: action.payload.id,
				customerAccountId: "",
			};
			state.transferDetail.recipient.cardId = "";
			state.transferDetail.recipientBankAccount = action.payload;
			state.transferDetail.recipient.internalAccountId = "";
		},
		setTransferDetailCardRecipient: (state: BulkTransferState, action: PayloadAction<string>) => {
			state.transferDetail.recipient.externalAccount = {
				accountNumber: "",
				bankCode: "",
				bankAccountId: "",
				customerAccountId: "",
			};
			state.transferDetail.recipientBankAccount = null;
			state.transferDetail.recipient.cardId = action.payload;
			state.transferDetail.recipient.internalAccountId = "";
		},

		setTransferDetailRecipientAccountNumber: (state: BulkTransferState, action: PayloadAction<string>) => {
			// state.transferDetail.recipient.accountNumber = action.payload;
			state.transferDetail.recipient.externalAccount = {
				accountNumber: action.payload,
				bankCode: state.transferDetail.recipient.externalAccount.bankCode || "",
				bankAccountId: state.transferDetail.recipient.externalAccount.bankAccountId || "",
				customerAccountId: state.transferDetail.recipient.externalAccount.customerAccountId || "",
			};
		},
		setTransferDetailRecipientBankCode: (state: BulkTransferState, action: PayloadAction<string>) => {
			// state.transferDetail.recipient.bankCode = action.payload;
			state.transferDetail.recipient.externalAccount = {
				accountNumber: state.transferDetail.recipient.externalAccount.accountNumber || "",
				bankCode: action.payload,
				bankAccountId: state.transferDetail.recipient.externalAccount.bankAccountId || "",
				customerAccountId: state.transferDetail.recipient.externalAccount.customerAccountId || "",
			};
		},
		setSelectedUserAccount: (state: BulkTransferState, action: PayloadAction<UserAccount>) => {
			state.transferDetail.selectedUserAccount = action.payload;
		},
		setTransferDetailIndex: (state: BulkTransferState) => {
			const randomId = Math.floor(Math.random() * 100000000000000) + 1;
			const existingRandomId = state.transferDetailArray.find((_el) => _el.key === randomId)?.key;

			if (existingRandomId) {
				state.transferDetail.key = existingRandomId + 2;
			} else {
				state.transferDetail.key = randomId;
			}
		},
		setCanApprove: (state: BulkTransferState, action: PayloadAction<boolean>) => {
			if (action.payload) {
				state.transferDetail.canApprove = action.payload;
				state.transferDetail.status = TransactionStatus.PROCESSING;
			} else {
				state.transferDetail.canApprove = action.payload;
				state.transferDetail.status = TransactionStatus.PENDING_APPROVAL;
			}
		},
		setCanVerifyRecipientAccountDetails: (state: BulkTransferState, action: PayloadAction<boolean>) => {
			state.canVerifyRecipientAccountDetails = action.payload;
		},
		setBulkTransferResponse: (state: BulkTransferState, action: PayloadAction<BulkPaymentResponse>) => {
			state.bulkTransferResponse = action.payload;
		},
		setBulkTransfersAmountTotal: (state: BulkTransferState, action: PayloadAction<number>) => {
			state.bulkTransfersAmountTotal = action.payload;
		},
		resetTransferDetail: (state: BulkTransferState) => {
			state.transferDetail.originatingAccountId = "";
			state.transferDetail.recipient = {
				externalAccount: {
					accountNumber: "",
					bankCode: "",
					bankAccountId: "",
					customerAccountId: "",
				},
				internalAccountId: "",
				cardId: "",
			};
			state.transferDetail.amount = "";
			state.transferDetail.purpose = "";
			state.transferDetail.selectedUserAccount = null;
			state.transferDetail.recipientBankAccount = null;
			state.transferDetail.fee = 0;
			state.transferDetail.canApprove = true;
			state.transferDetail.key = 0;
			state.isUpdatingTransferDetail = false;
			state.canVerifyRecipientAccountDetails = true;
		},
		resetTransferDetailRecipient: (state: BulkTransferState) => {
			state.transferDetail.recipientBankAccount = null;
			state.transferDetail.recipient = {
				externalAccount: {
					accountNumber: "",
					bankCode: "",
					bankAccountId: "",
					customerAccountId: "",
				},
				internalAccountId: "",
				cardId: "",
			};
		},
		resetTransferDetailRecipientBankAccount: (state: BulkTransferState) => {
			state.transferDetail.recipientBankAccount = null;
		},
		resetAllBulkTransferData: (state: BulkTransferState) => {
			state.transferDetailArray = [];
			state.transferDetail.originatingAccountId = "";
			state.transferDetail.recipient = {
				externalAccount: {
					accountNumber: "",
					bankCode: "",
					bankAccountId: "",
					customerAccountId: "",
				},
				internalAccountId: "",
				cardId: "",
			};
			state.transferDetail.amount = "";
			state.transferDetail.purpose = "";
			state.transferDetail.selectedUserAccount = null;
			state.transferDetail.recipientBankAccount = null;
			state.transferDetail.fee = 0;
			state.transferDetail.canApprove = true;
			state.transferDetail.key = 0;
			state.isUpdatingTransferDetail = false;
			state.canVerifyRecipientAccountDetails = true;
			state.bulkTransferResponse = null;
			state.bulkTransfersAmountTotal = 0;
		},
	},
});

export const {
	addMultipleTransferDetailToTransferList,
	addTransferDetailToTransferList,
	updateTransferDetailToTransferList,
	editTransferDetailToTransferList,
	removeTransferDetailToTransferList,
	setTransferDetail,
	setIsUpdatingTransferDetail,
	setTransferDetailOriginatingAccount,
	setTransferDetailAmount,
	setTransferDetailFee,
	setTransferDetailPurpose,
	// setTransferDetailRecipient,
	setTransferDetailRecipientAccountNumber,
	setTransferDetailRecipientBankCode,
	setSelectedUserAccount,
	setTransferDetailIndex,
	setCanApprove,
	setCanVerifyRecipientAccountDetails,
	setBulkTransferResponse,
	setBulkTransfersAmountTotal,
	updateTransferDetailStatus,

	setTransferDetailUserAccountRecipient,
	setTransferDetailCustomerAccountRecipient,
	setTransferDetailCardRecipient,

	resetTransferDetail,
	resetTransferDetailRecipient,
	resetTransferDetailRecipientBankAccount,
	resetAllBulkTransferData,
} = bulkTransferSlice.actions;

export default bulkTransferSlice.reducer;
