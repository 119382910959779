import {FilterDateType, FilterDateTypes} from "../../../../components/General/Filter/filter.constant";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {
	ZambiaCollectionPaymentChannel,
	ZambiaSettlementStatus,
	ZambiaSettlementType,
} from "../../../../models/zambia/zambiaCollectionRequest.constant";
import {ZambiaCollectionsSettlementsFilterTypes, ZambiaCollectionsSettlementsState} from "./zambiaCollectionsSettlementsSlice.ts.types";

import ZambiaSettlement from "../../../../models/zambia/zambiaSettlement";

const initialState: ZambiaCollectionsSettlementsState = {
	zambiaCollectionsSettlementsList: null,
	zambiaCollectionsSettlementsDetail: null,
	zambiaCachedCollectionsSettlementsList: [],

	isCustomModalOpen: false,
	isZambiaCollectionsSettlementsListEmpty: false,
	isExportZambiaCollectionsSettlementsLoading: false,
	isExportZambiaCollectionsSettlementsTrayOpen: false,
	isZambiaCollectionsSettlementsDetailCardOpen: false,
	isZambiaCollectionsSettlementsPaginationLoading: false,

	zambiaCollectionsSettlementsListTotal: 0,
	zambiaCollectionsSettlementsListGroupSize: 0,
	zambiaCollectionsSettlementsPaginationOffset: 0,

	zambiaCollectionsSettlementsSearchQuery: "",

	zambiaCollectionsSettlementsDate: FilterDateTypes.ALL_TIME,
	selectedZambiaCollectionsSettlementsDate: FilterDateTypes.ALL_TIME,

	zambiaCollectionsSettlementsFilterState: {
		query: "",
		offset: 0,
		status: [],
		channel: [],
		settlementType: [],
		date: {
			end: "",
			begin: "",
		},
	},
	selectedZambiaCollectionsSettlementsFilterState: {
		query: "",
		offset: 0,
		status: [],
		channel: [],
		settlementType: [],
		date: {
			end: "",
			begin: "",
		},
	},
};

export const zambiaCollectionsSettlementsSlice = createSlice({
	name: "zambiaCollectionsSettlements",
	initialState,
	reducers: {
		setSelectedZambiaCollectionsSettlementsDetails: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<string>) => {
			const collectionRequest = state.zambiaCollectionsSettlementsList?.find((_sub) => _sub.id === action.payload);
			if (collectionRequest) {
				state.zambiaCollectionsSettlementsDetail = collectionRequest;
			}
		},
		setIsZambiaCollectionsSettlementsDetailCardOpen: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<boolean>) => {
			state.isZambiaCollectionsSettlementsDetailCardOpen = action.payload;
		},
		setIsExportZambiaCollectionsSettlementsTrayOpen: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<boolean>) => {
			state.isExportZambiaCollectionsSettlementsTrayOpen = action.payload;
		},

		setIsAddMoneyDateModalOpen: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<boolean>) => {
			state.isCustomModalOpen = action.payload;
		},
		setIsExportZambiaCollectionsSettlementsLoading: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<boolean>) => {
			state.isExportZambiaCollectionsSettlementsLoading = action.payload;
		},

		setIsZambiaCollectionsSettlementsPaginationLoading: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<boolean>) => {
			state.isZambiaCollectionsSettlementsPaginationLoading = action.payload;
		},
		setIsZambiaCollectionsSettlementsListEmpty: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<boolean>) => {
			state.isZambiaCollectionsSettlementsListEmpty = action.payload;
		},
		setZambiaCollectionsSettlementsDate: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<FilterDateType>) => {
			if (action.payload.date.begin && action.payload.date.end) {
				state.zambiaCollectionsSettlementsFilterState = {
					...state.zambiaCollectionsSettlementsFilterState,
					date: {
						begin: action.payload.date.begin,
						end: action.payload.date.end,
					},
				};
				state.zambiaCollectionsSettlementsDate = action.payload.name;
			}
		},

		setSelectedZambiaCollectionsSettlementsFilterState: (state: ZambiaCollectionsSettlementsState) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState};
			state.selectedZambiaCollectionsSettlementsDate = state.zambiaCollectionsSettlementsDate;
		},
		// setSelectedTerminalTransactionFilterStateInstant: (
		// state: ZambiaCollectionsSettlementsState,
		// action: PayloadAction<ZambiaCollectionsSettlementsFilterTypes>
		// ) => {
		// state.selectedZambiaCollectionsSettlementsFilterState = {...state.selectedZambiaCollectionsSettlementsFilterState, ...action.payload};
		// state.zambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState, ...action.payload};
		// },

		setZambiaCollectionsSettlementsList: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<ZambiaSettlement[]>) => {
			state.zambiaCollectionsSettlementsList = action.payload;
			const uniqueList = new Map<string, ZambiaSettlement>();
			const updatedArray = [...state.zambiaCachedCollectionsSettlementsList, ...action.payload];
			updatedArray.forEach((item) => {
				uniqueList.set(item.id, item);
			});
			state.zambiaCachedCollectionsSettlementsList = Array.from(uniqueList.values());
		},
		setZambiaCollectionsSettlementsListTotal: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<number>) => {
			state.zambiaCollectionsSettlementsListTotal = action.payload;
		},
		setZambiaCollectionsSettlementsPaginationOffset: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<number>) => {
			state.zambiaCollectionsSettlementsPaginationOffset = action.payload;
		},
		setZambiaCollectionsSettlementsListGroupSize: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<number>) => {
			state.zambiaCollectionsSettlementsListGroupSize = action.payload;
		},
		setZambiaCollectionsSettlementsFilterState: (
			state: ZambiaCollectionsSettlementsState,
			action: PayloadAction<ZambiaCollectionsSettlementsFilterTypes>
		) => {
			state.zambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState, ...action.payload};
		},
		setZambiaCollectionsSettlementsDateFilter: (state: ZambiaCollectionsSettlementsState) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {
				...state.selectedZambiaCollectionsSettlementsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.zambiaCollectionsSettlementsFilterState = {
				...state.zambiaCollectionsSettlementsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedZambiaCollectionsSettlementsDate = FilterDateTypes.ALL_TIME;
			state.zambiaCollectionsSettlementsDate = FilterDateTypes.ALL_TIME;
		},

		setZambiaCollectionsSettlementsChannelFilter: (
			state: ZambiaCollectionsSettlementsState,
			action: PayloadAction<ZambiaCollectionPaymentChannel>
		) => {
			state.selectedZambiaCollectionsSettlementsFilterState.channel = state.selectedZambiaCollectionsSettlementsFilterState.channel.filter(
				(_types) => _types !== action.payload
			);
			state.zambiaCollectionsSettlementsFilterState.channel = state.zambiaCollectionsSettlementsFilterState.channel.filter(
				(_types) => _types !== action.payload
			);
		},
		setZambiaCollectionsSettlementsStatusFilter: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<ZambiaSettlementStatus>) => {
			state.selectedZambiaCollectionsSettlementsFilterState.status = state.selectedZambiaCollectionsSettlementsFilterState.status.filter(
				(_status) => _status !== action.payload
			);
			state.zambiaCollectionsSettlementsFilterState.status = state.zambiaCollectionsSettlementsFilterState.status.filter(
				(_status) => _status !== action.payload
			);
		},
		setZambiaCollectionsSettlementsTypeFilter: (state: ZambiaCollectionsSettlementsState, action: PayloadAction<ZambiaSettlementType>) => {
			state.selectedZambiaCollectionsSettlementsFilterState.settlementType =
				state.selectedZambiaCollectionsSettlementsFilterState.settlementType.filter((_settlementType) => _settlementType !== action.payload);
			state.zambiaCollectionsSettlementsFilterState.settlementType = state.zambiaCollectionsSettlementsFilterState.settlementType.filter(
				(_settlementType) => _settlementType !== action.payload
			);
		},
		setSelectedZambiaCollectionsSettlementsFilterStateInstant: (
			state: ZambiaCollectionsSettlementsState,
			action: PayloadAction<ZambiaCollectionsSettlementsFilterTypes>
		) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {...state.selectedZambiaCollectionsSettlementsFilterState, ...action.payload};
			state.zambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState, ...action.payload};
		},

		resetZambiaCollectionsSettlementsList: (state: ZambiaCollectionsSettlementsState) => {
			state.zambiaCollectionsSettlementsList = null;
		},
		resetZambiaCollectionsSettlementsDateFilterState: (state: ZambiaCollectionsSettlementsState) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {
				...state.selectedZambiaCollectionsSettlementsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.zambiaCollectionsSettlementsFilterState = {
				...state.zambiaCollectionsSettlementsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedZambiaCollectionsSettlementsDate = FilterDateTypes.ALL_TIME;
			state.zambiaCollectionsSettlementsDate = FilterDateTypes.ALL_TIME;
		},
		resetZambiaCollectionsSettlementsStatusFilterState: (state: ZambiaCollectionsSettlementsState) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {...state.selectedZambiaCollectionsSettlementsFilterState, status: []};
			state.zambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState, status: []};
		},
		resetZambiaCollectionsSettlementsTypeFilterState: (state: ZambiaCollectionsSettlementsState) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {...state.selectedZambiaCollectionsSettlementsFilterState, settlementType: []};
			state.zambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState, settlementType: []};
		},
		resetZambiaCollectionsSettlementsChannelFilterState: (state: ZambiaCollectionsSettlementsState) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {...state.selectedZambiaCollectionsSettlementsFilterState, channel: []};
			state.zambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState, channel: []};
		},
		resetZambiaCollectionsSettlementsQueryFilterState: (state: ZambiaCollectionsSettlementsState) => {
			state.selectedZambiaCollectionsSettlementsFilterState = {...state.selectedZambiaCollectionsSettlementsFilterState, query: ""};
			state.zambiaCollectionsSettlementsFilterState = {...state.zambiaCollectionsSettlementsFilterState, query: ""};
		},
		resetZambiaCollectionsSettlementsFilterState: (state: ZambiaCollectionsSettlementsState) => {
			state.zambiaCollectionsSettlementsFilterState = {
				query: "",
				offset: 0,
				date: {
					begin: "",
					end: "",
				},
				channel: [],
				settlementType: [],
				status: [],
			};
			state.selectedZambiaCollectionsSettlementsFilterState = {
				query: "",
				offset: 0,
				date: {
					begin: "",
					end: "",
				},
				channel: [],
				settlementType: [],
				status: [],
			};
		},
		resetAllZambiaCollectionsSettlementsData: (state: ZambiaCollectionsSettlementsState) => {
			state.zambiaCollectionsSettlementsDetail = null;
			state.isZambiaCollectionsSettlementsDetailCardOpen = false;
			state.isExportZambiaCollectionsSettlementsTrayOpen = false;
			state.zambiaCollectionsSettlementsList = null;
			state.zambiaCollectionsSettlementsListTotal = 0;
			state.zambiaCollectionsSettlementsPaginationOffset = 0;
			state.zambiaCollectionsSettlementsListGroupSize = 0;
			state.isExportZambiaCollectionsSettlementsLoading = false;
			state.isZambiaCollectionsSettlementsListEmpty = false;
			state.zambiaCollectionsSettlementsSearchQuery = "";
			state.isZambiaCollectionsSettlementsPaginationLoading = false;
			state.zambiaCollectionsSettlementsDate = FilterDateTypes.ALL_TIME;
			state.selectedZambiaCollectionsSettlementsDate = FilterDateTypes.ALL_TIME;
			state.isCustomModalOpen = false;
			state.zambiaCollectionsSettlementsFilterState = {
				query: "",
				status: [],
				offset: 0,
				settlementType: [],
				channel: [],
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedZambiaCollectionsSettlementsFilterState = {
				query: "",
				offset: 0,
				status: [],
				settlementType: [],
				channel: [],
				date: {
					begin: "",
					end: "",
				},
			};
		},
		resetZambiaCachedCollectionsSettlementsList: (state: ZambiaCollectionsSettlementsState) => {
			state.zambiaCachedCollectionsSettlementsList = [];
		},
	},
});

export const {
	setIsAddMoneyDateModalOpen,
	setZambiaCollectionsSettlementsDate,
	setZambiaCollectionsSettlementsList,
	setZambiaCollectionsSettlementsListTotal,
	setZambiaCollectionsSettlementsDateFilter,
	setZambiaCollectionsSettlementsChannelFilter,
	setZambiaCollectionsSettlementsFilterState,
	setIsZambiaCollectionsSettlementsListEmpty,
	setZambiaCollectionsSettlementsListGroupSize,
	setZambiaCollectionsSettlementsTypeFilter,
	setZambiaCollectionsSettlementsStatusFilter,
	setIsExportZambiaCollectionsSettlementsLoading,
	setSelectedZambiaCollectionsSettlementsDetails,
	setIsZambiaCollectionsSettlementsDetailCardOpen,
	setIsExportZambiaCollectionsSettlementsTrayOpen,
	setZambiaCollectionsSettlementsPaginationOffset,
	setSelectedZambiaCollectionsSettlementsFilterState,
	setIsZambiaCollectionsSettlementsPaginationLoading,
	setSelectedZambiaCollectionsSettlementsFilterStateInstant,

	resetZambiaCachedCollectionsSettlementsList,
	resetZambiaCollectionsSettlementsList,
	resetAllZambiaCollectionsSettlementsData,
	resetZambiaCollectionsSettlementsFilterState,
	resetZambiaCollectionsSettlementsDateFilterState,
	resetZambiaCollectionsSettlementsQueryFilterState,
	resetZambiaCollectionsSettlementsChannelFilterState,
	resetZambiaCollectionsSettlementsTypeFilterState,
	resetZambiaCollectionsSettlementsStatusFilterState,
} = zambiaCollectionsSettlementsSlice.actions;

export default zambiaCollectionsSettlementsSlice.reducer;
