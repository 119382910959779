import {useEffect, useState} from "react";

import Bank from "../models/bank";
import {BankType} from "../models/bank.constants";
import {IRootState} from "../redux/rootReducer";
import {useSelector} from "react-redux";

interface UseBanksListInterface {
	banksList: Array<Bank>;
}

interface Props {
	withoutLenco: boolean;
}

export default function useBanksList(props?: Props): UseBanksListInterface {
	const banks = useSelector((state: IRootState) => state.init.main?.options.banks);

	const [banksList, setBanksList] = useState<Array<Bank>>([]);

	const commercialBanks = banks?.filter((bank) => bank.type === BankType.COMMERCIAL_BANK).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const microFinanceBanks = banks?.filter((bank) => bank.type === BankType.MICRO_FINANCE_BANK).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const mobileMoney = banks?.filter((bank) => bank.type === BankType.MOBILE_MONEY).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const merchantBanks = banks?.filter((bank) => bank.type === BankType.MERCHANT_BANK).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const communityBanks = banks?.filter((bank) => bank.type === BankType.COMMUNITY_BANK).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const paymentSolution = banks?.filter((bank) => bank.type === BankType.PAYMENT_SOLUTION).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const discountHouse = banks?.filter((bank) => bank.type === BankType.DISCOUNT_HOUSE).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const otherFinancialInstitution =
		banks?.filter((bank) => bank.type === BankType.OTHER_FINANCIAL_INSTITUTION).sort((a, b) => a.name.localeCompare(b.name)) || [];
	const otherFinancialInstitutionFIltered = props?.withoutLenco
		? otherFinancialInstitution.filter((_) => _.code !== "lenco-till")
		: otherFinancialInstitution;

	useEffect(() => {
		setBanksList([
			...commercialBanks,
			...microFinanceBanks,
			...mobileMoney,
			...merchantBanks,
			...communityBanks,
			...paymentSolution,
			...discountHouse,
			...otherFinancialInstitutionFIltered,
		]);
	}, []);

	return {banksList};
}
