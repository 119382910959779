import BandData from "../models/bandData";
import {ZambiaPaymentMethod} from "../redux/payments/zambia/singleTransfer/slice/singleTransferSlice.types";
import store from "../redux/store";

export const BandFees = (
	_paymentMethod: ZambiaPaymentMethod,
	_amount: number
): {
	fee: number;
	tax: number;
	allFees: number;
} => {
	let taxFee = 0;
	let bandFee = 0;

	const mobileMoney = store.getState().init.main?.meta.transferFee.zm?.mobileMoney;
	const bankAccount = store.getState().init.main?.meta.transferFee.zm?.bankAccount;
	const lencoMerchant = store.getState().init.main?.meta.transferFee.zm?.lencoMerchant;

	const isBankAccount = _paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER;
	const isMobileMoney = _paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY;
	const isLencoMerchant = _paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS;

	const bandType = isMobileMoney ? mobileMoney : isBankAccount ? bankAccount : isLencoMerchant ? lencoMerchant : null;

	const bandData = bandType?.bandData.find((_bandData) => _amount >= _bandData.band.startAmount && _amount <= _bandData.band.endAmount);

	if (bandData) {
		const genericFee = calculateBandFee(bandData, _amount);
		bandFee = genericFee;
		taxFee = calculateTaxFee(bandData, _amount, genericFee);
	}

	return {
		fee: bandFee,
		tax: taxFee,
		allFees: bandFee + taxFee,
	};
};

export const calculateBandFee = (_bandData: BandData, _amount: number): number => {
	let bandFee = 0;

	if (_bandData.isFlatFee) {
		bandFee = _bandData.value;
	}
	if (_bandData.isPercentageFee) {
		const percentageFee = _amount * (_bandData.value / 100);
		bandFee = _bandData.percentageCap ? (_bandData.percentageCap > percentageFee ? percentageFee : _bandData.percentageCap) : percentageFee;
	}

	return bandFee;
};

export const calculateTaxFee = (_bandData: BandData, _amount: number, _bandFee: number): number => {
	let taxFee = 0;

	if (_bandData.tax) {
		const taxAmount = _bandData.tax.isTaxAmount ? _amount : _bandFee;
		if (_bandData.tax.isFlatFee) {
			taxFee = _bandData.tax.value;
		}
		if (_bandData.tax.isPercentageFee) {
			const percentageFee = taxAmount * (_bandData.tax.value / 100);
			taxFee = _bandData.tax.percentageCap
				? _bandData.tax.percentageCap > percentageFee
					? percentageFee
					: _bandData.tax.percentageCap
				: percentageFee;
		}
	}

	return taxFee;
};

// export const calculateFee = (
// _bandData: BandData,
// _amount: number
// ): {
// fee: string;
// tax: string;
// } => {
// let taxFee = 0;
// let bandFee = 0;

// if (_bandData.isFlatFee) {
// bandFee = _bandData.value;
// }
// if (_bandData.isPercentageFee) {
// const percentageFee = _amount * (_bandData.value / 100);
// bandFee = _bandData.percentageCap ? (_bandData.percentageCap > percentageFee ? percentageFee : _bandData.percentageCap) : percentageFee;
// }
// if (_bandData.tax) {
// const taxAmount = _bandData.tax.isTaxAmount ? _amount : bandFee;
// if (_bandData.tax.isFlatFee) {
// taxFee = _bandData.tax.value;
// }
// if (_bandData.tax.isPercentageFee) {
// const percentageFee = taxAmount * (_bandData.tax.value / 100);
// taxFee = _bandData.tax.percentageCap
// ? _bandData.tax.percentageCap > percentageFee
// ? percentageFee
// : _bandData.tax.percentageCap
// : percentageFee;
// }
// }

// return {
// fee: String(bandFee < 1 ? "" : bandFee),
// tax: String(taxFee < 1 ? "" : taxFee),
// };
// };
