import {AddMoneyFundingStageType, AddMoneyFundingType, AddMoneyPhase, AddMoneyState} from "./addMoneySlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

const initialState: AddMoneyState = {
	payTo: "",
	payFrom: "",
	pageFrom: "",
	selectedId: null,
	fundingType: null,
	fundingStage: AddMoneyFundingStageType.INITIAL,
	addMoneyPhase: AddMoneyPhase.ACCOUNT_AND_METHOD,
};

export const addMoneySlice = createSlice({
	name: "addMoney",
	initialState,
	reducers: {
		setAddMoneyPhase: (state: AddMoneyState, action: PayloadAction<AddMoneyPhase>) => {
			state.addMoneyPhase = action.payload;
		},
		setAddMoneyPayTo: (state: AddMoneyState, action: PayloadAction<string>) => {
			state.payTo = action.payload;
		},
		setAddMoneyPayFrom: (state: AddMoneyState, action: PayloadAction<string>) => {
			state.payFrom = action.payload;
		},
		setAddMoneyPageFrom: (state: AddMoneyState, action: PayloadAction<string>) => {
			state.pageFrom = action.payload;
		},
		setAddMoneyFundingType: (state: AddMoneyState, action: PayloadAction<AddMoneyFundingType>) => {
			state.fundingType = action.payload;
		},
		setAddMoneyFundingStage: (state: AddMoneyState, action: PayloadAction<AddMoneyFundingStageType>) => {
			state.fundingStage = action.payload;
		},
		setAddMoneySelectedId: (state: AddMoneyState, action: PayloadAction<string>) => {
			state.selectedId = action.payload;
		},

		resetAddMoneySelectedId: (state: AddMoneyState) => {
			state.selectedId = null;
		},

		resetAddMoneyFundingType: (state: AddMoneyState) => {
			state.fundingType = null;
		},

		resetAllAddMoneyData: (state: AddMoneyState) => {
			state.payTo = "";
			state.payFrom = "";
			state.pageFrom = "";
			state.fundingType = null;
			state.fundingStage = AddMoneyFundingStageType.INITIAL;
			state.addMoneyPhase = AddMoneyPhase.ACCOUNT_AND_METHOD;
		},
	},
});

export const {
	setAddMoneyPhase,
	setAddMoneyPayTo,
	setAddMoneyPayFrom,
	setAddMoneyPageFrom,
	setAddMoneySelectedId,
	setAddMoneyFundingType,
	setAddMoneyFundingStage,
	resetAllAddMoneyData,
	resetAddMoneySelectedId,
	resetAddMoneyFundingType,
} = addMoneySlice.actions;

export default addMoneySlice.reducer;
