import {ReactComponent as CommentListIcon} from "../../../../../../../assets/svg/commentList.svg";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import TransactionComment from "../../../../../../../models/transactionComment";
import {formatDateAndTime} from "../../../../../../../utils/formatDate";

interface Props {
	dataType: string;
	transaction: Transaction;
}

function TransactionDetailCommentsList({dataType, transaction}: Props): JSX.Element {
	return (
		<>
			{transaction.comments.length > 0 && (
				<div className="flex w-full flex-col items-start justify-start space-y-4 border-t-0.2 border-solid py-6" data-type={dataType}>
					<span className="text-sm font-medium text-black-secondary" data-type={dataType}>
						Comments
					</span>
					{transaction.comments.map((com: TransactionComment, index: number) => {
						return (
							<div className="flex w-full flex-col text-xs text-black-tertiary" data-type={dataType} key={index}>
								<div className="flex flex-row items-center justify-start" data-type={dataType}>
									<div data-type={dataType}>
										<CommentListIcon data-type={dataType} />
									</div>
									<span className="ml-2 text-sm font-normal text-black-secondary" data-type={dataType}>
										{com.createdBy && com.createdBy.name}
									</span>
									<span className="ml-2 text-xs font-normal text-black-quat" data-type={dataType}>
										{com.createdOn && formatDateAndTime(com.createdOn)}
									</span>
								</div>
								<div className="text-sm font-normal text-black-quat" data-type={dataType}>
									{com.text}
								</div>
							</div>
						);
					})}
				</div>
			)}
		</>
	);
}

export default TransactionDetailCommentsList;
