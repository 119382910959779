import React from "react";
import SkeletonElement from "../../../General/Skeletons/Components/SkeletonElement";

function SelectBusinessSkeleton(): JSX.Element {
	return (
		<>
			<div className="flex w-full flex-row items-center justify-start space-x-4">
				<div className="flex items-center justify-center">
					<div className="h-8 w-8 ">
						<SkeletonElement avatar />
					</div>
				</div>
				<div className="max-w-10 w-full">
					<SkeletonElement text />
				</div>
			</div>
		</>
	);
}

export default SelectBusinessSkeleton;
