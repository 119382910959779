import {setSingleTransferPurpose} from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

interface UseSingleTransferPurposeInterface {
	handlePurposeChange: (_purpose: string) => void;
}

function useSingleTransferPurpose(): UseSingleTransferPurposeInterface {
	const dispatch = useDispatch();

	const handlePurposeChange = useCallback((_purpose: string) => {
		dispatch(setSingleTransferPurpose(_purpose));
	}, []);

	return {
		handlePurposeChange,
	};
}

export default useSingleTransferPurpose;
