import {ReactComponent as LencoLogo} from "../../../../../assets/images/LencoLogo.svg";
import React from "react";
import {useHistory} from "react-router";

interface Props {
	url: string;
	children: React.ReactNode;
}

function ExternalFormLayout(props: Props): JSX.Element {
	const history = useHistory();

	return (
		<>
			<div className="flex flex-col w-screen h-screen bg-white relative" tabIndex={-1}>
				<div className="flex justify-start items-start py-6 px-4 2xs:px-10 w-full border-b cursor-pointer">
					<LencoLogo onClick={() => props.url && history.push({pathname: `${props.url}`})} />
				</div>
				<main className="flex flex-col justify-start items-center h-full w-full overflow-y-auto relative max-w-7xl mx-auto">
					{props.children}
				</main>
			</div>
		</>
	);
}

export default ExternalFormLayout;
