import "animate.css";

import React, {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import AddFundsCard from "../Cards/AddFundsCard";
import {IRootState} from "../../../../../redux/rootReducer";
import Modal from "../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../components/General/Modals/ModalBody";
import ModalHeader from "../../../../../components/General/Modals/ModalHeader";
import {ReactComponent as SearchIcon} from "../../../../../assets/svg/DashboardLayout/SearchBar/searchIcon.svg";
import {closeAddFundsModal} from "../../../../../redux/accounts/slice/accountsSlice";

function AddFundsModal(): JSX.Element {
	const dispatch = useDispatch();

	const isAddFundsModalOpen = useSelector((state: IRootState) => state.account.isAddFundsModalOpen);
	const userAccounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts || []);

	const [active, setActive] = useState(false);
	const [searchTerm, setSearchTerm] = useState("");
	const [inputValue, setInputValue] = useState("");

	const handleCloseAddFundsModal = useCallback(() => {
		dispatch(closeAddFundsModal());
	}, []);

	return (
		<Modal size="sm" active={isAddFundsModalOpen} toggler={handleCloseAddFundsModal}>
			<ModalHeader onClose={handleCloseAddFundsModal}>Add Funds</ModalHeader>
			<ModalBody>
				<div className="flex h-full w-full flex-col">
					<div className="flex flex-col items-start justify-start  text-left text-sm font-normal  ">
						<span className="text-black-secondary">You can add funds to your account via Bank transfer with the details below</span>
						{userAccounts.length > 5 && (
							<div className="w-full pt-4">
								<div
									className={
										`h-10 w-full rounded-custom border border-solid ` +
										`flex items-center justify-start px-4 xs:px-4 ` +
										`${active ? "border-blue text-blue" : "rounded-custom"} ` +
										`${active && searchTerm.length > 0 ? "rounded-b-none rounded-t-lg" : ""} ` +
										`${active && searchTerm.length === 0 ? "rounded-custom" : ""} ` +
										`${!active && searchTerm.length > 0 ? "border-black-secondary text-black" : ""} ` +
										`${
											!active && searchTerm.length === 0
												? "border-black-quin hover:border-blue hover:text-blue focus:border-blue"
												: ""
										} `
									}
								>
									<SearchIcon className="stroke-current text-black-tertiary" />
									<input
										type="text"
										className="outline-none focus:outline-none ml-2 w-full text-sm font-normal text-black "
										placeholder="Search for your account details"
										value={inputValue}
										onClick={() => {
											setActive(true);
										}}
										onChange={(e) => {
											setInputValue(e.target.value);
											setSearchTerm(e.target.value);
										}}
									/>
									{inputValue.length ? (
										<span
											className="cursor-pointer text-sm text-blue"
											onClick={() => {
												setInputValue("");
												setSearchTerm("");
											}}
										>
											Clear
										</span>
									) : null}
								</div>
							</div>
						)}
					</div>
					<div className="mt-4 flex w-full flex-col items-start justify-start space-y-4">
						{userAccounts
							.filter((_userAccount) => {
								if (searchTerm === "") {
									return _userAccount;
								} else if (
									_userAccount.bankAccount?.accountName.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
									_userAccount.bankAccount?.accountNumber.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase())
								) {
									return _userAccount;
								} else {
									return "";
								}
							})

							.sort(function (a, b) {
								const nameA = a.bankAccount?.accountName.toLowerCase(),
									nameB = b.bankAccount ? b.bankAccount.accountName.toLowerCase() : "";
								if (nameA && nameA < nameB)
									//sort string ascending
									return -1;
								if (nameA && nameA > nameB) return 1;
								return 0; //default return value (no sorting)
							})
							.map((_userAccount) => (
								<AddFundsCard userAccount={_userAccount} key={_userAccount.id} />
							))}
					</div>
				</div>
			</ModalBody>
		</Modal>
	);
}

export default AddFundsModal;
