import * as Yup from "yup";

import {Form, Formik, FormikProps} from "formik";
import React, {useEffect, useRef} from "react";

import ButtonComp from "../../../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../../../components/General/CurrencyCode";
import {InitiateMobileMoneyRequest} from "../../../../../../Accounts/Services/Zambia/mobileMoney.types";
import Input from "../../../../../../../../components/General/Inputs/Input";
import MessageToasts from "../../../../../../../../components/General/MessageToasts/MessageToasts";
import MoneyInput from "../../../../../../../../components/General/Inputs/MoneyInput";
import {ToastType} from "../../../../../../../../helpers/AppConstants";
import formikHasError from "../../../../../../../../helpers/formikHasError";
import titleCase from "../../../../../../../../hooks/titleCase";
import useMobileMoney from "../../../../../../Accounts/Hooks/State/Zambia/MobileMoney/useMobileMoney";
import {useMoneyToNumber} from "../../../../../../../../hooks/useMoneyToNumber";
import useVerifyMobileMoneyPhone from "../../../../../../Accounts/Hooks/State/Zambia/MobileMoney/useVerifyMobileMoneyPhone";

interface Props {
	keyId: string;
	selectedAccountId: string;
	handleBack: () => void;
	handleNext: () => void;
	handleLast: () => void;
	handleUpdateKeyId: () => void;
	handleUpdatePhoneNumber: (_phone: string) => void;
}

function MobileMoneyPaymentDetails(props: Props): JSX.Element {
	const initialFormState: InitiateMobileMoneyRequest = {
		key: props.keyId,
		amount: 0,
		operator: "",
		phoneNumber: "",
		recipientAccountId: props.selectedAccountId,
	};

	const formValidation = Yup.object().shape({
		key: Yup.string().required("Required"),
		amount: Yup.number().min(1).required("Required"),
		operator: Yup.string().required("Required"),
		phoneNumber: Yup.string().required("Required"),
	});

	const formikRef = useRef<FormikProps<InitiateMobileMoneyRequest> | null>(null);

	const {isMobileMoneyLoading, handleMobileMoney} = useMobileMoney({
		onComplete: (_phone: string) => {
			props.handleNext();
			props.handleUpdatePhoneNumber(_phone);
		},
		onUpdate: () => {
			props.handleUpdateKeyId();
		},
		onLast: () => {
			props.handleLast();
		},
	});

	const {telcoImg, isZamtelLine, bankCode, verifiedAccount, verifyAccountError, isVerifyAccountLoading, handleAccountNumberChange} =
		useVerifyMobileMoneyPhone();

	useEffect(() => {
		if (formikRef.current) {
			formikRef.current.getFieldHelpers("key").setValue(props.keyId);
			formikRef.current.getFieldHelpers("operator").setValue(bankCode);
		}
	}, [formikRef.current, props.keyId, bankCode]);

	return (
		<>
			<Formik
				initialValues={initialFormState}
				innerRef={formikRef}
				validationSchema={formValidation}
				onSubmit={(values) => handleMobileMoney({...values})}
				// enableReinitialize={true}
				validateOnChange
				validateOnMount
			>
				{(formik) => {
					return (
						<Form className="w-full flex flex-col items-center justify-start space-y-16">
							<div className="flex w-full flex-col items-center justify-center">
								<div className="w-full flex flex-col space-y-4">
									<MoneyInput
										placeholder="Amount"
										icon={<CurrencyCode />}
										value={String(formik.values.amount)}
										onChange={(value) => {
											formik.getFieldHelpers("amount").setValue(useMoneyToNumber(String(value)));
										}}
									/>
									<Input
										placeholder="Phone Number"
										value={titleCase(formik.values.phoneNumber)}
										onChange={(value: string) => {
											formik.getFieldHelpers("phoneNumber").setValue(value);
											handleAccountNumberChange(value);
										}}
										appendIcon={
											telcoImg ? (
												<div className="w-16 h-12 pr-4 flex justify-center items-center">
													<img className="h-9  rounded-full border border-black-quin" src={telcoImg} alt="telco" />
												</div>
											) : undefined
										}
										fullWidth
									/>
									{isZamtelLine && <MessageToasts toastMessage={"Funding with Zamtel coming soon "} toastType={ToastType.ERROR} />}
									{verifyAccountError && !verifiedAccount && (
										<MessageToasts toastMessage={verifyAccountError} toastType={ToastType.ERROR} />
									)}
									{!verifyAccountError && verifiedAccount && (
										<Input placeholder="Account Name" value={titleCase(verifiedAccount.accountName)} readOnly fullWidth />
									)}
								</div>
							</div>
							<div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
								<div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
									<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleBack} fullWidth>
										<span>Cancel</span>
									</ButtonComp>
								</div>
								<div className="order-1 w-full 2xs:order-2 2xs:w-max">
									<ButtonComp
										type="submit"
										color="blue"
										ripple="light"
										buttonType="primary"
										fullWidth
										isLoading={isVerifyAccountLoading || isMobileMoneyLoading}
										disable={formikHasError(formik.errors) || !verifiedAccount || isZamtelLine}
									>
										<span>Add Money</span>
									</ButtonComp>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default MobileMoneyPaymentDetails;
