import {setMultipleTransactions, updatePendingTransaction} from "../../../../../../redux/transaction/slice/transactionSlice";
import {useCallback, useState} from "react";

import {CancelPendingPaymentRequest} from "../../../Services/SendMoney/singleTransferApi.types";
import {DeclineReason} from "./usePendingPayments.types";
import {cancelPendingPayment} from "../../../Services/PendingApproval/pendingPayments";
import {errorTrue} from "../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../utils/getErrorMessage";
import {messageTrue} from "../../../../../../redux/message/slice/messageSlice";
import {useDispatch} from "react-redux";

export interface UseCancelPendingPaymentInterface {
	cancelReason: DeclineReason | null;
	cancelPaymentError: string | null;
	isCancelPaymentLoading: boolean;
	handleCancelPayment: (_data: CancelPendingPaymentRequest) => void;
}
interface Props {
	onComplete?: () => void;
}
const useCancelPendingPayment = (props?: Props): UseCancelPendingPaymentInterface => {
	const dispatch = useDispatch();

	const [cancelReason, setCancelReason] = useState<DeclineReason | null>(null);

	const [cancelPaymentError, setCancelPaymentError] = useState<string | null>("");
	const [isCancelPaymentLoading, setIsCancelPaymentLoading] = useState<boolean>(false);

	const handleCancelPayment = useCallback(
		async (_data: CancelPendingPaymentRequest) => {
			try {
				setCancelPaymentError(null);
				setIsCancelPaymentLoading(true);
				setCancelReason(_data.reason);
				const res = await cancelPendingPayment(_data);
				dispatch(messageTrue({message: "Payment cancelled successfully"}));
				dispatch(
					updatePendingTransaction([
						{
							id: _data.transactionId,
							approve: false,
							isCancelled: true,
						},
					])
				);
				dispatch(setMultipleTransactions([res.transaction]));
				props?.onComplete && props.onComplete();
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				setCancelPaymentError(errorMessage);
				dispatch(errorTrue({message: errorMessage}));
			} finally {
				setIsCancelPaymentLoading(false);
			}
		},
		[dispatch]
	);

	return {
		cancelReason,
		cancelPaymentError,
		isCancelPaymentLoading,
		handleCancelPayment,
	};
};

export default useCancelPendingPayment;
