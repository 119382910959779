import React, {useEffect} from "react";
import {
	addTransferDetailToTransferList,
	resetTransferDetail,
	setCanApprove,
	setCanVerifyRecipientAccountDetails,
	setIsUpdatingTransferDetail,
	setTransferDetailIndex,
	updateTransferDetailToTransferList,
} from "../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import {useDispatch, useSelector} from "react-redux";

import AccountNumberDropdown from "../DropDowns/AccountNumberDropdown";
// import BankAccount from "../../../../../models/bankAccount";
import BankDropdown from "../DropDowns/BankDropdown";
import ButtonComp from "../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../components/General/CurrencyCode";
import CustomerAccount from "../../../../../models/customerAccount";
import Fee from "../../../../../components/General/Fee";
import {IRootState} from "../../../../../redux/rootReducer";
import Input from "../../../../../components/General/Inputs/Input";
import MessageToasts from "../../../../../components/General/MessageToasts/MessageToasts";
import Modal from "../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../components/General/Modals/ModalBody";
import ModalFooter from "../../../../../components/General/Modals/ModalFooter";
import ModalHeader from "../../../../../components/General/Modals/ModalHeader";
import MoneyInput from "../../../../../components/General/Inputs/MoneyInput";
// import {PaymentStageType} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import TextArea from "../../../../../components/General/TextArea/TextArea";
import {ToastType} from "../../../../../helpers/AppConstants";
import UserAccount from "../../../../../models/userAccount";
import UserAccountDropdown from "../DropDowns/UserAccountDropdown";
import commaSeparator from "../../../../../utils/commaSeparator";
import doesUserHaveAccessToAccount from "../../../../../helpers/doesUserHaveAccessToAccount";
import formatNumber from "../../../../../utils/formatNumber";
import {messageTrue} from "../../../../../redux/message/slice/messageSlice";
// import {setPaymentStage} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import titleCase from "../../../../../hooks/titleCase";
import useBulkTransferAccountNumberAutocomplete from "../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferAccountNumberAutocomplete";
import useBulkTransferAmount from "../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferAmount";
import useBulkTransferPayFrom from "../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferPayFrom";
import useBulkTransferPurpose from "../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferPurpose";
import useBulkTransferRecipientBankCode from "../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferRecipientBankCode";
import useBulkTransferVerifyRecipientAccountDetails from "../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferVerifyRecipientAccountDetails";
import {useMoneyToNumber} from "../../../../../hooks/useMoneyToNumber";

interface AddRecipientsProps {
	showAboutModal: boolean;
	toggler: () => void;
}
function AddRecipients(props: AddRecipientsProps): JSX.Element {
	const {showAboutModal, toggler} = props;
	const dispatch = useDispatch();

	const selectedUserAccount = useSelector((state: IRootState) => state.bulkTransfer.transferDetail.selectedUserAccount);
	const transferDetail = useSelector((state: IRootState) => state.bulkTransfer.transferDetail);
	const isUpdatingTransferDetail = useSelector((state: IRootState) => state.bulkTransfer.isUpdatingTransferDetail);
	const verifiedAccount = useSelector((state: IRootState) => state.bulkTransfer.transferDetail.recipientBankAccount);

	const {handleAmountChange} = useBulkTransferAmount();
	const {handlePurposeChange} = useBulkTransferPurpose();
	const {handleSelectBank} = useBulkTransferRecipientBankCode();
	const {accounts, selectedAccountId, currentUserAccountMeta, handleSelectAccount, handleResetUserAccountMeta, handleSelectAccountUpdatingAccount} =
		useBulkTransferPayFrom();
	const {verifyAccountError, isVerifyAccountLoading, handleSetVerifiedAccount, handleResetVerifiedAccount} =
		useBulkTransferVerifyRecipientAccountDetails();

	const {
		suggestedRecipients,
		isRecipientAutocompleteLoading,

		// handleSelectRecipient,
		handleAccountNumberChange,
		handleSelectUserAccountRecipient,
		handleSelectCustomerAccountRecipient,
	} = useBulkTransferAccountNumberAutocomplete({handleSetVerifiedAccount, handleResetVerifiedAccount});

	const payFrom = useSelector((state: IRootState) => state.sendMoney.payFrom);

	// const [currentUserAccountMeta, setCurrentUserAccountMeta] = useState<UserAccountMeta | null>(null);

	useEffect(() => {
		if (!showAboutModal) {
			handleResetVerifiedAccount();
			handleResetUserAccountMeta();
		} else {
			if (payFrom) {
				handleSelectAccount(payFrom);
			}
		}
	}, [showAboutModal, payFrom]);

	useEffect(() => {
		if (!isUpdatingTransferDetail || !transferDetail.originatingAccountId) return;
		handleSelectAccountUpdatingAccount(transferDetail.originatingAccountId);
	}, [isUpdatingTransferDetail]);

	useEffect(() => {
		if (currentUserAccountMeta && currentUserAccountMeta.isApprover) {
			if (currentUserAccountMeta.maxApprovalAmount) {
				if (useMoneyToNumber(transferDetail.amount) <= currentUserAccountMeta.maxApprovalAmount) {
					dispatch(setCanApprove(true));
					return;
				} else {
					dispatch(setCanApprove(false));
					return;
				}
			} else {
				dispatch(setCanApprove(true));
				return;
			}
		} else {
			dispatch(setCanApprove(false));
		}
	}, [transferDetail, currentUserAccountMeta]);

	useEffect(() => {
		if (!accounts || !showAboutModal) return;
		if (accounts.length < 2) {
			if (doesUserHaveAccessToAccount(accounts[0].id)) {
				handleSelectAccount(accounts[0].id);
			}
		}
	}, [accounts, showAboutModal]);

	return (
		<>
			<Modal size="sm" active={showAboutModal} toggler={toggler}>
				<ModalHeader onClose={toggler}>Add Transfer</ModalHeader>
				<ModalBody>
					<div className=" flex w-full flex-col items-center justify-center  space-y-4 font-normal ">
						<div className="w-full">
							<UserAccountDropdown
								placeholder="Pay from"
								value={selectedAccountId || ""}
								options={accounts || null}
								onSelect={handleSelectAccount}
								isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
								showBalance
								initiatorCanSelect
							/>
						</div>
						{selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
							<div className="flex w-full max-w-sm flex-col items-center justify-start space-y-6">
								<MessageToasts toastMessage={" You can only send to specific recipients"} toastType={ToastType.INFORMATION} />
								<AccountNumberDropdown
									data={suggestedRecipients}
									inputValue={transferDetail.recipient.externalAccount?.accountNumber}
									placeholder={`Select Recipient`}
									clickAndClose
									onClickFunc={(e) => {
										// if (e instanceof UserAccount || e instanceof CustomerAccount) {
										// handleSelectRecipient(e.bankAccount as BankAccount);
										// }
										if (e instanceof UserAccount) {
											handleSelectUserAccountRecipient(e);
										}
										if (e instanceof CustomerAccount) {
											handleSelectCustomerAccountRecipient(e);
										}
									}}
									isSearchable={suggestedRecipients && suggestedRecipients?.length > 4 ? true : false}
									isInputDisabled
									showArrow
								/>
								{!verifyAccountError && verifiedAccount && (
									<div className="flex w-full max-w-sm flex-col items-start justify-start ">
										<Input placeholder="Account Name" value={titleCase(verifiedAccount.accountName)} readOnly fullWidth />
									</div>
								)}
							</div>
						)}
						{selectedUserAccount?.accountRestrictions.cannotSendMoney && (
							<div className=" w-full max-w-sm">
								<MessageToasts toastMessage={"You can not send money from this account"} toastType={ToastType.ERROR} />
							</div>
						)}
						{!selectedUserAccount?.accountRestrictions.cannotSendMoney &&
							!selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
								<>
									<div className="flex w-full flex-col items-start justify-start space-y-6 lg:flex-row">
										<div className="w-full max-w-sm">
											<AccountNumberDropdown
												data={suggestedRecipients as UserAccount[] | null}
												placeholder={`Account Number or Name`}
												inputValue={transferDetail.recipient.externalAccount?.accountNumber}
												isLoading={isRecipientAutocompleteLoading}
												clickAndClose
												onChangeFunc={handleAccountNumberChange}
												onClickFunc={(e) => {
													// if (e instanceof UserAccount || e instanceof CustomerAccount) {
													// handleSelectRecipient(e.bankAccount as BankAccount);
													// }
													if (e instanceof UserAccount) {
														handleSelectUserAccountRecipient(e);
													}
													if (e instanceof CustomerAccount) {
														handleSelectCustomerAccountRecipient(e);
													}
												}}
											/>
										</div>
									</div>
									<div className="flex w-full max-w-sm flex-col items-start justify-start ">
										<BankDropdown
											accountNumber={transferDetail.recipient.externalAccount?.accountNumber || ""}
											bankCode={transferDetail.recipient.externalAccount?.bankCode || ""}
											onClickFunc={handleSelectBank}
										/>
									</div>

									{verifyAccountError && !verifiedAccount && (
										<div className="w-full max-w-sm">
											<MessageToasts toastMessage={verifyAccountError.message} toastType={ToastType.ERROR} />
										</div>
									)}
									{!verifyAccountError && verifiedAccount && (
										<div className="flex w-full max-w-sm flex-col items-start justify-start ">
											<Input placeholder="Account Name" value={titleCase(verifiedAccount.accountName)} readOnly fullWidth />
										</div>
									)}
								</>
							)}
						<div className="flex w-full flex-col items-start space-y-2 text-base font-normal">
							<div className="flex w-full flex-col space-y-4">
								<div className="flex w-full flex-col items-start justify-start space-y-4 lg:flex-row">
									<div className="flex w-full max-w-sm flex-col space-y-2 ">
										<MoneyInput
											placeholder="Amount"
											icon={<CurrencyCode />}
											value={transferDetail.amount || ""}
											onChange={handleAmountChange}
										/>
										{selectedUserAccount?.bankCode !== transferDetail.recipient?.externalAccount.bankCode && (
											<div
												className={`text-xs text-black-tertiary ${
													transferDetail.amount || transferDetail.amount.length > 1 ? "block" : "hidden"
												}`}
											>
												{" "}
												Fee: {<Fee value={transferDetail.amount} />}
											</div>
										)}
									</div>
								</div>
								<div className="flex w-full max-w-sm flex-col items-start justify-start space-y-4">
									<div className="w-full">
										<TextArea
											placeholder="Purpose of payment"
											value={transferDetail.purpose}
											onChangeFunc={handlePurposeChange}
											sm={true}
										/>
									</div>
								</div>
								{currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
									<div className="w-full max-w-sm">
										<MessageToasts
											toastMessage={
												(
													<div>
														{" "}
														You can only send <CurrencyCode />
														{formatNumber(currentUserAccountMeta?.maxApprovalAmount)} or less on this account. Any amount
														more than this will be initiated and need to be approved by an admin.
													</div>
												) as unknown as string | Element
											}
											toastType={ToastType.INFORMATION}
										/>
									</div>
								)}
								{useMoneyToNumber(transferDetail.amount) > (selectedUserAccount?.balance as number) && (
									<div className="w-full max-w-sm">
										<MessageToasts
											toastMessage={
												(
													<div>
														{" "}
														You do not have enough funds. Your account balance is <CurrencyCode />
														{commaSeparator(selectedUserAccount?.balance as number)}
													</div>
												) as unknown as string | Element
											}
											toastType={ToastType.ERROR}
										/>
									</div>
								)}
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={() => toggler()}>
						<span className="w-full text-center">Cancel</span>
					</ButtonComp>
					<ButtonComp
						type="submit"
						color="blue"
						ripple="light"
						buttonType="primary"
						disable={
							!!(
								!selectedUserAccount ||
								verifyAccountError ||
								!verifiedAccount ||
								selectedUserAccount.accountRestrictions.cannotSendMoney ||
								!transferDetail.purpose ||
								!transferDetail.amount ||
								useMoneyToNumber(transferDetail.amount) === 0 ||
								useMoneyToNumber(transferDetail.amount) > (selectedUserAccount?.balance as number)
							)
						}
						isLoading={isVerifyAccountLoading}
						func={() => {
							if (isUpdatingTransferDetail) {
								dispatch(updateTransferDetailToTransferList(transferDetail));
								dispatch(messageTrue("Transfer Updated"));
							} else {
								dispatch(addTransferDetailToTransferList(transferDetail));
								dispatch(messageTrue("Transfer Added"));
							}
							handleResetVerifiedAccount();
							handleResetUserAccountMeta();
							dispatch(resetTransferDetail());
							dispatch(setIsUpdatingTransferDetail(false));
							dispatch(setCanVerifyRecipientAccountDetails(false));
							if (!isUpdatingTransferDetail) {
								dispatch(setTransferDetailIndex());
							}
						}}
					>
						<span className="w-full text-center">{isUpdatingTransferDetail ? "Update Transfer" : "Add Transfer"}</span>
					</ButtonComp>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default AddRecipients;
