import {
	resetTransferBetweenOriginatingAccount,
	resetTransferBetweenOriginatingCard,
	resetTransferBetweenRecipient,
	setSelectedCard,
	setSelectedUserAccount,
	setSingleCardBalance,
	setTransferBetweenOriginatingCardAccount,
} from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import Card from "../../../../../../../models/card";
import {CardBalanceRequest} from "../../../../../Cards/Services/cardsApi.types";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {RequestCancelledError} from "../../../../../../../helpers/request/requestErrors";
import UserAccount from "../../../../../../../models/userAccount";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";
import {cardBalance} from "../../../../../Cards/Services/cardsApi";
import doesUserHaveAccessToAccount from "../../../../../../../helpers/doesUserHaveAccessToAccount";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {setTransferBetweenOriginatingAccount} from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";

interface useTransferBetweenPayFromInterface {
	accounts: UserAccount[] | undefined;
	selectedCardId: string;
	CardBalanceError: string | null;
	getPayFromOptions: Array<Card | UserAccount>;
	selectedAccountId: string;
	isCardBalanceLoading: boolean;
	currentUserAccountMeta: UserAccountMeta | null;

	handleSelectAccount: (_accountId: string) => void;
}

function useTransferBetweenPayFrom(): useTransferBetweenPayFromInterface {
	const dispatch = useDispatch();
	const cards = useSelector((state: IRootState) => state.init.main?.companyDetails.activePrepaidCards);
	const payFrom = useSelector((state: IRootState) => state.sendMoney.payFrom);
	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
	const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
	const originatingCardId = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails.originatingCardId);
	const recipientAccountId = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails.recipientAccountId);
	const originatingAccountId = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails.originatingAccountId);

	const [CardBalanceError, setCardBalanceError] = useState<string | null>(null);
	const [isCardBalanceLoading, setIsCardBalanceLoading] = useState(false);

	const currentUserAccountMeta = userAccountsMeta?.find((_it) => _it.userAccountId === originatingAccountId) || null;

	useEffect(() => {
		if (!accounts) return;
		if (accounts.length < 2) {
			if (doesUserHaveAccessToAccount(accounts[0].id)) {
				handleSelectAccount(accounts[0].id);
			}
		}
	}, [accounts]);

	useEffect(() => {
		if (!payFrom) return;
		handleSelectAccount(payFrom);
	}, [payFrom]);

	const handleSelectAccount = useCallback(
		(_accountId): void => {
			const doesAccountExist = accounts?.some((el) => el.id === _accountId);
			if (doesAccountExist) {
				const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
				dispatch(setTransferBetweenOriginatingAccount(_accountId));
				dispatch(setSelectedUserAccount(userAccount));
				dispatch(resetTransferBetweenOriginatingCard());
				if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) dispatch(resetTransferBetweenRecipient());
			}
			const doesCardExist = cards?.some((el) => el.id === _accountId);
			if (doesCardExist) {
				const card = cards?.find((el) => el.id === _accountId) as Card;
				void handleCardBalance({createRequestId: card.createRequestId});
				dispatch(setTransferBetweenOriginatingCardAccount(_accountId));
				dispatch(setSelectedCard(card));
				dispatch(resetTransferBetweenOriginatingAccount());
			}
		},
		[cards, accounts]
	);

	const handleCardBalance = useCallback(
		async (_data: CardBalanceRequest) => {
			try {
				setIsCardBalanceLoading(true);
				const res = await cardBalance(_data);
				dispatch(
					setSingleCardBalance({
						balance: res.balance || 0,
						id: _data.createRequestId,
					})
				);
				setCardBalanceError(null);
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}
				const errorMessage = getErrorMessage(err);
				dispatch(errorTrue({message: errorMessage}));
				setCardBalanceError(errorMessage);
			}
			setIsCardBalanceLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const getPayFromOptions = useMemo((): Array<Card | UserAccount> => {
		if (!accounts || !cards) return [];
		return [
			...accounts.filter((el) => el.id !== originatingAccountId && el.id !== recipientAccountId),
			...cards.filter((el) => el.id !== originatingCardId),
		];
	}, [accounts, cards, originatingCardId, originatingAccountId, recipientAccountId]);

	return {
		accounts,
		CardBalanceError,
		getPayFromOptions,
		isCardBalanceLoading,
		currentUserAccountMeta,
		selectedAccountId: originatingAccountId,
		selectedCardId: originatingCardId,
		handleSelectAccount,
	};
}

export default useTransferBetweenPayFrom;
