import Parsers from "../utils/parsers";
import AccountRestriction from "./accountRestriction";
import Currency from "./currency";
import {GenericObject} from "../helpers/types";
import BankAccount from "./bankAccount";
import {AccountBalanceInstructionType, ActivationStatus} from "./userAccount.constants";
import isNullOrUndefined from "../utils/isNullOrUndefined";
import {CorporateAccountType} from "./corporate.constants";
import AccountBalanceInstruction from "./accountBalanceInstruction";
import {immerable} from "immer";

class UserAccountMonthBalance {
	[immerable] = true;

	constructor(public debit: null | number, public credit: null | number) {}

	static create(obj: GenericObject): UserAccountMonthBalance {
		return new UserAccountMonthBalance(Parsers.nullableNumber(obj.debit), Parsers.nullableNumber(obj.credit));
	}

	static createDefault(): UserAccountMonthBalance {
		return new UserAccountMonthBalance(null, null);
	}
}

export default class UserAccount {
	[immerable] = true;

	// todo -> when we start multiple currencies, refactor and include this
	// currency: Currency | null = null;

	constructor(
		public id: string,
		public bankAccount: BankAccount | null,
		public description: string | null,
		public balance: number | null,
		public isMain: boolean,
		public subAccountShortName: string | null,
		public accountRestrictions: AccountRestriction,
		public balanceInstructions: AccountBalanceInstruction[],
		public monthBalance: UserAccountMonthBalance,
		public activationStatus: ActivationStatus,
		public type: CorporateAccountType,
		public todayTransfersTotal: number | null,
		public nipDailyTransferLimit: number
	) {}

	get name(): string {
		return this.bankAccount ? this.bankAccount.accountName : "";
	}

	get alias(): string {
		return "";
	}

	get accountName(): string {
		return this.name;
	}

	get singleName(): string {
		return this.name;
	}

	get lencoName(): string {
		if (!this.bankAccount) {
			return "";
		}
		const suffix = `**${this.bankAccount.accountNumber.substr(Math.max(0, this.bankAccount.accountNumber.length - 4))}`;
		return `${this.lencoNameMin} ${suffix}`;
	}

	get lencoNameMin(): string {
		if (this.isMain) {
			return "Main";
		}
		if (this.subAccountShortName) {
			return this.subAccountShortName;
		}
		if (!this.bankAccount) {
			return "";
		}

		return this.bankAccount.accountName;
	}

	get accountNumber(): string {
		return this.bankAccount ? this.bankAccount.accountNumber : "";
	}

	get bankCode(): string {
		return this.bankAccount ? this.bankAccount.bankCode : "";
	}

	get bankAccountCurrency(): Currency | null {
		return this.bankAccount ? this.bankAccount.currency : null;
	}

	get isActivated(): boolean {
		return this.activationStatus === ActivationStatus.ACTIVATED;
	}

	get isUnactivated(): boolean {
		return this.activationStatus === ActivationStatus.NOT_ACTIVATED;
	}

	get isActivationOngoing(): boolean {
		return this.activationStatus === ActivationStatus.ONGOING;
	}

	static create(obj: GenericObject): UserAccount {
		return new UserAccount(
			Parsers.string(obj.id),
			Parsers.classObject(obj.bankAccount, BankAccount),
			Parsers.nullableString(obj.description),
			Parsers.nullableNumber(obj.balance),
			Parsers.boolean(obj.isMain),
			Parsers.nullableString(obj.subAccountShortName),
			Parsers.classObjectNonNullable(obj.accountRestrictions, AccountRestriction),
			Parsers.classObjectArray(obj.balanceInstructions, AccountBalanceInstruction),
			obj.monthBalance ? Parsers.classObjectNonNullable(obj.monthBalance, UserAccountMonthBalance) : UserAccountMonthBalance.createDefault(),
			Parsers.number(obj.activationStatus),
			Parsers.number(obj.type),
			Parsers.nullableNumber(obj.todayTransfersTotal),
			Parsers.number(obj.nipDailyTransferLimit)
		);
	}

	update(userAccount: UserAccount): void {
		// todo -> might ignore some if the data is not complete (i.e the object is not full)
		this.bankAccount = userAccount.bankAccount;
		this.description = userAccount.description;
		this.updateBalance(userAccount.balance);
		this.isMain = userAccount.isMain;
		this.subAccountShortName = userAccount.subAccountShortName;
		this.accountRestrictions = userAccount.accountRestrictions;
		this.balanceInstructions = userAccount.balanceInstructions;
		this.activationStatus = userAccount.activationStatus;
		if (userAccount.monthBalance) {
			this.monthBalance = userAccount.monthBalance;
		}
		this.type = userAccount.type;
		if (!isNullOrUndefined(userAccount.todayTransfersTotal)) {
			this.todayTransfersTotal = userAccount.todayTransfersTotal;
		}
	}

	updateBalance(balance: number | null | undefined): void {
		if (balance !== null && balance !== undefined) {
			this.balance = balance;
		}
	}

	get lowBalanceInstruction(): AccountBalanceInstruction | undefined {
		return this.balanceInstructions.find((b) => b.type === AccountBalanceInstructionType.LOW_BALANCE_ALERT);
	}

	get excessBalanceInstruction(): AccountBalanceInstruction | undefined {
		return this.balanceInstructions.find((b) => b.type === AccountBalanceInstructionType.EXCESS_BALANCE_ALERT);
	}

	get splitInflowInstruction(): AccountBalanceInstruction | undefined {
		return this.balanceInstructions.find((b) => b.type === AccountBalanceInstructionType.SPLIT_INFLOW);
	}

	getBalanceInstructionByType(type: AccountBalanceInstructionType): AccountBalanceInstruction | undefined {
		return this.balanceInstructions.find((instruction) => instruction.type === type);
	}

	hasBalanceInstructionByType(type: AccountBalanceInstructionType): boolean {
		return this.balanceInstructions.some((instruction) => instruction.type === type);
	}
}
