import "animate.css";

import React, {useEffect} from "react";

import ButtonComp from "../../../../../components/General/Buttons/ButtonComp";
import MessageToasts from "../../../../../components/General/MessageToasts/MessageToasts";
import Modal from "../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../components/General/Modals/ModalBody";
import ModalFooter from "../../../../../components/General/Modals/ModalFooter";
import ModalHeader from "../../../../../components/General/Modals/ModalHeader";
import OtpInputForm from "../../../../../components/OtpInputForm";
import {TailSpin} from "react-loader-spinner";
import {ToastType} from "../../../../../helpers/AppConstants";
import UserAccount from "../../../../../models/userAccount";
import useActivateAccountState from "../../Hooks/State/useActivateAccountState";

interface ActivateAccountProps {
	active: boolean;
	data: UserAccount | null;
	toggler: () => void;
	onCompleteFunc: (account: UserAccount) => void;
}
function ActivateAccount(props: ActivateAccountProps): JSX.Element {
	const {active, data, toggler, onCompleteFunc} = props;
	const {
		activateAccountFinishResponse,
		handleActivateAccountFinish,
		handleOTPInit,
		handleOtpChange,
		handleResendOTPInit,
		initOTPError,
		isActivateAccountFinishError,
		isActivateAccountFinishLoading,
		isInitOTPLoading,
		minutes,
		otp,
		seconds,
	} = useActivateAccountState(active);

	useEffect(() => {
		if (!data || !active) return;
		void handleOTPInit({userAccountId: data.id});
	}, [data, active, handleOTPInit]);

	useEffect(() => {
		if (!otp || otp.length < 6 || !data) return;
		void handleActivateAccountFinish({userAccountId: data.id, otp});
	}, [data, handleActivateAccountFinish, otp]);

	useEffect(() => {
		if (!activateAccountFinishResponse || !data) return;
		onCompleteFunc(data);
		toggler();
	}, [activateAccountFinishResponse, toggler, onCompleteFunc]);

	return (
		<>
			<Modal size="xs" active={active} toggler={toggler}>
				<ModalHeader onClose={toggler}>Activate Account</ModalHeader>
				<ModalBody>
					<div className="relative flex w-full flex-col text-black-secondary">
						{(initOTPError || isActivateAccountFinishError) && (
							<MessageToasts
								className="mb-4"
								toastMessage={initOTPError || isActivateAccountFinishError || ""}
								toastType={ToastType.ERROR}
							/>
						)}
						{!initOTPError && (
							<>
								{isInitOTPLoading ? (
									<div className=" flex w-full flex-row items-start justify-start space-x-1 pb-4 text-sm">
										<TailSpin color="#3A4EF8" height={20} width={20} /> <span className="text-left">Generating OTP...</span>
									</div>
								) : (
									<div className=" flex w-full flex-col items-start justify-start space-y-2 text-left ">
										<span className="text-base font-medium">This is a one-time action on this account.</span>
										<span className="text-sm font-normal">
											To enable {data && data.subAccountShortName} for payout, we sent a one-time password to your phone number
											to activate the account with our bank partner.
										</span>
									</div>
								)}
								<div className="flex w-full flex-col items-start justify-start pt-4">
									<OtpInputForm value={otp || undefined} otpFunc={handleOtpChange} errorMessage={isActivateAccountFinishError} />
									<div className="flex items-center justify-start pt-4">
										<div>
											{minutes === 0 && seconds === 0 ? (
												<div onClick={() => handleResendOTPInit({userAccountId: data?.id || ""})}>
													<span className="cursor-pointer  text-sm text-blue">Resend OTP</span>
												</div>
											) : (
												<span className="cursor-not-allowed  text-sm text-black-tertiary">
													Resend OTP in{" "}
													<span className="text-black-secondary">
														{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
													</span>
												</span>
											)}
										</div>
									</div>
								</div>
							</>
						)}
					</div>
				</ModalBody>
				<ModalFooter>
					<ButtonComp
						type="button"
						ripple="light"
						buttonType="secondary"
						color="grey"
						func={() => {
							toggler();
						}}
					>
						<span>Back</span>
					</ButtonComp>

					<ButtonComp
						type="submit"
						color="blue"
						ripple="light"
						buttonType="primary"
						isLoading={isActivateAccountFinishLoading}
						disable={!isActivateAccountFinishLoading}
					>
						<div className="flex w-full items-center justify-center">
							<span>Authorize</span>
						</div>
					</ButtonComp>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default ActivateAccount;
