import {ApiAccessScope, ApiAccessSource} from "../../../models/zambia/lencoPay/apiAccess.constant";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import ApiAccessWebhook from "../../../models/zambia/lencoPay/apiAccessWebhook";
import DefaultSetup from "../../../models/zambia/lencoPay/defaultSetup";
import {ZambiaDefaultSetupState} from "./zambiaDefaultSetupSlice.ts.types";
import User from "../../../models/user";

const initialState: ZambiaDefaultSetupState = {
	defaultSetup: null,

	defaultSetupConfiguration: {
		scopes: [
			ApiAccessScope.COLLECTIONS_CARD,
			ApiAccessScope.COLLECTIONS_MOBILE_MONEY,
			ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT,
			ApiAccessScope.DISBURSEMENT_MOBILE_MONEY,
		],
		otherAccountsAccessIds: [],
		settlementAccountId: null,
		collectionFeeSource: ApiAccessSource.MERCHANT,
		ipWhitelist: [],
		webhookUrl: null,
		webhookCustomHeaders: [],
	},

	tempDefaultSetupConfiguration: {
		scopes: [
			ApiAccessScope.COLLECTIONS_CARD,
			ApiAccessScope.COLLECTIONS_MOBILE_MONEY,
			ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT,
			ApiAccessScope.DISBURSEMENT_MOBILE_MONEY,
		],
		otherAccountsAccessIds: [],
		settlementAccountId: null,
		collectionFeeSource: ApiAccessSource.MERCHANT,
		ipWhitelist: [],
		webhookUrl: null,
		webhookCustomHeaders: [],
	},
	teamMemberAccess: {
		totalTeamAccessList: [],
		manageAccess: [],
		viewAccess: [],
	},
	removeTeamMember: null,
	users: [],
};

export const zambiaDefaultSetupSlice = createSlice({
	name: "zambiaDefaultSetup",
	initialState,
	reducers: {
		updateDefaultSetup: (state: ZambiaDefaultSetupState, action: PayloadAction<DefaultSetup>) => {
			state.defaultSetup = action.payload;
			state.tempDefaultSetupConfiguration.scopes = action.payload.scopes;
			if (action.payload.collectionFeeSource) {
				state.tempDefaultSetupConfiguration.collectionFeeSource = action.payload.collectionFeeSource;
			}
			state.tempDefaultSetupConfiguration.ipWhitelist = action.payload.ipWhitelist;
			state.tempDefaultSetupConfiguration.otherAccountsAccessIds = action.payload.otherAccountsAccess.map((_) => _.id);
			state.tempDefaultSetupConfiguration.scopes = action.payload.scopes;
			state.tempDefaultSetupConfiguration.settlementAccountId = action.payload.collectionsSettlementAccount?.id || null;
			state.tempDefaultSetupConfiguration.webhookCustomHeaders = action.payload.webhookCustomHeaders;
			state.tempDefaultSetupConfiguration.webhookUrl = action.payload.webhookUrl;
		},
		setUserList: (state: ZambiaDefaultSetupState, action: PayloadAction<User[]>) => {
			state.users = action.payload;
		},
		updateViewerAccessList: (state: ZambiaDefaultSetupState, action: PayloadAction<User[]>) => {
			state.teamMemberAccess.viewAccess = action.payload;
		},
		updateManagerAccessList: (state: ZambiaDefaultSetupState, action: PayloadAction<User[]>) => {
			state.teamMemberAccess.manageAccess = action.payload;
		},
		updateTotalAccessList: (state: ZambiaDefaultSetupState, action: PayloadAction<User[]>) => {
			state.teamMemberAccess.totalTeamAccessList = action.payload;
		},
		addManagersWithCurrentViewersList: (state: ZambiaDefaultSetupState, action: PayloadAction<User[]>) => {
			state.teamMemberAccess.totalTeamAccessList = [...action.payload, ...state.teamMemberAccess.viewAccess];
		},
		addViewersWithCurrentManagersList: (state: ZambiaDefaultSetupState, action: PayloadAction<User[]>) => {
			state.teamMemberAccess.totalTeamAccessList = [...state.teamMemberAccess.viewAccess, ...action.payload];
		},
		setRemoveTeamMember: (state: ZambiaDefaultSetupState, action: PayloadAction<User | null>) => {
			state.removeTeamMember = action.payload;
		},

		updateScopesDefaultSetupConfiguration: (state: ZambiaDefaultSetupState, action: PayloadAction<ApiAccessScope[]>) => {
			state.tempDefaultSetupConfiguration.scopes = action.payload;
		},
		updateCollectionFeeSourceDefaultSetupConfiguration: (state: ZambiaDefaultSetupState, action: PayloadAction<ApiAccessSource>) => {
			state.tempDefaultSetupConfiguration.collectionFeeSource = action.payload;
		},
		updateSettlementAccountIdDefaultSetupConfiguration: (state: ZambiaDefaultSetupState, action: PayloadAction<string>) => {
			state.tempDefaultSetupConfiguration.settlementAccountId = action.payload;
		},
		updateIpWhitelistDefaultSetupConfiguration: (state: ZambiaDefaultSetupState, action: PayloadAction<string[]>) => {
			state.tempDefaultSetupConfiguration.ipWhitelist = action.payload;
		},
		updateOtherAccountsAccessIdsDefaultSetupConfiguration: (state: ZambiaDefaultSetupState, action: PayloadAction<string[]>) => {
			state.tempDefaultSetupConfiguration.otherAccountsAccessIds = action.payload;
		},
		updateWebhookCustomHeadersDefaultSetupConfiguration: (state: ZambiaDefaultSetupState, action: PayloadAction<ApiAccessWebhook[]>) => {
			state.tempDefaultSetupConfiguration.webhookCustomHeaders = action.payload;
		},
		updateWebhookUrlDefaultSetupConfiguration: (state: ZambiaDefaultSetupState, action: PayloadAction<string>) => {
			state.tempDefaultSetupConfiguration.webhookUrl = action.payload;
		},

		setUpdateMainDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.defaultSetupConfiguration = state.tempDefaultSetupConfiguration;
		},

		resetScopesDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.tempDefaultSetupConfiguration.scopes = [
				ApiAccessScope.COLLECTIONS_CARD,
				ApiAccessScope.COLLECTIONS_MOBILE_MONEY,
				ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT,
				ApiAccessScope.DISBURSEMENT_MOBILE_MONEY,
			];
		},
		resetCollectionFeeSourceDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.tempDefaultSetupConfiguration.collectionFeeSource = ApiAccessSource.MERCHANT;
		},
		resetSettlementAccountIdDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.tempDefaultSetupConfiguration.settlementAccountId = null;
		},
		resetIpWhitelistDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.tempDefaultSetupConfiguration.ipWhitelist = [];
		},
		resetOtherAccountsAccessIdsDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.tempDefaultSetupConfiguration.otherAccountsAccessIds = [];
		},
		resetWebhookCustomHeadersDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.tempDefaultSetupConfiguration.webhookCustomHeaders = [];
		},
		resetWebhookUrlDefaultSetupConfiguration: (state: ZambiaDefaultSetupState) => {
			state.tempDefaultSetupConfiguration.webhookUrl = null;
		},
		resetAllZambiaDefaultSetup: (state: ZambiaDefaultSetupState) => {
			state.defaultSetup = null;

			state.users = [];
			state.teamMemberAccess = {
				manageAccess: [],
				totalTeamAccessList: [],
				viewAccess: [],
			};

			state.defaultSetupConfiguration = {
				scopes: [
					ApiAccessScope.COLLECTIONS_CARD,
					ApiAccessScope.COLLECTIONS_MOBILE_MONEY,
					ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT,
					ApiAccessScope.DISBURSEMENT_MOBILE_MONEY,
				],
				otherAccountsAccessIds: [],
				settlementAccountId: null,
				collectionFeeSource: ApiAccessSource.MERCHANT,
				ipWhitelist: [],
				webhookUrl: null,
				webhookCustomHeaders: [],
			};
			state.tempDefaultSetupConfiguration = {
				scopes: [
					ApiAccessScope.COLLECTIONS_CARD,
					ApiAccessScope.COLLECTIONS_MOBILE_MONEY,
					ApiAccessScope.DISBURSEMENT_BANK_ACCOUNT,
					ApiAccessScope.DISBURSEMENT_MOBILE_MONEY,
				],
				otherAccountsAccessIds: [],
				settlementAccountId: null,
				collectionFeeSource: ApiAccessSource.MERCHANT,
				ipWhitelist: [],
				webhookUrl: null,
				webhookCustomHeaders: [],
			};
		},
	},
});

export const {
	updateDefaultSetup,
	updateScopesDefaultSetupConfiguration,
	updateCollectionFeeSourceDefaultSetupConfiguration,
	updateSettlementAccountIdDefaultSetupConfiguration,
	updateIpWhitelistDefaultSetupConfiguration,
	updateOtherAccountsAccessIdsDefaultSetupConfiguration,
	updateWebhookCustomHeadersDefaultSetupConfiguration,
	updateWebhookUrlDefaultSetupConfiguration,
	setUpdateMainDefaultSetupConfiguration,

	resetScopesDefaultSetupConfiguration,
	resetCollectionFeeSourceDefaultSetupConfiguration,
	resetSettlementAccountIdDefaultSetupConfiguration,
	resetIpWhitelistDefaultSetupConfiguration,
	resetOtherAccountsAccessIdsDefaultSetupConfiguration,
	resetWebhookCustomHeadersDefaultSetupConfiguration,
	resetWebhookUrlDefaultSetupConfiguration,
	resetAllZambiaDefaultSetup,
	updateViewerAccessList,
	updateManagerAccessList,
	updateTotalAccessList,
	setRemoveTeamMember,
	addManagersWithCurrentViewersList,
	addViewersWithCurrentManagersList,
	setUserList,
} = zambiaDefaultSetupSlice.actions;

export default zambiaDefaultSetupSlice.reducer;
