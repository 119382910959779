import React, {useEffect} from "react";

import ActivateAccount from "../../../../Accounts/Components/Modals/ActivateAccountModal";
import FullPageTray from "../../../../../../components/General/Trays/FullPageTray/FullPageTray";
import FullPageTrayBody from "../../../../../../components/General/Trays/FullPageTray/FullPageTrayBody";
import FullPageTrayHeader from "../../../../../../components/General/Trays/FullPageTray/FullPageTrayHeader";
import {IRootState} from "../../../../../../redux/rootReducer";
import PendingApprovalModal from "../../../../Payments/Components/Modals/PendingApprovalModal";
import PreviewModal from "../../../../Application/components/modals/preview-modal";
import TagTransactionRecipient from "../../Modal/TagTransactionRecipient";
import TransactionDetailCardActions from "./Elements/TransactionDetailCardActions";
import TransactionDetailCardDetails from "./Elements/TransactionDetailCardDetails";
// import TransactionDetailCardDisputePayment from "./Elements/TransactionDetailCardDisputePayment";
import TransactionDetailCardExpenseManagement from "./Elements/TransactionDetailCardExpenseManagement";
import TransactionDetailCardHead from "./Elements/TransactionDetailCardHead";
import TransactionDetailCardHeader from "./Elements/TransactionDetailCardHeader";
import TransactionDetailCardPendingActivation from "./Elements/TransactionDetailCardPendingActivation";
import TransactionDetailCardStatus from "./Elements/TransactionDetailCardStatus";
import TransactionDetailCardUpload from "./Elements/TransactionDetailCardUpload";
import TransactionDetailCommentsList from "./Elements/TransactionDetailCommentsList";
import TransactionsDetailCardCancelApprovalActions from "./Elements/TransactionsDetailCardCancelApprovalActions";
import TransactionsDetailCardPendingApprovalActions from "./Elements/TransactionsDetailCardPendingApprovalActions";
import {abortTransactionCardCalls} from "./Services/transactionDetailApi";
import {useSelector} from "react-redux";
import useTransactionDetailsAccountingReceipt from "./Hooks/useTransactionDetailsAccountingReceipt";
import useTransactionDetailsActivateAccount from "./Hooks/useTransactionDetailsActivateAccount";
import useTransactionDetailsCard from "./Hooks/useTransactionDetailsCard";
import useTransactionDetailsPendingApproval from "./Hooks/useTransactionDetailsPendingApproval";

function TransactionDetailCard(): JSX.Element {
	const {
		transaction,
		canShowTransaction,
		handleCloseTransactionDetailCard,
		canApproveTransaction,
		showTagRecipientModal,
		setShowTagRecipientModal,
		domNode,
	} = useTransactionDetailsCard();

	const {
		isBigger,
		receiptBlob,
		isUploading: isUploadingReceipt,
		isPreviewDownloading,
		isPreviewModalOpen,
		handleClosePreviewModal,
		handleUploadAccountingReceipt,
		handlePreviewAccountingReceipt,
	} = useTransactionDetailsAccountingReceipt();

	// Activate Account Hook
	const {accountToActivate, setAccountToActivate, showActivateAccountModal, setShowActivateAccountModal, handleAccountActivationCompleted} =
		useTransactionDetailsActivateAccount();

	// Pending Transaction Hook
	const {
		showPendingApprovalModal,
		handleTransactionApproved,
		handleTransactionDeclined,
		handleTogglePendingApprovalShowModal,
		handleClosePendingApprovalShowModal,
	} = useTransactionDetailsPendingApproval();

	const globalActiveDataType = useSelector((state: IRootState) => state.init.globalActiveDataType);

	useEffect(() => {
		if (!canShowTransaction) {
			handleClosePendingApprovalShowModal();
			setShowTagRecipientModal(false);
			handleClosePreviewModal();
			setShowActivateAccountModal(false);
			abortTransactionCardCalls();
		}
	}, [canShowTransaction]);

	return (
		<>
			<div className="relative" ref={domNode} data-type={globalActiveDataType || "transaction"}>
				{transaction && (
					<>
						<PendingApprovalModal
							active={showPendingApprovalModal}
							data={transaction}
							toggler={handleClosePendingApprovalShowModal}
							onCompleteFunc={handleTransactionApproved}
						/>
						<ActivateAccount
							active={showActivateAccountModal}
							data={accountToActivate}
							toggler={() => setShowActivateAccountModal(false)}
							onCompleteFunc={handleAccountActivationCompleted}
						/>
						<PreviewModal
							active={isPreviewModalOpen}
							data={receiptBlob}
							toggler={handleClosePreviewModal}
							title="Transaction Attachment"
						/>
						<TagTransactionRecipient
							showModal={showTagRecipientModal}
							toggler={() => setShowTagRecipientModal(false)}
							transaction={transaction}
						/>
					</>
				)}
				<FullPageTray active={canShowTransaction} dataType={globalActiveDataType || "transaction"}>
					<FullPageTrayHeader toggler={handleCloseTransactionDetailCard} dataType={globalActiveDataType || "transaction"}>
						{/* Header title */}
						{transaction && <TransactionDetailCardHeader transaction={transaction} />}
					</FullPageTrayHeader>
					<FullPageTrayBody dataType={globalActiveDataType || "transaction"}>
						{transaction && (
							<>
								<TransactionDetailCardHead transaction={transaction} dataType={globalActiveDataType || "transaction"} />
								<TransactionDetailCardDetails transaction={transaction} dataType={globalActiveDataType || "transaction"} />
								<TransactionDetailCardStatus transaction={transaction} dataType={globalActiveDataType || "transaction"} />
								<TransactionDetailCardExpenseManagement
									transaction={transaction}
									showTransactionDetailCard={canShowTransaction}
									dataType={globalActiveDataType || "transaction"}
								/>
								<TransactionDetailCommentsList transaction={transaction} dataType={globalActiveDataType || "transaction"} />
								{/* {transaction.isCardPayment && (
									<TransactionDetailCardDisputePayment
										isBigger={isBigger}
										transaction={transaction}
										isPreviewDownloading={isPreviewDownloading}
										isUploadingReceipt={isUploadingReceipt}
										handleUploadAccountingReceipt={handleUploadAccountingReceipt}
										handlePreviewAccountingReceipt={handlePreviewAccountingReceipt}
									/>
								)} */}
								<TransactionDetailCardUpload
									transactionDetail={transaction}
									dataType={globalActiveDataType || "transaction"}
									isBigger={isBigger}
									isPreviewDownloading={isPreviewDownloading}
									isUploadingReceipt={isUploadingReceipt}
									handleUploadAccountingReceipt={handleUploadAccountingReceipt}
									handlePreviewAccountingReceipt={handlePreviewAccountingReceipt}
								/>
							</>
						)}
					</FullPageTrayBody>
					{transaction && !transaction.isCardPayment && (
						<>
							<TransactionDetailCardActions
								dataType={globalActiveDataType || "transaction"}
								transaction={transaction}
								handleShowTagTransactionModal={() => setShowTagRecipientModal(true)}
							/>
							<TransactionsDetailCardPendingApprovalActions
								dataType={globalActiveDataType || "transaction"}
								transaction={transaction}
								isAdmin={canApproveTransaction}
								handleUpdateDeclinedTransaction={handleTransactionDeclined}
								handleTogglePendingApprovalShowModal={handleTogglePendingApprovalShowModal}
							/>
							<TransactionsDetailCardCancelApprovalActions dataType={globalActiveDataType || "transaction"} transaction={transaction} />
							<TransactionDetailCardPendingActivation
								dataType={globalActiveDataType || "transaction"}
								transaction={transaction}
								handleShowActiveAccountModal={() => setShowActivateAccountModal(true)}
								handleSetAccountToActivate={setAccountToActivate}
							/>
						</>
					)}
				</FullPageTray>
			</div>
		</>
	);
}

export default TransactionDetailCard;
