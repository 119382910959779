import React, {useEffect, useState} from "react";

import Clipboard from "../../../../../components/General/Clipboard/Clipboard";
import getBankName from "../../../../../helpers/getBankName";
import titleCase from "../../../../../hooks/titleCase";
import UserAccount from "../../../../../models/userAccount";

interface AddFundsCardProps {
	userAccount: UserAccount;
}
function AddFundsCard(props: AddFundsCardProps): JSX.Element {
	const {userAccount} = props;
	const [clipSuccess, setClipSuccess] = useState(false);

	useEffect(() => {
		if (clipSuccess) {
			setTimeout(() => {
				setClipSuccess(false);
			}, 800);
		} else {
			return;
		}
	}, [clipSuccess]);
	return (
		<div className="relative box-border flex w-full flex-row items-center justify-center rounded-lg border py-4">
			<div className="flex w-60% flex-row items-center justify-center">
				<div className="flex w-70% flex-col">
					<span className="flex flex-row whitespace-nowrap pr-8 text-sm font-normal">
						{titleCase(userAccount.bankAccount?.accountName.toLocaleLowerCase() || "")}
					</span>
					<span className="text-sm font-normal text-black-tertiary">{titleCase(getBankName(userAccount.bankAccount?.bankCode))}</span>
					<span className="text-sm font-normal text-black-tertiary">{userAccount.bankAccount?.accountNumber}</span>
				</div>
			</div>

			<div className="flex w-40% flex-col items-center justify-center pl-16">
				<Clipboard text={userAccount.bankAccount?.accountNumber || ""} />
			</div>
		</div>
	);
}

export default AddFundsCard;
