import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRootState} from "../../../../../redux/rootReducer";
import MakePayment from "./BulkTransfer/MakePayment";
import PaymentDetails from "./BulkTransfer/PaymentDetails";
import {PaymentStageType} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import ProcessingPayment from "./BulkTransfer/ProcessingPayment";
import ReviewPayment from "./BulkTransfer/ReviewPayment";
import {resetAllBulkTransferData} from "../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";

function BulkTransfer(): JSX.Element {
	const dispatch = useDispatch();
	const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);

	useEffect(() => {
		return () => {
			dispatch(resetAllBulkTransferData());
		};
	}, []);

	return (
		<>
			{paymentStage === PaymentStageType.PAYMENT_DETAILS && <PaymentDetails />}
			{paymentStage === PaymentStageType.REVIEW_PAYMENT && <ReviewPayment />}
			{paymentStage === PaymentStageType.MAKE_PAYMENT && <MakePayment />}
			{paymentStage === PaymentStageType.PROCESS_PAYMENT && <ProcessingPayment />}
		</>
	);
}

export default BulkTransfer;
