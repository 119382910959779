import {
	IdentificationInterface,
	ResidencyPermitSubForm,
} from "../../modules/Dashboard/Application/services/nigeria-application/registered/signatory.types";

import {GenericObject} from "../../helpers/types";
import NextOfKin from "./nextOfKin";
import Parsers from "../../utils/parsers";
import {immerable} from "immer";
import isNullOrUndefined from "../../utils/isNullOrUndefined";

export default class OtherDirector {
	[immerable] = true;

	constructor(
		public firstName: string,
		public surname: string,
		public otherName: string,
		public title: number,
		public motherMaidenName: string,
		public dateOfBirth: string,
		public gender: number,
		public phones: string[],
		public email: string,
		public bvn: string,
		public role: number | null,
		public otherRoleText: string | null,
		public address: string,
		public nextOfKin: NextOfKin | null,
		public isNigerian: boolean,
		public nonNigerianNationality: string | null,
		public residencyPermit: ResidencyPermitSubForm | null,
		public identification: IdentificationInterface | null
	) {}

	static create(obj: GenericObject): OtherDirector {
		return new OtherDirector(
			Parsers.string(obj.firstName),
			Parsers.string(obj.surname),
			Parsers.string(obj.otherName),
			Parsers.number(obj.title),
			Parsers.string(obj.motherMaidenName),
			Parsers.string(obj.dateOfBirth),
			Parsers.number(obj.gender),
			Parsers.stringArray(obj.phones),
			Parsers.string(obj.email),
			Parsers.string(obj.bvn),
			Parsers.nullableNumber(obj.role),
			Parsers.nullableString(obj.otherRoleText),
			Parsers.string(obj.address),
			Parsers.classObject(obj.nextOfKin, NextOfKin),
			Parsers.boolean(obj.isNigerian),
			obj.nonNigerianNationality === null || obj.nonNigerianNationality === undefined ? null : Parsers.string(obj.nonNigerianNationality),
			isNullOrUndefined(obj.residencyPermit) ? null : (obj.residencyPermit as ResidencyPermitSubForm),
			isNullOrUndefined(obj.identification) ? null : (obj.identification as IdentificationInterface)
		);
	}

	isCompletedBio(): boolean {
		return !!this.surname && !!this.title && (!!this.role || !!this.otherRoleText) && !!this.address;
	}

	bioProgress(): number {
		let completedNumber = 0;
		const totalNumber = 4;
		if (this.surname) {
			completedNumber = completedNumber + 1;
		}
		if (this.title) {
			completedNumber = completedNumber + 1;
		}
		if (!!this.role || !!this.otherRoleText) {
			completedNumber = completedNumber + 1;
		}
		if (this.address) {
			completedNumber = completedNumber + 1;
		}

		return Number((completedNumber / totalNumber) * 100);
	}

	isCompletedNextOfKin(): boolean {
		return (
			!!this.nextOfKin &&
			!!this.nextOfKin.name &&
			!!this.nextOfKin.address &&
			!!this.nextOfKin.phone &&
			// !!this.nextOfKin.state &&
			(!!this.nextOfKin.relationship || !!this.nextOfKin.otherRelationshipText)
		);
	}

	nextOfKinProgress(): number {
		let completedNumber = 0;
		const totalNumber = 4;
		if (this.nextOfKin) {
			if (this.nextOfKin.name) {
				completedNumber = completedNumber + 1;
			}
			if (this.nextOfKin.address) {
				completedNumber = completedNumber + 1;
			}
			if (this.nextOfKin.phone) {
				completedNumber = completedNumber + 1;
			}
			if (!!this.nextOfKin.relationship || !!this.nextOfKin.otherRelationshipText) {
				completedNumber = completedNumber + 1;
			}
		} else {
			return 0;
		}

		return Number((completedNumber / totalNumber) * 100);
	}

	isCompletedIdentification(): boolean {
		return !!this.identification && !!this.identification.type && !!this.identification.fileId;
	}

	identificationProgress(): number {
		let completedNumber = 0;
		const totalNumber = 2;
		if (this.identification) {
			if (this.identification.type) {
				completedNumber = completedNumber + 1;
			}
			if (this.identification.fileId) {
				completedNumber = completedNumber + 1;
			}
		} else {
			return 0;
		}

		return Number((completedNumber / totalNumber) * 100);
	}

	isCompleted(): boolean {
		return this.isCompletedBio() && this.isCompletedNextOfKin() && this.isCompletedIdentification();
	}
}
