import {
	NotificationChannel,
	NotificationType,
	UserRole,
	UserRolesWithApproverAccess,
	UserRolesWithInitiatorAccess,
	UserRolesWithViewAccess,
} from "./userAccount.constants";

import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class UserAccountMeta {
	[immerable] = true;

	constructor(
		public id: string,
		public userAccountId: string,
		public name: string,
		public phones: string[],
		public email: string,
		public isAdmin: boolean,
		public role: UserRole,
		public notificationType: NotificationType,
		public notificationChannels: NotificationChannel[],
		public isInvitePending: boolean,
		public minApprovalAmount: number | null,
		public maxApprovalAmount: number | null
	) {}

	get isInitiatorAndApprover(): boolean {
		return this.role === UserRole.BOTH;
	}

	get isApprover(): boolean {
		return this.role === UserRole.APPROVER || this.role === UserRole.BOTH;
	}

	get isApproverOnly(): boolean {
		return this.role === UserRole.APPROVER;
	}

	get isInitiator(): boolean {
		return this.role === UserRole.INITIATOR || this.role === UserRole.BOTH;
	}

	get isViewerOnly(): boolean {
		return this.role === UserRole.VIEWER || this.role === UserRole.NOTIFICATION_ONLY;
	}

	static create(obj: GenericObject): UserAccountMeta {
		return new UserAccountMeta(
			Parsers.string(obj.id),
			Parsers.string(obj.userAccountId),
			Parsers.string(obj.name),
			Parsers.stringArray(obj.phones),
			Parsers.string(obj.email),
			Parsers.boolean(obj.isAdmin),
			Parsers.number(obj.role),
			Parsers.number(obj.notificationType),
			Parsers.numberArray(obj.notificationChannels),
			Parsers.boolean(obj.isInvitePending),
			Parsers.nullableNumber(obj.minApprovalAmount),
			Parsers.nullableNumber(obj.maxApprovalAmount)
		);
	}

	canView(): boolean {
		return UserRolesWithViewAccess.indexOf(this.role) > -1;
	}

	canInitiate(): boolean {
		return UserRolesWithInitiatorAccess.indexOf(this.role) > -1;
	}

	canApprove(): boolean {
		return UserRolesWithApproverAccess.indexOf(this.role) > -1;
	}
}
