import React, {useCallback, useRef} from "react";

import ButtonComp from "../../../../../../../components/General/Buttons/ButtonComp";
import {FileDrop} from "react-file-drop";
import {ReactComponent as GrayCloseIcon} from "../../../../../../../assets/svg/General/CloseIcon.svg";
import MessageToasts from "../../../../../../../components/General/MessageToasts/MessageToasts";
import {ReactComponent as Preview} from "../../../../../../../assets/svg/General/Icons/preview.svg";
import {ReactComponent as PreviewImage} from "../../../../../../../assets/svg/PreviewImage.svg";
import {TailSpin} from "react-loader-spinner";
import {ToastType} from "../../../../../../../helpers/AppConstants";
import Transaction from "../../../../../../../models/transaction";

interface TransactionDetailCardUploadInterface {
	transactionDetail: Transaction;
	dataType: string;
	isBigger: boolean;
	isUploadingReceipt: boolean;
	isPreviewDownloading: boolean;
	isCancel?: boolean;
	handleCancel?: () => void;
	handleUploadAccountingReceipt: (transaction: Transaction, file: File) => Promise<void>;
	handlePreviewAccountingReceipt: (transaction: Transaction) => Promise<void>;
}

function TransactionDetailCardUpload({
	transactionDetail,
	dataType,
	isBigger,
	isCancel,
	isUploadingReceipt,
	// isPreviewDownloading,
	handleCancel = undefined,
	handleUploadAccountingReceipt,
	handlePreviewAccountingReceipt,
}: TransactionDetailCardUploadInterface): JSX.Element {
	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const onSelectFile = useCallback(
		(file: File) => {
			void handleUploadAccountingReceipt(transactionDetail, file);
		},
		[handleUploadAccountingReceipt, transactionDetail]
	);

	const handlePreview = useCallback(() => {
		void handlePreviewAccountingReceipt(transactionDetail);
	}, [handlePreviewAccountingReceipt, transactionDetail]);

	const onTargetClick = () => {
		fileInputRef.current && fileInputRef.current.click();
	};

	return (
		<>
			{(transactionDetail.receiptUploaded || transactionDetail.invoiceUploaded) && (
				<div
					className={"flex w-full flex-col items-start justify-start space-y-4 py-6 " + `${isCancel ? "" : "border-t-0.2 border-solid"}`}
					data-type={dataType}
				>
					{!isCancel && (
						<span className="text-sm font-medium text-black-secondary" data-type={dataType}>
							Transaction Attachment
						</span>
					)}
					<div className="w-full pb-8 text-black-secondary" data-type={dataType}>
						<div
							className="flex h-14 w-full flex-row items-center justify-between space-x-2 rounded-lg border border-solid px-4 text-black-tertiary"
							data-type={dataType}
						>
							<div className="flex flex-row items-center justify-start" data-type={dataType}>
								<div data-type={dataType}>
									<div className="h-10 w-10 rounded-lg" data-type={dataType}>
										<div className="flex h-full w-full items-center justify-center" data-type={dataType}>
											<PreviewImage data-type={dataType} />
										</div>
									</div>
								</div>
								{isCancel ? (
									<span className="ml-4 text-sm" data-type={dataType}>
										Document/Receipt Name
									</span>
								) : (
									<span className="ml-4 text-sm" data-type={dataType}>
										Preview transaction attachment
									</span>
								)}
							</div>

							{/* <div>{isPreviewDownloading && <TailSpin color="#5466F9" height={20} width={20} />}</div> */}
							{isCancel ? (
								<div data-type={dataType}>
									<ButtonComp
										buttonType="tertiary"
										color="blue"
										func={handleCancel}
										size="sm"
										data-type={dataType}
										dataType={dataType}
									>
										<GrayCloseIcon className="h-3.5 w-3.5 stroke-current text-black-tertiary" data-type={dataType} />
									</ButtonComp>
								</div>
							) : (
								<div data-type={dataType}>
									<ButtonComp
										buttonType="tertiary"
										color="blue"
										func={handlePreview}
										size="sm"
										data-type={dataType}
										dataType={dataType}
									>
										<span className="text-sm" data-type={dataType}>
											Preview
										</span>
									</ButtonComp>
								</div>
							)}
						</div>
					</div>
				</div>
			)}
			{!(transactionDetail.receiptUploaded || transactionDetail.invoiceUploaded) && (
				<div className={"w-full pb-8 text-black-secondary " + `${transactionDetail.isFailed ? "mt-2" : ""}`} data-type={dataType}>
					{isBigger && (
						<div className="pb-4">
							<MessageToasts toastType={ToastType.ERROR} toastMessage={"File exceeded 5MB"} />
						</div>
					)}

					<div className="relative flex w-full cursor-pointer flex-col items-center justify-start space-y-2 rounded-lg border border-dashed hover:bg-blue-senary">
						{isUploadingReceipt && (
							<div
								className={`absolute left-0 top-0 flex h-full w-full items-center justify-center bg-white ${
									isUploadingReceipt ? "opacity-100" : "pointer-events-none opacity-0"
								}`}
							>
								<TailSpin color="#5466F9" height={20} width={20} />
							</div>
						)}
						<FileDrop
							className="h-fit"
							onTargetClick={onTargetClick}
							onDrop={(files: FileList | null) => {
								if (files && files.length > 0) {
									onSelectFile(files[0]);
								}
							}}
						>
							<input
								type="file"
								ref={fileInputRef}
								className="hidden"
								onChange={(event) => {
									if (event.target.files && event.target.files.length > 0) {
										onSelectFile(event.target.files[0]);
									}
								}}
								accept="image/jpeg, image/png, application/pdf, .pdf"
							/>

							<div className="flex w-full flex-row items-center justify-start px-4 py-1">
								<div>
									<div className="h-10 w-10 rounded-lg bg-blue-senary ">
										<div className="flex h-full w-full items-center justify-center">
											<Preview className="h-5 w-5 stroke-current text-blue " />
										</div>
									</div>
								</div>
								<span className="pointer-events-none ml-4 text-left text-sm text-black-tertiary">
									Click here to attach or Drag and drop the transaction document. Max file size 5MB
								</span>
							</div>
						</FileDrop>
					</div>
				</div>
			)}
		</>
	);
}

export default TransactionDetailCardUpload;
