import PendingApproval from "..";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import UseDeclinePendingPayment from "../../../../Hooks/State/PendingApproval/useDeclinePendingPayment";

interface Props {
	transaction: Transaction;
	handleShowModal: () => void;
	handleUpdateState: () => void;
}

function StandAlonePendingApproval(props: Props): JSX.Element {
	const {isApprove, declineReason, isDeclineTransactionLoading, handleIsApprove, handleDeclineTransaction} = UseDeclinePendingPayment({
		onComplete: () => props.handleUpdateState(),
	});

	return (
		<>
			<PendingApproval
				isApproved={!!isApprove}
				isDeclined={!!declineReason}
				transaction={props.transaction}
				declinedReason={declineReason || undefined}
				handleApprovePayment={() => {
					handleIsApprove();
					props.handleShowModal();
				}}
				isDeclineTransactionLoading={isDeclineTransactionLoading}
				handleSelectOrChangeDeclineReason={({reason, transaction}) =>
					handleDeclineTransaction({reason: reason, transactionId: transaction.id})
				}
			/>
		</>
	);
}

export default StandAlonePendingApproval;
