import {CardSpendLimitType} from "./card.constants";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class CardSpend {
	[immerable] = true;

	constructor(public type: CardSpendLimitType, public limit: string, public spent: string) {}

	static create(obj: GenericObject): CardSpend {
		return new CardSpend(Parsers.number(obj.type), Parsers.string(obj.limit), Parsers.string(obj.spent));
	}
}
