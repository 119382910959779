import {Link, useLocation} from "react-router-dom";
import React, {useEffect, useState} from "react";

import ButtonComp from "../../General/Buttons/ButtonComp";
import {canShowPreReleaseFeatures} from "../../../utils/preReleaseConfig";

interface Props {
	onClick(e: React.MouseEvent): void;
	path: string;
	icon: React.ReactElement;
	text: string;
	isPreRelease?: boolean;
	hasSubRoutes?: boolean;
	isNew?: boolean;
	isFill?: boolean;
}

function MenuItem({onClick, path, icon, text, isFill, isNew = false, hasSubRoutes = false, isPreRelease = false}: Props): JSX.Element {
	if (isPreRelease && !canShowPreReleaseFeatures) {
		return <></>;
	}

	const [active, setActive] = useState(false);
	const location = useLocation();

	useEffect(() => {
		setActive(location.pathname === path || (hasSubRoutes && location.pathname.includes(path)));
	}, [location.pathname]);

	return (
		<Link
			to={{
				pathname: path,
				state: {
					pageFrom: location.pathname,
				},
			}}
			onClick={onClick}
			data-type="section"
		>
			<div className={` ${active ? "" : "hover:bg-white "}`} data-type="section" tabIndex={-1}>
				<ButtonComp
					ripple="dark"
					color="transparent"
					type="button"
					buttonType="primary"
					data-type="section"
					className="pl-6"
					noTabIndex
					fullWidth
				>
					<div
						className={
							`relative flex h-9 w-full items-center justify-start tracking-normal ` +
							`${active ? "font-medium text-blue  antialiased" : ""}`
						}
						data-type="section"
						tabIndex={-1}
					>
						{React.cloneElement(icon, {className: `${isFill ? "fill-current" : "stroke-current"}`, "data-type": "section", tabIndex: -1})}
						<div className="ml-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm" tabIndex={-1} data-type="section">
							{text}
						</div>
						{isNew && (
							<div className="rounded bg-blue px-1 select-none ml-2 leading-none py-0.5">
								<span className="text-white font-medium text-xs">New</span>
							</div>
						)}
					</div>
				</ButtonComp>
			</div>
		</Link>
	);
}

export default MenuItem;
