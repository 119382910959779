import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {PaymentStageType, PaymentType, SendMoneyState} from "./sendMoneySlice.types";

const initialState: SendMoneyState = {
	paymentStage: PaymentStageType.INITIAL,
	paymentType: PaymentType.SINGLE_TRANSFER,
	payFrom: "",
	payTo: "",
	pageFrom: "",
	temp: false,
};

export const sendMoneySlice = createSlice({
	name: "sendMoney",
	initialState,
	reducers: {
		setPaymentStage: (state: SendMoneyState, action: PayloadAction<PaymentStageType>) => {
			state.paymentStage = action.payload;
		},
		setPaymentType: (state: SendMoneyState, action: PayloadAction<PaymentType>) => {
			state.paymentType = action.payload;
		},
		setPayFrom: (state: SendMoneyState, action: PayloadAction<string>) => {
			state.payFrom = action.payload;
		},
		setPayTo: (state: SendMoneyState, action: PayloadAction<string>) => {
			state.payTo = action.payload;
		},
		setPageFrom: (state: SendMoneyState, action: PayloadAction<string>) => {
			state.pageFrom = action.payload;
		},
		// setTempPayFrom: (state: SendMoneyState, action: PayloadAction<string>) => {
		// state.payFrom = action.payload;
		// state.temp = true;
		// },
		// setTempPayTo: (state: SendMoneyState, action: PayloadAction<string>) => {
		// state.payTo = action.payload;
		// state.temp = true;
		// },
		setTempTrue: (state: SendMoneyState) => {
			state.temp = true;
		},
		resetAllTempSendMoneyData: (state: SendMoneyState) => {
			state.payFrom = "";
			state.payTo = "";
			state.pageFrom = "";
			state.temp = false;
		},
		resetAllSendMoneyData: (state: SendMoneyState) => {
			state.paymentStage = PaymentStageType.INITIAL;
			state.paymentType = PaymentType.SINGLE_TRANSFER;
			state.payFrom = "";
			state.payTo = "";
			state.pageFrom = "";
			state.temp = false;
		},
	},
});

export const {
	setPaymentStage,
	setPaymentType,
	setPayFrom,
	setPayTo,
	setPageFrom,
	setTempTrue,
	// setTempPayFrom,
	// setTempPayTo,
	resetAllTempSendMoneyData,
	resetAllSendMoneyData,
} = sendMoneySlice.actions;

export default sendMoneySlice.reducer;
