import {AddMoneyFundingStageType, AddMoneyFundingType} from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import {ZambiaCollectionPaymentChannel, ZambiaCollectionStatus} from "../../../../../../models/zambia/zambiaCollectionRequest.constant";

import {DropdownItem} from "../../../../../../helpers/types";

export const BankTransferSections: {
	[value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
} = {
	[AddMoneyFundingStageType.INITIAL]: {
		value: AddMoneyFundingStageType.INITIAL,
		text: "",
		subtext: "",
	},
	[AddMoneyFundingStageType.PAYMENT_DETAILS]: {
		value: AddMoneyFundingStageType.PAYMENT_DETAILS,
		text: "Add Money",
		subtext: "You can add money to your account via Bank Transfer",
	},
	[AddMoneyFundingStageType.MAKE_PAYMENT]: {
		value: AddMoneyFundingStageType.MAKE_PAYMENT,
		text: "",
		subtext: "",
	},
	[AddMoneyFundingStageType.PROCESS_PAYMENT]: {
		value: AddMoneyFundingStageType.PROCESS_PAYMENT,
		text: "",
		subtext: "",
	},
};

export const MobileMoneySections: {
	[value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
} = {
	[AddMoneyFundingStageType.INITIAL]: {
		value: AddMoneyFundingStageType.INITIAL,
		text: "",
		subtext: "",
	},
	[AddMoneyFundingStageType.PAYMENT_DETAILS]: {
		value: AddMoneyFundingStageType.PAYMENT_DETAILS,
		text: "Add Money",
		subtext: "You can add money to your account via Mobile Money",
	},
	[AddMoneyFundingStageType.MAKE_PAYMENT]: {
		value: AddMoneyFundingStageType.MAKE_PAYMENT,
		text: "",
		subtext: "",
	},
	[AddMoneyFundingStageType.PROCESS_PAYMENT]: {
		value: AddMoneyFundingStageType.PROCESS_PAYMENT,
		text: "",
		subtext: "",
	},
};

export const CardPaymentSections: {
	[value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
} = {
	[AddMoneyFundingStageType.INITIAL]: {
		value: AddMoneyFundingStageType.INITIAL,
		text: "",
		subtext: "",
	},
	[AddMoneyFundingStageType.PAYMENT_DETAILS]: {
		value: AddMoneyFundingStageType.PAYMENT_DETAILS,
		text: "Add Money",
		subtext: "You can add money to your account using your Card",
	},
	[AddMoneyFundingStageType.MAKE_PAYMENT]: {
		value: AddMoneyFundingStageType.MAKE_PAYMENT,
		text: "",
		subtext: "",
	},
	[AddMoneyFundingStageType.PROCESS_PAYMENT]: {
		value: AddMoneyFundingStageType.PROCESS_PAYMENT,
		text: "",
		subtext: "",
	},
	// [AddMoneyFundingStageType.REVIEW_PAYMENT]: {
	// value: AddMoneyFundingStageType.REVIEW_PAYMENT,
	// text: "Review your purchase",
	// subtext: "Confirm the airtime purchase details",
	// },
};

export const AddMoneySections =
	//: {
	// [value in AddMoneyFundingStageType]: DropdownItem<AddMoneyFundingStageType>;
	// }
	{
		[AddMoneyFundingType.BANK_TRANSFER]: BankTransferSections,
		// [AddMoneyFundingType.CARD_PAYMENT]: CardPaymentSections,
		[AddMoneyFundingType.MOBILE_MONEY]: MobileMoneySections,
	};

export enum RequestCollectionRequestStage {
	STAGE_ONE = 1,
	STAGE_TWO = 2,
	STAGE_THREE = 3,
}

export enum ZambiaCollectionRequestFilters {
	DATE = "date",
	STATUS = "status",
	CHANNEL = "channel",
}

export const ZambiaCollectionRequestFiltersArray = [
	ZambiaCollectionRequestFilters.DATE,
	ZambiaCollectionRequestFilters.STATUS,
	ZambiaCollectionRequestFilters.CHANNEL,
];

export const ZambiaCollectionRequestFiltersText = {
	[ZambiaCollectionRequestFilters.DATE]: "Date",
	[ZambiaCollectionRequestFilters.STATUS]: "Status",
	[ZambiaCollectionRequestFilters.CHANNEL]: "Channel",
};

export const ZambiaCollectionRequestStatusArray = [
	ZambiaCollectionStatus.FAILED,
	ZambiaCollectionStatus.EXPIRED,
	ZambiaCollectionStatus.PENDING,
	ZambiaCollectionStatus.CANCELLED,
	ZambiaCollectionStatus.SUCCESSFUL,
];

export const getZambiaCollectionRequestStatusName = (_status: ZambiaCollectionStatus): string => {
	switch (_status) {
		case ZambiaCollectionStatus.FAILED:
			return "Failed";
		case ZambiaCollectionStatus.EXPIRED:
			return "Expired";
		case ZambiaCollectionStatus.PENDING:
			return "Pending";
		case ZambiaCollectionStatus.CANCELLED:
			return "Cancelled";
		case ZambiaCollectionStatus.SUCCESSFUL:
			return "Successful";
		default:
			return "";
	}
};

export const ZambiaCollectionRequestStatusOptions = ZambiaCollectionRequestStatusArray.map((_status) => ({
	name: getZambiaCollectionRequestStatusName(_status),
	value: _status,
}));

export const ZambiaCollectionRequestChannelArray = [
	ZambiaCollectionPaymentChannel.CARD,
	ZambiaCollectionPaymentChannel.BANK_ACCOUNT,
	ZambiaCollectionPaymentChannel.MOBILE_MONEY,
];

export const getZambiaCollectionChannelName = (_status: ZambiaCollectionPaymentChannel): string => {
	switch (_status) {
		case ZambiaCollectionPaymentChannel.CARD:
			return "Card";
		case ZambiaCollectionPaymentChannel.BANK_ACCOUNT:
			return "Bank Account";
		case ZambiaCollectionPaymentChannel.MOBILE_MONEY:
			return "Mobile Money";
		default:
			return "";
	}
};

export const ZambiaCollectionRequestChannelOptions = ZambiaCollectionRequestChannelArray.map((_status) => ({
	name: getZambiaCollectionChannelName(_status),
	value: _status,
}));

export const ZambiaCollectionRequestFiltersDefaultValues = {
	[ZambiaCollectionRequestFilters.STATUS]: ZambiaCollectionStatus.PENDING,
	[ZambiaCollectionRequestFilters.CHANNEL]: ZambiaCollectionPaymentChannel.CARD,
};

export const ZambiaCollectionRequestFiltersOptions: {
	[type in ZambiaCollectionRequestFilters]:
		| {
				name: string;
				value: ZambiaCollectionStatus | ZambiaCollectionPaymentChannel;
		  }[]
		| undefined;
} = {
	[ZambiaCollectionRequestFilters.STATUS]: ZambiaCollectionRequestStatusOptions,
	[ZambiaCollectionRequestFilters.CHANNEL]: ZambiaCollectionRequestChannelOptions,
	[ZambiaCollectionRequestFilters.DATE]: undefined,
};

export const ZambiaCollectionRequestFiltersData = ZambiaCollectionRequestFiltersArray.map((_collectionRequest) => ({
	name: ZambiaCollectionRequestFiltersText[_collectionRequest],
	value: _collectionRequest,
	options: ZambiaCollectionRequestFiltersOptions[_collectionRequest],
}));
