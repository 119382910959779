import {ExternalDirectorSubPageType, PersonalDetailsPageType} from "../../service/director.constants";

import DirectorPersonalDetails from "./DirectorPersonalDetails/DirectorPersonalDetails";
import React from "react";

interface Props {
	stage: ExternalDirectorSubPageType;
}

function PersonalDetails(props: Props): JSX.Element {
	return (
		<>
			<div className="flex flex-row justify-start items-start h-full w-full">
				{/* {props.stage.trim() === PersonalDetailsPageType.BVN && (
					<DirectorBVN urlData={props.urlData} director={props.director} handleUpdateDirector={props.handleUpdateDirector} />
				)} */}
				{props.stage.trim() === PersonalDetailsPageType.PERSONAL_DETAILS && <DirectorPersonalDetails />}
			</div>
		</>
	);
}

export default PersonalDetails;
