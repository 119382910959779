import {GenericObject} from "../../../helpers/types";
import Parsers from "../../../utils/parsers";

export default class AccountOpeningProgressGeneric {
	constructor(public total: number, public completed: number) {}

	static create(obj: GenericObject): AccountOpeningProgressGeneric {
		return new AccountOpeningProgressGeneric(Parsers.number(obj.total), Parsers.number(obj.completed));
	}

	isCompleted(): boolean {
		return this.total === this.completed;
	}
	progressPercentage(): number {
		if (this.total === 0) return 0;
		return Number((this.completed / this.total) * 100);
	}
}
