import {
	NigeriaBusinessAccountManagerResponse,
	UpdateNigeriaBusinessAccountTypeRequest,
	UpdateNigeriaBusinessAccountTypeResponse,
	UpdateNigeriaBusinessTypeRequest,
	UpdateNigeriaBusinessTypeResponse,
} from "./nigeria-application.types";
import {abortRequest, getAbortControllerSignal} from "../../../../../helpers/request/abortControllers";
import {makeRequest, makeRequestWithSignal} from "../../../../../helpers/request/makeRequest";

import BusinessDetailsMin from "../../../../../models/account-opening/businessDetailsMin";
import CreatedBy from "../../../../../models/account-opening/createdBy";
import {GenericObject} from "../../../../../helpers/types";
import Industry from "../../../../../models/meta/industry";
import KinRelationship from "../../../../../models/meta/kinRelationship";
import MetaEntity from "../../../../../models/meta/metaEntity";
import {NewAccountOpeningInitState} from "../application.types";
import NewAccountOpeningProgress from "../../../../../models/account-opening/progress/newAccountOpeningProgress";
import Parsers from "../../../../../utils/parsers";
import User from "../../../../../models/user";

export enum NigeriaAccountOpeningInitRequests {
	INIT = "nigeria-account-opening-init.init",
	ACCOUNT_MANAGER = "nigeria-account-opening-init.account-manager",
	UPDATE_BUSINESS_TYPE = "nigeria-account-opening-init.update-business-type",
	UPDATE_BUSINESS_ACCOUNT_TYPE = "nigeria-account-opening-init.update-business-account-type",
}

export async function nigeriaAccountOpeningInit(): Promise<NewAccountOpeningInitState> {
	const res = await makeRequest("/account-opening/ng/init");

	if (res instanceof Error) {
		throw res;
	}
	return {
		progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
		user: Parsers.classObjectNonNullable(res.user, User),
		applicationMin: Parsers.classObjectNonNullable(res.applicationMin, BusinessDetailsMin),
		isCreator: Parsers.boolean(res.isCreator),
		createdBy: Parsers.classObjectNonNullable(res.createdBy, CreatedBy),
		options: {
			gender: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).gender, MetaEntity),
			turnover: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).turnover, MetaEntity),
			nameTitle: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).nameTitle, MetaEntity),
			idType: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).idType, MetaEntity),
			role: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).role, MetaEntity),
			utilityBillType: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).utilityBillType, MetaEntity),
			registrationCategory: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).registrationCategory, MetaEntity),
			industry: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).industry, Industry),
			kinRelationship: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).kinRelationship, KinRelationship),
		},
	};
}
export async function updateNigeriaBusinessType(_data: UpdateNigeriaBusinessTypeRequest): Promise<UpdateNigeriaBusinessTypeResponse> {
	const signal = getAbortControllerSignal(NigeriaAccountOpeningInitRequests.UPDATE_BUSINESS_TYPE);
	const res = await makeRequestWithSignal("/account-opening/ng/registration-status/update", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		applicationMin: Parsers.classObjectNonNullable(res.applicationMin, BusinessDetailsMin),
	};
}
export async function nigeriaBusinessAccountManger(): Promise<NigeriaBusinessAccountManagerResponse> {
	const signal = getAbortControllerSignal(NigeriaAccountOpeningInitRequests.ACCOUNT_MANAGER);
	const res = await makeRequestWithSignal("/account-opening/ng/account-manager/details", {}, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		name: Parsers.string(res.name),
		email: Parsers.string(res.email),
		phone: Parsers.string(res.phone),
		photo: Parsers.string(res.photo),
	};
}

export async function updateNigeriaBusinessAccountType(
	_data: UpdateNigeriaBusinessAccountTypeRequest
): Promise<UpdateNigeriaBusinessAccountTypeResponse> {
	const signal = getAbortControllerSignal(NigeriaAccountOpeningInitRequests.UPDATE_BUSINESS_ACCOUNT_TYPE);
	const res = await makeRequestWithSignal("/account-opening/ng/requested-account-type/update", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		applicationMin: Parsers.classObjectNonNullable(res.applicationMin, BusinessDetailsMin),
	};
}

export function abortNigeriaAccountOpeningInitRequest(type: NigeriaAccountOpeningInitRequests): void {
	abortRequest(type);
}

export function abortAllNigeriaAccountOpeningInitRequests(): void {
	Object.values(NigeriaAccountOpeningInitRequests).forEach((type) => abortRequest(type));
}
