import Parsers from "../../utils/parsers";
import {GenericObject} from "../../helpers/types";
import {immerable} from "immer";

export default class AccountFaqMin {
	[immerable] = true;

	constructor(public questionId: string, public answerId: string) {}

	static create(obj: GenericObject): AccountFaqMin {
		return new AccountFaqMin(Parsers.string(obj.questionId), Parsers.string(obj.answerId));
	}
}
