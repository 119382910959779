import BankTransfer from "../../../assets/svg/bank-transfer.svg";
// import CardPayment from "../../../assets/svg/card-payment.svg";
import CreateAccountRequest from "../../../models/createAccountRequest";
import MobileMoney from "../../../assets/svg/mobile-money.svg";
import UserAccount from "../../../models/userAccount";
import {getAccountSummaryResponse} from "../../../modules/Dashboard/Accounts/Services/accountsApi.types";

export enum AccountModalStageType {
	INITIAL = 0,
	DETAILS = 1,
	ACTIVATE = 2,
}

export enum ZambiaAddMoneyModalSections {
	ACCOUNT_AND_METHOD = 1,
	FUNDING = 2,
}

export enum ZambiaFundingMethod {
	BANK_TRANSFER = 1,
	MOBILE_MONEY = 2,
	CARD_PAYMENT = 3,
}

export const ZambiaFundingMethods = [
	{
		id: ZambiaFundingMethod.MOBILE_MONEY,
		name: "Mobile Money",
		icon: MobileMoney as string,
	},
	// {
	// id: ZambiaFundingMethod.CARD_PAYMENT,
	// name: "Card Payment",
	// icon: CardPayment as string,
	// },
	{
		id: ZambiaFundingMethod.BANK_TRANSFER,
		name: "Bank Transfer",
		icon: BankTransfer as string,
	},
];

export interface AccountState {
	individualAccount: UserAccount | null;
	individualAccountSummary: getAccountSummaryResponse | null;

	accounts: string[];
	subAccountName: string;
	subAccountDescription: string;
	subAccountModalStage: AccountModalStageType;

	isAddFundsModalOpen: boolean;
	isZambiaAddFundsModalOpen: boolean;
	isAboutMyAccountModalOpen: boolean;
	isEditAccountDescriptionModalOpen: boolean;

	pendingAccounts: CreateAccountRequest[];

	listOfSubAccountsToBeActivated: string[];
	listOfSubAccountsToBeCreated: CreateAccountObj[];
	pendingSubAccountsToBeActivatedList: CreateAccountRequest[];
}

export type CreateAccountObj = {
	name: string;
	description: string;
	canDelete: boolean;
	index: number;
	isLoading: boolean;
};
