import {useCallback, useEffect, useRef, useState} from "react";

import Collection from "../../../../../../../models/collection";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {mobileMoneyStatus} from "../../../../Services/Zambia/mobileMoney";
import {useDispatch} from "react-redux";

export default function useMobileMoneyStatus(key: string): {transaction: Collection | null} {
	const dispatch = useDispatch();

	const [transaction, setTransaction] = useState<Collection | null>(null);

	const pollingRef = useRef<ReturnType<typeof setInterval> | null>();

	useEffect(() => {
		if (!key)
			return () => {
				if (pollingRef.current) {
					clearInterval(pollingRef.current);
				}
			};

		void handleGetTransaction();

		pollingRef.current = setInterval(() => {
			void handleGetTransaction();
		}, 10000);
		return () => {
			if (pollingRef.current) {
				clearInterval(pollingRef.current);
			}
		};
	}, [key]);

	const handleGetTransaction = useCallback(async (): Promise<void> => {
		if (key) {
			try {
				const updatedTransaction = await mobileMoneyStatus({key});
				setTransaction(updatedTransaction.mobileMoneyCollection);
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			}
		}
	}, [dispatch]);

	return {transaction};
}
