import React, {useCallback, useEffect} from "react";
import {
	removeTransferDetailToTransferList,
	resetAllBulkTransferData,
	setBulkTransfersAmountTotal,
	setCanVerifyRecipientAccountDetails,
	setIsUpdatingTransferDetail,
	setTransferDetail,
	setTransferDetailIndex,
} from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import {useDispatch, useSelector} from "react-redux";

import AddRecipients from "../../Modals/AddRecipients";
import BulkPaymentHeaders from "./BulkPaymentHeaders";
import BulkTransferDetailRow from "../../TableRows/BulkTransferDetailRow";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import {ERROR} from "../../../../../../helpers/AppConstants";
import {IRootState} from "../../../../../../redux/rootReducer";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {ReactComponent as Plus} from "../../../../../../assets/images/plus.svg";
import {TransferDetail} from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import {ReactComponent as Upload} from "../../../../../../assets/images/smallUpload.svg";
import UploadModal from "../../Modals/UploadModal";
import formatNumber from "../../../../../../utils/formatNumber";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import useBulkTransferRecipientModal from "../../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferRecipientModal";
import useBulkTransferUploadModal from "../../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferUploadModal";
import {useMoneyToNumber} from "../../../../../../hooks/useMoneyToNumber";

function PaymentDetails(): JSX.Element {
	const dispatch = useDispatch();

	const {
		handleBulkUpload,
		handleCloseShowUploadModal,
		handleDownloadTemplate,
		handleShowUploadModal,
		isBulkUploadLoading,
		isDownloadTemplateLoading,
		showUploadModal,
	} = useBulkTransferUploadModal();

	const {showRecipientModal, handleCloseRecipientModal, handleShowRecipientModal} = useBulkTransferRecipientModal();

	const transferDetailArray = useSelector((state: IRootState) => state.bulkTransfer.transferDetailArray);
	const bulkTransfersAmountTotal = useSelector((state: IRootState) => state.bulkTransfer.bulkTransfersAmountTotal);

	useEffect(() => {
		if (transferDetailArray.length < 1) return;
		dispatch(
			setBulkTransfersAmountTotal(
				transferDetailArray.reduce((acc, cur) => {
					if (cur.amount === ERROR) return acc;
					return acc + useMoneyToNumber(cur.amount);
				}, 0)
			)
		);
	}, [transferDetailArray]);

	const handleEditTransfer = useCallback((_data: TransferDetail) => {
		dispatch(setIsUpdatingTransferDetail(true));
		dispatch(setTransferDetail(_data));
		dispatch(setCanVerifyRecipientAccountDetails(false));
		handleShowRecipientModal();
	}, []);

	const handleRemoveTransfer = useCallback((_dataIndex: number) => {
		dispatch(removeTransferDetailToTransferList(_dataIndex));
	}, []);

	const handleBack = useCallback(() => {
		dispatch(setPaymentStage(PaymentStageType.INITIAL));
		dispatch(resetAllBulkTransferData());
	}, []);

	return (
		<>
			<UploadModal
				active={showUploadModal}
				toggler={handleCloseShowUploadModal}
				uploadFunc={handleBulkUpload}
				isDownloadTemplateLoading={isDownloadTemplateLoading}
				handleDownloadTemplate={handleDownloadTemplate}
				isLoading={isBulkUploadLoading}
			/>

			<AddRecipients showAboutModal={showRecipientModal} toggler={handleCloseRecipientModal} />
			<div className="flex h-full max-h-full w-full max-w-full flex-col">
				<div className="relative flex h-full w-full flex-col">
					<div className="absolute left-0 top-0 flex h-full max-h-full w-full flex-col overflow-y-auto">
						<BulkPaymentHeaders />
						<div className="flex h-full w-full flex-col ">
							<div className="flex w-full items-end justify-between pb-6">
								<div
									className={`flex w-max flex-row items-start  justify-start text-base font-normal ${
										transferDetailArray.length > 0 ? "mr-10" : ""
									}`}
								>
									{" "}
									{transferDetailArray && transferDetailArray.length > 0 && (
										<span className="w-max">
											Total: <CurrencyCode />
											{formatNumber(bulkTransfersAmountTotal)}
										</span>
									)}{" "}
								</div>

								<div className="flex w-full flex-col items-end justify-end space-x-0 space-y-4 2xs:flex-row 2xs:space-x-4 2xs:space-y-0">
									<ButtonComp
										type="button"
										ripple="light"
										buttonType="secondary"
										color="blue"
										size="md"
										func={handleShowUploadModal}
									>
										<div className="flex flex-row items-center space-x-1 ">
											<Upload className="stroke-current" />
											<span>Upload payments</span>
										</div>
									</ButtonComp>
									<ButtonComp
										type="button"
										ripple="light"
										buttonType="secondary"
										color="blue"
										size="md"
										func={() => {
											dispatch(setTransferDetailIndex());
											handleShowRecipientModal();
										}}
									>
										<div className="flex flex-row items-center space-x-1 ">
											<Plus className="stroke-current" />
											<span>Add Transfer</span>
										</div>
									</ButtonComp>
								</div>
							</div>

							<div className="relative flex h-full w-full flex-1 flex-shrink flex-grow">
								<div className="relative flex h-full w-full flex-1">
									<div className="flex h-fit w-full flex-1 overflow-x-auto">
										<div className="left-0 top-0 h-full w-full">
											<table className="tableT w-full">
												<thead className="tableheader relative z-10 rounded-bl-2xl border-grey-backdrop text-left text-sm text-black">
													<tr className="relative">
														<th className="tableTh1 px-6 py-3 font-normal">FROM</th>
														<th className="px-6 py-3 font-normal">TO</th>
														<th className="px-6 py-3 font-normal ">PURPOSE</th>
														<th className="px-6 py-3 font-normal ">AMOUNT</th>
														<th className="tableTh2 px-6 py-3 font-normal"></th>
													</tr>
												</thead>
												<tbody className="w-full">
													{transferDetailArray &&
														transferDetailArray.map((el, index) => (
															<BulkTransferDetailRow
																editFunc={handleEditTransfer}
																removeFunc={handleRemoveTransfer}
																data={el}
																key={index}
																reviewMode
															/>
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className=" my-6 flex flex-row items-center justify-center space-x-4">
					<div>
						<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={handleBack}>
							Back
						</ButtonComp>
					</div>
					<div>
						<ButtonComp
							type="submit"
							color="blue"
							ripple="light"
							buttonType="primary"
							func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
							disable={
								!!(
									transferDetailArray.length < 1 ||
									transferDetailArray.some((el) => el.originatingAccountId === ERROR) ||
									transferDetailArray.some((el) => el.purpose === ERROR) ||
									transferDetailArray.some((el) => el.amount === ERROR) ||
									transferDetailArray.some((el) => isNullOrUndefined(el.recipientBankAccount))
								)
							}
						>
							Next
						</ButtonComp>
					</div>
				</div>
			</div>
		</>
	);
}

export default PaymentDetails;
