import {AddMoneyFundingStageType, AddMoneyPhase} from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import React, {useEffect, useState} from "react";
import {setAddMoneyFundingStage, setAddMoneyPhase} from "../../../../../../redux/payments/addMoney/slice/addMoneySlice";

import AddMoneyHeader from "../../Layouts/AddMoney/Components/AddMoneyHeader";
import MobileMoneyMakePayment from "./Component/MakePayment";
import MobileMoneyPaymentDetails from "./Component/PaymentDetails";
import MobileMoneyProcessPayment from "./Component/ProcessPayment";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";

interface Props {
	selectedAccountId: string;
	handleBack: () => void;
	handleClose: () => void;
}

const enum MobileMoneyStage {
	PAYMENT_DETAILS = "payment-details",
	MAKE_PAYMENT = "make-payment",
	PROCESSING = "processing",
}

function MobileMoney(props: Props): JSX.Element {
	const history = useHistory();
	const dispatch = useDispatch();

	const [key, setKey] = useState(`${Math.floor(Math.random() * 100000000000000) + 1}`);
	const [stage, setStage] = useState(MobileMoneyStage.PAYMENT_DETAILS);
	const [phoneNumber, setPhoneNumber] = useState("");

	useEffect(() => {
		if (!props.selectedAccountId) {
			dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.INITIAL));
			dispatch(setAddMoneyPhase(AddMoneyPhase.ACCOUNT_AND_METHOD));
			history.push("/payments/add-money/make");
		}
	}, [props.selectedAccountId]);

	const handleUpdateState = (_key: string, _phoneNumber: string) => {
		setKey(_key);
		setPhoneNumber(_phoneNumber);
	};

	return (
		<>
			<div className="max-w-sm w-full flex flex-col">
				{stage !== MobileMoneyStage.PROCESSING && <AddMoneyHeader />}

				<div className="w-full flex flex-col items-center justify-start space-y-16">
					{stage === MobileMoneyStage.PAYMENT_DETAILS && (
						<MobileMoneyPaymentDetails
							keyId={key}
							selectedAccountId={props.selectedAccountId}
							handleBack={props.handleBack}
							handleNext={() => setStage(MobileMoneyStage.MAKE_PAYMENT)}
							handleLast={() => setStage(MobileMoneyStage.PROCESSING)}
							handleUpdateKeyId={() => setKey(`${Math.floor(Math.random() * 100000000000000) + 1}`)}
							handleUpdatePhoneNumber={(_value) => setPhoneNumber(_value)}
						/>
					)}
					{stage === MobileMoneyStage.MAKE_PAYMENT && (
						<MobileMoneyMakePayment
							keyId={key}
							recipientPhoneNumber={phoneNumber}
							handleBack={() => setStage(MobileMoneyStage.PAYMENT_DETAILS)}
							handleNext={() => setStage(MobileMoneyStage.PROCESSING)}
						/>
					)}
					{stage === MobileMoneyStage.PROCESSING && (
						<MobileMoneyProcessPayment keyId={key} handleClose={props.handleClose} handleUpdateState={() => handleUpdateState("", "")} />
					)}
				</div>
			</div>
		</>
	);
}

export default MobileMoney;
