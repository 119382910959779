import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {TransactionDateType, TransactionDateTypes, TransactionFilterTypes, TransactionState} from "./transactionsSlice.types";
import {TransactionStatus, TransactionType} from "../../../models/transaction.constants";

import Transaction from "../../../models/transaction";

const initialState: TransactionState = {
	transactionList: null,
	isTransactionPaginationLoading: false,
	transactionPaginationOffset: 0,
	transactionsListTotal: 0,
	isExportZambiaTransactionsTrayOpen: false,
	transactionsListGroupSize: 0,
	isExportTransactionsLoading: false,
	isTransactionListEmpty: false,
	isTransactionSearchQuery: false,
	transactionSearchQuery: "",
	transactionAccountQuery: "",
	isTransactionAccountQuery: false,
	transactionDate: TransactionDateTypes.ALL_TIME,
	selectedTransactionDate: TransactionDateTypes.ALL_TIME,
	isCustomModalOpen: false,
	transactionFilterState: {
		offset: 0,
		date: {begin: "", end: ""},
		transactionTypes: [],
		userGroupIds: [],
		userAccountIds: [],
		categoryIds: [],
		cardIds: [],
		transactionStatuses: [],
		query: "",
	},
	selectedTransactionFilterState: {
		offset: 0,
		date: {begin: "", end: ""},
		transactionTypes: [],
		userGroupIds: [],
		userAccountIds: [],
		categoryIds: [],
		cardIds: [],
		transactionStatuses: [],
		query: "",
	},
};

export const transactionsSlice = createSlice({
	name: "transactions",
	initialState,
	reducers: {
		setIsCustomModalOpen: (state: TransactionState, action: PayloadAction<boolean>) => {
			state.isCustomModalOpen = action.payload;
		},
		setIsExportTransactionsLoading: (state: TransactionState, action: PayloadAction<boolean>) => {
			state.isExportTransactionsLoading = action.payload;
		},
		setIsExportZambiaTransactionsTrayOpen: (state: TransactionState, action: PayloadAction<boolean>) => {
			state.isExportZambiaTransactionsTrayOpen = action.payload;
		},

		setIsTransactionPaginationLoading: (state: TransactionState, action: PayloadAction<boolean>) => {
			state.isTransactionPaginationLoading = action.payload;
		},
		setIsTransactionListEmpty: (state: TransactionState, action: PayloadAction<boolean>) => {
			state.isTransactionListEmpty = action.payload;
		},
		setTransactionDate: (state: TransactionState, action: PayloadAction<TransactionDateType>) => {
			state.transactionFilterState = {...state.transactionFilterState, date: action.payload.date};
			state.transactionDate = action.payload.name;
		},
		setTransactionList: (state: TransactionState, action: PayloadAction<Transaction[]>) => {
			state.transactionList = action.payload;
		},
		setTransactionsListTotal: (state: TransactionState, action: PayloadAction<number>) => {
			state.transactionsListTotal = action.payload;
		},
		setTransactionPaginationOffset: (state: TransactionState, action: PayloadAction<number>) => {
			state.transactionPaginationOffset = action.payload;
		},
		setTransactionsListGroupSize: (state: TransactionState, action: PayloadAction<number>) => {
			state.transactionsListGroupSize = action.payload;
		},
		setTransactionFilterState: (state: TransactionState, action: PayloadAction<TransactionFilterTypes>) => {
			state.transactionFilterState = {...state.transactionFilterState, ...action.payload};
		},
		setTransactionDateFilter: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, date: {begin: "", end: ""}};
			state.transactionFilterState = {...state.transactionFilterState, date: {begin: "", end: ""}};
			state.selectedTransactionDate = TransactionDateTypes.ALL_TIME;
			state.transactionDate = TransactionDateTypes.ALL_TIME;
		},
		setSelectedTransactionFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.transactionFilterState};
			state.selectedTransactionDate = state.transactionDate;
		},
		setSelectedTransactionFilterStateInstant: (state: TransactionState, action: PayloadAction<TransactionFilterTypes>) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, ...action.payload};
			state.transactionFilterState = {...state.transactionFilterState, ...action.payload};
		},
		setTransactionAccountsFilter: (state: TransactionState, action: PayloadAction<string>) => {
			state.selectedTransactionFilterState.userAccountIds = state.selectedTransactionFilterState.userAccountIds.filter(
				(_userId) => _userId !== action.payload
			);
			state.transactionFilterState.userAccountIds = state.transactionFilterState.userAccountIds.filter((_userId) => _userId !== action.payload);
		},
		setTransactionTypesFilter: (state: TransactionState, action: PayloadAction<TransactionType>) => {
			state.selectedTransactionFilterState.transactionTypes = state.selectedTransactionFilterState.transactionTypes.filter(
				(_types) => _types !== action.payload
			);
			state.transactionFilterState.transactionTypes = state.transactionFilterState.transactionTypes.filter(
				(_types) => _types !== action.payload
			);
		},
		setTransactionRecipientsFilter: (state: TransactionState, action: PayloadAction<string>) => {
			state.selectedTransactionFilterState.userGroupIds = state.selectedTransactionFilterState.userGroupIds.filter(
				(_userId) => _userId !== action.payload
			);
			state.transactionFilterState.userGroupIds = state.transactionFilterState.userGroupIds.filter((_userId) => _userId !== action.payload);
		},
		setTransactionCategoriesFilter: (state: TransactionState, action: PayloadAction<string>) => {
			state.selectedTransactionFilterState.categoryIds = state.selectedTransactionFilterState.categoryIds.filter(
				(_userId) => _userId !== action.payload
			);
			state.transactionFilterState.categoryIds = state.transactionFilterState.categoryIds.filter((_userId) => _userId !== action.payload);
		},
		setTransactionCardFilter: (state: TransactionState, action: PayloadAction<string>) => {
			state.selectedTransactionFilterState.cardIds = state.selectedTransactionFilterState.cardIds.filter(
				(_userId) => _userId !== action.payload
			);
			state.transactionFilterState.cardIds = state.transactionFilterState.cardIds.filter((_userId) => _userId !== action.payload);
		},
		setTransactionStatusesFilter: (state: TransactionState, action: PayloadAction<TransactionStatus>) => {
			state.selectedTransactionFilterState.transactionStatuses = state.selectedTransactionFilterState.transactionStatuses.filter(
				(_status) => _status !== action.payload
			);
			state.transactionFilterState.transactionStatuses = state.transactionFilterState.transactionStatuses.filter(
				(_status) => _status !== action.payload
			);
		},

		resetTransactionList: (state: TransactionState) => {
			state.transactionList = null;
		},
		resetTransactionDateFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, date: {begin: "", end: ""}};
			state.transactionFilterState = {...state.transactionFilterState, date: {begin: "", end: ""}};
			state.selectedTransactionDate = TransactionDateTypes.ALL_TIME;
			state.transactionDate = TransactionDateTypes.ALL_TIME;
		},
		resetTransactionStatusesFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, transactionStatuses: []};
			state.transactionFilterState = {...state.transactionFilterState, transactionStatuses: []};
		},
		resetTransactionCategoryIdsFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, categoryIds: []};
			state.transactionFilterState = {...state.transactionFilterState, categoryIds: []};
		},
		resetTransactionCardIdsFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, cardIds: []};
			state.transactionFilterState = {...state.transactionFilterState, cardIds: []};
		},
		resetTransactionRecipientsFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, userGroupIds: []};
			state.transactionFilterState = {...state.transactionFilterState, userGroupIds: []};
		},
		resetTransactionAccountsFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, userAccountIds: []};
			state.transactionFilterState = {...state.transactionFilterState, userAccountIds: []};
		},
		resetTransactionTypesFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, transactionTypes: []};
			state.transactionFilterState = {...state.transactionFilterState, transactionTypes: []};
		},
		resetTransactionQueryFilterState: (state: TransactionState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, query: ""};
			state.transactionFilterState = {...state.transactionFilterState, query: ""};
		},
		resetTransactionFilterState: (state: TransactionState) => {
			state.transactionFilterState = {
				offset: 0,
				date: {begin: "", end: ""},
				transactionTypes: [],
				userGroupIds: [],
				userAccountIds: [],
				categoryIds: [],
				cardIds: [],
				transactionStatuses: [],
				query: "",
			};
			state.selectedTransactionFilterState = {
				offset: 0,
				date: {begin: "", end: ""},
				transactionTypes: [],
				userGroupIds: [],
				userAccountIds: [],
				categoryIds: [],
				cardIds: [],
				transactionStatuses: [],
				query: "",
			};
		},
		resetAllTransactionData: (state: TransactionState) => {
			state.transactionList = null;
			state.transactionsListTotal = 0;
			state.transactionPaginationOffset = 0;
			state.transactionsListGroupSize = 0;
			state.isExportZambiaTransactionsTrayOpen = false;
			state.isExportTransactionsLoading = false;
			state.isTransactionListEmpty = false;
			state.isTransactionSearchQuery = false;
			state.transactionSearchQuery = "";
			state.transactionAccountQuery = "";
			state.isTransactionAccountQuery = false;
			state.isTransactionPaginationLoading = false;
			state.transactionDate = TransactionDateTypes.ALL_TIME;
			state.selectedTransactionDate = TransactionDateTypes.ALL_TIME;
			state.isCustomModalOpen = false;
			state.transactionFilterState = {
				offset: 0,
				date: {begin: "", end: ""},
				transactionTypes: [],
				userGroupIds: [],
				userAccountIds: [],
				categoryIds: [],
				cardIds: [],
				transactionStatuses: [],
				query: "",
			};
			state.selectedTransactionFilterState = {
				offset: 0,
				date: {begin: "", end: ""},
				transactionTypes: [],
				userGroupIds: [],
				userAccountIds: [],
				categoryIds: [],
				cardIds: [],
				transactionStatuses: [],
				query: "",
			};
		},
	},
});

export const {
	setIsCustomModalOpen,

	setTransactionList,
	setTransactionsListTotal,
	setTransactionPaginationOffset,
	setTransactionFilterState,
	setIsTransactionListEmpty,
	setTransactionsListGroupSize,
	setIsExportTransactionsLoading,
	setIsTransactionPaginationLoading,
	setTransactionDate,
	setTransactionDateFilter,
	setSelectedTransactionFilterStateInstant,
	setTransactionCardFilter,
	setTransactionTypesFilter,
	setTransactionAccountsFilter,
	setTransactionStatusesFilter,
	setTransactionCategoriesFilter,
	setTransactionRecipientsFilter,
	setSelectedTransactionFilterState,
	setIsExportZambiaTransactionsTrayOpen,

	resetTransactionList,
	resetTransactionFilterState,
	resetTransactionDateFilterState,
	resetTransactionQueryFilterState,
	resetTransactionCardIdsFilterState,
	resetTransactionStatusesFilterState,
	resetTransactionCategoryIdsFilterState,
	resetTransactionRecipientsFilterState,
	resetTransactionAccountsFilterState,
	resetTransactionTypesFilterState,
	resetAllTransactionData,
} = transactionsSlice.actions;

export default transactionsSlice.reducer;
