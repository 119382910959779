import Checkbox, {CheckType} from "../../../../../../components/General/Checkbox/Checkbox";
import {DeclineReason, DeclinedTransaction} from "../../../Hooks/State/PendingApproval/usePendingPayments.types";
import React, {useState} from "react";

import DropdownHead from "../../../../../../components/General/Dropdown/DropdownComponents/DropdownHead";
import DropdownLink from "../../../../../../components/General/Dropdown/DropdownComponents/DropdownLink";
import Transaction from "../../../../../../models/transaction";
import useClickOutside from "../../../../../../hooks/useClickOutside";

interface Props {
	isCancelled: boolean;
	transaction: Transaction;
	cancelledReason: DeclineReason | undefined;
	isCancelTransactionLoading?: boolean;
	handleSelectOrChangeCancelReason: (_data: DeclinedTransaction) => void;
	handleRemoveCancelOrChangeToCancel?: (_data: Transaction) => void;
}

function CancelPayment(props: Props): JSX.Element {
	const [isHoverCancel, setIsHoverCancel] = useState<boolean>(false);
	const [isCancelActive, setIsCancelActive] = useState<boolean>(false);

	const domNode = useClickOutside(() => {
		setIsCancelActive(false);
	});

	return (
		<>
			<div
				className="flex cursor-pointer flex-col items-center justify-center w-max mx-auto"
				ref={domNode}
				onMouseEnter={() => setIsHoverCancel(true)}
				onMouseLeave={() => setIsHoverCancel(false)}
				onClick={() => {
					if (props.isCancelled) {
						props.handleRemoveCancelOrChangeToCancel && props.handleRemoveCancelOrChangeToCancel(props.transaction);
						setIsCancelActive(false);
					} else {
						setIsCancelActive(true);
					}
				}}
			>
				<DropdownHead
					placeholder={
						<div
							className={
								`h-full center  flex items-center justify-center space-x-1 text-xs font-normal bg-red-300` +
								`${isCancelActive || props.isCancelled ? "text-error" : "text-current"}`
							}
						>
							<Checkbox type={CheckType.ERROR} id={props.transaction.id} checked={props.isCancelled} size="sm" readOnly />
							<span
								className={`h-full pr-2 border-r ${
									props.isCancelled ? "border-error-quin text-error-secondary" : "border-grey-secondary"
								} flex items-center`}
							>
								Cancel
							</span>
						</div>
					}
					noYPadding
					borderColor={props.isCancelled ? "border-error-quin" : "border-grey-secondary"}
					size="xs"
					clickAndClose
					placement="right"
					isLoading={props.isCancelTransactionLoading}
					color="red"
					isActive={isCancelActive}
					isHover={isHoverCancel || props.isCancelled}
					isSelected={props.isCancelled}
					noOutlineBorder={false}
					outline
					fitDropdown
				>
					<DropdownLink
						noHover={props.cancelledReason === DeclineReason.INCORRECT_DETAILS}
						onClick={() =>
							props.handleSelectOrChangeCancelReason({
								reason: DeclineReason.INCORRECT_DETAILS,
								transaction: props.transaction,
							})
						}
						redHover
						color="red"
					>
						<div className="flex items-center justify-start px-4 text-sm">Incorrect Details</div>
					</DropdownLink>
					<DropdownLink
						noHover={props.cancelledReason === DeclineReason.INCORRECT_AMOUNT}
						redHover
						onClick={() =>
							props.handleSelectOrChangeCancelReason({
								reason: DeclineReason.INCORRECT_AMOUNT,
								transaction: props.transaction,
							})
						}
						color="red"
					>
						<div className="flex items-center justify-start px-4 text-sm">Incorrect Amount</div>
					</DropdownLink>
					<DropdownLink
						noHover={props.cancelledReason === DeclineReason.WRONG_PURPOSE}
						redHover
						onClick={() =>
							props.handleSelectOrChangeCancelReason({
								reason: DeclineReason.WRONG_PURPOSE,
								transaction: props.transaction,
							})
						}
						color="red"
					>
						<div className="flex items-center justify-start px-4 text-sm">Wrong Purpose</div>
					</DropdownLink>
					<DropdownLink
						noHover={props.cancelledReason === DeclineReason.OTHERS}
						onClick={() =>
							props.handleSelectOrChangeCancelReason({
								reason: DeclineReason.OTHERS,
								transaction: props.transaction,
							})
						}
						redHover
						color="red"
					>
						<div className="flex items-center justify-start px-4 text-sm">Others</div>
					</DropdownLink>
				</DropdownHead>
			</div>
		</>
	);
}

export default CancelPayment;
