import * as Yup from "yup";

import {CategoryOptions, Countries} from "../../../modules/Landing/CompanyBase/Services/CompanyBase.constant";
import {Form, Formik, FormikProps} from "formik";
import React, {useCallback, useRef, useState} from "react";
import {addNewCompany, setCompanyId} from "../../../redux/init/slice/initSlice";

import ButtonComp from "../../General/Buttons/ButtonComp";
import DropdownLink from "../../General/Dropdown/DropdownComponents/DropdownLink";
import FormInput from "../../General/Inputs/FormInput";
import LabelDropdownHead from "../../General/Dropdown/LabelDropdownComponent/LabelDropdownHead";
import MessageToasts from "../../General/MessageToasts/MessageToasts";
import Modal from "../../General/Modals/Modal";
import ModalBody from "../../General/Modals/ModalBody";
import ModalFooter from "../../General/Modals/ModalFooter";
import ModalHeader from "../../General/Modals/ModalHeader";
import {Routes} from "../../../routes/routes.constants";
import {ToastType} from "../../../helpers/AppConstants";
import {addCompany} from "../../../modules/Dashboard/Home/Services/homeApi";
import {errorTrue} from "../../../redux/error/slice/errorSlice";
import formikHasError from "../../../helpers/formikHasError";
import {getErrorMessage} from "../../../utils/getErrorMessage";
import titleCase from "../../../hooks/titleCase";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router";

export type AddNewBusinessFormNew = {
	businessName: string;
	country: string;
};

interface Props {
	active: boolean;
	toggler: () => void;
}

function AddNewBusinessModal(props: Props): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();

	const formikRef = useRef<FormikProps<AddNewBusinessFormNew> | null>(null);

	const [error, setError] = useState<string | null>(null);
	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

	const initialFormState: AddNewBusinessFormNew = {
		businessName: "",
		country: "",
	};

	const formValidation = Yup.object().shape({
		businessName: Yup.string().required("Required"),
		country: Yup.string().required("Required"),
	});

	const handleAddNewBusiness = useCallback(
		async (data: AddNewBusinessFormNew) => {
			try {
				setError(null);
				setIsSubmitting(true);
				const res = await addCompany(data);
				dispatch(addNewCompany(res.company));
				dispatch(setCompanyId(res.company.id));
				history.push(Routes.ACCOUNT_OPENING.SET_REGISTRATION_STATUS);
				props.toggler();
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				setError(errorMessage);
				dispatch(errorTrue({message: errorMessage}));
			} finally {
				setIsSubmitting(false);
			}
		},
		[dispatch]
	);

	return (
		<>
			<Modal size="xs" active={props.active} toggler={props.toggler}>
				<ModalHeader onClose={props.toggler}>Add a new business</ModalHeader>
				<Formik
					initialValues={initialFormState}
					innerRef={formikRef}
					validationSchema={formValidation}
					onSubmit={(values) =>
						handleAddNewBusiness({businessName: values.businessName, country: values.country === Countries.ZAMBIA ? "ZM" : "NG"})
					}
					enableReinitialize={true}
					validateOnChange
					validateOnMount
				>
					{({errors, values, getFieldHelpers}) => {
						return (
							<Form className="w-full">
								<ModalBody>
									<div className="flex w-full flex-col items-center justify-center">
										{!!error && (
											<div className="w-full pb-4">
												<MessageToasts toastMessage={error || ""} toastType={ToastType.ERROR} />
											</div>
										)}
										<div className="w-full flex flex-col space-y-4">
											<FormInput type="text" placeholder="Business Name" name="businessName" isActive={props.active} />
											<LabelDropdownHead
												placeholder="Select Business Country"
												inputValue={titleCase(values.country || "")}
												clickAndClose
											>
												{CategoryOptions.filter(
													(_) => _.CategoryName === Countries.NIGERIA || _.CategoryName === Countries.ZAMBIA
												).map((category, index) => {
													return (
														<DropdownLink
															key={index}
															onClick={() => {
																getFieldHelpers("country").setValue(category.CategoryName);
															}}
															big
														>
															<div className="flex flex-row justify-start items-center space-x-4 px-4 cursor-pointer">
																<img
																	src={category.icon}
																	alt={category.CategoryName}
																	className="border-2 rounded-2xl"
																	width="30px"
																	height="30px"
																/>
																<div className="font-normal text-sm flex flex-row items-start pl-2 cursor-pointer whitespace-nowrap text-black-secondary capitalize">
																	{category.CategoryName}
																</div>
															</div>
														</DropdownLink>
													);
												})}
											</LabelDropdownHead>
										</div>
									</div>
								</ModalBody>
								<ModalFooter>
									<div className="flex w-full flex-row flex-wrap items-end justify-end ">
										<ButtonComp
											type="submit"
											color="blue"
											ripple="light"
											buttonType="primary"
											isLoading={isSubmitting}
											disable={formikHasError(errors)}
										>
											<span>Add Business</span>
										</ButtonComp>
									</div>
								</ModalFooter>
							</Form>
						);
					}}
				</Formik>
			</Modal>
		</>
	);
}

export default AddNewBusinessModal;
