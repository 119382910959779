import {addAccountToNewUserGroup, addAccountToUserGroup, removeAccountFromUserGroup} from "../../../../../People/Services/peopleApi";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import CustomerAccount from "../../../../../../../models/customerAccount";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {MainInitCompanyDetailsInterface} from "../../../../../../../redux/init/slice/initSlice.types";
import UserGroup from "../../../../../../../models/userGroup";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {mainUpdateCompanyDetails} from "../../../../../../../redux/init/slice/initSlice";
import {messageTrue} from "../../../../../../../redux/message/slice/messageSlice";
import {setCustomerAccount} from "../../../../../../../redux/customerAccount/slice/customerAccountSlice";

export interface UseTransactionDetailsUserGroupsResponse {
	userGroupOptions: UserGroup[];
	isSubmitting: boolean;
	isError: boolean;
	handleAddAccountToUserGroup: (account: CustomerAccount, userGroupId: string) => Promise<void>;
	handleCreateAndAddAccountToUserGroup: (account: CustomerAccount, name: string) => Promise<void>;
	handleRemoveAccountFromUserGroup: (account: CustomerAccount) => Promise<void>;
}

function useTransactionDetailsUserGroups(): UseTransactionDetailsUserGroupsResponse {
	const dispatch = useDispatch();

	const userGroupOptions = useSelector<IRootState, UserGroup[]>((state) => {
		return (state.init.main?.companyDetails.userGroups || []).filter((userGroup) => !userGroup.isDeleted);
	});

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const handleAddAccountToUserGroup = useCallback(
		async (account: CustomerAccount, userGroupId: string): Promise<void> => {
			try {
				setIsError(false);
				setIsSubmitting(true);
				const updatedCustomerAccount = await addAccountToUserGroup({account: account.toPaymentRecipient(), userGroupId});
				dispatch(setCustomerAccount(updatedCustomerAccount));
				dispatch(messageTrue({message: "Recipient Added Successfully"}));
			} catch (err) {
				setIsError(true);
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsSubmitting(false);
			}
		},
		[dispatch]
	);

	const handleCreateAndAddAccountToUserGroup = useCallback(
		async (account: CustomerAccount, name: string): Promise<void> => {
			try {
				setIsError(false);
				setIsSubmitting(true);
				const res = await addAccountToNewUserGroup({account: account.toPaymentRecipient(), name});
				dispatch(setCustomerAccount(res.account));
				dispatch(
					mainUpdateCompanyDetails({
						callback: (companyDetails: MainInitCompanyDetailsInterface) => {
							companyDetails.userGroups.push(res.userGroup);
						},
					})
				);
				dispatch(messageTrue({message: "Recipient Added Successfully"}));
			} catch (err) {
				setIsError(true);
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsSubmitting(false);
			}
		},
		[dispatch]
	);

	const handleRemoveAccountFromUserGroup = useCallback(
		async (account: CustomerAccount): Promise<void> => {
			try {
				setIsError(false);
				setIsSubmitting(true);
				const updatedCustomerAccount = await removeAccountFromUserGroup(account.id);
				dispatch(setCustomerAccount(updatedCustomerAccount));
				dispatch(messageTrue({message: "Recipient Removed Successfully"}));
			} catch (err) {
				setIsError(true);
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsSubmitting(false);
			}
		},
		[dispatch]
	);

	return {
		isError,
		userGroupOptions,
		isSubmitting,
		handleAddAccountToUserGroup,
		handleCreateAndAddAccountToUserGroup,
		handleRemoveAccountFromUserGroup,
	};
}

export default useTransactionDetailsUserGroups;
