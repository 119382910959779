import {
	makeRequestDownloadFile,
	makeRequestThrowError,
	makeRequestUploadFileWithSignal,
	makeRequestWithSignal,
} from "../../../../../../../helpers/request/makeRequest";

import Parsers from "../../../../../../../utils/parsers";
import {SetNewTransactionCategoryResponse} from "./transactionDetailApi.types";
import Transaction from "../../../../../../../models/transaction";
import TransactionCategory from "../../../../../../../models/transactionCategory";
import {toFormData} from "../../../../../../../utils/converters";

let controller = new AbortController();

//get transaction details
export async function getTransactionDetails(transactionId: string): Promise<Transaction> {
	const res = await makeRequestThrowError("/main/transaction/details", {
		transactionId,
	});
	return Parsers.classObjectNonNullable(res.transaction, Transaction);
}

//set a transaction in a category
export async function setTransactionCategory(transactionId: string, categoryId: string): Promise<Transaction> {
	controller = new AbortController();
	const res = await makeRequestWithSignal(
		"/main/transaction/set-category",
		{
			transactionId,
			categoryId,
		},
		controller.signal
	);
	if (res instanceof Error) {
		throw res;
	}
	return Parsers.classObjectNonNullable(res.transaction, Transaction);
}

//create a category and set a transaction in the category
export async function setNewTransactionCategory(transactionId: string, name: string): Promise<SetNewTransactionCategoryResponse> {
	controller = new AbortController();
	const res = await makeRequestWithSignal("/main/transaction/set-category/new", {transactionId, name}, controller.signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
		category: Parsers.classObjectNonNullable(res.category, TransactionCategory),
	};
}

//add comment to transaction
export async function addTransactionComment(transactionId: string, comment: string): Promise<Transaction> {
	controller = new AbortController();
	const res = await makeRequestWithSignal(
		"/main/transaction/add-comment",
		{
			transactionId,
			comment,
		},
		controller.signal
	);
	if (res instanceof Error) {
		throw res;
	}
	return Parsers.classObjectNonNullable(res.transaction, Transaction);
}

//download transaction receipt (lenco receipt)
export async function downloadLencoReceipt(transactionId: string): Promise<Blob> {
	const res = await makeRequestDownloadFile("/main/transaction/download-transaction-receipt", {transactionId});
	if (res instanceof Error) {
		throw res;
	}
	return res;
}

//upload receipt (accounting receipt)
export async function uploadAccountingReceipt(transactionId: string, file: File): Promise<Transaction> {
	controller = new AbortController();
	const res = await makeRequestUploadFileWithSignal("/main/transaction/receipt/upload", toFormData({transactionId, file}), controller.signal);
	if (res instanceof Error) {
		throw res;
	}
	return Parsers.classObjectNonNullable(res.transaction, Transaction);
}

//download receipt (accounting receipt)
export async function downloadAccountingReceipt(transactionId: string): Promise<Blob> {
	const res = await makeRequestDownloadFile("/main/transaction/receipt/download", {transactionId});
	if (res instanceof Error) {
		throw res;
	}
	return res;
}

//upload invoice (accounting invoice)
export async function uploadAccountingInvoice(transactionId: string, file: File): Promise<Transaction> {
	controller = new AbortController();
	const res = await makeRequestUploadFileWithSignal("/main/transaction/invoice/upload", toFormData({transactionId, file}), controller.signal);
	if (res instanceof Error) {
		throw res;
	}
	return Parsers.classObjectNonNullable(res.transaction, Transaction);
}

//download invoice (accounting invoice)
export async function downloadAccountingInvoice(transactionId: string): Promise<Blob> {
	const res = await makeRequestDownloadFile("/main/transaction/invoice/download", {transactionId});
	if (res instanceof Error) {
		throw res;
	}
	return res;
}

//edit sender alias
export async function editSenderAlias(transactionId: string, alias: string): Promise<Transaction> {
	controller = new AbortController();
	const res = await makeRequestWithSignal("/main/transaction/origination/edit-alias", {transactionId, alias}, controller.signal);
	if (res instanceof Error) {
		throw res;
	}
	return Parsers.classObjectNonNullable(res.transaction, Transaction);
}

//tag recipient
export async function tagRecipient(transactionId: string, contact: string): Promise<void> {
	controller = new AbortController();
	const res = await makeRequestWithSignal(
		"/main/transaction/tag-recipient",
		{
			transactionId,
			contact,
		},
		controller.signal
	);

	if (res instanceof Error) {
		throw res;
	}
}

export const abortTransactionCardCalls = (): void => {
	controller.abort();
};
