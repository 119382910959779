import React from "react";
import SendableTextarea from "../../../../../../../components/General/TextArea/SendableTextarea/SendableTextarea";
import Transaction from "../../../../../../../models/transaction";
import useTransactionDetailsCommentForm from "../Hooks/useTransactionDetailsCommentForm";

interface Props {
	dataType: string;
	transaction: Transaction;
}

function TransactionDetailCommentForm({dataType, transaction}: Props): JSX.Element {
	const {isSubmitting, handleAddTransactionComment, newCommentText, setNewCommentText} = useTransactionDetailsCommentForm();

	return (
		<form
			onSubmit={(e) => {
				e.preventDefault();
				void handleAddTransactionComment(transaction, newCommentText);
			}}
			data-type={dataType}
		>
			<SendableTextarea
				placeholder="Write A Comment"
				transactionCard
				value={newCommentText}
				onClickFunc={() => handleAddTransactionComment(transaction, newCommentText)}
				onChangeFunc={setNewCommentText}
				isLoading={isSubmitting}
				data-type={dataType}
				buttonText="Add Comment"
				sm
			/>
		</form>
	);
}

export default TransactionDetailCommentForm;
