import "animate.css";

import React, {useCallback, useRef, useState} from "react";

import ButtonComp from "../../../../../components/General/Buttons/ButtonComp";
import {FileDrop} from "react-file-drop";
import {IRootState} from "../../../../../redux/rootReducer";
import Modal from "../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../components/General/Modals/ModalBody";
import ModalFooter from "../../../../../components/General/Modals/ModalFooter";
import ModalHeader from "../../../../../components/General/Modals/ModalHeader";
import {useSelector} from "react-redux";

interface UploadModalProps {
	active: boolean;
	isLoading: boolean;
	isDownloadTemplateLoading: boolean;

	toggler: () => void;
	uploadFunc: (_data: FormData) => Promise<void>;
	handleDownloadTemplate: () => void;
}
function UploadModal(props: UploadModalProps): JSX.Element {
	const {active, toggler, uploadFunc, isLoading, isDownloadTemplateLoading, handleDownloadTemplate} = props;
	const companyId = useSelector((state: IRootState) => state.init.selectedCompanyId);
	const [isHover, setIsHover] = useState(false);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const formData = new FormData();

	const onFileChange = useCallback(
		(files) => {
			formData.append("companyId", companyId as string);
			formData.append("file", files);
			void uploadFunc(formData);
		},
		[companyId, uploadFunc]
	);
	const onTargetClick = () => {
		fileInputRef.current && fileInputRef.current.click();
	};

	return (
		<Modal size="sm" active={active} toggler={toggler}>
			<ModalHeader onClose={toggler}>Upload Payment File</ModalHeader>
			<ModalBody>
				<div
					className={`flex h-44 w-full max-w-xl cursor-pointer flex-col items-center justify-start space-y-2 rounded-lg border border-dashed ${
						isHover ? "bg-blue-senary" : ""
					}`}
				>
					{active && (
						<>
							<FileDrop
								onTargetClick={onTargetClick}
								onFrameDragEnter={() => setIsHover(true)}
								onFrameDragLeave={() => setIsHover(false)}
								onDragOver={() => setIsHover(true)}
								onDragLeave={() => setIsHover(false)}
								onDrop={(files) => onFileChange(files && files[0])}
							>
								<input
									type="file"
									ref={fileInputRef}
									className="hidden"
									onChange={(event) => onFileChange(event.target.files && event.target.files[0])}
									accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								/>
								<span className="pointer-events-none text-black-tertiary">
									Drag and drop or
									<br />
									click to upload
								</span>
							</FileDrop>
						</>
					)}
				</div>
				<div className="flex flex-row items-center justify-start pt-4">
					<ButtonComp color="blue" buttonType="tertiary" isLoading={isDownloadTemplateLoading} func={() => handleDownloadTemplate()}>
						Click to download template
					</ButtonComp>
				</div>
			</ModalBody>

			<ModalFooter>
				<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={toggler}>
					Cancel
				</ButtonComp>

				<ButtonComp type="submit" color="blue" ripple="light" buttonType="primary" isLoading={isLoading} disable={true}>
					Upload Payment File
				</ButtonComp>
			</ModalFooter>
		</Modal>
	);
}

export default UploadModal;
