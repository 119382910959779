import {
	InitiateMobileMoneyRequest,
	InitiateMobileMoneyResponse,
	MobileMoneyStatusRequest,
	MobileMoneyStatusResponse,
	VerifyPhoneMobileMoneyRequest,
	VerifyPhoneMobileMoneyResponse,
} from "./mobileMoney.types";
import {abortRequest, getAbortControllerSignal} from "../../../../../helpers/request/abortControllers";

import Collection from "../../../../../models/collection";
import Parsers from "../../../../../utils/parsers";
import {makeRequestWithSignal} from "../../../../../helpers/request/makeRequest";

export enum ZambiaMobileMoneyRequests {
	INIT = "zambia-mobile-money.init",
	STATUS = "zambia-mobile-money.status",
	VERIFY_PHONE = "zambia-mobile-money.verify-phone",
}

export const initiateMobileMoney = async (_data: InitiateMobileMoneyRequest): Promise<InitiateMobileMoneyResponse> => {
	const signal = getAbortControllerSignal(ZambiaMobileMoneyRequests.INIT);

	const res = await makeRequestWithSignal("/main/payments/request/mobile-money/initiate", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		key: Parsers.string(res.key),
		requireOtp: Parsers.boolean(res.requireOtp),
		mobileMoneyCollection: Parsers.classObjectNonNullable(res.mobileMoneyCollection, Collection),
	};
};

export const verifyPhoneMobileMoney = async (_data: VerifyPhoneMobileMoneyRequest): Promise<VerifyPhoneMobileMoneyResponse> => {
	const signal = getAbortControllerSignal(ZambiaMobileMoneyRequests.VERIFY_PHONE);

	const res = await makeRequestWithSignal("/main/payments/request/mobile-money/verify-phone", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		key: Parsers.string(res.key),
		mobileMoneyCollection: Parsers.classObjectNonNullable(res.mobileMoneyCollection, Collection),
	};
};

export const mobileMoneyStatus = async (_data: MobileMoneyStatusRequest): Promise<MobileMoneyStatusResponse> => {
	const signal = getAbortControllerSignal(ZambiaMobileMoneyRequests.STATUS);

	const res = await makeRequestWithSignal("/main/payments/request/mobile-money/status", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		key: Parsers.string(res.key),
		mobileMoneyCollection: Parsers.classObjectNonNullable(res.mobileMoneyCollection, Collection),
	};
};

export function abortZambiaMobileMoneyRequest(type: ZambiaMobileMoneyRequests): void {
	abortRequest(type);
}

export function abortAllZambiaMobileMoneyRequests(): void {
	Object.values(ZambiaMobileMoneyRequests).forEach((type) => abortRequest(type));
}
