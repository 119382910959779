import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class TransferBand {
	[immerable] = true;

	constructor(public startAmount: number, public endAmount: number) {}

	static create(obj: GenericObject): TransferBand {
		return new TransferBand(Parsers.number(obj.startAmount), Parsers.number(obj.endAmount));
	}
}
