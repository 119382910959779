import React, {useEffect, useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import FundCardHeaders from "./FundCardHeaders";
import {IRootState} from "../../../../../../redux/rootReducer";
import {OtpActivityType} from "../../../../../../helpers/AppConstants";
import OtpInputForm from "../../../../../../components/OtpInputForm";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {TailSpin} from "react-loader-spinner";
import formatNumber from "../../../../../../utils/formatNumber";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {setTransferBetweenOtp} from "../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import useFundCard from "../../../Hooks/State/SendMoney/FundCard/useFundCard";
import useFundCardOtp from "../../../Hooks/State/SendMoney/FundCard/useFundCardOtp";
import useOtpInit from "../../../Hooks/State/SendMoney/SingleTransfer/useOtpInit";

function MakePayment(): JSX.Element {
	const dispatch = useDispatch();

	const {handleOtpChange} = useFundCardOtp();
	const {minutes, seconds, isInitOTPLoading, handleOTPInit, handleResendOTPInit} = useOtpInit({});
	const {isFundCardLoading, isFundCardResponse, fundCardError, handleFundCard} = useFundCard();

	const fundCardDetails = useSelector((state: IRootState) => state.fundCard.fundCardDetails);
	const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

	useLayoutEffect(() => {
		if (isAuthAppSetup) return;
		handleOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
	}, [handleOTPInit, isAuthAppSetup]);

	useEffect(() => {
		if (fundCardDetails.otp.length < 6) return;
		void handleFundCard(fundCardDetails);
	}, [fundCardDetails]);

	useEffect(() => {
		if (!isFundCardResponse) return;
		dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
	}, [isFundCardResponse]);

	return (
		<>
			<div className=" flex w-full flex-col items-center justify-center lg:items-start lg:justify-start">
				<FundCardHeaders />
				{!isAuthAppSetup && isInitOTPLoading && (
					<>
						<div className="flex flex-row items-center justify-start space-x-4 text-sm">
							<TailSpin color="#5466F9" height={20} width={20} />
							<span>OTP is being sent to your email</span>
						</div>
					</>
				)}
				{!isInitOTPLoading && (
					<>
						<div className="flex w-full max-w-sm flex-col space-y-8">
							<div className=" text-sm  font-normal">
								{isAuthAppSetup && "Enter the OTP generated from your token App to approve your card funding of "}
								{!isAuthAppSetup && "Enter the OTP sent to your email to approve your card funding of "}
								<CurrencyCode />
								{formatNumber(fundCardDetails.amount)}
								{/* to {recipientCardDetails?.name}(card) */}
							</div>

							<div className="sm:w-100% mx-auto flex w-full flex-col md:w-full">
								<span>
									<OtpInputForm value={fundCardDetails.otp || undefined} otpFunc={handleOtpChange} errorMessage={fundCardError} />
								</span>
								{!isAuthAppSetup && (
									<div className="flex items-start justify-start pt-4">
										<div>
											{minutes === 0 && seconds === 0 ? (
												<div>
													<span
														className="cursor-pointer  text-sm text-blue"
														onClick={() => handleResendOTPInit({action: OtpActivityType.APPROVE_TRANSACTION})}
													>
														Resend OTP
													</span>
												</div>
											) : (
												<span className="cursor-not-allowed  text-sm text-black-tertiary">
													Resend OTP in{" "}
													<span className="text-black-secondary">
														{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
													</span>
												</span>
											)}
										</div>
									</div>
								)}
								<div className="flex w-full flex-row items-center justify-center space-x-4 pt-8">
									<ButtonComp
										type="button"
										ripple="light"
										buttonType="secondary"
										color="grey"
										func={() => {
											dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT));
											dispatch(setTransferBetweenOtp(""));
										}}
									>
										<span>Back</span>
									</ButtonComp>

									<ButtonComp
										type="submit"
										color="blue"
										ripple="light"
										buttonType="primary"
										func={() => dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT))}
										isLoading={isFundCardLoading}
										disable={!isFundCardLoading}
									>
										<span>Authorize</span>
									</ButtonComp>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default MakePayment;
