import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";

import Transaction from "../../../../../../../models/transaction";
import {editSenderAlias} from "../Services/transactionDetailApi";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {messageTrue} from "../../../../../../../redux/message/slice/messageSlice";
import {setSingleTransaction} from "../../../../../../../redux/transaction/slice/transactionSlice";
import {useDispatch} from "react-redux";

export interface UseTransactionDetailsCardHeadSenderResponse {
	isSubmitting: boolean;
	submitForm: (transaction: Transaction, alias: string) => Promise<void>;

	showEditAliasForm: boolean;
	handleShowEditForm: (transaction: Transaction) => void;
	handleHideEditForm: () => void;

	senderAlias: string | null;
	setSenderAlias: Dispatch<SetStateAction<string | null>>;
}

function useTransactionDetailsCardHeadSender(transaction: Transaction): UseTransactionDetailsCardHeadSenderResponse {
	const dispatch = useDispatch();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);

	const [showEditAliasForm, setShowEditAliasForm] = useState<boolean>(false);

	const [senderAlias, setSenderAlias] = useState<string | null>(null);

	useEffect(() => {
		if (!isError) return;

		if (transaction?.origination?.alias || transaction?.description) {
			return setSenderAlias(transaction.origination?.alias || transaction.description);
		}

		setSenderAlias(null);
	}, [isError, transaction]);

	const handleShowEditForm = useCallback((_transaction: Transaction) => {
		setSenderAlias(_transaction.origination?.alias || _transaction.description);
		setShowEditAliasForm(true);
	}, []);

	const handleHideEditForm = useCallback(() => {
		setShowEditAliasForm(false);
	}, []);

	const submitForm = useCallback(
		async (_transaction: Transaction, alias: string) => {
			if (!alias) {
				return;
			}
			try {
				setIsError(false);
				setIsSubmitting(true);
				const updatedTransaction = await editSenderAlias(_transaction.id, alias);
				dispatch(setSingleTransaction(updatedTransaction));
				dispatch(messageTrue("Sender Alias Edited Successfully"));
				handleHideEditForm();
			} catch (err) {
				setIsError(true);
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsSubmitting(false);
			}
		},
		[dispatch]
	);

	return {
		isSubmitting,
		submitForm,

		showEditAliasForm,
		handleShowEditForm,
		handleHideEditForm,

		senderAlias,
		setSenderAlias,
	};
}

export default useTransactionDetailsCardHeadSender;
