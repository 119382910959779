import {FundCardDetailInterface} from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice.types";
import {useCallback, useState} from "react";

import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {setFundCardResponse} from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import {setSingleTransaction} from "../../../../../../../redux/transaction/slice/transactionSlice";
import {useDispatch} from "react-redux";
import {fundCardInit} from "../../../../Services/SendMoney/fundCardApi";

interface UseFundCardInterface {
	isFundCardLoading: boolean;
	isFundCardResponse: boolean;
	fundCardError: boolean;
	handleFundCard: (_data: FundCardDetailInterface) => Promise<void>;
}

function useFundCard(): UseFundCardInterface {
	const dispatch = useDispatch();
	const [isFundCardLoading, setIsFundCardLoading] = useState(false);
	const [isFundCardResponse, setIsFundCardResponse] = useState<boolean>(false);
	const [fundCardError, setFundCardError] = useState<boolean>(false);

	const handleFundCard = useCallback(
		async (_data) => {
			try {
				setIsFundCardLoading(true);
				setFundCardError(false);
				const res = await fundCardInit(_data);
				setIsFundCardResponse(true);
				dispatch(
					setFundCardResponse({
						transactionId: res.transaction.id,
						isApproved: res.isApproved,
						processing: res.processing,
						accountsToActivate: res.accountsToActivate,
					})
				);
				dispatch(setSingleTransaction(res.transaction));
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				dispatch(errorTrue({message: errorMessage}));
				setFundCardError(true);
			} finally {
				setIsFundCardLoading(false);
			}
		},
		[dispatch]
	);

	return {
		isFundCardLoading,
		isFundCardResponse,
		fundCardError,
		handleFundCard,
	};
}

export default useFundCard;
