import Parsers from "../../../../../utils/parsers";
import Transaction from "../../../../../models/transaction";
import {makeRequest} from "../../../../../helpers/request/makeRequest";
import {FundCardInitRequest, FundCardInitResponse} from "./funcCardApi.types";

export const fundCardInit = async (data: FundCardInitRequest): Promise<FundCardInitResponse> => {
	const res = await makeRequest("/main/payments/initiate/card", data);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
		isApproved: Parsers.boolean(res.isApproved),
		processing: Parsers.boolean(res.processing),
		accountsToActivate: Parsers.stringArray(res.accountsToActivate),
	};
};
