import React, {useState} from "react";

import MobileMoneyMakePayment from "./MakePayment";
import MobileMoneyPaymentDetails from "./PaymentDetails";
import MobileMoneyProcessPayment from "./ProcessPayment";

interface Props {
	selectedAccountId: string;
	handleDone: () => void;
	handleBack: () => void;
	handleClose: () => void;
}

const enum MobileMoneyStage {
	PAYMENT_DETAILS = "payment-details",
	MAKE_PAYMENT = "make-payment",
	PROCESSING = "processing",
}

function MobileMoney(props: Props): JSX.Element {
	const [key, setKey] = useState(`${Math.floor(Math.random() * 100000000000000) + 1}`);
	const [stage, setStage] = useState(MobileMoneyStage.PAYMENT_DETAILS);
	const [phoneNumber, setPhoneNumber] = useState("");

	const handleUpdateState = (_key: string, _phoneNumber: string) => {
		setKey(_key);
		setPhoneNumber(_phoneNumber);
	};

	return (
		<>
			{stage === MobileMoneyStage.PAYMENT_DETAILS && (
				<MobileMoneyPaymentDetails
					keyId={key}
					selectedAccountId={props.selectedAccountId}
					handleBack={props.handleBack}
					handleNext={() => setStage(MobileMoneyStage.MAKE_PAYMENT)}
					handleLast={() => setStage(MobileMoneyStage.PROCESSING)}
					handleClose={props.handleClose}
					handleUpdateKeyId={() => setKey(`${Math.floor(Math.random() * 100000000000000) + 1}`)}
					handleUpdatePhoneNumber={(_value) => setPhoneNumber(_value)}
				/>
			)}
			{stage === MobileMoneyStage.MAKE_PAYMENT && (
				<MobileMoneyMakePayment
					keyId={key}
					recipientPhoneNumber={phoneNumber}
					handleBack={() => setStage(MobileMoneyStage.PAYMENT_DETAILS)}
					handleNext={() => setStage(MobileMoneyStage.PROCESSING)}
					handleClose={props.handleClose}
				/>
			)}
			{stage === MobileMoneyStage.PROCESSING && (
				<MobileMoneyProcessPayment keyId={key} handleUpdateState={() => handleUpdateState("", "")} handleClose={props.handleClose} />
			)}
		</>
	);
}

export default MobileMoney;
