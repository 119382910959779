import React, {useCallback} from "react";
import {resetAllSingleTransferData, setSinglePaymentInternalStage} from "../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import {setPageFrom, setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {useDispatch, useSelector} from "react-redux";

import AccountNumberDropdown from "../../DropDowns/AccountNumberDropdown";
import BankAccount from "../../../../../../models/bankAccount";
import BankDropdown from "../../DropDowns/BankDropdown";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import Card from "../../../../../../models/card";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import CustomerAccount from "../../../../../../models/customerAccount";
import Fee from "../../../../../../components/General/Fee";
import {IRootState} from "../../../../../../redux/rootReducer";
import Input from "../../../../../../components/General/Inputs/Input";
import MessageToasts from "../../../../../../components/General/MessageToasts/MessageToasts";
import MoneyInput from "../../../../../../components/General/Inputs/MoneyInput";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {PaymentsType} from "../../../Hooks/State/SendMoney/payments.constants";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import {SinglePaymentInternalStage} from "../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice.types";
import TextArea from "../../../../../../components/General/TextArea/TextArea";
import {ToastType} from "../../../../../../helpers/AppConstants";
import UserAccount from "../../../../../../models/userAccount";
import UserAccountDropdown from "../../DropDowns/UserAccountDropdown";
import commaSeparator from "../../../../../../utils/commaSeparator";
import formatNumber from "../../../../../../utils/formatNumber";
import {setCanVerifyRecipientAccountDetails} from "../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import titleCase from "../../../../../../hooks/titleCase";
import {useHistory} from "react-router";
import {useMoneyToNumber} from "../../../../../../hooks/useMoneyToNumber";
import useSingleTransferAccountNumberAutocomplete from "../../../Hooks/State/SendMoney/SingleTransfer/useSingleTransferAccountNumberAutocomplete";
import useSingleTransferAmount from "../../../Hooks/State/SendMoney/SingleTransfer/useSingleTransferAmount";
import useSingleTransferPayFrom from "../../../Hooks/State/SendMoney/SingleTransfer/useSingleTransferPayFrom";
import useSingleTransferPurpose from "../../../Hooks/State/SendMoney/SingleTransfer/useSingleTransferPurpose";
import useSingleTransferRecipientBankCode from "../../../Hooks/State/SendMoney/SingleTransfer/useSingleTransferRecipientBankCode";
import useSingleTransferVerifyRecipientAccountDetails from "../../../Hooks/State/SendMoney/SingleTransfer/useSingleTransferVerifyRecipientAccountDetails";

function PaymentDetails(): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();

	const selectedUserAccount = useSelector((state: IRootState) => state.singleTransfer.selectedUserAccount);
	const singleTransferDetails = useSelector((state: IRootState) => state.singleTransfer.singleTransferDetails);
	const singlePaymentInternalStage = useSelector((state: IRootState) => state.singleTransfer.singlePaymentInternalStage);

	const {handleAmountChange} = useSingleTransferAmount();
	const {handlePurposeChange} = useSingleTransferPurpose();
	const {handleSelectBank} = useSingleTransferRecipientBankCode();
	const {accounts, selectedAccountId, currentUserAccountMeta, handleSelectAccount} = useSingleTransferPayFrom();
	const {verifyAccountError, verifiedAccount, isVerifyAccountLoading, handleSetVerifiedAccount, handleResetVerifiedAccount} =
		useSingleTransferVerifyRecipientAccountDetails();
	const {isRecipientAutocompleteLoading, suggestedRecipients, handleSelectRecipient, handleAccountNumberChange} =
		useSingleTransferAccountNumberAutocomplete({handleSetVerifiedAccount, handleResetVerifiedAccount});

	const handleBack = useCallback(() => {
		dispatch(setPaymentStage(PaymentStageType.INITIAL));
		dispatch(resetAllSingleTransferData());
	}, []);

	return (
		<>
			<div className="flex w-full flex-col items-center justify-center lg:items-start lg:justify-start">
				<div className="w-full max-w-sm">
					<SinglePaymentHeaders />
					{singlePaymentInternalStage === SinglePaymentInternalStage.PAYMENT_DETAILS_STAGE && (
						<div className="flex w-full flex-col justify-between">
							<div className="flex min-h-72 flex-col items-start space-y-4 pt-2 text-base font-normal  md:items-center md:px-0 lg:items-center lg:px-0">
								<div className="flex w-full flex-col space-y-4">
									<div className="w-full ">
										<UserAccountDropdown
											placeholder="Pay from"
											value={selectedAccountId || ""}
											options={accounts || null}
											onSelect={handleSelectAccount}
											isDisabled={!!(accounts && accounts.length < 2 && selectedAccountId)}
											showBalance
											initiatorCanSelect
										/>
									</div>

									{selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
										<div className="flex w-full flex-col items-center justify-start space-y-6">
											<MessageToasts
												toastMessage={" You can only send to specific recipients"}
												toastType={ToastType.INFORMATION}
											/>
											<AccountNumberDropdown
												data={suggestedRecipients}
												inputValue={singleTransferDetails.recipient.accountNumber}
												placeholder={`Select Recipient`}
												clickAndClose
												onClickFunc={(e) => {
													if (e instanceof UserAccount || e instanceof CustomerAccount) {
														handleSelectRecipient(e.bankAccount as BankAccount);
													}
													if (e instanceof Card) {
														dispatch(setPageFrom(history.location.pathname));
														history.push({
															pathname: "/payments/make/single",
															search: `?to=${e.id}`,
															state: {
																typeOfTransfer: PaymentsType.FUND_CARD,
																from: selectedAccountId,
																pageFrom: history.location.pathname,
																to: e.id,
															},
														});
													}
												}}
												isSearchable={!!suggestedRecipients && suggestedRecipients.length > 4}
												isInputDisabled
												showArrow
											/>
											{!verifyAccountError && verifiedAccount && (
												<div className="flex w-full flex-col items-start justify-start">
													<Input
														placeholder="Account Name"
														value={titleCase(verifiedAccount.accountName)}
														readOnly
														fullWidth
													/>
												</div>
											)}
										</div>
									)}

									{selectedUserAccount?.accountRestrictions.cannotSendMoney && (
										<div className="w-full">
											<MessageToasts toastMessage={"You can not send money from this account"} toastType={ToastType.ERROR} />
										</div>
									)}

									{!selectedUserAccount?.accountRestrictions.cannotSendMoney &&
										!selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
											<>
												<div className="flex w-full flex-col items-start justify-start space-y-6 lg:flex-row">
													<div className="w-full">
														<AccountNumberDropdown
															data={suggestedRecipients as UserAccount[] | null}
															placeholder={`Account Number or Name`}
															inputValue={singleTransferDetails.recipient.accountNumber}
															isLoading={isRecipientAutocompleteLoading}
															clickAndClose
															onChangeFunc={handleAccountNumberChange}
															onClickFunc={(e) => {
																if (e instanceof UserAccount || e instanceof CustomerAccount) {
																	handleSelectRecipient(e.bankAccount as BankAccount);
																}
																if (e instanceof Card) {
																	dispatch(setPageFrom(history.location.pathname));
																	history.push({
																		pathname: "/payments/make/single",
																		search: `?to=${e.id}`,
																		state: {
																			typeOfTransfer: PaymentsType.FUND_CARD,
																			from: selectedAccountId,
																			temp: true,
																			pageFrom: history.location.pathname,
																			to: e.id,
																		},
																	});
																}
															}}
														/>
													</div>
												</div>
												<div className="flex w-full flex-col items-start justify-start ">
													<BankDropdown
														accountNumber={singleTransferDetails.recipient.accountNumber}
														bankCode={singleTransferDetails.recipient.bankCode}
														onClickFunc={handleSelectBank}
													/>
												</div>

												{verifyAccountError && !verifiedAccount && (
													<div className="w-full">
														<MessageToasts toastMessage={verifyAccountError.message} toastType={ToastType.ERROR} />
													</div>
												)}
												{!verifyAccountError && verifiedAccount && (
													<div className="flex w-full flex-col items-start justify-start ">
														<Input
															placeholder="Account Name"
															value={titleCase(verifiedAccount.accountName)}
															readOnly
															fullWidth
														/>
													</div>
												)}
											</>
										)}
								</div>
							</div>
							<div className="flex w-full flex-row items-center justify-center space-x-4 pt-8">
								<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={handleBack}>
									<span className="w-full text-center">Back</span>
								</ButtonComp>

								<ButtonComp
									type="submit"
									color="blue"
									ripple="light"
									buttonType="primary"
									disable={
										!!(
											!selectedUserAccount ||
											verifyAccountError ||
											!verifiedAccount ||
											selectedUserAccount.accountRestrictions.cannotSendMoney
										)
									}
									isLoading={isVerifyAccountLoading}
									func={() => {
										dispatch(setCanVerifyRecipientAccountDetails(false));
										dispatch(setSinglePaymentInternalStage(SinglePaymentInternalStage.AMOUNT_PURPOSE_STAGE));
									}}
								>
									<div className="flex flex-row items-center justify-center">
										<span className="w-full text-center">Next</span>
									</div>
								</ButtonComp>
							</div>
						</div>
					)}
					{singlePaymentInternalStage === SinglePaymentInternalStage.AMOUNT_PURPOSE_STAGE && (
						<div className=" flex w-full flex-col justify-between">
							<div className="flex flex-col items-start space-y-2 pt-2  text-base font-normal md:items-center   md:px-0 lg:items-center lg:px-0 ">
								<div className="flex w-full flex-col space-y-4">
									<div className="flex w-full flex-col items-start justify-start space-y-4 lg:flex-row">
										<div className="flex w-full flex-col space-y-2 ">
											<MoneyInput
												placeholder="Amount"
												icon={<CurrencyCode />}
												value={singleTransferDetails.amount || ""}
												onChange={handleAmountChange}
											/>
											{selectedUserAccount?.bankCode !== singleTransferDetails.recipient?.bankCode && (
												<div
													className={`text-xs text-black-tertiary ${
														singleTransferDetails.amount || singleTransferDetails.amount.length > 1 ? "block" : "hidden"
													}`}
												>
													{" "}
													Fee: {<Fee value={singleTransferDetails.amount} />}
												</div>
											)}
										</div>
									</div>
									<div className="flex w-full flex-col items-start justify-start space-y-4">
										<div className="w-full">
											<TextArea
												placeholder="Purpose of payment"
												value={singleTransferDetails.purpose}
												onChangeFunc={handlePurposeChange}
												sm={true}
											/>
										</div>
									</div>
									{currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
										<div className="w-full">
											<MessageToasts
												toastMessage={
													<p>
														You can only send <CurrencyCode />
														{formatNumber(currentUserAccountMeta?.maxApprovalAmount)} or less on this account. Any amount
														more than this will be initiated and need to be approved by an admin.
													</p>
												}
												toastType={ToastType.INFORMATION}
											/>
										</div>
									)}
									{useMoneyToNumber(singleTransferDetails.amount) > (selectedUserAccount?.balance as number) && (
										<div className="w-full">
											<MessageToasts
												toastMessage={
													(
														<div>
															{" "}
															You do not have enough funds. Your account balance is <CurrencyCode />
															{commaSeparator(selectedUserAccount?.balance as number)}
														</div>
													) as unknown as string | Element
												}
												toastType={ToastType.ERROR}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="flex w-full flex-row items-center justify-center space-x-4 pt-6">
								<ButtonComp
									type="button"
									ripple="light"
									buttonType="secondary"
									color="grey"
									func={() => dispatch(setSinglePaymentInternalStage(SinglePaymentInternalStage.PAYMENT_DETAILS_STAGE))}
								>
									<span className="w-full text-center">Back</span>
								</ButtonComp>
								<ButtonComp
									type="submit"
									color="blue"
									ripple="light"
									buttonType="primary"
									disable={
										!singleTransferDetails.purpose ||
										!singleTransferDetails.amount ||
										useMoneyToNumber(singleTransferDetails.amount) === 0 ||
										useMoneyToNumber(singleTransferDetails.amount) > (selectedUserAccount?.balance as number)
									}
									isLoading={isVerifyAccountLoading}
									func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
								>
									<span className="w-full text-center">Next</span>
								</ButtonComp>
							</div>
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default PaymentDetails;
