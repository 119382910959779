import {enableES5, enableMapSet} from "immer";

import App from "./App";
import {Provider} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter as Router} from "react-router-dom";
import store from "./redux/store";

enableMapSet();
enableES5();

ReactDOM.render(
	<React.StrictMode>
		<Router>
			<Provider store={store}>
				<App />
			</Provider>
		</Router>
	</React.StrictMode>,
	document.getElementById("root")
);
