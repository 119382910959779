import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {TransactionStatus, TransactionType} from "../../../models/transaction.constants";

interface FilterState {
	filter: string[];
	filterLength: number | null;
	currentFilterType: string[];
	categories: string[];
	recipients: string[];
	accounts: string[];
	transactionType: TransactionType[];
	transactionStatus: TransactionStatus[];
}

const initialState: FilterState = {
	filter: [],
	filterLength: null,
	currentFilterType: [],
	categories: [],
	recipients: [],
	accounts: [],
	transactionType: [],
	transactionStatus: [],
};

export const dateFilterValues: string[] = ["Last 30 days", "This Month", "Last Month", "This year", "All time", "Custom"];

export const filterSlice = createSlice({
	name: "filter",
	initialState,
	reducers: {
		clearFilter: (state: FilterState) => {
			state.filter = [];
			state.currentFilterType = [];
		},
		addFilterTypes: (state: FilterState) => {
			state.filter = state.currentFilterType;
		},
		addDateCurrentType: (state: FilterState, action: PayloadAction<string>) => {
			const updatedFilteredItems = state.currentFilterType.filter((item) => item !== action.payload);
			//check if date filter exist on filter
			const dateFilterExist = dateFilterValues.some((item) => state.currentFilterType.indexOf(item) !== -1);
			const filterItemsWithoutDate = state.currentFilterType.filter((item) => !dateFilterValues.includes(item));

			if (state.currentFilterType.some((el) => el === action.payload)) state.currentFilterType = updatedFilteredItems;
			// if date filter exist replace existing date filter with new date filter
			if (dateFilterExist) {
				filterItemsWithoutDate.push(action.payload);
				state.currentFilterType = filterItemsWithoutDate;
			} else state.currentFilterType.push(action.payload);
		},
		addCategories: (state: FilterState, action: PayloadAction<Array<string>>) => {
			state.categories = action.payload;
		},
		addRecipients: (state: FilterState, action: PayloadAction<Array<string>>) => {
			state.recipients = action.payload;
		},
		addAccounts: (state: FilterState, action: PayloadAction<Array<string>>) => {
			state.accounts = action.payload;
		},
		addTransactionType: (state: FilterState, action: PayloadAction<TransactionType[]>) => {
			state.transactionType = action.payload;
		},
		addTransactionStatus: (state: FilterState, action: PayloadAction<TransactionStatus[]>) => {
			state.transactionStatus = action.payload;
		},
		removeCategories: (state: FilterState, action: PayloadAction<string>) => {
			const updatedFilteredItems = state.categories.filter((item) => item !== action.payload);
			state.categories = updatedFilteredItems;
			state.filterLength = state.filter.length - 1;
		},
		removeRecipients: (state: FilterState, action: PayloadAction<string>) => {
			const updatedFilteredItems = state.recipients.filter((item) => item !== action.payload);
			state.recipients = updatedFilteredItems;
			state.filterLength = state.filter.length - 1;
		},
		removeAccounts: (state: FilterState, action: PayloadAction<string>) => {
			const updatedFilteredItems = state.accounts.filter((item) => item !== action.payload);
			state.accounts = updatedFilteredItems;
			state.filterLength = state.filter.length - 1;
		},
		removeTransactionType: (state: FilterState, action: PayloadAction<number>) => {
			const updatedFilteredItems = state.transactionType.filter((item) => item !== action.payload);
			state.transactionType = updatedFilteredItems;
			state.filterLength = state.filter.length - 1;
		},
		removeTransactionStatus: (state: FilterState, action: PayloadAction<number>) => {
			const updatedFilteredItems = state.transactionStatus.filter((item) => item !== action.payload);
			state.transactionStatus = updatedFilteredItems;
			state.filterLength = state.filter.length - 1;
		},
		currentFilter: (state: FilterState, action: PayloadAction<string>) => {
			const updatedFilteredItems = state.currentFilterType.filter((item) => item !== action.payload);
			if (state.currentFilterType.some((el) => el === action.payload)) state.currentFilterType = updatedFilteredItems;
			else state.currentFilterType.push(action.payload);
		},
	},
});

export const {
	clearFilter,
	addFilterTypes,
	addCategories,
	addRecipients,
	addAccounts,
	addTransactionType,
	addTransactionStatus,
	removeCategories,
	removeRecipients,
	removeAccounts,
	removeTransactionType,
	removeTransactionStatus,
	currentFilter,
	addDateCurrentType,
} = filterSlice.actions;

export default filterSlice.reducer;
