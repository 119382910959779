export enum FilterDateTypes {
	LAST_30_DAYS = "Last 30 days",
	THIS_MONTH = "This Month",
	LAST_MONTH = "Last Month",
	THIS_YEAR = "This year",
	ALL_TIME = "All time",
	CUSTOM = "Custom",
}

export type FilterDateObj = {
	begin: Date | null | string;
	end: Date | null | string;
};

export type FilterDateType = {
	name: FilterDateTypes;
	date: FilterDateObj;
};

// const dateOptions = [
// {
// name: FilterDateTypes.LAST_30_DAYS,
// date: {begin: last30Days, end: currentDate},
// },
// {
// name: FilterDateTypes.THIS_MONTH,
// date: {begin: firstDayOfMonth, end: currentDate},
// },
// {
// name: FilterDateTypes.LAST_MONTH,
// date: {begin: firstDayOfLastMonth, end: lastDayOfLastMonth},
// },
// {
// name: FilterDateTypes.THIS_YEAR,
// date: {begin: firstDayOfYear, end: currentDate},
// },
// {
// name: FilterDateTypes.ALL_TIME,
// date: {begin: firstDate, end: currentDate},
// },
// {
// name: FilterDateTypes.CUSTOM,
// date: {begin: "", end: currentDate},
// },
// ];
