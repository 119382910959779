import {GenericObject} from "../../helpers/types";
import Parsers from "../../utils/parsers";
import {immerable} from "immer";

export default class CreatedBy {
	[immerable] = true;

	constructor(public id: string, public firstName: string, public lastName: string, public phones: string[], public email: string) {}

	get name(): string {
		return `${this.firstName} ${this.lastName}`.trim();
	}

	get nameAcronym(): string {
		return `${this.firstName[0]}${this.lastName[0]}`.trim().toUpperCase();
	}

	static create(obj: GenericObject): CreatedBy {
		return new CreatedBy(
			Parsers.string(obj.id),
			Parsers.string(obj.firstName),
			Parsers.string(obj.lastName) || Parsers.string(obj.surname),
			Parsers.stringArray(obj.phones as unknown[]),
			Parsers.string(obj.email)
		);
	}
}
