import {AddMoneyFundingStageType} from "../../../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import {IRootState} from "../../../../../../redux/rootReducer";
import {ReactComponent as ProgressBarOne} from "../../../../../../assets/svg/AddMoney/ProgressBarOne.svg";
import {ReactComponent as ProgressBarTwo} from "../../../../../../assets/svg/AddMoney/ProgressBarTwo.svg";
import React from "react";
import {useSelector} from "react-redux";

interface AddMoneyLayoutInterface {
	children: React.ReactNode;
}

function AddMoneyLayout({children}: AddMoneyLayoutInterface): JSX.Element {
	const paymentStage = useSelector((state: IRootState) => state.addMoney.fundingStage);
	return (
		<>
			<div className="flex h-full w-full flex-col">
				<div className="flex flex-col items-start justify-start border-b-0.2 border-grey py-4">
					<span className="text-base font-medium  text-black md:text-lg">Add money</span>
					<span className="text-xs font-normal  text-black-tertiary md:text-sm">Add money to your Lenco account</span>
				</div>
				<div className="flex h-full w-full flex-col lg:flex-row">
					{/* <=== Start of Stage Level Component ===> */}

					{paymentStage !== AddMoneyFundingStageType.PROCESS_PAYMENT && (
						<div className="hidden h-full w-min flex-row items-start justify-start space-x-5 pr-20 pt-8 lg:flex  ">
							<div className="flex w-max  items-end justify-end">
								<div className="pt-1">
									{paymentStage === AddMoneyFundingStageType.INITIAL ? <ProgressBarOne /> : <ProgressBarTwo />}
								</div>
							</div>
							<div className="mt-0.5 flex w-max flex-col space-y-4.5 text-sm">
								<span
									className={
										` ` +
										`${paymentStage === AddMoneyFundingStageType.INITIAL ? "text-blue" : " "} ` +
										`${paymentStage > AddMoneyFundingStageType.INITIAL ? "text-black-secondary " : " "} `
									}
								>
									Select account to fund
								</span>
								<span
									className={
										` ` +
										`${paymentStage === AddMoneyFundingStageType.PAYMENT_DETAILS ? "text-blue" : " "} ` +
										`${paymentStage > AddMoneyFundingStageType.PAYMENT_DETAILS ? "text-black-secondary " : " "} ` +
										`${paymentStage < AddMoneyFundingStageType.PAYMENT_DETAILS ? "text-black-tertiary " : " "} `
									}
								>
									Add money details
								</span>
							</div>
						</div>
					)}

					{/* <=== End of Stage Level Component ===> */}
					{children}
				</div>
			</div>
		</>
	);
}

export default AddMoneyLayout;
