import {ExternalApplicationRequests, abortExternalApplicationRequest, externalDirectorInit} from "../service/externalApplication.api";
import {setExternalApplicationInit, setExternalParams} from "../../../../../redux/externalApplication/externalApplicationSlice";
import {useCallback, useState} from "react";

import {ExternalInitRequest} from "../service/externalApplication.types";
import {errorTrue} from "../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../utils/getErrorMessage";
import {nigeriaBusinessAccountManger} from "../../../../Dashboard/Application/services/nigeria-application/nigeiraAccountOpeningInit";
import {setNigeriaAccountManager} from "../../../../../redux/application/applicationSlice";
import {useDispatch} from "react-redux";

export interface UseExternalApplicationInitInterface {
	externalApplicationInitError: string;
	isExternalApplicationInitLoading: boolean;

	handleRest: () => void;
	handleNigeriaAccountManager: () => Promise<void>;
	handleExternalApplicationInit: (_data: ExternalInitRequest) => Promise<void>;
}

const useExternalApplicationInit = (): UseExternalApplicationInitInterface => {
	const dispatch = useDispatch();

	const [externalApplicationInitError, setExternalApplicationInitError] = useState<string>("");
	const [isExternalApplicationInitLoading, setIsExternalApplicationInitLoading] = useState<boolean>(false);

	const handleExternalApplicationInit = useCallback(
		async (_data: ExternalInitRequest): Promise<void> => {
			try {
				setExternalApplicationInitError("");
				setIsExternalApplicationInitLoading(true);
				const res = await externalDirectorInit(_data);
				dispatch(setExternalApplicationInit(res));
				dispatch(
					setExternalParams({
						a: _data.a,
						b: _data.b,
						type: _data.type,
					})
				);
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				dispatch(errorTrue(errorMessage));
				setExternalApplicationInitError(errorMessage);
			} finally {
				setIsExternalApplicationInitLoading(false);
			}
		},
		[dispatch]
	);

	const handleNigeriaAccountManager = useCallback(async () => {
		try {
			const res = await nigeriaBusinessAccountManger();
			dispatch(setNigeriaAccountManager(res));
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			dispatch(errorTrue(errorMessage));
		}
	}, [dispatch]);

	const handleRest = () => {
		setIsExternalApplicationInitLoading(false);
		abortExternalApplicationRequest(ExternalApplicationRequests.INIT);
	};

	return {
		externalApplicationInitError,
		isExternalApplicationInitLoading,
		handleRest,
		handleNigeriaAccountManager,
		handleExternalApplicationInit,
	};
};
export default useExternalApplicationInit;
