import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import BulkPaymentHeaders from "./BulkPaymentHeaders";
import {BulkPaymentInitRequest} from "../../../Services/SendMoney/bulkTransferApi.types";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import {IRootState} from "../../../../../../redux/rootReducer";
import {OtpActivityType} from "../../../../../../helpers/AppConstants";
import OtpInputForm from "../../../../../../components/OtpInputForm";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {TailSpin} from "react-loader-spinner";
import formatNumber from "../../../../../../utils/formatNumber";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import useBulkTransferOtp from "../../../Hooks/State/SendMoney/BulkTransfer/useBulkTransferOtp";
import useOtpInit from "../../../Hooks/State/SendMoney/SingleTransfer/useOtpInit";

function MakePayment(): JSX.Element {
	const dispatch = useDispatch();

	const {minutes, seconds, isInitOTPLoading, handleOTPInit, handleResendOTPInit} = useOtpInit({});
	const {bulkPaymentOtp, bulkPaymentError, transferDetailArray, bulkPaymentResponse, isBulkPaymentLoading, handleOtpChange, handleBulkTransfer} =
		useBulkTransferOtp();
	const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);
	const bulkTransfersAmountTotal = useSelector((state: IRootState) => state.bulkTransfer.bulkTransfersAmountTotal);

	useLayoutEffect(() => {
		if (isAuthAppSetup) return;
		handleOTPInit({action: OtpActivityType.PROCESS_TRANSACTION});
	}, [handleOTPInit, isAuthAppSetup]);

	useEffect(() => {
		if (!bulkPaymentResponse) return;
		dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
	}, [bulkPaymentResponse]);
	const [resendOtpText, setResendOtpText] = useState(false);
	return (
		<>
			<div className=" flex w-full flex-col items-center justify-center lg:items-start lg:justify-start">
				<BulkPaymentHeaders />
				{!isAuthAppSetup && isInitOTPLoading && (
					<>
						<div className="flex flex-row items-center justify-start space-x-4 text-sm">
							<TailSpin color="#5466F9" height={20} width={20} />
							<span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
						</div>
					</>
				)}
				{!isInitOTPLoading && (
					<div className="flex w-full max-w-sm flex-col space-y-8">
						<div className="text-sm font-normal">
							{isAuthAppSetup && "Enter the OTP generated from your token App to approve the bulk payment of "}
							{!isAuthAppSetup && "Enter the OTP sent to your email to approve the bulk payment of "}
							<span>
								<CurrencyCode />
								{formatNumber(bulkTransfersAmountTotal)}
							</span>
						</div>

						<div className="sm:w-100% mx-auto flex w-full flex-col md:w-full">
							<span>
								<OtpInputForm value={bulkPaymentOtp || undefined} otpFunc={handleOtpChange} errorMessage={bulkPaymentError} />
							</span>
							{!isAuthAppSetup && (
								<div className="flex items-start justify-start pt-4">
									<div>
										{minutes === 0 && seconds === 0 ? (
											<div>
												<span
													className="cursor-pointer text-sm text-blue"
													onClick={() => {
														handleResendOTPInit({action: OtpActivityType.PROCESS_TRANSACTION});
														setResendOtpText(true);
													}}
												>
													Resend OTP
												</span>
											</div>
										) : (
											<span className="cursor-not-allowed  text-sm text-black-tertiary">
												Resend OTP in{" "}
												<span className="text-black-secondary">
													{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
												</span>
											</span>
										)}
									</div>
								</div>
							)}
							<div className="flex w-full flex-row items-center justify-center space-x-4 pt-8">
								<ButtonComp
									type="button"
									ripple="light"
									buttonType="secondary"
									color="grey"
									func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
								>
									<span>Back</span>
								</ButtonComp>

								<ButtonComp
									type="submit"
									color="blue"
									ripple="light"
									buttonType="primary"
									isLoading={isBulkPaymentLoading}
									disable={bulkPaymentOtp.length < 6}
									func={() => {
										const bulkPaymentDetails: BulkPaymentInitRequest = {
											payments: transferDetailArray.map((_el) => ({
												originatingAccountId: _el.originatingAccountId as string,
												recipient: _el.recipient,
												amount: _el.amount,
												purpose: _el.purpose,
												key: _el.key,
											})),
											otp: bulkPaymentOtp,
										};
										void handleBulkTransfer(bulkPaymentDetails);
									}}
								>
									<span>Authorize</span>
								</ButtonComp>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}

export default MakePayment;
