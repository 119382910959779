import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class CardAssignedTo {
	[immerable] = true;

	constructor(public id: string, public firstName: string, public lastName: string) {}

	static create(obj: GenericObject): CardAssignedTo {
		return new CardAssignedTo(Parsers.string(obj.id), Parsers.string(obj.firstName), Parsers.string(obj.lastName));
	}
	get fullName(): string {
		return `${this.firstName} ${this.lastName}`;
	}
}
