import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class CardMin {
	[immerable] = true;

	constructor(public id: string, public name: string, public panLast4Digits: string, public createRequestId: string) {}

	static create(obj: GenericObject): CardMin {
		return new CardMin(Parsers.string(obj.id), Parsers.string(obj.name), Parsers.string(obj.panLast4Digits), Parsers.string(obj.createRequestId));
	}
}
