import "react-circular-progressbar/dist/styles.css";

import {CircularProgressbar, buildStyles} from "react-circular-progressbar";
import React, {ReactNode} from "react";

// import useClickOutside from "../../../hooks/useClickOutside";

interface Props {
	header: ReactNode;
	tooltip?: boolean;
	progress: number;
	subTitle?: string;
	subTitle2?: string;
	children: ReactNode;
}

function ApplicationHeader(props: Props): JSX.Element {
	return (
		<div className="w-full">
			<div className="w-full pb-4">
				<div className="flex flex-row justify-between items-center">
					<div className="flex flex-col justify-start font-medium text-md text-black-secondary">
						<div className="flex justify-start items-start capitalize">
							<span>{props.header}</span>
							{props.tooltip && <span className="ml-2">i</span>}
						</div>
						{props.subTitle && (
							<div className="text-black-tertiary text-sm font-normal">
								{props.subTitle} <br />
								{props.subTitle2}
							</div>
						)}
					</div>
					<div>
						<div className="ml-4 w-16 h-16">
							<CircularProgressbar
								value={props.progress}
								text={`${props.progress}%`}
								styles={buildStyles({
									// Colors
									pathColor: `rgba(84, 102, 249, 1)`,
									textColor: "rgba(113, 116, 140, 1)",
									trailColor: "rgba(241, 241, 244, 1)",
									backgroundColor: "rgba(255, 255, 255, 1)",
								})}
							/>
						</div>
					</div>
				</div>
			</div>

			<div className="w-full pt-4 block">{props.children}</div>
		</div>
	);
}

export default ApplicationHeader;
