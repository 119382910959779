import React, {useEffect, useState} from "react";

import FullPageTrayFooter from "../../../../../../../components/General/Trays/FullPageTray/FullPageTrayFooter";
import {PENDING_APPROVAL} from "../../../../../../../helpers/AppConstants";
import StandAlonePendingApproval from "../../../../../Payments/Components/Elements/PendingApproval/StandAlonePendingApproval";
import Transaction from "../../../../../../../models/transaction";

interface TransactionsDetailCardPendingApprovalActionsInterface {
	isAdmin: boolean;
	dataType: string;
	transaction: Transaction;
	handleUpdateDeclinedTransaction: () => void;
	handleTogglePendingApprovalShowModal: () => void;
}

function TransactionsDetailCardPendingApprovalActions({
	isAdmin,
	dataType,
	transaction,
	handleUpdateDeclinedTransaction,
	handleTogglePendingApprovalShowModal,
}: TransactionsDetailCardPendingApprovalActionsInterface): JSX.Element {
	const [transactionDetail, setTransactionDetail] = useState<Transaction | null>(null);

	useEffect(() => {
		if (!transaction) return;
		setTransactionDetail(transaction);
	}, [transaction]);

	return (
		<>
			{transactionDetail &&
				isAdmin &&
				transactionDetail.status === PENDING_APPROVAL &&
				transactionDetail.destination &&
				(transactionDetail.destination.customerAccount ||
					transactionDetail.destination.userAccount ||
					transactionDetail.destination.card) && (
					<>
						<FullPageTrayFooter dataType={dataType}>
							<div className="flex w-full items-center justify-center text-black-tertiary" data-type={dataType}>
								<StandAlonePendingApproval
									transaction={transactionDetail}
									handleShowModal={handleTogglePendingApprovalShowModal}
									handleUpdateState={handleUpdateDeclinedTransaction}
								/>
							</div>
						</FullPageTrayFooter>
					</>
				)}
		</>
	);
}

export default TransactionsDetailCardPendingApprovalActions;
