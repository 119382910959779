import {} from "../../../utils/preReleaseConfig";

import {PaymentStageType, PaymentType} from "../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {SendMoneyOptionType, ZambiaSendMoneyOptionType} from "./SendMoney/sendMoney.types";
import {setPaymentStage, setPaymentType} from "../../../redux/payments/sendMoney/slice/sendMoneySlice";

import {ReactComponent as AddFundsIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/AddFundsIcon.svg";
import {ReactComponent as BankTransferIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/bankTransfer.svg";
import {ReactComponent as BulkTransferIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/BulkTransferIcon.svg";
import {ReactComponent as CardPaymentIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/cardPayment.svg";
import {ReactComponent as FundCardIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/card.svg";
import {History} from "history";
import {ReactComponent as MobileMoneyIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/mobileMoney.svg";
import {PaymentsType} from "../../../modules/Dashboard/Payments/Hooks/State/SendMoney/payments.constants";
import React from "react";
import {ReactComponent as SingleTransferIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/SingleTransferIcon.svg";
import {ReactComponent as TransferBtwAccountsIcon} from "../../../assets/svg/DashboardLayout/SendMoneyDropdown/TransferBtwAccountsIcon.svg";
import store from "../../../redux/store";

export interface SendMoneyOption {
	icon: JSX.Element;
	text: string;
	type?: SendMoneyOptionType | ZambiaSendMoneyOptionType;
	onClick(history: History, handleOpenAddFundsModal?: () => void): void;
}

export const SendMoneyOptions: Array<SendMoneyOption> = [
	{
		icon: <SingleTransferIcon className=" stroke-current" />,
		text: "Single Transfer",
		type: SendMoneyOptionType.SINGLE_TRANSFER,
		onClick: function (history: History): void {
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			store.dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
			return history.push({
				pathname: "/payments/make/single",
				state: {
					typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
				},
			});
		},
	},
	{
		icon: <TransferBtwAccountsIcon className="stroke-current" />,
		text: "Transfer Between Accounts",
		type: SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS,
		onClick: function (history: History): void {
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			store.dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
			return history.push({
				pathname: "/payments/make/accounts",
				state: {typeOfTransfer: PaymentsType.TRANSFER_BETWEEN_ACCOUNT},
			});
		},
	},
	{
		icon: <BulkTransferIcon className="stroke-current" />,
		text: "Create a Bulk Transfer",
		type: SendMoneyOptionType.BULK_TRANSFER,
		onClick: function (history: History): void {
			store.dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			return history.push({
				pathname: "/payments/make/bulk",
				state: {typeOfTransfer: PaymentsType.BULK_TRANSFER},
			});
		},
	},
	{
		icon: <AddFundsIcon className="stroke-current" />,
		text: "Add Money",
		type: SendMoneyOptionType.ADD_MONEY,
		onClick: function (history: History, handleOpenAddFundsModal: () => void): void {
			return handleOpenAddFundsModal && handleOpenAddFundsModal();
		},
	},
	{
		icon: <FundCardIcon className="stroke-current" />,
		text: "Fund Card",
		type: SendMoneyOptionType.FUND_CARD,
		onClick: function (history: History): void {
			store.dispatch(setPaymentType(PaymentType.FUND_CARDS));
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			return history.push({
				pathname: "/payments/make/cards",
				state: {typeOfTransfer: PaymentsType.FUND_CARD},
			});
		},
	},
];

export const ZambiaSendMoneyOptions: Array<SendMoneyOption> = [
	{
		icon: <SingleTransferIcon className=" stroke-current" />,
		text: "Single Transfer",
		type: SendMoneyOptionType.SINGLE_TRANSFER,
		onClick: function (history: History): void {
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			store.dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
			return history.push({
				pathname: "/payments/make/single",
				state: {
					typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
				},
			});
		},
	},
	{
		icon: <TransferBtwAccountsIcon className="stroke-current" />,
		text: "Transfer Between Accounts",
		type: SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS,
		onClick: function (history: History): void {
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			store.dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
			return history.push({
				pathname: "/payments/make/accounts",
				state: {typeOfTransfer: PaymentsType.TRANSFER_BETWEEN_ACCOUNT},
			});
		},
	},
	// {
	// icon: <BulkTransferIcon className="stroke-current" />,
	// text: "Create a Bulk Transfer",
	// type: SendMoneyOptionType.BULK_TRANSFER,
	// onClick: function (history: History): void {
	// store.dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
	// store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
	// return history.push({
	// pathname: "/payments/make/bulk",
	// state: {typeOfTransfer: PaymentsType.BULK_TRANSFER},
	// });
	// },
	// },
	{
		icon: <AddFundsIcon className="stroke-current" />,
		text: "Add Money",
		type: SendMoneyOptionType.ADD_MONEY,
		onClick: function (): void {
			return;
		},
	},
	// {
	// icon: <FundCardIcon className="stroke-current" />,
	// text: "Fund Card",
	// type: SendMoneyOptionType.FUND_CARD,
	// onClick: function (history: History): void {
	// store.dispatch(setPaymentType(PaymentType.FUND_CARDS));
	// store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
	// return history.push({
	// pathname: "/payments/make/cards",
	// state: {typeOfTransfer: PaymentsType.FUND_CARD},
	// });
	// },
	// },
];

export const SendMoneyOptionsPreRelease: Array<SendMoneyOption> = [
	{
		icon: <SingleTransferIcon className=" stroke-current" />,
		text: "Single Transfer",
		type: SendMoneyOptionType.SINGLE_TRANSFER,
		onClick: function (history: History): void {
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			store.dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
			return history.push({
				pathname: "/payments/make/single",
				state: {
					typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
				},
			});
		},
	},
	{
		icon: <TransferBtwAccountsIcon className="stroke-current" />,
		text: "Transfer Between Accounts",
		type: SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS,
		onClick: function (history: History): void {
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			store.dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
			return history.push({
				pathname: "/payments/make/accounts",
				state: {typeOfTransfer: PaymentsType.TRANSFER_BETWEEN_ACCOUNT},
			});
		},
	},
	{
		icon: <BulkTransferIcon className="stroke-current" />,
		text: "Create a Bulk Transfer",
		type: SendMoneyOptionType.BULK_TRANSFER,
		onClick: function (history: History): void {
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			store.dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
			return history.push({
				pathname: "/payments/make/bulk",
				state: {typeOfTransfer: PaymentsType.BULK_TRANSFER},
			});
		},
	},
	{
		icon: <AddFundsIcon className="stroke-current" />,
		text: "Add Money",
		type: SendMoneyOptionType.ADD_MONEY,
		onClick: function (history: History, handleOpenAddFundsModal: () => void): void {
			return handleOpenAddFundsModal && handleOpenAddFundsModal();
		},
	},
	{
		icon: <FundCardIcon className="stroke-current" />,
		text: "Fund Card",
		type: SendMoneyOptionType.FUND_CARD,
		onClick: function (history: History): void {
			store.dispatch(setPaymentType(PaymentType.FUND_CARDS));
			store.dispatch(setPaymentStage(PaymentStageType.INITIAL));
			return history.push({
				pathname: "/payments/make/cards",
				state: {typeOfTransfer: PaymentsType.FUND_CARD},
			});
		},
	},
];

export const ZambiaAddMoneyInnerOptions: Array<SendMoneyOption> = [
	{
		icon: <MobileMoneyIcon className="stroke-current" />,
		text: "Mobile Money",
		type: ZambiaSendMoneyOptionType.MOBILE_MONEY,
		onClick: function (history: History): void {
			history.push("/payments/add-money/make/mobile-money");
			return;
		},
	},
	{
		icon: <CardPaymentIcon className="stroke-current" />,
		text: "Card Payment",
		type: ZambiaSendMoneyOptionType.CARD_PAYMENT,
		onClick: function (history: History): void {
			history.push("/payments/add-money/make/card-payment");
			return;
		},
	},
	{
		icon: <BankTransferIcon className="stroke-current" />,
		text: "Bank Transfer",
		type: ZambiaSendMoneyOptionType.BANK_TRANSFER,
		onClick: function (history: History): void {
			history.push("/payments/add-money/make/bank-transfer");
			return;
		},
	},
];
