import {Link, useLocation} from "react-router-dom";
import React, {useCallback, useEffect, useRef, useState} from "react";
import {SearchPageItem, listOfPages} from "../../../helpers/Data/SearchBar/listOfPages";
import {
	setIsZambiaCollectionsSettlementsDetailCardOpen,
	setSelectedZambiaCollectionsSettlementsDetails,
} from "../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";
import {
	setIsZambiaCollectionsTransactionsDetailCardOpen,
	setSelectedZambiaCollectionsTransactionsDetails,
} from "../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import {setIsZambiaPayoutsDetailCardOpen, setSelectedZambiaPayoutsDetails} from "../../../redux/zambia/payouts/zambiaPayoutsSlice";
import {useDispatch, useSelector} from "react-redux";

import {AccountsSearchCard} from "./AccountsSearch";
import Card from "../../../models/card";
import {CardsSearchCard} from "./CardsSearch";
import CustomerAccount from "../../../models/customerAccount";
import {IRootState} from "../../../redux/rootReducer";
import PagesSearch from "./PagesSearch";
import SearchBarFeatureBodyAmount from "./Component/SearchBarFeatureBodyAmount";
import SearchBarFeatureHeader from "./Component/SearchBarFeatureHeader";
import {ReactComponent as SearchIcon} from "../../../assets/svg/DashboardLayout/SearchBar/searchIcon.svg";
import Transaction from "../../../models/transaction";
import TransactionsSearch from "./TransactionsSearch";
import ZambiaCollectionRequest from "../../../models/zambia/zambiaCollectionRequest";
import ZambiaSettlement from "../../../models/zambia/zambiaSettlement";
import {mapToArray} from "../../../utils/map";
import {showTransactionDetails} from "../../../redux/transaction/slice/transactionSlice";
import useClickOutside from "../../../hooks/useClickOutside";

interface SearchBarProps {
	placeholder: string;
}

function SearchBar({placeholder}: SearchBarProps): JSX.Element {
	const dispatch = useDispatch();
	const location = useLocation();

	const main = useSelector((state: IRootState) => state.init.main);
	const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isZambia || state.zambiaApplication.init);
	const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isNigeria);
	const cachedCards = useSelector((state: IRootState) => state.init.main?.companyDetails.cards || []);
	const customerList = useSelector<IRootState, Map<string, CustomerAccount>>((state: IRootState) => state.customerAccount.customerAccounts);
	const TransactionList = useSelector<IRootState, Map<string, Transaction>>((state: IRootState) => state.transaction.transactions);
	const cachedZambiaCollectionsTransactionsList = useSelector(
		(state: IRootState) => state.zambiaCollectionsTransactions.zambiaCachedCollectionsTransactionsList
	);
	const cachedZambiaCollectionsSettlementsList = useSelector(
		(state: IRootState) => state.zambiaCollectionsSettlements.zambiaCachedCollectionsSettlementsList
	);
	const cachedZambiaPayoutsList = useSelector((state: IRootState) => state.zambiaPayouts.zambiaCachedPayoutsList);

	const [active, setActive] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [hasSearchTerm, setHasSearchTerm] = useState<boolean>(false);

	const [cachedAccounts, setCachedAccounts] = useState<CustomerAccount[]>([]);
	const [cachedTransactions, setCachedTransactions] = useState<Transaction[]>([]);
	const [cachedZambiaPayouts, setCachedZambiaPayouts] = useState<Transaction[]>([]);
	const [cachedZambiaCollectionsSettlements, setCachedZambiaCollectionsSettlements] = useState<ZambiaSettlement[]>([]);
	const [cachedZambiaCollectionsTransactions, setCachedZambiaCollectionsTransactions] = useState<ZambiaCollectionRequest[]>([]);

	const [filteredCards, setFilteredCards] = useState<Card[]>([]);
	const [filteredPages, setFilteredPages] = useState<SearchPageItem[]>([]);
	const [filteredAccounts, setFilteredAccounts] = useState<CustomerAccount[]>([]);
	const [filteredTransactions, setFilteredTransactions] = useState<Transaction[]>([]);
	const [filteredZambiaPayouts, setFilteredZambiaPayouts] = useState<Transaction[]>([]);
	const [filteredZambiaCollectionsSettlements, setFilteredZambiaCollectionsSettlements] = useState<ZambiaSettlement[]>([]);
	const [filteredZambiaCollectionsTransactions, setFilteredZambiaCollectionsTransactions] = useState<ZambiaCollectionRequest[]>([]);

	const inputRef = useRef<HTMLInputElement | null>(null);

	const domNode = useClickOutside(() => {
		setActive(false), (eventTarget: HTMLElement) => eventTarget.dataset.type !== "transaction";
	});

	useEffect(() => {
		if (!customerList) return;
		setCachedAccounts(mapToArray(customerList));
	}, [customerList]);

	useEffect(() => {
		if (!TransactionList) return;
		setCachedTransactions(mapToArray(TransactionList));
	}, [TransactionList]);

	useEffect(() => {
		if (cachedZambiaCollectionsTransactionsList.length < 1) return;
		setCachedZambiaCollectionsTransactions(cachedZambiaCollectionsTransactionsList);
	}, [cachedZambiaCollectionsTransactionsList]);

	useEffect(() => {
		if (cachedZambiaCollectionsSettlementsList.length < 1) return;
		setCachedZambiaCollectionsSettlements(cachedZambiaCollectionsSettlementsList);
	}, [cachedZambiaCollectionsSettlementsList]);

	useEffect(() => {
		if (cachedZambiaPayoutsList.length < 1) return;
		setCachedZambiaPayouts(cachedZambiaPayoutsList);
	}, [cachedZambiaPayoutsList]);

	useEffect(() => {
		// setSearchTerm(searchTerm.trim());
		setHasSearchTerm(searchTerm.trim().length > 0);
	}, [searchTerm]);

	useEffect(() => {
		if (main) {
			const uniqueTransactions = new Map<string, Transaction>();

			cachedTransactions
				.filter((item) => {
					const searchQuery = searchTerm.trim().toLowerCase();
					if (!searchQuery) {
						return false;
					}

					return (
						(!!item.userAccount && item.userAccount.accountName.toLowerCase().includes(searchQuery)) ||
						(!!item.otherAccount && item.otherAccount.accountName.toLowerCase().includes(searchQuery))
					);
				})
				.forEach((item) => {
					uniqueTransactions.set(item.id, item);
				});

			setFilteredTransactions(Array.from(uniqueTransactions.values()));
		}
	}, [main, cachedTransactions, searchTerm]);

	useEffect(() => {
		if (main) {
			const uniqueAccounts = new Map<string, CustomerAccount>();
			cachedAccounts
				.filter((item) => {
					const searchQuery = searchTerm.trim().toLowerCase();
					if (!searchQuery) {
						return false;
					}

					return item.accountName.toLowerCase().includes(searchQuery) || item.accountNumber.toLowerCase().includes(searchQuery);
				})
				.forEach((item) => {
					uniqueAccounts.set(item.id, item);
				});
			setFilteredAccounts(Array.from(uniqueAccounts.values()));
		}
	}, [main, cachedAccounts, searchTerm]);

	useEffect(() => {
		if (main) {
			setFilteredCards(
				cachedCards.filter((item) => {
					const searchQuery = searchTerm.trim().toLowerCase();
					if (!searchQuery) {
						return false;
					}
					return item.name.toLowerCase().includes(searchTerm) || item.maskedPan.toLowerCase().includes(searchTerm);
				})
			);
		}
	}, [main, cachedCards, searchTerm]);

	useEffect(() => {
		if (main) {
			setFilteredZambiaCollectionsTransactions(
				cachedZambiaCollectionsTransactions.filter((item) => {
					const searchQuery = searchTerm.trim().toLowerCase();
					if (!searchQuery) {
						return false;
					}
					return item.bankingAppRequestPayment?.recipientAccount.accountName.toLowerCase().includes(searchTerm);
				})
			);
		}
	}, [main, cachedZambiaCollectionsTransactions, searchTerm]);

	useEffect(() => {
		if (main) {
			setFilteredZambiaCollectionsSettlements(
				cachedZambiaCollectionsSettlements.filter((item) => {
					const searchQuery = searchTerm.trim().toLowerCase();
					if (!searchQuery) {
						return false;
					}
					return item.mobileMoneyCollection?.debitAccountName?.toLowerCase().includes(searchTerm);
				})
			);
		}
	}, [main, cachedZambiaCollectionsSettlements, searchTerm]);

	useEffect(() => {
		if (main) {
			setFilteredZambiaPayouts(
				cachedZambiaPayouts.filter((item) => {
					const searchQuery = searchTerm.trim().toLowerCase();
					if (!searchQuery) {
						return false;
					}
					return (
						item.origination?.account?.accountName?.toLowerCase().includes(searchTerm) ||
						item.destination?.customerAccount?.bankAccount?.accountName.toLowerCase().includes(searchTerm)
					);
				})
			);
		}
	}, [main, cachedZambiaPayouts, searchTerm]);

	useEffect(() => {
		setFilteredPages(
			listOfPages.filter((item) => {
				const searchQuery = searchTerm.trim().toLowerCase();
				if (!searchQuery) {
					return false;
				}
				return item.name.toLowerCase().includes(searchTerm) || item.url.toLowerCase().includes(searchTerm);
			})
		);
	}, [searchTerm]);

	const handleShowTransactionDetail = useCallback(
		(t: Transaction) => {
			dispatch(showTransactionDetails(t.id));
		},
		[dispatch]
	);

	return (
		<div
			className={
				`mx-auto hidden h-10 w-full max-w-xl cursor-text items-center justify-start border border-solid px-4 lg:flex ` +
				`focus:outline-none relative transition-all duration-150 ease-in-out focus:border-blue-focused focus:text-blue  ` +
				`${active ? `border-blue text-blue ${hasSearchTerm ? "rounded-b-none rounded-t-lg" : "rounded-custom"} ` : ""} ` +
				`${!active ? "rounded-custom" : ""} ` +
				`${!active && hasSearchTerm ? "border-blue text-black" : ""} ` +
				`${!active && !hasSearchTerm ? "border-black-quin text-black-quat hover:border-blue hover:text-blue focus:border-blue" : ""} `
			}
			ref={domNode}
			onClick={() => {
				setActive(true);
				if (inputRef.current) {
					inputRef.current.focus();
				}
			}}
			tabIndex={0}
			onFocus={() => {
				if (inputRef.current) {
					inputRef.current.focus();
				}
				setActive(true);
			}}
			// onBlur={() => {
			// setActive(false);
			// }}
			data-type="transaction"
		>
			<SearchIcon className="stroke-current" tabIndex={-1} data-type="transaction" />

			<input
				type="text"
				ref={inputRef}
				className="outline-none focus:outline-none ml-2 w-full text-sm font-normal text-black-secondary antialiased placeholder-black-quat"
				placeholder={placeholder}
				value={searchTerm}
				onChange={(e) => {
					setSearchTerm(e.target.value);
				}}
				maxLength={40}
				tabIndex={-1}
				data-type="transaction"
			/>

			{hasSearchTerm && (
				<span
					className="cursor-pointer pr-1 text-sm text-blue"
					onClick={(e) => {
						inputRef.current?.blur();
						setSearchTerm("");
						setActive(false);
						e.preventDefault();
						e.stopPropagation();
					}}
					tabIndex={-1}
					data-type="transaction"
				>
					Clear
				</span>
			)}

			<div className="absolute -bottom-0.5 left-0 w-full " tabIndex={-1} data-type="transaction">
				<div
					className={
						`absolute z-40 max-h-120 w-full overflow-auto rounded-b-lg bg-white py-4 shadow-custom ` +
						`${!active || !hasSearchTerm ? "hidden" : ""}`
					}
					tabIndex={-1}
					data-type="transaction"
				>
					<div className="flex flex-col flex-grow">
						{/* //Accounts */}
						<div className="w-full">
							<div
								className="flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5"
								tabIndex={-1}
								data-type="transaction"
							>
								<div className="" tabIndex={-1} data-type="transaction">
									<p className="text-xs text-black-tertiary" data-type="transaction">
										Accounts
									</p>
								</div>
								<div className="" tabIndex={-1} data-type="transaction">
									<p className="cursor-pointer text-xs text-blue-tertiary hover:text-blue" tabIndex={-1} data-type="transaction">
										<Link
											to={`/payments/recipients/${searchTerm}`}
											onClick={() => {
												inputRef.current?.blur();
												setSearchTerm("");
											}}
											tabIndex={active ? 0 : -1}
											data-type="transaction"
										>
											Search Recipients
										</Link>
									</p>
								</div>
							</div>
							<div className="accounts-search" tabIndex={-1} data-type="transaction">
								{active &&
									filteredAccounts.slice(0, 5).map((el, index) => (
										<AccountsSearchCard
											key={`search-account-${index}`}
											account={el}
											index={index}
											onClick={() => {
												setActive(false);
												setSearchTerm("");
												inputRef.current?.blur();
											}}
											data-type="transaction"
										/>
									))}
							</div>
						</div>
						{/* //Cards*/}
						{isNigeria && (
							<div className="w-full">
								<div
									className="mt-3 flex flex-row justify-between border-b  border-grey-tertiary px-4 pb-2.5"
									tabIndex={-1}
									data-type="transaction"
								>
									<div className="" tabIndex={-1} data-type="transaction">
										<p className="text-xs text-black-tertiary" data-type="transaction">
											Cards
										</p>
									</div>
								</div>
								<div className="accounts-search" tabIndex={-1} data-type="transaction">
									{active &&
										filteredCards.slice(0, 5).map((el, index) => (
											<CardsSearchCard
												key={`search-account-${index}`}
												card={el}
												index={index}
												onClick={() => {
													setActive(false);

													inputRef.current?.blur();
													setSearchTerm("");
												}}
												data-type="transaction"
											/>
										))}
								</div>
							</div>
						)}
						{/* //Transactions */}
						<div className="w-full">
							<div
								className="my-3 flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5"
								tabIndex={-1}
								data-type="transaction"
							>
								<div className="" tabIndex={-1} data-type="transaction">
									<p className="text-xs text-black-tertiary" tabIndex={-1} data-type="transaction">
										Transactions
									</p>
								</div>
								<div className="" tabIndex={-1} data-type="transaction">
									<p className="cursor-pointer text-xs text-blue-tertiary hover:text-blue" tabIndex={-1} data-type="transaction">
										<Link
											to={`/transactions/search/${searchTerm}`}
											onClick={() => {
												setActive(false);

												inputRef.current?.blur();
												setSearchTerm("");
											}}
											tabIndex={active ? 0 : -1}
											data-type="transaction"
										>
											Search Transactions
										</Link>
									</p>
								</div>
							</div>
							<div data-type="transaction" tabIndex={-1}>
								{active &&
									filteredTransactions.slice(0, 5).map((el, index) => (
										<div
											onClick={() => {
												setActive(false);

												inputRef.current?.blur();
												setSearchTerm("");
											}}
											key={`search-transaction-${index}`}
											tabIndex={-1}
											data-type="transaction"
										>
											<TransactionsSearch
												data-type="transaction"
												transaction={el}
												index={index}
												handleShowTransactionDetail={handleShowTransactionDetail}
											/>
										</div>
									))}
							</div>
						</div>
						{/* //Collections */}
						{isZambia && (
							<div className={`${location.pathname.includes("/lenco-pay/collections/transactions") ? "order-first" : ""} w-full`}>
								<SearchBarFeatureHeader
									dataType="zambia-collections-transaction"
									feature="Collections"
									isActive={active}
									isFilterEmpty={filteredZambiaCollectionsTransactions.length < 1}
									isCurrentPage={location.pathname.includes("/lenco-pay/collections/transactions")}
									searchLink={`/lenco-pay/collections/transactions/search/${searchTerm}`}
									onSearch={() => {
										setActive(false);
										inputRef.current?.blur();
										setSearchTerm("");
									}}
								/>
								{active &&
									filteredZambiaCollectionsTransactions.slice(0, 5).map((_collection, _index) => (
										<SearchBarFeatureBodyAmount
											key={_index}
											amount={_collection.amount}
											date={_collection.createdAt}
											name={_collection.bankingAppRequestPayment?.recipientAccount.accountName || ""}
											currency={_collection.currency}
											isCurrentPage={location.pathname.includes("/lenco-pay/collections/transactions")}
											isDebit={false}
											isFailed={_collection.isFailed || _collection.isExpired}
											isDeclined={_collection.isCancelled}
											isSuccessful={_collection.isSuccessful}
											isProcessing={_collection.isPending}
											dataType="zambia-collections-transaction"
											onClick={() => {
												dispatch(setIsZambiaCollectionsTransactionsDetailCardOpen(true));
												dispatch(setSelectedZambiaCollectionsTransactionsDetails(_collection.id));
											}}
										/>
									))}
							</div>
						)}
						{/* //Settlements */}
						{isZambia && (
							<div className={`${location.pathname.includes("/lenco-pay/collections/settlements") ? "order-first" : ""} w-full`}>
								<SearchBarFeatureHeader
									dataType="zambia-collections-settlements"
									feature="Settlements"
									isActive={active}
									isFilterEmpty={filteredZambiaCollectionsSettlements.length < 1}
									isCurrentPage={location.pathname.includes("/lenco-pay/collections/settlements")}
									searchLink={`/lenco-pay/collections/settlements/search/${searchTerm}`}
									onSearch={() => {
										setActive(false);
										inputRef.current?.blur();
										setSearchTerm("");
									}}
								/>
								{active &&
									filteredZambiaCollectionsSettlements.slice(0, 5).map((_collection, _index) => (
										<SearchBarFeatureBodyAmount
											key={_index}
											amount={_collection.settlementAmount}
											date={_collection.createdAt}
											name={_collection.mobileMoneyCollection?.debitAccountName || ""}
											currency={_collection.currency}
											isCurrentPage={location.pathname.includes("/lenco-pay/collections/settlements")}
											isDebit={false}
											isFailed={false}
											isDeclined={false}
											isSuccessful={_collection.isSettled}
											isProcessing={_collection.isPending}
											dataType="zambia-collections-settlements"
											onClick={() => {
												dispatch(setIsZambiaCollectionsSettlementsDetailCardOpen(true));
												dispatch(setSelectedZambiaCollectionsSettlementsDetails(_collection.id));
											}}
										/>
									))}
							</div>
						)}
						{/* //Payouts */}
						{isZambia && (
							<div className={`${location.pathname.includes("/lenco-pay/payouts") ? "order-first" : ""} w-full`}>
								<SearchBarFeatureHeader
									dataType="zambia-payout"
									feature="Payouts"
									isActive={active}
									isFilterEmpty={filteredZambiaPayouts.length < 1}
									isCurrentPage={location.pathname.includes("/lenco-pay/payouts")}
									searchLink={`/lenco-pay/payouts/search/${searchTerm}`}
									onSearch={() => {
										setActive(false);
										inputRef.current?.blur();
										setSearchTerm("");
									}}
								/>
								{active &&
									filteredZambiaPayouts.slice(0, 5).map((_payout, _index) => (
										<SearchBarFeatureBodyAmount
											key={_index}
											amount={_payout.amount}
											date={_payout.singleDatetime}
											name={_payout.originatingUserAccount?.accountName || ""}
											secondName={_payout.destination?.customerAccount?.bankAccount?.accountName || ""}
											currency={_payout.currency}
											isCurrentPage={location.pathname.includes("/lenco-pay/payouts")}
											isDebit={_payout.isDebit()}
											isFailed={_payout.isFailed}
											isDeclined={_payout.isDeclined}
											isSuccessful={_payout.isSuccess}
											isProcessing={_payout.isProcessing}
											dataType="zambia-payout"
											onClick={() => {
												dispatch(setIsZambiaPayoutsDetailCardOpen(true));
												dispatch(setSelectedZambiaPayoutsDetails(_payout.id));
											}}
										/>
									))}
							</div>
						)}
						{/* //Pages */}
						<div className="w-full">
							<div tabIndex={-1} data-type="transaction">
								<div
									className={
										"flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5 " + `${!isZambia ? "mt-3" : ""}`
									}
									tabIndex={-1}
									data-type="transaction"
								>
									<div className="" tabIndex={-1} data-type="transaction">
										<p className="text-xs text-black-tertiary" tabIndex={-1} data-type="transaction">
											Pages
										</p>
									</div>
								</div>
								{active &&
									filteredPages.slice(0, 5).map((el, index) => (
										<PagesSearch
											key={`search-page-${index}`}
											pagesData={el}
											index={index}
											onClick={() => {
												setActive(false);

												inputRef.current?.blur();
												setSearchTerm("");
											}}
											data-type="transaction"
										/>
									))}
							</div>
							<div className="mt-4 px-4 py-2" tabIndex={-1} data-type="transaction">
								<p className="cursor-pointer text-xs text-blue" tabIndex={-1} data-type="transaction">
									<Link
										to={`/transactions/search/${searchTerm}`}
										onClick={() => {
											setActive(false);

											inputRef.current?.blur();
											setSearchTerm("");
										}}
										tabIndex={active ? 0 : -1}
										data-type="transaction"
									>
										View all matches for the term &quot;{searchTerm}
										&quot; in transactions
									</Link>
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default SearchBar;
