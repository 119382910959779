import {BillPaymentPage, BillPaymentStage, BillPaymentState, BillPaymentType} from "./billPaymentSlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import BillPurchase from "../../../../models/billPurchase";
import BillVendor from "../../../../models/billVendor";
import {
	BillHistoryTransactionDateTypes,
	BillPurchasesHistoryRequest,
	BillPurchasesHistoryResponse,
} from "../../../../modules/Dashboard/Payments/Services/billPayment/billPaymentApi.types";
import Transaction from "../../../../models/transaction";

const initialState: BillPaymentState = {
	billPaymentStage: BillPaymentStage.INITIAL,
	billPaymentType: BillPaymentType.AIRTIME_PAYMENT,
	billPaymentTypeBeforeBillHistoryType: null,
	vendorsList: null,
	isVendorsListLoading: false,
	vendorsListError: false,
	recentBillPurchases: null,
	isRecentBillPurchasesLoading: false,
	recentBillPurchasesError: false,
	payFrom: "",
	billPaymentPage: BillPaymentPage.MAKE_PAYMENT,
	billPurchases: [],
	billPurchasesGroupSize: 0,
	billPurchasesTotal: 0,
	billPurchasesOffSet: 0,
	selectedBillPurchase: null,
	showBillPurchaseDetails: null,
	billHistoryFilterState: {userAccountIds: []},
	isCustomModalOpen: false,
	billHistoryTransactionDateType: BillHistoryTransactionDateTypes.ALL_TIME,
	selectedBillHistoryTransaction: null,
};

export const billPaymentSlice = createSlice({
	name: "billPayment",
	initialState,
	reducers: {
		setSelectedBillHistoryTransaction: (state: BillPaymentState, action: PayloadAction<Transaction | null>) => {
			state.selectedBillHistoryTransaction = action.payload;
		},
		setIsBillHistoryCustomModalOpen: (state: BillPaymentState, action: PayloadAction<boolean>) => {
			state.isCustomModalOpen = action.payload;
		},
		setBillHistoryTransactionDateType: (state: BillPaymentState, action: PayloadAction<BillHistoryTransactionDateTypes | string>) => {
			state.billHistoryTransactionDateType = action.payload;
		},
		setShowBillPurchaseDetails: (state: BillPaymentState, action: PayloadAction<BillPurchase | null>) => {
			state.showBillPurchaseDetails = action.payload;
		},
		setBillHistoryFilterState: (state: BillPaymentState, action: PayloadAction<BillPurchasesHistoryRequest>) => {
			state.billHistoryFilterState = {...state.billHistoryFilterState, ...action.payload};
		},

		resetBillHistoryFilterState: (state: BillPaymentState) => {
			state.billHistoryFilterState = {userAccountIds: []};
		},
		setBillHistoryPaginationOffset: (state: BillPaymentState, action: PayloadAction<number>) => {
			state.billPurchasesOffSet = action.payload;
		},
		setSelectedBillPurchase: (state: BillPaymentState, action: PayloadAction<BillPurchase | null>) => {
			state.selectedBillPurchase = action.payload;
		},
		setBillPaymentStage: (state: BillPaymentState, action: PayloadAction<BillPaymentStage>) => {
			state.billPaymentStage = action.payload;
		},
		setBillPaymentTypeBeforeBillHistoryType: (state: BillPaymentState, action: PayloadAction<BillPaymentType | null>) => {
			state.billPaymentTypeBeforeBillHistoryType = action.payload;
		},
		setBillPaymentType: (state: BillPaymentState, action: PayloadAction<BillPaymentType>) => {
			state.billPaymentType = action.payload;
		},
		setBillPaymentPage: (state: BillPaymentState, action: PayloadAction<BillPaymentPage>) => {
			state.billPaymentPage = action.payload;
		},
		setBillPaymentVendorsList: (state: BillPaymentState, action: PayloadAction<BillVendor[]>) => {
			state.vendorsList = action.payload;
			state.isVendorsListLoading = false;
			state.vendorsListError = false;
		},
		setBillPurchases: (state: BillPaymentState, action: PayloadAction<BillPurchasesHistoryResponse>) => {
			state.billPurchases = action.payload.purchases;
			state.billPurchasesGroupSize = action.payload.groupSize;
			state.billPurchasesTotal = action.payload.total;
			state.billPurchasesOffSet = action.payload.offset;
		},
		setBillPaymentVendorsLoading: (state: BillPaymentState) => {
			state.vendorsList = null;
			state.isVendorsListLoading = true;
			state.vendorsListError = false;
		},
		setBillPaymentVendorsError: (state: BillPaymentState) => {
			state.billPaymentStage = BillPaymentStage.INITIAL;
			state.billPaymentType = BillPaymentType.AIRTIME_PAYMENT;
			state.vendorsList = null;
			state.isVendorsListLoading = false;
			state.vendorsListError = true;
		},
		setRecentBillPurchasesList: (state: BillPaymentState, action: PayloadAction<BillPurchase[]>) => {
			state.recentBillPurchases = action.payload;
			state.isRecentBillPurchasesLoading = false;
			state.recentBillPurchasesError = false;
		},
		setRecentBillPurchasesLoading: (state: BillPaymentState) => {
			state.recentBillPurchases = null;
			state.isRecentBillPurchasesLoading = true;
			state.recentBillPurchasesError = false;
		},
		setRecentBillPurchasesError: (state: BillPaymentState) => {
			state.billPaymentStage = BillPaymentStage.INITIAL;
			state.billPaymentType = BillPaymentType.AIRTIME_PAYMENT;
			state.recentBillPurchases = null;
			state.isRecentBillPurchasesLoading = false;
			state.recentBillPurchasesError = true;
		},
		resetBillPaymentData: (state: BillPaymentState) => {
			state.billPaymentStage = BillPaymentStage.INITIAL;
			state.billPaymentType = BillPaymentType.AIRTIME_PAYMENT;
			state.vendorsList = null;
			state.isVendorsListLoading = false;
			state.vendorsListError = false;
			state.recentBillPurchases = null;
			state.isRecentBillPurchasesLoading = false;
			state.recentBillPurchasesError = false;
		},
		resetAllBillPaymentData: (state: BillPaymentState) => {
			state.billPaymentStage = BillPaymentStage.INITIAL;
			state.billPaymentType = BillPaymentType.AIRTIME_PAYMENT;
			state.vendorsList = null;
			state.isVendorsListLoading = false;
			state.vendorsListError = false;
			state.recentBillPurchases = null;
			state.isRecentBillPurchasesLoading = false;
			state.recentBillPurchasesError = false;
			state.payFrom = "";
			state.billPaymentTypeBeforeBillHistoryType = null;
			state.billHistoryFilterState = {userAccountIds: []};
			state.billPurchasesGroupSize = 0;
			state.billPurchasesTotal = 0;
			state.billPurchasesOffSet = 0;
			state.selectedBillPurchase = null;
			state.showBillPurchaseDetails = null;
			state.billHistoryFilterState = {userAccountIds: []};
			state.isCustomModalOpen = false;
			state.billHistoryTransactionDateType = BillHistoryTransactionDateTypes.ALL_TIME;
			state.selectedBillHistoryTransaction = null;
		},
	},
});

export const {
	setBillPaymentStage,
	setBillPaymentType,
	setBillPaymentVendorsList,
	setBillPaymentVendorsLoading,
	setBillPaymentVendorsError,
	setRecentBillPurchasesList,
	setRecentBillPurchasesLoading,
	setRecentBillPurchasesError,
	resetBillPaymentData,
	resetAllBillPaymentData,
	setBillPaymentPage,
	setBillPaymentTypeBeforeBillHistoryType,
	setBillPurchases,
	setSelectedBillPurchase,
	setShowBillPurchaseDetails,
	setBillHistoryFilterState,
	setBillHistoryPaginationOffset,
	setIsBillHistoryCustomModalOpen,
	setBillHistoryTransactionDateType,
	resetBillHistoryFilterState,
	setSelectedBillHistoryTransaction,
} = billPaymentSlice.actions;

export default billPaymentSlice.reducer;
