import React, {useCallback, useLayoutEffect, useState} from "react";
import {canShowCardModal, saveCardModalSkipped} from "../helpers/cardReminder";
import {isSwitchingBusinessFalse, setCompanyId} from "../redux/init/slice/initSlice";
import {useDispatch, useSelector} from "react-redux";

import CardOnboardingModal from "../modules/Dashboard/Home/Components/Modals/CardOnboardingModal";
import DashboardLayout from "../components/DashboardLayout/DashboardLayout";
import FullScreenLoader from "../components/Loaders/FullScreenLoader";
import {IRootState} from "../redux/rootReducer";
import SelectBusinessModal from "../components/DashboardLayout/Modal/SelectBusinessModal";
import {Switch} from "react-router";
import useInit from "../redux/init/services/hooks/useInit";

interface Props {
	children: React.ReactNode;
}

function BankingAppWrapper({children}: Props): JSX.Element {
	const dispatch = useDispatch();

	// const initLoading: boolean = useSelector<IRootState, boolean>((state: IRootState) => state.init.accountInitLoading);
	// const mainInitLoading: boolean = useSelector<IRootState, boolean>((state: IRootState) => state.init.mainInitLoading);
	const main = useSelector((state: IRootState) => state.init.main);
	const account = useSelector((state: IRootState) => state.init.account);
	const loading = useSelector<IRootState, boolean>((state: IRootState) => state.init.loading);
	const companyId = useSelector((state: IRootState) => state.init.selectedCompanyId);
	const isSwitchingBusiness = useSelector((state: IRootState) => state.init.isSwitchingBusiness);

	const [showSelectBusiness, setShowSelectBusiness] = useState(false);
	const [isAccessingAccount, setIsAccessingAccount] = useState(false);
	const [isCardOnboardingModalOpen, setIsCardOnboardingModalOpen] = useState<boolean>(true);

	const {isAppReady} = useInit();

	// Get company id on load of dom
	useLayoutEffect(() => {
		if (!account || !isAppReady || !!companyId) return;
		dispatch(isSwitchingBusinessFalse());
		setShowSelectBusiness(true);
	}, [isAppReady, account, companyId, dispatch]);

	const handleSelectCompany = useCallback((_companyId: string) => {
		dispatch(setCompanyId(_companyId));
		setShowSelectBusiness(false);
		setIsAccessingAccount(true);
	}, []);

	return (
		<>
			{isAppReady && (
				<div>
					{showSelectBusiness && <SelectBusinessModal accountCompaniesData={account?.companies} selectCompanyFunc={handleSelectCompany} />}
					{loading && <FullScreenLoader isSwitchingBusiness={isSwitchingBusiness} isAccessingAccount={isAccessingAccount} />}
					{!loading && (
						<DashboardLayout>
							{main?.companyDetails.user.isAdmin && canShowCardModal() && (
								<CardOnboardingModal
									active={isCardOnboardingModalOpen}
									toggler={() => {
										saveCardModalSkipped();
										setIsCardOnboardingModalOpen(false);
									}}
								/>
							)}
							<Switch>{children}</Switch>
						</DashboardLayout>
					)}
				</div>
			)}
		</>
	);
}

export default BankingAppWrapper;
