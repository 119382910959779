import {useCallback, useEffect, useState} from "react";

import Transaction from "../../../../../../../models/transaction";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {messageTrue} from "../../../../../../../redux/message/slice/messageSlice";
import {tagRecipient} from "../Services/transactionDetailApi";
import {useDispatch} from "react-redux";

export interface UseTransactionDetailsTagRecipientResponse {
	isSent: boolean;
	isSubmitting: boolean;
	handleTagRecipient: (transaction: Transaction, contact: string) => Promise<void>;
}

function useTransactionDetailsTagRecipient(): UseTransactionDetailsTagRecipientResponse {
	const dispatch = useDispatch();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isSent, setIsSent] = useState<boolean>(false);

	useEffect(() => {
		return () => {
			setIsSent(false);
			setIsSubmitting(false);
		};
	}, []);

	const handleTagRecipient = useCallback(
		async (transaction: Transaction, contact: string) => {
			try {
				setIsSubmitting(true);
				setIsSent(false);
				await tagRecipient(transaction.id, contact);
				dispatch(messageTrue("Receipt Shared Successfully"));
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsSubmitting(false);
				setIsSent(true);
			}
		},
		[dispatch]
	);

	return {
		isSent,
		isSubmitting,
		handleTagRecipient,
	};
}

export default useTransactionDetailsTagRecipient;
