import Card from "../../../../../models/card";
import {ReactComponent as CardIcon} from "../../../../../assets/svg/DashboardLayout/Sections/card.svg";
import CustomerAccount from "../../../../../models/customerAccount";
import DropdownLink from "../../../../../components/General/Dropdown/DropdownComponents/DropdownLink";
import Money from "../../../../../components/General/Money";
import React from "react";
import UserAccount from "../../../../../models/userAccount";
import getAvatarColorClass from "../../../../../helpers/avatarColors";
import getBankName from "../../../../../helpers/getBankName";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import titleCase from "../../../../../hooks/titleCase";

interface Props {
	data: UserAccount | CustomerAccount | Card;
	message?: string;
	isDisabled?: boolean;
	showBalance?: boolean;
	cardBalance?: number;

	onClick: () => void;
}

function AccountNumberItem(props: Props): JSX.Element {
	return (
		<>
			<DropdownLink onClick={props.onClick} noHover={props.isDisabled} big>
				<div
					className={
						"flex h-10 w-full items-center justify-start space-x-4 px-4 " +
						` ${props.isDisabled ? "cursor-not-allowed pointer-events-none" : ""}`
					}
				>
					<div>
						<div className={`flex h-8 w-8 items-center justify-center rounded-full ${getAvatarColorClass(props.data.id)}`}>
							{props.data instanceof UserAccount && props.data.lencoNameMin.slice(0, 1)}
							{props.data instanceof CustomerAccount && props.data.singleName?.slice(0, 1)}
							{props.data instanceof Card && <CardIcon className="stroke-current text-blue" />}
						</div>
					</div>
					<div className="flex h-full flex-col items-start justify-center">
						<span className="text-sm">
							{titleCase((props.data instanceof UserAccount && props.data.lencoNameMin) || "")}
							{titleCase((props.data instanceof CustomerAccount && props.data.singleName) || "")}
							{titleCase((props.data instanceof Card && props.data.name) || "")}
						</span>
						{!props.message &&
							props.showBalance &&
							props.data instanceof UserAccount &&
							!isNullOrUndefined(props.data instanceof UserAccount && props.data.balance) && (
								<div className="flex flex-row items-center justify-start space-x-2 text-xs" data-type="dropdown">
									<Money amount={(props.data instanceof UserAccount && props.data.balance) || 0} />
								</div>
							)}
						{!props.message && !props.showBalance && (props.data instanceof UserAccount || props.data instanceof CustomerAccount) && (
							<div className="flex flex-row items-center justify-start space-x-2 text-xs">
								<span>{props.data.bankAccount && props.data.bankAccount.accountNumber} </span>
								<div>
									<div className="h-1 w-1 rounded-full bg-black-tertiary" />
								</div>
								<div>{getBankName(props.data.bankAccount?.bankCode)}</div>
							</div>
						)}
						{!props.message && props.data instanceof Card && (
							<>
								{!!props.cardBalance && (
									<div className="text-xs">
										<Money amount={props.cardBalance} />
									</div>
								)}
								{!props.cardBalance && (
									<div className="text-xs">
										<span>••••{props.data.maskedPan.slice(-4)}</span>
									</div>
								)}
							</>
						)}
						{props.message && (
							<div className="flex flex-row items-center justify-start space-x-2 text-xs" data-type="dropdown">
								{props.message}
							</div>
						)}
					</div>
				</div>
			</DropdownLink>
		</>
	);
}

export default AccountNumberItem;
