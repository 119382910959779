import {Countries} from "../redux/init/slice/initSlice.types";
import moment from "moment";
import store from "../redux/store";

const fullHourCountries = [Countries.ZAMBIA];

export function formatDate(date: Date): string {
	const d = moment(date).format("DD");
	const m = moment(date).format("MMMM").slice(0, 3);
	const y = moment(date).format("YYYY");
	return `${d} ${m}, ${y}`;
}

export function formatTime(date: Date, country?: Countries): string {
	const accountCountry = store.getState().init.main?.companyDetails.company.country || null;
	const isFullHour = isFullHourCountry(country || accountCountry);
	return moment(date).format(isFullHour ? "HH:mm" : "h:mm a");
}

export function isFullHourCountry(country: Countries | null): boolean {
	return country ? fullHourCountries.includes(country) : false;
}

export function formatDateAndTime(date: Date, country?: Countries): string {
	return `${formatDate(date)}, ${formatTime(date, country)}`;
}

export function momentFormatDate(date: Date, format?: string): string {
	return moment(date).format(format);
}
