import {HintType, ListStyle} from "../../../../../Dashboard/Application/components/element/hint/hint.constant";
import React, {useRef, useState} from "react";
import {useHistory, useLocation} from "react-router";

import ApplicationHeader from "../../../../../Dashboard/Application/components/element/ApplicationHeader";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {CompanyHint} from "../../../../../Dashboard/Application/services/nigeria-application/registered/company.types";
import {FileDrop} from "react-file-drop";
import Hint from "../../../../../Dashboard/Application/components/element/hint";
import {IRootState} from "../../../../../../redux/rootReducer";
import PreviewModal from "../../../../../Dashboard/Application/components/modals/preview-modal";
import RadioButton from "../../../../../../components/General/RadioButton/RadioButton";
import {Routes} from "../../../../../../routes/routes.constants";
import {TailSpin} from "react-loader-spinner";
import {ReactComponent as UploadCloud} from "../../../../../../assets/svg/upload-cloud.svg";
import UploadedCard from "../../../../../Dashboard/Application/components/cards/UploadedCard";
import useExternalDirectorPreview from "../../Hooks/useExternalDirectorPreview";
import {useSelector} from "react-redux";
import useUploadExternalDirectorId from "../../Hooks/useUploadExternalDirectorId";

const MeansOfIdHints: CompanyHint[] = [
	{
		index: 0,
		span: <span>Choose your preferred means of identification before uploading.</span>,
	},
	{
		index: 1,
		span: <span>Ensure your ID number is visible on your means of ID.</span>,
	},
	{
		index: 2,
		span: <span>Ensure your ID is within its validity period.</span>,
	},
];

interface LocationState {
	from?: string;
}

function IdentificationForm(): JSX.Element {
	const history = useHistory();
	const location = useLocation<LocationState>();

	const {previewBlob, handlePreviewDocument} = useExternalDirectorPreview({
		onComplete: () => setShowPreviewModal(true),
	});
	const {isExternalDirectorIdLoading, handleUploadExternalDirectorId} = useUploadExternalDirectorId({onComplete: () => setIsUploaded(true)});

	const url = useSelector((state: IRootState) => state.externalApplication.externalLink || "");
	const externalParams = useSelector((state: IRootState) => state.externalApplication.externalParams);
	const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

	const director = externalApplicationInit?.director || null;

	const fileInputRef = useRef<HTMLInputElement | null>(null);

	const [type, setType] = useState<number | null>(null);
	const [uploaded, setIsUploaded] = useState(!!director?.identification?.fileId);
	const [selectedFileName, setSelectedName] = useState<string | null>(null);
	const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

	const onFileChange = (_files: File | null) => {
		if (_files && externalParams) {
			setSelectedName(_files.name);
			void handleUploadExternalDirectorId(externalParams, {typeId: type as number, file: _files});
		}
	};

	const handleReUpload = () => {
		setIsUploaded(false);
		setSelectedName(null);
	};

	const onTargetClick = () => {
		fileInputRef.current && fileInputRef.current.click();
	};

	const handleBack = () => {
		location?.state?.from === url ? history.push(url) : history.push(`${url}${Routes.EXTERNAL_DIRECTOR.NEXT_OF_KIN}`);
	};

	return (
		<>
			<div className="w-full">
				<PreviewModal
					active={showPreviewModal}
					toggler={() => setShowPreviewModal(false)}
					data={previewBlob}
					title="Identification Preview"
				/>
				<div className="flex flex-row justify-start items-start space-x-6 w-full">
					{externalApplicationInit && director && (
						<div className="lg:min-w-60% lg:max-w-3xl w-full">
							<ApplicationHeader header="Means of ID" subTitle="Provide us with a valid means of ID" progress={uploaded ? 100 : 0}>
								<div className="w-full pb-32">
									{!uploaded && (
										<div className="flex flex-col justify-start items-start space-y-4 w-full">
											<div className="flex w-full flex-col items-center lg:flex-row space-x-0 lg:space-x-10 space-y-4 lg:space-y-0 border p-5 rounded-lg">
												<div className="flex flex-col justify-start items-start space-y-4 w-64">
													{externalApplicationInit.options.idType?.map((el) => (
														<RadioButton
															id={String(el.id)}
															checked={type === el.id}
															text={el.name}
															key={el.id}
															func={() => setType(el.id)}
															size="sm"
														/>
													))}
												</div>
												<div className="cursor-pointer w-full">
													<div className="flex flex-col items-center justify-center w-full space-y-2 h-32 px-2 border border-black-quat border-dashed rounded-lg cursor-pointer bg-grey-backdrop hover:bg-blue-senary transition-all">
														<FileDrop onTargetClick={onTargetClick} onDrop={(files) => onFileChange(files && files[0])}>
															<input
																type="file"
																ref={fileInputRef}
																className="hidden"
																onChange={(event) => onFileChange(event.target.files && event.target.files[0])}
																accept="image/jpeg, image/png, application/pdf, .pdf"
																multiple
															/>
															{!selectedFileName && (
																<>
																	<UploadCloud />
																	<p className="text-blue font-medium">Select document to upload</p>
																	<p className="text-black-tertiary text-sm">or Drag and Drop it here</p>
																</>
															)}
															{selectedFileName && (
																<div className="flex flex-row justify-center items-center space-x-2">
																	<span className="text-black-tertiary">{selectedFileName}</span>
																	{isExternalDirectorIdLoading && (
																		<TailSpin color="#5466F9" height={20} width={20} />
																	)}
																</div>
															)}
														</FileDrop>
													</div>
												</div>
											</div>
										</div>
									)}
									{uploaded && (
										<UploadedCard
											text="Means of ID"
											primaryButtonText="Re-upload ID"
											handlePreviewDocumentFunc={() => externalParams && handlePreviewDocument(externalParams)}
											handleRedo={handleReUpload}
										/>
									)}

									<div className="flex flex-col 2xs:flex-row items-center justify-center pt-16 2xs:space-x-4 w-full">
										<div className="w-full 2xs:w-max order-2 2xs:order-1">
											<ButtonComp type="button" color="grey" ripple="light" buttonType="secondary" func={handleBack} fullWidth>
												<span>Back</span>
											</ButtonComp>
										</div>
										<div className="w-full 2xs:w-max order-1 2xs:order-2">
											<ButtonComp
												type="button"
												color="blue"
												ripple="light"
												buttonType="primary"
												disable={!uploaded}
												func={() =>
													history.push({
														pathname: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
														state: {
															from: history.push(`${url}${Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID}`),
														},
													})
												}
												fullWidth
											>
												<span>Continue</span>
											</ButtonComp>
										</div>
									</div>
								</div>
							</ApplicationHeader>
						</div>
					)}
					<Hint hintType={HintType.GUIDE} listStyle={ListStyle.BULLET} firstList={MeansOfIdHints} />
				</div>
			</div>
		</>
	);
}

export default IdentificationForm;
