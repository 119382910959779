import CardMin from "./cardMin";
import CustomerAccount from "./customerAccount";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {TransactionInfoType} from "./transactionInfo.constants";
import UserAccount from "./userAccount";
import {immerable} from "immer";
import store from "../redux/store";

export default class TransactionInfo {
	[immerable] = true;

	private constructor(
		public id: string,
		public type: TransactionInfoType,
		public userAccount: UserAccount | null,
		// public customerAccount: CustomerAccount|null,
		public _customerAccountId: string | null,
		public name: string | null,
		public phone: string | null,
		public narration: string | null,
		public alias: string | null,
		public card: CardMin | null
	) {}

	static create(obj: GenericObject): TransactionInfo {
		const customerAccount = Parsers.classObject(obj.customerAccount, CustomerAccount);

		return new TransactionInfo(
			Parsers.string(obj.id),
			Parsers.number(obj.type),
			Parsers.classObject(obj.userAccount, UserAccount),
			customerAccount?.id || null,
			Parsers.nullableString(obj.name),
			Parsers.nullableString(obj.phone),
			Parsers.nullableString(obj.narration),
			Parsers.nullableString(obj.alias),
			Parsers.classObject(obj.card, CardMin)
		);
	}

	getSummary(truncated: boolean): string {
		if (this.userAccount) {
			return this.userAccount.lencoNameMin;
		}
		if (this.customerAccount) {
			return this.customerAccount.singleName;
		}

		// if there is no UserAccount and no CustomerAccount, then there will definitely be a narration
		return this.alias || this.name || this.phone || (truncated ? this.truncatedNarration : this.narration) || "";
	}

	get summary(): string {
		return this.getSummary(false);
	}

	get truncatedSummary(): string {
		return this.getSummary(true);
	}

	get truncatedNarration(): string | null {
		if (this.narration === null) {
			return null;
		}
		if (this.narration.length > 23) {
			return `${this.narration.substr(0, 20).trim()}...`;
		}

		return this.narration;
	}

	get account(): UserAccount | CustomerAccount | null {
		return this.userAccount || this.customerAccount || null;
	}

	get customerAccount(): CustomerAccount | null {
		if (!this._customerAccountId) {
			return null;
		}
		return store.getState().customerAccount.customerAccounts.get(this._customerAccountId) || null;
	}

	set customerAccount(value: CustomerAccount | null) {
		this._customerAccountId = value?.id || null;
	}
}
