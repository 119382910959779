import {resetFundCardRecipient, setSelectedUserAccount} from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import {useCallback, useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRootState} from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import doesUserHaveAccessToAccount from "../../../../../../../helpers/doesUserHaveAccessToAccount";
import {resetAllTempSendMoneyData} from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {setFundCardOriginatingAccount} from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice";

interface useFundCardPayFromInterface {
	selectedAccountId: string;
	accounts: UserAccount[] | undefined;
	handleSelectAccount: (_accountId: string) => void;
}

function useFundCardPayFrom(): useFundCardPayFromInterface {
	const originatingAccountId = useSelector((state: IRootState) => state.fundCard.fundCardDetails.originatingAccountId);
	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
	const payFrom = useSelector((state: IRootState) => state.sendMoney.payFrom);
	const dispatch = useDispatch();

	useEffect(() => {
		if (!accounts) return;
		if (accounts.length < 2) {
			if (doesUserHaveAccessToAccount(accounts[0].id)) {
				handleSelectAccount(accounts[0].id);
			}
		}
	}, [accounts]);

	useEffect(() => {
		if (!payFrom) return;
		handleSelectAccount(payFrom);
	}, [payFrom]);

	const handleSelectAccount = useCallback((_accountId) => {
		const doesAccountExist = accounts?.some((el) => el.id === _accountId);
		if (doesAccountExist) {
			const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
			dispatch(setFundCardOriginatingAccount(_accountId));
			dispatch(setSelectedUserAccount(userAccount));
			if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) {
				dispatch(resetAllTempSendMoneyData());

				dispatch(resetFundCardRecipient());
			}
		}
	}, []);

	const getPayFromOptions = useMemo((): Array<UserAccount> => {
		if (!accounts) return [];
		return [...accounts.filter((el) => doesUserHaveAccessToAccount(el.id))];
	}, [accounts]);

	return {
		accounts: getPayFromOptions,
		selectedAccountId: originatingAccountId,
		handleSelectAccount,
	};
}

export default useFundCardPayFrom;
