import {BillPaymentStage, BillPaymentType} from "../../redux/payments/billPayment/slice/billPaymentSlice.types";
import {Link, useLocation} from "react-router-dom";
import {PaymentStageType, PaymentType} from "../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import React, {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {canShowCardBanner, canShowNewCard, canShowNewTerminals, saveBannerSkippedDate} from "../../helpers/cardReminder";
import {closeAddFundsModal, closeZambiaAddFundsModal} from "../../redux/accounts/slice/accountsSlice";
import {setBillPaymentStage, setBillPaymentType} from "../../redux/payments/billPayment/slice/billPaymentSlice";
import {setPaymentStage, setPaymentType} from "../../redux/payments/sendMoney/slice/sendMoneySlice";
import {useDispatch, useSelector} from "react-redux";

import AccountDropdown from "./Dropdowns/AccountDropdown";
import {AccountOpeningInitState} from "../../modules/Dashboard/Application/services/application.types";
import {ReactComponent as AccountsIcon} from "../../assets/svg/DashboardLayout/Sections/accountsIcon.svg";
import AddFundsModal from "../../modules/Dashboard/Accounts/Components/Modals/AddFundsModal";
import AddNewBusinessModal from "./Modal/AddNewBusinessModal";
import ApplicationSection from "./Sections/ApplicationSection";
import BusinessDropdown from "./Dropdowns/BusinessDropdown";
import {ReactComponent as CardIcon} from "../../assets/svg/DashboardLayout/Sections/card.svg";
import {ReactComponent as GrayCloseIcon} from "../../assets/svg/General/CloseIcon.svg";
import Hamburger from "./Hamburger/Hamburger";
import HamburgerClose from "./Hamburger/HamburgerClose";
import {ReactComponent as HomeIcon} from "../../assets/svg/DashboardLayout/Sections/homeIcon.svg";
import {IRootState} from "../../redux/rootReducer";
import IdleModal from "./Modal/IdleModal";
import {ReactComponent as LencoLogo} from "../../assets/svg/DashboardLayout/Logo/lenco.svg";
import {ReactComponent as LencoPayIcon} from "../../assets/svg/DashboardLayout/Sections/lencoPayIcon.svg";
import MenuItem from "./Sections/MenuItem";
import MenuItemWithChildren from "./Sections/MenuItemWithChildren";
import NigeriaFlag from "../../assets/svg/Countries/nigeria.png";
import {ReactComponent as NotificationIcon} from "../../assets/svg/NotificationIcon.svg";
import Overlay from "../Overlay";
import {Routes} from "../../routes/routes.constants";
import SearchBar from "./SearchBar/SearchBar";
import SendMoneyDropdown from "./Dropdowns/SendMoney";
// import SendMoneyDropdown from "./Dropdowns/SendMoneyDropdown";
import {ReactComponent as SendMoneyIcon} from "../../assets/svg/DashboardLayout/Sections/sendMoneyIcon1.svg";
import {ReactComponent as SettingsIcon} from "../../assets/svg/DashboardLayout/Sections/settingsIcon.svg";
import {ReactComponent as TerminalsIcon} from "../../assets/svg/DashboardLayout/Sections/terminalsIcon.svg";
import ToastContainer from "../General/ToastContainer";
import TransactionDetailCard from "../../modules/Dashboard/Transactions/Components/Cards/TransactionDetailCard";
import {ReactComponent as TransactionsIcon} from "../../assets/svg/DashboardLayout/Sections/transactionsIcon1.svg";
import ZambiaAddFundsModal from "../../modules/Dashboard/Accounts/Components/Modals/ZambiaAddMoneyModal";
import ZambiaFlag from "../../assets/svg/Countries/zambia.png";
import {hideTransactionDetails} from "../../redux/transaction/slice/transactionSlice";
import useDimension from "../../hooks/useDimension";
import usePing from "../../hooks/usePing";

interface DashboardLayoutProps {
	children: React.ReactNode;
}

declare global {
	interface Window {
		Headway: {
			init: (e: unknown) => void;
		};
	}
}

function DashboardLayout({children}: DashboardLayoutProps): JSX.Element {
	const {initPing} = usePing();
	const dispatch = useDispatch();
	const location = useLocation();
	const {width} = useDimension();

	const main = useSelector((state: IRootState) => state.init.main);
	const isAdmin = useSelector((state: IRootState) => state.init.main?.companyDetails?.user.isAdmin);
	const isLencoPayEnabled = useSelector((state: IRootState) => state.init.main?.meta.lencoPay.isEnabled);
	const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isZambia || state.zambiaApplication.init);
	const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isNigeria || state.application.init);
	const accountOpening = useSelector<IRootState, AccountOpeningInitState | null>((state) => state.init.accountOpening);
	const canAccessCards = useSelector((state: IRootState) => state.init.canAccessCards);
	const canAccessTerminals = useSelector((state: IRootState) => state.init.canAccessTerminals);
	const hasViewLencoPayAccess = useSelector((state: IRootState) => state.init.main?.companyDetails.user.hasViewLencoPayAccess);
	const isZambiaAddFundsModalOpen = useSelector((state: IRootState) => state.account.isZambiaAddFundsModalOpen);
	const canApprove = useSelector<IRootState, boolean>(
		(state: IRootState) => state.init.main?.companyDetails.userAccountsMeta.some((accountMeta) => accountMeta.canApprove()) || false
	);

	const [showNav, setShowNav] = useState<boolean>(false);
	const [isAccountCreated, setIsAccountCreated] = useState<boolean>(false);
	const [isNewCardBannerOpen, setIsNewCardBannerOpen] = useState<boolean>(true);
	const [showAddNewBusinessModal, setShowAddNewBusinessModal] = useState<boolean>(false);

	useLayoutEffect(() => {
		initPing();
	}, []);

	useLayoutEffect(() => {
		if (!main) return;
		setIsAccountCreated(main.companyDetails.company.accountCreated);
	}, [main]);

	useLayoutEffect(() => {
		if (!accountOpening) return;
		setIsAccountCreated(false);
	}, [accountOpening]);

	useEffect(() => {
		setShowNav(false);
		document.body.style.overflow = "auto";
		dispatch(hideTransactionDetails());
		dispatch(closeAddFundsModal());
		dispatch(closeZambiaAddFundsModal());
	}, [location]);

	useEffect(() => {
		const script = document.createElement("script");
		script.async = true;
		script.src = "https://cdn.headwayapp.co/widget.js";
		document.head.appendChild(script);
		const config = {
			selector: ".headway-badge",
			account: process.env.REACT_APP_HEADWAY_KEY,
			trigger: ".headway-trigger",
		};
		script.onload = function () {
			window.Headway.init(config);
		};
	}, []);

	const handleOpenNav = useCallback(() => {
		if (width < 1025) {
			setShowNav(true);
			document.body.style.overflow = "hidden";
		}
	}, [width]);

	const handleCloseNav = useCallback(() => {
		if (width < 1025) {
			setShowNav(false);
			document.body.style.overflow = "auto";
		}
	}, [width]);

	const handleAddNewBusiness = useCallback(() => {
		setShowAddNewBusinessModal(true);
	}, []);

	const handleCloseAddNewBusiness = useCallback(() => {
		setShowAddNewBusinessModal(false);
	}, []);

	return (
		<>
			<div className="min-h-screen h-full w-full">
				{main?.companyDetails.user.isAdmin && isNewCardBannerOpen && canShowCardBanner() && (
					<div className="h-10 w-full flex justify-center items-center space-x-2 relative bg-blue-senary border-b border-blue">
						<div className="rounded bg-blue px-1 select-none">
							<span className="text-white font-medium text-sm">New</span>
						</div>
						<p className="text-sm text-black-secondary font-medium select-none">The corporate card that supports your business</p>
						<div
							className="absolute top-0 right-8 h-full w-max flex justify-center items-center cursor-pointer"
							onClick={() => {
								saveBannerSkippedDate();
								setIsNewCardBannerOpen(false);
							}}
						>
							<GrayCloseIcon className="h-2.5 w-2.5 stroke-current text-black" />
						</div>
					</div>
				)}
				<div
					// className={`relative bg-white lg:grid-cols-dash 4xs:grid min-h-screen ` + `${isNewCardBannerOpen ? "h-full" : "min-h-screen"}`}
					className="relative bg-white lg:grid-cols-dash 4xs:grid min-h-screen"
					id="dashboard"
					tabIndex={-1}
				>
					<ToastContainer />
					<IdleModal />
					<AddNewBusinessModal active={showAddNewBusinessModal} toggler={handleCloseAddNewBusiness} />
					{showNav && <Overlay onClick={handleCloseNav} />}
					<aside
						className={`${
							showNav ? " translate-x-0" : "-translate-x-full"
						} fixed z-40 flex w-80% max-w-16 transform flex-col items-center justify-between bg-blue-senary transition-all -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available lg:relative lg:w-auto lg:-translate-x-0  lg:transition-none  `}
						tabIndex={-1}
					>
						<div className="absolute -right-12 top-10  hidden 4xs:-right-14 xs:-right-24">
							<HamburgerClose active={showNav} func={handleCloseNav} />
						</div>

						<div className="flex h-full w-full flex-col items-start justify-between overflow-y-auto pt-10 scrollbar-hide lg:overflow-y-auto">
							<div className="w-full">
								<div className="flex items-center justify-between px-6">
									<Link to={Routes.DASHBOARD} tabIndex={showNav ? 0 : -1}>
										<div className="-mt-6 flex w-28 cursor-pointer items-center justify-center">
											<LencoLogo />
										</div>
									</Link>
								</div>

								<div className="mx-6 mt-10 ">
									<BusinessDropdown handleAddNewBusiness={handleAddNewBusiness} />
								</div>

								{!isAccountCreated ? (
									<>
										<div className="pb-1 pt-4">
											<ApplicationSection />
										</div>
									</>
								) : (
									<div className="py-4">
										<MenuItem onClick={handleOpenNav} path={Routes.DASHBOARD} icon={<HomeIcon />} text="Home" />

										<MenuItem onClick={handleOpenNav} path="/transactions" icon={<TransactionsIcon />} text="Transactions" />

										<MenuItemWithChildren
											onClick={handleOpenNav}
											path="/payments"
											icon={<SendMoneyIcon />}
											text="Payments"
											subItems={[
												{
													path: "/payments/make",
													text: "Send Money",
													onClick: () => {
														dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
														dispatch(setPaymentStage(PaymentStageType.INITIAL));
													},
												},
												{
													path: "/payments/bill/make",
													text: "Bill Payments",
													onClick: () => {
														dispatch(setBillPaymentType(BillPaymentType.AIRTIME_PAYMENT));
														dispatch(setBillPaymentStage(BillPaymentStage.INITIAL));
													},
													hide: !canApprove || !isNigeria,
												},
												{
													path: "/payments/add-money/make",
													optionalPath: "/payments/add-money/history",
													text: "Add Money",
													hide: !!isNigeria || !canApprove,
												},
												{path: "/payments/pending", text: "Pending Payments"},
												{path: "/payments/recipients", text: "Recipients"},
											]}
										/>
										{isNigeria && canAccessCards && (
											<MenuItem
												onClick={handleOpenNav}
												path="/cards"
												icon={<CardIcon />}
												text="Cards"
												isNew={canShowNewCard()}
											/>
										)}

										{isZambia && hasViewLencoPayAccess && !isLencoPayEnabled && (
											<MenuItem
												onClick={handleOpenNav}
												path="/lenco-pay/get-started"
												icon={<LencoPayIcon />}
												text="Lenco Pay"
												isFill
											/>
										)}

										{isZambia && hasViewLencoPayAccess && isLencoPayEnabled && (
											<MenuItemWithChildren
												onClick={handleOpenNav}
												path="/lenco-pay"
												icon={<LencoPayIcon />}
												text="Lenco Pay"
												subItems={[
													{
														path: "/lenco-pay/collections/transactions",
														optionalPath: "/lenco-pay/collections",
														text: "Collections",
													},
													{path: "/lenco-pay/payouts", text: "Payouts"},
													{path: "/lenco-pay/api-access", text: "APIs"},
													{path: "/lenco-pay/configuration", text: "Configuration"},
												]}
												isFill
											/>
										)}

										{isNigeria && canAccessTerminals && (
											<MenuItem
												onClick={handleOpenNav}
												path="/terminals"
												icon={<TerminalsIcon />}
												text="Terminals"
												isNew={canShowNewTerminals()}
											/>
										)}

										{/* <InvoiceSection onClick={handleOpenNav} /> */}

										{/* <MenuItem onClick={handleOpenNav} path="/people" icon={<PeopleIcon />} text="People" /> */}

										<MenuItem onClick={handleOpenNav} path="/accounts" icon={<AccountsIcon />} text="Accounts" />

										<MenuItemWithChildren
											onClick={handleOpenNav}
											path="/settings"
											icon={<SettingsIcon />}
											text="Settings"
											subItems={[
												{path: "/settings/account", text: "Account Settings"},
												{path: "/settings/teammembers", text: "Team Members", hide: !isAdmin},
												{path: "/settings/security", text: "Security"},
											]}
										/>
									</div>
								)}
							</div>
							{width < 1023 && (
								<div className="headway-trigger mx-2.5 mt-20 flex cursor-pointer items-center justify-center pb-10 lg:hidden">
									<div className="headway-trigger relative flex cursor-pointer items-center justify-center pl-4 pr-3" tabIndex={0}>
										<div className="headway-badge flex items-center justify-center" id="headway-badge">
											<NotificationIcon />
										</div>
									</div>
									<span className="text-sm font-normal text-black-tertiary">Notification</span>
								</div>
							)}
						</div>
					</aside>
					<section className="w-full">
						<div className="flex h-screen max-h-screen w-full flex-col items-start justify-start 4xs:h-full">
							<header className="flex h-16 w-full flex-row items-center justify-center border-b-0.2 border-grey py-1 " tabIndex={-1}>
								<div className="mx-auto flex w-full flex-row  items-center justify-between  px-4 lg:px-8 ">
									<div className="flex w-full justify-between space-x-6 pb-2 pt-2 sm:space-x-12">
										<div className="flex w-max flex-col items-center justify-center lg:w-full">
											<div className="flex space-x-2" tabIndex={-1}>
												<div>
													<Hamburger func={handleOpenNav} />
												</div>
												<div
													className="headway-badge-hamburger pointer-events-none -mt-3 flex lg:hidden"
													id="headway-badge-hamburger"
												/>
											</div>

											{isAccountCreated && (
												<div className="relative w-full" tabIndex={-1}>
													<SearchBar placeholder="Search for accounts, transactions, and pages" />
												</div>
											)}
										</div>

										<div
											className="flex w-max flex-row items-center justify-center space-x-2 sm:space-x-3 lg:space-x-6 "
											tabIndex={-1}
										>
											{isAccountCreated && (
												<div className="lg:pl-4 lg:pr-3 " tabIndex={-1}>
													<SendMoneyDropdown />
												</div>
											)}
											{width > 1023 && (
												<div
													className="headway-trigger relative hidden cursor-pointer items-center justify-center pl-4 pr-3 lg:flex"
													tabIndex={0}
												>
													<div className="headway-badge flex items-center justify-center" id="headway-badge">
														<NotificationIcon />
													</div>
												</div>
											)}

											<div className="lg:inline">
												<AccountDropdown />
											</div>

											<div className="w-6 h-full flex justify-center items-center">
												{isNigeria && (
													<img
														className="border border-black-quat rounded-full"
														src={NigeriaFlag}
														alt="Nigerian flag"
														width="24px"
														height="24px"
													/>
												)}
												{isZambia && (
													<img
														className="border border-black-quat rounded-full"
														src={ZambiaFlag}
														alt="Zambian flag"
														width="24px"
														height="24px"
													/>
												)}
											</div>
										</div>
									</div>
								</div>
							</header>

							<main
								className={
									"basis-auto relative overflow-y-auto flex w-full flex-shrink flex-grow flex-col items-center justify-start px-4 -moz-h-fit-available -webkit-h-fit-available -ms-h-fit-available lg:px-8 "
									//  +
									// `${location.pathname.includes("review") ? "" : "overflow-y-auto"}`
								}
							>
								{children}
								<TransactionDetailCard />
								<AddFundsModal />
								{isZambiaAddFundsModalOpen && <ZambiaAddFundsModal />}
							</main>
						</div>
					</section>
				</div>
			</div>
		</>
	);
}

export default DashboardLayout;
