import {BasicFinalStatus, TransactionType} from "../../../models/posTransaction.constants";

import PosTransaction from "../../../models/posTransaction";

export interface TerminalTransactionsState {
	terminalTransactionDetail: PosTransaction | null;
	isTerminalTransactionDetailCardOpen: boolean;

	isTransactionPaginationLoading: boolean;
	transactionPaginationOffset: number;
	isExportTransactionsLoading: boolean;
	isTransactionAccountQuery: boolean;
	isTransactionSearchQuery: boolean;
	transactionsListTotal: number;
	transactionsListGroupSize: number;
	posTransactionList: PosTransaction[] | null;
	isTransactionListEmpty: boolean;
	transactionSearchQuery: string;
	transactionAccountQuery: string;
	transactionDate: TransactionDateTypes;
	selectedTransactionDate: TransactionDateTypes;
	isCustomModalOpen: boolean;

	transactionFilterState: {
		query: string;
		offset: number;
		types: TransactionType[];
		statuses: BasicFinalStatus[];
		terminalId: string;
		startDate: Date | string;
		endDate: Date | string;
	};
	selectedTransactionFilterState: {
		query: string;
		offset: number;
		types: TransactionType[];
		statuses: BasicFinalStatus[];
		terminalId: string;
		startDate: Date | string;
		endDate: Date | string;
	};
}

export type TerminalTransactionFilterTypes =
	| {query: string}
	| {endDate: string}
	| {startDate: string}
	| {offset: number}
	| {types: Array<TransactionType>}
	| {statuses: Array<BasicFinalStatus>}
	| {terminalId: string};

export enum TransactionDateTypes {
	LAST_30_DAYS = "Last 30 days",
	THIS_MONTH = "This Month",
	LAST_MONTH = "Last Month",
	THIS_YEAR = "This year",
	ALL_TIME = "All time",
	CUSTOM = "Custom",
}

export type TransactionDateObj = {
	startDate: Date | null | string;
	endDate: Date | null | string;
};

export type TerminalTransactionDateType = {
	name: TransactionDateTypes;
	date: TransactionDateObj;
};
