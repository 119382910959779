import React, {KeyboardEvent, useEffect, useRef, useState} from "react";

import AccountNumberItem from "./AccountNumberItem";
import {ReactComponent as ArrowDownIcon} from "../../../../../assets/svg/General/arrowDownIcon.svg";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import BarLoader from "react-bar-loader";
import Card from "../../../../../models/card";
import CustomerAccount from "../../../../../models/customerAccount";
import {ReactComponent as EmptyXX} from "../../../../../assets/svg/EmptyXX.svg";
import {IRootState} from "../../../../../redux/rootReducer";
import SearchBar from "../../../Settings/Components/Searchbar/TeamMemebers/SearchBar";
import UserAccount from "../../../../../models/userAccount";
import titleCase from "../../../../../hooks/titleCase";
import useClickOutside from "../../../../../hooks/useClickOutside";
import {useSelector} from "react-redux";

interface AccountNumberDropdownProps {
	data?: Array<UserAccount | CustomerAccount | Card> | null;
	noData?: boolean;
	isLoading?: boolean;
	isPayFrom?: boolean;
	showArrow?: boolean;
	inputValue?: string;
	isDisabled?: boolean;
	placeholder?: string;
	showBalance?: boolean;
	isSearchable?: boolean;
	clickAndClose?: boolean;
	isInputDisabled?: boolean;
	showBalanceHelperText?: boolean;
	// canInitiatorSelect

	onClickFunc?: (e: UserAccount | CustomerAccount | Card) => void;
	onChangeFunc?: (e: string) => void;
}
function AccountNumberDropdown(props: AccountNumberDropdownProps): JSX.Element {
	const {
		data,
		noData = false,
		showArrow = false,
		isPayFrom = false,
		isLoading,
		inputValue,
		isDisabled,
		placeholder,
		showBalance = false,
		isSearchable = false,
		clickAndClose,
		isInputDisabled = false,
		onClickFunc,
		onChangeFunc,
	} = props;
	const [active, setActive] = useState(false);
	const domNode = useClickOutside(() => setActive(false));
	const inputRef = useRef<HTMLInputElement | null>(null);
	const [isHover, setIsHover] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [filteredAccounts, setFilteredAccounts] = useState<Array<UserAccount | CustomerAccount | Card>>([]);

	const cardBalances = useSelector((state: IRootState) => state.transferBetween.cardBalances);
	const userAccountMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
	const user = useSelector((state: IRootState) => state.init.main?.companyDetails.user);

	useEffect(() => {
		const accounts = data || [];
		const query = searchTerm.trim().toLowerCase();

		if (!isSearchable || !query.length) {
			setFilteredAccounts(accounts);
		} else {
			setFilteredAccounts(
				accounts.filter((item: UserAccount | CustomerAccount | Card) => {
					if (item instanceof UserAccount) {
						return (
							item.accountName.toLowerCase().includes(query) ||
							item.accountNumber.toLowerCase().includes(query) ||
							item.lencoNameMin.toLowerCase().includes(query)
						);
					}
					if (item instanceof CustomerAccount) {
						return (
							item.accountName.toLowerCase().includes(query) ||
							item.accountNumber.toLowerCase().includes(query) ||
							item.alias?.toLowerCase().includes(query)
						);
					}
					if (item instanceof Card) {
						return item.name.toLowerCase().includes(query) || item.maskedPan.toLowerCase().includes(query);
					}
				})
			);
		}
	}, [data, searchTerm, isSearchable]);

	const handleKeypress = (event: KeyboardEvent<HTMLDivElement>) => {
		//it triggers by pressing the enter key
		if (event.key === "Enter") {
			!isInputDisabled && inputRef?.current?.focus();
			setActive((prev) => !prev);
		}
	};

	return (
		<div className={`relative flex h-full w-full flex-col items-center justify-start`} ref={domNode}>
			<div
				className={
					`outline-none focus:outline-none relative h-12  w-full whitespace-nowrap rounded-lg border border-solid bg-transparent text-base capitalize leading-relaxed shadow-none transition-all duration-75 hover:text-blue focus:border-blue-focused focus:text-blue lg:hover:border-blue ` +
					`${
						inputValue && inputValue.length > 0
							? !active
								? "border-black-quin text-black-secondary hover:text-blue lg:hover:border-blue "
								: active
								? "border-blue"
								: "border-black-quin text-black-tertiary hover:text-blue lg:hover:border-blue"
							: active
							? "border-blue"
							: "border-black-quin text-black-tertiary"
					} ` +
					`${isHover ? "" : ""} ` +
					`${isInputDisabled ? "cursor-pointer" : "cursor-text"} `
				}
				onMouseEnter={() => setIsHover(true)}
				onMouseLeave={() => setIsHover(false)}
				onClick={() => {
					!isInputDisabled && inputRef?.current?.focus();
					setActive(true);
				}}
				tabIndex={isDisabled ? -1 : 0}
				onKeyDown={handleKeypress}
			>
				<div className="relative flex h-full w-full items-center justify-between space-x-4 overflow-hidden whitespace-nowrap rounded-lg bg-white px-4 py-3 text-left text-base font-normal capitalize leading-relaxed">
					<input
						id={placeholder}
						name={placeholder}
						ref={inputRef}
						type="text"
						value={titleCase(inputValue || "")}
						onChange={(e) => {
							onChangeFunc && onChangeFunc(e.target.value);
						}}
						autoComplete="off"
						className={
							`focus:outline-none h-10 w-full placeholder-transparent focus:border-none ` +
							`${inputValue && inputValue.length > 0 ? "text-black-secondary " : active ? "text-black" : "text-black"} ` +
							`${isInputDisabled ? "pointer-events-none cursor-pointer" : ""} ` +
							`${isDisabled ? "text-black-quat " : ""} `
						}
						placeholder="john@doe.com"
					/>
					{isLoading && (
						<div className="space-x-none absolute bottom-0 left-0 z-10 w-full">
							<BarLoader color="#5466F9" height="3" />
						</div>
					)}
					{showArrow && (
						<span
							className={
								`duration flex transform items-center  justify-end transition-transform ` +
								`${active ? "-rotate-180 text-blue" : "rotate-0"} ` +
								`${isDisabled ? "text-black-quat " : ""} `
							}
						>
							<ArrowDownIcon className="h-3.5 w-3.5 stroke-current" />
						</span>
					)}
				</div>
				<label
					htmlFor="text"
					className={
						`pointer-events-none absolute cursor-text duration-75 ease-in-out ` +
						`${
							active || (inputValue && inputValue?.length > 0)
								? "-top-2 left-2.5 bg-white px-1 text-xs "
								: "left-4 top-0 flex h-full items-center justify-center "
						} ` +
						`${active ? "text-blue" : ""} ` +
						`${active ? "text-blue" : inputValue && inputValue?.length > 0 ? "text-black-tertiary" : "text-black-tertiary"} ` +
						`${isHover ? "text-blue" : ""} ` +
						`${isDisabled ? "text-black-quat " : ""} `
					}
					onClick={() => !isInputDisabled && inputRef?.current?.focus()}
				>
					{placeholder}
				</label>
			</div>

			<div
				className={
					`absolute right-0 top-full z-50 mt-1 h-fit max-h-224px w-full transform cursor-pointer overflow-hidden overflow-y-auto rounded-lg bg-white shadow  ` +
					`${!active ? "pointer-events-none scale-0 opacity-0" : "scale-100 opacity-100"} `
				}
			>
				{data && data.length > 0 && isSearchable && (
					<div className="flex h-14 w-full items-center justify-start px-4">
						<SearchBar placeholder="Search for account" value={searchTerm} onChange={setSearchTerm} />
					</div>
				)}
				{isPayFrom
					? filteredAccounts
							.filter((_account) => !userAccountMeta?.some((_meta) => _meta.userAccountId === _account.id && _meta.isApproverOnly))
							.filter((_account) => (_account instanceof Card ? _account.assignedToCorporateAccountMember?.id === user?.id : _account))
							.map((el, index) => {
								const _cardBalance = cardBalances.get(el instanceof Card ? el.createRequestId : "");
								// const canInitiate = userAccountMeta?.some((_meta) => _meta.userAccountId === el.id && _meta.isInitiator);
								// const canInitiateCard = el instanceof Card && el.assignedToCorporateAccountMember?.id === user?.id;
								return (
									<AccountNumberItem
										key={index}
										data={el}
										onClick={() => {
											onClickFunc && onClickFunc(el);
											if (clickAndClose) return setActive((prev) => !prev);
										}}
										cardBalance={_cardBalance}
										showBalance={showBalance}
										// showBalance={!canInitiate ? false : showBalance}
										// isDisabled={el instanceof UserAccount || el instanceof CustomerAccount ? !canInitiate : !canInitiateCard}
										// message={
										// el instanceof UserAccount || el instanceof CustomerAccount
										// ? !canInitiate
										// ? "User role - You do not have access to initiate payment"
										// : undefined
										// : !canInitiateCard
										// ? "Assigned User - You were not assigned to this card"
										// : undefined
										// }
									/>
								);
							})
					: filteredAccounts.map((el, index) => {
							const _cardBalance = cardBalances.get(el instanceof Card ? el.createRequestId : "");
							return (
								<AccountNumberItem
									key={index}
									data={el}
									onClick={() => {
										onClickFunc && onClickFunc(el);
										if (clickAndClose) return setActive((prev) => !prev);
									}}
									cardBalance={_cardBalance}
									showBalance={showBalance}
								/>
							);
					  })}
				{noData && (
					<>
						<div className="flex h-14 w-full flex-row items-center justify-center space-x-2 text-sm text-black-tertiary">
							<EmptyXX className="h-10 w-10" />
							<span>No Accounts Found</span>
						</div>
					</>
				)}
			</div>
			{isDisabled && <div className="absolute left-0 top-0 z-10 h-full w-full cursor-default" />}
		</div>
	);
}

export default AccountNumberDropdown;
