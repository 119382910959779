import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {PeopleStageType, PeopleState} from "./peopleSlice.types";

import UserGroup from "../../../models/userGroup";

const initialState: PeopleState = {
	peopleStage: PeopleStageType.INITIAL,
	userGroupSelected: null,
	customerAccountList: null,
	userGroups: null,
};

export const peopleSlice = createSlice({
	name: "people",
	initialState,
	reducers: {
		setPeopleStage: (state: PeopleState, action: PayloadAction<PeopleStageType>) => {
			state.peopleStage = action.payload;
		},
		setSelectedUserGroup: (state: PeopleState, action: PayloadAction<UserGroup>) => {
			state.userGroupSelected = action.payload;
		},
		setUserGroups: (state: PeopleState, action: PayloadAction<UserGroup[]>) => {
			state.userGroups = action.payload;
		},
		setCustomerAccountList: (state: PeopleState, action: PayloadAction<string[]>) => {
			state.customerAccountList = action.payload;
		},
		removeCustomerAccount: (state: PeopleState, action: PayloadAction<string>) => {
			state.customerAccountList = state.customerAccountList?.filter((_el) => _el !== action.payload) || state.customerAccountList;
		},
		removeUserGroup: (state: PeopleState, action: PayloadAction<UserGroup>) => {
			state.userGroupSelected = action.payload;
			state.peopleStage = PeopleStageType.DELETE_CATEGORY;
		},
		editUserGroup: (state: PeopleState, action: PayloadAction<UserGroup>) => {
			state.userGroupSelected = action.payload;
			state.peopleStage = PeopleStageType.EDIT_CATEGORY;
		},
		resetPeopleStage: (state: PeopleState) => {
			state.peopleStage = PeopleStageType.INITIAL;
		},
		resetSelectedUserGroup: (state: PeopleState) => {
			state.userGroupSelected = null;
		},
		resetCustomerAccountList: (state: PeopleState) => {
			state.customerAccountList = null;
		},
		resetAllPeopleData: (state: PeopleState) => {
			state.userGroupSelected = null;
			state.customerAccountList = null;
			state.peopleStage = PeopleStageType.INITIAL;
		},
	},
});

export const {
	setPeopleStage,
	setSelectedUserGroup,
	resetPeopleStage,
	resetSelectedUserGroup,
	removeUserGroup,
	editUserGroup,
	setUserGroups,
	setCustomerAccountList,
	resetCustomerAccountList,
	removeCustomerAccount,
	resetAllPeopleData,
} = peopleSlice.actions;

export default peopleSlice.reducer;
