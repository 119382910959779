import Parsers from "../utils/parsers";
import {GenericObject} from "../helpers/types";
import {TransactionType} from "./transaction.constants";
import {add} from "../utils/math";
import {immerable} from "immer";

export default class LastTransaction {
	[immerable] = true;

	constructor(
		public transactionId: string,
		public amount: number,
		public charges: number,
		public type: TransactionType,
		public datetime: Date | null
	) {}

	static create(obj: GenericObject): LastTransaction {
		return new LastTransaction(
			Parsers.string(obj.transactionId),
			Parsers.number(obj.amount) || 0,
			Parsers.number(obj.charges) || 0,
			Parsers.number(obj.type) || 0,
			Parsers.date(obj.datetime)
		);
	}

	get total(): number {
		return add(this.amount, this.charges);
	}
}
