import AddMoneyCard from "./Card/AddMoneyCard";
import ButtonComp from "../../../../../../../components/General/Buttons/ButtonComp";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {ReactComponent as InfoIcon} from "../../../../../../../assets/svg/Accounts/info.svg";
import Modal from "../../../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../../../components/General/Modals/ModalBody";
import ModalFooter from "../../../../../../../components/General/Modals/ModalFooter";
import ModalHeader from "../../../../../../../components/General/Modals/ModalHeader";
import React from "react";
import {useSelector} from "react-redux";

interface Props {
	selectedAccountId: string;
	handleDone: () => void;
	handleBack: () => void;
	handleClose: () => void;
}

function BankTransfer(props: Props): JSX.Element {
	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

	const account = useSelector(
		(state: IRootState) => state.init.main?.companyDetails.accounts.find((acc) => acc.id === props.selectedAccountId) || null
	);

	return (
		<>
			<Modal size="sm" active toggler={props.handleClose}>
				<ModalHeader onClose={props.handleClose} subTitle="You can add money to your account via Bank Transfer">
					Add Money
				</ModalHeader>
				<ModalBody>
					<div className="flex w-full flex-col space-y-4">
						<AddMoneyCard
							accountName={"Broadpay Zambia Limited" || ""}
							accountNumber={"9130000328395" || ""}
							medium="Stanbic Ibtc Bank"
							branch="40002"
							swift="SBI MULCH"
						/>
						<div className="flex w-full flex-col space-y-2 rounded-md border bg-blue-backdrop p-4">
							<div className="flex w-full items-center space-x-2">
								<InfoIcon />
								<h6 className="text-sm font-medium text-black-secondary">Fill out the memo as written below</h6>
							</div>
							<p className="text-xs text-black-tertiary">
								FFC - {account?.accountNumber || ""} - {account?.accountName || ""}
							</p>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
						{(accounts?.length || 0) > 1 && (
							<div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
								<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleBack} fullWidth>
									<span>Back</span>
								</ButtonComp>
							</div>
						)}
						<div className="order-1 w-full 2xs:order-2 2xs:w-max">
							<ButtonComp type="button" color="blue" ripple="light" buttonType="primary" fullWidth func={props.handleDone}>
								<span>I&apos;ve Sent the Money</span>
							</ButtonComp>
						</div>
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default BankTransfer;
