import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import MessageToasts from "../../../../../../components/General/MessageToasts/MessageToasts";
import React from "react";
import {ToastType} from "../../../../../../helpers/AppConstants";
import commaSeparator from "../../../../../../utils/commaSeparator";
import formatNumber from "../../../../../../utils/formatNumber";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
interface Props {
	zambiaMinAmount?: number;
	zambiaMaxAmount?: number;
	isZambiaMinAmount?: boolean;
	isZambiaMaxAmount?: boolean;
	isApprovalError?: boolean;
	isInsufficientFunds?: boolean;
	maxApprovalAmount?: number;
	balance?: number;
}

function ErrorCard(props: Props): JSX.Element {
	return (
		<>
			<MessageToasts
				toastMessage={
					<>
						{props.isZambiaMinAmount && (
							<div>
								The minimum you can send is <CurrencyCode />
								{formatNumber(props.zambiaMinAmount || 0)}
							</div>
						)}
						{props.isZambiaMaxAmount && (
							<div>
								The maximum you can send is <CurrencyCode />
								{formatNumber(props.zambiaMaxAmount || 0)}
							</div>
						)}
						{props.isApprovalError && !isNullOrUndefined(props.maxApprovalAmount) && (
							<div>
								You can only send <CurrencyCode />
								{formatNumber(props.maxApprovalAmount)} or less on this account. Any amount more than this will be initiated and need
								to be approved by an admin.
							</div>
						)}
						{props.isInsufficientFunds && !isNullOrUndefined(props.balance) && (
							<div>
								You do not have enough funds. Your account balance is <CurrencyCode />
								{commaSeparator(props.balance)}
							</div>
						)}
					</>
				}
				toastType={props.isApprovalError ? ToastType.INFORMATION : ToastType.ERROR}
			/>
		</>
	);
}

export default ErrorCard;
