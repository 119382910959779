import {CorporateAccountType} from "./corporate.constants";
import {Countries} from "../redux/init/slice/initSlice.types";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class Corporate {
	[immerable] = true;

	constructor(
		public id: string,
		public name: string,
		public shortName: string | null,
		public accountCreated: boolean,
		public accountCreatedOn: Date | null,
		public operatingAddress: string,
		public registeredAddress: string,
		public type: CorporateAccountType,
		public isRegistered: boolean | null,
		public country: Countries | null
	) {}

	static create(obj: GenericObject): Corporate {
		return new Corporate(
			Parsers.string(obj.id),
			Parsers.string(obj.name),
			Parsers.nullableString(obj.shortName),
			Parsers.boolean(obj.accountCreated),
			Parsers.date(obj.accountCreatedOn),
			Parsers.string(obj.operatingAddress),
			Parsers.string(obj.registeredAddress),
			Parsers.number(obj.type),
			Parsers.nullableBoolean(obj.isRegistered),
			Parsers.nullableEnum(obj.country, Countries)
		);
	}

	get isTypeFull(): boolean {
		return this.type === CorporateAccountType.FULL;
	}

	get isTypeBeta(): boolean {
		return this.type === CorporateAccountType.BETA;
	}

	get isTypeStarter(): boolean {
		return this.type === CorporateAccountType.STARTER;
	}

	get isZambia(): boolean {
		return this.country === Countries.ZAMBIA;
	}
	get isNigeria(): boolean {
		return this.country === Countries.NIGERIA;
	}

	get typeName(): string {
		if (this.type === CorporateAccountType.STARTER) {
			return "starter";
		}
		if (this.type === CorporateAccountType.BETA) {
			return "prime";
		}
		if (this.type === CorporateAccountType.FULL) {
			return "pro";
		}
		return "";
	}
}
