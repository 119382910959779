import React, {useEffect, useState} from "react";

import ButtonComp from "../../../../../../../../components/General/Buttons/ButtonComp";
// import CurrencyCode from "../../../../../../../../components/General/CurrencyCode";
import {IRootState} from "../../../../../../../../redux/rootReducer";
// import {OtpActivityType} from "../../../../../../../../helpers/AppConstants";
import OtpInputForm from "../../../../../../../../components/OtpInputForm";
// import {TailSpin} from "react-loader-spinner";
// import formatNumber from "../../../../../../../../utils/formatNumber";
import useMakePayment from "../../../../../../Accounts/Hooks/State/Zambia/MobileMoney/useMakePayment";
// import useOtpInit from "../../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useOtpInit";
import {useSelector} from "react-redux";

interface Props {
	keyId: string;
	recipientPhoneNumber: string;
	handleBack: () => void;
	handleNext: () => void;
}

function MobileMoneyMakePayment(props: Props): JSX.Element {
	// const [resendOtpText, setResendOtpText] = useState(false);
	// const {minutes, seconds, isInitOTPLoading, handleOTPInit, handleResendOTPInit} = useOtpInit({});

	const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

	const [otp, setOtp] = useState("");

	const {isMakePaymentLoading, makePaymentError, handleMakePayment} = useMakePayment({
		onComplete: () => props.handleNext(),
	});

	// useLayoutEffect(() => {
	// if (isAuthAppSetup) return;
	// handleOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
	// }, [handleOTPInit, isAuthAppSetup]);

	useEffect(() => {
		if (otp.length < 6) return;
		void handleMakePayment({
			key: props.keyId,
			otp: otp,
		});
	}, [otp]);

	const handleOtpChange = (_otp: string) => {
		setOtp(_otp);
	};

	return (
		<>
			{/* {!isAuthAppSetup && isInitOTPLoading && (
				<>
					<div className="flex flex-row items-start justify-start space-x-4 text-sm">
						<TailSpin color="#5466F9" height={20} width={20} />
						<span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
					</div>
				</>
			)} */}
			{/* {!isInitOTPLoading && (
				<> */}
			<div className="flex w-full max-w-sm flex-col space-y-8">
				<p className=" text-sm  font-normal">
					{!isAuthAppSetup && "Enter the OTP sent to your phone number ending with "}
					••••{props.recipientPhoneNumber.slice(-4)}
					<span> to authorize payment</span>
				</p>

				<div className="mx-auto flex flex-col w-full">
					<OtpInputForm value={otp || undefined} otpFunc={handleOtpChange} errorMessage={makePaymentError} />
					{/* {!isAuthAppSetup && (
								<div className="flex items-start justify-start pt-4">
									<div>
										{minutes === 0 && seconds === 0 ? (
											<div>
												<span
													className="cursor-pointer  text-sm text-blue"
													onClick={() => {
														handleResendOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
														setResendOtpText(true);
													}}
												>
													Resend OTP
												</span>
											</div>
										) : (
											<span className="cursor-not-allowed  text-sm text-black-tertiary">
												Resend OTP in{" "}
												<span className="text-black-secondary">
													{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
												</span>
											</span>
										)}
									</div>
								</div>
							)} */}
				</div>
			</div>
			{/* </>
			)} */}
			<div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
				<div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
					<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleBack} fullWidth>
						<span>Cancel</span>
					</ButtonComp>
				</div>
				<div className="order-1 w-full 2xs:order-2 2xs:w-max">
					<ButtonComp
						type="submit"
						color="blue"
						ripple="light"
						buttonType="primary"
						isLoading={isMakePaymentLoading}
						disable={otp.length < 6 || isMakePaymentLoading}
						func={() =>
							void handleMakePayment({
								key: props.keyId,
								otp: otp,
							})
						}
					>
						<span>Authorize</span>
					</ButtonComp>
				</div>
			</div>
		</>
	);
}

export default MobileMoneyMakePayment;
