import AccountOpeningProgressGeneric from "./accountOpeningProgressGeneric";
import {GenericObject} from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import {immerable} from "immer";

export default class AccountOpeningProgressSignatories {
	[immerable] = true;

	constructor(
		public currentSignatory: boolean,
		public signatories: AccountOpeningProgressGeneric,
		public otherDirectors: AccountOpeningProgressGeneric
	) {}

	static create(obj: GenericObject): AccountOpeningProgressSignatories {
		return new AccountOpeningProgressSignatories(
			Parsers.boolean(obj.currentSignatory),
			Parsers.classObjectNonNullable(obj.signatories, AccountOpeningProgressGeneric),
			Parsers.classObjectNonNullable(obj.otherDirectors, AccountOpeningProgressGeneric)
		);
	}

	hasInvitedSignatories(): boolean {
		return this.signatories.total >= 1;
	}

	progressPercentage(): number {
		if (this.signatories.total === 0) return 0;
		return Number((this.signatories.completed / this.signatories.total) * 100);
	}

	isCompleted(): boolean {
		return this.hasInvitedSignatories() && this.signatories.isCompleted() && this.otherDirectors.isCompleted();
	}
}
