import ButtonComp from "../../../../../../../components/General/Buttons/ButtonComp";
import {IRootState} from "../../../../../../../redux/rootReducer";
import React from "react";
import SendableTextarea from "../../../../../../../components/General/TextArea/SendableTextarea/SendableTextarea";
import Transaction from "../../../../../../../models/transaction";
import titleCase from "../../../../../../../hooks/titleCase";
import useClickOutside from "../../../../../../../hooks/useClickOutside";
import {useSelector} from "react-redux";
import useTransactionDetailsCardHeadSender from "../Hooks/useTransactionDetailsCardHeadSender";

interface Props {
	transaction: Transaction;
}

function TransactionDetailsCardHeadSender({transaction}: Props): JSX.Element {
	const {
		isSubmitting,
		submitForm,

		showEditAliasForm,
		handleShowEditForm,
		handleHideEditForm,

		senderAlias,
		setSenderAlias,
	} = useTransactionDetailsCardHeadSender(transaction);
	const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);

	const domNode = useClickOutside(() => {
		handleHideEditForm();
	});

	return (
		<>
			{transaction && transaction.isCredit() && (
				<div className="w-full" ref={domNode}>
					{showEditAliasForm && (
						<form
							className="w-full"
							onSubmit={(e) => {
								e.preventDefault();
								void submitForm(transaction, senderAlias || "");
							}}
						>
							<SendableTextarea
								placeholder="Edit sender alias"
								transactionCard
								value={senderAlias}
								onClickFunc={() => submitForm(transaction, senderAlias || "")}
								onChangeFunc={(e) => setSenderAlias(e)}
								isLoading={isSubmitting}
								data-type="transaction-active"
								buttonText="Edit Alias"
							/>
							{/* <EditInput
								data-type="transaction-active"
								value={senderAlias}
								isLoading={isSubmitting}
								activeFunc={() => handleShowEditForm(transaction)}
								onClickFunc={() => submitForm(transaction, senderAlias || "")}
								onChangeFunc={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setSenderAlias(e.target.value);
								}}
							/> */}
						</form>
					)}

					{!showEditAliasForm && (
						<>
							<div className="relative flex flex-col items-end justify-start ">
								<p
									className="max-w-full break-words text-right text-sm font-medium text-black-secondary"
									data-type="transaction-active"
								>
									{titleCase(transaction.origination?.alias || transaction.description)}
								</p>
								{isNigeria && (
									<ButtonComp buttonType="tertiary" color="blue" size="sm" func={() => handleShowEditForm(transaction)}>
										<span className="text-sm">Edit</span>
									</ButtonComp>
								)}
							</div>
						</>
					)}
				</div>
			)}
		</>
	);
}

export default TransactionDetailsCardHeadSender;
