import {AccountInitState, Countries, MainInitState} from "../../slice/initSlice.types";
import {accountInit, accountInitWithSignInKey, mainInit} from "../init.api";
import {
	accountInitFailure,
	accountInitSuccess,
	accountOpeningInitFailure,
	accountOpeningInitSuccess,
	initRequest,
	mainInitFailure,
	mainInitRequest,
	mainInitSuccess,
	setAccountCreated,
	setCanAccessCards,
	setCanAccessTerminals,
} from "../../slice/initSlice";
import {
	nigeriaAccountOpeningInit,
	nigeriaBusinessAccountManger,
} from "../../../../modules/Dashboard/Application/services/nigeria-application/nigeiraAccountOpeningInit";
import {resetAllApplicationState, setAccountOpeningInit, setNigeriaAccountManager} from "../../../application/applicationSlice";
import {resetZambiaAllApplicationState, setZambiaAccountManager, setZambiaAccountOpeningInit} from "../../../application/zambiaApplicationSlice";
import {useCallback, useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
	zambiaAccountOpeningInit,
	zambiaBusinessAccountManger,
} from "../../../../modules/Dashboard/Application/services/zambia-application/zambiaAccountOpeningInit";

import {IRootState} from "../../../rootReducer";
import IntercomHelper from "../../../../helpers/intercom";
import {Routes} from "../../../../routes/routes.constants";
import SocketWrapper from "../../../../helpers/websocket/socketWrapper";
import {errorTrue} from "../../../error/slice/errorSlice";
import {getErrorMessage} from "../../../../utils/getErrorMessage";
import {registerEventHandlers} from "../../../../helpers/websocket/eventHandlers";
import {useHistory} from "react-router-dom";

function useInit(): {isAppReady: boolean} {
	const dispatch = useDispatch();
	const history = useHistory();
	// const location = useLocation();
	const account = useSelector<IRootState, AccountInitState | null>((state) => state.init.account);
	const selectedCompanyId = useSelector<IRootState, string | null>((state) => state.init.selectedCompanyId);
	const [isAppReady, setIsAppReady] = useState<boolean>(false);

	const checkForSignInKey = useCallback(async (): Promise<boolean> => {
		// check if the url is for the dashboard, and there is a query "key"
		try {
			if ((window.location.pathname === "/" || window.location.pathname === "") && window.location.search) {
				const searchParams = new URLSearchParams(window.location.search);
				if (searchParams.has("key")) {
					return await accountInitWithSignInKey(searchParams.get("key") as string);
				}
			}
			return false;
		} catch (err) {
			return false;
		}
	}, [dispatch, window.location]);

	const handleAccountInit = useCallback(async () => {
		dispatch(initRequest());
		dispatch(resetZambiaAllApplicationState());
		dispatch(resetAllApplicationState());
		try {
			if (await checkForSignInKey()) {
				window.location.replace("/");
				return;
			}

			const res = await accountInit();
			// if there is only one company or if there is selectedCompanyId, select it
			let companyIdToSelect: string | null = null;
			if (res.companies.length === 1) {
				companyIdToSelect = res.companies[0].id;
			} else if (res.selectedCompanyId) {
				companyIdToSelect = res.selectedCompanyId;
			}
			dispatch(
				accountInitSuccess({
					account: res,
					selectedCompanyId: companyIdToSelect,
				})
			);
			IntercomHelper.update({
				user_id: res.user.id,
				email: res.user.email,
				name: res.user.name,
				user_hash: res.user.intercomHash, // HMAC using SHA-256
			});
		} catch (err) {
			history.push("/login");
			const errorMessage = getErrorMessage(err);
			dispatch(accountInitFailure(errorMessage));
		}
	}, [dispatch]);

	const handleMainInit = useCallback(async () => {
		dispatch(mainInitRequest());
		try {
			const res = await mainInit();
			dispatch(mainInitSuccess(res));
			const user = res.companyDetails.user;
			dispatch(
				setCanAccessCards(user.isAdmin || res.companyDetails.cards.some((_card) => _card?.assignedToCorporateAccountMember?.id === user.id))
			);
			dispatch(setCanAccessTerminals(user.hasManageTerminalAccess || user.hasViewTerminalAccess));
			setupWebsocket(res);
			IntercomHelper.update({
				plan: res.companyDetails.company.typeName,
				phone: res.companyDetails.user.phones[0],
			});
			if (window.location.pathname.includes("/application")) {
				history.push(Routes.DASHBOARD);
			}
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			dispatch(mainInitFailure(errorMessage));
			dispatch(errorTrue(errorMessage));
		}
	}, [dispatch]);

	const handleNigeriaAccountOpeningInit = useCallback(async () => {
		dispatch(initRequest());
		dispatch(resetAllApplicationState());
		try {
			const res = await nigeriaAccountOpeningInit();
			dispatch(setAccountOpeningInit(res));
			dispatch(resetZambiaAllApplicationState());
			dispatch(accountOpeningInitSuccess());
			if (!window.location.pathname.includes("/application")) {
				history.push("/application");
			}
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			dispatch(accountOpeningInitFailure(errorMessage));
		}
	}, [dispatch]);

	const handleZambiaAccountOpeningInit = useCallback(async () => {
		dispatch(initRequest());
		dispatch(resetZambiaAllApplicationState());
		try {
			const res = await zambiaAccountOpeningInit();
			dispatch(setZambiaAccountOpeningInit(res));
			dispatch(resetAllApplicationState());
			dispatch(accountOpeningInitSuccess());
			if (!window.location.pathname.includes("/application")) {
				history.push("/application");
			}
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			dispatch(accountOpeningInitFailure(errorMessage));
		}
	}, [dispatch]);

	const handleNigeriaAccountManager = useCallback(async () => {
		try {
			const res = await nigeriaBusinessAccountManger();
			dispatch(setNigeriaAccountManager(res));
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			dispatch(errorTrue(errorMessage));
		}
	}, [dispatch]);

	const handleZambiaAccountManager = useCallback(async () => {
		try {
			const res = await zambiaBusinessAccountManger();
			dispatch(setZambiaAccountManager(res));
		} catch (err) {
			const errorMessage = getErrorMessage(err);
			dispatch(errorTrue(errorMessage));
		}
	}, [dispatch]);

	const setupWebsocket = useCallback((res: MainInitState) => {
		const socket = SocketWrapper.setup(res.socket.url, res.socket.secure, res.socket.token);
		registerEventHandlers(socket);
	}, []);

	// on mount, try account init
	useEffect(() => {
		void handleAccountInit();
		setIsAppReady(true);
	}, []);

	// Get company details on company switch and company select
	useLayoutEffect(() => {
		if (selectedCompanyId && account) {
			const selectedCompany = account.companies.find((c) => c.id === selectedCompanyId);
			if (selectedCompany) {
				dispatch(setAccountCreated(selectedCompany.accountCreated));
				if (selectedCompany.accountCreated) {
					void handleMainInit();
				} else {
					if (selectedCompany.country === Countries.NIGERIA) {
						void handleNigeriaAccountOpeningInit();
						void handleNigeriaAccountManager();
					} else {
						void handleZambiaAccountOpeningInit();
						void handleZambiaAccountManager();
					}
				}
			}
		}
	}, [selectedCompanyId]);

	return {isAppReady};
}

export default useInit;
