import AccountFaqAnswerItem from "./accountFaqAnswerItem";
import {GenericObject} from "../../helpers/types";
import Parsers from "../../utils/parsers";
import {immerable} from "immer";

export default class AccountFaq {
	[immerable] = true;

	constructor(public id: string, public question: string, public answers: AccountFaqAnswerItem[]) {}

	static create(obj: GenericObject): AccountFaq {
		return new AccountFaq(Parsers.string(obj.id), Parsers.string(obj.question), Parsers.classObjectArray(obj.answers, AccountFaqAnswerItem));
	}
}
