import {DirectorPageType, ExternalDirectorPageType, ExternalDirectorSubPageType} from "./service/director.constants";
import {PageTitle, ToastType} from "../../../../helpers/AppConstants";
import React, {useEffect, useLayoutEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import DirectorApplicationDone from "../BusinessForms/Components/General/ApplicationDone";
import DirectorReview from "./components/DirectorReview/DirectorReview";
import DirectorStatus from "./components/status";
import ExternalDirectorLayout from "./components/layout";
import ExternalFormLayout from "../Components/Layout";
import {IRootState} from "../../../../redux/rootReducer";
import IdentificationForm from "./components/identification-form/IdentificationForm";
import {InviteType} from "./service/externalApplication.types";
import MessageToasts from "../../../../components/General/MessageToasts/MessageToasts";
import NextOfKinForm from "./components/next-of-kin-form/NextOfKinForm";
import PersonalDetails from "./components/PersonalDetails";
import {TailSpin} from "react-loader-spinner";
import ToastContainer from "../../../../components/General/ToastContainer";
import {resetAllExternalApplicationState} from "../../../../redux/externalApplication/externalApplicationSlice";
import useExternalApplicationInit from "./Hooks/useExternalApplicationInit";
import {useParams} from "react-router";

interface DirectorUrlParams {
	a: string;
	b: string;
	stage: ExternalDirectorSubPageType;
	section: ExternalDirectorPageType;
}

function DirectorForm(): JSX.Element {
	document.title = PageTitle.DIRECTORS_PAGE;

	const dispatch = useDispatch();

	const {a, b, stage, section} = useParams<DirectorUrlParams>();

	const {externalApplicationInitError, isExternalApplicationInitLoading, handleRest, handleNigeriaAccountManager, handleExternalApplicationInit} =
		useExternalApplicationInit();

	const externalLink = useSelector((state: IRootState) => state.externalApplication.externalLink);
	const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

	useLayoutEffect(() => {
		if (a && b) {
			void handleExternalApplicationInit({
				a,
				b,
				type: InviteType.S,
			});
			void handleNigeriaAccountManager();
		}
	}, [a, b]);

	useEffect(() => {
		return () => {
			handleRest();
			dispatch(resetAllExternalApplicationState());
		};
	}, []);

	return (
		<>
			<ExternalFormLayout url={externalLink || ""}>
				<ToastContainer />
				{isExternalApplicationInitLoading && (
					<div className="flex justify-center items-center w-full h-screen">
						<TailSpin color="#5466F9" height={30} width={30} />
					</div>
				)}
				{externalApplicationInitError && (
					<div className="flex flex-col justify-center items-center w-full h-full">
						<div className="w-max">
							<MessageToasts toastType={ToastType.ERROR} toastMessage={externalApplicationInitError} />
						</div>
					</div>
				)}
				{externalApplicationInit && !externalApplicationInitError && !isExternalApplicationInitLoading && (
					<>
						{!section ? (
							<DirectorStatus />
						) : (
							<>
								{section === DirectorPageType.DONE && <DirectorApplicationDone url={externalLink || ""} />}
								{section !== DirectorPageType.DONE && (
									<ExternalDirectorLayout section={section} stage={stage} url={externalLink || ""}>
										{section === DirectorPageType.PERSONAL && <PersonalDetails stage={stage} />}
										{section === DirectorPageType.NEXT_OF_KIN && <NextOfKinForm />}
										{section === DirectorPageType.MEANS_OF_ID && <IdentificationForm />}
										{section === DirectorPageType.REVIEW && <DirectorReview />}
									</ExternalDirectorLayout>
								)}
							</>
						)}
					</>
				)}
			</ExternalFormLayout>
		</>
	);
}

export default DirectorForm;
