import {FeeType} from "../redux/init/slice/initSlice.types";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class BasicFeeData {
	[immerable] = true;

	constructor(public type: FeeType | null, public value: number, public cap: number | null) {}

	static create(obj: GenericObject): BasicFeeData {
		return new BasicFeeData(Parsers.nullableEnum(obj.type, FeeType), Parsers.number(obj.value), Parsers.nullableNumber(obj.cap));
	}

	get isFlatFee(): boolean {
		return this.type === FeeType.FLAT_FEE;
	}
	get isPercentageFee(): boolean {
		return this.type === FeeType.PERCENTAGE;
	}
	get hasPercentageCap(): boolean {
		return !!this.cap || !!(this.cap && this.cap > 0);
	}
}
