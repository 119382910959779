import React, {useEffect, useState} from "react";

import {ReactComponent as ArrowDownIcon} from "../../../assets/svg/General/arrowDownIcon.svg";
import ButtonComp from "../../General/Buttons/ButtonComp";
import {CompanyInterface} from "../../../helpers/types";
import {Countries} from "../../../redux/init/slice/initSlice.types";
import {ReactComponent as EmptyX} from "../../../assets/svg/EmptyX.svg";
import {IRootState} from "../../../redux/rootReducer";
import Layout from "../../../components/Layout";
import NigeriaFlag from "../../../assets/svg/Countries/nigeria.png";
import SearchBar from "../../../modules/Dashboard/Settings/Components/Searchbar/TeamMemebers/SearchBar";
import SelectBusinessSkeleton from "./Skeleton/SelectBusinessSkeleton";
import ZambiaFlag from "../../../assets/svg/Countries/zambia.png";
import getAvatarColorClass from "../../../helpers/avatarColors";
import useLogout from "../../../redux/init/services/hooks/useLogout";
import {useSelector} from "react-redux";

interface SelectBusinessModelProps {
	accountCompaniesData?: CompanyInterface[] | null;
	selectCompanyFunc?: ((id: string) => void) | null;
}

const enum SelectedCountryBusiness {
	NIGERIA = "nigeria",
	ZAMBIA = "zambia",
}

function SelectBusinessModal({accountCompaniesData = null, selectCompanyFunc = null}: SelectBusinessModelProps): JSX.Element {
	const isZambia = useSelector((state: IRootState) => state.init.account?.companies[0].country === Countries.ZAMBIA);

	const [selectedCountry, setSelectedCountry] = useState<SelectedCountryBusiness | null>(
		isZambia ? SelectedCountryBusiness.ZAMBIA : SelectedCountryBusiness.NIGERIA
	);

	const [nigerianCompanies, setNigerianCompanies] = useState<CompanyInterface[]>(
		accountCompaniesData?.filter((_company) => _company.country === Countries.NIGERIA) || []
	);
	const [zambianCompanies, setZambianCompanies] = useState<CompanyInterface[]>(
		accountCompaniesData?.filter((_company) => _company.country === Countries.ZAMBIA) || []
	);

	const [searchTerm, setSearchTerm] = useState("");

	useEffect(() => {
		if (!accountCompaniesData) return;
		if (searchTerm !== "") {
			const filteredData = accountCompaniesData.filter((item) => {
				return Object.values(item.name).join("").toLowerCase().includes(searchTerm.toLowerCase());
			});
			setNigerianCompanies(filteredData.filter((_company) => _company.country === Countries.NIGERIA));
			setZambianCompanies(filteredData.filter((_company) => _company.country === Countries.ZAMBIA));
		} else {
			setNigerianCompanies(accountCompaniesData?.filter((_company) => _company.country === Countries.NIGERIA));
			setZambianCompanies(accountCompaniesData?.filter((_company) => _company.country === Countries.ZAMBIA));
		}
	}, [searchTerm, accountCompaniesData]);

	const searchQuery = (searchValue: string) => {
		setSearchTerm(searchValue);
	};

	const {handleLogout} = useLogout();

	return (
		<>
			<div className="fixed z-100 flex h-full w-full items-center justify-center bg-white ">
				<Layout showNothing>
					<div className="flex w-full max-w-sm flex-col items-center justify-start space-y-4 overflow-hidden rounded-lg py-8 3xs:shadow">
						<div className="text-2xl font-medium text-black">Select a Business</div>
						<div className="w-full px-4 pt-2">
							{accountCompaniesData && accountCompaniesData.length > 5 && (
								<SearchBar placeholder="Search business name" value={searchTerm || ""} onChange={(e: string) => searchQuery(e)} />
							)}
						</div>

						{accountCompaniesData ? (
							<>
								{nigerianCompanies.length > 0 && (
									<div className="w-full">
										<div
											className="bg-grey-tertiary"
											data-type="section"
											onClick={() =>
												setSelectedCountry((prev) =>
													prev === SelectedCountryBusiness.NIGERIA ? null : SelectedCountryBusiness.NIGERIA
												)
											}
											tabIndex={0}
										>
											<ButtonComp
												ripple="light"
												color="transparent"
												type="button"
												buttonType="primary"
												data-type="section"
												noTabIndex
												fullWidth
											>
												<div
													className={`relative flex h-9 w-full items-center justify-start tracking-normal  `}
													data-type="section"
													tabIndex={-1}
												>
													<img
														className="border border-black-quat rounded-full"
														src={NigeriaFlag}
														alt="Nigerian flag"
														width="24px"
														height="24px"
													/>
													<div
														className="ml-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm"
														data-type="section"
														tabIndex={-1}
													>
														Nigeria
													</div>
												</div>
												<span
													className={
														`absolute right-6 text-lg font-light transition-all ` +
														`${
															selectedCountry === SelectedCountryBusiness.NIGERIA ? "-rotate-180 transform" : "rotate-0"
														} `
													}
													data-type="section"
												>
													<ArrowDownIcon className="h-3.5 w-3.5 stroke-current" data-type="section" tabIndex={-1} />
												</span>
											</ButtonComp>
										</div>
										<div
											className={
												`${selectedCountry === SelectedCountryBusiness.NIGERIA ? "max-h-76" : "max-h-0"} ` +
												"overflow-hidden transition-all duration-300 ease-in-out"
											}
										>
											<div className="flex w-full flex-col items-start justify-start">
												<div className="select__business flex h-full max-h-76 w-full flex-col overflow-y-auto">
													{nigerianCompanies.map((item, index) => (
														<div
															className="flex w-full cursor-pointer flex-row items-center justify-start space-x-4 px-6 py-6 hover:bg-blue-senary"
															onClick={() => (selectCompanyFunc ? selectCompanyFunc(item.id) : null)}
															key={index}
														>
															<div>
																<div
																	className={`flex h-8 w-8 items-center justify-center rounded-full text-black-secondary ${getAvatarColorClass(
																		item.id
																	)}`}
																>
																	{item.name.trim().substring(0, 1)}
																</div>
															</div>
															<div className="flex flex-col items-start justify-start">
																<div className="text-base text-black">{item.name}</div>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								)}
								{zambianCompanies.length > 0 && (
									<div className="w-full">
										<div
											className="bg-grey-tertiary"
											data-type="section"
											onClick={() =>
												setSelectedCountry((prev) =>
													prev === SelectedCountryBusiness.ZAMBIA ? null : SelectedCountryBusiness.ZAMBIA
												)
											}
											tabIndex={0}
										>
											<ButtonComp
												ripple="light"
												color="transparent"
												type="button"
												buttonType="primary"
												data-type="section"
												noTabIndex
												fullWidth
											>
												<div
													className={`relative flex h-9 w-full items-center justify-start tracking-normal  `}
													data-type="section"
													tabIndex={-1}
												>
													<img
														className="border border-black-quat rounded-full"
														src={ZambiaFlag}
														alt="Zambian flag"
														width="24px"
														height="24px"
													/>
													<div
														className="ml-2 overflow-hidden overflow-ellipsis whitespace-nowrap text-sm"
														data-type="section"
														tabIndex={-1}
													>
														Zambia
													</div>
												</div>
												<span
													className={
														`absolute right-6 text-lg font-light transition-all ` +
														`${
															selectedCountry === SelectedCountryBusiness.ZAMBIA ? "-rotate-180 transform" : "rotate-0"
														} `
													}
													data-type="section"
												>
													<ArrowDownIcon className="h-3.5 w-3.5 stroke-current" data-type="section" tabIndex={-1} />
												</span>
											</ButtonComp>
										</div>
										<div
											className={
												`${selectedCountry === SelectedCountryBusiness.ZAMBIA ? "max-h-76" : "max-h-0"} ` +
												"overflow-hidden transition-all duration-300 ease-in-out"
											}
										>
											<div className="flex w-full flex-col items-start justify-start">
												<div className="select__business flex h-full max-h-76 w-full flex-col overflow-y-scroll">
													{zambianCompanies?.map((item, index) => (
														<div
															className="flex w-full cursor-pointer flex-row items-center justify-start space-x-4 px-6 py-6 hover:bg-blue-senary"
															onClick={() => (selectCompanyFunc ? selectCompanyFunc(item.id) : null)}
															key={index}
														>
															<div>
																<div
																	className={`flex h-8 w-8 items-center justify-center rounded-full text-black-secondary ${getAvatarColorClass(
																		item.id
																	)}`}
																>
																	{item.name.trim().substring(0, 1)}
																</div>
															</div>
															<div className="flex flex-col items-start justify-start">
																<div className="text-base text-black">{item.name}</div>
															</div>
														</div>
													))}
												</div>
											</div>
										</div>
									</div>
								)}
								{nigerianCompanies.length < 1 && zambianCompanies.length < 1 && (
									<div className="flex flex-col items-center justify-center space-y-2 text-center py-12">
										<EmptyX className="h-16 w-16" />
										<span className="text-sm text-black-secondary">No result</span>
									</div>
								)}
							</>
						) : (
							<>
								<div className="space-y-4 px-8 w-full">
									<SelectBusinessSkeleton />
									<SelectBusinessSkeleton />
								</div>
							</>
						)}
					</div>
					<>
						<p className="flex flex-row  space-x-2 pt-6 text-base font-normal text-black-tertiary">
							<span className=" cursor-pointer whitespace-nowrap text-blue" onClick={() => handleLogout()}>
								Log Out
							</span>
						</p>
					</>
				</Layout>
			</div>
		</>
	);
}

export default SelectBusinessModal;
