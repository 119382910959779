import {ApprovePendingPaymentResponse, OtpInitRequest} from "../../../Services/SendMoney/singleTransferApi.types";
import {useCallback, useState} from "react";

import {approvePendingPayment} from "../../../Services/PendingApproval/pendingPayments";
import {errorTrue} from "../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../utils/getErrorMessage";
import {messageTrue} from "../../../../../../redux/message/slice/messageSlice";
import {updatePendingTransaction} from "../../../../../../redux/transaction/slice/transactionSlice";
import {useDispatch} from "react-redux";
import useOtpInit from "../SendMoney/SingleTransfer/useOtpInit";

export interface UseApprovePendingPaymentInterface {
	otp: string | null;
	minutes: number;
	seconds: number;
	isInitOTPLoading: boolean;
	approveTransactionError: string | null;
	approveTransactionResponse: ApprovePendingPaymentResponse | null;
	isApproveTransactionLoading: boolean;

	handleOTPInit: (data: OtpInitRequest) => void;
	handleOtpChange: (_otp: string) => void;
	handleResendOTPInit: (data: OtpInitRequest) => void;
	handleApproveTransaction: (_data: {transactionId: string; otp: string}) => void;
}

interface Props {
	active?: boolean;
	onComplete?: () => void;
}

const useApprovePendingPayment = ({active, onComplete}: Props): UseApprovePendingPaymentInterface => {
	const dispatch = useDispatch();

	const {minutes, seconds, isInitOTPLoading, handleOTPInit, handleResendOTPInit} = useOtpInit({active});

	const [otp, setOtp] = useState<string | null>(null);

	const [approveTransactionError, setApproveTransactionError] = useState<string | null>("");
	const [approveTransactionResponse, setApproveTransactionResponse] = useState<ApprovePendingPaymentResponse | null>(null);
	const [isApproveTransactionLoading, setIsApproveTransactionLoading] = useState<boolean>(false);

	const handleApproveTransaction = useCallback(
		async (_data: {transactionId: string; otp: string}) => {
			try {
				setApproveTransactionError(null);
				setIsApproveTransactionLoading(true);
				const res = await approvePendingPayment(_data);
				setApproveTransactionResponse(res);
				dispatch(messageTrue({message: "Transfer approved successfully"}));
				dispatch(
					updatePendingTransaction([
						{
							id: _data.transactionId,
							approve: true,
						},
					])
				);
				onComplete && onComplete();
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				setApproveTransactionError(errorMessage);
				dispatch(errorTrue({message: errorMessage}));
			} finally {
				setIsApproveTransactionLoading(false);
			}
		},
		[dispatch]
	);

	const handleOtpChange = (e: string) => {
		setOtp(e);
	};

	return {
		otp,
		seconds,
		minutes,
		isInitOTPLoading,
		approveTransactionError,
		approveTransactionResponse,
		isApproveTransactionLoading,

		handleOTPInit,
		handleOtpChange,
		handleResendOTPInit,
		handleApproveTransaction,
	};
};

export default useApprovePendingPayment;
