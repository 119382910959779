import CustomerAccount from "../../../models/customerAccount";
import {GenericObject} from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import Transaction from "../../../models/transaction";
import {TransactionStatus} from "../../../models/transaction.constants";
import UserAccount from "../../../models/userAccount";
import produce from "immer";

export interface handleUpdateUserGroupDataInterface {
	prevData: Transaction;
	nextData: CustomerAccount;
}
export interface handleActivateTransactionAccountInterface {
	prevData: Transaction;
	nextData: UserAccount;
}
export interface HandleApproveTransactionInterface {
	prevData: Map<string, Transaction>;
	nextData: {id: string; approve: boolean; isCancelled?: boolean};
}

export const handleUpdateUserGroupData = ({prevData, nextData}: handleUpdateUserGroupDataInterface): Transaction => {
	const obj: CustomerAccount | GenericObject = {};

	obj["destination"] = {
		...prevData.destination,
		customerAccount: {
			id: nextData.id,
			bankAccount: nextData.bankAccount,
			alias: nextData.alias,
			userGroupId: nextData.userGroupId,
			userGroup: nextData.userGroup,
		},
	};

	return Parsers.classObjectNonNullable({...prevData, ...obj}, Transaction);
};

export const handleTransactionUserGroupRemoval = ({prevData, nextData}: handleUpdateUserGroupDataInterface): Transaction => {
	const obj: CustomerAccount | GenericObject = {};

	obj["destination"] = {
		...prevData.destination,
		customerAccount: {
			id: nextData.id,
			bankAccount: nextData.bankAccount,
			alias: null,
			userGroupId: null,
			userGroup: null,
		},
	};

	return Parsers.classObjectNonNullable({...prevData, ...obj}, Transaction);
};

export const handleActivateTransactionAccount = ({prevData, nextData}: handleActivateTransactionAccountInterface): Transaction => {
	if (!prevData.origination) return prevData;
	if (prevData.origination.userAccount?.id !== nextData.id) return prevData;
	const updatedTransaction = produce(prevData, (draft) => {
		draft["isActivationPending"] = false;
	});

	return updatedTransaction;
};

export const handleUpdatePendingTransaction = ({prevData, nextData}: HandleApproveTransactionInterface): Transaction => {
	const transaction = prevData.get(nextData.id);

	const updatedTransaction = produce(transaction as Transaction, (draft) => {
		draft["status"] = nextData.approve
			? TransactionStatus.SUCCESS
			: nextData.isCancelled
			? TransactionStatus.CANCELLED
			: TransactionStatus.DECLINED;
		draft["isCancelled"] = !!nextData.isCancelled;
	});

	return updatedTransaction;
};
