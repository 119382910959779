import React, {useEffect, useRef, useState} from "react";

import OtpInput from "react-otp-input";

export interface otpProps {
	otpFunc: (e: string) => void | undefined;
	errorMessage: unknown;
	value: string | null | undefined;
	active?: boolean;
}

function OtpInputForm({value, errorMessage, active = undefined, otpFunc}: otpProps): JSX.Element {
	const otpRef = useRef<OtpInput | null>(null);

	const [optFocusValue, setOtpFocusValue] = useState<number>(0);

	useEffect(() => {
		setOtpFocusValue(() => {
			if (!value) {
				return 0;
			}
			if (value.length <= 6) {
				return value.length;
			}
			return 6;
		});
	}, [value]);

	useEffect(() => {
		if (!active) return;
		otpRef.current?.focusInput(optFocusValue);
	}, [active, otpRef.current]);

	return (
		<>
			<div className="w-full" onClick={() => otpRef.current?.focusInput(optFocusValue)}>
				<OtpInput
					value={value || undefined}
					onChange={otpFunc}
					placeholder="000000"
					inputStyle="w-10 h-10 text-3xl otp bg-grey-tertiary"
					numInputs={6}
					containerStyle={` justify-between font-normal w-full border text-lg bg-grey-tertiary p-4 rounded-lg cursor-text ${
						errorMessage ? "border-error" : "border-black-quin"
					}`}
					focusStyle="outline-none"
					isInputNum={true}
					shouldAutoFocus={true}
					ref={otpRef}
				/>
			</div>
		</>
	);
}

export default OtpInputForm;
