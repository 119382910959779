import React from "react";
import Transaction from "../../../../../../../models/transaction";

interface Props {
	dataType: string;
	transaction: Transaction;
}

function TransactionDetailCardReference({dataType, transaction}: Props): JSX.Element {
	return (
		<>
			<div className="flex w-full flex-row items-start justify-between" data-type={dataType}>
				<p className="text-sm text-black-secondary" data-type={dataType}>
					Transaction Reference
				</p>
				<p className="max-w-55% break-words text-right text-sm font-medium text-black-secondary" data-type={dataType}>
					{transaction.transactionReference}
				</p>
			</div>
		</>
	);
}

export default TransactionDetailCardReference;
