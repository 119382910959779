export enum HintType {
	TIP = "tips",
	GUIDE = "guide",
	INFO = "info",
}

export enum ListStyle {
	PLAIN = "plain",
	CHECK = "check",
	BULLET = "bullet",
}

export type hintMarkItem = {
	text: string;
	isChecked: boolean;
};

export type dependencyItem = {
	primary: string;
	secondary: string;
};
