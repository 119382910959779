import Layout from "../../components/Layout";
import React from "react";
import {TailSpin} from "react-loader-spinner";

function LandingSuspense(): JSX.Element {
	return (
		<Layout showNothing>
			<div
				className="absolute top-50% left-50% h-7 w-7 flex justify-center items-center"
				style={{
					transform: "translate(-50%, -50%)",
				}}
			>
				<TailSpin color="#3A4EF8" height={25} width={25} />
			</div>
		</Layout>
	);
}
export default LandingSuspense;
