import React, {useState} from "react";

import {ReactComponent as ArrowDownIcon} from "../../../../../assets/svg/General/arrowDownIcon.svg";
import {canShowPreReleaseFeatures} from "../../../../../utils/preReleaseConfig";

interface SectionCardInterface {
	text: string;
	icon: React.ReactNode;
	isPreRelease?: boolean;

	func: () => void;
}

function SectionCard({text, icon, isPreRelease = false, func}: SectionCardInterface): JSX.Element {
	const [isHover, setIsHover] = useState<boolean>(false);
	if (isPreRelease && !canShowPreReleaseFeatures) {
		return <></>;
	}

	return (
		<div
			className={
				`flex h-12 w-full max-w-sm cursor-pointer flex-row items-center justify-between rounded-lg p-4 text-black-secondary ` +
				`${isHover ? "shadow-custom-v2 " : "shadow "} `
			}
			onClick={func}
			onMouseEnter={() => setIsHover(true)}
			onMouseLeave={() => setIsHover(false)}
		>
			<div className="flex items-center justify-start space-x-4">
				<div>
					<div className="flex h-8 w-8 items-center justify-center rounded-full bg-blue-backdrop text-blue"> {icon}</div>
				</div>

				<span className="w-full text-left text-sm">{text}</span>
			</div>

			<div className="-rotate-90 transform">
				{" "}
				<ArrowDownIcon className="h-3.5 w-3.5 stroke-current" />
			</div>
		</div>
	);
}

export default SectionCard;
