import {DirectorFormOptions} from "../../../service/directorForm.types";
import OtherDirector from "../../../../../../../models/account-opening/otherDirector";
import React from "react";

interface Props {
	data: OtherDirector | undefined;
	options: DirectorFormOptions;
}

function DirectorPersonalDetailsReview(props: Props): JSX.Element {
	return (
		<>
			{props.data && (
				<div className="flex flex-col justify-start items-start w-full space-y-4 p-5 border border-black-quin rounded-lg">
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Title</span>
						<span className="text-black-secondary w-56 text-left">
							{props.options.nameTitle.find((_gender) => _gender.id === props.data?.title)?.name || "-"}
						</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">First Name</span>
						<span className="text-black-secondary w-56 text-left">{props.data.firstName || "-"}</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Other Name</span>
						<span className="text-black-secondary w-56 text-left">{props.data.otherName || "-"}</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Last Name</span>
						<span className="text-black-secondary w-56 text-left">{props.data.surname || "-"}</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Date of Birth</span>
						<span className="text-black-secondary w-56 text-left">{props.data.dateOfBirth || "-"}</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Mother{"'"}s Maiden Name</span>
						<span className="text-black-secondary w-56 text-left">{props.data.motherMaidenName || "-"}</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Nationality</span>
						<span className="text-black-secondary w-56 text-left">
							{props.data.isNigerian ? "Nigerian" : ""}
							{!props.data.isNigerian && (props.data.nonNigerianNationality || "-")}
						</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">BVN</span>
						<span className="text-black-secondary w-56 text-left">{props.data.bvn || "-"}</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Role in Business</span>
						<span className="text-black-secondary w-56 text-left">
							{props.data.role &&
								!props.data.otherRoleText &&
								(props.options.role.find((_role) => _role.id === props.data?.role)?.name || "")}
							{!props.data.role && (props.data.otherRoleText || "-")}
						</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Gender</span>
						<span className="text-black-secondary w-56 text-left">
							{props.options.gender.find((_gender) => _gender.id === props.data?.gender)?.name || "-"}
						</span>
					</div>
					<div className="flex flex-row justify-between items-start w-full space-x-4">
						<span className="text-black-tertiary w-52">Address</span>
						<span className="text-black-secondary w-56 text-left">{props.data.address || "-"}</span>
					</div>
				</div>
			)}
		</>
	);
}

export default DirectorPersonalDetailsReview;
