import {TransferBetweenAccountInitRequest, TransferBetweenAccountInitResponse} from "./transferBetweenAccountApi.types";

import Parsers from "../../../../../utils/parsers";
import Transaction from "../../../../../models/transaction";
import {makeRequest} from "../../../../../helpers/request/makeRequest";

export const transferBetweenAccountInit = async (data: TransferBetweenAccountInitRequest): Promise<TransferBetweenAccountInitResponse> => {
	const res = await makeRequest("/main/payments/initiate/internal", data);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
		isApproved: Parsers.boolean(res.isApproved),
		processing: Parsers.boolean(res.processing),
		accountsToActivate: Parsers.stringArray(res.accountsToActivate),
	};
};
