import Card from "./card";
import CardRequest from "./cardRequest";
import CardSpend from "./cardSpend";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class CardMain {
	[immerable] = true;

	constructor(public createRequest: CardRequest, public card: Card | null, public balance: number | null, public cardSpend: CardSpend | null) {}

	static create(obj: GenericObject): CardMain {
		return new CardMain(
			Parsers.classObjectNonNullable(obj.createRequest, CardRequest),
			Parsers.classObject(obj.card, Card),
			Parsers.number(obj.balance),
			Parsers.classObject(obj.cardSpend, CardSpend)
		);
	}
}
