import {ReactComponent as InflowArrowIcon} from "../../../assets/images/greenArrow.svg";
import Money from "../../General/Money";
import {ReactComponent as OutflowArrowIcon} from "../../../assets/svg/DashboardLayout/SearchBar/outflowArrowIcon.svg";
import React from "react";
import Transaction from "../../../models/transaction";
import moment from "moment";

interface TransactionsSearchProps {
	index: number;
	transaction: Transaction;
	handleShowTransactionDetail(transaction: Transaction): void;
}

function TransactionsSearch({index, transaction, handleShowTransactionDetail}: TransactionsSearchProps): JSX.Element {
	return (
		<div key={index} onClick={() => handleShowTransactionDetail(transaction)} data-type="transaction" className="">
			<div
				className="flex cursor-pointer flex-row items-center justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop"
				data-type="transaction"
			>
				<div className="w-25% pr-1" data-type="transaction">
					<p
						className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary"
						data-type="transaction"
					>
						{moment(transaction && transaction.createdOn).format("Do MMM YYYY")}{" "}
					</p>
				</div>

				<div className="flex w-40% items-center justify-start xl:w-65%">
					<p className="max-w-3xl overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-black-secondary" data-type="transaction">
						{transaction.destination?.account?.bankAccount?.accountName}
					</p>
				</div>

				<div className="flex w-35% justify-end space-x-2 xl:w-20%" data-type="transaction">
					<div>
						<div className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize" data-type="transaction">
							<div data-type="transaction">
								<Money
									amount={transaction.amount}
									currency={transaction.currency}
									className={
										transaction.isDeclined || transaction.isProcessing
											? "text-warning"
											: `${transaction.isCredit() && transaction.isSuccess ? "text-success" : "text-black-secondary"} ` +
											  `${transaction.isFailed ? "line-through" : ""} `
									}
									data-type="transaction"
								/>
							</div>
						</div>
					</div>

					<div className="flex items-center space-x-2" data-type="transaction">
						{transaction && transaction.isDebit() && <OutflowArrowIcon />}
						{transaction && transaction.isCredit() && <InflowArrowIcon />}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TransactionsSearch;
