import Parsers from "../utils/parsers";
import {GenericObject} from "../helpers/types";
import {immerable} from "immer";

export default class Currency {
	[immerable] = true;

	constructor(public id: number, public htmlCode: string, public isoCode: string) {}

	static create(obj: GenericObject): Currency {
		return new Currency(Parsers.number(obj.id), Parsers.string(obj.htmlCode), Parsers.string(obj.isoCode));
	}

	get htmlString(): string {
		return this.htmlCode || (this.isoCode ? `${this.isoCode} ` : "") || "";
	}
}
