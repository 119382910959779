import {DirectorPage} from "./directors.types";
import {Routes} from "../../../../../routes/routes.constants";

export enum DirectorSectionStatusType {
	TODO = 1,
	UNDER_REVIEW = 2,
	REVIEWED = 3,
}

export enum PersonalDetailsPageType {
	BVN = "bvn",
	PERSONAL_DETAILS = "details",
}
export enum NextOfKinPageType {
	KIN = "next-of-kin",
}
export enum GenericDirectorPageType {
	REVIEW = "review",
}
export enum MeansOfIDPageType {
	ID = "means-of-id",
}
export enum DirectorPageType {
	PERSONAL = "personal-details",
	NEXT_OF_KIN = "next-of-kin",
	MEANS_OF_ID = "means-of-id",
	REVIEW = "review",
	DONE = "done",
}

export const DirectorNavigation: {
	[type in PersonalDetailsPageType | NextOfKinPageType | MeansOfIDPageType | GenericDirectorPageType]: string;
} = {
	[PersonalDetailsPageType.BVN]: "bvn",
	[PersonalDetailsPageType.PERSONAL_DETAILS]: "personal details",
	[NextOfKinPageType.KIN]: "next of kin",
	[MeansOfIDPageType.ID]: "means of ID",
	[GenericDirectorPageType.REVIEW]: "review",
};

export const DirectorRoutes: {
	[type in DirectorPageType]: string;
} = {
	[DirectorPageType.PERSONAL]: Routes.EXTERNAL_DIRECTOR.PERSONAL_DETAILS.DETAILS,
	[DirectorPageType.NEXT_OF_KIN]: Routes.EXTERNAL_DIRECTOR.NEXT_OF_KIN,
	[DirectorPageType.MEANS_OF_ID]: Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID,
	[DirectorPageType.REVIEW]: Routes.EXTERNAL_DIRECTOR.REVIEW,
	[DirectorPageType.DONE]: Routes.EXTERNAL_DIRECTOR.DONE,
};

export type ExternalDirectorPageType = DirectorPageType;
export type ExternalDirectorSubPageType = PersonalDetailsPageType | NextOfKinPageType | MeansOfIDPageType;

export const DirectorPages: DirectorPage[] = [
	{
		value: DirectorPageType.PERSONAL,
		title: "Personal Details",
		description: "Tell us about yourself",
		index: 1,
		// route: Routes.ACCOUNT_OPENING.UNREGISTERED.PERSONAL,
		progress: 0,
	},
	{
		value: DirectorPageType.NEXT_OF_KIN,
		title: "Next of Kin form",
		description: "Please provide your personal information",
		index: 2,
		// route: Routes.ACCOUNT_OPENING.UNREGISTERED.BUSINESS.DETAILS,
		progress: 0,
	},
	{
		value: DirectorPageType.MEANS_OF_ID,
		title: "Means of ID",
		description: "Please provide your personal information",
		index: 3,
		// route: Routes.ACCOUNT_OPENING.UNREGISTERED.IDENTITY,
		progress: 0,
	},
];
