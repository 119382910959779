import ButtonComp from "../../../../../../../components/General/Buttons/ButtonComp";
import FullPageTrayFooter from "../../../../../../../components/General/Trays/FullPageTray/FullPageTrayFooter";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import UserAccount from "../../../../../../../models/userAccount";

interface Props {
	dataType: string;
	transaction: Transaction;
	handleShowActiveAccountModal: () => void;
	handleSetAccountToActivate: (account: UserAccount | null) => void;
}

function TransactionDetailCardPendingActivation({
	dataType,
	transaction,
	handleShowActiveAccountModal,
	handleSetAccountToActivate,
}: Props): JSX.Element {
	return (
		<>
			{transaction && transaction.isActivationPending && (
				<>
					<FullPageTrayFooter dataType={dataType}>
						<div className="flex w-full items-center justify-center text-black-tertiary" data-type={dataType}>
							<ButtonComp
								type="button"
								ripple="light"
								buttonType="primary"
								color="blue"
								func={(e) => {
									e.preventDefault();
									handleShowActiveAccountModal();
									handleSetAccountToActivate(transaction.origination?.userAccount || null);
								}}
								data-type={dataType}
							>
								<span data-type={dataType}>Activate Account</span>
							</ButtonComp>
						</div>
					</FullPageTrayFooter>
				</>
			)}
		</>
	);
}

export default TransactionDetailCardPendingActivation;
