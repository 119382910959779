import {CardType, CardWalletType, CreateCardRequestBankingAppStatus} from "./card.constants";

import CardAssignedTo from "./cardAssignedTo";
import CardRequestedBy from "./cardRequestedBy";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import UserAccount from "./userAccount";
import {immerable} from "immer";

export default class CardRequest {
	[immerable] = true;

	constructor(
		public id: string,
		public createdAt: string,
		public debitAccount: UserAccount | null,
		public requestedNameOnCard: string,
		public deliveryPhone: string,
		public deliveryAddress: string,
		public type: CardType,
		public walletType: CardWalletType,
		public linkedAccount: UserAccount | null,
		public purpose: string | null,
		public status: CreateCardRequestBankingAppStatus,
		public shouldChargeIssuanceFee: boolean,
		public requestedBy: CardRequestedBy | null,
		public assignedTo: CardAssignedTo | null,
		public cardholderEmail: string,
		public cardholderPhone: string,
		public cardId: string | null,
		public approvedNameOnCard: string | null,
		public declineReason: string | null,
		public requestedAt: string,
		public declinedAt: string | null,
		public approvedAt: string | null,
		public printingRequestedAt: string | null,
		public cardPrintedAt: string | null,
		public shippedAt: string | null,
		public deliveredAt: string | null
	) {}

	static create(obj: GenericObject): CardRequest {
		return new CardRequest(
			Parsers.string(obj.id),
			Parsers.string(obj.createdAt),
			Parsers.classObject(obj.debitAccount, UserAccount),
			Parsers.string(obj.requestedNameOnCard),
			Parsers.string(obj.deliveryPhone),
			Parsers.string(obj.deliveryAddress),
			Parsers.number(obj.type),
			Parsers.number(obj.walletType),
			Parsers.classObject(obj.linkedAccount, UserAccount),
			Parsers.nullableString(obj.purpose),
			Parsers.number(obj.status),
			Parsers.boolean(obj.shouldChargeIssuanceFee),
			Parsers.classObject(obj.requestedBy, CardRequestedBy),
			Parsers.classObject(obj.assignedTo, CardAssignedTo),
			Parsers.string(obj.cardholderEmail),
			Parsers.string(obj.cardholderPhone),
			Parsers.nullableString(obj.cardId),
			Parsers.nullableString(obj.approvedNameOnCard),
			Parsers.nullableString(obj.declineReason),
			Parsers.string(obj.requestedAt),
			Parsers.nullableString(obj.declinedAt),
			Parsers.nullableString(obj.approvedAt),
			Parsers.nullableString(obj.printingRequestedAt),
			Parsers.nullableString(obj.cardPrintedAt),
			Parsers.nullableString(obj.shippedAt),
			Parsers.nullableString(obj.deliveredAt)
		);
	}

	get isCardDelivered(): boolean {
		return this.status === CreateCardRequestBankingAppStatus.DELIVERED ? true : false;
	}
}
