import React, {useEffect, useState} from "react";

import ButtonComp from "../../../../../components/General/Buttons/ButtonComp";
import OtpInputForm from "../../../../../components/OtpInputForm";

interface otpProps {
	error: string | null;
	value: string;
	isLoading: boolean;
	isAuthAppSetup: boolean;

	otpFunc: (e: string) => void;
	backFunc: (e: React.MouseEvent<Element, MouseEvent>) => void | undefined;
	resendFunc: () => void;
}

function Otp({error, value, isLoading, isAuthAppSetup, backFunc, otpFunc, resendFunc}: otpProps): JSX.Element {
	const [minutes, setMinutes] = useState<number>(2);
	const [seconds, setSeconds] = useState<number>(59);

	useEffect(() => {
		const optTimerInterval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}
			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(optTimerInterval);
				} else {
					setMinutes(minutes - 1);
					setSeconds(59);
				}
			}
		}, 1000);
		return () => {
			clearInterval(optTimerInterval);
		};
	});
	return (
		<>
			<div className="-mb-5 flex w-full flex-row text-center">
				<div className="w-full max-w-sm ">
					<div className=" flex w-full  flex-col space-y-2 text-black ">
						<span className="  text text-center text-2xl font-normal">Enter OTP</span>
						{isAuthAppSetup ? (
							<span className=" text text-center text-base font-normal text-black-secondary">
								Enter the OTP generated from your token app
							</span>
						) : (
							<span className=" text text-center text-base font-normal text-black-secondary">
								We sent a one-time password to your email to authenticate your login.
							</span>
						)}
					</div>

					<div className="sm:w-100%  mx-auto my-5%  w-full md:w-full lg:w-full">
						<OtpInputForm value={value || undefined} otpFunc={otpFunc} errorMessage={error} />

						<div className="flex w-full flex-row items-center  justify-center space-x-4 px-2 pt-12">
							<ButtonComp
								type="button"
								ripple="light"
								buttonType="secondary"
								color="grey"
								func={backFunc}
								size={undefined}
								disable={undefined}
								href={undefined}
								isLoading={undefined}
							>
								<span>Back</span>
							</ButtonComp>

							<ButtonComp
								type="submit"
								color="blue"
								ripple="light"
								buttonType="primary"
								isLoading={isLoading}
								disable={!isLoading}
								size={undefined}
								href={undefined}
							>
								<span className="w-full text-center">Authorize</span>
							</ButtonComp>
						</div>
						<div className="flex items-center justify-center pt-10">
							<div>
								{minutes === 0 && seconds === 0 ? (
									<div
										onClick={() => {
											setMinutes(2);
											setSeconds(59);
										}}
									>
										<span className="cursor-pointer  text-sm text-blue" onClick={resendFunc}>
											Resend OTP
										</span>
									</div>
								) : (
									<span className="cursor-not-allowed  text-sm text-black-tertiary">
										Resend OTP in{" "}
										<span className="text-black-secondary">
											{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
										</span>
									</span>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Otp;
