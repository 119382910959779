import {CardCurrency, CardScheme, CardSpendLimitType, CardStatus, CardTransactionChannel, CardType, CardWalletType} from "./card.constants";

import CardAssignedTo from "./cardAssignedTo";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import UserAccount from "./userAccount";
import {immerable} from "immer";

export default class Card {
	[immerable] = true;

	constructor(
		public id: string,

		public name: string,

		public type: CardType,

		public scheme: CardScheme,

		public status: CardStatus,

		public maskedPan: string,

		public currency: CardCurrency,

		public accountType: string,

		public walletType: CardWalletType,

		public linkedAccount: UserAccount | null,

		public createRequestId: string,

		public assignedToCorporateAccountMember: CardAssignedTo | null,

		public description: string | null,

		public allowedChannels: CardTransactionChannel[],

		public systemSpendLimits: CardSpendLimitType[],

		public userSpendLimit: CardSpendLimitType[],

		public hasDoneExternalTransaction: boolean
	) {}

	static create(obj: GenericObject): Card {
		return new Card(
			Parsers.string(obj.id),
			Parsers.string(obj.name),
			Parsers.number(obj.type),
			Parsers.number(obj.scheme),
			Parsers.number(obj.status),
			Parsers.string(obj.maskedPan),
			Parsers.number(obj.currency),
			Parsers.string(obj.accountType),
			Parsers.number(obj.walletType),
			Parsers.classObject(obj.linkedAccount, UserAccount),
			Parsers.string(obj.createRequestId),
			Parsers.classObject(obj.assignedToCorporateAccountMember, CardAssignedTo),
			Parsers.nullableString(obj.description),
			Parsers.numberArray(obj.allowedChannels),
			Parsers.numberArray(obj.systemSpendLimits),
			Parsers.numberArray(obj.userSpendLimit),
			Parsers.boolean(obj.hasDoneExternalTransaction)
		);
	}
	get cardTypeName(): string {
		return this.type === CardType.NGN_PHYSICAL ? "Physical" : "Virtual";
	}
	get walletTypeName(): string {
		return this.walletType === CardWalletType.INDEPENDENT_WALLET
			? "Prepaid"
			: this.walletType === CardWalletType.LINKED_TO_ACCOUNT
			? "Direct Debit"
			: "";
	}
	get cardStatusName(): string {
		if (this.status === CardStatus.ACTIVE) {
			return "Card Activated";
		}
		if (this.status === CardStatus.DEACTIVATED) {
			return "Card Deactivated";
		}
		if (this.status === CardStatus.EXPIRED) {
			return "Card Expired";
		}
		if (this.status === CardStatus.UNACTIVATED) {
			return "Pending Activation";
		}
		// if (this.status === CardStatus.DEACTIVATED) {
		// return "Deactivation Requested";
		// }
		return "";
	}

	get isActive(): boolean {
		return this.status === CardStatus.ACTIVE ? true : false;
	}
	get isFrozen(): boolean {
		return this.status === CardStatus.FROZEN ? true : false;
	}
	get isPendingActivation(): boolean {
		return this.status === CardStatus.UNACTIVATED ? true : false;
	}
	get isDeactivated(): boolean {
		return this.status === CardStatus.DEACTIVATED ? true : false;
	}
	get isExpired(): boolean {
		return this.status === CardStatus.EXPIRED ? true : false;
	}
	get isRequestedToDeactivate(): boolean {
		return this.status === CardStatus.DEACTIVATED ? true : false;
	}
}
