import {ChargeType, TransactionStatus, TransactionType} from "./transaction.constants";
import {Num, add} from "../utils/math";

import CardPayment from "./cardPayment";
import Currency from "./currency";
import CustomerAccount from "./customerAccount";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import TransactionCategory from "./transactionCategory";
import TransactionComment from "./transactionComment";
import TransactionInfo from "./transactionInfo";
import UserAccount from "./userAccount";
import UserMin from "./userMin";
import {immerable} from "immer";

export default class Transaction {
	[immerable] = true;

	constructor(
		public id: string,
		public origination: TransactionInfo | null,
		public destination: TransactionInfo | null,
		public description: string,
		public currency: Currency | null,
		public amount: number,
		public type: TransactionType,
		public status: TransactionStatus,
		public cardPayment: CardPayment | null,
		public datetime: Date | null,
		public transactionReference: string,
		public postTransactionBalance: number | null,
		public createdOn: Date | null,
		public initiatedBy: UserMin | null,
		public initiatedOn: Date | null,
		public approvedBy: UserMin | null,
		public approvedOn: Date | null,
		public declinedBy: UserMin | null,
		public declinedOn: Date | null,
		public declineReason: string | null,
		public cancelledBy: UserMin | null,
		public cancelledOn: Date | null,
		public cancelReason: string | null,
		public failedReason: string | null,
		public comments: TransactionComment[],
		public categoryId: string | null,
		public category: TransactionCategory | null,
		public charges: Transaction[],
		public isRetry: boolean,
		public failedTransactionId: string | null,
		public isCharge: boolean,
		public isPendingFraudReview: boolean,
		public chargeType: ChargeType | null,
		public transactionBeingChargedId: string | null,
		public receiptUploaded: boolean,
		public invoiceUploaded: boolean,
		public isActivationPending: boolean,
		public isSplitPayment: boolean,
		public isApi: boolean,
		public isCardPayment: boolean,
		public isFundCardRequest: boolean,
		public isReversal: boolean,
		public isInternal: boolean,
		public clientReference: string | null,
		public nipSessionId: string | null
	) {}

	static create(obj: GenericObject): Transaction {
		return new Transaction(
			Parsers.string(obj.id),
			Parsers.classObject(obj.origination, TransactionInfo),
			Parsers.classObject(obj.destination, TransactionInfo),

			Parsers.string(obj.description),
			Parsers.classObject(obj.currency, Currency),
			Parsers.number(obj.amount) || 0,
			Parsers.number(obj.type),
			Parsers.number(obj.status),

			Parsers.classObject(obj.cardPayment, CardPayment),

			Parsers.date(obj.datetime),
			Parsers.string(obj.transactionReference),
			Parsers.nullableNumber(obj.postTransactionBalance),

			Parsers.date(obj.createdOn),
			Parsers.classObject(obj.initiatedBy, UserMin),
			Parsers.date(obj.initiatedOn),

			Parsers.classObject(obj.approvedBy, UserMin),
			Parsers.date(obj.approvedOn),

			Parsers.classObject(obj.declinedBy, UserMin),
			Parsers.date(obj.declinedOn),
			Parsers.nullableString(obj.declineReason),

			Parsers.classObject(obj.cancelledBy, UserMin),
			Parsers.date(obj.cancelledOn),
			Parsers.nullableString(obj.cancelReason),

			Parsers.nullableString(obj.failedReason),
			Parsers.classObjectArray(obj.comments, TransactionComment),

			Parsers.nullableString(obj.categoryId),
			Parsers.classObject(obj.category, TransactionCategory),
			Parsers.classObjectArray(obj.charges, Transaction),

			Parsers.boolean(obj.isRetry),
			Parsers.nullableString(obj.failedTransactionId),

			Parsers.boolean(obj.isCharge),
			Parsers.boolean(obj.isPendingFraudReview),
			Parsers.nullableNumber(obj.chargeType),
			Parsers.nullableString(obj.transactionBeingChargedId),

			Parsers.boolean(obj.receiptUploaded),
			Parsers.boolean(obj.invoiceUploaded),
			Parsers.boolean(obj.isActivationPending),
			Parsers.boolean(obj.isSplitPayment),

			Parsers.boolean(obj.isApi),
			Parsers.boolean(obj.isCardPayment),
			Parsers.boolean(obj.isFundCardRequest),
			Parsers.boolean(obj.isReversal),
			Parsers.boolean(obj.isInternal),
			Parsers.nullableString(obj.clientReference),
			Parsers.nullableString(obj.nipSessionId)
		);
	}

	update(transaction: Transaction): void {
		// todo -> might ignore some if the data is not complete (i.e the object is not full)
		this.origination = transaction.origination;
		this.destination = transaction.destination;
		this.description = transaction.description;
		this.currency = transaction.currency;
		this.amount = transaction.amount;
		this.type = transaction.type;
		this.status = transaction.status;
		this.cardPayment = transaction.cardPayment;
		this.datetime = transaction.datetime;
		this.transactionReference = transaction.transactionReference;
		this.postTransactionBalance = transaction.postTransactionBalance;
		this.createdOn = transaction.createdOn;
		this.initiatedBy = transaction.initiatedBy;
		this.initiatedOn = transaction.initiatedOn;
		this.approvedBy = transaction.approvedBy;
		this.approvedOn = transaction.approvedOn;
		this.declinedBy = transaction.declinedBy;
		this.declinedOn = transaction.declinedOn;
		this.declineReason = transaction.declineReason;
		this.cancelledBy = transaction.cancelledBy;
		this.cancelledOn = transaction.cancelledOn;
		this.cancelReason = transaction.cancelReason;
		this.failedReason = transaction.failedReason;
		this.comments = transaction.comments;
		this.categoryId = transaction.categoryId;
		this.category = transaction.category;
		this.charges = transaction.charges;
		this.isRetry = transaction.isRetry;
		this.failedTransactionId = transaction.failedTransactionId;
		this.isCharge = transaction.isCharge;
		this.isPendingFraudReview = transaction.isPendingFraudReview;
		this.chargeType = transaction.chargeType;
		this.transactionBeingChargedId = transaction.transactionBeingChargedId;
		this.receiptUploaded = transaction.receiptUploaded;
		this.invoiceUploaded = transaction.invoiceUploaded;
		this.isActivationPending = transaction.isActivationPending;
		this.isSplitPayment = transaction.isSplitPayment;
		this.isApi = transaction.isApi;
		this.isCardPayment = transaction.isCardPayment;
		this.isFundCardRequest = transaction.isFundCardRequest;
		this.isReversal = transaction.isReversal;
		this.isInternal = transaction.isInternal;
		this.clientReference = transaction.clientReference;
		this.nipSessionId = transaction.nipSessionId;
	}

	isDebit(): boolean {
		return this.type === TransactionType.DEBIT;
	}

	isCredit(): boolean {
		return this.type === TransactionType.CREDIT;
	}

	get isFraudReview(): boolean {
		return this.status === TransactionStatus.PENDING_FRAUD_REVIEW || this.isPendingFraudReview;
	}

	get isSuccess(): boolean {
		return this.status === TransactionStatus.SUCCESS;
	}

	get isFailed(): boolean {
		return this.status === TransactionStatus.FAILED;
	}

	get isPendingApproval(): boolean {
		return this.status === TransactionStatus.PENDING_APPROVAL;
	}

	get isDeclined(): boolean {
		return this.status === TransactionStatus.DECLINED;
	}

	get isCancelled(): boolean {
		return this.status === TransactionStatus.CANCELLED;
	}

	get isProcessing(): boolean {
		return this.status === TransactionStatus.PROCESSING;
	}

	get singleDatetime(): Date | null {
		return this.datetime || this.initiatedOn || this.createdOn;
	}

	get totalCharges(): number {
		return this.charges.reduce((accumulator: Num, charge: Transaction) => accumulator.add(charge.amount), new Num(0)).valueOf();
	}

	get total(): number {
		return add(this.amount, this.totalCharges);
	}

	get originatingUserAccount(): UserAccount | null {
		if (this.isDebit()) {
			return this.origination ? this.origination.userAccount : null;
		}
		if (this.isCredit()) {
			return this.destination ? this.destination.userAccount : null;
		}
		return null;
	}

	get userAccount(): UserAccount | null {
		if (this.isDebit()) {
			return this.origination ? this.origination.userAccount : null;
		}
		if (this.isCredit()) {
			return this.destination ? this.destination.userAccount : null;
		}
		return null;
	}

	// i.e. not the user account. So if it's debit, get the recipient user/customer account. if it's credit get the sending user/customer account
	get otherTransactionInfo(): TransactionInfo | null {
		return this.isDebit() ? this.destination : this.origination;
	}

	// i.e. not the user account. So if it's debit, get the recipient user/customer account. if it's credit get the sending user/customer account
	get otherAccount(): UserAccount | CustomerAccount | null {
		if (this.otherTransactionInfo) {
			return this.otherTransactionInfo.account;
		}
		return null;
	}
}
