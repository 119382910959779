import BottomLogo from "../../assets/images/Logo/bottom-logo-.png";
import MiddleLogo from "../../assets/images/Logo/Middle-Icon.png";
import React from "react";
import TopLogo from "../../assets/images/Logo/Top-Icon.png";

interface FullScreenLoaderProps {
	isSwitchingBusiness?: boolean;
	isAccessingAccount?: boolean;
}

function FullScreenLoader({isSwitchingBusiness, isAccessingAccount}: FullScreenLoaderProps): JSX.Element {
	return (
		<div className="fixed z-90 flex h-full w-full items-center justify-center bg-white transition-all duration-300 ease-in-out">
			<div className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center">
				<div className="relative h-12 w-12  transition-all duration-500 ease-in-out">
					<img src={TopLogo} alt="TopLogo" className="absolute left-0 top-0 animate-iconOne" />
					<img src={MiddleLogo} alt="MiddleLogo" className="absolute left-0 top-2.5 animate-iconTwo" />
					<img src={BottomLogo} alt="BottomLogo" className="absolute left-0 top-6 animate-iconThree" />
				</div>

				{!isSwitchingBusiness && isAccessingAccount && (
					<span className="pt-16 text-sm text-black-secondary opacity-50"> Accessing your account...</span>
				)}
				{isSwitchingBusiness && <span className="pt-16 text-sm text-black-secondary opacity-50"> Switching Business...</span>}
			</div>
		</div>
	);
}

export default FullScreenLoader;
