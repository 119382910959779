import BillProduct from "../../../../../models/billProduct";
import BillPurchase from "../../../../../models/billPurchase";
import {BillPurchaseCategoriesType, BillPurchaseStatusType} from "../../../../../models/billPurchase.constant";
import BillVendor from "../../../../../models/billVendor";

export type BillHistoryParam = {
	categories?: BillPurchaseCategoriesType[];
	vendorIds?: string[];
	offset?: number;
	query?: string;
	userAccountIds?: string[];
	statuses?: string[];
	date?: {
		begin: string;
		end: string;
	};
};
export type VendorRequest = {
	categories: string[];
};

export interface VendorResponse {
	message: string;
	success: boolean;
	vendors: BillVendor[];
}

export type BillProductsRequest = {
	categories: string[];
	vendorIds: string[];
};
export interface BillProductsResponse {
	message: string;
	success: boolean;
	products: BillProduct[];
}

export type BillVerifyAccountRequest = {
	productId: string;
	accountId: string;
};
export interface BillVerifyAccountResponse {
	message: string;
	success: boolean;
	name: string;
}

export type BillPurchaseInitRequest = {
	debitAccountId: string;
	productId: string;
	accountId: string;
	phone: string;
	contactName: string;
	amount: string;
	bioAuth: string;
	otp: string;
};
export interface BillPurchaseInitResponse {
	message: string;
	success: boolean;
	purchase: BillPurchase;
}

export type RecentBillPurchasesRequest = {
	category: string;
	vendorId?: string;
};

export interface RecentBillPurchasesResponse {
	message: string;
	success: boolean;
	purchases: BillPurchase[];
}

export type BillPurchasesHistoryRequest = {
	query?: string;
	offset?: number;
	statuses?: BillPurchaseStatusType[];
	vendorIds?: string[];
	categories?: BillPurchaseCategoriesType[];
	userAccountIds?: string[];
	date?: {
		begin: string;
		end: string;
	};
};

export interface BillPurchasesHistoryResponse {
	message: string;
	success: boolean;
	total: number;
	groupSize: number;
	purchases: BillPurchase[];
	offset: number;
}

export enum BillHistoryFilters {
	DATE = "Date",
	CATEGORIES = "Categories",
	VENDORS = "Vendors",
	ACCOUNTS = "Accounts",
	STATUS = "Status",
}

export const BillHistoryFiltersArray = [
	BillHistoryFilters.ACCOUNTS,
	BillHistoryFilters.CATEGORIES,
	BillHistoryFilters.DATE,
	BillHistoryFilters.VENDORS,
	BillHistoryFilters.STATUS,
];

export type BillHistoryTransactionDateObj = {
	begin: Date | null | string;
	end: Date | null | string;
};

export enum BillHistoryTransactionDateTypes {
	LAST_30_DAYS = "Last 30 days",
	THIS_MONTH = "This Month",
	LAST_MONTH = "Last Month",
	THIS_YEAR = "This year",
	ALL_TIME = "All time",
	CUSTOM = "Custom",
}

export type BillHistoryTransactionDateType = {
	name: BillHistoryTransactionDateTypes;
	date: BillHistoryTransactionDateObj;
};
