import {ApplicationNote} from "../../../services/application.constants";
import {ReactComponent as ChatIcon} from "../../../../../../assets/svg/Application/chat-icon.svg";
import React from "react";
import {ToastType} from "../../../../../../components/General/ToastContainer";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import {messageTrue} from "../../../../../../redux/message/slice/messageSlice";
import {useDispatch} from "react-redux";

interface ApplicationSectionItemProps {
	icon: React.ReactNode;
	title: string;
	description: string;
	ctaIcon?: React.ReactNode;
	isDisabled?: boolean;
	notes?: ApplicationNote[] | undefined;
	progress: number;
	onClick?: () => void;
}

function ApplicationSectionItem({icon, notes, title, description, ctaIcon, progress, isDisabled, onClick}: ApplicationSectionItemProps): JSX.Element {
	const dispatch = useDispatch();
	return (
		<div
			className="w-full"
			onClick={() => {
				if (isDisabled) {
					dispatch(messageTrue({message: "Fill business details before proceeding", type: ToastType.INFORMATION}));
				}
			}}
		>
			<div
				className={
					`px-6 flex flex-col space-y-4 p-4 justify ` +
					`shadow hover:shadow-custom-v2 cursor-pointer rounded-lg ` +
					`${isDisabled ? "pointer-events-none opacity-50" : ""}`
				}
				onClick={() => {
					if (onClick && !isDisabled) {
						onClick();
					}
				}}
			>
				<div className="flex flex-row justify-between justify">
					<div className="flex flex-row justify-center items-center space-x-4">
						<div>
							<div className="h-12 w-12 flex justify-center items-center">{icon}</div>
						</div>
						<div className="flex flex-col">
							<div className="flex flex-row items-start">
								<div className="text-black-secondary">{title}</div>
							</div>
							<div className="text-sm text-black-tertiary">{description}</div>
						</div>
					</div>
					<div className="flex justify-center items-center pl-2 text-sm text-black-tertiary">
						{ctaIcon || progress}
						{!isNullOrUndefined(progress) ? "%" : ""}
					</div>
				</div>
				{notes && notes.length > 0 && (
					<div className="bg-grey-backdrop rounded-lg p-4">
						<div className="flex flex-row justify-start items-center space-x-2">
							<ChatIcon />
							<span className="text-black-secondary text-sm font-medium">For your Attention:</span>
						</div>
						<div className="flex flex-col justify-start items-start space-y-4 w-full mt-2">
							{notes.map((_note, index) => (
								<div className="w-full px-4 py-2 bg-white rounded-md" key={index}>
									<p className="text-black-tertiary text-sm">{_note.note}</p>
								</div>
							))}
						</div>
					</div>
				)}
			</div>
		</div>
	);
}

export default ApplicationSectionItem;
