import UserAccount from "../../../../../models/userAccount";
/*
export interface TransactionInitState {
	updatedTransactionUserGroup: Transaction | null;
	updatedUserAccount: UserAccount | null;
	updatedTransaction: Transaction | null;
	showTransactionDetailCard: boolean;
	transactionDetail: Transaction | null;
}
*/
export interface ZambiaSingleTransferDetailInterface {
	originatingAccountId: string;
	recipient: {
		bankAccountId: string;
		customerAccountId: string;
		accountNumber: string;
		bankCode: string;
	};
	mobileMoneyRecipient: {
		mobileNumber: string;
		firstName: string;
		lastName: string;
	};
	paymentMethod: ZambiaPaymentMethod | null;
	amount: string;
	purpose: string;
	otp: string;
}

export interface ZambiaSinglePaymentResponse {
	transactionId: string;
	isApproved: boolean;
	processing: boolean;
	accountsToActivate: Array<string>;
}

export interface ZambiaSinglePaymentResponse {
	transactionId: string;
	isApproved: boolean;
	processing: boolean;
	accountsToActivate: Array<string>;
}

export enum ZambiaSinglePaymentInternalStage {
	PAYMENT_DETAILS_STAGE = 0,
	AMOUNT_PURPOSE_STAGE = 1,
}

export enum ZambiaPaymentMethod {
	BANK_TRANSFER = 1,
	MOBILE_MONEY = 2,
	LENCO_BUSINESS = 3,
}

export interface ZambiaSingleTransferState {
	singleTransferDetails: ZambiaSingleTransferDetailInterface;
	selectedUserAccount: UserAccount | null;
	canVerifyRecipientAccountDetails: boolean;
	recipientBankAccountName: string;
	singleTransferResponse: ZambiaSinglePaymentResponse | null;
	singlePaymentInternalStage: ZambiaSinglePaymentInternalStage;
}
