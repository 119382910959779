import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ButtonComp from "../../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../../components/General/CurrencyCode";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {OtpActivityType} from "../../../../../../../helpers/AppConstants";
import OtpInputForm from "../../../../../../../components/OtpInputForm";
import {PaymentStageType} from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import {TailSpin} from "react-loader-spinner";
import formatNumber from "../../../../../../../utils/formatNumber";
import {setPaymentStage} from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {setZambiaSingleTransferOtp} from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import titleCase from "../../../../../../../hooks/titleCase";
import useOtpInit from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useOtpInit";
import useSingleTransfer from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransfer";
import useSingleTransferOtp from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransferOtp";

function MakePayment(): JSX.Element {
	const dispatch = useDispatch();

	const {handleOtpChange} = useSingleTransferOtp();
	const {minutes, seconds, isInitOTPLoading, handleOTPInit, handleResendOTPInit} = useOtpInit({});
	const {isSinglePaymentLoading, singlePaymentError, singlePaymentResponse, handleSingleTransfer} = useSingleTransfer();

	const selectedUserAccount = useSelector((state: IRootState) => state.zambiaSingleTransfer.selectedUserAccount);
	const singleTransferDetails = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails);
	const recipientBankAccountName = useSelector((state: IRootState) => state.zambiaSingleTransfer.recipientBankAccountName);
	const isAuthAppSetup = useSelector<IRootState, boolean>((state) => state.init.main?.companyDetails.user.authApp.isSetup || false);

	useLayoutEffect(() => {
		if (isAuthAppSetup) return;
		handleOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
	}, [handleOTPInit, isAuthAppSetup]);

	useEffect(() => {
		if (singleTransferDetails.otp.length < 6) return;
		void handleSingleTransfer(singleTransferDetails);
	}, [singleTransferDetails]);

	useEffect(() => {
		if (!singlePaymentResponse) return;
		dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
	}, [singlePaymentResponse]);
	const [resendOtpText, setResendOtpText] = useState(false);
	return (
		<>
			<div className=" flex w-full flex-col items-center justify-center lg:items-start lg:justify-start">
				<SinglePaymentHeaders />
				{!isAuthAppSetup && isInitOTPLoading && (
					<>
						<div className="flex flex-row items-center justify-start space-x-4 text-sm">
							<TailSpin color="#5466F9" height={20} width={20} />
							<span>OTP is being {resendOtpText ? "resent" : "sent"} to your email</span>
						</div>
					</>
				)}
				{!isInitOTPLoading && (
					<>
						<div className="flex w-full max-w-sm flex-col space-y-8">
							<div className=" text-sm  font-normal">
								{isAuthAppSetup && "Enter the OTP generated from your token App to approve the payment of "}
								{!isAuthAppSetup && "Enter the OTP sent to your email to approve the payment of "}
								<CurrencyCode currency={selectedUserAccount ? selectedUserAccount.bankAccount?.currency : undefined} />
								{formatNumber(singleTransferDetails.amount)} to {titleCase(recipientBankAccountName)}
							</div>

							<div className="sm:w-100% mx-auto flex w-full flex-col md:w-full">
								<span>
									<OtpInputForm
										value={singleTransferDetails.otp || undefined}
										otpFunc={handleOtpChange}
										errorMessage={singlePaymentError}
									/>
								</span>
								{!isAuthAppSetup && (
									<div className="flex items-start justify-start pt-4">
										<div>
											{minutes === 0 && seconds === 0 ? (
												<div>
													<span
														className="cursor-pointer  text-sm text-blue"
														onClick={() => {
															handleResendOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
															setResendOtpText(true);
														}}
													>
														Resend OTP
													</span>
												</div>
											) : (
												<span className="cursor-not-allowed  text-sm text-black-tertiary">
													Resend OTP in{" "}
													<span className="text-black-secondary">
														{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
													</span>
												</span>
											)}
										</div>
									</div>
								)}
								<div className="flex w-full flex-row items-center justify-center space-x-4 pt-8">
									<ButtonComp
										type="button"
										ripple="light"
										buttonType="secondary"
										color="grey"
										func={() => {
											dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT));
											dispatch(setZambiaSingleTransferOtp(""));
										}}
									>
										<span>Back</span>
									</ButtonComp>

									<ButtonComp
										type="submit"
										color="blue"
										ripple="light"
										buttonType="primary"
										isLoading={isSinglePaymentLoading}
										disable={singleTransferDetails.otp.length < 6}
										func={() => void handleSingleTransfer(singleTransferDetails)}
									>
										<span>Authorize</span>
									</ButtonComp>
								</div>
							</div>
						</div>
					</>
				)}
			</div>
		</>
	);
}

export default MakePayment;
