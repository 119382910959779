import {ReactComponent as Divider} from "../../../../../../assets/svg/Lines4.svg";
import {Link} from "react-router-dom";
import React from "react";
import SkeletonElement from "../../../../../../components/General/Skeletons/Components/SkeletonElement";
import callIcon from "../../../../../../assets/svg/call.svg";
import smsIcon from "../../../../../../assets/svg/sms.svg";
interface Props {
	name: string | undefined;
	photo: string | undefined;
	email: string | undefined;
	phone: string | undefined;
}

function Support(props: Props): JSX.Element {
	const phoneLink = "tel:" + (props.phone || "");
	const emailLink = "mailto:" + (props.email || "");

	return (
		<>
			<div className="flex justify-center items-start xl:h-full pt-10 lg:pt-28 mt-2 w-full xl:w-30%">
				<div className="rounded-lg border p-4 max-w-xs w-full mx-auto">
					<div className="text-center w-full">
						<h4 className="font-medium text-base 2xs:text-lg capitalize text-black">Hi there,</h4>
						<p className="text-xs 2xs:text-sm xl:max-w-15 mx-auto w-full text-black-tertiary pb-5">
							I&apos;m here to assist you anytime you need it.
						</p>
					</div>

					<div className="flex flex-col justify-center items-center">
						<div className="h-28 2xs:h-36 w-28 2xs:w-36 rounded-full">
							{props.photo ? (
								<img src={`data:image/png;base64,${props.photo}`} alt="account manager" className="rounded-full" />
							) : (
								<SkeletonElement avatar />
							)}
						</div>
						{props.name ? (
							<h3 className="text-sm 2xs:text-base font-medium mt-2 text-black">{props.name || ""}</h3>
						) : (
							<div className="h-4 mt-2 w-36">
								<SkeletonElement box />
							</div>
						)}
						{props.name ? (
							<p className="text-xs text-black-tertiary">Account manager</p>
						) : (
							<div className="h-3 mt-0.5 w-24">
								<SkeletonElement box />
							</div>
						)}

						<Divider className="my-3" />

						<Link to={{pathname: phoneLink}} target="_blank" className="text-black-secondary hover:text-black w-max mx-auto">
							{props.phone ? (
								<div className="flex space-x-2 pb-2">
									<img src={callIcon as string} alt="callIcon" />
									<p className="text-sm">{props.phone}</p>
								</div>
							) : (
								<div className="h-3 w-44 mx-auto">
									<SkeletonElement box />
								</div>
							)}
						</Link>
						<Link to={{pathname: emailLink}} target="_blank" className="text-black-secondary hover:text-black w-max mx-auto">
							{props.email ? (
								<div className="flex space-x-2">
									<img src={smsIcon as string} alt="smsIcon" />
									<span className="text-sm">{props.email}</span>
								</div>
							) : (
								<div className="h-3 w-44 mt-1 mx-auto">
									<SkeletonElement box />
								</div>
							)}
						</Link>
					</div>
				</div>
			</div>
			{/* <div className="flex sm:hidden justify-center items-start xl:h-full pt-10 lg:pt-28 mt-2 w-full 2xs:w-max 2xs:mx-auto">
				<div className="w-full rounded-lg border p-5 lg:max-w-xs">
					<div className="m-auto rounded-lg text-black-secondary text-sm relative mb-2 flex flex-col">
						<span className="text-black-secondary text-xs">I&apos;m here to assist you anytime you need it.</span>
					</div>
					<div className="flex flex-row space-x-4 items-center pb-2">
						<div className="h-36 w-36 rounded-full">
							{props.photo ? <img src={props.photo} alt="account manager" /> : <SkeletonElement box />}
						</div>
						<div className="flex flex-col">
							{props.name ? (
								<h3 className="text-base font-medium text-black">{props.name || ""}</h3>
							) : (
								<div className="h-6 w-36">
									<SkeletonElement box />
								</div>
							)}
							{props.name ? (
								<p className="text-xs text-black-tertiary">Account manager</p>
							) : (
								<div className="h-4 w-24">
									<SkeletonElement box />
								</div>
							)}
						</div>
					</div>
					<div className="w-max">
						<Link to={{pathname: phoneLink}} target="_blank" className="text-black-secondary hover:text-black w-max">
							{props.phone ? (
								<div className="flex space-x-2 pb-2">
									<img src={callIcon as string} alt="callIcon" />
									<p className="text-sm">{props.phone}</p>
								</div>
							) : (
								<div className="h-4 w-full">
									<SkeletonElement box />
								</div>
							)}
						</Link>
						<Link to={{pathname: emailLink}} target="_blank" className="text-black-secondary hover:text-black w-max">
							{props.email ? (
								<div className="flex space-x-2">
									<img src={smsIcon as string} alt="smsIcon" />
									<span className="text-sm">{props.email}</span>
								</div>
							) : (
								<div className="h-4 w-full">
									<SkeletonElement box />
								</div>
							)}
						</Link>
					</div>
				</div>
			</div> */}
		</>
	);
}

export default Support;
