import {setFundCardOtp} from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

interface useFundCardOtpInterface {
	handleOtpChange: (_amount: string) => void;
}

function useFundCardOtp(): useFundCardOtpInterface {
	const dispatch = useDispatch();

	const handleOtpChange = useCallback((_otp: string) => {
		dispatch(setFundCardOtp(_otp));
	}, []);

	return {handleOtpChange};
}

export default useFundCardOtp;
