import CustomerAccount from "../../../models/customerAccount";
import {Link} from "react-router-dom";
import {PaymentsType} from "../../../modules/Dashboard/Payments/Hooks/State/SendMoney/payments.constants";
import React from "react";
import {ReactComponent as Send} from "../../../assets/svg/DashboardLayout/Sections/sendMoneyIcon1.svg";
import TooltipIcons from "../../General/TooltipIcons/TooltipIcons";
import getBankName from "../../../helpers/getBankName";

interface AccountsSearchCardProps {
	index: number;
	account: CustomerAccount;
	onClick: (e: React.MouseEvent) => void;
}

export function AccountsSearchCard({index, account, onClick}: AccountsSearchCardProps): JSX.Element {
	return (
		<>
			<Link
				to={{
					pathname: "/payments/make/single",
					search: `?to=${account.id}`,
					state: {
						typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
						to: account.id,
					},
				}}
				onClick={onClick}
				tabIndex={-1}
				data-type="transaction"
			>
				<div className="" key={index} data-type="transaction">
					<div data-type="transaction">
						<div
							className="flex cursor-pointer flex-row items-center justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop"
							data-type="transaction"
						>
							<div className="w-30% pr-1" data-type="transaction">
								<p
									className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-secondary"
									data-type="transaction"
								>
									{account.accountName}
								</p>
							</div>
							<div className="flex w-30% items-center justify-start" data-type="transaction">
								<p
									className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary"
									data-type="transaction"
								>
									{account.accountNumber}
								</p>
							</div>
							<div className="flex w-35% items-center justify-between" data-type="transaction">
								<p
									className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary "
									data-type="transaction"
								>
									{getBankName(account.bankCode)}
								</p>
								<div className="pl-6" data-type="transaction">
									<TooltipIcons
										icon={<Send className="stroke-current text-blue" />}
										size="small"
										message="Send Money"
										placement="right"
										data-type="transaction"
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Link>
		</>
	);
}
