export enum UserRole {
	BOTH = 1,
	APPROVER = 2,
	INITIATOR = 3,
	VIEWER = 4,
	NOTIFICATION_ONLY = 5,
}

export const AllUserRoles = [UserRole.BOTH, UserRole.APPROVER, UserRole.INITIATOR, UserRole.VIEWER, UserRole.NOTIFICATION_ONLY];

export const UserRolesWithViewAccess = [UserRole.BOTH, UserRole.APPROVER, UserRole.INITIATOR, UserRole.VIEWER];

export const UserRolesWithInitiatorAccess = [UserRole.BOTH, UserRole.INITIATOR];

export const UserRolesWithApproverAccess = [UserRole.BOTH, UserRole.APPROVER];

export const UserRolesForSignatories = [UserRole.BOTH, UserRole.APPROVER, UserRole.INITIATOR];

export enum NotificationType {
	FULL = 1,
	TRUNCATED = 2,
	NONE = 3,
}
export const AllNotificationTypes = [NotificationType.FULL, NotificationType.TRUNCATED, NotificationType.NONE];

export enum NotificationChannel {
	IN_APP = 0,
	SMS = 1,
	EMAIL = 2,
}

export const SelectableNotificationChannels = [NotificationChannel.SMS, NotificationChannel.EMAIL];

export enum AccountBalanceInstructionType {
	LOW_BALANCE_ALERT = 1,
	EXCESS_BALANCE_ALERT = 2,
	SPLIT_INFLOW = 3,
}

export type AccountBalanceAlertType = AccountBalanceInstructionType.LOW_BALANCE_ALERT | AccountBalanceInstructionType.EXCESS_BALANCE_ALERT;

export enum ActivationStatus {
	NOT_APPLICABLE = 0,
	ACTIVATED = 1,
	ONGOING = 2,
	NOT_ACTIVATED = 3,
}

export const UserRoleText: {[role in UserRole]: string} = {
	[UserRole.BOTH]: "Initiator and Approver",
	[UserRole.APPROVER]: "Approver Only",
	[UserRole.INITIATOR]: "Initiator Only",
	[UserRole.VIEWER]: "Viewer Only",
	[UserRole.NOTIFICATION_ONLY]: "Notification Only",
};

export const UserRoleDescription: {[role in UserRole]: string} = {
	[UserRole.BOTH]: "This user can create transfers and move money without approval",
	[UserRole.APPROVER]: "This user can only approve transfers made by an initiator but can not create a transfer",
	[UserRole.INITIATOR]: "This user can create transfers for approval by an approver",
	[UserRole.VIEWER]: "This user has read-only access to the account",
	[UserRole.NOTIFICATION_ONLY]:
		"This user would only receive notification alert without showing the balance and can not login to access the business",
};

export const NotificationTypeText: {[type in NotificationType]: string} = {
	[NotificationType.FULL]: "Full Notification",
	[NotificationType.TRUNCATED]: "Truncated Notification",
	[NotificationType.NONE]: "No Notification",
};

export const NotificationTypeDescription: {
	[type in NotificationType]: string;
} = {
	[NotificationType.FULL]: "This user would receive inflow and payout alert with account balance",
	[NotificationType.TRUNCATED]: "This user would receive inflow and payout alert without account balance",
	[NotificationType.NONE]: "This user would not receive any payment alert",
};

export const NotificationChannelText: {
	[channel in NotificationChannel]: string;
} = {
	[NotificationChannel.IN_APP]: "In-App",
	[NotificationChannel.SMS]: "SMS",
	[NotificationChannel.EMAIL]: "Email",
};

export const NotificationChannelDescription: {
	[channel in NotificationChannel]: string;
} = {
	[NotificationChannel.IN_APP]: "",
	[NotificationChannel.SMS]: "This user would receive SMS alerts",
	[NotificationChannel.EMAIL]: "This user would receive email alerts",
};

export enum ApprovalLimitActions {
	EDIT = 1,
	CANCEL = 2,
}

export const ApprovalLimitOptions = [ApprovalLimitActions.EDIT, ApprovalLimitActions.CANCEL];
