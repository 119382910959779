import React, {useLayoutEffect, useRef, useState} from "react";
import {useHistory, useParams} from "react-router";

import ApplicationHeader from "../../../../../Dashboard/Application/components/element/ApplicationHeader";
import {ReactComponent as BusinessDocumentIcon} from "../../../../../../assets/svg/General/Documents/business-document.svg";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import DirectorNextOfKinReview from "./components/DirectorNextOfKinReview";
import DirectorPersonalDetailsReview from "./components/DirectorPersonalDetailsReview";
import {ReactComponent as DoubleArrowDownIcon} from "../../../../../../assets/svg/General/Arrow/doubleArrowDown.svg";
import {IRootState} from "../../../../../../redux/rootReducer";
import {InviteType} from "../../service/externalApplication.types";
import MessageToasts from "../../../../../../components/General/MessageToasts/MessageToasts";
import PreviewCard from "../../../../../Dashboard/Application/components/cards/PreviewCard";
import PreviewModal from "../../../../../Dashboard/Application/components/modals/preview-modal";
import {Routes} from "../../../../../../routes/routes.constants";
import {TailSpin} from "react-loader-spinner";
import {ToastType} from "../../../../../../helpers/AppConstants";
import useExternalApplicationInit from "../../Hooks/useExternalApplicationInit";
import useExternalDirectorPreview from "../../Hooks/useExternalDirectorPreview";
import {useSelector} from "react-redux";

interface DirectorUrlParams {
	a: string;
	b: string;
}

function DirectorReview(): JSX.Element {
	const history = useHistory();
	const {a, b} = useParams<DirectorUrlParams>();

	const {previewBlob, handlePreviewDocument} = useExternalDirectorPreview({
		onComplete: () => setShowPreviewModal(true),
	});

	const {externalApplicationInitError, isExternalApplicationInitLoading, handleExternalApplicationInit} = useExternalApplicationInit();

	const url = useSelector((state: IRootState) => state.externalApplication.externalLink || "");
	const externalParams = useSelector((state: IRootState) => state.externalApplication.externalParams);
	const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

	const director = externalApplicationInit?.director || null;

	const divRef = useRef<HTMLDivElement | null>(null);

	const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

	useLayoutEffect(() => {
		if (a && b) {
			void handleExternalApplicationInit({
				a,
				b,
				type: InviteType.S,
			});
		}
	}, [a, b]);

	return (
		<>
			<PreviewModal active={showPreviewModal} toggler={() => setShowPreviewModal(false)} data={previewBlob} title="Preview" />

			{isExternalApplicationInitLoading && (
				<div className="flex justify-center items-center h-full w-full px-4">
					<TailSpin color="#5466F9" height={30} width={30} />
				</div>
			)}
			{externalApplicationInitError && !isExternalApplicationInitLoading && (
				<div className="flex justify-center items-center h-full w-full px-4">
					<div className="w-max">
						<MessageToasts toastType={ToastType.ERROR} toastMessage={externalApplicationInitError} />
					</div>
				</div>
			)}
			{!isExternalApplicationInitLoading && !externalApplicationInitError && externalApplicationInit && director && (
				<div className="flex flex-row justify-start items-start space-x-6 w-full pb-28">
					<div className="lg:min-w-60% lg:max-w-3xl w-full">
						<ApplicationHeader
							header="Review your Application"
							subTitle="Below is the summary of the information you provided. You can make any necessary changes as you complete your application. "
							progress={100}
						>
							<div className="flex flex-col justify-start items-start w-full space-y-8 relative">
								<div className="flex flex-col justify-start items-center space-y-4 w-full">
									<div className="flex flex-row justify-between items-start w-full">
										<h3 className="text-black-secondary font-medium">Personal Details</h3>
										<ButtonComp
											type="button"
											color="blue"
											ripple="light"
											buttonType="tertiary"
											func={() =>
												history.push({
													pathname: `${url}${Routes.EXTERNAL_DIRECTOR.PERSONAL_DETAILS.DETAILS}`,
													state: {
														from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
													},
												})
											}
											size="sm"
										>
											<span>Change</span>
										</ButtonComp>
									</div>
									<DirectorPersonalDetailsReview data={director || undefined} options={externalApplicationInit.options} />
								</div>

								<div className="flex flex-col justify-start items-center space-y-4 w-full">
									<div className="flex flex-row justify-between items-start w-full">
										<h3 className="text-black-secondary font-medium">Next of Kin</h3>
										<ButtonComp
											type="button"
											color="blue"
											ripple="light"
											buttonType="tertiary"
											func={() =>
												history.push({
													pathname: `${url}${Routes.EXTERNAL_DIRECTOR.NEXT_OF_KIN}`,
													state: {
														from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
													},
												})
											}
											size="sm"
										>
											<span>Change</span>
										</ButtonComp>
									</div>
									<DirectorNextOfKinReview data={director?.nextOfKin || null} options={externalApplicationInit.options} />
								</div>

								<div className="flex flex-col justify-start items-center space-y-4 w-full">
									<div className="flex flex-row justify-between items-start w-full">
										<h3 className="text-black-secondary font-medium">Means of ID</h3>
										<ButtonComp
											type="button"
											color="blue"
											ripple="light"
											buttonType="tertiary"
											func={() =>
												history.push({
													pathname: `${url}${Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID}`,
													state: {
														from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
													},
												})
											}
											size="sm"
										>
											<span>Change</span>
										</ButtonComp>
									</div>
									<PreviewCard
										icon={<BusinessDocumentIcon />}
										text={
											externalApplicationInit?.options.idType?.find((_el) => _el.id === director?.identification?.type)?.name ||
											"-"
										}
										canNotPreview={!director.identification?.fileId}
										handlePreviewDocumentFunc={() => externalParams && handlePreviewDocument(externalParams)}
										// handlePreviewDocumentFunc={() => handlePreviewDocument(director.identification?.fileId || "")}
									/>
								</div>

								<div className="flex flex-col 2xs:flex-row items-center justify-center pt-16 2xs:space-x-4 w-full">
									<div className="w-full 2xs:w-max order-2 2xs:order-1">
										<ButtonComp
											type="button"
											color="grey"
											ripple="light"
											buttonType="secondary"
											func={() => history.push(`${url}${Routes.EXTERNAL_DIRECTOR.MEANS_OF_ID}`)}
											fullWidth
										>
											<span>Back</span>
										</ButtonComp>
									</div>
									<div className="w-full 2xs:w-max order-1 2xs:order-2">
										<ButtonComp
											type="button"
											color="blue"
											ripple="light"
											buttonType="primary"
											func={() =>
												history.push({
													pathname: `${url}${Routes.EXTERNAL_DIRECTOR.DONE}`,
													state: {
														from: `${url}${Routes.EXTERNAL_DIRECTOR.REVIEW}`,
													},
												})
											}
											fullWidth
										>
											<span>Submit Application</span>
										</ButtonComp>
									</div>
								</div>
							</div>
						</ApplicationHeader>
					</div>
					<div className="flex justify-end items-end w-full h-full relative">
						<div
							className="flex flex-row justify-center items-center fixed top-90% cursor-pointer"
							onClick={() => {
								divRef.current?.scrollIntoView({behavior: "smooth"});
							}}
						>
							<DoubleArrowDownIcon />
							<span className="ml-2 text-sm font-medium text-blue">Jump to end</span>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default DirectorReview;
