import React from "react";
import Transaction from "../../../../../../../models/transaction";
import TransactionDetailCardChannel from "./TransactionDetailCardChannel";
import TransactionDetailCardCharges from "./TransactionDetailCardCharges";
import TransactionDetailCardClientReference from "./TransactionDetailCardClientReference";
import TransactionDetailCardDate from "./TransactionDetailCardDate";
import TransactionDetailCardDescription from "./TransactionDetailCardDescription";
import TransactionDetailCardMerchantName from "./TransactionDetailCardMerchantName";
import TransactionDetailCardNIP from "./TransactionDetailCardNIP";
import TransactionDetailCardReference from "./TransactionDetailCardReference";
import TransactionDetailCardRetrievalReferenceNumber from "./TransactionDetailCardRetrievalReferenceNumber";
import TransactionDetailCardTime from "./TransactionDetailCardTime";

interface Props {
	dataType: string;
	transaction: Transaction;
}

function TransactionDetailCardDetails({dataType, transaction}: Props): JSX.Element {
	return (
		<div className="flex w-full flex-col items-start justify-start space-y-4 border-t-0.2 border-solid py-4 pb-4" data-type={dataType}>
			<p className="font-medium text-black-secondary" data-type={dataType}>
				Details
			</p>
			<div className="flex w-full flex-col items-start justify-start space-y-4" data-type={dataType}>
				{transaction.isCardPayment ? (
					<>
						<TransactionDetailCardMerchantName transaction={transaction} dataType={dataType} />
						<TransactionDetailCardChannel transaction={transaction} dataType={dataType} />
						<TransactionDetailCardTime transaction={transaction} dataType={dataType} />
						<TransactionDetailCardReference transaction={transaction} dataType={dataType} />
						<TransactionDetailCardRetrievalReferenceNumber transaction={transaction} dataType={dataType} />
					</>
				) : (
					<>
						<TransactionDetailCardDate transaction={transaction} dataType={dataType} />
						<TransactionDetailCardDescription transaction={transaction} dataType={dataType} />
						<TransactionDetailCardCharges transaction={transaction} dataType={dataType} />
						<TransactionDetailCardReference transaction={transaction} dataType={dataType} />
						<TransactionDetailCardClientReference transaction={transaction} dataType={dataType} />
						<TransactionDetailCardNIP transaction={transaction} dataType={dataType} />
					</>
				)}
			</div>
		</div>
	);
}

export default TransactionDetailCardDetails;
