import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {ApplicationBusinessType} from "../../modules/Dashboard/Application/services/application.constants";
import {BusinessDocumentResponse} from "../../modules/Dashboard/Application/services/zambia-application/registered/new/document.types";
import {BusinessInformationResponse} from "../../modules/Dashboard/Application/services/zambia-application/registered/new/company.types";
import IncorporationDocumentZambia from "../../models/account-opening/incorportationDocuments/incorporationDocumentZambia";
import {NewCurrentSignatoryResponse} from "../../modules/Dashboard/Application/services/zambia-application/registered/new/currentSignatory.types";
import {NewZambiaAccountOpeningInitState} from "../../modules/Dashboard/Application/services/application.types";
import ProofOfResidence from "../../models/account-opening/proofOfResidence";
import {ZambiaAccountSignatoriesResponse} from "../../modules/Dashboard/Application/services/zambia-application/registered/new/accountSignatories.types";
import {ZambiaBusinessAccountManagerResponse} from "../../modules/Dashboard/Application/services/zambia-application/zambia-application.types";
import ZambiaBusinessDetails from "../../models/account-opening/zambiaBusinessDetails";
import ZambiaSignatoryInfo from "../../models/account-opening/zambiaSignatoryInfo";
import ZambiaSignatoryMin from "../../models/account-opening/zambiaSignatoryInfoMin";

interface ApplicationState {
	init: NewZambiaAccountOpeningInitState | null;
	accountManager: {
		photo: string;
		name: string;
		email: string;
		phone: string;
	} | null;
	applicationBusinessType: ApplicationBusinessType;
	businessDetails: ZambiaBusinessDetails | null;
	currentSignatory: ZambiaSignatoryInfo | null;
	accountSignatories: {
		signatories: ZambiaSignatoryMin[];
	} | null;
	businessDocuments: {
		application: ZambiaBusinessDetails;
		incorporationDocuments: IncorporationDocumentZambia[];
		proofOfResidence: ProofOfResidence | null;
	} | null;
	isDirectorOrSignatory: boolean;
}

const initialState: ApplicationState = {
	init: null,
	accountManager: null,
	applicationBusinessType: ApplicationBusinessType.INITIAL,
	businessDetails: null,
	currentSignatory: null,
	accountSignatories: null,
	businessDocuments: null,
	isDirectorOrSignatory: false,
};

export const zambiaApplicationSlice = createSlice({
	name: "zambiaApplication",
	initialState,
	reducers: {
		setZambiaApplicationBusinessType: (state: ApplicationState, action: PayloadAction<ApplicationBusinessType>) => {
			state.applicationBusinessType = action.payload;
		},
		setZambiaAccountOpeningInit: (state: ApplicationState, action: PayloadAction<NewZambiaAccountOpeningInitState>) => {
			state.init = action.payload;
		},
		setZambiaAccountManager: (state: ApplicationState, action: PayloadAction<ZambiaBusinessAccountManagerResponse>) => {
			state.accountManager = action.payload;
		},
		updateZambiaCurrentSignatory: (state: ApplicationState, action: PayloadAction<NewCurrentSignatoryResponse>) => {
			state.currentSignatory = action.payload.signatory;
			if (state.init) {
				state.init.progress = action.payload.progress;
			}
		},
		resetZambiaCurrentSignatory: (state: ApplicationState) => {
			state.currentSignatory = null;
		},

		setZambiaBusinessDetails: (state: ApplicationState, action: PayloadAction<BusinessInformationResponse>) => {
			state.businessDetails = action.payload.application;
			if (state.init) {
				state.init.progress = action.payload.progress;
			}
		},
		updateZambiaBusinessDetails: (state: ApplicationState, action: PayloadAction<ZambiaBusinessDetails>) => {
			if (state.init) {
				state.init.application = action.payload;
			}
		},
		updateZambiaBusinessDetailsType: (state: ApplicationState, action: PayloadAction<boolean>) => {
			if (state.init) {
				state.init.application.isRegistered = action.payload;
			}
		},
		resetZambiaBusinessDetails: (state: ApplicationState) => {
			state.businessDetails = null;
		},
		setZambiaAccountSignatories: (state: ApplicationState, action: PayloadAction<ZambiaAccountSignatoriesResponse>) => {
			state.accountSignatories = {
				signatories: action.payload.signatories,
			};
			if (state.init) {
				state.init.progress = action.payload.progress;
			}
		},
		resetZambiaAccountSignatories: (state: ApplicationState) => {
			state.accountSignatories = null;
		},
		setZambiaBusinessDocuments: (state: ApplicationState, action: PayloadAction<BusinessDocumentResponse>) => {
			state.businessDocuments = {
				application: action.payload.application,
				incorporationDocuments: action.payload.incorporationDocuments,
				proofOfResidence: action.payload.proofOfResidence || null,
			};
			if (state.init) {
				state.init.progress = action.payload.progress;
				state.init.application = action.payload.application;
			}
		},
		resetZambiaBusinessDocuments: (state: ApplicationState) => {
			state.businessDocuments = null;
		},

		setZambiaIsDirectorOrSignatory: (state: ApplicationState, action: PayloadAction<boolean>) => {
			state.isDirectorOrSignatory = action.payload;
		},

		resetZambiaAllApplicationState: (state: ApplicationState) => {
			state.init = null;
			state.accountManager = null;
			state.businessDetails = null;
			state.currentSignatory = null;
			state.businessDocuments = null;
			state.accountSignatories = null;
			state.isDirectorOrSignatory = false;
			state.applicationBusinessType = ApplicationBusinessType.INITIAL;
		},
	},
});

export const {
	setZambiaAccountOpeningInit,
	setZambiaAccountManager,

	updateZambiaCurrentSignatory,
	resetZambiaCurrentSignatory,

	updateZambiaBusinessDetailsType,

	setZambiaBusinessDetails,
	resetZambiaBusinessDetails,
	updateZambiaBusinessDetails,
	setZambiaAccountSignatories,
	resetZambiaAccountSignatories,

	setZambiaBusinessDocuments,
	resetZambiaBusinessDocuments,

	setZambiaIsDirectorOrSignatory,
	setZambiaApplicationBusinessType,

	resetZambiaAllApplicationState,
} = zambiaApplicationSlice.actions;

export default zambiaApplicationSlice.reducer;
