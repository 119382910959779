import AddMoneyCard from "../../../../Accounts/Components/Modals/ZambiaAddMoneyModal/Components/Card/AddMoneyCard";
import AddMoneyHeader from "../../Layouts/AddMoney/Components/AddMoneyHeader";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {IRootState} from "../../../../../../redux/rootReducer";
import {ReactComponent as InfoIcon} from "../../../../../../assets/svg/Accounts/info.svg";
import React from "react";
import {useSelector} from "react-redux";

interface Props {
	selectedAccountId: string;
	handleDone: () => void;
	handleBack: () => void;
}

function BankTransfer(props: Props): JSX.Element {
	const account = useSelector(
		(state: IRootState) => state.init.main?.companyDetails.accounts.find((acc) => acc.id === props.selectedAccountId) || null
	);

	const addMoneyBankAccount = useSelector((state: IRootState) => state.init.main?.meta.addMoneyBankAccount);

	return (
		<>
			<div className="max-w-sm w-full flex flex-col">
				<AddMoneyHeader />

				<div className="w-full flex flex-col items-center justify-start space-y-16">
					<div className="flex w-full flex-col space-y-4">
						<AddMoneyCard
							accountName={addMoneyBankAccount?.accountName || ""}
							accountNumber={addMoneyBankAccount?.accountNumber || ""}
							medium={addMoneyBankAccount?.bank || ""}
							branch={addMoneyBankAccount?.branch || ""}
							swift={addMoneyBankAccount?.swiftCode || ""}
						/>
						<div className="flex w-full flex-col space-y-2 rounded-md border bg-blue-backdrop p-4">
							<div className="flex w-full items-center space-x-2">
								<InfoIcon />
								<h6 className="text-sm font-medium text-black-secondary">Fill out the memo as written below</h6>
							</div>
							<p className="text-xs text-black-tertiary">
								FFC - {account?.accountNumber || ""} - {account?.accountName || ""}
							</p>
						</div>
					</div>
					<div className="flex w-full flex-col 2xs:w-max 2xs:flex-row 2xs:space-x-4">
						<div className="order-2 w-full pt-4 2xs:order-1 2xs:w-max 2xs:pt-0">
							<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={props.handleBack} fullWidth>
								<span>Back</span>
							</ButtonComp>
						</div>
						<div className="order-1 w-full 2xs:order-2 2xs:w-max">
							<ButtonComp type="button" color="blue" ripple="light" buttonType="primary" fullWidth func={props.handleDone}>
								<span>I&apos;ve Sent the Money</span>
							</ButtonComp>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default BankTransfer;
