import FullPageTrayFooter from "../../../../../../../components/General/Trays/FullPageTray/FullPageTrayFooter";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {IconType} from "../../../../../../../helpers/AppConstants";
import Icons from "../../../../../../../components/General/Icon/Icon";
import {PaymentsType} from "../../../../../Payments/Hooks/State/SendMoney/payments.constants";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import useTransactionDetailsLencoReceipt from "../Hooks/useTransactionDetailsLencoReceipt";

interface TransactionDetailCardActionsInterface {
	dataType: string;
	transaction: Transaction;
	handleShowTagTransactionModal: () => void;
	// handleShowUploadReceiptModal: () => void;
}

function TransactionDetailCardActions({dataType, transaction, handleShowTagTransactionModal}: TransactionDetailCardActionsInterface): JSX.Element {
	const history = useHistory();
	const {isDownloading: isDownloadingLencoReceipt, handleDownloadLencoReceipt} = useTransactionDetailsLencoReceipt();
	const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);

	return (
		<>
			{transaction && transaction.isDebit() && transaction.isSuccess && transaction.destination && transaction.destination.account && (
				<FullPageTrayFooter dataType={dataType}>
					<div
						className="flex w-full flex-row items-center justify-center space-x-5 text-center text-2xs text-black-tertiary"
						data-type={dataType}
					>
						{transaction.isDebit() && (
							<>
								<div
									className="flex cursor-pointer flex-col items-center justify-center space-y-2 "
									data-type={dataType}
									onClick={() =>
										history.push({
											pathname: "/payments/make/single",
											search: `?to=${transaction.destination?.account?.id || ""}`,
											state: {
												typeOfTransfer: PaymentsType.SINGLE_TRANSFER,
												to: transaction.destination?.account?.id || "",
											},
										})
									}
								>
									<Icons icon={IconType.NEW_PAYMENT} data-type={dataType} />
									<p className="text-xs text-black-tertiary" data-type={dataType}>
										New Payment
									</p>
								</div>
							</>
						)}

						{transaction.isDebit() && transaction.isSuccess && transaction.destination && transaction.destination.account && (
							<div
								className="flex cursor-pointer flex-col items-center justify-center space-y-2"
								onClick={() => handleDownloadLencoReceipt(transaction)}
								data-type={dataType}
							>
								<div data-type={dataType}>
									<Icons icon={IconType.DOWNLOAD} isLoading={isDownloadingLencoReceipt} data-type={dataType} />
								</div>
								<div className="text-xs text-black-tertiary" data-type={dataType}>
									Download Receipt
								</div>
							</div>
						)}

						{/*
						{transaction.receiptUploaded && (
							<div
								className="flex flex-col justify-center items-center space-y-2 cursor-pointer"
								onClick={() => handleDownloadAccountingReceipt(transaction)}
								data-type={dataType}
							>
								<div data-type={dataType}>
									<Icons icon={IconType.PREVIEW} isLoading={isDownloading} data-type={dataType} />
								</div>
								<div className="text-xs text-black-tertiary" data-type={dataType}>
									{transaction.receiptUploaded ? "Download Invoice" : "Download Receipt"}
									Preview Receipt
								</div>
							</div>
						)} */}

						{/* {!transaction.receiptUploaded && (
							<div
								className="flex flex-col justify-center items-center space-y-2 cursor-pointer"
								onClick={handleShowUploadReceiptModal}
								data-type={dataType}
							>
								<div data-type={dataType}>
									<Icons icon={IconType.UPLOAD} data-type={dataType} />
								</div>
								<div className="text-xs text-black-tertiary" data-type={dataType}>
									{transaction.receiptUploaded ? "Upload Invoice" : "Upload Receipt"}
									Add Attachment
								</div>
							</div>
						)} */}

						{isNigeria &&
							transaction.isDebit() &&
							transaction.isSuccess &&
							transaction.destination &&
							transaction.destination.customerAccount && (
								<div
									className="flex cursor-pointer flex-col items-center justify-center space-y-2"
									onClick={handleShowTagTransactionModal}
									data-type={dataType}
								>
									<div data-type={dataType}>
										<Icons icon={IconType.SHARE} data-type={dataType} />
									</div>
									<div className="text-xs text-black-tertiary" data-type={dataType}>
										Share Receipt
									</div>
								</div>
							)}
					</div>
				</FullPageTrayFooter>
			)}
		</>
	);
}

export default TransactionDetailCardActions;
