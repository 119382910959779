import {ExcludeProps, InputPropsToExclude} from "./types";
import React, {InputHTMLAttributes, ReactNode, useEffect, useRef, useState} from "react";

import {ReactComponent as PercentageIcon} from "../../../assets/svg/Settings/PercentageIcon.svg";
import useClickOutside from "../../../hooks/useClickOutside";

// use to generate a unique id for the input
let inputCounter = 0;

function IconContainer({show, children, className}: {show: boolean; children: React.ReactNode; className?: string}): JSX.Element {
	return (
		<div
			className={
				"flex cursor-default items-center justify-end text-black-secondary transition-all duration-75 ease-in-out " +
				`${show ? "opacity-100" : "opacity-0"} ` +
				`${className || ""}`
			}
		>
			{children}
		</div>
	);
}

interface InputProps extends ExcludeProps<InputHTMLAttributes<HTMLInputElement>, InputPropsToExclude> {
	// interface InputProps {
	placeholder?: ReactNode;
	inputPlaceholder?: string;
	value?: string | number | undefined;
	isExpiry?: boolean;
	type?: string;
	icon?: ReactNode;
	iconRight?: ReactNode;
	iconRightClassName?: string;
	onClickRightIcon?: () => void;
	inputSize?: "sm" | "md" | "lg";
	appendIcon?: ReactNode;
	appendOuterIcon?: ReactNode;
	iconType?: string;
	onChange?(value: string): void;
	isFocused?: boolean;
	isLoading?: boolean;
	alwaysActive?: boolean;
	isDisabled?: boolean;
	fullWidth?: boolean;
	readOnly?: boolean;
	helperText?: React.ReactNode;
	mobileHelperText?: boolean;
	isControlledInput?: boolean;
}
function Input({
	placeholder,
	value,
	type,
	icon,
	iconRight,
	iconRightClassName = "",
	onClickRightIcon,
	isExpiry = false,
	appendIcon,
	appendOuterIcon,
	inputPlaceholder = "",
	iconType,
	onChange,
	isFocused,
	isLoading,
	inputSize = "lg",
	alwaysActive = false,
	isDisabled = false,
	fullWidth = false,
	readOnly = false,
	helperText = "",
	mobileHelperText = false,
	isControlledInput = false,
	...otherProps
}: InputProps): JSX.Element {
	const [active, setActive] = useState(false);
	const [isHover, setIsHover] = useState(false);
	const [hasValue, setHasValue] = useState(false);
	const [uniqueId, setUniqueId] = useState<string>("");
	const inputRef = useRef<HTMLInputElement | null>(null);

	const domNode = useClickOutside(() => setActive(false));

	useEffect(() => {
		setUniqueId(`input-${++inputCounter}`);
	}, []);

	useEffect(() => {
		if (isFocused) {
			if (inputRef.current) {
				inputRef.current.focus();
			}
			setActive(true);
		}
	}, [isFocused]);

	useEffect(() => {
		setHasValue(!!value && String(value).length > 0);
	}, [value]);

	const [maxLength] = useState(9);

	const correctInput = (event: React.KeyboardEvent<HTMLInputElement>) => {
		const target = event.target as HTMLInputElement;
		const inputValue = target.value;
		const key = event.key;
		let typeKey;
		const monthMin = "01";
		const monthMax = "12";
		const yearMin = new Date().getFullYear() - 2000;
		const yearMax = new Date().getFullYear() - 2000 + 25;

		if (/(\d|ArrowLeft|ArrowRight|Backspace|Delete|Tab)/.test(key)) {
			if (/(\d)/.test(key)) {
				typeKey = "number";
			} else {
				typeKey = "specSymbol";
			}

			if (inputValue.length === 0 && Number(key) > 1) {
				// 2 -> 02/
				target.value = `0${key}/`;
				event.preventDefault();
			}
			// if (inputValue.length === 0 && key > "1") {
			// // 2 -> 02/
			// target.value = `0${key}${"/"}`;
			// event.preventDefault();
			// }

			if (inputValue.length === 1 && inputValue[0] !== "0" && Number(key) > 2) {
				// 16 -> 12/
				target.value = `${monthMax}/`;
				event.preventDefault();
				return;
			}
			// if (inputValue.length === 1 && inputValue[0] !== "0" && key > "2") {
			// // 16 -> 12/
			// target.value = `${monthMax}/`;
			// event.preventDefault();
			// return;
			// }

			if (inputValue.length === 1 && typeKey !== "specSymbol") {
				// 12 backspace -> 1
				target.value = `${inputValue}${key}/`;
				event.preventDefault();
			}

			if (inputValue.length === 2 && typeKey !== "specSymbol") {
				// 2 -> 02/
				target.value = `${inputValue}/${key}`;
				event.preventDefault();
				return;
			}

			if (inputValue === "0" && key === "0") {
				// 00 -> 01
				target.value = `${monthMin}/`;
				event.preventDefault();
				return;
			}

			if (target.value.length + 1 === maxLength) {
				// 12/11 -> 12/16
				const arr = target.value.split("/");

				if (arr[0].length === 2 && parseInt(arr[1] + key, 10) < yearMin) {
					target.value = `${arr[0]}/${yearMin}`;
					event.preventDefault();
					return;
				}

				if (arr[0].length === 2 && parseInt(arr[1] + key, 10) > yearMax) {
					// 12/55 -> 12/41
					target.value = `${arr[0]}/${yearMax}`;
					event.preventDefault();
					return;
				}
			}
		} else {
			event.preventDefault();
			return;
		}

		onChange && onChange(target.value);
	};

	return (
		<div className={"relative flex w-full flex-col items-center " + (fullWidth ? "w-full" : "")}>
			<div
				className={
					`relative flex h-full w-full flex-col items-center justify-start ` +
					`${isControlledInput ? "" : ""} ` +
					`${isDisabled ? "pointer-events-none" : ""} ` +
					`${readOnly ? "pointer-events-none" : ""} ` +
					`${!(readOnly && isDisabled) ? "cursor-text" : ""}`
				}
				ref={domNode}
			>
				<div
					onFocus={() => {
						if (inputRef.current) {
							inputRef.current.focus();
						}
						setActive(true);
					}}
					onBlur={() => {
						setActive(false);
					}}
					className={
						`outline-none focus:outline-none relative flex w-full items-center justify-between space-x-1 whitespace-nowrap rounded-lg border border-solid bg-white text-left text-base font-normal leading-relaxed shadow-none transition-all duration-150 hover:text-blue focus:border-blue-focused focus:text-blue lg:hover:border-blue ` +
						`${
							hasValue || alwaysActive
								? !active
									? "border-black-quin text-black-secondary hover:text-blue lg:hover:border-blue "
									: active
									? "border-blue"
									: "border-black-quin text-black-tertiary hover:text-blue focus:border-blue-focused focus:text-blue lg:hover:border-blue "
								: active
								? "border-blue text-blue"
								: "border-black-quin text-black-tertiary focus:border-blue-focused focus:text-blue "
						} ` +
						`${inputSize === "lg" ? "h-12" : ""} ` +
						`${inputSize === "md" ? "h-10" : ""} ` +
						`${inputSize === "sm" ? "h-8" : ""} ` +
						`${isLoading ? "pointer-events-none" : ""} ` +
						`${icon ? "px-4" : ""}`
					}
					onClick={() => {
						if (inputRef.current) {
							inputRef.current.focus();
						}
						setActive(true);
					}}
					onMouseEnter={() => !isDisabled && setIsHover(true)}
					onMouseLeave={() => !isDisabled && setIsHover(false)}
					ref={domNode}
				>
					{icon && (
						<span
							className={
								`flex cursor-default items-center justify-end text-black-secondary transition-all duration-75 ease-in-out ` +
								`${placeholder ? (active || alwaysActive || hasValue ? "opacity-100" : "opacity-0") : ""}`
							}
						>
							{icon}
						</span>
					)}
					<input
						ref={inputRef}
						type={type ? type : "text"}
						value={value || ""}
						onKeyDown={isExpiry ? correctInput : undefined}
						maxLength={isExpiry ? 5 : undefined}
						onChange={(e) => onChange && onChange(e.target.value)}
						className={
							`focus:outline-none z-10 h-full w-full rounded-lg bg-white py-3  focus:border-none ` +
							`${alwaysActive || hasValue ? "text-black-secondary" : "text-black"} ` +
							`${isDisabled ? "bg-transparent text-black-quat " : ""} ` +
							`${type === "password" ? "text-xl " : ""} ` +
							`${!icon ? "px-4" : ""}`
						}
						id={otherProps.id || uniqueId}
						disabled={isDisabled}
						tabIndex={readOnly || isDisabled ? -1 : 0}
						{...otherProps}
						placeholder={placeholder ? (active ? inputPlaceholder : undefined) : inputPlaceholder}
					/>
					{iconRight && (
						<div
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								onClickRightIcon && onClickRightIcon();
							}}
							className={
								`flex cursor-pointer items-center justify-center h-full py-0 pl-3 pr-4 text-black-secondary transition-all duration-75 ease-in-out border-l border-black-quin  ` +
								`${iconRightClassName} ` +
								`${active || alwaysActive || hasValue ? "opacity-100" : "opacity-0 pointer-events-none"}`
							}
						>
							{iconRight}
						</div>
					)}
					{iconType === "percentage" ? (
						<span
							className={` flex cursor-default items-center justify-start pr-4 transition-all duration-75 ease-in-out ${
								active || alwaysActive || hasValue ? "opacity-100" : "opacity-0"
							}`}
						>
							<PercentageIcon className="h-3.5 w-3.5 fill-current" />
						</span>
					) : (
						appendIcon && <IconContainer show={active || alwaysActive || hasValue}>{appendIcon}</IconContainer>
					)}
					{appendOuterIcon && (
						<span className="px-3">
							<IconContainer
								show
								className={`${
									hasValue || alwaysActive
										? !active
											? "text-black-secondary hover:text-blue "
											: active
											? ""
											: "text-black-tertiary hover:text-blue "
										: active
										? "text-blue"
										: "text-black-tertiary"
								} `}
							>
								{appendOuterIcon}
							</IconContainer>
						</span>
					)}
					{placeholder && (
						<label
							// htmlFor={otherProps.id || uniqueId}
							// className={`z-10 absolute  ease-in-out duration-150 h-2 space-x-none pointer-events-none ${
							// active || alwaysActive || hasValue
							// ? "left-2.5 -top-2 text-xs bg-white px-1 "
							// : "left-4 top-0 text-base h-full flex justify-center items-center "
							// } ${active ? "text-blue" : alwaysActive || hasValue ? "text-black-tertiary " : "text-black-tertiary "}  ${
							// isHover ? "text-blue" : ""
							// }`}
							htmlFor={otherProps.id || uniqueId}
							className={
								"ml-0! pointer-events-none absolute z-10 h-2 cursor-text duration-150 ease-in-out " +
								`${active || alwaysActive || hasValue ? " -top-2 left-2.5 bg-white px-1 text-xs" : " left-4 top-3 text-base"} ` +
								`${active ? "text-blue" : ""} ` +
								`${isHover ? "lg:text-blue" : ""} ` +
								`${!active && (alwaysActive || hasValue) ? "text-black-secondary" : ""} ` +
								`${!active && !(alwaysActive || hasValue) ? "text-black-tertiary" : ""} ` +
								`${isDisabled ? "text-black-quat " : ""} `
							}
							onClick={() => {
								if (inputRef.current) {
									inputRef.current.focus();
								}
							}}
						>
							<span className="h-fit overflow-ellipsis overflow-hidden whitespace-nowrap max-w-full">{placeholder}</span>
						</label>
					)}
				</div>
			</div>
			{helperText && (
				<span
					className={
						`w-full pt-2 text-left text-xs leading-4 text-black-tertiary ` +
						`${mobileHelperText ? "lg:hidden" : ""} ` +
						`${!mobileHelperText ? "" : ""} ` +
						`${isDisabled ? "text-black-quat " : ""} `
					}
					// data-type={dataType}
				>
					{helperText}
				</span>
			)}
		</div>
	);
}

export default Input;
