import {GenericObject} from "../../helpers/types";
import MetaEntity from "./metaEntity";
import Parsers from "../../utils/parsers";
import {immerable} from "immer";

export default class ZambiaIndustry extends MetaEntity {
	[immerable] = true;

	constructor(public id: number, public name: string, public requireOperatingLicense: boolean, public isDeleted: boolean) {
		super(id, name);
	}

	static create(obj: GenericObject): ZambiaIndustry {
		return new ZambiaIndustry(
			Parsers.number(obj.id),
			Parsers.string(obj.name),
			Parsers.boolean(obj.requireSCUML),
			Parsers.boolean(obj.isDeleted)
		);
	}
}
