import {ONE, TWO} from "../../../../../helpers/AppConstants";
import {abortLoginOtp, inviteLogin, inviteOtp, login, otp, verifyInvite} from "../../Services/LoginApi";
import {
	inviteLoginData,
	inviteOtpData,
	loginData,
	otpData,
	verifyInviteLoginData,
	verifyInviteLoginDataResponse,
} from "../../Services/LoginApi.types";
import {useCallback, useEffect, useRef, useState} from "react";

import {FormikProps} from "formik";
import {RequestCancelledError} from "../../../../../helpers/request/requestErrors";
import {Routes} from "./../../../../../routes/routes.constants";
import {getErrorMessage} from "../../../../../utils/getErrorMessage";
import {loginSuccess} from "../../../../../redux/init/slice/initSlice";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {useParams} from "react-router";

interface InviteLoginUrlParams {
	a: string;
	b: string;
	type: string;
}
export interface UseLoginStateInterface {
	url: string;
	invite: boolean;
	section: number;
	loginOtp: string;
	formikRef: React.MutableRefObject<FormikProps<{username: string; password: string}> | null>;
	loginError: string | null;
	isOtpLoading: boolean;
	isAuthAppSetup: boolean;
	isLoginLoading: boolean;
	verifyLoginError: string | null;
	isVerifyInviteLoginLoading: boolean;
	verifyInviteLoginResponse: verifyInviteLoginDataResponse | null;

	handleChange: (e: string) => void;
	handleResend: () => void;
	handleOtpBack: () => void;
	handleSubmitLoginDetails: (_values: loginData) => void;
}

const useLoginState = (): UseLoginStateInterface => {
	const dispatch = useDispatch();

	const formikRef = useRef<FormikProps<{username: string; password: string}> | null>(null);

	const [isOtpLoading, setIsOtpLoading] = useState<boolean>(false);
	const [isLoginLoading, setIsLoginLoading] = useState<boolean>(false);

	const [isVerifyInviteLoginLoading, setIsVerifyInviteLoading] = useState<boolean>(false);
	const [verifyInviteLoginResponse, setVerifyInviteLoginResponse] = useState<verifyInviteLoginDataResponse | null>(null);

	const [loginError, setLoginError] = useState<string | null>(null);
	const [verifyLoginError, setVerifyLoginError] = useState<string | null>(null);

	const {a, b, type} = useParams<InviteLoginUrlParams>();

	const queryParams = new URLSearchParams(location.search);
	const referralCode = queryParams.get("ref");

	const history = useHistory();
	const [url, setURL] = useState<string>("");
	const [invite, setInvite] = useState<boolean>(false);
	const [section, setSection] = useState<number>(ONE);
	const [loginOtp, setLoginOtp] = useState<string>("");
	const [isAuthAppSetup, setIsAuthAppSetup] = useState<boolean>(false);
	const [info, setInfo] = useState<loginData>({
		username: "",
		password: "",
	});

	const handleLogin = useCallback(
		async (data: loginData): Promise<void> => {
			try {
				setIsLoginLoading(true);
				setLoginError(null);
				const res = await login(data);
				// setLoginResponse(res);
				if (res.success && res.otp) {
					setSection(TWO);
					setIsAuthAppSetup(res.isAuthAppSetup);
				}
				if (res.success && !res.otp) {
					dispatch(loginSuccess());
					history.push(Routes.DASHBOARD);
				}
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}
				const errorMessage = getErrorMessage(err);
				setLoginError(errorMessage);
			}
			setIsLoginLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const handleInviteLogin = useCallback(
		async (data: inviteLoginData): Promise<void> => {
			try {
				setIsLoginLoading(true);
				setLoginError(null);
				const res = await inviteLogin(data);
				// setLoginResponse(res);
				if (res.success && res.otp) {
					setSection(TWO);
					setIsAuthAppSetup(res.isAuthAppSetup);
				}
				if (res.success && !res.otp) {
					dispatch(loginSuccess());
					history.push(Routes.DASHBOARD);
				}
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}
				const errorMessage = getErrorMessage(err);
				setLoginError(errorMessage);
			}
			setIsLoginLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const handleOtp = useCallback(
		async (data: otpData): Promise<void> => {
			try {
				setIsOtpLoading(true);
				setLoginError(null);
				const res = await otp(data);
				// setLoginResponse(res);
				if (res.success && !res.otp) {
					dispatch(loginSuccess());
					history.push(Routes.DASHBOARD);
				}
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}

				const errorMessage = getErrorMessage(err);
				setLoginError(errorMessage);
			}
			setIsOtpLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const handleInviteOtp = useCallback(
		async (data: inviteOtpData): Promise<void> => {
			try {
				setIsOtpLoading(true);
				setLoginError(null);
				const res = await inviteOtp(data);
				// setLoginResponse(res);
				if (res.success && !res.otp) {
					dispatch(loginSuccess());
					history.push(Routes.DASHBOARD);
				}
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}

				const errorMessage = getErrorMessage(err);
				setLoginError(errorMessage);
			}
			setIsOtpLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const handleVerifyInvite = useCallback(
		async (data: verifyInviteLoginData): Promise<void> => {
			try {
				setIsVerifyInviteLoading(true);
				setLoginError(null);
				const res = await verifyInvite(data);
				setVerifyInviteLoginResponse(res);
				formikRef.current?.getFieldHelpers("username").setValue(res.email);
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}
				const errorMessage = getErrorMessage(err);
				setVerifyLoginError(errorMessage);
			}
			setIsVerifyInviteLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const handleReset = useCallback(() => {
		setLoginError(null);
		setIsLoginLoading(false);
		// setLoginResponse(null);
		setInfo({
			username: "",
			password: "",
		});
		setLoginOtp("");
		setIsOtpLoading(false);
		setVerifyInviteLoginResponse(null);
		setLoginError(null);
		setVerifyLoginError(null);
	}, []);

	useEffect(() => {
		//check if params exists
		if (!a || !b || !type) {
			setInvite(false);
			handleReset();
			return;
		}
		//verify invite link
		void handleVerifyInvite({a, b, type, auth: "login"});
		//set URL
		setURL(`/signup/account/invite/${type}/${a}/${b}?ref=${referralCode || ""}`);
		//set invite
		setInvite(true);
	}, [a, b, type, referralCode]);

	//send OTP
	useEffect(() => {
		if (!loginOtp || loginOtp.length < 6) return;
		if (invite) {
			void handleInviteOtp({...info, a, b, type, otp: loginOtp});
			return;
		}
		void handleOtp({...info, otp: loginOtp});
	}, [handleInviteOtp, handleOtp, a, b, type, info, invite, loginOtp]);

	//send login details
	const handleSubmitLoginDetails = useCallback(
		(values: loginData) => {
			setInfo(values);
			if (invite) {
				void handleInviteLogin({...values, a, b, type});
				return;
			}
			void handleLogin(values);
		},
		[a, b, type, invite, handleLogin, handleInviteLogin]
	);

	//set OTP value
	const handleChange = useCallback((e: string) => {
		setLoginOtp(e);
	}, []);

	//resend OTP
	const handleResend = useCallback(() => {
		if (invite) {
			void handleInviteLogin({...info, a, b, type});
			return;
		}
		void handleLogin({...info});
	}, [a, b, type, info, invite, handleLogin, handleInviteLogin]);

	const handleOtpBack = useCallback(() => {
		setSection(ONE);
		handleReset();

		//work on the abort system for the new logic
		abortLoginOtp();
	}, [handleReset]);

	return {
		url,
		invite,
		section,
		loginOtp,
		formikRef,
		loginError,
		isOtpLoading,
		isLoginLoading,
		isAuthAppSetup,
		verifyLoginError,
		verifyInviteLoginResponse,
		isVerifyInviteLoginLoading,

		handleChange,
		handleResend,
		handleOtpBack,
		handleSubmitLoginDetails,
	};
};

export default useLoginState;
