import ApiAccess from "../../../../models/zambia/lencoPay/apiAccess";
import {ApiAccessStatus} from "../../../../models/zambia/lencoPay/apiAccess.constant";

export enum ApiAccessKeysStage {
	LIST = "list",
	SETTINGS = "settings",
}

export enum ZambiaApiAccessKeySettingsTab {
	API_KEYS = 1,
	WEBHOOK = 2,
	WHITELISTED_IP_ADDRESS = 3,
	WIDGETS = 4,
	SETUP = 5,
}
export const ZambiaApiAccessKeySettingsTabs = [
	ZambiaApiAccessKeySettingsTab.API_KEYS,
	ZambiaApiAccessKeySettingsTab.WEBHOOK,
	ZambiaApiAccessKeySettingsTab.WHITELISTED_IP_ADDRESS,
	ZambiaApiAccessKeySettingsTab.WIDGETS,
	ZambiaApiAccessKeySettingsTab.SETUP,
];

export const ZambiaApiAccessKeySettingsTabText = {
	[ZambiaApiAccessKeySettingsTab.API_KEYS]: "API Keys",
	[ZambiaApiAccessKeySettingsTab.SETUP]: "Setup",
	[ZambiaApiAccessKeySettingsTab.WEBHOOK]: "Webhook",
	[ZambiaApiAccessKeySettingsTab.WHITELISTED_IP_ADDRESS]: "Whitelisted IP Address",
	[ZambiaApiAccessKeySettingsTab.WIDGETS]: "Widgets",
};

export interface ApiAccessKeysState {
	isCreateApiAccessKeyModalOpen: boolean;
	isRegenerateApiAccessKeyModalOpen: boolean;
	isDeactivateApiAccessKeyModalOpen: boolean;
	apiAccessKeysStage: ApiAccessKeysStage;
	apiAccessKeySettingTab: ZambiaApiAccessKeySettingsTab;
	apiAccessKeySecretKey: string | null;
	selectedApiAccessKey: ApiAccess | null;
	tempSelectedApiAccessKey: ApiAccess | null;
	isApiAccessKeysPaginationLoading: boolean;
	apiAccessKeysPaginationOffset: number;
	apiAccessKeysListTotal: number;
	apiAccessKeysListGroupSize: number;
	apiAccessKeysList: ApiAccess[] | null;
	isApiAccessKeysListEmpty: boolean;
	apiAccessKeysFilterState: {
		query: string;
		offset: number;
		status: ApiAccessStatus[];
	};
	selectedApiAccessKeysFilterState: {
		query: string;
		offset: number;
		status: ApiAccessStatus[];
	};
}

export type ApiAccessKeysFilterTypes = {query: string} | {offset: number} | {status?: Array<ApiAccessStatus>};
