import React, {Suspense, lazy} from "react";
import {Redirect, Route, Switch} from "react-router-dom";

import AccountLogIn from "../modules/Landing/Login/Pages/LogIn";
import AddMoney from "../modules/Dashboard/Payments/Pages/AddMoney";
import AppRoute from "./AppRoute";
import BankingAppWrapper from "./BankingAppWrapper";
import DashboardSuspense from "../modules/Suspense/DashboardSuspense";
import DirectorForm from "../modules/Landing/ExternalApplicationForms/DirectorForm/DirectorForm";
import ExternalBusinessForms from "../modules/Landing/ExternalApplicationForms/BusinessForms/Pages";
import {IRootState} from "../redux/rootReducer";
import LandingSuspense from "../modules/Suspense/LandingSuspense";
import PageTransitionProgress from "../components/PageTransitionProgress";
import {Routes} from "./routes.constants";
import SendMoney from "../modules/Dashboard/Payments/Pages/SendMoney";
import {useSelector} from "react-redux";

// const AddMoney = lazy(() => import("../modules/Dashboard/Payments/Pages/AddMoney"));

const AccountDetails = lazy(() => import("../modules/Dashboard/Accounts/Pages/IndividualAccount"));
const AccountOpening = lazy(() => import("../modules/Dashboard/Application/Pages/AccountOpening"));
const AccountOpeningCompanyBase = lazy(() => import("../modules/Landing/CompanyBase/Pages/CompanyBase"));
const AccountSettings = lazy(() => import("../modules/Dashboard/Settings/Pages/AccountSettings"));
const Accounts = lazy(() => import("../modules/Dashboard/Accounts/Pages"));
const Application = lazy(() => import("../modules/Dashboard/Application/Pages/Application"));
const BillPayment = lazy(() => import("../modules/Dashboard/Payments/Pages/BillPayment"));
// const AddMoney = lazy(() => import("../modules/Dashboard/Payments/Pages/AddMoney"));
const Cards = lazy(() => import("../modules/Dashboard/Cards/Pages"));
const ApiAccessKeys = lazy(() => import("../modules/Dashboard/LencoPay/Pages/ApiAccessKeys"));
const Configuration = lazy(() => import("../modules/Dashboard/LencoPay/Pages/Configuration"));
const Terminals = lazy(() => import("../modules/Dashboard/Terminals/Pages"));
const DashboardHome = lazy(() => import("../modules/Dashboard/Home/Pages"));

const EmailVerification = lazy(() => import("../modules/Landing/EmailVerification/Pages/EmailVerification"));
const ErrorPage = lazy(() => import("../modules/404/404Page"));
const ForgotPassword = lazy(() => import("../modules/Landing/ForgotPassword/Pages/ForgotPassword"));

const PendingPayments = lazy(() => import("../modules/Dashboard/Payments/Pages/PendingPayment"));
const People = lazy(() => import("../modules/Dashboard/People/Pages"));
const ReferenceForm = lazy(() => import("../modules/Landing/ExternalApplicationForms/ReferenceForm/Pages/ReferenceForm"));
const ResetForgotPassword = lazy(() => import("../modules/Landing/ResetPassword/Pages/ResetForgotPassword"));
const SavedRecipients = lazy(() => import("../modules/Dashboard/Payments/Pages/SavedRecipient"));
const Security = lazy(() => import("../modules/Dashboard/Settings/Pages/Security"));
const SelectTypeOfBusiness = lazy(() => import("../modules/Dashboard/Application/Pages/SelectTypeOfBusiness"));
// const SendMoney = lazy(() => import("../modules/Dashboard/Payments/Pages/SendMoney"));
const ShareReceipt = lazy(() => import("../modules/Landing/ShareReceipt/Pages/ShareReceipt"));
const AddMoneyHistory = lazy(() => import("../modules/Dashboard/Payments/Components/AddMoney/AddMoneyHistory"));
const SelectBusinessType = lazy(() => import("../modules/Landing/SelectBusinessType/Pages/SelectBusinessType"));
const Signup = lazy(() => import("../modules/Landing/Signup/Pages/Signup"));
const TeamMembers = lazy(() => import("../modules/Dashboard/Settings/Pages/TeamMembers"));
const TransactionsPage = lazy(() => import("../modules/Dashboard/Transactions/Pages"));
const WaitList = lazy(() => import("../modules/Landing/WaitList/Pages/WaitList"));
const ZambiaPayouts = lazy(() => import("../modules/Dashboard/LencoPay/Pages/Payouts"));
const ZambiaCollections = lazy(() => import("../modules/Dashboard/LencoPay/Pages/Collections"));
const ZambiaLencoPayGetStarted = lazy(() => import("../modules/Dashboard/LencoPay/Pages/GetStarted"));

function AppRouter(): JSX.Element {
	const isLogin = useSelector((state: IRootState) => state.init.isLoggedIn);

	return (
		<>
			<PageTransitionProgress />
			<Suspense fallback={isLogin ? <DashboardSuspense /> : <LandingSuspense />}>
				<Switch>
					{/*Routes outside the banking app -> i.e. does not need to be logged in*/}
					<Route path="/signup/account" exact component={Signup} />
					<Route path="/signup" exact component={AccountOpeningCompanyBase} />
					<Route path="/signup/invite/:type/:a/:b" component={AccountOpeningCompanyBase} />
					<Route path="/signup/business-type" exact component={SelectBusinessType} />
					<Route path="/signup/business-type/invite/:type/:a/:b" component={SelectBusinessType} />
					<Route path="/wait-list/:stage" exact component={WaitList} />
					<Route path="/signup/account/invite/:type/:a/:b" component={Signup} />
					<Route path="/verify-email/:a/:b/:c" component={EmailVerification} />
					<Route path="/login" exact component={AccountLogIn} />
					<Route path="/login/invite/:type/:a/:b" component={AccountLogIn} />
					<Route path="/forgot-password" component={ForgotPassword} />
					<Route path="/tr/:a/:b" component={ShareReceipt} />
					<Route path="/reset-password/:a/:b/:c" component={ResetForgotPassword} />
					<Route path="/share/:document/:a/:b" component={ExternalBusinessForms} />
					<Route path="/referee/:a/:b" component={ReferenceForm} />
					<Route path="/director/:a/:b" exact component={DirectorForm} />
					<Route path="/director/:a/:b/:section" exact component={DirectorForm} />
					<Route path="/director/:a/:b/:section/:stage" exact component={DirectorForm} />
					<Route path="/reset-password/:a/:b/:c" component={ResetForgotPassword} />
					<BankingAppWrapper>
						{/*Routes inside the banking app -> i.e. needs to be logged in*/}
						<AppRoute path="/people" component={People} />
						<AppRoute path="/settings/security" component={Security} />
						<AppRoute path="/accounts" exact component={Accounts} />
						<AppRoute path="/lenco-pay/payouts" exact component={ZambiaPayouts} />
						<AppRoute path="/lenco-pay/payouts/search/:query" exact component={ZambiaPayouts} />

						<AppRoute path="/lenco-pay/collections" exact component={ZambiaCollections} />
						<AppRoute path="/lenco-pay/collections/:type" exact component={ZambiaCollections} />
						<AppRoute path="/lenco-pay/collections/:type/search/:query" exact component={ZambiaCollections} />
						<AppRoute path="/payments/add-money/make" exact nonLazyComponent={AddMoney} />
						<AppRoute path="/payments/add-money/history" exact component={AddMoneyHistory} />
						<AppRoute path="/payments/add-money/make/:urlFundingType" exact nonLazyComponent={AddMoney} />
						<AppRoute path="/payments/make" exact nonLazyComponent={SendMoney} />
						<AppRoute path="/payments/make/:type" exact nonLazyComponent={SendMoney} />
						<AppRoute path="/payments/make/:type/:accountId" nonLazyComponent={SendMoney} />
						<AppRoute path="/settings/account/:accountId" component={AccountSettings} />
						<AppRoute path="/settings/account" exact component={AccountSettings} />
						<AppRoute path="/settings/teammembers" component={TeamMembers} />
						<AppRoute path="/application/:section" exact component={Application} />
						<AppRoute path="/application/:section/:stage" exact component={Application} />
						<AppRoute path={Routes.ACCOUNT_OPENING.SET_REGISTRATION_STATUS} component={SelectTypeOfBusiness} />
						<AppRoute path={Routes.ACCOUNT_OPENING.STATUS} component={AccountOpening} />
						<AppRoute path="/transactions" exact component={TransactionsPage} />
						<AppRoute path="/transactions/:type" exact component={TransactionsPage} />
						<AppRoute path="/transactions/:type/:query" component={TransactionsPage} />
						<AppRoute path="/accounts/:accountId" exact component={AccountDetails} />
						<AppRoute path="/payments/pending" exact component={PendingPayments} />
						<AppRoute path="/payments/recipients/:query" exact component={SavedRecipients} />
						<AppRoute path="/payments/recipients" exact component={SavedRecipients} />
						<AppRoute path="/payments/bill/make" component={BillPayment} />
						<AppRoute path="/terminals" exact component={Terminals} />
						<AppRoute path="/cards" exact component={Cards} />
						<AppRoute path={Routes.LENCO_PAY.APIS} exact component={ApiAccessKeys} />
						<AppRoute path={Routes.LENCO_PAY.CONFIGURATION} exact component={Configuration} />
						<AppRoute path={"/lenco-pay/get-started"} exact component={ZambiaLencoPayGetStarted} />

						<AppRoute path={Routes.DASHBOARD} component={DashboardHome} />
						<Redirect from="*" to="/" />
					</BankingAppWrapper>
					{/* This will never be triggered. But leave it */}
					<Route path="*" component={ErrorPage} />
				</Switch>
			</Suspense>
		</>
	);
}

export default AppRouter;
