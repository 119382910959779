import {setTransferBetweenOtp} from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

interface useTransferBetweenOtpInterface {
	handleOtpChange: (_amount: string) => void;
}

function useTransferBetweenOtp(): useTransferBetweenOtpInterface {
	const dispatch = useDispatch();

	const handleOtpChange = useCallback((_otp: string) => {
		dispatch(setTransferBetweenOtp(_otp));
	}, []);

	return {handleOtpChange};
}

export default useTransferBetweenOtp;
