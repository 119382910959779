import {AddCompanyRequest, AddCompanyResponse, DashboardDetailsResponse} from "./homeApi.types";
import {makeRequest, makeRequestWithSignal} from "../../../../helpers/request/makeRequest";

import {Countries} from "../../../../redux/init/slice/initSlice.types";
import {GenericObject} from "../../../../helpers/types";
import LastTransaction from "../../../../models/lastTransaction";
import Parsers from "../../../../utils/parsers";
import Transaction from "../../../../models/transaction";

let controller = new AbortController();

export const dashboardDetails = async (): Promise<DashboardDetailsResponse> => {
	controller = new AbortController();
	const res = await makeRequestWithSignal("/main/dashboard", {}, controller.signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		pending: {
			transactions: res.pending ? Parsers.classObjectArray((res.pending as GenericObject).transactions, Transaction) : [],
			total: res.pending ? Parsers.number((res.pending as GenericObject).total) : 0,
		},
		recent: {
			credit: res.recent ? Parsers.classObjectArray((res.recent as GenericObject).credit, Transaction) : [],
			debit: res.recent ? Parsers.classObjectArray((res.recent as GenericObject).debit, Transaction) : [],
			combined: res.recent ? Parsers.classObjectArray((res.recent as GenericObject).combined, Transaction) : [],
		},
		chart: !res.chart
			? []
			: (res.chart as GenericObject[]).map((item) => ({
					date: Parsers.string(item.date),
					balance: Parsers.number(item.balance),
			  })),
		accountsActivity: !res.accountsActivity
			? []
			: (res.accountsActivity as GenericObject[]).map((item) => ({
					userAccountId: Parsers.string(item.userAccountId),
					balance: Parsers.number(item.balance),
					lastTransaction: Parsers.classObject(item.lastTransaction, LastTransaction),
			  })),
	};
};

export async function addCompany(data: AddCompanyRequest): Promise<AddCompanyResponse> {
	const res = await makeRequest("/accounts/add-company", data);

	if (res instanceof Error) {
		throw res;
	}

	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		company: {
			id: Parsers.string((res.company as GenericObject).id),
			name: Parsers.string((res.company as GenericObject).name),
			accountCreated: Parsers.boolean((res.company as GenericObject).accountCreated),
			country: Parsers.nullableEnum((res.company as GenericObject).country, Countries) || ((res.company as GenericObject).country as Countries),
		},
	};
}

export const abortHomeTransfer = (): void => {
	controller.abort();
};
