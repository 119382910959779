import React, {useEffect} from "react";
import {SendMoneyOptions, ZambiaSendMoneyOptions} from "../SendMoneyDropdown.constants";
import {closeAddFundsModal, openAddFundsModal, openZambiaAddFundsModal} from "../../../../redux/accounts/slice/accountsSlice";
import {useDispatch, useSelector} from "react-redux";

import AddMoneyRow from "./Components/Elements/AddMoneyRow";
import DropdownHead from "../../../General/Dropdown/DropdownComponents/DropdownHead";
import {IRootState} from "../../../../redux/rootReducer";
import {SendMoneyOptionType} from "./sendMoney.types";

function SendMoneyDropdown(): JSX.Element {
	const dispatch = useDispatch();

	const main = useSelector((state: IRootState) => state.init.main);
	const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails?.company.isNigeria);

	const sendMoneyOptions = (isNigeria ? SendMoneyOptions : ZambiaSendMoneyOptions).filter((_option) =>
		(main?.companyDetails.accounts || []).length < 2 ? _option.type !== SendMoneyOptionType.TRANSFER_BETWEEN_ACCOUNTS : _option
	);

	const handleOpenAddFundsModal = () => {
		dispatch(isNigeria ? openAddFundsModal() : openZambiaAddFundsModal());
	};

	useEffect(() => {
		return () => {
			dispatch(closeAddFundsModal());
		};
	}, []);

	return (
		<>
			<DropdownHead
				placeholder={<span className="text-sm text-white subpixel-antialiased">Send Money</span>}
				placement="right"
				size="sm"
				filled
				clickAndClose
				noOutlineBorder
				noOverflow
			>
				{sendMoneyOptions.map((option, index) => {
					return <AddMoneyRow key={index} option={option} onClick={handleOpenAddFundsModal} />;
				})}
			</DropdownHead>
		</>
	);
}

export default SendMoneyDropdown;
