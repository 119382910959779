import "yup-phone";

import * as Yup from "yup";

import {Form, Formik} from "formik";
import {HintType, ListStyle} from "../../../../../Dashboard/Application/components/element/hint/hint.constant";
import {
	NextOfKinForm,
	getNextOfKinFormHints,
} from "../../../../../Dashboard/Application/services/nigeria-application/registered/currentSignatory.types";
import React, {useEffect} from "react";
import {useHistory, useLocation} from "react-router";

import ApplicationHeader from "../../../../../Dashboard/Application/components/element/ApplicationHeader";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CustomSelectWithOther from "../../../../../../components/General/Dropdown/CustomSelectWithOther";
import FormInput from "../../../../../../components/General/Inputs/FormInput";
import Hint from "../../../../../Dashboard/Application/components/element/hint";
import {IRootState} from "../../../../../../redux/rootReducer";
import KinRelationship from "../../../../../../models/meta/kinRelationship";
import {Routes} from "../../../../../../routes/routes.constants";
import SingleSelectDropdown from "../../../../../Dashboard/Settings/Components/DropDowns/TeamMembers/SingleSelectDropdown";
import useExternalDirectorNextOfKin from "../../Hooks/useExternalDirectorNextOfKin";
import {useSelector} from "react-redux";

interface LocationState {
	from?: string;
}

function NextOfKin(): JSX.Element {
	const history = useHistory();
	const location = useLocation<LocationState>();

	const {isExternalDirectorNextOfKinLoading, handleReset, handleUpdateExternalDirectorNextOfKin} = useExternalDirectorNextOfKin();

	const externalLink = useSelector((state: IRootState) => state.externalApplication.externalLink || "");
	const externalParams = useSelector((state: IRootState) => state.externalApplication.externalParams);
	const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

	const director = externalApplicationInit?.director || null;

	const NextOfKinInitialState: NextOfKinForm = {
		name: director?.nextOfKin?.name || "",
		phone: director?.nextOfKin?.phone || "",
		genderId: director?.nextOfKin?.gender || null,
		otherRelationshipText: director?.nextOfKin?.otherRelationshipText || null,
		relationshipId: director?.nextOfKin?.relationship || null,
		address: director?.nextOfKin?.address || "",
	};

	const NextOfKimFormValidation = Yup.object().shape({
		name: Yup.string().required("Required"),
		phone: Yup.string().phone("Invalid Phone Number").required("Required"),
		genderId: Yup.number().nullable().required("Required"),
		relationshipId: Yup.string().nullable(),
		otherRelationshipText: Yup.string().nullable(),
		address: Yup.string().required("Required"),
	});

	useEffect(() => {
		return () => {
			handleReset();
		};
	}, []);

	return (
		<>
			<Formik
				initialValues={{
					...NextOfKinInitialState,
				}}
				validationSchema={NextOfKimFormValidation}
				onSubmit={(values) => {
					if (externalParams) {
						void handleUpdateExternalDirectorNextOfKin(externalParams, values);
					}
				}}
				validateOnMount
				validateOnBlur
			>
				{(formik) => {
					return (
						<Form className="w-full pb-32">
							<div className="flex flex-row justify-start items-start space-x-6 w-full">
								{externalApplicationInit && director && (
									<div className="lg:min-w-60% lg:max-w-3xl w-full">
										<ApplicationHeader
											header="Next of Kin"
											subTitle="We need more information about you"
											progress={externalApplicationInit.director.nextOfKinProgress()}
										>
											<div className="flex items-start lg:items-center flex-col lg:px-0 space-y-4 font-normal text-base">
												<div className="flex w-full flex-col lg:flex-row lg:space-x-4 lg:space-y-0 space-y-4">
													<div className="w-full lg:w-50%">
														<FormInput type="text" placeholder="Full Name" name="name" />
													</div>
													<div className="w-full lg:w-50%">
														<FormInput type="text" placeholder="Phone" name="phone" />
													</div>
												</div>
												<div className="flex w-full flex-col lg:flex-row lg:space-x-4 lg:space-y-0 space-y-4">
													<div className="lg:w-6/12">
														<SingleSelectDropdown
															placeholder="Gender"
															options={externalApplicationInit.options.gender.map((el) => {
																return {
																	text: el.name,
																	value: el.id,
																};
															})}
															onChange={(value) => {
																if (value) {
																	formik.getFieldHelpers("genderId").setValue(value || "");
																}
															}}
															value={formik.values.genderId}
															defaultValue={director.nextOfKin?.gender}
															bigDropdown={false}
															isDisabled={
																!!externalApplicationInit.options.kinRelationship.find(
																	(_rel) => _rel.id === formik.values.relationshipId
																)?.genderId
															}
														/>
													</div>
													<div className="lg:w-6/12">
														<CustomSelectWithOther
															placeholder="Relationship"
															value={Number(formik.values.relationshipId) || undefined}
															otherValue={formik.values.otherRelationshipText || undefined}
															options={externalApplicationInit.options.kinRelationship.map((item: KinRelationship) =>
																item.toDropdownItem()
															)}
															otherOptionValue={null}
															reset={() => {
																formik.getFieldHelpers("relationshipId").setValue(null);
																formik.getFieldHelpers("otherRelationshipText").setValue(null);
															}}
															resetDropdownValue={() => {
																formik.getFieldHelpers("relationshipId").setValue(null);
															}}
															onSelect={(value, otherText) => {
																if (value) {
																	formik
																		.getFieldHelpers("genderId")
																		.setValue(
																			externalApplicationInit.options.kinRelationship.find(
																				(_rel) => _rel.id === value
																			)?.genderId || null
																		);
																}
																formik.getFieldHelpers("relationshipId").setValue(value || null);
																formik.getFieldHelpers("otherRelationshipText").setValue(otherText || null);
															}}
														/>
													</div>
												</div>
												<div className="w-full">
													<FormInput type="textarea" placeholder="Address" name="address" value={formik.values.address} />
												</div>
												<div className="flex flex-col 2xs:flex-row items-center justify-center pt-16 2xs:space-x-4 w-full">
													<div className="w-full 2xs:w-max order-2 2xs:order-1">
														<ButtonComp
															type="button"
															color="grey"
															ripple="light"
															buttonType="secondary"
															func={() =>
																history.push(
																	location?.state?.from === externalLink
																		? externalLink
																		: `${externalLink}${Routes.EXTERNAL_DIRECTOR.PERSONAL_DETAILS.DETAILS}`
																)
															}
															fullWidth
														>
															<span>Back</span>
														</ButtonComp>
													</div>
													<div className="w-full 2xs:w-max order-1 2xs:order-2">
														<ButtonComp
															type="submit"
															color="blue"
															ripple="light"
															buttonType="primary"
															isLoading={isExternalDirectorNextOfKinLoading}
															fullWidth
															disable={
																!!(
																	formik.errors.name ||
																	formik.errors.phone ||
																	formik.errors.address ||
																	formik.errors.genderId
																) ||
																!formik.values.genderId ||
																!!(!formik.values.otherRelationshipText && formik.errors.relationshipId) ||
																!!(!formik.values.otherRelationshipText && !formik.values.relationshipId)
															}
														>
															<span>Continue</span>
														</ButtonComp>
													</div>
												</div>
											</div>
										</ApplicationHeader>
									</div>
								)}
								<Hint
									hintType={HintType.GUIDE}
									listStyle={ListStyle.CHECK}
									firstList={getNextOfKinFormHints(
										Object.keys(formik.values || []),
										[
											...Object.keys(formik.errors || []),
											...(!formik.values.relationshipId && !formik.values.otherRelationshipText ? ["relationshipId"] : []),
											...(!formik.values.genderId ? ["gender"] : []),
										],
										["otherRelationshipText"],
										[{primary: "relationshipId", secondary: "otherRelationshipText"}]
									)}
								/>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default NextOfKin;
