import {AccountInitState, MainInitState} from "../slice/initSlice.types";
import {CompanyInterface, GenericObject} from "../../../helpers/types";
import {makeRequest, makeRequestThrowError} from "../../../helpers/request/makeRequest";

import AccountFaq from "../../../models/faq/accountFaq";
import AccountType from "../../../models/accountType";
import BandData from "../../../models/bandData";
import Bank from "../../../models/bank";
import Card from "../../../models/card";
import Corporate from "../../../models/corporate";
import Parsers from "../../../utils/parsers";
import TransactionCategory from "../../../models/transactionCategory";
import User from "../../../models/user";
import UserAccount from "../../../models/userAccount";
import UserAccountMeta from "../../../models/userAccountMeta";
import UserGroup from "../../../models/userGroup";
import UserMin from "../../../models/userMin";

export async function accountInitWithSignInKey(key: string): Promise<boolean> {
	try {
		await makeRequestThrowError("/accounts/verify-single-access-key", {key});
		return true;
	} catch (err) {
		return false;
	}
}

export async function accountInit(): Promise<AccountInitState> {
	const res = await makeRequest("/accounts/init");
	if (res instanceof Error) {
		throw res;
	}

	const companies: CompanyInterface[] = !("companies" in res) || !Array.isArray(res.companies) ? [] : (res.companies as CompanyInterface[]);
	const selectedCompanyId = Parsers.nullableString(res.selectedCompanyId);

	return {
		user: Parsers.classObjectNonNullable(res.user, UserMin),
		companies: companies,
		// ensure that the selectedCompanyId is part of the companies
		selectedCompanyId: companies.some((c) => c.id === selectedCompanyId) ? selectedCompanyId : null,
	};
}

export async function mainInit(): Promise<MainInitState> {
	const res = await makeRequest("/main/init");
	if (res instanceof Error) {
		throw res;
	}

	const options: GenericObject = (res.options as GenericObject) || {};
	const meta: GenericObject = (res.meta as GenericObject) || {};
	const transferFee: GenericObject = (meta.transferFee as GenericObject) || {};
	const companyDetails: GenericObject = (res.companyDetails as GenericObject) || {};
	const socket: GenericObject = (res.socket as GenericObject) || {};

	return {
		options: {
			banks: Parsers.classObjectArray(options.banks, Bank),
		},
		companyDetails: {
			user: Parsers.classObjectNonNullable(companyDetails.user, User),
			activePrepaidCards: Parsers.classObjectArray(companyDetails.activePrepaidCards, Card),
			userAccountsMeta: Parsers.classObjectArray(companyDetails.userAccountsMeta, UserAccountMeta),
			company: Parsers.classObjectNonNullable(companyDetails.company, Corporate),
			accounts: Parsers.classObjectArray(companyDetails.accounts, UserAccount),
			cards: Parsers.classObjectArray(companyDetails.cards, Card),
			userGroups: Parsers.classObjectArray(companyDetails.userGroups, UserGroup),
			transactionCategories: Parsers.classObjectArray(companyDetails.transactionCategories, TransactionCategory),
		},
		meta: {
			accountTypes: Parsers.classObjectArray(meta.accountTypes, AccountType),
			addMoneyBankAccount: meta.addMoneyBankAccount
				? {
						accountName: Parsers.string((meta.addMoneyBankAccount as GenericObject).accountName),
						accountNumber: Parsers.string((meta.addMoneyBankAccount as GenericObject).accountNumber),
						bank: Parsers.string((meta.addMoneyBankAccount as GenericObject).bank),
						branch: Parsers.string((meta.addMoneyBankAccount as GenericObject).branch),
						swiftCode: Parsers.string((meta.addMoneyBankAccount as GenericObject).swiftCode),
				  }
				: null,
			api: {
				isActive: Parsers.boolean((meta.api as GenericObject).isActive),
				virtualAccounts: Parsers.boolean((meta.api as GenericObject).virtualAccounts),
			},
			card: {
				hasMadeRequest: Parsers.boolean((meta.card as GenericObject).hasMadeRequest),
				ngnPhysical: {
					issuanceFee: Parsers.number(((meta.card as GenericObject).ngnPhysical as GenericObject).issuanceFee),
					monthlyMaintenance: Parsers.number(((meta.card as GenericObject).ngnPhysical as GenericObject).monthlyMaintenance),
					shouldChargeMonthlyMaintenance: Parsers.boolean(
						((meta.card as GenericObject).ngnPhysical as GenericObject).shouldChargeMonthlyMaintenance
					),
					minimumBalanceToRequest: Parsers.number(((meta.card as GenericObject).ngnPhysical as GenericObject).minimumBalanceToRequest),
				},
			},
			cardCreationCharges: {
				1: Parsers.number((meta.cardCreationCharges as GenericObject)[1]),
			},
			faqs: Parsers.classObjectArray(meta.faqs, AccountFaq),
			lencoPay: {
				isEnabled: Parsers.boolean((meta.lencoPay as GenericObject).isEnabled),
			},
			transferCharges: {
				1: Parsers.number((meta.transferCharges as GenericObject)[1]),
				2: Parsers.number((meta.transferCharges as GenericObject)[2]),
				3: Parsers.number((meta.transferCharges as GenericObject)[3]),
			},
			transferFee: {
				ng: transferFee.ng
					? {
							1: Parsers.number((transferFee.ng as GenericObject)[1]),
							2: Parsers.number((transferFee.ng as GenericObject)[2]),
							3: Parsers.number((transferFee.ng as GenericObject)[3]),
					  }
					: null,
				zm: transferFee.zm
					? {
							bankAccount: {
								bandData: Parsers.classObjectArray(
									((transferFee.zm as GenericObject).bankAccount as GenericObject).bandData,
									BandData
								),
								maxAmount: Parsers.number(((transferFee.zm as GenericObject).bankAccount as GenericObject).maxAmount),
								minAmount: Parsers.number(((transferFee.zm as GenericObject).bankAccount as GenericObject).minAmount),
							},
							lencoMerchant: {
								bandData: Parsers.classObjectArray(
									((transferFee.zm as GenericObject).lencoMerchant as GenericObject).bandData,
									BandData
								),
								maxAmount: Parsers.number(((transferFee.zm as GenericObject).lencoMerchant as GenericObject).maxAmount),
								minAmount: Parsers.number(((transferFee.zm as GenericObject).lencoMerchant as GenericObject).minAmount),
							},
							lencoMoney: {
								bandData: Parsers.classObjectArray(
									((transferFee.zm as GenericObject).lencoMoney as GenericObject).bandData,
									BandData
								),
								maxAmount: Parsers.number(((transferFee.zm as GenericObject).lencoMoney as GenericObject).maxAmount),
								minAmount: Parsers.number(((transferFee.zm as GenericObject).lencoMoney as GenericObject).minAmount),
							},
							mobileMoney: {
								bandData: Parsers.classObjectArray(
									((transferFee.zm as GenericObject).mobileMoney as GenericObject).bandData,
									BandData
								),
								maxAmount: Parsers.number(((transferFee.zm as GenericObject).mobileMoney as GenericObject).maxAmount),
								minAmount: Parsers.number(((transferFee.zm as GenericObject).mobileMoney as GenericObject).minAmount),
							},
					  }
					: null,
			},
		},
		socket: {
			token: res.socket ? Parsers.string(socket.token) : "",
			url: res.socket ? Parsers.string(socket.url) : "",
			secure: res.socket ? Parsers.boolean(socket.secure) : true,
		},
	};
}

export async function logout(): Promise<void> {
	await makeRequest("/accounts/logout");
}
