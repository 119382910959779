import {
	setCanVerifyRecipientAccountDetails,
	setTransferDetailCardRecipient,
	setTransferDetailCustomerAccountRecipient,
	setTransferDetailRecipientAccountNumber,
	setTransferDetailUserAccountRecipient,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import BankAccount from "../../../../../../../models/bankAccount";
import Card from "../../../../../../../models/card";
import CustomerAccount from "../../../../../../../models/customerAccount";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {RequestCancelledError} from "../../../../../../../helpers/request/requestErrors";
import UserAccount from "../../../../../../../models/userAccount";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import {recipientAutocomplete} from "../../../../Services/SendMoney/singleTransferApi";

interface AccountAutoCompleteDetailsInterface {
	query: string;
	includeUserAccounts: boolean;
}

interface RecipientAutocompleteResponse {
	success: boolean;
	message: string;
	userAccounts: Array<UserAccount>;
	customerAccounts: Array<CustomerAccount>;
}

interface UseBulkTransferAccountNumberAutocompleteInterface {
	suggestedRecipients: (UserAccount | CustomerAccount)[] | null;
	accountAutoCompleteDetails: AccountAutoCompleteDetailsInterface;
	recipientAutocompleteResponse: RecipientAutocompleteResponse | null;
	isRecipientAutocompleteLoading: boolean;

	handleSelectRecipient: (_bankAccount: BankAccount) => void;
	handleAccountNumberChange: (_accountNumber: string) => void;
	handleSelectCardRecipient: (_card: Card) => void;
	handleSelectUserAccountRecipient: (_userAccount: UserAccount) => void;
	handleSelectCustomerAccountRecipient: (_customerAccount: CustomerAccount) => void;
}

interface Props {
	handleSetVerifiedAccount: (_bankAccount: BankAccount) => void;
	handleResetVerifiedAccount: () => void;
}

function useBulkTransferAccountNumberAutocomplete({
	handleSetVerifiedAccount,
	handleResetVerifiedAccount,
}: Props): UseBulkTransferAccountNumberAutocompleteInterface {
	const dispatch = useDispatch();

	const customerAccountList = useSelector<IRootState, Map<string, CustomerAccount>>((state) => state.customerAccount.customerAccounts);
	const originatingAccountId = useSelector((state: IRootState) => state.bulkTransfer.transferDetail.originatingAccountId);
	const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);

	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

	const [accountAutoCompleteDetails, setAccountAutoCompleteDetails] = useState<AccountAutoCompleteDetailsInterface>({
		query: "",
		includeUserAccounts: true,
	});
	const [isRecipientAutocompleteLoading, setIsRecipientAutocompleteLoading] = useState(false);
	const [recipientAutocompleteResponse, setRecipientAutocompleteResponse] = useState<RecipientAutocompleteResponse | null>(null);
	const [suggestedRecipients, setSuggestedRecipients] = useState<(UserAccount | CustomerAccount)[] | null>(null);

	useEffect(() => {
		const {query} = accountAutoCompleteDetails;
		if (query.length < 3) return;
		void handleRecipientAutoComplete(accountAutoCompleteDetails);
	}, [accountAutoCompleteDetails]);

	useEffect(() => {
		if (!accounts) return;
		const doesAccountExist = accounts.some((el) => el.id === originatingAccountId);
		if (!doesAccountExist && !recipientAutocompleteResponse) return;
		if (doesAccountExist) {
			const userAccount = accounts.find((el) => el.id === originatingAccountId) as UserAccount;
			if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) {
				if (userAccount.accountRestrictions.customerAccountIds.length > 0) {
					const customerAccounts = userAccount.accountRestrictions.customerAccountIds.map((el) =>
						customerAccountList.get(el)
					) as CustomerAccount[];
					return setSuggestedRecipients([...userAccount.accountRestrictions.userAccounts, ...customerAccounts]);
				}
				return setSuggestedRecipients([...userAccount.accountRestrictions.userAccounts]);
			}
			if (recipientAutocompleteResponse) {
				return setSuggestedRecipients([
					...recipientAutocompleteResponse.customerAccounts.filter(
						(el) => el.bankAccount && el.bankAccount.id !== userAccount.bankAccount?.id
					),
					...recipientAutocompleteResponse.userAccounts.filter((el) => el.bankAccount && el.bankAccount.id !== userAccount.bankAccount?.id),
				]);
			}
		} else if (recipientAutocompleteResponse) {
			return setSuggestedRecipients([...recipientAutocompleteResponse.customerAccounts, ...recipientAutocompleteResponse.userAccounts]);
		}
	}, [recipientAutocompleteResponse, accounts, originatingAccountId, customerAccountList]);

	useEffect(() => {
		if (!payTo || !customerAccountList) return;
		const recipient = customerAccountList.get(payTo);
		if (!isNullOrUndefined(recipient)) {
			dispatch(setCanVerifyRecipientAccountDetails(false));
			// dispatch(setTransferDetailRecipient(recipient.bankAccount as BankAccount));
			if (recipient.bankAccount) {
				dispatch(setTransferDetailCustomerAccountRecipient(recipient.bankAccount));
			}
			handleSetVerifiedAccount(recipient.bankAccount as BankAccount);
		}
	}, [payTo, customerAccountList]);

	const handleRecipientAutoComplete = useCallback(
		async (_data) => {
			try {
				setIsRecipientAutocompleteLoading(true);
				const res = await recipientAutocomplete(_data);
				setRecipientAutocompleteResponse(res);
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}
				const errorMessage = getErrorMessage(err);
				dispatch(errorTrue({message: errorMessage}));
			}
			setIsRecipientAutocompleteLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const handleAccountNumberChange = useCallback((_accountNumber: string) => {
		setAccountAutoCompleteDetails((prev): AccountAutoCompleteDetailsInterface => {
			return {
				...prev,
				query: _accountNumber,
			};
		});
		dispatch(setTransferDetailRecipientAccountNumber(_accountNumber));
		dispatch(setCanVerifyRecipientAccountDetails(true));
		handleResetVerifiedAccount();
	}, []);

	const handleSelectRecipient = useCallback((_bankAccount: BankAccount) => {
		dispatch(setCanVerifyRecipientAccountDetails(false));
		// dispatch(setTransferDetailRecipient(_bankAccount));
		handleSetVerifiedAccount(_bankAccount);
	}, []);

	const handleSelectUserAccountRecipient = useCallback((_userAccount: UserAccount) => {
		dispatch(setCanVerifyRecipientAccountDetails(false));
		dispatch(setTransferDetailUserAccountRecipient(_userAccount.id));
		if (_userAccount.bankAccount) {
			handleSetVerifiedAccount(_userAccount.bankAccount);
		}
	}, []);
	const handleSelectCustomerAccountRecipient = useCallback((_customerAccount: CustomerAccount) => {
		dispatch(setCanVerifyRecipientAccountDetails(false));
		if (_customerAccount.bankAccount) {
			dispatch(setTransferDetailCustomerAccountRecipient(_customerAccount.bankAccount));
			handleSetVerifiedAccount(_customerAccount.bankAccount);
		}
	}, []);
	const handleSelectCardRecipient = useCallback((_card: Card) => {
		dispatch(setCanVerifyRecipientAccountDetails(false));
		if (_card) {
			dispatch(setTransferDetailCardRecipient(_card.id));
			// handleSetVerifiedAccount(_card.);
		}
	}, []);

	return {
		suggestedRecipients,
		accountAutoCompleteDetails,
		recipientAutocompleteResponse,
		isRecipientAutocompleteLoading,

		handleSelectRecipient,
		handleSelectCardRecipient,
		handleAccountNumberChange,
		handleSelectUserAccountRecipient,
		handleSelectCustomerAccountRecipient,
	};
}

export default useBulkTransferAccountNumberAutocomplete;
