import "./index.css";
// import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import React, {useEffect} from "react";

import AppRouter from "./routes/AppRouter";
import IntercomHelper from "./helpers/intercom";

function App(): JSX.Element {
	useEffect(() => {
		IntercomHelper.initialize();
	}, []);

	return <AppRouter />;
}

export default App;
