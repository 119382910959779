import React, {useEffect, useLayoutEffect, useState} from "react";
import {ZambiaAddMoneyModalSections, ZambiaFundingMethod} from "../../../../../../redux/accounts/slice/accountsSlice.types";
import {useDispatch, useSelector} from "react-redux";

import BankTransfer from "./Components/BankTransfer";
// import CardPayment from "./Components/CardPayment";
import {IRootState} from "../../../../../../redux/rootReducer";
import MobileMoney from "./Components/MobileMoney";
import SelectAccountAndMethod from "./Components/SelectAccountAndMethod";
import {closeZambiaAddFundsModal} from "../../../../../../redux/accounts/slice/accountsSlice";
import doesUserHaveAccessToAccount from "../../../../../../helpers/doesUserHaveAccessToAccount";

function ZambiaAddFundsModal(): JSX.Element {
	const dispatch = useDispatch();

	const [paymentMethod, setPaymentMethod] = useState<ZambiaFundingMethod | null>(null);
	const [addMoneyStage, setAddMoneyStage] = useState<ZambiaAddMoneyModalSections>(ZambiaAddMoneyModalSections.ACCOUNT_AND_METHOD);
	const [selectedAccountId, setSelectedAccountId] = useState<string>("");

	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);

	useLayoutEffect(() => {
		if (!accounts) return;
		if (accounts.length < 2) {
			if (doesUserHaveAccessToAccount(accounts[0].id)) {
				setSelectedAccountId(accounts[0].id);
				// setAddMoneyStage(ZambiaAddMoneyModalSections.FUNDING);
			}
		}
	}, [accounts]);

	useEffect(() => {
		return () => {
			setAddMoneyStage(ZambiaAddMoneyModalSections.ACCOUNT_AND_METHOD);
			setSelectedAccountId("");
		};
	}, []);

	return (
		<>
			{addMoneyStage === ZambiaAddMoneyModalSections.ACCOUNT_AND_METHOD && (
				<SelectAccountAndMethod
					paymentMethod={paymentMethod}
					selectedAccountId={selectedAccountId}
					handleNext={() => setAddMoneyStage(ZambiaAddMoneyModalSections.FUNDING)}
					handleClose={() => dispatch(closeZambiaAddFundsModal())}
					handleSelectAccount={(_value) => setSelectedAccountId(_value)}
					handleSelectPaymentMethod={(_value) => setPaymentMethod(_value)}
				/>
			)}
			{addMoneyStage === ZambiaAddMoneyModalSections.FUNDING && (
				<>
					{paymentMethod === ZambiaFundingMethod.BANK_TRANSFER && (
						<BankTransfer
							selectedAccountId={selectedAccountId}
							handleBack={() => setAddMoneyStage(ZambiaAddMoneyModalSections.ACCOUNT_AND_METHOD)}
							handleDone={() => dispatch(closeZambiaAddFundsModal())}
							handleClose={() => dispatch(closeZambiaAddFundsModal())}
						/>
					)}
					{/* {paymentMethod === ZambiaFundingMethod.CARD_PAYMENT && (
						<CardPayment
							handleBack={() => setAddMoneyStage(ZambiaAddMoneyModalSections.ACCOUNT_AND_METHOD)}
							handleDone={() => dispatch(closeZambiaAddFundsModal())}
						/>
					)} */}
					{paymentMethod === ZambiaFundingMethod.MOBILE_MONEY && (
						<MobileMoney
							selectedAccountId={selectedAccountId}
							handleBack={() => setAddMoneyStage(ZambiaAddMoneyModalSections.ACCOUNT_AND_METHOD)}
							handleDone={() => dispatch(closeZambiaAddFundsModal())}
							handleClose={() => dispatch(closeZambiaAddFundsModal())}
						/>
					)}
				</>
			)}
		</>
	);
}

export default ZambiaAddFundsModal;
