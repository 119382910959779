import {AddMoneyCollectionRequestFilterTypes, AddMoneyCollectionRequestState} from "./addMoneyCollectionRequestSlice.types";
import {FilterDateType, FilterDateTypes} from "../../../../../components/General/Filter/filter.constant";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {ZambiaCollectionPaymentChannel, ZambiaCollectionStatus} from "../../../../../models/zambia/zambiaCollectionRequest.constant";

import ZambiaCollectionRequest from "../../../../../models/zambia/zambiaCollectionRequest";

const initialState: AddMoneyCollectionRequestState = {
	addMoneyCollectionRequestList: null,
	addMoneyCollectionRequestDetail: null,

	isCustomModalOpen: false,
	isAddMoneyCollectionRequestListEmpty: false,
	isExportAddMoneyCollectionRequestLoading: false,
	isAddMoneyCollectionRequestDetailCardOpen: false,
	isAddMoneyCollectionRequestPaginationLoading: false,

	addMoneyCollectionRequestListTotal: 0,
	addMoneyCollectionRequestListGroupSize: 0,
	addMoneyCollectionRequestPaginationOffset: 0,

	addMoneyCollectionRequestSearchQuery: "",

	addMoneyCollectionRequestDate: FilterDateTypes.ALL_TIME,
	selectedAddMoneyCollectionRequestDate: FilterDateTypes.ALL_TIME,

	addMoneyCollectionRequestFilterState: {
		offset: 0,
		query: "",
		status: [],
		channel: [],
		date: {
			end: "",
			begin: "",
		},
	},
	selectedAddMoneyCollectionRequestFilterState: {
		offset: 0,
		query: "",
		status: [],
		channel: [],
		date: {
			end: "",
			begin: "",
		},
	},
};

export const addMoneyCollectionRequestSlice = createSlice({
	name: "addMoneyCollectionRequest",
	initialState,
	reducers: {
		setSelectedAddMoneyCollectionRequestDetails: (state: AddMoneyCollectionRequestState, action: PayloadAction<string>) => {
			const collectionRequest = state.addMoneyCollectionRequestList?.find((_sub) => _sub.id === action.payload);
			if (collectionRequest) {
				state.addMoneyCollectionRequestDetail = collectionRequest;
			}
		},
		setIsAddMoneyCollectionRequestDetailCardOpen: (state: AddMoneyCollectionRequestState, action: PayloadAction<boolean>) => {
			state.isAddMoneyCollectionRequestDetailCardOpen = action.payload;
		},

		setIsAddMoneyDateModalOpen: (state: AddMoneyCollectionRequestState, action: PayloadAction<boolean>) => {
			state.isCustomModalOpen = action.payload;
		},
		setIsExportAddMoneyCollectionRequestLoading: (state: AddMoneyCollectionRequestState, action: PayloadAction<boolean>) => {
			state.isExportAddMoneyCollectionRequestLoading = action.payload;
		},

		setIsAddMoneyCollectionRequestPaginationLoading: (state: AddMoneyCollectionRequestState, action: PayloadAction<boolean>) => {
			state.isAddMoneyCollectionRequestPaginationLoading = action.payload;
		},
		setIsAddMoneyCollectionRequestListEmpty: (state: AddMoneyCollectionRequestState, action: PayloadAction<boolean>) => {
			state.isAddMoneyCollectionRequestListEmpty = action.payload;
		},
		setAddMoneyCollectionRequestDate: (state: AddMoneyCollectionRequestState, action: PayloadAction<FilterDateType>) => {
			if (action.payload.date.begin && action.payload.date.end) {
				state.addMoneyCollectionRequestFilterState = {
					...state.addMoneyCollectionRequestFilterState,
					date: {
						begin: action.payload.date.begin,
						end: action.payload.date.end,
					},
				};
				state.addMoneyCollectionRequestDate = action.payload.name;
			}
		},
		setAddMoneyCollectionRequestList: (state: AddMoneyCollectionRequestState, action: PayloadAction<ZambiaCollectionRequest[]>) => {
			state.addMoneyCollectionRequestList = action.payload;
		},
		setAddMoneyCollectionRequestListTotal: (state: AddMoneyCollectionRequestState, action: PayloadAction<number>) => {
			state.addMoneyCollectionRequestListTotal = action.payload;
		},
		setAddMoneyCollectionRequestPaginationOffset: (state: AddMoneyCollectionRequestState, action: PayloadAction<number>) => {
			state.addMoneyCollectionRequestPaginationOffset = action.payload;
		},
		setAddMoneyCollectionRequestListGroupSize: (state: AddMoneyCollectionRequestState, action: PayloadAction<number>) => {
			state.addMoneyCollectionRequestListGroupSize = action.payload;
		},
		setAddMoneyCollectionRequestFilterState: (
			state: AddMoneyCollectionRequestState,
			action: PayloadAction<AddMoneyCollectionRequestFilterTypes>
		) => {
			state.addMoneyCollectionRequestFilterState = {...state.addMoneyCollectionRequestFilterState, ...action.payload};
		},
		setAddMoneyCollectionRequestDateFilter: (state: AddMoneyCollectionRequestState) => {
			state.selectedAddMoneyCollectionRequestFilterState = {
				...state.selectedAddMoneyCollectionRequestFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.addMoneyCollectionRequestFilterState = {
				...state.addMoneyCollectionRequestFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedAddMoneyCollectionRequestDate = FilterDateTypes.ALL_TIME;
			state.addMoneyCollectionRequestDate = FilterDateTypes.ALL_TIME;
		},
		setSelectedTerminalTransactionFilterState: (state: AddMoneyCollectionRequestState) => {
			state.selectedAddMoneyCollectionRequestFilterState = {...state.addMoneyCollectionRequestFilterState};
			state.selectedAddMoneyCollectionRequestDate = state.addMoneyCollectionRequestDate;
		},
		setSelectedTerminalTransactionFilterStateInstant: (
			state: AddMoneyCollectionRequestState,
			action: PayloadAction<AddMoneyCollectionRequestFilterTypes>
		) => {
			state.selectedAddMoneyCollectionRequestFilterState = {...state.selectedAddMoneyCollectionRequestFilterState, ...action.payload};
			state.addMoneyCollectionRequestFilterState = {...state.addMoneyCollectionRequestFilterState, ...action.payload};
		},

		setAddMoneyCollectionRequestChannelFilter: (state: AddMoneyCollectionRequestState, action: PayloadAction<ZambiaCollectionPaymentChannel>) => {
			state.selectedAddMoneyCollectionRequestFilterState.channel = state.selectedAddMoneyCollectionRequestFilterState.channel.filter(
				(_types) => _types !== action.payload
			);
			state.addMoneyCollectionRequestFilterState.channel = state.addMoneyCollectionRequestFilterState.channel.filter(
				(_types) => _types !== action.payload
			);
		},
		setAddMoneyCollectionRequestStatusFilter: (state: AddMoneyCollectionRequestState, action: PayloadAction<ZambiaCollectionStatus>) => {
			state.selectedAddMoneyCollectionRequestFilterState.status = state.selectedAddMoneyCollectionRequestFilterState.status.filter(
				(_status) => _status !== action.payload
			);
			state.addMoneyCollectionRequestFilterState.status = state.addMoneyCollectionRequestFilterState.status.filter(
				(_status) => _status !== action.payload
			);
		},

		resetAddMoneyCollectionRequestList: (state: AddMoneyCollectionRequestState) => {
			state.addMoneyCollectionRequestList = null;
		},
		resetAddMoneyCollectionRequestDateFilterState: (state: AddMoneyCollectionRequestState) => {
			state.selectedAddMoneyCollectionRequestFilterState = {
				...state.selectedAddMoneyCollectionRequestFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.addMoneyCollectionRequestFilterState = {
				...state.addMoneyCollectionRequestFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedAddMoneyCollectionRequestDate = FilterDateTypes.ALL_TIME;
			state.addMoneyCollectionRequestDate = FilterDateTypes.ALL_TIME;
		},
		resetAddMoneyCollectionRequestStatusFilterState: (state: AddMoneyCollectionRequestState) => {
			state.selectedAddMoneyCollectionRequestFilterState = {...state.selectedAddMoneyCollectionRequestFilterState, status: []};
			state.addMoneyCollectionRequestFilterState = {...state.addMoneyCollectionRequestFilterState, status: []};
		},
		resetAddMoneyCollectionRequestChannelFilterState: (state: AddMoneyCollectionRequestState) => {
			state.selectedAddMoneyCollectionRequestFilterState = {...state.selectedAddMoneyCollectionRequestFilterState, channel: []};
			state.addMoneyCollectionRequestFilterState = {...state.addMoneyCollectionRequestFilterState, channel: []};
		},
		resetAddMoneyCollectionRequestQueryFilterState: (state: AddMoneyCollectionRequestState) => {
			state.selectedAddMoneyCollectionRequestFilterState = {...state.selectedAddMoneyCollectionRequestFilterState, query: ""};
			state.addMoneyCollectionRequestFilterState = {...state.addMoneyCollectionRequestFilterState, query: ""};
		},
		resetAddMoneyCollectionRequestFilterState: (state: AddMoneyCollectionRequestState) => {
			state.addMoneyCollectionRequestFilterState = {
				query: "",
				offset: 0,
				date: {
					begin: "",
					end: "",
				},
				channel: [],
				status: [],
			};
			state.selectedAddMoneyCollectionRequestFilterState = {
				query: "",
				offset: 0,
				date: {
					begin: "",
					end: "",
				},
				channel: [],
				status: [],
			};
		},
		resetAllAddMoneyCollectionRequestData: (state: AddMoneyCollectionRequestState) => {
			state.addMoneyCollectionRequestDetail = null;
			state.isAddMoneyCollectionRequestDetailCardOpen = false;
			state.addMoneyCollectionRequestList = null;
			state.addMoneyCollectionRequestListTotal = 0;
			state.addMoneyCollectionRequestPaginationOffset = 0;
			state.addMoneyCollectionRequestListGroupSize = 0;
			state.isExportAddMoneyCollectionRequestLoading = false;
			state.isAddMoneyCollectionRequestListEmpty = false;
			state.addMoneyCollectionRequestSearchQuery = "";
			state.isAddMoneyCollectionRequestPaginationLoading = false;
			state.addMoneyCollectionRequestDate = FilterDateTypes.ALL_TIME;
			state.selectedAddMoneyCollectionRequestDate = FilterDateTypes.ALL_TIME;
			state.isCustomModalOpen = false;
			state.addMoneyCollectionRequestFilterState = {
				query: "",
				status: [],
				offset: 0,
				channel: [],
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedAddMoneyCollectionRequestFilterState = {
				query: "",
				offset: 0,
				status: [],
				channel: [],
				date: {
					begin: "",
					end: "",
				},
			};
		},
	},
});

export const {
	setIsAddMoneyDateModalOpen,
	setAddMoneyCollectionRequestDate,
	setAddMoneyCollectionRequestList,
	setAddMoneyCollectionRequestListTotal,
	setAddMoneyCollectionRequestDateFilter,
	setAddMoneyCollectionRequestChannelFilter,
	setAddMoneyCollectionRequestFilterState,
	setIsAddMoneyCollectionRequestListEmpty,
	setAddMoneyCollectionRequestListGroupSize,
	setSelectedTerminalTransactionFilterState,
	setAddMoneyCollectionRequestStatusFilter,
	setIsExportAddMoneyCollectionRequestLoading,
	setSelectedAddMoneyCollectionRequestDetails,
	setIsAddMoneyCollectionRequestDetailCardOpen,
	setAddMoneyCollectionRequestPaginationOffset,
	setIsAddMoneyCollectionRequestPaginationLoading,
	setSelectedTerminalTransactionFilterStateInstant,

	resetAddMoneyCollectionRequestList,
	resetAllAddMoneyCollectionRequestData,
	resetAddMoneyCollectionRequestFilterState,
	resetAddMoneyCollectionRequestDateFilterState,
	resetAddMoneyCollectionRequestQueryFilterState,
	resetAddMoneyCollectionRequestChannelFilterState,
	resetAddMoneyCollectionRequestStatusFilterState,
} = addMoneyCollectionRequestSlice.actions;

export default addMoneyCollectionRequestSlice.reducer;
