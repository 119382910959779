import {setFundCardPurpose} from "../../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

interface useFundCardPurposeInterface {
	handlePurposeChange: (_purpose: string) => void;
}

function useFundCardPurpose(): useFundCardPurposeInterface {
	const dispatch = useDispatch();

	const handlePurposeChange = useCallback((_purpose: string) => {
		dispatch(setFundCardPurpose(_purpose));
	}, []);

	return {
		handlePurposeChange,
	};
}

export default useFundCardPurpose;
