import {IRootState} from "../../../../../../redux/rootReducer";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {ReactComponent as ProgressBarFour} from "../../../../../../assets/images/Dashboard/SendMoney/TransferProgressBarFour.svg";
import {ReactComponent as ProgressBarOne} from "../../../../../../assets/images/Dashboard/SendMoney/TransferProgressBarOne.svg";
import {ReactComponent as ProgressBarThree} from "../../../../../../assets/images/Dashboard/SendMoney/TransferProgressBarThree.svg";
import {ReactComponent as ProgressBarTwo} from "../../../../../../assets/images/Dashboard/SendMoney/TransferProgressBarTwo.svg";
import React from "react";
import {useSelector} from "react-redux";

interface SendMoneyLayoutInterface {
	children: React.ReactNode;
}

function SendMoneyLayout({children}: SendMoneyLayoutInterface): JSX.Element {
	const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);
	return (
		<>
			<div className="flex h-full w-full flex-col">
				<div className="flex flex-col items-start justify-start border-b-0.2 border-grey py-4">
					<span className="text-base font-medium  text-black md:text-lg">Payments</span>
					<span className="text-xs font-normal  text-black-tertiary md:text-sm">Send Money using your Lenco accounts</span>
				</div>
				<div className="flex h-full w-full flex-col lg:flex-row">
					{/* <=== Start of Stage Level Component ===> */}

					{paymentStage !== PaymentStageType.PROCESS_PAYMENT && (
						<div className="hidden h-full w-min flex-row items-start justify-start space-x-5 pr-20 pt-8 lg:flex  ">
							<div className="flex w-max  items-end justify-end">
								<div className="pt-1">
									{paymentStage === PaymentStageType.INITIAL ? (
										<ProgressBarOne />
									) : paymentStage === PaymentStageType.PAYMENT_DETAILS ? (
										<ProgressBarTwo />
									) : paymentStage === PaymentStageType.REVIEW_PAYMENT ? (
										<ProgressBarThree />
									) : (
										<ProgressBarFour />
									)}
								</div>
							</div>
							<div className="mt-0.5 flex w-max flex-col space-y-4.5 text-sm">
								<span
									className={
										` ` +
										`${paymentStage === PaymentStageType.INITIAL ? "text-blue" : " "} ` +
										`${paymentStage > PaymentStageType.INITIAL ? "text-black-secondary " : " "} `
									}
								>
									Type of transfer
								</span>
								<span
									className={
										` ` +
										`${paymentStage === PaymentStageType.PAYMENT_DETAILS ? "text-blue" : " "} ` +
										`${paymentStage > PaymentStageType.PAYMENT_DETAILS ? "text-black-secondary " : " "} ` +
										`${paymentStage < PaymentStageType.PAYMENT_DETAILS ? "text-black-tertiary " : " "} `
									}
								>
									Payment details
								</span>
								<span
									className={
										` ` +
										`${paymentStage === PaymentStageType.REVIEW_PAYMENT ? "text-blue" : " "} ` +
										`${paymentStage > PaymentStageType.REVIEW_PAYMENT ? "text-black-secondary " : " "} ` +
										`${paymentStage < PaymentStageType.REVIEW_PAYMENT ? "text-black-tertiary " : " "} `
									}
								>
									Review Payment
								</span>
								<span
									className={
										` ` +
										`${paymentStage === PaymentStageType.MAKE_PAYMENT ? "text-blue" : " "} ` +
										`${paymentStage > PaymentStageType.MAKE_PAYMENT ? "text-black-secondary " : " "} ` +
										`${paymentStage < PaymentStageType.MAKE_PAYMENT ? "text-black-tertiary " : " "} `
									}
								>
									Make Payment
								</span>
							</div>
						</div>
					)}

					{/* <=== End of Stage Level Component ===> */}
					{children}
				</div>
			</div>
		</>
	);
}

export default SendMoneyLayout;
