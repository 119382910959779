import React, {useEffect, useState} from "react";

import FullPageTrayFooter from "../../../../../../../components/General/Trays/FullPageTray/FullPageTrayFooter";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {PENDING_APPROVAL} from "../../../../../../../helpers/AppConstants";
import StandAloneCancelPayment from "../../../../../Payments/Components/Elements/CancelPayment/StandAloneCancelPayment";
import Transaction from "../../../../../../../models/transaction";
import {useSelector} from "react-redux";

interface TransactionsDetailCardCancelApprovalActionsInterface {
	dataType: string;
	transaction: Transaction;
}

function TransactionsDetailCardCancelApprovalActions({dataType, transaction}: TransactionsDetailCardCancelApprovalActionsInterface): JSX.Element {
	const userAccountMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);

	const [transactionDetail, setTransactionDetail] = useState<Transaction | null>(null);

	const currentUserAccountMeta = userAccountMeta?.find((_el) => _el.userAccountId === transaction?.origination?.userAccount?.id);
	const isTransactionInitiator = !!currentUserAccountMeta?.isInitiator && !currentUserAccountMeta?.isApprover;

	useEffect(() => {
		if (!transaction) return;
		setTransactionDetail(transaction);
	}, [transaction]);

	return (
		<>
			{transactionDetail &&
				isTransactionInitiator &&
				transactionDetail.status === PENDING_APPROVAL &&
				transactionDetail.destination &&
				(transactionDetail.destination.customerAccount ||
					transactionDetail.destination.userAccount ||
					transactionDetail.destination.card) && (
					<>
						<FullPageTrayFooter dataType={dataType}>
							<div className="flex w-full items-center justify-center text-black-tertiary" data-type={dataType}>
								<StandAloneCancelPayment transaction={transactionDetail} />
							</div>
						</FullPageTrayFooter>
					</>
				)}
		</>
	);
}

export default TransactionsDetailCardCancelApprovalActions;
