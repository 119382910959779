import Parsers from "../utils/parsers";
import {GenericObject} from "../helpers/types";
import {immerable} from "immer";

export default class UserGroup {
	[immerable] = true;

	constructor(public id: string, public name: string, public shortCode: string, public isStaff: boolean, public isDeleted: boolean) {}

	static create(obj: GenericObject): UserGroup {
		return new UserGroup(
			Parsers.string(obj.uid),
			Parsers.string(obj.name),
			Parsers.string(obj.shortCode),
			Parsers.boolean(obj.isStaff),
			Parsers.boolean(obj.isDeleted)
		);
	}

	update(userGroup: UserGroup): void {
		this.name = userGroup.name;
		this.shortCode = userGroup.shortCode;
		this.isStaff = userGroup.isStaff;
		this.isDeleted = userGroup.isDeleted;
	}

	get urlSlug(): string {
		return this.name.toLowerCase().split(/[\s+]/g).join("-");
	}

	get uid(): string {
		return this.id;
	}
}
