import {
	setCanVerifyRecipientAccountDetails,
	setSingleTransferRecipientBankCode,
} from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";

import {useCallback} from "react";
import {useDispatch} from "react-redux";

interface UseSingleTransferRecipientBankCodeInterface {
	handleSelectBank: (_bankAccountCode: string) => void;
}

function useSingleTransferRecipientBankCode(): UseSingleTransferRecipientBankCodeInterface {
	const dispatch = useDispatch();

	const handleSelectBank = useCallback((_bankAccountCode: string) => {
		dispatch(setSingleTransferRecipientBankCode(_bankAccountCode));
		dispatch(setCanVerifyRecipientAccountDetails(true));
	}, []);

	return {handleSelectBank};
}

export default useSingleTransferRecipientBankCode;
