import React, {useEffect, useState} from "react";

import ButtonComp from "../../../../../components/General/Buttons/ButtonComp";
import Input from "../../../../../components/General/Inputs/Input";
import Modal from "../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../components/General/Modals/ModalBody";
import ModalFooter from "../../../../../components/General/Modals/ModalFooter";
import ModalHeader from "../../../../../components/General/Modals/ModalHeader";
import Transaction from "../../../../../models/transaction";
import useTransactionDetailsTagRecipient from "../Cards/TransactionDetailCard/Hooks/useTransactionDetailsTagRecipient";

interface TagTransactionRecipientInterface {
	showModal: boolean;
	toggler(): void;
	transaction: Transaction;
}

function TagTransactionRecipient({showModal, toggler, transaction}: TagTransactionRecipientInterface): JSX.Element {
	const {isSent, isSubmitting, handleTagRecipient} = useTransactionDetailsTagRecipient();

	const [tagRecipientContact, setTagRecipientContact] = useState<string>("");

	useEffect(() => {
		if (showModal) return;
		setTagRecipientContact("");
	}, [showModal]);

	useEffect(() => {
		if (!isSent) return;
		toggler();
	}, [isSent]);

	return (
		<>
			<Modal size="xs" active={showModal} toggler={toggler}>
				<ModalHeader onClose={toggler} subTitle="The payment receipt will be sent to the recipient">
					Share Receipt
				</ModalHeader>
				<ModalBody>
					<div className="flex w-full flex-col">
						{/* <div className=" flex flex-col w-full">
							<span className="text-base text-black-secondary text-left">The payment receipt will be sent to the recipient</span>
						</div> */}

						<div className="w-full">
							<Input placeholder="Enter Email or Phone number" value={tagRecipientContact} onChange={setTagRecipientContact} />
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={toggler} disable={isSubmitting}>
						<span>Cancel</span>
					</ButtonComp>

					<ButtonComp
						type="submit"
						color="blue"
						ripple="light"
						buttonType="primary"
						func={() => handleTagRecipient(transaction, tagRecipientContact)}
						isLoading={isSubmitting}
						disable={tagRecipientContact.length === 0}
					>
						<span>Share Receipt</span>
					</ButtonComp>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default TagTransactionRecipient;
