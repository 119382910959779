import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import BillProduct from "../../../../models/billProduct";
import {BillPurchaseInitResponse} from "../../../../modules/Dashboard/Payments/Services/billPayment/billPaymentApi.types";
import BillVendor from "../../../../models/billVendor";
import BillVendorMin from "../../../../models/billVendorMin";
import {DataPurchaseState} from "./dataPurchaseSlice.types";
import UserAccount from "../../../../models/userAccount";

const initialState: DataPurchaseState = {
	dataPurchaseDetails: {
		debitAccountId: "",
		productId: "",
		accountId: "",
		phone: "",
		amount: "",
		otp: "",
		bioAuth: "",
		contactName: "",
	},
	selectedUserAccount: null,
	selectedVendorName: null,
	selectedVendorId: null,
	selectedProductName: null,
	selectedProductFixedAmount: null,
	dataPurchaseResponse: null,
};

/* Since the accountId and phone number require the same data no need for a set phone number reducer */

export const dataPurchaseSlice = createSlice({
	name: "dataPurchase",
	initialState,
	reducers: {
		setDataPurchaseDebitAccountId: (state: DataPurchaseState, action: PayloadAction<string>) => {
			state.dataPurchaseDetails.debitAccountId = action.payload;
		},
		setDataPurchaseSelectedUserAccount: (state: DataPurchaseState, action: PayloadAction<UserAccount>) => {
			state.selectedUserAccount = action.payload;
		},
		setDataPurchaseAccountId: (state: DataPurchaseState, action: PayloadAction<string>) => {
			state.dataPurchaseDetails.accountId = action.payload;
			state.dataPurchaseDetails.phone = action.payload;
		},
		setDataPurchaseVendorId: (state: DataPurchaseState, action: PayloadAction<BillVendor | BillVendorMin>) => {
			state.selectedVendorName = action.payload.name;
			state.selectedVendorId = action.payload.id;
		},
		setDataPurchaseProductId: (state: DataPurchaseState, action: PayloadAction<BillProduct>) => {
			state.dataPurchaseDetails.productId = action.payload.id;
			state.dataPurchaseDetails.amount = String(action.payload.fixedAmount);
			state.selectedProductName = action.payload.name;
			state.selectedProductFixedAmount = action.payload.fixedAmount;
		},
		setDataPurchaseAmount: (state: DataPurchaseState, action: PayloadAction<string>) => {
			state.dataPurchaseDetails.amount = action.payload;
		},
		setDataPurchaseOtp: (state: DataPurchaseState, action: PayloadAction<string>) => {
			state.dataPurchaseDetails.otp = action.payload;
		},
		setDataPurchaseResponse: (state: DataPurchaseState, action: PayloadAction<BillPurchaseInitResponse>) => {
			state.dataPurchaseResponse = action.payload;
		},
		resetDataPurchaseProductId: (state: DataPurchaseState) => {
			state.selectedProductName = "";
			state.dataPurchaseDetails.amount = "";
			state.selectedProductFixedAmount = null;
			state.dataPurchaseDetails.productId = "";
		},
		resetAllDataPurchaseData: (state: DataPurchaseState) => {
			state.selectedUserAccount = null;
			state.selectedVendorName = null;
			state.selectedVendorId = null;
			state.dataPurchaseResponse = null;
			state.selectedProductFixedAmount = null;
			state.selectedProductName = null;
			state.dataPurchaseDetails = {
				debitAccountId: "",
				productId: "",
				accountId: "",
				phone: "",
				amount: "",
				otp: "",
				bioAuth: "",
				contactName: "",
			};
		},
	},
});

export const {
	setDataPurchaseOtp,
	setDataPurchaseAmount,
	setDataPurchaseVendorId,
	setDataPurchaseResponse,
	resetAllDataPurchaseData,
	setDataPurchaseProductId,
	setDataPurchaseAccountId,
	resetDataPurchaseProductId,
	setDataPurchaseDebitAccountId,
	setDataPurchaseSelectedUserAccount,
} = dataPurchaseSlice.actions;

export default dataPurchaseSlice.reducer;
