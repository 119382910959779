import {FeeType, TransferTaxOn} from "../redux/init/slice/initSlice.types";

import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class BandTax {
	[immerable] = true;

	constructor(
		public name: string,
		public feeType: FeeType | null,
		public value: number,
		public percentageCap: number | null,
		public whatToTax: TransferTaxOn
	) {}

	static create(obj: GenericObject): BandTax {
		return new BandTax(
			Parsers.string(obj.name),
			Parsers.nullableEnum(obj.feeType, FeeType),
			Parsers.number(obj.value),
			Parsers.nullableNumber(obj.percentageCap),
			Parsers.number(obj.whatToTax)
		);
	}

	get isFlatFee(): boolean {
		return this.feeType === FeeType.FLAT_FEE;
	}
	get isPercentageFee(): boolean {
		return this.feeType === FeeType.PERCENTAGE;
	}
	get hasPercentageCap(): boolean {
		return !!this.percentageCap || !!(this.percentageCap && this.percentageCap > 0);
	}
	get isTaxFee(): boolean {
		return this.whatToTax === TransferTaxOn.TRANSFER_FEE;
	}
	get isTaxAmount(): boolean {
		return this.whatToTax === TransferTaxOn.TRANSFER_AMOUNT;
	}
}
