import BankTransfer from "../../../../../../../assets/svg/bank-transfer.svg";
import {DropdownItem} from "../../../../../../../helpers/types";
import LencoTransfer from "../../../../../../../assets/svg/lenco-transfer.svg";
import MobileMoney from "../../../../../../../assets/svg/mobile-money.svg";
import {ZambiaPaymentMethod} from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice.types";

type PaymentMethod = {
	id: number;
	name: string;
	icon: string;
};

export enum ZambiaMobileMoneyNumberMTNFormat {
	MTN_ONE = "096",
	MTN_TWO = "076",
	MTN_ONE_FULL = "26096",
	MTN_TWO_FULL = "26076",
}
export enum ZambiaMobileMoneyNumberAirtelFormat {
	AIRTEL_ONE = "097",
	AIRTEL_TWO = "077",
	AIRTEL_ONE_FULL = "26097",
	AIRTEL_TWO_FULL = "26077",
}
export enum ZambiaMobileMoneyNumberZamtelFormat {
	ZAMTEL_ONE = "095",
	ZAMTEL_TWO = "075",
	ZAMTEL_ONE_FULL = "26095",
	ZAMTEL_TWO_FULL = "26075",
}
export enum ZambiaMobileMoneyNumberFormat {
	MTN_ONE = "096",
	MTN_TWO = "076",
	MTN_ONE_FULL = "26096",
	MTN_TWO_FULL = "26076",
	AIRTEL_ONE = "097",
	AIRTEL_TWO = "077",
	AIRTEL_ONE_FULL = "26097",
	AIRTEL_TWO_FULL = "26077",
	ZAMTEL_ONE = "095",
	ZAMTEL_TWO = "075",
	ZAMTEL_ONE_FULL = "26095",
	ZAMTEL_TWO_FULL = "26075",
}

export enum ZambiaMobileMoneyOperator {
	MTN = "zm_mtn",
	AIRTEL = "zm_airtel",
	ZAMTEL = "zm_zamtel",
}

export const ZambiaMobileMoneyOperatorsValues = {
	[ZambiaMobileMoneyOperator.AIRTEL]: {
		value: ZambiaMobileMoneyOperator.AIRTEL,
		text: "Airtel",
	},
	[ZambiaMobileMoneyOperator.MTN]: {
		value: ZambiaMobileMoneyOperator.MTN,
		text: "MTN",
	},
	[ZambiaMobileMoneyOperator.ZAMTEL]: {
		value: ZambiaMobileMoneyOperator.ZAMTEL,
		text: "Zamtel",
	},
	[""]: {
		value: undefined,
		text: undefined,
	},
};

export const ZambiaMobileMoneyOperators: Array<DropdownItem<ZambiaMobileMoneyOperator>> = [
	{
		value: ZambiaMobileMoneyOperator.AIRTEL,
		text: "Airtel",
	},
	{
		value: ZambiaMobileMoneyOperator.MTN,
		text: "MTN",
	},
	{
		value: ZambiaMobileMoneyOperator.ZAMTEL,
		text: "Zamtel",
	},
];

export const zambiaPaymentMethods: PaymentMethod[] = [
	{
		id: ZambiaPaymentMethod.LENCO_BUSINESS,
		name: "Lenco Business",
		icon: LencoTransfer as string,
	},
	{
		id: ZambiaPaymentMethod.MOBILE_MONEY,
		name: "Mobile Money",
		icon: MobileMoney as string,
	},
	{
		id: ZambiaPaymentMethod.BANK_TRANSFER,
		name: "Bank Transfer",
		icon: BankTransfer as string,
	},
];
