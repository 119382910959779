import {Link} from "react-router-dom";
import React from "react";

interface Props {
	feature: string;
	isActive: boolean;
	dataType: string;
	searchLink: string;
	isFilterEmpty: boolean;
	isCurrentPage: boolean;
	onSearch: () => void;
}

function SearchBarFeatureHeader(props: Props): JSX.Element {
	return (
		<>
			<div
				className={
					"flex flex-row justify-between border-b border-grey-tertiary px-4 pb-2.5 " +
					// `${!props.isCurrentPage ? "mt-3" : ""} ` +
					`${props.isFilterEmpty ? "mb-3" : ""}`
				}
				tabIndex={-1}
				data-type={props.dataType}
			>
				<div tabIndex={-1} data-type={props.dataType}>
					<p className="text-xs text-black-tertiary" tabIndex={-1} data-type={props.dataType}>
						{props.feature}
					</p>
				</div>
				<div tabIndex={-1} data-type={props.dataType}>
					<Link to={props.searchLink} onClick={props.onSearch} tabIndex={props.isActive ? 0 : -1} data-type={props.dataType}>
						<p className="cursor-pointer text-xs text-blue-tertiary hover:text-blue" tabIndex={-1} data-type={props.dataType}>
							Search <span className="capitalize">{props.feature}</span>
						</p>
					</Link>
				</div>
			</div>
		</>
	);
}

export default SearchBarFeatureHeader;
