import {IRootState} from "../../../../../../../redux/rootReducer";
import React from "react";
import {SingleTransferSections} from "../../../../Hooks/State/SendMoney/payments.constants";
import {useSelector} from "react-redux";

function SinglePaymentHeaders(): JSX.Element {
	const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);
	return (
		<>
			<div className="flex w-full flex-col pb-4 pt-8 text-base">
				<span className="text-sm font-medium text-black-secondary md:text-base">{SingleTransferSections[paymentStage].text}</span>
				<span className="text-xs text-black-tertiary md:text-sm">{SingleTransferSections[paymentStage].subtext}</span>
			</div>
		</>
	);
}

export default SinglePaymentHeaders;
