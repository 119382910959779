import Checkbox, {CheckType} from "../../../../../../components/General/Checkbox/Checkbox";
import {DeclineReason, DeclinedTransaction} from "../../../Hooks/State/PendingApproval/usePendingPayments.types";
import React, {useState} from "react";

import DropdownHead from "../../../../../../components/General/Dropdown/DropdownComponents/DropdownHead";
import DropdownLink from "../../../../../../components/General/Dropdown/DropdownComponents/DropdownLink";
import Transaction from "../../../../../../models/transaction";
import useClickOutside from "../../../../../../hooks/useClickOutside";

interface Props {
	isApproved: boolean;
	isDeclined: boolean;
	transaction: Transaction;
	declinedReason: DeclineReason | undefined;
	isDeclineTransactionLoading?: boolean;
	handleApprovePayment: (_data: Transaction) => void;
	handleSelectOrChangeDeclineReason: (_data: DeclinedTransaction) => void;
	handleRemoveDeclineOrChangeToDecline?: (_data: Transaction) => void;
}

function PendingApproval(props: Props): JSX.Element {
	const [isHoverActive, setIsHoverActive] = useState<boolean>(false);
	const [isHoverDecline, setIsHoverDecline] = useState<boolean>(false);
	const [isDeclineActive, setIsDeclineActive] = useState<boolean>(false);

	const domNode = useClickOutside(() => {
		setIsDeclineActive(false);
	});

	return (
		<>
			<div className="flex flex-row justify-between space-x-4 w-max mx-auto">
				<div
					className="flex cursor-pointer flex-col items-center justify-center space-y-2"
					ref={domNode}
					onMouseEnter={() => setIsHoverDecline(true)}
					onMouseLeave={() => setIsHoverDecline(false)}
					onClick={() => {
						if (props.isDeclined) {
							setIsDeclineActive(true);
							props.handleRemoveDeclineOrChangeToDecline && props.handleRemoveDeclineOrChangeToDecline(props.transaction);
						} else {
							setIsDeclineActive(true);
						}
					}}
				>
					<DropdownHead
						placeholder={
							<div
								className={
									`h-full center  flex items-center justify-center space-x-1 text-xs font-normal bg-red-300` +
									`${isDeclineActive || props.isDeclined ? "text-error" : "text-current"}`
								}
							>
								<Checkbox type={CheckType.ERROR} id={props.transaction.id} checked={props.isDeclined} size="sm" readOnly />
								<span
									className={`h-full pr-2 border-r ${
										props.isDeclined ? "border-error-quin text-error-secondary" : "border-grey-secondary"
									} flex items-center`}
								>
									Decline
								</span>
							</div>
						}
						noYPadding
						borderColor={props.isDeclined ? "border-error-quin" : "border-grey-secondary"}
						size="xs"
						clickAndClose
						placement="right"
						isLoading={props.isDeclineTransactionLoading}
						color="red"
						isActive={isDeclineActive}
						isHover={isHoverDecline || props.isDeclined}
						isSelected={props.isDeclined}
						noOutlineBorder={false}
						outline
						fitDropdown
					>
						<DropdownLink
							noHover={props.declinedReason === DeclineReason.INCORRECT_DETAILS}
							onClick={() =>
								props.handleSelectOrChangeDeclineReason({
									reason: DeclineReason.INCORRECT_DETAILS,
									transaction: props.transaction,
								})
							}
							redHover
							color="red"
						>
							<div className="flex items-center justify-start px-4 text-sm">Incorrect Details</div>
						</DropdownLink>
						<DropdownLink
							noHover={props.declinedReason === DeclineReason.INCORRECT_AMOUNT}
							redHover
							onClick={() =>
								props.handleSelectOrChangeDeclineReason({
									reason: DeclineReason.INCORRECT_AMOUNT,
									transaction: props.transaction,
								})
							}
							color="red"
						>
							<div className="flex items-center justify-start px-4 text-sm">Incorrect Amount</div>
						</DropdownLink>
						<DropdownLink
							noHover={props.declinedReason === DeclineReason.WRONG_PURPOSE}
							redHover
							onClick={() =>
								props.handleSelectOrChangeDeclineReason({
									reason: DeclineReason.WRONG_PURPOSE,
									transaction: props.transaction,
								})
							}
							color="red"
						>
							<div className="flex items-center justify-start px-4 text-sm">Wrong Purpose</div>
						</DropdownLink>
						<DropdownLink
							noHover={props.declinedReason === DeclineReason.OTHERS}
							onClick={() =>
								props.handleSelectOrChangeDeclineReason({
									reason: DeclineReason.OTHERS,
									transaction: props.transaction,
								})
							}
							redHover
							color="red"
						>
							<div className="flex items-center justify-start px-4 text-sm">Others</div>
						</DropdownLink>
					</DropdownHead>
				</div>
				<div
					className="flex cursor-pointer flex-col items-center justify-center space-y-2"
					onMouseEnter={() => setIsHoverActive(true)}
					onMouseLeave={() => setIsHoverActive(false)}
					onClick={() => props.handleApprovePayment(props.transaction)}
				>
					<div
						className={
							` border flex h-8 items-center justify-center rounded-lg px-2 duration-150 ease-in-out ` +
							`${
								props.isApproved
									? "text-success border-success-quin bg-success-backdrop"
									: "text-black-tertiary border-grey-secondary"
							} ` +
							`${isHoverActive ? "bg-success-backdrop text-success" : ""} `
						}
					>
						<Checkbox
							type={CheckType.SUCCESS}
							text={<span className={`text-xs ${props.isApproved ? "text-success" : "text-black-tertiary"}`}>Approve</span>}
							id={props.transaction.id}
							checked={props.isApproved}
							size="sm"
							readOnly
						/>
					</div>
				</div>
			</div>
		</>
	);
}

export default PendingApproval;
