import React, {useEffect, useState} from "react";

import CustomerAccount from "../../../../../../../models/customerAccount";
import {IRootState} from "../../../../../../../redux/rootReducer";
import SendableDropdown from "../../../../../../../components/General/Dropdown/SendableDropdown/SendableDropdown";
import Transaction from "../../../../../../../models/transaction";
import {useSelector} from "react-redux";
import useTransactionDetailsUserGroups from "../Hooks/useTransactionDetailsUserGroups";

interface Props {
	dataType: string;
	transaction: Transaction;
	showTransactionDetailCard: boolean;
}

function TransactionDetailCardUserGroups({dataType, transaction, showTransactionDetailCard}: Props): JSX.Element {
	const {
		userGroupOptions,
		isError,
		isSubmitting,
		handleAddAccountToUserGroup,
		handleCreateAndAddAccountToUserGroup,
		handleRemoveAccountFromUserGroup,
	} = useTransactionDetailsUserGroups();

	const [displayText, setDisplayText] = useState<string>("");
	// const [recipientCustomerAccount, setRecipientCustomerAccount] = useState<CustomerAccount|undefined>(undefined);

	const recipientCustomerAccount = useSelector<IRootState, CustomerAccount | undefined>((state) =>
		state.customerAccount.customerAccounts.get(transaction.destination?._customerAccountId || "")
	);

	useEffect(() => {
		if (transaction) {
			// setRecipientCustomerAccount(transaction.destination?.customerAccount || undefined);
			setDisplayText(transaction.destination?.customerAccount?.userGroup?.name || "");
		}
	}, [transaction]);

	useEffect(() => {
		if (!isError) return;
		if (recipientCustomerAccount?.userGroup?.name) {
			return setDisplayText(recipientCustomerAccount.userGroup?.name);
		}
		setDisplayText("");
	}, [isError, recipientCustomerAccount]);

	return (
		<>
			{transaction && transaction.isDebit() && transaction.isSuccess && !!recipientCustomerAccount && userGroupOptions && (
				<div className={`w-full ` + `${!showTransactionDetailCard ? "hidden " : ""}`} data-type={dataType}>
					<SendableDropdown
						data={userGroupOptions}
						placeholder="Select or Create a Group"
						inputValue={recipientCustomerAccount.userGroup?.name || ""}
						value={displayText}
						changeValue={setDisplayText}
						clickAndClose
						isLoading={isSubmitting}
						cancelFunc={() => handleRemoveAccountFromUserGroup(recipientCustomerAccount)}
						onClickFunc={(option) => handleAddAccountToUserGroup(recipientCustomerAccount, option.id)}
						createFunc={(name) => handleCreateAndAddAccountToUserGroup(recipientCustomerAccount, name)}
						data-type={dataType}
					/>
				</div>
			)}
		</>
	);
}

export default TransactionDetailCardUserGroups;
