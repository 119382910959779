import {PurchaseType, TerminalRequestStatus} from "../../../models/terminal.constants";

import Merchant from "../../../models/merchant";
import Terminal from "../../../models/terminal";
import TerminalRequest from "../../../models/terminalRequest";

export enum TerminalsStageType {
	TERMINAL_TRANSACTION = 1,
	ALL_TERMINALS = 2,
	TERMINAL_REQUEST = 3,
}
export enum AllTerminalsStageType {
	INITIAL = 1,
	SPECIFIC_TERMINAL_TRANSACTION = 2,
}
export enum SpecificTerminalStageType {
	TERMINAL_TRANSACTIONS = 1,
	SETTINGS = 2,
}

export interface TerminalsState {
	//<===for terminal stages and inner stages===>
	terminalStage: TerminalsStageType;
	allTerminalStage: AllTerminalsStageType;
	specificTerminalStage: SpecificTerminalStageType;
	selectedTerminalNameInTerminalStage: string | null;
	specificallySelectedTerminal: Terminal | null;

	//<===for terminals in general===>
	initMeta: {
		setupVideoUrl: string | null;
		purchaseCost: number | null;
		cautionFee: number | null;
	} | null;
	merchant: Merchant | null;
	terminals: Terminal[];
	initTerminals: Terminal[];
	requestedTerminals: TerminalRequest[];
	initRequestedTerminals: TerminalRequest[];
	initTerminalRequestsListTotal: number;
	initTerminalRequestsListGroupSize: number;

	//<===for terminal modals===>
	isSettingUpTerminalModalOpen: boolean;
	isRequestingTerminalModalOpen: boolean;

	//<===for terminal filter===>
	isExportTerminalsLoading: boolean;
	isTerminalPaginationLoading: boolean;
	terminalListTotal: number;
	terminalListGroupSize: number;
	terminalPaginationOffset: number;
	terminalFilterState: {
		offset: number;
		query: string;
		groupId: string;
	};
	selectedTerminalFilterState: {
		offset: number;
		query: string;
		groupId: string;
	};

	//<===for terminal transaction filter===>
	isExportTerminalRequestsLoading: boolean;
	isTerminalRequestsPaginationLoading: boolean;
	terminalRequestsListTotal: number;
	terminalRequestsListGroupSize: number;
	terminalRequestsPaginationOffset: number;
	terminalRequestsFilterState: {
		offset: number;
		statuses: TerminalRequestStatus[];
		purchaseTypes: PurchaseType[];
	};
	selectedTerminalRequestsFilterState: {
		offset: number;
		statuses: TerminalRequestStatus[];
		purchaseTypes: PurchaseType[];
	};
}

export type TerminalRequestsFilterTypes = {offset: number} | {statuses?: Array<TerminalRequestStatus>} | {purchaseTypes?: Array<PurchaseType>};

export type TerminalFilterTypes = {offset: number} | {query: string} | {groupId: string};

export type TerminalRequestsFilterState = {
	offset: number;
	statuses: TerminalRequestStatus[];
	purchaseTypes: PurchaseType[];
};
