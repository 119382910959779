import BankAccount from "../../../../models/bankAccount";
import UserAccount from "../../../../models/userAccount";
/*
export interface TransactionInitState {
	updatedTransactionUserGroup: Transaction | null;
	updatedUserAccount: UserAccount | null;
	updatedTransaction: Transaction | null;
	showTransactionDetailCard: boolean;
	transactionDetail: Transaction | null;
}
*/
export interface SingleTransferDetailInterface {
	originatingAccountId: string;
	recipient: {
		bankAccountId: string;
		customerAccountId: string;
		accountNumber: string;
		bankCode: string;
	};
	amount: string;
	purpose: string;
	otp: string;
}

export interface SinglePaymentResponse {
	transactionId: string;
	isApproved: boolean;
	processing: boolean;
	accountsToActivate: Array<string>;
}

export interface SinglePaymentResponse {
	transactionId: string;
	isApproved: boolean;
	processing: boolean;
	accountsToActivate: Array<string>;
}

export enum SinglePaymentInternalStage {
	PAYMENT_DETAILS_STAGE = 0,
	AMOUNT_PURPOSE_STAGE = 1,
}

export interface SingleTransferState {
	singleTransferDetails: SingleTransferDetailInterface;
	selectedUserAccount: UserAccount | null;
	canVerifyRecipientAccountDetails: boolean;
	verifiedAccount: BankAccount | null;
	recipientBankAccountName: string;
	singleTransferResponse: SinglePaymentResponse | null;
	singlePaymentInternalStage: SinglePaymentInternalStage;
}
