import CardAssignedTo from "./cardAssignedTo";
import CardMin from "./cardMin";
import {CardPaymentStatus} from "./transaction.constants";
import {CardTransactionChannel} from "./card.constants";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class CardPayment {
	[immerable] = true;

	constructor(
		public id: string,
		public createdAt: string,
		public cardId: string | null,
		public cardDetails: CardMin,
		public datetime: Date | null,
		public amount: number,
		public transactionFee: number,
		public channel: CardTransactionChannel,
		public merchant: string,
		public stan: string,
		public rrn: string,
		public balance: number | null,
		public assignedTo: CardAssignedTo | null,
		public status: CardPaymentStatus
	) {}

	static create(obj: GenericObject): CardPayment {
		return new CardPayment(
			Parsers.string(obj.id),
			Parsers.string(obj.createdAt),
			Parsers.string(obj.cardId),
			Parsers.classObjectNonNullable(obj.cardDetails, CardMin),
			Parsers.date(obj.datetime),
			Parsers.number(obj.amount),
			Parsers.number(obj.transactionFee),
			Parsers.number(obj.channel),
			Parsers.string(obj.merchant),
			Parsers.string(obj.stan),
			Parsers.string(obj.rrn),
			Parsers.nullableNumber(obj.balance),
			Parsers.classObject(obj.assignedTo, CardAssignedTo),
			Parsers.number(obj.status)
		);
	}
	get getChannelName(): string {
		return this.channel === CardTransactionChannel.ATM ? "ATM" : this.channel === CardTransactionChannel.POS_TERMINAL ? "POS Terminal" : "Web";
	}
	get isReversed(): boolean {
		return this.status === CardPaymentStatus.REVERSED;
	}
}
