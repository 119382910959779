import {initReset} from "../../slice/initSlice";
import {isStorageAvailable} from "../../../../utils/windowStorage";
import {logout} from "../init.api";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

interface Response {
	handleLogout: () => void;
}

function useLogout(): Response {
	const dispatch = useDispatch();
	const history = useHistory();

	const handleLogout = useCallback(() => {
		void logout();
		dispatch(initReset());
		history.push("/login");
		if (isStorageAvailable) window.localStorage.clear();
	}, [dispatch, history]);

	return {handleLogout};
}

export default useLogout;
