import * as Yup from "yup";

import {Form, Formik} from "formik";
import {HintType, ListStyle} from "../../../../../../Dashboard/Application/components/element/hint/hint.constant";
import {
	PersonalDetailForm,
	getPersonalDetailFormHints,
} from "../../../../../../Dashboard/Application/services/nigeria-application/registered/currentSignatory.types";

import ApplicationHeader from "../../../../../../Dashboard/Application/components/element/ApplicationHeader";
import ButtonComp from "../../../../../../../components/General/Buttons/ButtonComp";
// import {CompanyHint} from "../../../../../../Dashboard/Application/services/nigeria-application/registered/company.types";
import CustomSelectWithOther from "../../../../../../../components/General/Dropdown/CustomSelectWithOther";
import DateComponent from "../../../../../../../components/General/Inputs/DateComponent";
import FormInput from "../../../../../../../components/General/Inputs/FormInput";
import Hint from "../../../../../../Dashboard/Application/components/element/hint";
import {IRootState} from "../../../../../../../redux/rootReducer";
import Input from "../../../../../../../components/General/Inputs/Input";
import React from "react";
import SingleSelectDropdown from "../../../../../../Dashboard/Settings/Components/DropDowns/TeamMembers/SingleSelectDropdown";
import formikHasError from "../../../../../../../helpers/formikHasError";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import moment from "moment";
import useExternalDirectorBio from "../../../Hooks/useExternalDirectorBio";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

// const PersonalDetailsHints: CompanyHint[] = [
// {
// index: 0,
// span: <span>Review your personal information and update your role in the business.</span>,
// },
// ];

function DirectorPersonalDetails(): JSX.Element {
	const history = useHistory();

	const {isExternalDirectorBioLoading, handleUpdateExternalDirectorBio} = useExternalDirectorBio();

	const externalLink = useSelector((state: IRootState) => state.externalApplication.externalLink || "");
	const externalParams = useSelector((state: IRootState) => state.externalApplication.externalParams);
	const externalApplicationInit = useSelector((state: IRootState) => state.externalApplication.init);

	const director = externalApplicationInit?.director || null;

	const INITIAL_FORM_STATE: PersonalDetailForm = {
		nameTitleId: director?.title || 0,
		firstName: director?.firstName || "",
		otherName: director?.otherName || "",
		surname: director?.surname || "",
		dateOfBirth: director?.dateOfBirth ? new Date(director?.dateOfBirth) : null,
		motherMaidenName: director?.motherMaidenName || "",
		phone: director?.phones[0] || "",
		isNigerian: isNullOrUndefined(director?.isNigerian) ? null : director?.isNigerian === false ? false : director?.isNigerian || null,
		nonNigerianNationality: director?.nonNigerianNationality || null,
		residencyPermit: {
			expiryDate: director?.residencyPermit?.expiryDate ? new Date(director?.residencyPermit?.expiryDate) : null,
			issueDate: director?.residencyPermit?.issueDate ? new Date(director?.residencyPermit?.issueDate) : null,
			permitNumber: director?.residencyPermit?.permitNumber || "",
		},
		bvn: director?.bvn || "",
		roleId: director?.role || 0,
		otherRoleText: director?.otherRoleText || null,
		genderId: director?.gender || 0,
		address: director?.address || "",
	};

	const FORM_VALIDATION = Yup.object().shape({
		nameTitleId: Yup.number().required("Required"),
		firstName: Yup.string().required("Required"),
		otherName: Yup.string(),
		surname: Yup.string().required("Required"),
		dateOfBirth: Yup.date().nullable().required("Required"),
		motherMaidenName: Yup.string().nullable().required("Required"),
		phone: Yup.string().phone().required("Required"),
		isNigerian: Yup.boolean().nullable(),
		nonNigerianNationality: Yup.string().nullable(),
		residencyPermit: Yup.object().when("isNigerian", {
			is: false,
			then: Yup.object().shape({
				permitNumber: Yup.string().required("Required"),
				issueDate: Yup.string().required("Required"),
				expiryDate: Yup.string().required("Required"),
			}),
			otherwise: Yup.object().nullable(),
		}),
		bvn: Yup.string().required("Required"),
		roleId: Yup.number(),
		otherRoleText: Yup.string().nullable(),
		genderId: Yup.number().nullable().required("Required"),
		address: Yup.string().required("Required"),
	});

	return (
		<>
			<Formik
				initialValues={{
					...INITIAL_FORM_STATE,
				}}
				validationSchema={FORM_VALIDATION}
				onSubmit={(values) => {
					if (externalParams) {
						void handleUpdateExternalDirectorBio(externalParams, {
							...values,
							dateOfBirth: moment(values.dateOfBirth).format("YYYY-MM-DD"),
						});
					}
				}}
				enableReinitialize
				validateOnBlur
				validateOnChange
			>
				{(formik) => {
					return (
						<Form className="w-full pb-8">
							<div className="flex flex-row justify-start items-start space-x-6 w-full">
								{externalApplicationInit && (
									<div className="lg:min-w-60% lg:max-w-3xl w-full">
										<ApplicationHeader
											header="Personal Details"
											subTitle="Please review and update your personal information"
											progress={externalApplicationInit.director.identificationProgress()}
										>
											<div className="flex flex-col items-start space-y-4 w-full">
												<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
													<div className="lg:w-6/12">
														<SingleSelectDropdown
															placeholder="Title"
															options={externalApplicationInit?.options.nameTitle.map((el) => {
																return {
																	text: el.name,
																	value: el.id,
																};
															})}
															onChange={(value) => formik.getFieldHelpers("nameTitleId").setValue(value)}
															value={formik.values.nameTitleId}
															defaultValue={(director && director?.title) || undefined}
															bigDropdown={false}
														/>
													</div>
													<div className="lg:w-6/12">
														<FormInput
															type="text"
															placeholder="First Name"
															name="firstName"
															value={formik.values.firstName || ""}
														/>
													</div>
												</div>
												<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
													<div className="w-full lg:w-50%">
														<FormInput
															type="text"
															placeholder="Other Name (Optional)"
															name="otherName"
															value={formik.values.otherName || ""}
														/>
													</div>
													<div className="lg:w-6/12">
														<FormInput
															type="text"
															placeholder="Last Name"
															name="surname"
															value={formik.values.surname || ""}
														/>
													</div>
												</div>
												<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
													<div className="w-full lg:w-50% ">
														<DateComponent
															date={formik.values.dateOfBirth || null}
															handleSetDate={(_date) =>
																// formik.getFieldHelpers("dateOfBirth").setValue(moment(_date).format("YYYY-MM-DD"))
																formik.getFieldHelpers("dateOfBirth").setValue(_date)
															}
															placeholder="Date of Birth"
														/>
													</div>
													<div className="lg:w-6/12">
														<FormInput
															type="text"
															placeholder="Mother's Maiden Name"
															name="motherMaidenName"
															value={formik.values.motherMaidenName || ""}
														/>
													</div>
												</div>
												<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
													<div className="lg:w-6/12">
														<Input
															type="text"
															placeholder="Email"
															name="email"
															value={director?.email || ""}
															isDisabled
														/>
													</div>
													<div className="lg:w-6/12">
														<FormInput
															type="text"
															placeholder="Phone Number"
															name="phone"
															value={formik.values.phone || ""}
														/>
													</div>
												</div>
												<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
													<div className="w-full lg:w-50% ">
														<CustomSelectWithOther
															placeholder="Nationality"
															value={formik.values.isNigerian || undefined}
															otherValue={formik.values.nonNigerianNationality || undefined}
															options={[
																{
																	text: "Nigerian",
																	value: true,
																},
															]}
															otherOptionValue={false}
															reset={() => {
																formik.getFieldHelpers("isNigerian").setValue(null);
																formik.getFieldHelpers("nonNigerianNationality").setValue(undefined);
																formik.getFieldHelpers("residencyPermit.issueDate").setValue(null);
																formik.getFieldHelpers("residencyPermit.expiryDate").setValue(null);
																formik.getFieldHelpers("residencyPermit?.permitNumber").setValue("");
															}}
															resetDropdownValue={() => {
																formik.getFieldHelpers("isNigerian").setValue(null);
															}}
															onSelect={(value, otherText) => {
																if (!isNullOrUndefined(value)) {
																	formik.getFieldHelpers("isNigerian").setValue(value);
																}
																formik.getFieldHelpers("nonNigerianNationality").setValue(otherText || null);
															}}
														/>
													</div>
													<div className="relative lg:w-6/12">
														<FormInput
															type="text"
															name="bvn"
															value={formik.values.bvn || ""}
															helperText="Bank Verification Number"
															placeholder="BVN"
															mobileHelperText
														/>
														{/* <div className="absolute left-full top-0 z-10 hidden h-full w-max items-center justify-center lg:flex ">
															<TooltipIcons
																placement="right"
																icon={<Info />}
																message="Bank Verification Number"
																noHoverBg
															/>
														</div> */}
													</div>
												</div>
												<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
													<div className="lg:w-6/12">
														<CustomSelectWithOther
															placeholder="Role in Business"
															value={formik.values.roleId || undefined}
															otherValue={formik.values.otherRoleText || undefined}
															options={externalApplicationInit.options.role.map((item) => item.toDropdownItem())}
															otherOptionValue={null}
															reset={() => {
																formik.getFieldHelpers("roleId").setValue(0);
																formik.getFieldHelpers("otherRoleText").setValue(undefined);
															}}
															resetDropdownValue={() => {
																formik.getFieldHelpers("roleId").setValue(0);
															}}
															onSelect={(value, otherText) => {
																formik.getFieldHelpers("roleId").setValue(value || 0);
																formik.getFieldHelpers("otherRoleText").setValue(otherText || null);
															}}
														/>
													</div>
													<div className="lg:w-6/12">
														<SingleSelectDropdown
															placeholder="Gender"
															options={externalApplicationInit?.options.gender.map((el) => {
																return {
																	text: el.name,
																	value: el.id,
																};
															})}
															onChange={(value) => formik.getFieldHelpers("genderId").setValue(value || null)}
															value={formik.values.genderId}
															defaultValue={undefined}
															bigDropdown={false}
														/>
													</div>
												</div>
												<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
													<FormInput
														type="textarea"
														placeholder="Address"
														name="address"
														value={formik.values.address || ""}
													/>
												</div>
												{formik.values.nonNigerianNationality && (
													<>
														<div className="w-full">
															<h4 className="font-medium text-md text-black-secondary">Resident Permit</h4>
															<p className="text-black-tertiary text-sm font-normal">For Non-Nigerians</p>
														</div>

														<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
															<div className="lg:w-6/12">
																<DateComponent
																	date={formik.values.residencyPermit?.issueDate || null}
																	handleSetDate={(_date) =>
																		formik.getFieldHelpers("residencyPermit.issueDate").setValue(_date)
																	}
																	placeholder="Issue Date"
																/>
															</div>
															<div className="lg:w-6/12">
																<DateComponent
																	date={formik.values.residencyPermit?.expiryDate || null}
																	handleSetDate={(_date) =>
																		formik.getFieldHelpers("residencyPermit.expiryDate").setValue(_date)
																	}
																	placeholder="Expiry Date"
																/>
															</div>
														</div>
														<div className="flex w-full flex-col space-x-0 space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
															<FormInput
																type="text"
																placeholder="Permit Number"
																name="residencyPermit.permitNumber"
																value={formik.values.residencyPermit?.permitNumber || ""}
															/>
														</div>
													</>
												)}
												<div className="flex flex-col 2xs:flex-row items-center justify-center pt-8 2xs:space-x-4 w-full">
													<div className="w-full 2xs:w-max order-2 2xs:order-1">
														<ButtonComp
															type="button"
															color="grey"
															ripple="light"
															buttonType="secondary"
															func={() => history.push(`${externalLink}`)}
															fullWidth
														>
															<span>Back</span>
														</ButtonComp>
													</div>
													<div className="w-full 2xs:w-max order-1 2xs:order-2">
														<ButtonComp
															type="submit"
															color="blue"
															ripple="light"
															buttonType="primary"
															isLoading={isExternalDirectorBioLoading}
															fullWidth
															disable={
																!!(
																	formikHasError(formik.errors) ||
																	isNullOrUndefined(formik.values.isNigerian) ||
																	(formik.values.isNigerian === false &&
																		(formik.values.nonNigerianNationality?.length || 0) < 1) ||
																	(formik.values.roleId === 0 && (formik.values.otherRoleText?.length || 0) < 1)
																)
															}
														>
															<span>Continue</span>
														</ButtonComp>
													</div>
												</div>
											</div>
										</ApplicationHeader>
									</div>
								)}
								<Hint
									// hintType={HintType.GUIDE}
									// listStyle={ListStyle.PLAIN}
									// firstList={PersonalDetailsHints}
									// secondHint
									hintType={HintType.GUIDE}
									listStyle={ListStyle.CHECK}
									firstList={getPersonalDetailFormHints(
										Object.keys(INITIAL_FORM_STATE || []),
										[
											...Object.keys(formik.errors || []),
											...(!formik.values.roleId && !formik.values.otherRoleText ? ["roleId"] : []),
											...(!formik.values.isNigerian && !formik.values.nonNigerianNationality ? ["isNigerian"] : []),
										],
										[
											"otherName",
											"otherRoleText",
											"nonNigerianNationality",
											formik.values.isNigerian || isNullOrUndefined(formik.values.isNigerian) ? "residencyPermit" : "",
										],
										[
											{primary: "roleId", secondary: "otherRoleText"},
											{primary: "isNigerian", secondary: "nonNigerianNationality"},
											{primary: "isNigerian", secondary: "nonNigerianNationality"},
										]
									)}
								/>
							</div>
						</Form>
					);
				}}
			</Formik>
		</>
	);
}

export default DirectorPersonalDetails;
