import React, {useState} from "react";
import {SendMoneyOption, ZambiaAddMoneyInnerOptions} from "../../../SendMoneyDropdown.constants";

import {ReactComponent as ChevronRightIcon} from "../../../../../../assets/images/chevronRight.svg";
import DropdownLink from "../../../../../General/Dropdown/DropdownComponents/DropdownLink";
import {IRootState} from "../../../../../../redux/rootReducer";
import {SendMoneyOptionType} from "../../sendMoney.types";
import {useHistory} from "react-router";
import {useSelector} from "react-redux";

interface Props {
	option: SendMoneyOption;
	onClick: () => void;
}

function AddMoneyRow(props: Props): JSX.Element {
	const history = useHistory();

	const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);

	const [isHover, setIsHover] = useState<boolean>(false);

	return (
		<>
			<div className="relative w-full" onMouseEnter={() => setIsHover(true)} onMouseLeave={() => setIsHover(false)}>
				<DropdownLink
					onClick={(e) =>
						props.option.type === SendMoneyOptionType.ADD_MONEY && isZambia
							? e.stopPropagation()
							: props.option.onClick(history, props.onClick)
					}
					isLink
				>
					<div className="flex w-full items-center justify-start px-4">
						<span className="text-xl">{props.option.icon}</span>
						<div
							className={
								`ml-4 inline items-center ` +
								`${
									props.option.type === SendMoneyOptionType.ADD_MONEY && isZambia ? "flex w-full justify-between" : "justify-center"
								}`
							}
						>
							<p className="text-sm">{props.option.text}</p>
							{props.option.type === SendMoneyOptionType.ADD_MONEY && isZambia && <ChevronRightIcon className="stroke-current" />}
						</div>
					</div>
				</DropdownLink>

				<div
					className={
						`absolute left-full top-0 h-fit max-h-84 max-w-max w-full flex-col overflow-hidden rounded-b-lg rounded-r-lg bg-white shadow ` +
						`${!isHover ? "hidden" : "flex"}`
					}
				>
					{props.option.type === SendMoneyOptionType.ADD_MONEY && isZambia && (
						<>
							{ZambiaAddMoneyInnerOptions.map((_options, _index) => (
								<DropdownLink key={_index} onClick={() => _options.onClick(history)} isLink>
									<div className="flex w-full items-center justify-start px-4">
										<span className="text-xl">{_options.icon}</span>
										<p className="ml-4 text-sm">{_options.text}</p>
									</div>
								</DropdownLink>
							))}
						</>
					)}
				</div>
			</div>
		</>
	);
}

export default AddMoneyRow;
