import {
	ExternalInitRequest,
	ExternalInitResponse,
} from "../../modules/Landing/ExternalApplicationForms/DirectorForm/service/externalApplication.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import OtherDirector from "../../models/account-opening/otherDirector";

interface ExternalApplicationState {
	init: ExternalInitResponse | null;
	externalLink: string | null;
	externalParams: ExternalInitRequest | null;
}

const initialState: ExternalApplicationState = {
	init: null,
	externalLink: "",
	externalParams: null,
};

export const externalApplicationSlice = createSlice({
	name: "externalApplication",
	initialState,
	reducers: {
		setExternalApplicationInit: (state: ExternalApplicationState, action: PayloadAction<ExternalInitResponse>) => {
			state.init = action.payload;
		},
		setExternalParams: (state: ExternalApplicationState, action: PayloadAction<ExternalInitRequest>) => {
			state.externalLink = `/director/${action.payload.a}/${action.payload.b}`;
			state.externalParams = action.payload;
		},
		updateExternalDirector: (state: ExternalApplicationState, action: PayloadAction<OtherDirector>) => {
			if (state.init) {
				state.init.director = action.payload;
			}
		},
		resetAllExternalApplicationState: (state: ExternalApplicationState) => {
			state.init = null;
			state.externalLink = null;
			state.externalParams = null;
		},
	},
});

export const {setExternalApplicationInit, setExternalParams, updateExternalDirector, resetAllExternalApplicationState} =
	externalApplicationSlice.actions;

export default externalApplicationSlice.reducer;
