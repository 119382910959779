import CancelPayment from "..";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import useCancelPendingPayment from "../../../../Hooks/State/PendingApproval/useCancelPendingPayment";

interface Props {
	transaction: Transaction;
}

function StandAloneCancelPayment(props: Props): JSX.Element {
	const {cancelReason, isCancelPaymentLoading, handleCancelPayment} = useCancelPendingPayment();

	return (
		<>
			<CancelPayment
				isCancelled={!!cancelReason}
				transaction={props.transaction}
				cancelledReason={cancelReason || undefined}
				isCancelTransactionLoading={isCancelPaymentLoading}
				handleSelectOrChangeCancelReason={({reason, transaction}) => handleCancelPayment({reason: reason, transactionId: transaction.id})}
			/>
		</>
	);
}

export default StandAloneCancelPayment;
