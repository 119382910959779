import {
	resetVerifiedAccount,
	setSingleTransferRecipient,
	setVerifiedAccount,
} from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import BankAccount from "../../../../../../../models/bankAccount";
import {IRootState} from "../../../../../../../redux/rootReducer";
import {RequestCancelledError} from "../../../../../../../helpers/request/requestErrors";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {verifyAccount} from "../../../../Services/SendMoney/singleTransferApi";

export interface UseSingleTransferVerifyRecipientAccountDetailsInterface {
	isVerifyAccountLoading: boolean;
	verifyAccountError: {message: string} | null;
	verifiedAccount: BankAccount | null;
	handleSetVerifiedAccount: (_bankAccount: BankAccount) => void;
	handleResetVerifiedAccount: () => void;
}

function useSingleTransferVerifyRecipientAccountDetails(): UseSingleTransferVerifyRecipientAccountDetailsInterface {
	const dispatch = useDispatch();
	const verifiedAccount = useSelector((state: IRootState) => state.singleTransfer.verifiedAccount);
	const selectedUserAccount = useSelector((state: IRootState) => state.singleTransfer.selectedUserAccount);
	const singleTransferDetails = useSelector((state: IRootState) => state.singleTransfer.singleTransferDetails);
	const canVerifyRecipientAccountDetails = useSelector((state: IRootState) => state.singleTransfer.canVerifyRecipientAccountDetails);

	const [isVerifyAccountLoading, setIsVerifyAccountLoading] = useState(false);
	const [verifyAccountError, setVerifyAccountError] = useState<{message: string} | null>(null);
	// const [verifiedAccount, setVerifiedAccount] = useState<BankAccount | null>(null);

	useEffect(() => {
		if (!selectedUserAccount) return;
		if (selectedUserAccount.accountRestrictions.canSendMoneyToSpecificAccounts) {
			setVerifyAccountError(null);
			dispatch(resetVerifiedAccount());
		}
	}, [selectedUserAccount]);

	useEffect(() => {
		if (
			singleTransferDetails.recipient.accountNumber.length < 10 ||
			singleTransferDetails.recipient.bankCode.length < 2 ||
			!canVerifyRecipientAccountDetails
		)
			return;
		void handleVerifyAccount({
			bankCode: singleTransferDetails.recipient.bankCode,
			accountNumber: singleTransferDetails.recipient.accountNumber,
		});
	}, [singleTransferDetails.recipient.accountNumber, singleTransferDetails.recipient.bankCode, canVerifyRecipientAccountDetails]);

	const handleVerifyAccount = useCallback(
		async (_data) => {
			try {
				setIsVerifyAccountLoading(true);
				const res = await verifyAccount(_data);
				dispatch(setSingleTransferRecipient(res.bankAccount));
				dispatch(setVerifiedAccount(res.bankAccount));
				// setVerifiedAccount(res.bankAccount);
				setVerifyAccountError(null);
			} catch (err) {
				if (err instanceof RequestCancelledError) {
					return; // do nothing
				}
				const errorMessage = getErrorMessage(err);
				dispatch(errorTrue({message: errorMessage}));
				dispatch(resetVerifiedAccount());
				// setVerifiedAccount(null);
				setVerifyAccountError({
					message: errorMessage,
				});
			}
			setIsVerifyAccountLoading(false); // set outside catch block, because finally will ignore the return in catch block
		},
		[dispatch]
	);

	const handleSetVerifiedAccount = useCallback((_bankAccount) => {
		dispatch(setVerifiedAccount(_bankAccount));
	}, []);

	const handleResetVerifiedAccount = useCallback(() => {
		dispatch(resetVerifiedAccount());
	}, []);

	return {
		isVerifyAccountLoading,
		verifyAccountError,
		verifiedAccount,
		handleSetVerifiedAccount,
		handleResetVerifiedAccount,
	};
}

export default useSingleTransferVerifyRecipientAccountDetails;
