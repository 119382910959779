import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class TransactionCategory {
	[immerable] = true;

	constructor(public id: string, public name: string, public isDeleted: boolean) {}

	static create(obj: GenericObject): TransactionCategory {
		return new TransactionCategory(Parsers.string(obj.uid), Parsers.string(obj.name), Parsers.boolean(obj.isDeleted));
	}

	update(transactionCategory: TransactionCategory): void {
		this.name = transactionCategory.name;
		this.isDeleted = transactionCategory.isDeleted;
	}

	get uid(): string {
		return this.id;
	}
}
