import {
	DirectorNavigation,
	DirectorPageType,
	ExternalDirectorPageType,
	ExternalDirectorSubPageType,
	GenericDirectorPageType,
	MeansOfIDPageType,
	NextOfKinPageType,
} from "../../service/director.constants";
import React, {useCallback} from "react";

import {ReactComponent as ArrowDownIcon} from "../../../../../../assets/svg/General/arrowDownIcon.svg";
import {ReactComponent as CancelIcon} from "../../../../../../assets/svg/Transfer/Cancel.svg";
import {useHistory} from "react-router";

interface Props {
	url: string;
	stage: ExternalDirectorSubPageType;
	section: ExternalDirectorPageType;
	children: React.ReactNode;
}

function ExternalDirectorLayout(props: Props): JSX.Element {
	const history = useHistory();
	const getPath = useCallback((_section: ExternalDirectorPageType, _stage: ExternalDirectorSubPageType): string => {
		switch (_section) {
			case DirectorPageType.PERSONAL:
				return DirectorNavigation[_stage];
			case DirectorPageType.NEXT_OF_KIN:
				return DirectorNavigation[NextOfKinPageType.KIN];
			case DirectorPageType.MEANS_OF_ID:
				return DirectorNavigation[MeansOfIDPageType.ID];
			case DirectorPageType.REVIEW:
				return DirectorNavigation[GenericDirectorPageType.REVIEW];
			default:
				return "";
		}
	}, []);

	return (
		<div className="flex flex-col w-full h-full max-w-7xl mx-auto">
			<div className="flex flex-col items-start w-full h-full relative px-4 2xs:px-10 lg:px-16 ">
				<div className="pt-6 pb-8 lg:w-max lg:max-w-max lg:pb-0 flex justify-start items-center w-full text-sm space-x-2">
					<p className="text-black-quat px-2 py-1.5 w-max text-xs rounded-md bg-blue-senary leading-none select-none">Director Details</p>
					<div className="flex justify-start items-center w-max text-sm space-x-2">
						<ArrowDownIcon className="stroke-current text-black-quin inline-block h-3 w-3 transform -rotate-90" />
						<div
							className={
								"flex justify-start items-center text-xs leading-none " +
								"px-2 py-1.5 w-max rounded-md bg-blue-senary " +
								`text-black-secondary border border-blue-quin font-medium`
							}
						>
							<p className="select-none pointer-events-none capitalize">{getPath(props.section, props.stage || "")}</p>
							<span
								className="ml-4 cursor-pointer text-xs text-black-tertiary hover:text-blue"
								onClick={(e) => {
									history.push(`${props.url}`);
									e.preventDefault();
									e.stopPropagation();
								}}
							>
								<CancelIcon className="stroke-current" />
							</span>
						</div>
					</div>
				</div>
				<div className="w-full flex justify-start items-center flex-col relative flex-grow flex-shrink basis-auto h-fit-available">
					<div className="w-full flex justify-start items-start flex-col absolute top-0 left-0 flex-grow flex-shrink basis-auto overflow-y-auto h-fit-available scrollbar-hide">
						<div className="w-full flex justify-start items-start flex-col flex-grow flex-shrink basis-auto h-fit-available lg:pt-12">
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExternalDirectorLayout;
