import {
	UpdateZambiaBusinessAccountTypeRequest,
	UpdateZambiaBusinessAccountTypeResponse,
	UpdateZambiaBusinessTypeRequest,
	UpdateZambiaBusinessTypeResponse,
	ZambiaBusinessAccountManagerResponse,
} from "./zambia-application.types";
import {abortRequest, getAbortControllerSignal} from "../../../../../helpers/request/abortControllers";
import {makeRequest, makeRequestWithSignal} from "../../../../../helpers/request/makeRequest";

import BusinessDetailsMin from "../../../../../models/account-opening/businessDetailsMin";
import CreatedBy from "../../../../../models/account-opening/createdBy";
import {GenericObject} from "../../../../../helpers/types";
import MetaEntity from "../../../../../models/meta/metaEntity";
import NewAccountOpeningProgress from "../../../../../models/account-opening/progress/newAccountOpeningProgress";
import {NewZambiaAccountOpeningInitState} from "../application.types";
import Parsers from "../../../../../utils/parsers";
import User from "../../../../../models/user";
import ZambiaBusinessDetails from "../../../../../models/account-opening/zambiaBusinessDetails";
import ZambiaIndustry from "../../../../../models/meta/zambiaIndustry";

export enum ZambiaAccountOpeningInitRequests {
	INIT = "zambia-account-opening-init.init",
	ACCOUNT_MANAGER = "zambia-account-opening-init.account-manager",
	UPDATE_BUSINESS_TYPE = "zambia-account-opening-init.update-business-type",
	UPDATE_BUSINESS_ACCOUNT_TYPE = "zambia-account-opening-init.update-business-account-type",
}

export async function zambiaAccountOpeningInit(): Promise<NewZambiaAccountOpeningInitState> {
	const res = await makeRequest("/account-opening/zm/init");

	if (res instanceof Error) {
		throw res;
	}
	return {
		progress: Parsers.classObjectNonNullable(res.progress, NewAccountOpeningProgress),
		application: Parsers.classObjectNonNullable(res.application, ZambiaBusinessDetails),
		isCreator: Parsers.boolean(res.isCreator),
		user: Parsers.classObjectNonNullable(res.user, User),
		createdBy: Parsers.classObjectNonNullable(res.createdBy, CreatedBy),
		options: {
			industry: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).industry, ZambiaIndustry),
			gender: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).gender, MetaEntity),
			idType: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).idType, MetaEntity),
			role: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).role, MetaEntity),
			businessCategory: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).businessCategory, MetaEntity),
			proofOfResidence: !res.options ? [] : Parsers.classObjectArray((res.options as GenericObject).proofOfResidence, MetaEntity),
		},
	};
}
export async function updateZambiaBusinessType(_data: UpdateZambiaBusinessTypeRequest): Promise<UpdateZambiaBusinessTypeResponse> {
	const signal = getAbortControllerSignal(ZambiaAccountOpeningInitRequests.UPDATE_BUSINESS_TYPE);
	const res = await makeRequestWithSignal("/account-opening/zm/registration-status/update", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		isRegistered: Parsers.boolean((res.application as GenericObject).isRegistered),
	};
}
export async function zambiaBusinessAccountManger(): Promise<ZambiaBusinessAccountManagerResponse> {
	const signal = getAbortControllerSignal(ZambiaAccountOpeningInitRequests.ACCOUNT_MANAGER);
	const res = await makeRequestWithSignal("/account-opening/zm/account-manager/details", {}, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		name: Parsers.string(res.name),
		email: Parsers.string(res.email),
		phone: Parsers.string(res.phone),
		photo: Parsers.string(res.photo),
	};
}
export async function updateZambiaBusinessAccountType(
	_data: UpdateZambiaBusinessAccountTypeRequest
): Promise<UpdateZambiaBusinessAccountTypeResponse> {
	const signal = getAbortControllerSignal(ZambiaAccountOpeningInitRequests.UPDATE_BUSINESS_ACCOUNT_TYPE);
	const res = await makeRequestWithSignal("/account-opening/zm/requested-account-type/update", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		applicationMin: Parsers.classObjectNonNullable(res.applicationMin, BusinessDetailsMin),
	};
}

export function abortZambiaAccountOpeningInitRequest(type: ZambiaAccountOpeningInitRequests): void {
	abortRequest(type);
}

export function abortAllZambiaAccountOpeningInitRequests(): void {
	Object.values(ZambiaAccountOpeningInitRequests).forEach((type) => abortRequest(type));
}
