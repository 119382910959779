import React, {CSSProperties, useEffect, useState} from "react";

import Bank from "../../../../../models/bank";
import DropdownLink from "../../../../../components/General/Dropdown/DropdownComponents/DropdownLink";
import {ReactComponent as EmptyXX} from "../../../../../assets/svg/EmptyXX.svg";
import LabelDropdownHead from "../../../../../components/General/Dropdown/LabelDropdownComponent/LabelDropdownHead";
import {FixedSizeList as List} from "react-window";
import {suggestBanks} from "../../../../../helpers/bankSuggestion";
import titleCase from "../../../../../hooks/titleCase";
import useBanksList from "../../../../../helpers/useBanksList";

interface BankDropdownInterface {
	bankCode: string;
	withoutLenco?: boolean;
	accountNumber: string;
	onClickFunc: (bankCode: string) => void;
}

interface LabelInterface {
	name: string;
}

function BankDropdown({onClickFunc, bankCode, withoutLenco, accountNumber}: BankDropdownInterface): JSX.Element {
	const {banksList} = useBanksList({withoutLenco: !!withoutLenco});
	const [banks, setBanks] = useState<Array<Bank | LabelInterface> | null>(null);
	const [selectedBank, setSelectedBank] = useState<Bank | null>(null);
	const [test, setTest] = useState<Bank[] | null>(null);
	const [searchTerm, setSearchTerm] = useState<string>("");

	useEffect(() => {
		if (!banksList) return;
		setBanks(banksList);
	}, [banksList]);

	useEffect(() => {
		if (!bankCode || !banksList) return setSelectedBank(null);
		setSelectedBank((banksList.find((el) => el.code === bankCode) as Bank) || null);
		return;
	}, [banksList, bankCode]);

	useEffect(() => {
		if (!accountNumber || accountNumber.length < 8 || !banksList) return;
		setTest(suggestBanks(banksList, accountNumber));
	}, [accountNumber, banksList]);

	useEffect(() => {
		if (!banksList) return;
		if (test && test.length > 0) {
			const arrayContainer = [];
			arrayContainer.push({name: "Suggested Banks"});
			arrayContainer.push(...test);
			arrayContainer.push({name: "All Banks"});
			arrayContainer.push(...banksList);
			setBanks(arrayContainer);
		} else {
			const arrayContainer = [];
			arrayContainer.push({name: "All Banks"});
			arrayContainer.push(...banksList);
			setBanks(arrayContainer);
		}
		return;
	}, [test, banksList]);

	useEffect(() => {
		const filteredAllBanks = (banksList || []).filter((item: Bank | LabelInterface) => {
			if (!item || !item.name) {
				return false;
			}
			if (!searchTerm || searchTerm.trim().length === 0) {
				return true;
			}
			if (item instanceof Bank) {
				return item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.shortName.toLowerCase().includes(searchTerm.toLowerCase());
			}
			return false;
		});
		if (searchTerm.length === 0 || searchTerm.trim().length === 0) {
			if (test && test?.length > 0) {
				return setBanks([{name: "Suggested Banks"}, ...test, {name: "All Banks"}, ...filteredAllBanks]);
			}
			return setBanks([{name: "All Banks"}, ...filteredAllBanks]);
		}
		if (filteredAllBanks.length > 0) {
			setBanks([{name: "Filtered Banks"}, ...filteredAllBanks]);
		} else {
			setBanks([]);
		}
	}, [banksList, searchTerm]);

	const handleChangeSearchTerm = (e: string) => {
		setSearchTerm(e);
	};

	return (
		<>
			<LabelDropdownHead
				placeholder="Select Bank"
				inputValue={titleCase((selectedBank && selectedBank.name) || "")}
				searchTerm={searchTerm}
				handleChangeSearchTerm={handleChangeSearchTerm}
				searchPlaceholder="Search for bank"
				isSearchable={!!(banksList && banksList.length > 4)}
				clickAndClose
			>
				{banks && (
					<>
						<List
							className="List"
							height={banks.length * 40 > 160 ? 160 : banks.length * 40}
							itemCount={banks && banks.length}
							itemSize={40}
							width={"100%"}
						>
							{({index, style}: {index: number; style: CSSProperties | undefined}) => (
								<>
									{!(banks[index] as Bank).code && (
										<div
											className="flex w-full items-center justify-start bg-gray-100 px-4 text-xs text-black "
											style={style}
											key={index}
										>
											{banks[index].name}
										</div>
									)}
									{(banks[index] as Bank).code && (
										<>
											<div className="flex w-full flex-col" style={style} key={index}>
												<DropdownLink
													onClick={() => {
														onClickFunc((banks[index] as Bank).code);
													}}
												>
													<div className="flex items-center justify-start px-4 text-sm">{titleCase(banks[index].name)}</div>
												</DropdownLink>
											</div>
										</>
									)}
								</>
							)}
						</List>
					</>
				)}
				{banks && banks.length < 1 && (
					<div className="flex h-14 w-full flex-row items-center justify-center space-x-2 text-sm text-black-tertiary">
						<EmptyXX className="h-10 w-10" />
						<span>No Banks Found</span>
					</div>
				)}
			</LabelDropdownHead>
		</>
	);
}

export default BankDropdown;

// const {banksList} = useBanksList();

// const suggestedBanks: Bank[] | null = !(!accountNumber || accountNumber.length < 6) ? suggestBanks(banksList, accountNumber) : null;
// const suggestedBanksExist = !!(suggestedBanks && suggestedBanks.length > 0);
// const [banks, setBanks] = useState<Array<Bank | LabelInterface> | null>([
// ...(suggestedBanksExist ? [{name: "Suggested Banks"}] : []),
// ...(suggestedBanksExist ? suggestedBanks : []),
// {name: "All Banks"},
// ...banksList,
// ]);
// const selectedBank: Bank | null = bankCode ? banksList.find((el) => el.code === bankCode) || null : null;

// const [searchTerm, setSearchTerm] = useState<string>("");

// useEffect(() => {
// const filteredAllBanks = (banksList || []).filter((item: Bank | LabelInterface) => {
// if (!item || !item.name) {
// return false;
// }
// if (!searchTerm || searchTerm.trim().length === 0) {
// return true;
// }
// return item.name.toLowerCase().includes(searchTerm.toLowerCase());
// });
// if (searchTerm.length === 0 || searchTerm.trim().length === 0) {
// if (suggestedBanks && suggestedBanks?.length > 0) {
// return setBanks([{name: "Suggested Banks"}, ...suggestedBanks, {name: "All Banks"}, ...filteredAllBanks]);
// }
// return setBanks([{name: "All Banks"}, ...filteredAllBanks]);
// }
// if (filteredAllBanks.length > 0) {
// setBanks([{name: "Filtered Banks"}, ...filteredAllBanks]);
// } else {
// setBanks([]);
// }
// }, [searchTerm, suggestedBanks?.length]);

// const handleChangeSearchTerm = (e: string) => {
// setSearchTerm(e);
// };
