import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {ReactComponent as EyesIcon} from "../../../../../../assets/svg/General/Eyes/eyes.svg";
import React from "react";
interface Props {
	text: string;
	icon: React.ReactNode;
	canNotPreview?: boolean;
	handlePreviewDocumentFunc: () => void;
}

function PreviewCard(props: Props): JSX.Element {
	return (
		<div className="flex flex-col items-center justify-center w-full space-y-10">
			<div className="flex flex-col 4xs:flex-row items-center justify-between space-y-4 4xs:space-y-0 w-full h-full 4xs:h-14 py-4 4xs:py-0 px-6 border border-black-quat rounded-lg">
				<div className="flex flex-row items-center space-x-2 4xs:space-x-4">
					<div className="flex justify-center items-center h-full w-full">{props.icon}</div>
					<span className="text-black-tertiary text-sm 4xs:text-base whitespace-nowrap">{props.text}</span>
				</div>
				<div className="flex flex-row justify-start space-x-2">
					{props.canNotPreview ? (
						"-"
					) : (
						<ButtonComp color="blue" buttonType="tertiary" size="md" func={props.handlePreviewDocumentFunc}>
							Preview
							<span className="ml-1">
								<EyesIcon />
							</span>
						</ButtonComp>
					)}
				</div>
			</div>
		</div>
	);
}

export default PreviewCard;
