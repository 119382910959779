import {AccountModalStageType, AccountState, CreateAccountObj} from "./accountsSlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import CreateAccountRequest from "../../../models/createAccountRequest";
import UserAccount from "../../../models/userAccount";
import {getAccountSummaryResponse} from "../../../modules/Dashboard/Accounts/Services/accountsApi.types";
import produce from "immer";

const initialState: AccountState = {
	// general
	isAddFundsModalOpen: false,
	isZambiaAddFundsModalOpen: false,
	isEditAccountDescriptionModalOpen: false,

	// accounts
	accounts: [],
	subAccountName: "",
	pendingAccounts: [],
	subAccountModalStage: AccountModalStageType.INITIAL,
	subAccountDescription: "",

	//individual account
	individualAccount: null,
	individualAccountSummary: null,
	isAboutMyAccountModalOpen: false,

	//create sub account modal
	listOfSubAccountsToBeActivated: [],
	// listOfSubAccountsToBeCreated: [],
	listOfSubAccountsToBeCreated: [
		{name: "", description: "", canDelete: false, index: Math.floor(Math.random() * 100000000000000) + 1, isLoading: false},
	],
	pendingSubAccountsToBeActivatedList: [],
};

export const accountSlice = createSlice({
	name: "account",
	initialState,
	reducers: {
		//  general
		openAddFundsModal: (state: AccountState) => {
			state.isAddFundsModalOpen = true;
		},
		closeAddFundsModal: (state: AccountState) => {
			state.isAddFundsModalOpen = false;
		},
		openZambiaAddFundsModal: (state: AccountState) => {
			state.isZambiaAddFundsModalOpen = true;
		},
		closeZambiaAddFundsModal: (state: AccountState) => {
			state.isZambiaAddFundsModalOpen = false;
		},
		openEditAccountDescriptionModal: (state: AccountState) => {
			state.isEditAccountDescriptionModalOpen = true;
		},
		closeEditAccountDescriptionModal: (state: AccountState) => {
			state.isEditAccountDescriptionModalOpen = false;
		},

		//account
		addAccount: (state: AccountState, action: PayloadAction<string>) => {
			state.accounts = produce(state.accounts, (draft) => {
				draft.push(action.payload);
			});
		},
		setAccounts: (state: AccountState, action: PayloadAction<string[]>) => {
			state.accounts = action.payload;
		},
		resetAccounts: (state: AccountState) => {
			state.accounts = [];
		},
		setSubAccountName: (state: AccountState, action: PayloadAction<string>) => {
			state.subAccountName = action.payload;
		},
		setSubAccountModalStage: (state: AccountState, action: PayloadAction<AccountModalStageType>) => {
			state.subAccountModalStage = action.payload;
		},
		setSubAccountDescription: (state: AccountState, action: PayloadAction<string>) => {
			state.subAccountDescription = action.payload;
		},
		addPendingAccount: (state: AccountState, action: PayloadAction<CreateAccountRequest>) => {
			state.pendingAccounts.push(action.payload);
			state.pendingSubAccountsToBeActivatedList.push(action.payload);
		},
		addMultiplePendingAccount: (state: AccountState, action: PayloadAction<CreateAccountRequest[]>) => {
			action.payload.forEach((_pending) => state.pendingAccounts.push(_pending));
		},
		removePendingAccount: (state: AccountState, action: PayloadAction<CreateAccountRequest>) => {
			state.pendingAccounts = state.pendingAccounts.filter((_userAccount) => _userAccount.id !== action.payload.id);
			state.pendingSubAccountsToBeActivatedList = state.pendingSubAccountsToBeActivatedList.filter(
				(_userAccount) => _userAccount.id !== action.payload.id
			);
		},
		resetSubAccountData: (state: AccountState) => {
			state.subAccountName = "";
			state.subAccountDescription = "";
			state.subAccountModalStage = AccountModalStageType.INITIAL;
		},
		resetAccountsData: (state: AccountState) => {
			state.subAccountName = "";
			state.subAccountDescription = "";
			state.subAccountModalStage = AccountModalStageType.INITIAL;
			state.isAddFundsModalOpen = false;
			state.isZambiaAddFundsModalOpen = false;
			state.isEditAccountDescriptionModalOpen = false;
			state.accounts = [];
			state.subAccountName = "";
			state.pendingAccounts = [];
			state.subAccountModalStage = AccountModalStageType.INITIAL;
			state.subAccountDescription = "";
		},

		//individual Account
		setIndividualAccount: (state: AccountState, action: PayloadAction<UserAccount>) => {
			state.individualAccount = action.payload;
		},
		openAboutMyAccountModal: (state: AccountState) => {
			state.isAboutMyAccountModalOpen = true;
		},
		closeAboutMyAccountModal: (state: AccountState) => {
			state.isAboutMyAccountModalOpen = false;
		},
		updateIndividualAccount: (state: AccountState, action: PayloadAction<UserAccount>) => {
			state.individualAccount = action.payload;
		},
		setIndividualAccountSummary: (state: AccountState, action: PayloadAction<getAccountSummaryResponse>) => {
			state.individualAccountSummary = action.payload;
		},
		resetIndividualAccount: (state: AccountState) => {
			state.individualAccount = null;
			state.individualAccountSummary = null;
			state.isAddFundsModalOpen = false;
			state.isZambiaAddFundsModalOpen = false;
			state.isAboutMyAccountModalOpen = false;
			state.isEditAccountDescriptionModalOpen = false;
		},

		//Create sub account
		addSubAccountToCreate: (state: AccountState, action: PayloadAction<CreateAccountObj>) => {
			state.listOfSubAccountsToBeCreated.push(action.payload);
		},
		addSubAccountToActivate: (state: AccountState, action: PayloadAction<string>) => {
			state.listOfSubAccountsToBeActivated = produce(state.listOfSubAccountsToBeActivated, (draft) => {
				draft.push(action.payload);
			});
		},
		setSubAccountLoadingTrue: (state: AccountState, action: PayloadAction<number>) => {
			const _subAccountIndex = state.listOfSubAccountsToBeCreated.findIndex((_sub) => _sub.index === action.payload);
			state.listOfSubAccountsToBeCreated[_subAccountIndex].isLoading = true;
		},
		setSubAccountLoadingFalse: (state: AccountState, action: PayloadAction<number>) => {
			const _subAccountIndex = state.listOfSubAccountsToBeCreated.findIndex((_sub) => _sub.index === action.payload);
			state.listOfSubAccountsToBeCreated[_subAccountIndex].isLoading = false;
		},
		editSubAccountToCreate: (state: AccountState, action: PayloadAction<CreateAccountObj>) => {
			const _subAccountIndex = state.listOfSubAccountsToBeCreated.findIndex((_sub) => _sub.index === action.payload.index);
			state.listOfSubAccountsToBeCreated[_subAccountIndex] = action.payload;
		},
		removeSubAccountToCreate: (state: AccountState, action: PayloadAction<number>) => {
			state.listOfSubAccountsToBeCreated = state.listOfSubAccountsToBeCreated.filter((_account) => _account.index !== action.payload);
		},
		resetSubAccountToCreate: (state: AccountState) => {
			state.listOfSubAccountsToBeCreated = [
				{name: "", description: "", canDelete: false, index: Math.floor(Math.random() * 100000000000000) + 1, isLoading: false},
			];
		},
		resetSubAccountsToBeActivated: (state: AccountState) => {
			state.pendingSubAccountsToBeActivatedList = [];
		},
		resetAllCreateSubAccountData: (state: AccountState) => {
			state.pendingSubAccountsToBeActivatedList = [];
			state.listOfSubAccountsToBeActivated = [];
			state.listOfSubAccountsToBeCreated = [
				{name: "", description: "", canDelete: false, index: Math.floor(Math.random() * 100000000000000) + 1, isLoading: false},
			];
			state.pendingSubAccountsToBeActivatedList = [];
		},
	},
});

export const {
	addAccount,
	setAccounts,
	resetAccounts,
	setSubAccountName,
	resetSubAccountData,
	setIndividualAccount,
	openAddFundsModal,
	openZambiaAddFundsModal,
	closeZambiaAddFundsModal,
	openAboutMyAccountModal,
	openEditAccountDescriptionModal,
	closeAddFundsModal,
	closeAboutMyAccountModal,
	closeEditAccountDescriptionModal,
	resetIndividualAccount,
	updateIndividualAccount,
	setSubAccountDescription,
	setIndividualAccountSummary,

	addPendingAccount,
	addMultiplePendingAccount,
	removePendingAccount,
	setSubAccountModalStage,

	resetAccountsData,

	addSubAccountToCreate,
	addSubAccountToActivate,
	setSubAccountLoadingTrue,
	setSubAccountLoadingFalse,
	editSubAccountToCreate,
	removeSubAccountToCreate,
	resetSubAccountToCreate,
	resetSubAccountsToBeActivated,
	resetAllCreateSubAccountData,
} = accountSlice.actions;

export default accountSlice.reducer;
