import Parsers from "../utils/parsers";
import {GenericObject} from "../helpers/types";
import {AccountBalanceInstructionType} from "./userAccount.constants";
import isNullOrUndefined from "../utils/isNullOrUndefined";
import {immerable} from "immer";

class AccountBalanceInstructionRatio {
	[immerable] = true;

	constructor(public percentage: number, public userAccountId: string) {}

	static create(obj: GenericObject): AccountBalanceInstructionRatio {
		return new AccountBalanceInstructionRatio(Parsers.number(obj.percentage), Parsers.string(obj.userAccountId));
	}
}

export default class AccountBalanceInstruction {
	[immerable] = true;

	constructor(public type: AccountBalanceInstructionType, public amount: number | null, public ratio: AccountBalanceInstructionRatio[] | null) {}

	static create(obj: GenericObject): AccountBalanceInstruction {
		return new AccountBalanceInstruction(
			Parsers.number(obj.type),
			Parsers.nullableNumber(obj.amount),
			isNullOrUndefined(obj.ratio) ? null : Parsers.classObjectArray(obj.ratio, AccountBalanceInstructionRatio)
		);
	}
}
