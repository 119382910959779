import React, {useCallback, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import BulkPaymentHeaders from "./BulkPaymentHeaders";
import {BulkPaymentInitRequest} from "../../../Services/SendMoney/bulkTransferApi.types";
import BulkTransferDetailRow from "../../TableRows/BulkTransferDetailRow";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import {IRootState} from "../../../../../../redux/rootReducer";
import {ReactComponent as Lock} from "../../../../../../assets/images/yellowlock.svg";
import {PaymentDetail} from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import formatNumber from "../../../../../../utils/formatNumber";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import useBulkTransfer from "../../../Hooks/State/SendMoney/BulkTransfer/useBulkTransfer";

function ReviewPayment(): JSX.Element {
	const dispatch = useDispatch();
	const {bulkPaymentResponse, handleBulkTransfer, isBulkPaymentLoading} = useBulkTransfer();
	const transferDetailArray = useSelector((state: IRootState) => state.bulkTransfer.transferDetailArray);
	const bulkTransfersAmountTotal = useSelector((state: IRootState) => state.bulkTransfer.bulkTransfersAmountTotal);

	useEffect(() => {
		if (!bulkPaymentResponse) return;
		dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
	}, [bulkPaymentResponse]);

	const handleInitiatePayment = useCallback(() => {
		const paymentsArray: PaymentDetail[] = transferDetailArray.map((_el) => ({
			originatingAccountId: _el.originatingAccountId as string,
			recipient: _el.recipient,
			amount: _el.amount,
			purpose: _el.purpose,
			key: _el.key,
		}));
		const bulkPaymentDetails: BulkPaymentInitRequest = {
			payments: paymentsArray,
			otp: "",
		};
		void handleBulkTransfer(bulkPaymentDetails);
	}, []);

	return (
		<>
			<div className="flex h-full w-full flex-col">
				<div className="relative flex h-full w-full flex-col items-center justify-start">
					<div className="relative flex h-full w-full flex-col">
						<div className="absolute left-0 top-0 flex h-full max-h-full w-full flex-col overflow-y-auto">
							<BulkPaymentHeaders />
							<div className="flex items-center justify-start">
								<div className="flex w-full flex-col space-y-8">
									<div className="w-full">
										<div className="flex flex-col items-center justify-center">
											<span className="text-sm font-normal text-black-tertiary"> You’re sending </span>
											<div className="flex flex-row">
												<div className="text-4xl font-medium">
													{transferDetailArray && transferDetailArray.length > 0 && (
														<span>
															<CurrencyCode />
															{formatNumber(bulkTransfersAmountTotal)}
														</span>
													)}
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className=" m-auto w-full max-w-md pt-8">
								<div className=" box-border flex w-full flex-row  items-center justify-center space-x-2  rounded-lg bg-warning-backdrop px-4">
									<div>
										<Lock />
									</div>
									<p className="py-4 text-left text-sm font-normal text-warning">
										{" "}
										Ensure you verify that the recipient is genuine as payments can not be reversed after approval.
									</p>
								</div>
							</div>

							<div className="relative flex h-full w-full flex-1 flex-shrink flex-grow">
								<div className="relative mt-6 flex h-full w-full flex-1">
									<div className="flex h-fit w-full flex-1 overflow-x-auto">
										<div className="left-0 top-0 h-full w-full">
											<table className="tableT w-full">
												<thead className="tableheader relative z-10 rounded-bl-2xl border-grey-backdrop text-left text-sm text-black">
													<tr className="relative">
														<th className="tableTh1 px-6 py-3 font-normal">FROM</th>
														<th className="px-6 py-3 font-normal">TO</th>
														<th className="px-6 py-3 font-normal">PURPOSE</th>
														<th className="px-6 py-3 font-normal">AMOUNT</th>
														<th className="tableTh2 px-6 py-3 font-normal"></th>
													</tr>
												</thead>
												<tbody>
													{transferDetailArray &&
														transferDetailArray.map((el, index) => (
															<BulkTransferDetailRow reviewMode={false} data={el} key={index} />
														))}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="flex w-full flex-row items-center justify-center space-x-4 py-6">
						<ButtonComp
							type="button"
							ripple="light"
							buttonType="secondary"
							color="grey"
							func={() => dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS))}
							disable={isBulkPaymentLoading}
						>
							Back
						</ButtonComp>

						<ButtonComp
							type="submit"
							color="blue"
							ripple="light"
							buttonType="primary"
							func={() => {
								if (transferDetailArray.some((el) => !el.canApprove)) {
									handleInitiatePayment();
								} else {
									dispatch(setPaymentStage(PaymentStageType.MAKE_PAYMENT));
								}
							}}
							isLoading={isBulkPaymentLoading}
						>
							{transferDetailArray.some((el) => !el.canApprove) ? <span>Initiate Payment</span> : <span>Make Payment</span>}
						</ButtonComp>
					</div>
				</div>
			</div>
		</>
	);
}

export default ReviewPayment;
