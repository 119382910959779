import React, {useCallback, useEffect} from "react";
import {isSwitchingBusinessTrue, setCompanyId} from "../../../redux/init/slice/initSlice";
import {useDispatch, useSelector} from "react-redux";

import {CompanyInterface} from "../../../helpers/types";
import DropdownHead from "../../General/Dropdown/DropdownComponents/DropdownHead";
import DropdownLink from "../../General/Dropdown/DropdownComponents/DropdownLink";
import {IRootState} from "../../../redux/rootReducer";
import {ReactComponent as PlusSvg} from "../../../assets/svg/BusinessPlus.svg";
import {Routes} from "../../../routes/routes.constants";
import UserAccount from "../../../models/userAccount";
import {clearCustomerAccounts} from "../../../redux/customerAccount/slice/customerAccountSlice";
import getAvatarColorClass from "../../../helpers/avatarColors";
import {resetAllApplicationState} from "../../../redux/application/applicationSlice";
import {resetTransactionState} from "../../../redux/transaction/slice/transactionSlice";
import {resetZambiaAllApplicationState} from "../../../redux/application/zambiaApplicationSlice";
import {resetZambiaCachedCollectionsSettlementsList} from "../../../redux/zambia/collections/settlements/zambiaCollectionsSettlementsSlice";
import {resetZambiaCachedCollectionsTransactionsList} from "../../../redux/zambia/collections/transactions/zambiaCollectionsTransactionsSlice";
import {resetZambiaCachedPayouts} from "../../../redux/zambia/payouts/zambiaPayoutsSlice";
import {useHistory} from "react-router";
import {useState} from "react";

interface BusinessDropdownProps {
	handleAddNewBusiness(): void;
}

function BusinessDropdown({handleAddNewBusiness}: BusinessDropdownProps): JSX.Element {
	const history = useHistory();
	const dispatch = useDispatch();

	const account = useSelector((state: IRootState) => state.init.account);
	const main = useSelector((state: IRootState) => state.init.main);
	const companyId = useSelector((state: IRootState) => state.init.selectedCompanyId);

	const [mainAccount, setMainAccount] = useState<UserAccount | undefined>(undefined);
	const [subCompanies, setSubCompanies] = useState<CompanyInterface[]>([]);
	const [searchTerm, setSearchTerm] = useState<string>("");

	//Function to setting company details
	useEffect(() => {
		//Set array of non-selected companies
		setSubCompanies(() => (account?.companies || []).filter((el) => el.id !== companyId));
		if (main) {
			//Set account details of the selected company
			setMainAccount(() => main?.companyDetails?.accounts?.find((el) => el.isMain === true));
		}
	}, [companyId, account, main]);

	useEffect(() => {
		const filteredAccounts = (account?.companies || [])
			.filter((el) => el.id !== companyId)
			.filter((item: CompanyInterface) => {
				if (!item || !item.name) {
					return false;
				}
				if (!searchTerm || searchTerm.trim().length === 0) {
					return true;
				}
				return item.name.toLowerCase().includes(searchTerm.toLowerCase());
			});
		setSubCompanies(filteredAccounts);
	}, [account, searchTerm]);

	const handleChangeSearchTerm = useCallback((_term: string) => {
		setSearchTerm(_term);
	}, []);

	const content = () => {
		return (
			<>
				<>
					<div className="flex w-full max-w-9 flex-row items-center justify-between">
						<div>
							{account?.companies
								?.filter((el) => el.id === companyId)
								.map((el, index) => (
									<div
										key={index}
										className={`flex h-8 w-8 items-center justify-center rounded-full bg-blue-senary text-center text-blue subpixel-antialiased ${getAvatarColorClass(
											el.id
										)}`}
									>
										{el.name.slice(0, 1)}
									</div>
								))}
						</div>

						<div className=" w-full overflow-hidden overflow-ellipsis whitespace-nowrap px-2 subpixel-antialiased">
							<div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
								<div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-sm font-medium text-black">
									{account?.companies?.filter((el) => el.id === companyId).map((el) => el.name)}
								</div>
								<div className=" overflow-hidden overflow-ellipsis whitespace-nowrap text-xs font-normal text-black-tertiary">
									{mainAccount?.bankAccount?.accountNumber}
								</div>
							</div>
						</div>
					</div>
				</>
			</>
		);
	};

	return (
		<>
			<DropdownHead
				placeholder={content()}
				placement="center"
				searchTerm={searchTerm}
				handleChangeSearchTerm={handleChangeSearchTerm}
				isSearchable={!!(account && account.companies && account.companies.length > 4)}
				noHoverBg
				clickAndClose
			>
				<div className={`w-full ` + `${subCompanies.length > 3 ? "h-full max-h-42 overflow-y-auto" : ""}`}>
					{subCompanies &&
						subCompanies.map((_company: CompanyInterface, index: number) => (
							<DropdownLink
								onClick={() => {
									history.push(Routes.DASHBOARD);
									dispatch(clearCustomerAccounts());
									dispatch(resetZambiaCachedCollectionsTransactionsList());
									dispatch(resetZambiaCachedCollectionsSettlementsList());
									dispatch(resetZambiaCachedPayouts());
									dispatch(resetTransactionState());
									dispatch(setCompanyId(_company.id));
									dispatch(resetZambiaAllApplicationState());
									dispatch(resetAllApplicationState());
									dispatch(isSwitchingBusinessTrue());
								}}
								key={index}
								big
							>
								<div className="flex w-full flex-row items-center justify-start px-4">
									<div>
										<div
											className={`flex h-8 w-8 items-center justify-center rounded-full text-center ${getAvatarColorClass(
												_company.id
											)}`}
										>
											{_company?.name?.slice(0, 1)}
										</div>
									</div>

									<div className=" w-full overflow-hidden overflow-ellipsis px-2 ">
										<div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-left">
											<div className="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize">
												{_company?.name}
											</div>
										</div>
									</div>
								</div>
							</DropdownLink>
						))}
				</div>
				<DropdownLink onClick={() => handleAddNewBusiness()} big isLink>
					<div className="flex w-full items-center justify-start px-4">
						<span className=" text-xl ">
							<PlusSvg className="stroke-current ease-in-out" />
						</span>
						<div className="ml-4 inline items-center justify-center text-xs">Add a New Business</div>
					</div>
				</DropdownLink>
			</DropdownHead>
		</>
	);
}

export default BusinessDropdown;
