import React from "react";
import Transaction from "../../../../../../../models/transaction";

interface Props {
	transaction: Transaction;
}

function TransactionDetailCardHeader({transaction}: Props): JSX.Element {
	return (
		<>
			{transaction && !transaction.isCardPayment && transaction.isDebit() && "Payout Details"}
			{transaction && !transaction.isCardPayment && transaction.isCredit() && "Inflow Details"}
			{transaction && transaction.isCardPayment && "Transaction details"}
		</>
	);
}

export default TransactionDetailCardHeader;
