import "animate.css";

import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import Modal from "../../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../../components/General/Modals/ModalBody";
import ModalHeader from "../../../../../../components/General/Modals/ModalHeader";
import React from "react";

interface PreviewModalProps {
	data: Blob | null;
	title: string;
	active: boolean;

	toggler: () => void;
	handleToggleShowUploadModal?: () => void;
}
function PreviewModal(props: PreviewModalProps): JSX.Element {
	const {active, toggler, data, title} = props;

	return (
		<>
			<div className={`w-full ${!active ? "pointer-events-none" : ""}`}>
				<Modal size="lg" active={active} toggler={toggler}>
					<ModalHeader onClose={toggler}>{title}</ModalHeader>
					<ModalBody>
						{(data?.type === "image/png" || data?.type === "image/jpeg" || data?.type === "image/jpg") && (
							<div className="  relative flex h-fit w-full flex-col items-center justify-center space-y-4">
								<div className="flex h-full w-full items-center justify-center">
									<img className="h-full w-full" src={URL.createObjectURL(data)}></img>
								</div>
								{props.handleToggleShowUploadModal && (
									<div>
										<ButtonComp
											type="button"
											color="blue"
											ripple="light"
											buttonType="secondary"
											func={() => {
												props.handleToggleShowUploadModal && props.handleToggleShowUploadModal();
												toggler();
											}}
										>
											<span className="">Re-Take Picture</span>
										</ButtonComp>
									</div>
								)}
							</div>
						)}
						{data?.type === "application/pdf" && (
							<div className="relative flex h-fit w-full flex-col items-center justify-center space-y-4">
								<div className="flex h-fit w-full items-center justify-center">
									<iframe className="h-100vh w-80%" src={`${URL.createObjectURL(data)}#view=fitH`} title={title} />
									{/* <object className="h-fit w-full">
										<iframe src={`${URL.createObjectURL(data)}#view=fitH`} height="100%" width="100%"></iframe>
									</object> */}
								</div>
							</div>
						)}
					</ModalBody>
				</Modal>
			</div>
		</>
	);
}

export default PreviewModal;
