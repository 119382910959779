import {CollectionsState, ZambiaCollectionsStageType} from "./collectionsSlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

const initialState: CollectionsState = {
	collectionsStage: null,
};

export const zambiaCollectionsSlice = createSlice({
	name: "zambiaCollections",
	initialState,
	reducers: {
		setZambiaCollectionsStage: (state: CollectionsState, action: PayloadAction<ZambiaCollectionsStageType>) => {
			state.collectionsStage = action.payload;
		},

		resetAllZambiaCollectionsData: (state: CollectionsState) => {
			//<===for Collections stages and inner stages===>
			state.collectionsStage = null;
		},
	},
});

export const {setZambiaCollectionsStage, resetAllZambiaCollectionsData} = zambiaCollectionsSlice.actions;

export default zambiaCollectionsSlice.reducer;
