import {useCallback, useState} from "react";

import {ErrorMessage} from "../../../../../../../helpers/request/makeRequest";
import {InitiateMobileMoneyRequest} from "../../../../Services/Zambia/mobileMoney.types";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {initiateMobileMoney} from "../../../../Services/Zambia/mobileMoney";
import {useDispatch} from "react-redux";

interface UseMobileMoneyInterface {
	mobileMoneyError: string;
	isMobileMoneyLoading: boolean;
	handleMobileMoney: (_data: InitiateMobileMoneyRequest) => void;
}

interface Props {
	onComplete: (_phone: string) => void;
	onUpdate: () => void;
	onLast: () => void;
}
function useMobileMoney(props: Props): UseMobileMoneyInterface {
	const dispatch = useDispatch();
	const [isMobileMoneyLoading, setIsMobileMoneyLoading] = useState<boolean>(false);
	const [mobileMoneyError, setMobileMoneyError] = useState<string>("");

	const handleMobileMoney = useCallback(
		async (_data: InitiateMobileMoneyRequest) => {
			try {
				setMobileMoneyError("");
				setIsMobileMoneyLoading(true);
				const res = await initiateMobileMoney(_data);
				res.requireOtp ? props.onComplete(_data.phoneNumber) : props.onLast();
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				if (errorMessage === ErrorMessage.DUPLICATE_PAYMENT_ERROR) {
					props.onUpdate();
				}
				dispatch(errorTrue({message: errorMessage}));
				setMobileMoneyError(errorMessage);
			} finally {
				setIsMobileMoneyLoading(false);
			}
		},
		[dispatch]
	);

	return {
		mobileMoneyError,
		isMobileMoneyLoading,
		handleMobileMoney,
	};
}

export default useMobileMoney;
