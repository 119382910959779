import {ReactComponent as AccountIcon} from "../../../assets/svg/DashboardLayout/AccountDropdown/AccountIcon.svg";
import {AccountInitState} from "../../../redux/init/slice/initSlice.types";
import {ReactComponent as ChatIcon} from "../../../assets/svg/ChatIcon.svg";
import DropdownHead from "../../General/Dropdown/DropdownComponents/DropdownHead";
import DropdownLink from "../../General/Dropdown/DropdownComponents/DropdownLink";
import {ReactComponent as FAQIcon} from "../../../assets/svg/FAQIcon.svg";
import {IRootState} from "../../../redux/rootReducer";
import IntercomHelper from "../../../helpers/intercom";
import {Link} from "react-router-dom";
import {ReactComponent as LogoutIcon} from "../../../assets/svg/LogoutIcon.svg";
import React from "react";
import UserMin from "../../../models/userMin";
import useLogout from "../../../redux/init/services/hooks/useLogout";
import {useSelector} from "react-redux";

const AccountOptions = [
	{
		icon: <ChatIcon className="stroke-current ease-in-out" />,
		text: "Get Help",
		onClick: function (e: React.MouseEvent, initialize: () => void) {
			e.preventDefault();
			initialize();
		},
	},
	{
		icon: <FAQIcon className="stroke-current ease-in-out" />,
		text: "FAQ",
		link: process.env.REACT_APP_FAQ_URL,
	},
	{
		icon: <LogoutIcon className="stroke-current ease-in-out" />,
		text: "Logout",
		onClick: function (e: React.MouseEvent, handleLogout: () => void) {
			e.preventDefault();
			handleLogout();
		},
	},
];

function AccountDropdownPlaceholder({user}: {user: UserMin | null}): JSX.Element {
	return (
		<div className="flex items-center justify-center">
			<AccountIcon className=" fill-current stroke-current text-current ease-in-out" />
			<span className="ml-2 hidden  text-sm font-normal xl:inline">{user && user.name}</span>
		</div>
	);
}

function AccountDropdown(): JSX.Element {
	const account = useSelector<IRootState, AccountInitState | null>((state) => state.init.account);
	const {handleLogout} = useLogout();

	const handleShowIntercom = () => {
		IntercomHelper.show();
	};

	return (
		<DropdownHead
			placeholder={<AccountDropdownPlaceholder user={account ? account.user : null} />}
			size="sm"
			placement="right"
			clickAndClose
			noOutlineBorder
			outline
			noHoverBg
		>
			{AccountOptions &&
				AccountOptions.map((option, index) => (
					<DropdownLink
						onClick={(e) => {
							if (option.onClick) {
								if (option.text === "Logout") {
									option.onClick(e, handleLogout);
								}

								if (option.text === "Get Help") {
									option.onClick(e, handleShowIntercom);
								}
							}
						}}
						key={index}
						isLink
						redHover={option.text === "Logout"}
					>
						{option.link && (
							<>
								<Link to={{pathname: option.link}} target="_blank">
									<div className="flex items-center justify-start px-4">
										<span className="fill-current stroke-current text-base ">{option.icon}</span>
										<div className="ml-4  inline items-center justify-center text-sm"> {option.text}</div>
									</div>
								</Link>
							</>
						)}
						{!option.link && (
							<>
								<div className="flex items-center justify-start px-4">
									<span className="fill-current stroke-current text-base ">{option.icon}</span>
									<div className="ml-4  inline items-center justify-center text-sm"> {option.text}</div>
								</div>
							</>
						)}
					</DropdownLink>
				))}
		</DropdownHead>
	);
}

export default AccountDropdown;
