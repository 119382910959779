import React, {useState} from "react";

import {ReactComponent as ActiveCancel} from "../../../../../assets/svg/General/Trash/ActiveTrash.svg";
import {ReactComponent as ActiveEdit} from "../../../../../assets/svg/General/Edit/ActiveEdit.svg";
import {ReactComponent as Cancel} from "../../../../../assets/svg/General/Trash/InactiveTrash.svg";
import CurrencyCode from "../../../../../components/General/CurrencyCode";
import {ERROR} from "../../../../../helpers/AppConstants";
import {ReactComponent as Edit} from "../../../../../assets/svg/General/Edit/InactiveEdit.svg";
import Fee from "../../../../../components/General/Fee";
import {TransactionStatus} from "../../../../../models/transaction.constants";
import {TransferDetail} from "../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import formatNumber from "../../../../../utils/formatNumber";
import getBankName from "../../../../../helpers/getBankName";
import isNullOrUndefined from "../../../../../utils/isNullOrUndefined";
import titleCase from "../../../../../hooks/titleCase";

interface BulkTransferDetailRowProps {
	data: TransferDetail;
	reviewMode: boolean;
	processingMode?: boolean;
	activateAccount?: boolean;
	onClick?: (_data: TransferDetail) => void;
	editFunc?: (e: TransferDetail) => void;
	removeFunc?: (e: number) => void;
}
function BulkTransferDetailRow(props: BulkTransferDetailRowProps): JSX.Element {
	const {data, reviewMode, activateAccount, processingMode, editFunc, removeFunc, onClick} = props;
	const [isEditHover, setIsEditHover] = useState(false);
	const [isRemoveHover, setIsRemoveHover] = useState(false);

	return (
		<tr
			className={
				`te cursor-pointer whitespace-nowrap border-b-2 border-grey-secondary text-sm text-gray-700 hover:bg-blue-senary ` +
				`${data && (data.purpose === ERROR || data.originatingAccountId === ERROR || data.amount === ERROR) ? "bg-error-backdrop" : ""} `
			}
			onClick={() => onClick && onClick(data)}
		>
			{data && (
				<>
					<td className="px-6 py-3">
						<div className="flex flex-col justify-start">
							<span className="text-sm font-normal">
								{data.originatingAccountId === ERROR && <span className="text-error">Sender account is not valid</span>}
								{data.originatingAccountId !== ERROR && titleCase(data.selectedUserAccount?.bankAccount?.accountName || "")}
							</span>
						</div>
					</td>
					<td className="px-6 py-3">
						<div className="flex h-10 w-full items-center justify-start space-x-4 text-right">
							<div className="flex h-full flex-col items-start justify-center">
								{isNullOrUndefined(data.recipientBankAccount) && <span className="text-error">Recipient account is not valid</span>}
								{!isNullOrUndefined(data.recipientBankAccount) && (
									<>
										<span className="text-sm">
											<span>{titleCase(data.recipientBankAccount.accountName)} </span>
										</span>
										<div className="flex flex-row items-center justify-start space-x-2 text-xs">
											<span>{data.recipientBankAccount.accountNumber} </span>
											<div>
												<div className="h-1 w-1 rounded-full bg-black-tertiary"></div>
											</div>
											<div>{titleCase(getBankName(data.recipientBankAccount.bankCode))}</div>
										</div>
									</>
								)}
							</div>
						</div>
					</td>
					<td className="px-6 py-3">
						<div className="">
							<span className="text-xs font-normal text-black-tertiary">
								{data.purpose === ERROR && <span className="text-error">Purpose was not given</span>}
								{data.purpose !== ERROR && data.purpose}
							</span>
						</div>
					</td>
					<td className="px-6 py-3">
						<div className="">
							<div className="text-sm font-normal">
								{data.amount !== ERROR && (
									<>
										<CurrencyCode />
										{formatNumber(data.amount)}
									</>
								)}
								{data.amount === ERROR && (
									<>
										<span className="block text-xs text-error">Amount is not valid</span>
									</>
								)}
							</div>{" "}
							<span className="text-xs font-normal text-black-tertiary">
								{data.selectedUserAccount?.bankCode !== data.recipient?.externalAccount.bankCode && data.amount !== ERROR && (
									<>
										<Fee value={data.amount} />
									</>
								)}
							</span>{" "}
						</div>
					</td>
					<td className="px-6 py-3">
						{reviewMode && (
							<div className="flex flex-row items-center justify-center space-x-6 pr-1">
								{" "}
								<div>
									<div
										className="relative h-5 w-5 cursor-pointer"
										onMouseEnter={() => setIsEditHover(true)}
										onMouseLeave={() => setIsEditHover(false)}
										onClick={() => editFunc && editFunc(data)}
									>
										<div className={`absolute left-0 top-0 ${isEditHover ? "opacity-0" : ""} transition-all duration-200 `}>
											<Edit />{" "}
										</div>
										<div className={`absolute left-0 top-0 ${!isEditHover ? "opacity-0" : ""} transition-all duration-200 `}>
											{" "}
											<ActiveEdit />
										</div>
									</div>
								</div>
								<div>
									<div
										className="relative h-5 w-5 cursor-pointer"
										onMouseEnter={() => setIsRemoveHover(true)}
										onMouseLeave={() => setIsRemoveHover(false)}
										onClick={() => removeFunc && removeFunc(data.key)}
									>
										<div className={`absolute left-0 top-0 ${isRemoveHover ? "opacity-0" : ""} transition-all duration-200 `}>
											<Cancel className="stroke-current text-blue" />
										</div>
										<div className={`absolute left-0 top-0 ${!isRemoveHover ? "opacity-0" : ""} transition-all duration-200 `}>
											{" "}
											<ActiveCancel />
										</div>
									</div>
								</div>
							</div>
						)}

						{!reviewMode && processingMode && data.status === TransactionStatus.FAILED && (
							<span className="uppercase text-error">failed</span>
						)}
						{!reviewMode && processingMode && data.status === TransactionStatus.DECLINED && (
							<span className="uppercase text-error">declined</span>
						)}
						{!reviewMode && processingMode && data.status === TransactionStatus.SUCCESS && (
							<span className="uppercase text-success">success</span>
						)}
						{!reviewMode && processingMode && data.status === TransactionStatus.CANCELLED && (
							<span className="uppercase text-warning">Cancelled</span>
						)}
						{!reviewMode && processingMode && data.status === TransactionStatus.PROCESSING && (
							<span className="uppercase text-warning">processing</span>
						)}
						{!reviewMode && processingMode && activateAccount && <span className="uppercase text-warning">pending activation</span>}
						{!reviewMode && processingMode && data.status === TransactionStatus.PENDING_APPROVAL && (
							<span className="uppercase text-warning">pending approval</span>
						)}
					</td>
				</>
			)}
		</tr>
	);
}

export default BulkTransferDetailRow;
