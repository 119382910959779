import {IRootState} from "../../../../../redux/rootReducer";
import MakePayment from "./FundCard/MakePayment";
import PaymentDetails from "./FundCard/PaymentDetails";
import {PaymentStageType} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import ProcessingPayment from "./FundCard/ProcessingPayment";
import React, {useEffect} from "react";
import ReviewPayment from "./FundCard/ReviewPayment";
import {useDispatch, useSelector} from "react-redux";
import {resetAllFundCardData} from "../../../../../redux/payments/fundCard/slice/fundCardSlice";
import {resetAllTempSendMoneyData} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice";

function FundCard(): JSX.Element {
	const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);
	const temp = useSelector((state: IRootState) => state.sendMoney.temp);
	const dispatch = useDispatch();

	useEffect(() => {
		return () => {
			if (temp) {
				dispatch(resetAllTempSendMoneyData());
			}
			dispatch(resetAllFundCardData());
		};
	}, [temp]);

	return (
		<>
			{paymentStage === PaymentStageType.PAYMENT_DETAILS && <PaymentDetails />}
			{paymentStage === PaymentStageType.REVIEW_PAYMENT && <ReviewPayment />}
			{paymentStage === PaymentStageType.MAKE_PAYMENT && <MakePayment />}
			{paymentStage === PaymentStageType.PROCESS_PAYMENT && <ProcessingPayment />}
		</>
	);
}

export default FundCard;
