import Kenya from "../../../../assets/svg/Countries/kenya.png";
import Nigeria from "../../../../assets/svg/Countries/nigeria.png";
import OtherCountries from "../../../../assets/svg/Countries/other-countries.png";
import {SignUpCountries} from "../../Signup/Services/signup.constant";
import Zambia from "../../../../assets/svg/Countries/zambia.png";

export enum Countries {
	NIGERIA = "nigeria",
	ZAMBIA = "zambia",
	KENYA = "kenya",
	OTHER_COUNTRIES = "other countries",
}

export const CountryValue: {
	[type in Countries]: {
		value: string;
	};
} = {
	[Countries.NIGERIA]: {
		value: SignUpCountries.NIGERIA,
	},
	[Countries.ZAMBIA]: {
		value: SignUpCountries.ZAMBIA,
	},
	[Countries.KENYA]: {
		value: "ke",
	},

	[Countries.OTHER_COUNTRIES]: {
		value: "other",
	},
};

export const LencoCountriesOptions: {
	icon: string;
	link: string;
	value: SignUpCountries;
	countryName: Countries;
}[] = [
	{
		icon: Nigeria,
		link: "?country=NG",
		value: SignUpCountries.NIGERIA,
		countryName: Countries.NIGERIA,
	},
	{
		icon: Zambia,
		link: "?country=ZM",
		value: SignUpCountries.ZAMBIA,
		countryName: Countries.ZAMBIA,
	},
];

export const CategoryOptions: {
	CategoryName: Countries;
	icon: string;
	link: string;
	value: string;
}[] = [
	{
		CategoryName: Countries.NIGERIA,
		icon: Nigeria,
		link: "/signup/business-type",
		value: SignUpCountries.NIGERIA,
	},
	{
		CategoryName: Countries.ZAMBIA,
		icon: Zambia,
		link: "/signup/business-type",
		value: SignUpCountries.ZAMBIA,
	},
	{
		CategoryName: Countries.KENYA,
		icon: Kenya,
		link: "/wait-list/join",
		value: "ke",
	},
	{
		CategoryName: Countries.OTHER_COUNTRIES,
		icon: OtherCountries,
		link: "/wait-list/join",
		value: "other",
	},
];

export enum CountryBusinessType {
	NIGERIA = "nigeria",
	ZAMBIA = "zambia",
}
export const BusinessTypeText: {
	[type in CountryBusinessType]: {
		registered: {
			title: string;
			Subtitle: string;
		};
		unregistered: {
			title: string;
			Subtitle: string;
		};
	};
} = {
	[CountryBusinessType.NIGERIA]: {
		registered: {
			title: "Registered Business",
			Subtitle:
				"This business has an incorporation certificate. It is suitable for Sole Proprietor, Limited Liability Companies, NGO and all type of registered entities.",
		},
		unregistered: {
			title: "Unregistered Business",
			Subtitle: "This business is NOT registered. It is suitable for Freelancers, Individuals, Social Media Vendor and Stores.",
		},
	},
	[CountryBusinessType.ZAMBIA]: {
		registered: {
			title: "Registered Business",
			Subtitle:
				"The business has an incorporation certificate. Sole Proprietor, Limited Liability, NGO and others. Suitable for all type of registered entities.",
		},
		unregistered: {
			title: "Unregistered Business",
			Subtitle:
				"The business is NOT registered with the PACRA. For individuals, one-person business. Suitable for social media vendors and stores.",
		},
	},
};
