import {DirectorFormOptions} from "../../../service/directorForm.types";
import NextOfKin from "../../../../../../../models/account-opening/nextOfKin";
import React from "react";

interface Props {
	data: NextOfKin | null;
	options: DirectorFormOptions;
}

function DirectorNextOfKinReview(props: Props): JSX.Element {
	return (
		<>
			<div className="flex flex-col justify-start items-start w-full space-y-4 p-5 border border-black-quin rounded-lg">
				<div className="flex flex-row justify-between items-start w-full space-x-4">
					<span className="text-black-tertiary w-52">Full Name</span>
					<span className="text-black-secondary w-56 text-left">{props.data?.name || "-"}</span>
				</div>
				<div className="flex flex-row justify-between items-start w-full space-x-4">
					<span className="text-black-tertiary w-52">Phone</span>
					<span className="text-black-secondary w-56 text-left">{props.data?.phone || "-"}</span>
				</div>
				<div className="flex flex-row justify-between items-start w-full space-x-4">
					<span className="text-black-tertiary w-52">Gender</span>
					<span className="text-black-secondary w-56 text-left">{props.data?.gender || "-"}</span>
				</div>
				<div className="flex flex-row justify-between items-start w-full space-x-4">
					<span className="text-black-tertiary w-52">Relationship</span>
					<span className="text-black-secondary w-56 text-left">
						{props.data?.relationship &&
							!props.data?.otherRelationshipText &&
							(props.options.kinRelationship.find((_relationship) => _relationship.id === props.data?.relationship)?.name || "")}
						{!props.data?.relationship && (props.data?.otherRelationshipText || "-")}
					</span>
				</div>
				<div className="flex flex-row justify-between items-start w-full space-x-4">
					<span className="text-black-tertiary w-52">Address</span>
					<span className="text-black-secondary w-56 text-left">{props.data?.address || "-"}</span>
				</div>
			</div>
		</>
	);
}

export default DirectorNextOfKinReview;
