import "animate.css";

import React, {useEffect} from "react";

import ButtonComp from "../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../components/General/CurrencyCode";
import Modal from "../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../components/General/Modals/ModalBody";
import ModalFooter from "../../../../../components/General/Modals/ModalFooter";
import ModalHeader from "../../../../../components/General/Modals/ModalHeader";
import {OtpActivityType} from "../../../../../helpers/AppConstants";
import OtpInputForm from "../../../../../components/OtpInputForm";
import {TailSpin} from "react-loader-spinner";
import Transaction from "../../../../../models/transaction";
import commaSeparator from "../../../../../utils/commaSeparator";
import useApprovePendingPayment from "../../Hooks/State/PendingApproval/useApprovePendingPayment";

interface PendingApprovalModalProps {
	active: boolean;
	data: Transaction;
	toggler: () => void;
	onCompleteFunc: () => void;
}
function PendingApprovalModal(props: PendingApprovalModalProps): JSX.Element {
	const {active, data, toggler, onCompleteFunc} = props;

	const {
		otp,
		minutes,
		seconds,
		isInitOTPLoading,
		approveTransactionError,
		approveTransactionResponse,
		isApproveTransactionLoading,

		handleOTPInit,
		handleOtpChange,
		handleResendOTPInit,
		handleApproveTransaction,
	} = useApprovePendingPayment({active, onComplete: () => onCompleteFunc()});

	useEffect(() => {
		if (!data || !active) return;
		handleOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
	}, [active, data, handleOTPInit]);

	useEffect(() => {
		if (!otp || otp.length < 6 || approveTransactionResponse) return;
		void handleApproveTransaction({
			transactionId: data.id,
			otp: otp,
		});
	}, [data, handleApproveTransaction, otp, approveTransactionResponse]);

	return (
		<>
			<Modal size="xs" active={active} toggler={toggler}>
				<ModalHeader onClose={toggler}>Approve Payment</ModalHeader>
				<ModalBody>
					<div className="relative flex w-full flex-col text-black-secondary">
						{isInitOTPLoading ? (
							<div className=" flex w-full flex-row items-start justify-start space-x-1 pb-4 text-sm">
								<TailSpin color="#3A4EF8" height={20} width={20} /> <span className="text-left">Generating OTP...</span>
							</div>
						) : (
							<div className="flex w-full flex-col items-start justify-start space-y-2 text-left">
								<span className="text-sm">
									Enter the OTP sent to your email address to approve the payment of{" "}
									{data && (
										<>
											<CurrencyCode />
											{commaSeparator(data.amount)}
										</>
									)}
								</span>
							</div>
						)}
						<div className="flex w-full flex-col items-start justify-start pt-4">
							<OtpInputForm value={otp || ""} otpFunc={handleOtpChange} errorMessage={approveTransactionError} />
							<div className="flex items-center justify-start pt-4">
								<div>
									{minutes === 0 && seconds === 0 ? (
										<div
											onClick={() => {
												handleResendOTPInit({action: OtpActivityType.APPROVE_TRANSACTION});
											}}
										>
											<span className="cursor-pointer  text-sm text-blue">Resend OTP</span>
										</div>
									) : (
										<span className="cursor-not-allowed  text-sm text-black-tertiary">
											Resend OTP in{" "}
											<span className="text-black-secondary">
												{minutes}:{seconds < 10 ? `0${seconds}` : seconds}
											</span>
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
				<ModalFooter>
					<ButtonComp
						type="button"
						ripple="light"
						buttonType="secondary"
						color="grey"
						func={() => {
							toggler();
						}}
					>
						<span>Back</span>
					</ButtonComp>

					<ButtonComp
						type="submit"
						color="blue"
						ripple="light"
						buttonType="primary"
						isLoading={isApproveTransactionLoading}
						disable={!isApproveTransactionLoading}
					>
						<div className="flex w-full items-center justify-center">
							<span>Authorize</span>
						</div>
					</ButtonComp>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default PendingApprovalModal;
