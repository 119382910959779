import {TransactionStatus, TransactionType} from "../../../models/transaction.constants";

import Transaction from "../../../models/transaction";

export interface TransactionState {
	isTransactionPaginationLoading: boolean;
	transactionPaginationOffset: number;
	isExportTransactionsLoading: boolean;
	isTransactionAccountQuery: boolean;
	isTransactionSearchQuery: boolean;
	isExportZambiaTransactionsTrayOpen: boolean;
	transactionsListTotal: number;
	transactionsListGroupSize: number;
	transactionList: Transaction[] | null;
	isTransactionListEmpty: boolean;
	transactionSearchQuery: string;
	transactionAccountQuery: string;
	transactionDate: TransactionDateTypes;
	selectedTransactionDate: TransactionDateTypes;
	isCustomModalOpen: boolean;
	transactionFilterState: {
		offset: number;
		date: TransactionDateObj;
		transactionTypes: TransactionType[];
		userGroupIds: string[];
		userAccountIds: string[];
		categoryIds: string[];
		cardIds: string[];
		transactionStatuses: TransactionStatus[];
		query: string;
	};
	selectedTransactionFilterState: {
		offset: number;
		date: TransactionDateObj;
		transactionTypes: TransactionType[];
		userGroupIds: string[];
		userAccountIds: string[];
		categoryIds: string[];
		cardIds: string[];
		transactionStatuses: TransactionStatus[];
		query: string;
	};
}

export type TransactionFilterTypes =
	| {
			date: TransactionDateObj;
	  }
	| {userAccountIds: Array<string>}
	| {categoryIds: Array<string>}
	| {userGroupIds?: Array<string>}
	| {cardIds?: Array<string>}
	| {query: string}
	| {offset: number}
	| {transactionTypes?: Array<TransactionType>; transactionStatuses?: Array<TransactionStatus>};

export enum TransactionDateTypes {
	LAST_30_DAYS = "Last 30 days",
	THIS_MONTH = "This Month",
	LAST_MONTH = "Last Month",
	THIS_YEAR = "This year",
	ALL_TIME = "All time",
	CUSTOM = "Custom",
}

export type TransactionDateObj = {
	begin: Date | null | string;
	end: Date | null | string;
};

export type TransactionDateType = {
	name: TransactionDateTypes;
	date: TransactionDateObj;
};
