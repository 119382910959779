import {setSingleTransferAmount} from "../../../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

interface UseSingleTransferPurposeInterface {
	handleAmountChange: (_amount: string) => void;
}

function useSingleTransferAmount(): UseSingleTransferPurposeInterface {
	const dispatch = useDispatch();

	const handleAmountChange = useCallback((_amount: string) => {
		if (!_amount || Number(_amount) === 0) return;
		dispatch(setSingleTransferAmount(_amount));
	}, []);

	return {handleAmountChange};
}

export default useSingleTransferAmount;
