export enum ZambiaRequestPaymentAttemptStatus {
	PENDING = "pending",
	SUCCESSFUL = "successful",
	FAILED = "failed",
	CANCELLED = "cancelled",
	EXPIRED = "expired",
}

export enum ZambiaCollectionStatus {
	PENDING = "pending",
	SUCCESSFUL = "successful",
	FAILED = "failed",
	CANCELLED = "cancelled",
	EXPIRED = "expired",
}

export enum ZambiaCollectionSource {
	API = "api",
	BANKING_APP = "banking-app",
}

export enum ZambiaCollectionPaymentChannel {
	CARD = "card",
	MOBILE_MONEY = "mobile-money",
	BANK_ACCOUNT = "bank-account",
}

export enum ZambiaCollectionFeeBearer {
	MERCHANT = "merchant",
	CUSTOMER = "customer",
}

export enum ZambiaSettlementType {
	INSTANT = "instant",
	NEXT_DAY = "next-day",
}

export enum ZambiaSettlementStatus {
	PENDING = "pending",
	SETTLED = "settled",
}

export enum ZambiaMobileMoneyServiceProvider {
	MTN = "zm_mtn",
	AIRTEL = "zm_airtel",
	ZAMTEL = "zm_zamtel",
}

export enum ZambiaCardPaymentCollectionStatus {
	PENDING = "pending",
	SUCCESSFUL = "successful",
	FAILED = "failed",
	OTP_REQUIRED = "otp-required",
	BILLING_INFO_REQUIRED = "billing-info-required",
	D_S_REQUIRED = "3d-s-required",
	PIN_REQUIRED = "pin-required",
}
