import {HintType, ListStyle, hintMarkItem} from "./hint.constant";
import React, {useCallback, useState} from "react";

import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {ReactComponent as CameraIcon} from "../../../../../../assets/svg/Hint/camera.svg";
import {CompanyHint} from "../../../services/nigeria-application/registered/company.types";
import {ReactComponent as GrayCloseIcon} from "../../../../../../assets/svg/General/CloseIcon.svg";
import {ReactComponent as GuideIcon} from "../../../../../../assets/svg/Hint/guide.svg";
import {ReactComponent as InfoIcon} from "../../../../../../assets/svg/Hint/info.svg";
import {ReactComponent as MarkActiveIcon} from "../../../../../../assets/svg/Hint/mark-active.svg";
import {ReactComponent as MarkInactiveIcon} from "../../../../../../assets/svg/Hint/mark-inactive.svg";

type FirstProps = {hintType: HintType; listStyle: ListStyle; firstList: CompanyHint[] | hintMarkItem[]; infoText?: string; fullWidth?: boolean};
type SecondProps = {
	secondHint?: boolean;
	secondHintType?: HintType;
	secondListStyle?: ListStyle;
	secondList?: CompanyHint[] | hintMarkItem[];
};
type ThirdProps = {
	thirdHint?: boolean;
	thirdHintType?: HintType;
	thirdListStyle?: ListStyle;
	thirdList?: CompanyHint[] | hintMarkItem[];
};

type Props = FirstProps & SecondProps & ThirdProps;

function Hint(props: Props): JSX.Element {
	const [isHintOpen, setIsHintOpen] = useState(false);

	const handleCloseHint = useCallback(() => {
		setIsHintOpen(false);
	}, []);

	const handleToggleHint = useCallback(() => {
		setIsHintOpen((prev) => !prev);
	}, []);

	return (
		<>
			<div
				className={
					"fixed z-30 left-0 top-0 lg:relative flex justify-center items-start lg:h-full w-full " +
					`${props.fullWidth ? "" : "lg:max-w-60%"}`
				}
			>
				<div className="hidden lg:flex flex-col space-y-5 bg-blue-backdrop py-8 px-5 rounded-lg max-w-xs">
					<div className="flex flex-col space-y-5">
						<div className="flex flex-row justify-start items-center space-x-2">
							<div className="h-5 w-5">
								{props.hintType === HintType.TIP && <CameraIcon className="h-5 w-5" />}
								{props.hintType === HintType.INFO && <InfoIcon className="h-5 w-5" />}
								{props.hintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue h-5 w-5" />}
							</div>
							<span className="font-medium text-black">
								{props.hintType === HintType.TIP ? "Tips" : ""}
								{props.hintType === HintType.GUIDE ? "Guide" : ""}
								{props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
							</span>
						</div>
						{/* <div>{props.children && props.children}</div> */}
						{props.listStyle === ListStyle.BULLET && (
							<ul className="flex flex-col justify-start items-start space-y-5 list-disc ml-5">
								{(props.firstList as CompanyHint[]).map((_el, index) => (
									<li className="text-sm text-black-secondary" key={index}>
										{_el.span}
									</li>
								))}
							</ul>
						)}
						{props.listStyle === ListStyle.PLAIN && (
							<div className="flex flex-col justify-start items-start space-y-5">
								{(props.firstList as CompanyHint[]).map((_el, index) => (
									<div className="text-sm text-black-secondary" key={index}>
										{_el.span}
									</div>
								))}
							</div>
						)}
						{props.listStyle === ListStyle.CHECK && (
							<div className="flex flex-col justify-start items-start space-y-5">
								{(props.firstList as hintMarkItem[]).map((_el, index) => (
									<div className=" flex flex-row justify-start items-center space-x-2" key={index}>
										<div>{_el.isChecked ? <MarkActiveIcon /> : <MarkInactiveIcon />}</div>
										<div className={`capitalize text-sm ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
											{_el.text}
										</div>
									</div>
								))}
							</div>
						)}
					</div>
					{props.secondHint && (
						<div className="flex flex-col space-y-5">
							<div className="flex flex-row justify-start items-start space-x-2">
								<div className="h-4 w-4">
									{props.secondHintType === HintType.TIP && <CameraIcon />}
									{props.hintType === HintType.INFO && <InfoIcon />}
									{props.secondHintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
								</div>
								<span className="font-medium text-black">
									{props.secondHintType === HintType.TIP ? "Tips" : ""}
									{props.secondHintType === HintType.GUIDE ? "Guide" : ""}
									{props.secondHintType === HintType.INFO ? props.infoText || "Info" : ""}
								</span>
							</div>
							{props.secondListStyle === ListStyle.BULLET && (
								<ul className="flex flex-col justify-start items-start space-y-5 list-disc ml-5">
									{(props.secondList as CompanyHint[]).map((_el, index) => (
										<li className="text-sm text-black-secondary" key={index}>
											{_el.span}
										</li>
									))}
								</ul>
							)}
							{props.secondListStyle === ListStyle.PLAIN && (
								<div className="flex flex-col justify-start items-start space-y-5">
									{(props.secondList as CompanyHint[]).map((_el, index) => (
										<div className="text-sm text-black-secondary" key={index}>
											{_el.span}
										</div>
									))}
								</div>
							)}
							{props.secondListStyle === ListStyle.CHECK && (
								<div className="flex flex-col justify-start items-start space-y-5">
									{(props.secondList as hintMarkItem[]).map((_el, index) => (
										<div className=" flex flex-row justify-start items-center space-x-2" key={index}>
											<div>{_el.isChecked ? <MarkActiveIcon /> : <MarkInactiveIcon />}</div>
											<div className={`capitalize text-sm ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
												{_el.text}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					)}
					{props.thirdHint && (
						<div className="flex flex-col space-y-5">
							<div className="flex flex-row justify-start items-start space-x-2">
								<div className="h-4 w-4">
									{props.thirdHintType === HintType.TIP && <CameraIcon />}
									{props.hintType === HintType.INFO && <InfoIcon />}
									{props.thirdHintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
								</div>
								<span className="font-medium text-black">
									{props.thirdHintType === HintType.TIP ? "Tips" : ""}
									{props.thirdHintType === HintType.GUIDE ? "Guide" : ""}
									{props.thirdHintType === HintType.INFO ? props.infoText || "Info" : ""}
								</span>
							</div>
							{/* <div>{props.children && props.children}</div> */}
							{props.thirdListStyle === ListStyle.BULLET && (
								<ul className="flex flex-col justify-start items-start space-y-5 list-disc ml-5">
									{(props.thirdList as CompanyHint[]).map((_el, index) => (
										<li className="text-sm text-black-secondary" key={index}>
											{_el.span}
										</li>
									))}
								</ul>
							)}
							{props.thirdListStyle === ListStyle.PLAIN && (
								<div className="flex flex-col justify-start items-start space-y-5">
									{(props.thirdList as CompanyHint[]).map((_el, index) => (
										<div className="text-sm text-black-secondary" key={index}>
											{_el.span}
										</div>
									))}
								</div>
							)}
							{props.thirdListStyle === ListStyle.CHECK && (
								<div className="flex flex-col justify-start items-start space-y-5">
									{(props.thirdList as hintMarkItem[]).map((_el, index) => (
										<div className=" flex flex-row justify-start items-center space-x-2" key={index}>
											<div>{_el.isChecked ? <MarkActiveIcon /> : <MarkInactiveIcon />}</div>
											<div className={`capitalize text-sm ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
												{_el.text}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
					)}
				</div>

				{/* ======= For mobile view ======== */}
				<div
					className={
						`grid place-items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-80 mx-2 outline-none focus:outline-none transition-all duration-150 px-2 lg:opacity-0 lg:pointer-events-none ` +
						`${isHintOpen ? "opacity-100 pointer-events-auto" : "opacity-0 pointer-events-none"} `
					}
					onClick={handleCloseHint}
				>
					<div className="flex flex-col space-y-5 bg-blue-backdrop py-8 px-5 rounded-lg w-full max-w-sm">
						<div className="flex flex-col space-y-5 " style={{backgroundColor: "#FAFBFF"}}>
							<div className="flex flex-row justify-start items-start space-x-2">
								<div className="h-4 w-4">
									{props.hintType === HintType.TIP && <CameraIcon />}
									{props.hintType === HintType.INFO && <InfoIcon />}
									{props.hintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
								</div>
								<span className="font-medium text-black text-base">
									{props.hintType === HintType.TIP ? "Tips" : ""}
									{props.hintType === HintType.GUIDE ? "Guide" : ""}
									{props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
								</span>
							</div>
							{/* <div>{props.children && props.children}</div> */}
							{props.listStyle === ListStyle.BULLET && (
								<ul className="flex flex-col justify-start items-start space-y-5 list-disc ml-5">
									{(props.firstList as CompanyHint[]).map((_el, index) => (
										<li className="text-sm text-black-secondary" key={index}>
											{_el.span}
										</li>
									))}
								</ul>
							)}
							{props.listStyle === ListStyle.PLAIN && (
								<div className="flex flex-col justify-start items-start space-y-5">
									{(props.firstList as CompanyHint[]).map((_el, index) => (
										<div className="text-sm text-black-secondary" key={index}>
											{_el.span}
										</div>
									))}
								</div>
							)}
							{props.listStyle === ListStyle.CHECK && (
								<div className="flex flex-col justify-start items-start space-y-5">
									{(props.firstList as hintMarkItem[]).map((_el, index) => (
										<div className=" flex flex-row justify-start items-center space-x-2" key={index}>
											<div>{_el.isChecked ? <MarkActiveIcon /> : <MarkInactiveIcon />}</div>
											<div className={`capitalize text-sm ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`}>
												{_el.text}
											</div>
										</div>
									))}
								</div>
							)}
						</div>
						{props.secondHint && (
							<div className="flex flex-col space-y-5">
								<div className="flex flex-row justify-start items-start space-x-2">
									<div className="h-4 w-4">
										{props.hintType === HintType.TIP && <CameraIcon />}
										{props.hintType === HintType.INFO && <InfoIcon />}
										{props.hintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
									</div>
									<span className="font-medium text-black">
										{props.hintType === HintType.TIP ? "Tips" : ""}
										{props.hintType === HintType.GUIDE ? "Guide" : ""}
										{props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
									</span>
								</div>
								{props.secondListStyle === ListStyle.BULLET && (
									<ul className="flex flex-col justify-start items-start space-y-5 list-disc ml-5">
										{(props.secondList as CompanyHint[]).map((_el, index) => (
											<li className="text-sm text-black-secondary" key={index}>
												{_el.span}
											</li>
										))}
									</ul>
								)}
								{props.secondListStyle === ListStyle.PLAIN && (
									<div className="flex flex-col justify-start items-start space-y-5">
										{(props.secondList as CompanyHint[]).map((_el, index) => (
											<div className="text-sm text-black-secondary" key={index}>
												{_el.span}
											</div>
										))}
									</div>
								)}
								{props.secondListStyle === ListStyle.CHECK && (
									<div className="flex flex-col justify-start items-start space-y-5">
										{(props.secondList as hintMarkItem[]).map((_el, index) => (
											<div className=" flex flex-row justify-start items-center space-x-2" key={index}>
												<div>{_el.isChecked ? <MarkActiveIcon /> : <MarkInactiveIcon />}</div>
												<div
													className={
														`capitalize text-sm ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`
													}
												>
													{_el.text}
												</div>
											</div>
										))}
									</div>
								)}
							</div>
						)}
						{props.thirdHint && (
							<div className="flex flex-col space-y-5">
								<div className="flex flex-row justify-start items-start space-x-2">
									<div className="h-4 w-4">
										{props.hintType === HintType.TIP && <CameraIcon />}
										{props.hintType === HintType.INFO && <InfoIcon />}
										{props.hintType === HintType.GUIDE && <GuideIcon className="stroke-current text-blue" />}
									</div>
									<span className="font-medium text-black">
										{props.hintType === HintType.TIP ? "Tips" : ""}
										{props.hintType === HintType.GUIDE ? "Guide" : ""}
										{props.hintType === HintType.INFO ? props.infoText || "Info" : ""}
									</span>
								</div>
								{/* <div>{props.children && props.children}</div> */}
								{props.thirdListStyle === ListStyle.BULLET && (
									<ul className="flex flex-col justify-start items-start space-y-5 list-disc ml-5">
										{(props.thirdList as CompanyHint[]).map((_el, index) => (
											<li className="text-sm text-black-secondary" key={index}>
												{_el.span}
											</li>
										))}
									</ul>
								)}
								{props.thirdListStyle === ListStyle.PLAIN && (
									<div className="flex flex-col justify-start items-start space-y-5">
										{(props.thirdList as CompanyHint[]).map((_el, index) => (
											<div className="text-sm text-black-secondary" key={index}>
												{_el.span}
											</div>
										))}
									</div>
								)}
								{props.thirdListStyle === ListStyle.CHECK && (
									<div className="flex flex-col justify-start items-start space-y-5">
										{(props.thirdList as hintMarkItem[]).map((_el, index) => (
											<div className=" flex flex-row justify-start items-center space-x-2" key={index}>
												<div>{_el.isChecked ? <MarkActiveIcon /> : <MarkInactiveIcon />}</div>
												<div
													className={
														`capitalize text-sm ` + `${_el.isChecked ? "text-black-secondary" : "text-black-quat"}`
													}
												>
													{_el.text}
												</div>
											</div>
										))}
									</div>
								)}
							</div>
						)}
					</div>
				</div>
				<div className="fixed bottom-16 right-8 z-80 lg:hidden lg:w-36">
					<ButtonComp ripple="dark" color="blue" type="button" buttonType="tertiary" data-type="section" func={handleToggleHint} fullWidth>
						<div className="rounded-3xl bg-blue-senary text-blue p-4 flex justify-center items-center shadow-md mb-2 mx-1">
							{!isHintOpen && (
								<>
									<GuideIcon className="stroke-current h-4 w-4" tabIndex={-1} />
									<div className="ml-3 overflow-ellipsis overflow-hidden whitespace-nowrap text-sm" tabIndex={-1}>
										Guide
									</div>
								</>
							)}
							{isHintOpen && (
								<>
									<GrayCloseIcon className="stroke-current h-2.5 w-2.5" tabIndex={-1} />
									<div
										className="ml-3 overflow-ellipsis overflow-hidden whitespace-nowrap text-sm"
										data-type="section"
										tabIndex={-1}
									>
										Close
									</div>
								</>
							)}
						</div>
					</ButtonComp>
				</div>
				<div
					className={
						`fixed inset-0 z-70 bg-black transition-all duration-150 lg:opacity-0 lg:pointer-events-none ` +
						`${isHintOpen ? "opacity-25 pointer-events-auto" : "opacity-0 pointer-events-none"} `
					}
					onClick={handleCloseHint}
					// data-type={props.dataType && props.dataType}
				></div>
			</div>
		</>
	);
}

export default Hint;
