import {CardStatus, CardWalletType} from "../../../models/card.constants";
import {CardsFilterTypes, CardsState} from "./cardsSlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import CardMain from "../../../models/cardMain";
import {CardSectionEnum} from "../../../modules/Dashboard/Cards/Services/cardsApi.types";
import User from "../../../models/user";

// import {produce} from "immer";

const initialState: CardsState = {
	//modals
	isCardUsageModalOpen: false,
	isCardAccessModalOpen: false,
	teamMembersWithManageAccess: [],
	teamMemberToRemoveManageAccess: null,

	users: [],
	newCards: [],

	//gideon
	selectedCardDetails: null,
	openReAssignCardForm: false,
	activeCardTraySections: [],
	isSelectedCardDetailsOpen: false,
	activeIDidNotGetCardRequest: [],
	activeOpenedReassignCardForms: [],
	activeOpenedCardActivationForm: [],

	//filter
	cardsSearchQuery: "",
	isCardsFiltering: false,
	isCardsPaginationLoading: false,

	cardsListTotal: 0,
	cardsListGroupSize: 0,
	cardsPaginationOffset: 0,

	cardsFilterState: {
		offset: 0,
		walletType: [],
		assignedToIds: [],
		cardStatuses: [],
		query: "",
	},
	selectedCardsFilterState: {
		offset: 0,
		walletType: [],
		assignedToIds: [],
		cardStatuses: [],
		query: "",
	},
	showCVV: false,
};

export const cardsSlice = createSlice({
	name: "cards",
	initialState,
	reducers: {
		//account
		showReAssignCardForm: (state: CardsState) => {
			state.openReAssignCardForm = true;
		},
		hideReAssignCardForm: (state: CardsState) => {
			state.openReAssignCardForm = false;
		},
		addToActiveOpenReassignCardForms: (state: CardsState, action: PayloadAction<string>) => {
			state.activeOpenedReassignCardForms.push(action.payload);
		},
		removeFromActiveOpenedReassignCardForms: (state: CardsState, action: PayloadAction<string>) => {
			state.activeOpenedReassignCardForms = state.activeOpenedReassignCardForms.filter((item) => item !== action.payload);
		},
		addToActiveIDintNotGetCardRequest: (state: CardsState, action: PayloadAction<string>) => {
			state.activeIDidNotGetCardRequest.push(action.payload);
		},
		removeFromActiveIDintNotGetCardRequest: (state: CardsState, action: PayloadAction<string>) => {
			state.activeIDidNotGetCardRequest = state.activeIDidNotGetCardRequest.filter((item) => item !== action.payload);
		},
		addToActiveOpenedCardActivationForms: (state: CardsState, action: PayloadAction<string>) => {
			state.activeOpenedCardActivationForm.push(action.payload);
		},
		removeFromActiveOpenedCardActivationForms: (state: CardsState, action: PayloadAction<string>) => {
			state.activeOpenedCardActivationForm = state.activeOpenedCardActivationForm.filter((item) => item !== action.payload);
		},
		openSelectedCardDetails: (state: CardsState) => {
			state.isSelectedCardDetailsOpen = true;
		},
		closeSelectedCardDetails: (state: CardsState) => {
			state.isSelectedCardDetailsOpen = false;
		},
		setSelectedCardDetails: (state: CardsState, action: PayloadAction<string>) => {
			const _card = state.newCards.find((_sub) => _sub.createRequest.id === action.payload) || null;
			state.selectedCardDetails = _card;
		},
		setSelectedCardDetailsTemp: (state: CardsState, action: PayloadAction<CardMain>) => {
			state.selectedCardDetails = action.payload;
		},
		setActiveCardTraySections: (state: CardsState, action: PayloadAction<CardSectionEnum[]>) => {
			state.activeCardTraySections = action.payload;
		},
		resetSelectedCardDetails: (state: CardsState) => {
			state.selectedCardDetails = null;
		},

		setCardUsers: (state: CardsState, action: PayloadAction<User[]>) => {
			state.users = action.payload;
		},

		updateCard: (state: CardsState, action: PayloadAction<CardMain>) => {
			const _cardIndex = state.newCards.findIndex((_sub) => _sub.createRequest.id === action.payload.createRequest.id);
			state.newCards[_cardIndex] = action.payload;
			const isSelectedCardDetailsUpdated = state.selectedCardDetails?.createRequest.id === action.payload.createRequest.id;
			if (isSelectedCardDetailsUpdated) {
				state.selectedCardDetails = action.payload;
			}
		},

		setTeamMembersWithManageAccess: (state: CardsState, action: PayloadAction<User[]>) => {
			state.teamMembersWithManageAccess = action.payload;
		},
		setTeamMemberToRemoveManageAccess: (state: CardsState, action: PayloadAction<User>) => {
			state.teamMemberToRemoveManageAccess = action.payload;
		},
		resetTeamMemberToRemoveManageAccess: (state: CardsState) => {
			state.teamMemberToRemoveManageAccess = null;
		},

		resetCards: (state: CardsState) => {
			state.users = [];
			state.newCards = [];
			state.teamMembersWithManageAccess = [];
			state.teamMemberToRemoveManageAccess = null;

			state.isCardUsageModalOpen = false;
			state.isCardAccessModalOpen = false;

			//gideon
			state.selectedCardDetails = null;
			state.openReAssignCardForm = false;
			state.activeCardTraySections = [];
			state.isSelectedCardDetailsOpen = false;
			state.activeIDidNotGetCardRequest = [];
			state.activeOpenedReassignCardForms = [];
			state.activeOpenedCardActivationForm = [];

			//filter
			state.cardsSearchQuery = "";
			state.isCardsPaginationLoading = false;

			state.cardsListTotal = 0;
			state.cardsListGroupSize = 0;
			state.cardsPaginationOffset = 0;

			state.cardsFilterState = {
				offset: 0,
				walletType: [],
				assignedToIds: [],
				cardStatuses: [],
				query: "",
			};
			state.selectedCardsFilterState = {
				offset: 0,
				walletType: [],
				assignedToIds: [],
				cardStatuses: [],
				query: "",
			};
		},

		//======New======

		setNewCard: (state: CardsState, action: PayloadAction<CardMain>) => {
			// state.newCards = produce(state.newCards, (draft) => {
			// draft.push(action.payload);
			// });
			state.newCards = [action.payload, ...state.newCards];
		},
		setNewCards: (state: CardsState, action: PayloadAction<CardMain[]>) => {
			state.newCards = action.payload;
		},
		setUpdateNewCard: (state: CardsState, action: PayloadAction<CardMain>) => {
			const _cardIndex = state.newCards.findIndex((_sub) => _sub.card?.id === action.payload.card?.id);
			state.newCards[_cardIndex] = action.payload;
		},
		setShowCVV: (state: CardsState, action: PayloadAction<boolean>) => {
			// w
			state.showCVV = action.payload;
		},
		// setUpdatedCardActivation: (state: CardsState, action: PayloadAction<CardMain>) => {
		// const _cardIndex = state.newCards.findIndex((_sub) => _sub.card?.id === action.payload.card?.id);
		// state.newCards[_cardIndex] = action.payload;
		// state.newCards = produce(state.newCards, (draft) => {
		// const index = draft.findIndex((_cardMain) => _cardMain.card?.id === action.payload.card?.id);
		// if (index !== -1) {
		// draft[index].card = action.payload.card;
		// draft[index].transactionId = action.payload.transactionId;
		// }
		// })
		// },

		setIsCardUsageModalOpen: (state: CardsState, action: PayloadAction<boolean>) => {
			state.isCardUsageModalOpen = action.payload;
		},
		setIsCardAccessModalOpen: (state: CardsState, action: PayloadAction<boolean>) => {
			state.isCardAccessModalOpen = action.payload;
		},

		//filter
		setIsCardsFiltering: (state: CardsState, action: PayloadAction<boolean>) => {
			state.isCardsFiltering = action.payload;
		},
		setSelectedCardsFilterState: (state: CardsState) => {
			state.selectedCardsFilterState = {...state.cardsFilterState};
		},
		setCardsFilterState: (state: CardsState, action: PayloadAction<CardsFilterTypes>) => {
			state.cardsFilterState = {...state.cardsFilterState, ...action.payload};
		},
		setIsCardsPaginationLoading: (state: CardsState, action: PayloadAction<boolean>) => {
			state.isCardsPaginationLoading = action.payload;
		},
		setCardsListTotal: (state: CardsState, action: PayloadAction<number>) => {
			state.cardsListTotal = action.payload;
		},
		setCardsPaginationOffset: (state: CardsState, action: PayloadAction<number>) => {
			state.cardsPaginationOffset = action.payload;
		},
		setCardsListGroupSize: (state: CardsState, action: PayloadAction<number>) => {
			state.cardsListGroupSize = action.payload;
		},

		setSearchQueryFilter: (state: CardsState, action: PayloadAction<string>) => {
			state.selectedCardsFilterState.query = action.payload;
			state.cardsFilterState.query = action.payload;
		},
		setWalletTypesFilter: (state: CardsState, action: PayloadAction<CardWalletType>) => {
			state.selectedCardsFilterState.walletType = state.selectedCardsFilterState.walletType.filter((_types) => _types !== action.payload);
			state.cardsFilterState.walletType = state.cardsFilterState.walletType.filter((_types) => _types !== action.payload);
		},
		setCardStatusesFilter: (state: CardsState, action: PayloadAction<CardStatus>) => {
			state.selectedCardsFilterState.cardStatuses = state.selectedCardsFilterState.cardStatuses.filter((_status) => _status !== action.payload);
			state.cardsFilterState.cardStatuses = state.cardsFilterState.cardStatuses.filter((_status) => _status !== action.payload);
		},
		setCardAssignedToIdsFilter: (state: CardsState, action: PayloadAction<string>) => {
			state.selectedCardsFilterState.assignedToIds = state.selectedCardsFilterState.assignedToIds.filter(
				(_userId) => _userId !== action.payload
			);
			state.cardsFilterState.assignedToIds = state.cardsFilterState.assignedToIds.filter((_userId) => _userId !== action.payload);
		},

		resetSearchQueryFilterState: (state: CardsState) => {
			state.selectedCardsFilterState = {...state.selectedCardsFilterState, query: ""};
			state.cardsFilterState = {...state.cardsFilterState, query: ""};
		},
		resetWalletTypesFilterState: (state: CardsState) => {
			state.selectedCardsFilterState = {...state.selectedCardsFilterState, walletType: []};
			state.cardsFilterState = {...state.cardsFilterState, walletType: []};
		},
		resetCardStatusesFilterState: (state: CardsState) => {
			state.selectedCardsFilterState = {...state.selectedCardsFilterState, cardStatuses: []};
			state.cardsFilterState = {...state.cardsFilterState, cardStatuses: []};
		},
		resetCardAssignedToIdsFilterState: (state: CardsState) => {
			state.selectedCardsFilterState = {...state.selectedCardsFilterState, assignedToIds: []};
			state.cardsFilterState = {...state.cardsFilterState, assignedToIds: []};
		},

		resetCardsFilterState: (state: CardsState) => {
			state.cardsSearchQuery = "";
			state.isCardsPaginationLoading = false;

			state.cardsListTotal = 0;
			state.cardsListGroupSize = 0;
			state.cardsPaginationOffset = 0;

			state.cardsFilterState = {
				offset: 0,
				walletType: [],
				assignedToIds: [],
				cardStatuses: [],
				query: "",
			};
			state.selectedCardsFilterState = {
				offset: 0,
				walletType: [],
				assignedToIds: [],
				cardStatuses: [],
				query: "",
			};
		},
	},
});

export const {
	setTeamMembersWithManageAccess,
	setTeamMemberToRemoveManageAccess,
	resetTeamMemberToRemoveManageAccess,
	setIsCardAccessModalOpen,
	resetCards,
	updateCard,
	setCardUsers,
	openSelectedCardDetails,
	closeSelectedCardDetails,
	setSelectedCardDetails,
	resetSelectedCardDetails,
	setSelectedCardDetailsTemp,
	setActiveCardTraySections,
	setNewCard,
	setNewCards,
	setUpdateNewCard,
	addToActiveOpenedCardActivationForms,
	removeFromActiveOpenedCardActivationForms,
	showReAssignCardForm,
	hideReAssignCardForm,
	setIsCardUsageModalOpen,
	addToActiveIDintNotGetCardRequest,
	removeFromActiveIDintNotGetCardRequest,
	addToActiveOpenReassignCardForms,
	removeFromActiveOpenedReassignCardForms,

	setIsCardsFiltering,
	setCardsFilterState,
	setSelectedCardsFilterState,
	setIsCardsPaginationLoading,
	setCardsListTotal,
	setCardsPaginationOffset,
	setCardsListGroupSize,
	setSearchQueryFilter,
	setWalletTypesFilter,
	setCardStatusesFilter,
	setCardAssignedToIdsFilter,
	resetSearchQueryFilterState,
	resetWalletTypesFilterState,
	resetCardStatusesFilterState,
	resetCardAssignedToIdsFilterState,
	resetCardsFilterState,
	setShowCVV,
} = cardsSlice.actions;

export default cardsSlice.reducer;
