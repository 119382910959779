import React, {useCallback, useEffect, useState} from "react";

import {ReactComponent as Check} from "../../../assets/svg/checkbox-check.svg";
import {ReactComponent as GreenCheck} from "../../../assets/svg/green-check-box.svg";
import {ReactComponent as XCheckIcon} from "../../../assets/svg/x-check-box.svg";
import isNullOrUndefined from "../../../utils/isNullOrUndefined";

export enum CheckType {
	NORMAL = 1,
	SUCCESS = 2,
	ERROR = 3,
}

interface Props {
	id: string;
	text?: React.ReactNode;
	size?: "sm" | "md";
	type?: CheckType;
	checked?: boolean;
	dataType?: string;
	truncate?: boolean;
	readOnly?: boolean;

	func?: () => void;
}

function Checkbox({type = CheckType.NORMAL, ...props}: Props): JSX.Element {
	const [isChecked, setIsChecked] = useState<boolean>(false);
	const {truncate = false} = props;

	const getCheckColor = useCallback((cColor: CheckType) => {
		if (cColor === CheckType.NORMAL) return "border-blue bg-blue";
		if (cColor === CheckType.SUCCESS) return "bg-success-secondary";
		if (cColor === CheckType.ERROR) return "bg-error-secondary";
		return "";
	}, []);

	useEffect(() => {
		if (isNullOrUndefined(props.checked)) return;
		setIsChecked(props.checked);
	}, [props.checked]);

	const handleClick = () => {
		setIsChecked((prev) => !prev);
		props.func && props.func();
	};

	return (
		<>
			<div
				className={`flex flex-row justify-start items-center ` + `${props.readOnly ? "pointer-events-none" : "cursor-pointer"} `}
				onClick={handleClick}
				data-type={props.dataType}
			>
				<div data-type={props.dataType}>
					<div
						className={
							`relative flex items-center justify-center rounded-md transition-all duration-150 ` +
							`${props.size === "sm" ? "h-4 w-4" : "h-5 w-5"} ` +
							`${isChecked ? getCheckColor(type) : ""} ` +
							`${
								!isChecked && type === CheckType.NORMAL
									? "border border-solid border-black-tertiary"
									: !isChecked && type !== CheckType.NORMAL
									? "border border-solid border-black-quin"
									: isChecked && type === CheckType.SUCCESS
									? "border border-solid border-success-secondary"
									: isChecked && type === CheckType.ERROR
									? "border border-solid border-error-tertiary"
									: "border border-solid border-black-tertiary"
							}` +
							`${props.readOnly ? "border-black-quin" : ""} `
						}
						data-type={props.dataType}
					>
						<input
							className="focus:outline-none outline-none absolute left-0 top-0 hidden h-full w-full placeholder-transparent"
							checked={isChecked}
							type={"checkbox"}
							id={props.id}
							readOnly
							data-type={props.dataType}
						/>
						{type === CheckType.NORMAL && (
							<Check
								className={
									`fill-current ` +
									`${isChecked ? "opacity-100" : "opacity-0"} ` +
									`${props.size === "sm" ? "w-2.5" : "w-3"} ` +
									`${props.readOnly && isChecked ? "text-grey-tertiary" : ""} ` +
									`${!props.readOnly && isChecked ? "text-white" : ""} ` +
									`${props.readOnly && !isChecked ? "" : ""} ` +
									`${!props.readOnly && !isChecked ? "" : ""}`
								}
								data-type={props.dataType}
							/>
						)}
						{type === CheckType.SUCCESS && (
							<GreenCheck
								className={
									`${isChecked ? "opacity-100" : "opacity-0"} ` +
									`${props.size === "sm" ? "w-3.5" : "w-3.5"} ` +
									`${props.readOnly && isChecked ? "text-grey-tertiary" : ""} ` +
									`${!props.readOnly && isChecked ? "text-white" : ""} ` +
									`${props.readOnly && !isChecked ? "" : ""} ` +
									`${!props.readOnly && !isChecked ? "" : ""}`
								}
								data-type={props.dataType}
							/>
						)}
						{type === CheckType.ERROR && (
							<XCheckIcon
								className={
									`${isChecked ? "opacity-100" : "opacity-0"} ` +
									`${props.size === "sm" ? "w-3.5" : "w-3.5"} ` +
									`${props.readOnly && isChecked ? "text-grey-tertiary" : ""} ` +
									`${!props.readOnly && isChecked ? "text-white" : ""} ` +
									`${props.readOnly && !isChecked ? "" : ""} ` +
									`${!props.readOnly && !isChecked ? "" : ""}`
								}
								data-type={props.dataType}
							/>
						)}
					</div>
				</div>

				<div
					className={
						`pointer-events-none w-fit max-w-full pl-2 ` +
						`${truncate ? "overflow-hidden overflow-ellipsis whitespace-nowrap leading-none" : "leading-4"} ` +
						`${props.readOnly ? "text-black-tertiary" : " text-black-secondary"} ` +
						`${props.size === "sm" ? "text-sm" : "text-base"} `
					}
					data-type={props.dataType}
				>
					{props.text || ""}
				</div>
			</div>
		</>
	);
}

export default Checkbox;
