import {Redirect, Route} from "react-router-dom";

import React from "react";
/* eslint-disable @typescript-eslint/no-explicit-any */
import {canShowPreReleaseFeatures} from "../utils/preReleaseConfig";

interface Props {
	path: string;
	component?: React.LazyExoticComponent<() => JSX.Element>;
	nonLazyComponent?: () => JSX.Element;
	exact?: boolean;
	isPreRelease?: boolean;
}

function AppRoute({path, component, exact = false, isPreRelease = false, nonLazyComponent}: Props): JSX.Element {
	if (isPreRelease && !canShowPreReleaseFeatures) {
		return <Redirect to="/" />;
	}
	return <Route path={path} component={component || nonLazyComponent} exact={exact} />;
}

export default AppRoute;
