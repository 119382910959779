import {dependencyItem, hintMarkItem} from "../../../components/element/hint/hint.constant";

import AccountOpeningProgress from "../../../../../../models/account-opening/progress/accountOpeningProgress";
import NewAccountOpeningProgress from "../../../../../../models/account-opening/progress/newAccountOpeningProgress";
import SignatoryInfo from "../../../../../../models/account-opening/signatoryInfo";

export type CurrentSignatoryPersonnelRequest = {
	isDirector: boolean | null;
};
export type CurrentSignatoryBioRequest = {
	otherName: string;
	motherMaidenName: string;
	roleId: number | null;
	otherRoleText: string | null;
	nonNigerianNationality: string | null;
	residencyPermit: ResidencyPermitSubForm | null;
	nameTitleId: number;
	firstName: string;
	surname: string;
	dateOfBirth: string;
	genderId: number;
	phone: string[];
	bvn: string;
	address: string;
	isNigerian: boolean;
};

export type SignatoryBVNForm = {
	bvn: string;
};
export type SignatoryKeyContactForm = {
	keyContact: string;
};

export type NextOfKinForm = {
	name: string;
	genderId: number | null;
	relationshipId: number | null;
	otherRelationshipText: string | null;
	phone: string;
	address: string;
};

export enum NextOfKinFormFields {
	NAME = "name",
	PHONE = "phone",
	GENDER = "genderId",
	ADDRESS = "address",
	RELATIONSHIP = "relationshipId",
	// OTHER_RELATIONSHIP_TEXT = "otherRelationshipText",
}

export const NextOfKinFormFieldTexts: {
	[type in NextOfKinFormFields]: string;
} = {
	[NextOfKinFormFields.NAME]: "Full Name",
	[NextOfKinFormFields.PHONE]: "Phone Number",
	[NextOfKinFormFields.GENDER]: "Gender",
	[NextOfKinFormFields.ADDRESS]: "Address",
	[NextOfKinFormFields.RELATIONSHIP]: "Relationship",
};

export const NextOfKinFormTextArray = [
	{formikName: NextOfKinFormFields.NAME, text: NextOfKinFormFieldTexts[NextOfKinFormFields.NAME]},
	{formikName: NextOfKinFormFields.PHONE, text: NextOfKinFormFieldTexts[NextOfKinFormFields.PHONE]},
	{formikName: NextOfKinFormFields.GENDER, text: NextOfKinFormFieldTexts[NextOfKinFormFields.GENDER]},
	{formikName: NextOfKinFormFields.ADDRESS, text: NextOfKinFormFieldTexts[NextOfKinFormFields.ADDRESS]},
	{formikName: NextOfKinFormFields.RELATIONSHIP, text: NextOfKinFormFieldTexts[NextOfKinFormFields.RELATIONSHIP]},
];

export const NextOfKinFormTextMap = NextOfKinFormTextArray.reduce((accumulator, obj) => {
	return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getNextOfKinFormHints(
	_formikList: string[],
	_uncompleted: string[],
	_exempted: string[],
	_dependencies: dependencyItem[]
): hintMarkItem[] {
	let computedList: hintMarkItem[] = [];
	const completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));
	// const uncompleted = _uncompleted.filter((_) => !_exempted.some((_un) => _un === _));
	for (const _string of completed) {
		computedList.push({
			isChecked: true,
			text: NextOfKinFormTextMap.get(_string) || "",
		});
	}
	for (const _string of _uncompleted) {
		computedList = computedList.map((_) =>
			_.text === NextOfKinFormTextMap.get(_string)
				? {
						isChecked: false,
						text: _.text,
				  }
				: _
		);
	}
	const notFilledDependencies = _dependencies.filter(
		(_dependency) => _uncompleted.some((_) => _ === _dependency.primary) && _uncompleted.some((_) => _ === _dependency.secondary)
	);
	for (const _dependency of notFilledDependencies) {
		computedList = computedList.map((_) =>
			_.text === NextOfKinFormTextMap.get(_dependency.primary)
				? {
						isChecked: false,
						text: _.text,
				  }
				: _
		);
	}
	return computedList;
}

export type PersonalDetailForm = {
	nameTitleId: number;
	firstName: string;
	otherName: string;
	surname: string;
	dateOfBirth: Date | null;
	motherMaidenName: string;
	phone: string;
	isNigerian: boolean | null;
	nonNigerianNationality: string | null;
	bvn: string;
	roleId: number;
	otherRoleText: string | null;
	genderId: number;
	address: string;
	residencyPermit: ResidencyPermitSubForm | null;
};

export enum PersonalDetailFormFields {
	BVN = "bvn",
	ROLE = "roleId",
	PHONE = "phone",
	GENDER = "genderId",
	SURNAME = "surname",
	ADDRESS = "address",
	FIRST_NAME = "firstName",
	NAME_TITLE = "nameTitleId",
	OTHER_NAME = "otherName",
	IS_NIGERIAN = "isNigerian",
	DATE_OF_BIRTH = "dateOfBirth",
	OTHER_ROLE_TEXT = "otherRoleText",
	RESIDENCY_PERMIT = "residencyPermit",
	MOTHER_MAIDEN_NAME = "motherMaidenName",
	NON_NIGERIAN_NATIONALITY = "nonNigerianNationality",
}

export const PersonalDetailFormFieldTexts: {
	[type in PersonalDetailFormFields]: string;
} = {
	[PersonalDetailFormFields.BVN]: "bvn",
	[PersonalDetailFormFields.ROLE]: "role in business",
	[PersonalDetailFormFields.PHONE]: "phone",
	[PersonalDetailFormFields.GENDER]: "gender",
	[PersonalDetailFormFields.SURNAME]: "last name",
	[PersonalDetailFormFields.ADDRESS]: "address",
	[PersonalDetailFormFields.NAME_TITLE]: "title",
	[PersonalDetailFormFields.FIRST_NAME]: "first name",
	[PersonalDetailFormFields.OTHER_NAME]: "other name",
	[PersonalDetailFormFields.IS_NIGERIAN]: "nationality",
	[PersonalDetailFormFields.DATE_OF_BIRTH]: "date of birth",
	[PersonalDetailFormFields.OTHER_ROLE_TEXT]: "other role in business",
	[PersonalDetailFormFields.RESIDENCY_PERMIT]: "residency permit",
	[PersonalDetailFormFields.MOTHER_MAIDEN_NAME]: "Mother Maiden Name",
	[PersonalDetailFormFields.NON_NIGERIAN_NATIONALITY]: "Non Nigerian Nationality",
};

export const PersonalDetailFormTextArray = [
	{formikName: PersonalDetailFormFields.BVN, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.BVN]},
	{formikName: PersonalDetailFormFields.ROLE, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.ROLE]},
	{formikName: PersonalDetailFormFields.PHONE, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.PHONE]},
	{formikName: PersonalDetailFormFields.GENDER, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.GENDER]},
	{formikName: PersonalDetailFormFields.SURNAME, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.SURNAME]},
	{formikName: PersonalDetailFormFields.ADDRESS, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.ADDRESS]},
	{formikName: PersonalDetailFormFields.NAME_TITLE, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.NAME_TITLE]},
	{formikName: PersonalDetailFormFields.FIRST_NAME, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.FIRST_NAME]},
	{formikName: PersonalDetailFormFields.OTHER_NAME, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.OTHER_NAME]},
	{formikName: PersonalDetailFormFields.IS_NIGERIAN, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.IS_NIGERIAN]},
	{formikName: PersonalDetailFormFields.DATE_OF_BIRTH, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.DATE_OF_BIRTH]},
	{formikName: PersonalDetailFormFields.OTHER_ROLE_TEXT, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.OTHER_ROLE_TEXT]},
	{formikName: PersonalDetailFormFields.RESIDENCY_PERMIT, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.RESIDENCY_PERMIT]},
	{formikName: PersonalDetailFormFields.MOTHER_MAIDEN_NAME, text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.MOTHER_MAIDEN_NAME]},
	{
		formikName: PersonalDetailFormFields.NON_NIGERIAN_NATIONALITY,
		text: PersonalDetailFormFieldTexts[PersonalDetailFormFields.NON_NIGERIAN_NATIONALITY],
	},
];

export const PersonalDetailFormTextMap = PersonalDetailFormTextArray.reduce((accumulator, obj) => {
	return accumulator.set(obj.formikName, obj.text);
}, new Map<string, string>());

export function getPersonalDetailFormHints(
	_formikList: string[],
	_uncompleted: string[],
	_exempted: string[],
	_dependencies: dependencyItem[]
): hintMarkItem[] {
	let computedList: hintMarkItem[] = [];
	const completed = _formikList.filter((_) => !_exempted.some((_un) => _un === _));
	// const uncompleted = _uncompleted.filter((_) => !_exempted.some((_un) => _un === _));
	for (const _string of completed) {
		computedList.push({
			isChecked: true,
			text: PersonalDetailFormTextMap.get(_string) || "",
		});
	}
	for (const _string of _uncompleted) {
		computedList = computedList.map((_) =>
			_.text === PersonalDetailFormTextMap.get(_string)
				? {
						isChecked: false,
						text: _.text,
				  }
				: _
		);
	}
	const notFilledDependencies = _dependencies.filter(
		(_dependency) => _uncompleted.some((_) => _ === _dependency.primary) && _uncompleted.some((_) => _ === _dependency.secondary)
	);
	for (const _dependency of notFilledDependencies) {
		computedList = computedList.map((_) =>
			_.text === PersonalDetailFormTextMap.get(_dependency.primary)
				? {
						isChecked: false,
						text: _.text,
				  }
				: _
		);
	}
	return computedList;
}

export interface NewCurrentSignatoryResponse {
	signatory: SignatoryInfo | null;
	progress: NewAccountOpeningProgress;
}

export interface CurrentSignatoryResponse {
	currentSignatory: SignatoryInfo;
	progress: AccountOpeningProgress;
}
export type ResidencyPermitSubForm = {
	permitNumber: string;
	issueDate: Date | null;
	expiryDate: Date | null;
};

export type IdentificationRequest = {
	file: Blob | null;
	typeId: number | null;
};

export type PhotoRequest = {
	file: Blob | null;
};
export type SignatureRequest = {
	file: Blob | null;
};

export type SignatoryPersonalDetailsForm = {
	nameTitleId: number;
	firstName: string;
	otherName: string;
	surname: string;
	dateOfBirth: string;
	motherMaidenName: string;
	phone: string;
	isNigerian: boolean | null;
	nonNigerianNationality: string | null;
	bvn: string;
	roleId: number;
	otherRoleText: string | null;
	genderId: number;
	address: string;
	residencyPermit: ResidencyPermitSubForm | null;
};

export type SignatoryForm = {
	signatories: SignatoryPersonalDetailsForm[];
};

export type UploadSignatoryDocumentForm = {
	signatory: string;
	identification: Blob | null;
	passportPhoto: Blob | null;
};
