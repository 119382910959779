import {AddMoneyFundingStageType, AddMoneyFundingType, AddMoneyPhase} from "../../../../redux/payments/addMoney/slice/addMoneySlice.types";
import React, {useEffect, useLayoutEffect} from "react";
import {
	resetAddMoneySelectedId,
	resetAllAddMoneyData,
	setAddMoneyFundingStage,
	setAddMoneyFundingType,
	setAddMoneyPageFrom,
	setAddMoneyPhase,
	setAddMoneySelectedId,
} from "../../../../redux/payments/addMoney/slice/addMoneySlice";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router";

import AddMoneyLayout from "../Components/Layouts/AddMoney";
import BankTransfer from "../Components/AddMoney/BankTransfer";
// import CardPayment from "../Components/AddMoney/CardPayment";
import {IRootState} from "../../../../redux/rootReducer";
import MobileMoney from "../Components/AddMoney/MobileMoney";
import {PageTitle} from "../../../../helpers/AppConstants";
import TypeOfAddMoney from "../Components/AddMoney/TypeOfAddMoney";
import doesUserHaveAccessToAccount from "../../../../helpers/doesUserHaveAccessToAccount";
import {useLocation} from "react-router-dom";

interface AddMoneyUrlParams {
	urlFundingType: AddMoneyFundingType | undefined;
}
interface LocationState {
	to: string;
	from: string;
	temp?: boolean;
	pageFrom?: string;
	accountToFund?: string;
	typeOfFunding?: AddMoneyFundingType;
}

function AddMoney(): JSX.Element {
	document.title = PageTitle.PAYMENTS_PAGE;

	const params = useParams<AddMoneyUrlParams>();
	const history = useHistory();
	const dispatch = useDispatch();
	const location = useLocation<LocationState>();

	const fundingType = useSelector((state: IRootState) => state.addMoney.fundingType);
	const fundingStage = useSelector((state: IRootState) => state.addMoney.fundingStage);
	const addMoneyPhase = useSelector((state: IRootState) => state.addMoney.addMoneyPhase);

	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
	const selectedAccountId = useSelector((state: IRootState) => state.addMoney.selectedId);

	useLayoutEffect(() => {
		if (!accounts) return;
		if (accounts.length < 2) {
			if (doesUserHaveAccessToAccount(accounts[0].id)) {
				dispatch(setAddMoneySelectedId(accounts[0].id));
				// setAddMoneyStage(ZambiaAddMoneyModalSections.FUNDING);
			}
		}
	}, [accounts]);

	useEffect(() => {
		if (!location) return;

		if (location?.state?.pageFrom) {
			dispatch(setAddMoneyPageFrom(location.state.pageFrom));
		}

		if (params.urlFundingType) {
			dispatch(setAddMoneyFundingType(params.urlFundingType));
			selectedAccountId ? dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.PAYMENT_DETAILS)) : undefined;
			selectedAccountId ? dispatch(setAddMoneyPhase(AddMoneyPhase.FUNDING)) : undefined;
		} else {
			dispatch(setAddMoneyPhase(AddMoneyPhase.ACCOUNT_AND_METHOD));
			dispatch(setAddMoneyFundingStage(AddMoneyFundingStageType.INITIAL));
		}

		if (location?.state?.accountToFund) {
			dispatch(setAddMoneySelectedId(location?.state?.accountToFund));
		}
	}, [location, params.urlFundingType, selectedAccountId]);

	useEffect(() => {
		return () => {
			dispatch(resetAddMoneySelectedId());
			dispatch(resetAllAddMoneyData());
		};
	}, []);

	return (
		<>
			<AddMoneyLayout>
				{addMoneyPhase === AddMoneyPhase.ACCOUNT_AND_METHOD && fundingStage === AddMoneyFundingStageType.INITIAL && (
					<TypeOfAddMoney
						fundingType={fundingType}
						selectedAccountId={selectedAccountId || ""}
						// handleNext={() => fundingType && history.push(AddMoneyFundingLink[fundingType].link)}
						// handleViewHistory={() => history.push("/payments/add-money/history")}
						// handleSelectAccount={(_id) => dispatch(setAddMoneySelectedId(_id))}
						// handleSelectFundingMethod={(_value: AddMoneyFundingType) => dispatch(setAddMoneyFundingType(_value))}
					/>
				)}

				{addMoneyPhase === AddMoneyPhase.FUNDING &&
					fundingStage !== AddMoneyFundingStageType.INITIAL &&
					params.urlFundingType === AddMoneyFundingType.BANK_TRANSFER && (
						<BankTransfer
							handleBack={() => history.push("/payments/add-money/make")}
							handleDone={() => history.push("/")}
							selectedAccountId={selectedAccountId || ""}
						/>
					)}
				{/* {addMoneyPhase === AddMoneyPhase.FUNDING &&
					fundingStage !== AddMoneyFundingStageType.INITIAL &&
					params.urlFundingType === AddMoneyFundingType.CARD_PAYMENT && (
						<CardPayment handleBack={() => history.push("/payments/add-money/make")} selectedAccountId={selectedAccountId} />
					)} */}
				{addMoneyPhase === AddMoneyPhase.FUNDING &&
					fundingStage !== AddMoneyFundingStageType.INITIAL &&
					params.urlFundingType === AddMoneyFundingType.MOBILE_MONEY && (
						<MobileMoney
							handleBack={() => history.push("/payments/add-money/make")}
							handleClose={() => history.push("/")}
							selectedAccountId={selectedAccountId || ""}
						/>
					)}
			</AddMoneyLayout>
		</>
	);
}

export default AddMoney;
