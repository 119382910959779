import ButtonComp from "../../../../../components/General/Buttons/ButtonComp";
import Modal from "../../../../../components/General/Modals/Modal";
import ModalBody from "../../../../../components/General/Modals/ModalBody";
import ModalFooter from "../../../../../components/General/Modals/ModalFooter";
import ModalHeader from "../../../../../components/General/Modals/ModalHeader";
import NewCards from "../../../../../assets/svg/Cards/new-cards.png";
import React from "react";
import {saveCardModalSkipped} from "../../../../../helpers/cardReminder";
import {useHistory} from "react-router";

interface Props {
	active: boolean;
	toggler: () => void;
}

function CardOnboardingModal(props: Props): JSX.Element {
	const history = useHistory();
	return (
		<>
			<Modal
				size="md"
				imgSection={
					<div className="max-w-26 w-full">
						<img src={NewCards} alt="new cards with confetti"></img>
					</div>
				}
				active={props.active}
				toggler={props.toggler}
			>
				<ModalHeader withImgSection onClose={props.toggler}>
					<p className="text-3xl text-black font-bold">The corporate card that supports your business</p>
				</ModalHeader>
				<ModalBody>
					<div>
						<p className="text-lg text-black-secondary">Enhance your seamless spending experience.</p>
						<p className="text-lg text-black-secondary pt-8">
							Request a Lenco corporate debit card to empower your teams and manage your business expense transparently.
						</p>
					</div>
				</ModalBody>
				<ModalFooter>
					<div className="w-full flex justify-between items-center">
						<ButtonComp type="button" ripple="light" buttonType="tertiary" color="grey" func={props.toggler}>
							<span>Skip</span>
						</ButtonComp>

						<ButtonComp
							type="submit"
							color="blue"
							ripple="light"
							buttonType="primary"
							func={() => {
								saveCardModalSkipped();
								history.push({
									pathname: "/cards",
									state: {
										createCardInstant: true,
									},
								});
							}}
						>
							<span>Get Started</span>
						</ButtonComp>
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
}

export default CardOnboardingModal;
