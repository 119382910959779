import AccountOpeningProgressGeneric from "./accountOpeningProgressGeneric";
import AccountOpeningProgressSignatories from "./accountOpeningProgressSignatories";
import {AccountOpeningStatus} from "../accountOpening.constants";
import {GenericObject} from "../../../helpers/types";
import Parsers from "../../../utils/parsers";
import {immerable} from "immer";

export default class AccountOpeningProgress {
	[immerable] = true;

	constructor(
		public status: AccountOpeningStatus,

		// registered
		public company: boolean,
		public signatories: AccountOpeningProgressSignatories,
		public documents: boolean,
		public referees: AccountOpeningProgressGeneric,
		public agreements: AccountOpeningProgressGeneric,
		public currentSignatoryAgreements: AccountOpeningProgressGeneric,

		// unregistered
		public business: boolean,
		public personal: boolean,
		public identity: boolean
	) {}

	static create(obj: GenericObject): AccountOpeningProgress {
		return new AccountOpeningProgress(
			Parsers.number(obj.status),
			Parsers.boolean(obj.company),
			Parsers.classObjectNonNullable(obj.signatories, AccountOpeningProgressSignatories),
			Parsers.boolean(obj.documents),
			Parsers.classObjectNonNullable(obj.referees, AccountOpeningProgressGeneric),
			Parsers.classObjectNonNullable(obj.agreements, AccountOpeningProgressGeneric),
			Parsers.classObjectNonNullable(obj.currentSignatoryAgreements, AccountOpeningProgressGeneric),
			Parsers.boolean(obj.business),
			Parsers.boolean(obj.personal),
			Parsers.boolean(obj.identity)
		);
	}

	isCurrentSignatoryCompleted(): boolean {
		return this.signatories.currentSignatory;
	}

	currentSignatoryCompletedProgress(): number {
		return this.company ? 100 : 0;
	}

	isSignatoriesCompleted(): boolean {
		return this.signatories.isCompleted();
	}
	signatoriesCompletedProgress(): number {
		return Number(this.signatories.progressPercentage().toFixed());
	}

	hasInvitedSignatories(): boolean {
		return this.signatories.hasInvitedSignatories();
	}

	isDocumentsCompleted(): boolean {
		return this.documents;
	}

	documentsCompletedProgress(): number {
		return this.documents ? 100 : 0;
	}

	isRefereesCompleted(): boolean {
		return this.referees.total === 2 && this.referees.isCompleted();
	}

	refereesCompletedProgress(): number {
		return Number(this.referees.progressPercentage().toFixed());
	}

	isAgreementsCompleted(): boolean {
		return this.agreements.isCompleted();
	}

	agreementsCompletedProgress(): number {
		return Number(this.agreements.progressPercentage().toFixed());
	}

	isCurrentSignatoryAgreementCompleted(): boolean {
		return this.currentSignatoryAgreements.isCompleted();
	}

	get isInProgress(): boolean {
		return this.status === AccountOpeningStatus.IN_PROGRESS;
	}

	get isBeingReviewed(): boolean {
		return this.status === AccountOpeningStatus.REVIEWING;
	}

	get isBeingCreated(): boolean {
		return this.status === AccountOpeningStatus.CREATING;
	}

	get isCreated(): boolean {
		return this.status === AccountOpeningStatus.CREATED;
	}

	isBusinessCompleted(): boolean {
		return this.business;
	}
	businessCompletedProgress(): number {
		return this.business ? 100 : 0;
	}

	isPersonalCompleted(): boolean {
		return this.personal;
	}
	personalCompletedProgress(): number {
		return this.personal ? 100 : 0;
	}

	isIdentityCompleted(): boolean {
		return this.identity;
	}

	identityCompletedProgress(): number {
		return this.identity ? 100 : 0;
	}
}
