import {BulkPaymentResponse, BulkTransferDetail} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import {useCallback, useState} from "react";

import {bulkPaymentInit} from "../../../../Services/SendMoney/bulkTransferApi";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {setBulkTransferResponse} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
// import {setMultipleTransactions} from "../../../../../../../redux/transaction/slice/transactionSlice";
import {useDispatch} from "react-redux";

interface UseBulkTransferInterface {
	isBulkPaymentLoading: boolean;
	bulkPaymentResponse: BulkPaymentResponse | null;
	bulkPaymentError: boolean;
	handleBulkTransfer: (_data: BulkTransferDetail) => Promise<void>;
}

function useBulkTransfer(): UseBulkTransferInterface {
	const dispatch = useDispatch();
	const [isBulkPaymentLoading, setIsBulkPaymentLoading] = useState(false);
	const [bulkPaymentResponse, setBulkPaymentResponse] = useState<BulkPaymentResponse | null>(null);
	const [bulkPaymentError, setBulkPaymentError] = useState<boolean>(false);

	const handleBulkTransfer = useCallback(
		async (_data) => {
			try {
				setIsBulkPaymentLoading(true);
				setBulkPaymentError(false);
				const res = await bulkPaymentInit(_data);
				setBulkPaymentResponse({
					// isApproved: res.isApproved,
					// processing: res.processing,
					// transactionsId: res.transactions.map((_el) => _el.id),
					bulkPaymentKey: res.bulkPaymentKey,
					accountsToActivate: res.accountsToActivate,
				});
				dispatch(
					setBulkTransferResponse({
						// isApproved: res.isApproved,
						// processing: res.processing,
						// transactionsId: res.transactions.map((_el) => _el.id),
						bulkPaymentKey: res.bulkPaymentKey,
						accountsToActivate: res.accountsToActivate,
					})
				);
				// dispatch(setMultipleTransactions(res.transactions));
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				dispatch(errorTrue({message: errorMessage}));
				setBulkPaymentError(true);
			} finally {
				setIsBulkPaymentLoading(false);
			}
		},
		[dispatch]
	);

	return {
		isBulkPaymentLoading,
		bulkPaymentResponse,
		bulkPaymentError,
		handleBulkTransfer,
	};
}

export default useBulkTransfer;
