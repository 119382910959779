import {ExternalApplicationRequests, abortExternalApplicationRequest, updateExternalDirectorBio} from "../service/externalApplication.api";
import {useCallback, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {ExternalInitRequest} from "../service/externalApplication.types";
import {IRootState} from "../../../../../redux/rootReducer";
import {Routes} from "../../../../../routes/routes.constants";
import {SignatoryPersonalDetailsForm} from "../../../../Dashboard/Application/services/nigeria-application/registered/currentSignatory.types";
import {errorTrue} from "../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../utils/getErrorMessage";
import {updateExternalDirector} from "../../../../../redux/externalApplication/externalApplicationSlice";
import {useHistory} from "react-router";

export interface UseExternalDirectorBioInterface {
	isExternalDirectorBioError: string | null;
	isExternalDirectorBioLoading: boolean | null;
	handleReset: () => void;
	handleUpdateExternalDirectorBio: (_externalParams: ExternalInitRequest, _data: SignatoryPersonalDetailsForm) => Promise<void>;
}

const useExternalDirectorBio = (): UseExternalDirectorBioInterface => {
	const history = useHistory();
	const dispatch = useDispatch();

	const url = useSelector((state: IRootState) => state.externalApplication.externalLink || "");

	const [isExternalDirectorBioError, setIsExternalDirectorBioError] = useState<string | null>(null);
	const [isExternalDirectorBioLoading, setIsExternalDirectorBioLoading] = useState<boolean | null>(false);

	const handleUpdateExternalDirectorBio = useCallback(
		async (_externalParams: ExternalInitRequest, _data: SignatoryPersonalDetailsForm): Promise<void> => {
			try {
				setIsExternalDirectorBioError(null);
				setIsExternalDirectorBioLoading(true);
				const res = await updateExternalDirectorBio(_externalParams, _data);
				dispatch(updateExternalDirector(res.director));
				history.push({
					pathname: `${url}${Routes.EXTERNAL_DIRECTOR.NEXT_OF_KIN}`,
					state: {
						from: history.push(`${url}${Routes.EXTERNAL_DIRECTOR.PERSONAL_DETAILS.DETAILS}`),
					},
				});
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				setIsExternalDirectorBioError(errorMessage);
				dispatch(errorTrue(errorMessage));
			} finally {
				setIsExternalDirectorBioLoading(false);
			}
		},
		[dispatch]
	);

	const handleReset = useCallback(() => {
		setIsExternalDirectorBioError(null);
		setIsExternalDirectorBioLoading(false);
		abortExternalApplicationRequest(ExternalApplicationRequests.UPDATE_EXTERNAL_DIRECTOR_BIO);
	}, []);

	return {
		isExternalDirectorBioError,
		isExternalDirectorBioLoading,
		handleReset,
		handleUpdateExternalDirectorBio,
	};
};
export default useExternalDirectorBio;
