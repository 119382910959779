import {BasicFinalStatus, TransactionType} from "../../../models/posTransaction.constants";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {
	TerminalTransactionDateType,
	TerminalTransactionFilterTypes,
	TerminalTransactionsState,
	TransactionDateTypes,
} from "./terminalTransactionsSlice.types";

import PosTransaction from "../../../models/posTransaction";

const initialState: TerminalTransactionsState = {
	terminalTransactionDetail: null,
	isTerminalTransactionDetailCardOpen: false,

	posTransactionList: null,
	isTransactionPaginationLoading: false,
	transactionPaginationOffset: 0,
	transactionsListTotal: 0,
	transactionsListGroupSize: 0,
	isExportTransactionsLoading: false,
	isTransactionListEmpty: false,
	isTransactionSearchQuery: false,
	transactionSearchQuery: "",
	transactionAccountQuery: "",
	isTransactionAccountQuery: false,
	transactionDate: TransactionDateTypes.ALL_TIME,
	selectedTransactionDate: TransactionDateTypes.ALL_TIME,
	isCustomModalOpen: false,
	transactionFilterState: {
		offset: 0,
		query: "",
		endDate: "",
		startDate: "",
		types: [],
		statuses: [],
		terminalId: "",
	},
	selectedTransactionFilterState: {
		offset: 0,
		query: "",
		endDate: "",
		startDate: "",
		types: [],
		statuses: [],
		terminalId: "",
	},
};

export const terminalTransactionsSlice = createSlice({
	name: "terminalTransactions",
	initialState,
	reducers: {
		setSelectedTerminalTransactionsDetails: (state: TerminalTransactionsState, action: PayloadAction<string>) => {
			const terminal = state.posTransactionList?.find((_sub) => _sub.id === action.payload);
			if (terminal) {
				state.terminalTransactionDetail = terminal;
			}
		},
		setIsTerminalTransactionsDetailCardOpen: (state: TerminalTransactionsState, action: PayloadAction<boolean>) => {
			state.isTerminalTransactionDetailCardOpen = action.payload;
		},

		setIsCustomModalOpen: (state: TerminalTransactionsState, action: PayloadAction<boolean>) => {
			state.isCustomModalOpen = action.payload;
		},
		setIsExportTerminalTransactionsLoading: (state: TerminalTransactionsState, action: PayloadAction<boolean>) => {
			state.isExportTransactionsLoading = action.payload;
		},

		setIsTerminalTransactionsPaginationLoading: (state: TerminalTransactionsState, action: PayloadAction<boolean>) => {
			state.isTransactionPaginationLoading = action.payload;
		},
		setIsTerminalTransactionsListEmpty: (state: TerminalTransactionsState, action: PayloadAction<boolean>) => {
			state.isTransactionListEmpty = action.payload;
		},
		setTerminalTransactionsDate: (state: TerminalTransactionsState, action: PayloadAction<TerminalTransactionDateType>) => {
			if (action.payload.date.startDate && action.payload.date.endDate) {
				state.transactionFilterState = {
					...state.transactionFilterState,
					startDate: action.payload.date.startDate,
					endDate: action.payload.date.endDate,
				};
				state.transactionDate = action.payload.name;
			}
		},
		setTerminalTransactionsList: (state: TerminalTransactionsState, action: PayloadAction<PosTransaction[]>) => {
			state.posTransactionList = action.payload;
		},
		setTerminalTransactionsListTotal: (state: TerminalTransactionsState, action: PayloadAction<number>) => {
			state.transactionsListTotal = action.payload;
		},
		setTerminalTransactionsPaginationOffset: (state: TerminalTransactionsState, action: PayloadAction<number>) => {
			state.transactionPaginationOffset = action.payload;
		},
		setTerminalTransactionsListGroupSize: (state: TerminalTransactionsState, action: PayloadAction<number>) => {
			state.transactionsListGroupSize = action.payload;
		},
		setTerminalTransactionsFilterState: (state: TerminalTransactionsState, action: PayloadAction<TerminalTransactionFilterTypes>) => {
			state.transactionFilterState = {...state.transactionFilterState, ...action.payload};
		},
		setTerminalTransactionsDateFilter: (state: TerminalTransactionsState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, endDate: "", startDate: ""};
			state.transactionFilterState = {...state.transactionFilterState, endDate: "", startDate: ""};
			state.selectedTransactionDate = TransactionDateTypes.ALL_TIME;
			state.transactionDate = TransactionDateTypes.ALL_TIME;
		},
		setSelectedTerminalTransactionFilterState: (state: TerminalTransactionsState) => {
			state.selectedTransactionFilterState = {...state.transactionFilterState};
			state.selectedTransactionDate = state.transactionDate;
		},
		setSelectedTerminalTransactionFilterStateInstant: (
			state: TerminalTransactionsState,
			action: PayloadAction<TerminalTransactionFilterTypes>
		) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, ...action.payload};
			state.transactionFilterState = {...state.transactionFilterState, ...action.payload};
		},
		setTerminalTransactionsTerminalIdFilter: (state: TerminalTransactionsState, action: PayloadAction<string>) => {
			state.selectedTransactionFilterState.terminalId = action.payload;
			state.transactionFilterState.terminalId = action.payload;
		},
		setTerminalTransactionsTypesFilter: (state: TerminalTransactionsState, action: PayloadAction<TransactionType>) => {
			state.selectedTransactionFilterState.types = state.selectedTransactionFilterState.types.filter((_types) => _types !== action.payload);
			state.transactionFilterState.types = state.transactionFilterState.types.filter((_types) => _types !== action.payload);
		},
		setTerminalTransactionsStatusesFilter: (state: TerminalTransactionsState, action: PayloadAction<BasicFinalStatus>) => {
			state.selectedTransactionFilterState.statuses = state.selectedTransactionFilterState.statuses.filter(
				(_status) => _status !== action.payload
			);
			state.transactionFilterState.statuses = state.transactionFilterState.statuses.filter((_status) => _status !== action.payload);
		},

		resetTerminalTransactionsList: (state: TerminalTransactionsState) => {
			state.posTransactionList = null;
		},
		resetTerminalTransactionsDateFilterState: (state: TerminalTransactionsState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, endDate: "", startDate: ""};
			state.transactionFilterState = {...state.transactionFilterState, endDate: "", startDate: ""};
			state.selectedTransactionDate = TransactionDateTypes.ALL_TIME;
			state.transactionDate = TransactionDateTypes.ALL_TIME;
		},
		resetTerminalTransactionsTerminalIdFilterState: (state: TerminalTransactionsState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, terminalId: ""};
			state.transactionFilterState = {...state.transactionFilterState, terminalId: ""};
		},
		resetTerminalTransactionsStatusesFilterState: (state: TerminalTransactionsState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, statuses: []};
			state.transactionFilterState = {...state.transactionFilterState, statuses: []};
		},
		resetTerminalTransactionsTypesFilterState: (state: TerminalTransactionsState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, types: []};
			state.transactionFilterState = {...state.transactionFilterState, types: []};
		},
		resetTerminalTransactionsQueryFilterState: (state: TerminalTransactionsState) => {
			state.selectedTransactionFilterState = {...state.selectedTransactionFilterState, query: ""};
			state.transactionFilterState = {...state.transactionFilterState, query: ""};
		},
		resetTerminalTransactionsFilterState: (state: TerminalTransactionsState) => {
			state.transactionFilterState = {
				query: "",
				offset: 0,
				endDate: "",
				startDate: "",
				types: [],
				statuses: [],
				terminalId: "",
			};
			state.selectedTransactionFilterState = {
				query: "",
				offset: 0,
				endDate: "",
				startDate: "",
				types: [],
				statuses: [],
				terminalId: "",
			};
		},
		resetAllTerminalTransactionsData: (state: TerminalTransactionsState) => {
			state.terminalTransactionDetail = null;
			state.isTerminalTransactionDetailCardOpen = false;
			state.posTransactionList = null;
			state.transactionsListTotal = 0;
			state.transactionPaginationOffset = 0;
			state.transactionsListGroupSize = 0;
			state.isExportTransactionsLoading = false;
			state.isTransactionListEmpty = false;
			state.isTransactionSearchQuery = false;
			state.transactionSearchQuery = "";
			state.transactionAccountQuery = "";
			state.isTransactionAccountQuery = false;
			state.isTransactionPaginationLoading = false;
			state.transactionDate = TransactionDateTypes.ALL_TIME;
			state.selectedTransactionDate = TransactionDateTypes.ALL_TIME;
			state.isCustomModalOpen = false;
			state.transactionFilterState = {
				query: "",
				offset: 0,

				endDate: "",
				startDate: "",
				types: [],
				statuses: [],
				terminalId: "",
			};
			state.selectedTransactionFilterState = {
				query: "",
				offset: 0,

				endDate: "",
				startDate: "",
				types: [],
				statuses: [],
				terminalId: "",
			};
		},
	},
});

export const {
	setSelectedTerminalTransactionsDetails,
	setIsTerminalTransactionsDetailCardOpen,

	setIsCustomModalOpen,

	setTerminalTransactionsList,
	setTerminalTransactionsListTotal,
	setTerminalTransactionsPaginationOffset,
	setTerminalTransactionsFilterState,
	setIsTerminalTransactionsListEmpty,
	setTerminalTransactionsListGroupSize,
	setIsExportTerminalTransactionsLoading,
	setIsTerminalTransactionsPaginationLoading,
	setTerminalTransactionsDate,
	setTerminalTransactionsDateFilter,
	setSelectedTerminalTransactionFilterStateInstant,
	setTerminalTransactionsTypesFilter,
	setTerminalTransactionsStatusesFilter,
	setSelectedTerminalTransactionFilterState,

	resetTerminalTransactionsList,
	resetTerminalTransactionsFilterState,
	resetTerminalTransactionsDateFilterState,
	resetTerminalTransactionsQueryFilterState,
	resetTerminalTransactionsStatusesFilterState,
	resetTerminalTransactionsTypesFilterState,
	resetAllTerminalTransactionsData,
} = terminalTransactionsSlice.actions;

export default terminalTransactionsSlice.reducer;
