import {Link, useHistory} from "react-router-dom";

import Card from "../../../models/card";
import {ReactComponent as FundCard} from "../../../assets/svg/Cards/card-receive.svg";
import {PaymentsType} from "../../../modules/Dashboard/Payments/Hooks/State/SendMoney/payments.constants";
import React from "react";
import TooltipIcons from "../../General/TooltipIcons/TooltipIcons";
import titleCase from "../../../hooks/titleCase";

interface CardsSearchCardProps {
	index: number;
	card: Card;
	onClick: () => void;
}

export function CardsSearchCard({index, card, onClick}: CardsSearchCardProps): JSX.Element {
	const history = useHistory();
	return (
		<>
			<Link
				to={{
					pathname: `/cards`,
					search: `?query=${card.id}`,
					state: {
						cardId: card.id,
					},
				}}
				onClick={onClick}
				tabIndex={-1}
				data-type="transaction"
			>
				<div className="" key={index} data-type="transaction">
					<div data-type="transaction">
						<div
							className="flex cursor-pointer flex-row items-center justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop"
							data-type="transaction"
						>
							<div className="w-30% pr-1" data-type="transaction">
								<p
									className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-secondary"
									data-type="transaction"
								>
									{titleCase(card.name)}
								</p>
							</div>
							<div className="flex w-30% items-center justify-start" data-type="transaction">
								<p
									className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary"
									data-type="transaction"
								>
									••••{titleCase(card.maskedPan).slice(-4)}
								</p>
							</div>
							<div className="flex  w-35% items-center justify-between" data-type="transaction">
								<p
									className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary "
									data-type="transaction"
								>
									{card.walletTypeName}
								</p>
								<div className="pl-6" data-type="transaction">
									<TooltipIcons
										icon={<FundCard className="stroke-current text-blue" />}
										size="small"
										message="Fund Card"
										placement="right"
										data-type="transaction"
										onClick={() => {
											history.push({
												pathname: "/payments/make/single",
												search: `?from=${card.id}`,
												state: {
													typeOfTransfer: PaymentsType.FUND_CARD,
													to: card.id,
													temp: true,
												},
											});
											onClick();
										}}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</Link>
		</>
	);
}
