import {Dispatch, SetStateAction, useCallback, useState} from "react";
import {downloadBlob, guessExtension} from "../../../../../../../utils/blob";

import Transaction from "../../../../../../../models/transaction";
import {downloadLencoReceipt} from "../Services/transactionDetailApi";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {messageTrue} from "../../../../../../../redux/message/slice/messageSlice";
import {useDispatch} from "react-redux";

export interface UseTransactionDetailsReceiptResponse {
	isDownloading: boolean;
	handleDownloadLencoReceipt: (transaction: Transaction) => Promise<void>;

	showUploadModal: boolean;
	setShowUploadModal: Dispatch<SetStateAction<boolean>>;
}

function useTransactionDetailsLencoReceipt(): UseTransactionDetailsReceiptResponse {
	const dispatch = useDispatch();

	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

	const handleDownloadLencoReceipt = useCallback(
		async (transaction: Transaction) => {
			try {
				setIsDownloading(true);
				const blob = await downloadLencoReceipt(transaction.id);
				downloadBlob(blob, `receipt.${guessExtension(blob.type) || "pdf"}`);
				dispatch(messageTrue({message: "Receipt Downloaded Successfully"}));
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsDownloading(false);
			}
		},
		[dispatch]
	);

	return {
		isDownloading,
		handleDownloadLencoReceipt,

		showUploadModal,
		setShowUploadModal,
	};
}

export default useTransactionDetailsLencoReceipt;
