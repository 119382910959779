import BillPurchase from "../../../../models/billPurchase";
import BillVendor from "../../../../models/billVendor";
import Transaction from "../../../../models/transaction";
import {
	BillHistoryTransactionDateTypes,
	BillPurchasesHistoryRequest,
} from "../../../../modules/Dashboard/Payments/Services/billPayment/billPaymentApi.types";

export enum BillPaymentPage {
	MAKE_PAYMENT = 1,
	PAYMENT_HISTORTY = 2,
}

export enum BillPaymentStage {
	INITIAL = 1,
	PAYMENT_DETAILS = 2,
	REVIEW_PAYMENT = 3,
	MAKE_PAYMENT = 4,
	PROCESS_PAYMENT = 5,
}

export enum BillPaymentType {
	AIRTIME_PAYMENT = "airtime",
	DATA_PAYMENT = "data",
	CABLE_TV_PAYMENT = "cableTV",
	ELECTRICITY_PAYMENT = "electricity",
	BILL_PAYMENT_HISTORY = "bill_payment_history",
}

export interface BillPaymentState {
	billPaymentStage: BillPaymentStage;
	billPaymentType: BillPaymentType;
	billPaymentTypeBeforeBillHistoryType: BillPaymentType | null;
	recentBillPurchases: BillPurchase[] | null;
	isRecentBillPurchasesLoading: boolean;
	recentBillPurchasesError: boolean;
	vendorsList: BillVendor[] | null;
	isVendorsListLoading: boolean;
	vendorsListError: boolean;
	payFrom: string;
	billPaymentPage: BillPaymentPage;
	billPurchases: BillPurchase[];
	billPurchasesGroupSize: number;
	billPurchasesTotal: number;
	billPurchasesOffSet: number;
	selectedBillPurchase: BillPurchase | null;
	billHistoryFilterState: BillPurchasesHistoryRequest;
	showBillPurchaseDetails: BillPurchase | null;
	isCustomModalOpen: boolean;
	billHistoryTransactionDateType: BillHistoryTransactionDateTypes | string;
	selectedBillHistoryTransaction: Transaction | null;
}
