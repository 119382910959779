import React, {useCallback, useEffect, useState} from "react";
import {resetAllSendMoneyData, setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {setSingleTransaction, showTransactionDetails} from "../../../../../../redux/transaction/slice/transactionSlice";
import {useDispatch, useSelector} from "react-redux";

import ActivateAccount from "../../../../Accounts/Components/Modals/ActivateAccountModal";
import ActivateAccountList from "../../../../Transactions/Components/Modal/ActivateAccountList";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import BarLoader from "react-bar-loader";
import BulkTransferDetailRow from "../../TableRows/BulkTransferDetailRow";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {ReactComponent as ChevronRightIcon} from "../../../../../../assets/images/chevronRight.svg";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import {ReactComponent as GreenCheck} from "../../../../../../assets/images/bluecheck.svg";
import {IRootState} from "../../../../../../redux/rootReducer";
import {Link} from "react-router-dom";
import MessageToasts from "../../../../../../components/General/MessageToasts/MessageToasts";
import {ReactComponent as OrangeCheck} from "../../../../../../assets/svg/General/Approval/pendingApprovalIcon.svg";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {Routes} from "../../../../../../routes/routes.constants";
import {TailSpin} from "react-loader-spinner";
import {ToastType} from "../../../../../../helpers/AppConstants";
import Transaction from "../../../../../../models/transaction";
import {TransactionStatus} from "../../../../../../models/transaction.constants";
import {TransferDetail} from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice.types";
import UserAccount from "../../../../../../models/userAccount";
import {errorTrue} from "../../../../../../redux/error/slice/errorSlice";
import formatNumber from "../../../../../../utils/formatNumber";
import {getErrorMessage} from "../../../../../../utils/getErrorMessage";
import {getTransactionDetails} from "../../../../Transactions/Components/Cards/TransactionDetailCard/Services/transactionDetailApi";
import {resetAllBulkTransferData} from "../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
// import {showTransactionDetails} from "../../../../../../redux/transaction/slice/transactionSlice";
import useCheckBulkStatus from "../../../Hooks/State/SendMoney/BulkTransfer/useCheckBulkStatus";
// import useGetTransaction from "../../../../Transactions/Hooks/State/useGetTransaction";
import {useMoneyToNumber} from "../../../../../../hooks/useMoneyToNumber";

function ProcessingPayment(): JSX.Element {
	const dispatch = useDispatch();

	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
	const currency = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts[0].bankAccountCurrency);
	const bulkPayment = useSelector((state: IRootState) => state.bulkTransfer.bulkTransferResponse);
	const transferDetailArray = useSelector((state: IRootState) => state.bulkTransfer.transferDetailArray);
	const transactions = useSelector<IRootState, Map<string, Transaction>>((state) => {
		return state.transaction.transactions;
	});

	// const [transactionList, setTransactionList] = useState<Array<Transaction> | null>(null);
	const [showActiveAccountModal, setShowActiveAccountModal] = useState(false);
	const [isFetchTransactionLoading, setIsFetchTransactionLoading] = useState(false);
	const [showActiveAccountListModal, setShowActiveAccountListModal] = useState(false);
	const [accountToActivate, setAccountToActivate] = useState<UserAccount | null>(null);
	const [accountsToActivate, setAccountsToActivate] = useState<Array<UserAccount | undefined>>();

	const [noOfProcessing, setNoOfProcessing] = useState<number>(0);
	const [noOfFailed, setNoOfFailed] = useState<number>(0);
	const [noOfPendingApproval, setNoOfPendingApproval] = useState<number>(0);
	const [noOfSuccessful, setNoOfSuccessful] = useState<number>(0);
	// const isPendingBulkPayment = transferDetailArray.some((_arr) => !_arr.canApprove);

	// const {bulkPaymentStatuses, handleGetBulkPaymentStatus} = useCheckBulkStatus();
	useCheckBulkStatus(
		!!bulkPayment?.bulkPaymentKey && transferDetailArray.some((_transfer) => _transfer.status === TransactionStatus.PROCESSING),
		bulkPayment?.bulkPaymentKey || ""
	);

	useEffect(() => {
		if (!bulkPayment || !transactions || !accounts) return;
		// setTransactionList(bulkPayment.transactionsId.map((el) => transactions.get(el) as Transaction));
		// void handleGetBulkPaymentStatus(bulkPayment.bulkPaymentKey);
		if (bulkPayment.accountsToActivate.length < 1) return;
		setAccountsToActivate(bulkPayment.accountsToActivate.map((el) => accounts.find((it) => it.id === el)) as UserAccount[]);
	}, [bulkPayment, transactions, accounts]);

	useEffect(() => {
		if (!transferDetailArray) return;
		setNoOfProcessing(
			transferDetailArray.reduce((acc, cur) => {
				if (cur.status === TransactionStatus.PROCESSING) {
					return acc + 1;
				}
				return acc;
			}, 0)
		);
		setNoOfPendingApproval(
			transferDetailArray.reduce((acc, cur) => {
				if (cur.status === TransactionStatus.PENDING_APPROVAL) {
					return acc + 1;
				}
				return acc;
			}, 0)
		);
		setNoOfSuccessful(
			transferDetailArray.reduce((acc, cur) => {
				if (cur.status === TransactionStatus.SUCCESS) {
					return acc + 1;
				}
				return acc;
			}, 0)
		);
		setNoOfFailed(
			transferDetailArray.reduce((acc, cur) => {
				if (cur.status === TransactionStatus.FAILED) {
					return acc + 1;
				}
				return acc;
			}, 0)
		);
	}, [transferDetailArray]);

	useEffect(() => {
		if (!bulkPayment || bulkPayment.accountsToActivate.length < 1) return;
		setShowActiveAccountListModal(true);
	}, []);

	const handleActivatedAccount = useCallback((e: UserAccount) => {
		setAccountsToActivate((prev) => prev?.filter((el) => el?.id !== e.id));
		setShowActiveAccountListModal(false);
		setShowActiveAccountModal(false);
	}, []);

	// const handleShowTransactionDetail = useCallback(
	// (t: TransferDetail) => {
	// dispatch(showTransactionDetails(t.transactionId));
	// },
	// [dispatch]
	// );

	const handleShowTransactionDetail = useCallback(
		(t: TransferDetail) => {
			if (transactions.has(t.transactionId)) {
				dispatch(showTransactionDetails(t.transactionId));
			} else {
				void handleFetchTransactionDetail(t);
			}
		},
		[dispatch]
	);

	const handleFetchTransactionDetail = useCallback(
		async (t: TransferDetail): Promise<void> => {
			try {
				setIsFetchTransactionLoading(true);
				const updatedTransaction = await getTransactionDetails(t.transactionId);
				dispatch(setSingleTransaction(updatedTransaction));
				dispatch(showTransactionDetails(t.transactionId));
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsFetchTransactionLoading(false);
			}
		},
		[dispatch]
	);

	const handleSelectAccountToActivate = useCallback(
		(e) => {
			setAccountToActivate(e);
			setShowActiveAccountModal(true);
			setShowActiveAccountListModal(false);
		},
		[dispatch]
	);

	const handleCloseActivateAccount = useCallback(() => {
		setShowActiveAccountModal(false);
	}, [dispatch]);

	return (
		<>
			<ActivateAccount
				active={showActiveAccountModal}
				data={accountToActivate as UserAccount}
				toggler={handleCloseActivateAccount}
				onCompleteFunc={handleActivatedAccount}
			/>

			<ActivateAccountList
				ShowAboutModal={showActiveAccountListModal}
				onClick={handleSelectAccountToActivate}
				accountsToActivate={accountsToActivate as UserAccount[]}
				SetShowAboutModal={() => setShowActiveAccountListModal(false)}
				isSingleAccount={false}
			/>

			<div className="flex h-full w-full flex-col">
				<div className="flex h-full w-full flex-col items-center justify-start">
					{transferDetailArray ? (
						<>
							<div className="relative flex h-full w-full flex-col">
								<div className="absolute left-0 top-0 flex h-full max-h-full w-full flex-col overflow-y-auto">
									<div className="flex w-full flex-col items-center justify-center space-y-4 pt-8">
										<div>
											{transferDetailArray.some((el) => el.status === TransactionStatus.PROCESSING) && (
												<div className="flex h-10 w-10 items-center justify-center rounded-full bg-blue-quin">
													<TailSpin color="#4756D1" height={20} width={20} />
												</div>
											)}
											{transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
												!transferDetailArray.some((el) => el.status === TransactionStatus.PROCESSING) && <OrangeCheck />}
											{!transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
												!transferDetailArray.some((el) => el.status === TransactionStatus.PROCESSING) && <GreenCheck />}
										</div>
										<div>
											<span className="text-center text-xl font-normal">
												{transferDetailArray.some((el) => el.status === TransactionStatus.PROCESSING) && (
													<>
														Your bulk payment of <CurrencyCode currency={currency} />
														{formatNumber(
															Number(
																transferDetailArray.reduce((acc, cur) => {
																	if (cur.status === TransactionStatus.PROCESSING) {
																		return acc + useMoneyToNumber(cur.amount);
																	}
																	return acc;
																}, 0)
															)
														)}{" "}
														<span> is being processed</span>
													</>
												)}
												{transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
													!transferDetailArray.some((el) => el.status === TransactionStatus.PROCESSING) && (
														<>
															Your bulk payment of <CurrencyCode currency={currency} />
															{formatNumber(
																Number(
																	transferDetailArray.reduce((acc, cur) => {
																		if (cur.status === TransactionStatus.PENDING_APPROVAL) {
																			return acc + useMoneyToNumber(cur.amount);
																		}
																		return acc;
																	}, 0)
																)
															)}{" "}
															<span> has been sent for approval</span>
														</>
													)}
												{!transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
													!transferDetailArray.some((el) => el.status === TransactionStatus.PROCESSING) && (
														<>
															Your bulk payment of <CurrencyCode currency={currency} />
															{formatNumber(
																Number(
																	transferDetailArray.reduce((acc, cur) => {
																		if (cur.status === TransactionStatus.SUCCESS) {
																			return acc + useMoneyToNumber(cur.amount);
																		}
																		return acc;
																	}, 0)
																)
															)}
															<span> has been processed</span>
														</>
													)}{" "}
											</span>
										</div>
									</div>

									<div className="h-full w-full pb-8">
										<div className="flex h-full w-full flex-col">
											<div className="mb-4 mt-4 flex flex-col items-center justify-center space-x-1 text-black-secondary sm:flex-row">
												<span>
													{transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
														"The money will be sent once approved"}
													{!transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
														!transferDetailArray.every((el) => el.status === TransactionStatus.SUCCESS) &&
														noOfProcessing > 0 && (
															<>
																{noOfProcessing} payment{noOfProcessing > 1 ? "s " : " "} processing
																{(noOfPendingApproval > 0 || noOfSuccessful > 0 || noOfFailed > 0) && ","}{" "}
															</>
														)}
													{!transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
														!transferDetailArray.every((el) => el.status === TransactionStatus.SUCCESS) &&
														noOfPendingApproval > 0 && (
															<>
																{noOfPendingApproval} payment{noOfPendingApproval > 1 ? "s are" : " is"} pending
																approval
																{(noOfSuccessful > 0 || noOfFailed > 0) && ","}{" "}
															</>
														)}
													{!transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
														!transferDetailArray.every((el) => el.status === TransactionStatus.SUCCESS) &&
														noOfSuccessful > 0 && (
															<>
																{noOfSuccessful} payment{noOfSuccessful > 1 ? "s were" : " was"} successful
																{noOfFailed > 0 && ","}{" "}
															</>
														)}
													{!transferDetailArray.every((el) => el.status === TransactionStatus.PENDING_APPROVAL) &&
														!transferDetailArray.every((el) => el.status === TransactionStatus.SUCCESS) &&
														noOfFailed > 0 && (
															<>
																{noOfFailed} payment{noOfFailed > 1 ? "s" : ""} failed{" "}
															</>
														)}
												</span>{" "}
											</div>

											{accountsToActivate && accountsToActivate.length > 0 && (
												<div className="flex items-center justify-center">
													<div className="cursor-pointer" onClick={() => setShowActiveAccountListModal(true)}>
														<MessageToasts
															toastMessage={
																(
																	<div className="flex items-center justify-between">
																		{" "}
																		<span>Active account for payout</span>{" "}
																		<div className="ml-4">
																			<ChevronRightIcon className="stroke-current text-info" />
																		</div>
																	</div>
																) as unknown as Element
															}
															toastType={ToastType.INFORMATION}
														/>
													</div>
												</div>
											)}
											<div className="relative flex h-full w-full flex-1 flex-shrink flex-grow">
												<div className="relative mt-6 flex h-full w-full flex-1">
													<div className="flex h-fit w-full flex-1 overflow-x-auto">
														<div className="h-full w-full">
															{isFetchTransactionLoading && (
																<div className="absolute left-0 top-10 z-50 w-full">
																	<BarLoader color="#5466F9" height="3" />
																</div>
															)}
															<table className="tableT w-full">
																<thead className="tableheader relative z-10 rounded-bl-2xl border-grey-backdrop text-left text-sm text-black">
																	<tr className="relative">
																		<th className="tableTh1 px-6 py-3 font-normal">FROM</th>
																		<th className="px-6 py-3 font-normal">TO</th>
																		<th className="px-6 py-3 font-normal">PURPOSE</th>
																		<th className="px-6 py-3 font-normal">AMOUNT</th>
																		<th className="tableTh2 px-6 py-3 font-normal"></th>
																	</tr>
																</thead>
																<tbody className="w-full">
																	{transferDetailArray &&
																		transferDetailArray.map((el, index) => {
																			return (
																				<BulkTransferDetailRow
																					onClick={(e) => handleShowTransactionDetail(e)}
																					data={el}
																					// activateAccount={accountsToActivate.some(
																					// (it) => it === el.origination?.userAccount?.id
																					// )}
																					activateAccount={false}
																					reviewMode={false}
																					processingMode
																					key={index}
																				/>
																			);
																		})}
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="flex w-full flex-row items-center justify-center space-x-4 py-6">
								<ButtonComp
									type="button"
									ripple="light"
									buttonType="secondary"
									color="grey"
									func={() => {
										dispatch(resetAllSendMoneyData());
										dispatch(resetAllBulkTransferData());
										dispatch(setPaymentStage(PaymentStageType.INITIAL));
									}}
								>
									<span>Make another payment</span>
								</ButtonComp>

								<Link to={Routes.DASHBOARD}>
									<ButtonComp
										type="submit"
										color="blue"
										ripple="light"
										buttonType="primary"
										func={() => {
											dispatch(resetAllSendMoneyData());
											dispatch(resetAllBulkTransferData());
										}}
									>
										<span>Done</span>
									</ButtonComp>
								</Link>
							</div>
						</>
					) : (
						<div className="flex h-full w-full items-center justify-center">
							<TailSpin color="#5466F9" height={25} width={25} />
						</div>
					)}
				</div>
			</div>
		</>
	);
}

export default ProcessingPayment;
