import {
	TransferBetweenDetailInterface,
	TransferBetweenResponse,
} from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice.types";
import {useCallback, useState} from "react";

import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {setSingleTransaction} from "../../../../../../../redux/transaction/slice/transactionSlice";
import {setTransferBetweenResponse} from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import {transferBetweenAccountInit} from "../../../../Services/SendMoney/transferBetweenAccountApi";
import {useDispatch} from "react-redux";

interface UseTransferBetweenInterface {
	isTransferBetweenLoading: boolean;
	transferBetweenAccountResponse: TransferBetweenResponse | null;
	transferBetweenError: boolean;
	handleTransferBetween: (_data: TransferBetweenDetailInterface) => Promise<void>;
}

function useTransferBetween(): UseTransferBetweenInterface {
	const dispatch = useDispatch();
	const [isTransferBetweenLoading, setIsTransferBetweenLoading] = useState(false);
	const [transferBetweenAccountResponse, setTransferBetweenAccountResponse] = useState<TransferBetweenResponse | null>(null);
	const [transferBetweenError, setTransferBetweenError] = useState<boolean>(false);

	const handleTransferBetween = useCallback(
		async (_data) => {
			try {
				setIsTransferBetweenLoading(true);
				setTransferBetweenError(false);
				const res = await transferBetweenAccountInit(_data);
				setTransferBetweenAccountResponse({
					transactionId: res.transaction.id,
					isApproved: res.isApproved,
					processing: res.processing,
					accountsToActivate: res.accountsToActivate,
				});
				dispatch(
					setTransferBetweenResponse({
						transactionId: res.transaction.id,
						isApproved: res.isApproved,
						processing: res.processing,
						accountsToActivate: res.accountsToActivate,
					})
				);
				dispatch(setSingleTransaction(res.transaction));
			} catch (err) {
				const errorMessage = getErrorMessage(err);
				dispatch(errorTrue({message: errorMessage}));
				setTransferBetweenError(true);
			} finally {
				setIsTransferBetweenLoading(false);
			}
		},
		[dispatch]
	);

	return {
		isTransferBetweenLoading,
		transferBetweenAccountResponse,
		transferBetweenError,
		handleTransferBetween,
	};
}

export default useTransferBetween;
