import CustomerAccount from "../../../../../../../models/customerAccount";
import {IRootState} from "../../../../../../../redux/rootReducer";
import React from "react";
import Transaction from "../../../../../../../models/transaction";
import TransactionDetailCardCategories from "./TransactionDetailCardCategories";
import TransactionDetailCardUserGroups from "./TransactionDetailCardUserGroups";
import TransactionDetailCommentSection from "./TransactionDetailCommentSection";
import {useSelector} from "react-redux";

interface Props {
	dataType: string;
	transaction: Transaction;
	showTransactionDetailCard: boolean;
}

function TransactionDetailCardExpenseManagement({dataType, transaction, showTransactionDetailCard}: Props): JSX.Element {
	const recipientCustomerAccount = useSelector<IRootState, CustomerAccount | undefined>((state) =>
		state.customerAccount.customerAccounts.get(transaction.destination?._customerAccountId || "")
	);
	return (
		<>
			{((transaction && transaction.isDebit() && transaction.isSuccess && !!recipientCustomerAccount) ||
				transaction.isSuccess ||
				transaction.isPendingApproval ||
				transaction.isProcessing) && (
				<div className="flex w-full flex-col items-start justify-start space-y-4 border-t-0.2 border-solid py-6" data-type={dataType}>
					<span className="font-medium text-black-secondary" data-type={dataType}>
						Expense Management
					</span>
					<TransactionDetailCardCategories
						dataType={dataType}
						transaction={transaction}
						showTransactionDetailCard={showTransactionDetailCard}
					/>

					<TransactionDetailCardUserGroups
						dataType={dataType}
						transaction={transaction}
						showTransactionDetailCard={showTransactionDetailCard}
					/>

					<TransactionDetailCommentSection
						dataType={dataType}
						transaction={transaction}
						showTransactionDetailCard={showTransactionDetailCard}
					/>
				</div>
			)}
		</>
	);
}

export default TransactionDetailCardExpenseManagement;
