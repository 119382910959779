import React from "react";

interface HamburgerProps {
	func(e: React.MouseEvent): void;
	active: boolean;
}

function HamburgerClose({active, func}: HamburgerProps): JSX.Element {
	return (
		<div
			className={
				`relative transform cursor-pointer transition-all lg:hidden ` +
				`${active ? "opacity-1 pointer-events-auto" : "pointer-events-none hidden  opacity-0"} `
			}
			onClick={func}
			tabIndex={active ? 0 : -1}
		>
			<div className="relative h-6 w-6">
				<div className="absolute top-50% h-0.5 w-6 rotate-45 transform rounded-full bg-white" />
				<div className="absolute top-50% h-0.5 w-6 -rotate-45 transform rounded-full bg-white" />
			</div>
		</div>
	);
}

export default HamburgerClose;
