import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import {immerable} from "immer";

export default class UserApp {
	[immerable] = true;

	constructor(public isSetup: boolean, public device: string | null) {}

	static create(obj: GenericObject): UserApp {
		return new UserApp(Parsers.boolean(obj.isSetup), Parsers.nullableString(obj.device));
	}

	static createDefault(): UserApp {
		return new UserApp(false, null);
	}
}
