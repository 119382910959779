import * as Yup from "yup";

import {Form, Formik} from "formik";
import {ONE, PageTitle, TWO, ToastType} from "../../../../helpers/AppConstants";

import ButtonComp from "../../../../components/General/Buttons/ButtonComp";
// import Clipboard from "../../../../components/General/Clipboard/Clipboard";
import FormInput from "../../../../components/General/Inputs/FormInput";
import Layout from "../../../../components/Layout";
import {Link} from "react-router-dom";
import MessageToasts from "../../../../components/General/MessageToasts/MessageToasts";
import Otp from "../Components/Element/Otp";
import React from "react";
import {TailSpin} from "react-loader-spinner";
import YupPassword from "yup-password";
import useLoginState from "../Hooks/State/useLoginState";

YupPassword(Yup);

const INITIAL_FORM_STATE = {
	username: "",
	password: "",
};
const FORM_VALIDATION = Yup.object().shape({
	username: Yup.string().required(),
	password: Yup.string().required(),
});

function AccountLogIn(): JSX.Element {
	const {
		url,
		invite,
		section,
		loginOtp,
		formikRef,
		loginError,
		isOtpLoading,
		isLoginLoading,
		isAuthAppSetup,
		verifyLoginError,
		verifyInviteLoginResponse,
		isVerifyInviteLoginLoading,

		handleChange,
		handleResend,
		handleOtpBack,
		handleSubmitLoginDetails,
	} = useLoginState();

	document.title = PageTitle.LOGIN_PAGE;

	return (
		<Layout inviteURL={url} showHeaderSignUp={true} showNothing={section === TWO || !!(verifyLoginError || isVerifyInviteLoginLoading)}>
			<div className="w-full flex justify-center items-center flex-col">
				{invite && (!(verifyInviteLoginResponse || verifyLoginError) || isVerifyInviteLoginLoading) && (
					<>
						<div className="absolute left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-white">
							<TailSpin color="#5466F9" height={20} width={20} />
						</div>
					</>
				)}
				{!verifyLoginError && (
					<div className="relative flex w-full max-w-sm flex-col items-center justify-center rounded-lg px-4 3xs:p-12 3xs:shadow">
						<div className="relative flex w-full flex-col items-center justify-center">
							{loginError && (
								<div className="max-w-xs pb-4">
									<MessageToasts toastMessage={loginError || ""} toastType={ToastType.ERROR} />
								</div>
							)}
							{section === ONE && (
								<div className="flex w-full flex-col">
									<div className="flex w-full flex-col ">
										<div className="text text-center text-2xl">
											{invite && (
												<div className="flex w-full flex-col items-center justify-center space-y-4">
													<span className="text-black">
														You have been invited to join{" "}
														<span className="font-bold">
															{verifyInviteLoginResponse && verifyInviteLoginResponse.businessName}{" "}
														</span>
														on Lenco
													</span>
												</div>
											)}
											{!invite && <span className="text-secondary font-bold">Login to your account</span>}
										</div>
									</div>

									<Formik
										initialValues={{
											...INITIAL_FORM_STATE,
										}}
										innerRef={formikRef}
										validationSchema={FORM_VALIDATION}
										onSubmit={(values) => {
											handleSubmitLoginDetails(values);
										}}
										enableReinitialize
										validateOnMount
									>
										{(formik) => {
											return (
												<Form className="w-full" tabIndex={-1}>
													<div className="flex flex-col pb-4 pt-8 text-base font-normal ">
														<FormInput type="text" name="username" placeholder="Username or Email Address" />
													</div>
													<FormInput placeholder="Password" name="password" type="password" />
													<div className="flex w-full flex-col justify-center pt-4">
														<ButtonComp
															type="submit"
															color="blue"
															ripple="light"
															buttonType="primary"
															fullWidth
															disable={!!(formik.errors.username || formik.errors.password)}
															isLoading={isLoginLoading}
														>
															<span>Log In</span>
														</ButtonComp>

														<div className="flex flex-col items-center justify-self-center pt-6  text-sm  font-normal text-blue">
															<Link to="/forgot-password" className="hover:text-blue ">
																Forgot password
															</Link>
														</div>
													</div>
												</Form>
											);
										}}
									</Formik>
								</div>
							)}

							{section === TWO && (
								<div className="w-full">
									<Otp
										otpFunc={handleChange}
										value={loginOtp}
										error={loginError}
										backFunc={handleOtpBack}
										resendFunc={handleResend}
										isLoading={isOtpLoading}
										isAuthAppSetup={isAuthAppSetup}
									/>
								</div>
							)}
						</div>
					</div>
				)}
				{verifyLoginError && (
					<div className="flex items-center justify-center pt-8">
						<MessageToasts toastMessage={verifyLoginError || ""} toastType={ToastType.ERROR} />
					</div>
				)}
			</div>
		</Layout>
	);
}
export default AccountLogIn;
