import {CANNOT_SEND_MONEY, SEND_MONEY_TO_SPECIFIC_ACCOUNTS, ToastType} from "../../../../../../helpers/AppConstants";
import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CardDropdown from "../../DropDowns/CardDropdown";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import FundCardHeaders from "./FundCardHeaders";
import {IRootState} from "../../../../../../redux/rootReducer";
import MessageToasts from "../../../../../../components/General/MessageToasts/MessageToasts";
import MoneyInput from "../../../../../../components/General/Inputs/MoneyInput";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import TextArea from "../../../../../../components/General/TextArea/TextArea";
import UserAccount from "../../../../../../models/userAccount";
import UserAccountDropdown from "../../DropDowns/UserAccountDropdown";
import commaSeparator from "../../../../../../utils/commaSeparator";
import {resetAllFundCardData} from "../../../../../../redux/payments/fundCard/slice/fundCardSlice";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import useFundCardAmount from "../../../Hooks/State/SendMoney/FundCard/useFundCardAmount";
import useFundCardPayFrom from "../../../Hooks/State/SendMoney/FundCard/useFundCardPayFrom";
import useFundCardPurpose from "../../../Hooks/State/SendMoney/FundCard/useFundCardPurpose";
import useFundCardRecipient from "../../../Hooks/State/SendMoney/FundCard/useFundCardRecipient";
import {useHistory} from "react-router";
import {useMoneyToNumber} from "../../../../../../hooks/useMoneyToNumber";

function PaymentDetails(): JSX.Element {
	const history = useHistory();
	const dispatch = useDispatch();
	const main = useSelector((state: IRootState) => state.init.main);
	const selectedUserAccount = useSelector((state: IRootState) => state.fundCard.selectedUserAccount);
	const fundCardDetails = useSelector((state: IRootState) => state.fundCard.fundCardDetails);
	const recipientCardDetails = useSelector((state: IRootState) => state.fundCard.recipientCardDetails);
	const pageFrom = useSelector((state: IRootState) => state.sendMoney.pageFrom);
	const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);

	// const recipientCardName = useSelector((state: IRootState) => state.fundCard.recipientCardName);
	const {handleAmountChange} = useFundCardAmount();
	const {handlePurposeChange} = useFundCardPurpose();

	const {selectedAccountId, handleSelectAccount, accounts} = useFundCardPayFrom();

	const {suggestedCards, handleSelectCard} = useFundCardRecipient();

	const handleBack = useCallback(() => {
		if (pageFrom && payTo) {
			history.push({
				pathname: pageFrom,
				state: {
					cardId: payTo,
				},
			});
		}
		dispatch(setPaymentStage(PaymentStageType.INITIAL));
		dispatch(resetAllFundCardData());
	}, [pageFrom, payTo]);

	return (
		<>
			<div className=" flex w-full flex-col items-center justify-center lg:items-start lg:justify-start ">
				<div className="w-full max-w-sm ">
					<FundCardHeaders />
					<div className=" w-full ">
						<div className="flex w-full flex-col justify-between">
							<div className="flex  min-h-72 flex-col items-start space-y-4 pt-2 text-base font-normal  md:items-center md:px-0 lg:items-center lg:px-0">
								<div className="flex w-full flex-col space-y-4 ">
									<div className="w-full max-w-sm ">
										<UserAccountDropdown
											placeholder="Fund card from"
											value={selectedAccountId || ""}
											options={accounts as UserAccount[]}
											onSelect={handleSelectAccount}
											isDisabled={!!(main && main.companyDetails.accounts.length < 2)}
											showBalance
											initiatorCanSelect
										/>
									</div>
									{selectedUserAccount && selectedUserAccount.accountRestrictions.type === SEND_MONEY_TO_SPECIFIC_ACCOUNTS && (
										<div className="flex w-full max-w-sm flex-col items-center justify-start space-y-6">
											<MessageToasts
												toastMessage={"You can only send to specific recipients"}
												toastType={ToastType.INFORMATION}
											/>
										</div>
									)}
									<div className="w-full max-w-sm ">
										<CardDropdown
											data={suggestedCards || []}
											inputValue={recipientCardDetails?.name || ""}
											placeholder={`Select Card`}
											clickAndClose
											onClickFunc={handleSelectCard}
											isSearchable={!!suggestedCards && suggestedCards.length > 4}
											isInputDisabled
											showArrow
										/>
									</div>
									<div className="flex w-full max-w-sm flex-col space-y-2 ">
										<MoneyInput
											placeholder="Amount"
											icon={<CurrencyCode />}
											value={fundCardDetails.amount || ""}
											onChange={handleAmountChange}
										/>
									</div>
									{selectedUserAccount && selectedUserAccount.accountRestrictions.type === CANNOT_SEND_MONEY && (
										<div className=" w-full max-w-sm">
											<MessageToasts toastMessage={"You can not send money from this account"} toastType={ToastType.ERROR} />
										</div>
									)}
									<div className="flex w-full max-w-sm flex-col items-start justify-start space-y-4">
										<div className="w-full">
											<TextArea
												placeholder="Purpose of payment (Optional)"
												value={fundCardDetails.purpose}
												onChangeFunc={handlePurposeChange}
											/>
										</div>
									</div>
								</div>
							</div>

							<div className="flex w-full max-w-sm flex-row items-center justify-center space-x-4 pt-4">
								{fundCardDetails.amount &&
									selectedUserAccount &&
									useMoneyToNumber(fundCardDetails.amount) > (selectedUserAccount.balance as number) && (
										<>
											<MessageToasts
												toastMessage={
													(
														<div>
															{" "}
															You do not have enough funds. Your account balance is <CurrencyCode />
															{commaSeparator(selectedUserAccount.balance as number)}
														</div>
													) as unknown as string | Element
												}
												toastType={ToastType.ERROR}
											/>
										</>
									)}
							</div>

							<div className="flex w-full max-w-sm flex-row items-center justify-center space-x-4 pb-8 pt-8">
								<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={handleBack}>
									<span>Back</span>
								</ButtonComp>

								<ButtonComp
									type="submit"
									color="blue"
									ripple="light"
									buttonType="primary"
									disable={
										!selectedAccountId ||
										!recipientCardDetails?.name ||
										// !fundCardDetails.purpose ||
										!useMoneyToNumber(fundCardDetails.amount) ||
										useMoneyToNumber(fundCardDetails.amount) > (selectedUserAccount?.balance as number)
											? true
											: false
									}
									func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
								>
									<span>Next</span>
								</ButtonComp>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PaymentDetails;
