import {ReactComponent as BlueCheck} from "../../../../../../assets/svg/General/Lenco/Mark/success-mark.svg";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {IRootState} from "../../../../../../redux/rootReducer";
import React from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";

interface Props {
	url: string;
}

function DirectorApplicationDone(props: Props): JSX.Element {
	const history = useHistory();
	const accountOpening = useSelector((state: IRootState) => state.application.init);
	const accountManager = useSelector((state: IRootState) => state.application.accountManager);
	const zambiaAccountManager = useSelector((state: IRootState) => state.zambiaApplication.accountManager);
	const zambiaAccountOpening = useSelector((state: IRootState) => state.zambiaApplication.init);

	return (
		<>
			<div className="max-w-7xl mx-auto px-4 2xs:px-10 lg:px-16 pb-20 w-full">
				<div className="pt-3 text-sm font-normal">
					<p className="font-medium text-lg capitalize text-black mb-2">
						{accountOpening?.applicationMin.name || zambiaAccountOpening?.application.name}
					</p>
					<p className="text-sm xl:max-w-lg w-full text-black-secondary pb-8">Reviewing your application.</p>
				</div>

				<div className="flex flex-col justify-start items-start space-y-6 w-full">
					<div className="flex flex-col justify-center items-center space-y-8 border border-grey rounded-lg p-8 w-full">
						<div className="flex flex-col justify-center items-center space-y-4 w-full">
							<BlueCheck />
							<h1 className="text-black text-2xl font-medium">All done!</h1>
							<div className="text-sm text-center text-black-secondary">
								<p>
									Your application has been submitted successfully and is being reviewed by{" "}
									<span className="text-blue">{accountManager?.name || zambiaAccountManager?.name || ""}.</span>
								</p>
								<p>
									{zambiaAccountOpening &&
										"We estimate that you will hear back from us within 1 business day. If you have more information to provide please add them to your application."}
									{accountOpening &&
										"We estimate that we will reach out to you within the next 1-2 business days. Have a great day"}
								</p>
							</div>
						</div>
						<ButtonComp type="submit" color="blue" ripple="light" buttonType="primary" func={() => history.push(`${props.url}`)}>
							<span>Return to Application</span>
						</ButtonComp>
					</div>
				</div>
			</div>
		</>
	);
}

export default DirectorApplicationDone;
