import {BusinessFormsPageTypes} from "../Services/business-forms.constants";
import ExternalFormLayout from "../../Components/Layout";
import React from "react";
import ToastContainer from "../../../../../components/General/ToastContainer";
import {useParams} from "react-router";

interface BusinessFormsUrlParams {
	a: string;
	b: string;
	document: BusinessFormsPageTypes;
}

function ExternalBusinessForms(): JSX.Element {
	// const history = useHistory();
	// const dispatch = useDispatch();

	const {a, b, document} = useParams<BusinessFormsUrlParams>();

	// const {documentDetailsLoading, handleDocumentDetails} = useBusinessDocuments();

	// const {isGetAgreementDetailsLoading, handleGetAgreementDetails} = useAgreement();
	// const agreementData = useSelector((state: IRootState) => state.application.agreementData);
	// const accountOpening = useSelector((state: IRootState) => state.application.init);
	// const businessDocuments = useSelector((state: IRootState) => state.application.businessDocumentsData);

	// useLayoutEffect(() => {
	// if (Object.values(AgreementType).some((val) => val === document)) {
	// handleGetAgreementDetails();
	// } else if (Object.values(CompanyDocumentTypes).some((val) => val === document)) {
	// handleDocumentDetails();
	// } else {
	// history.push(Routes.DASHBOARD);
	// }
	// }, [document, handleDocumentDetails, handleGetAgreementDetails]);

	// // useLayoutEffect(() => {
	// // handleInitializeAccountOpening();
	// // }, [handleInitializeAccountOpening]);

	// useEffect(() => {
	// return () => {
	// dispatch(resetAgreementData());
	// dispatch(resetBusinessDocumentsData());
	// };
	// }, []);

	return (
		<>
			<ExternalFormLayout url={"/share/" + `${document.toString()}` + "/share/" + a + "/" + b}>
				<ToastContainer />
				{/*
				<>
					{(documentDetailsLoading || isGetAgreementDetailsLoading || !accountOpening) && (
						<div className="flex justify-center items-center h-full w-full px-4">
							<TailSpin color="#5466F9" height={30} width={30} />
						</div>
					)}
					{!(documentDetailsLoading || isGetAgreementDetailsLoading) && accountOpening && (
						<div className="flex flex-row justify-start items-start space-x-6 w-full p-4 2xs:p-10 lg:p-16 pb-28">
							<div className="flex flex-col justify-start items-start w-full relative">
								{document === AgreementType.ACCOUNT_MANDATE_CARD && <AccountMandateComponent data={agreementData} />}
								{document === AgreementType.SEARCH_REPORT && <SearchReportComponent data={agreementData} />}
								{document === AgreementType.ACCOUNT_OPENING_DECLARATION && (
									<AccountOpeningDeclarationComponent data={agreementData} />
								)}
								{document === AgreementType.TERMS_AND_CONDITIONS && <TermsAndConditionsComponent data={agreementData} />}
								{document === AgreementType.BOARD_RESOLUTION && agreementData?.canHaveBoardResolution && (
									<BoardResolutionComponent data={agreementData} />
								)}

								{document === CompanyDocumentTypes.FORM_C02 && <FormC02 data={businessDocuments} />}
								{document === CompanyDocumentTypes.FORM_C07 && <FormC07 data={businessDocuments} />}
								{document === CompanyDocumentTypes.CERTIFICATE && <IncorporationCertificate data={businessDocuments} />}
								{document === CompanyDocumentTypes.UTILITY_BILL && <UtilityBill data={businessDocuments} />}
								{document === CompanyDocumentTypes.SCUML_CERTIFICATE && <ScumlCertificate data={businessDocuments} />}
								{document === CompanyDocumentTypes.INCORPORATION_DOCUMENT && <IncorporationDocument data={businessDocuments} />}
								{document === CompanyDocumentTypes.MEMORANDUM_OF_ASSOCIATION && <MemorandumOfAssociation data={businessDocuments} />}
							</div>
						</div>
					)}
				</> */}
			</ExternalFormLayout>
		</>
	);
}

export default ExternalBusinessForms;
