import {useCallback, useState} from "react";

import {ExternalInitRequest} from "../service/externalApplication.types";
import {errorTrue} from "../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../utils/getErrorMessage";
import {messageTrue} from "../../../../../redux/message/slice/messageSlice";
import {openBlobInNewTab} from "../../../../../utils/blob";
import {previewExternalDirectorId} from "../service/externalApplication.api";
import {useDispatch} from "react-redux";

interface UseExternalDirectorPreviewInterface {
	previewBlob: Blob | null;
	isPreviewDownloading: boolean;
	handleReset: () => void;
	handlePreviewDocument: (_data: ExternalInitRequest) => void;
}

interface Props {
	onComplete?: () => void;
	downloadedDocumentName?: string;
}

const useExternalDirectorPreview = (props: Props): UseExternalDirectorPreviewInterface => {
	const dispatch = useDispatch();
	const [previewBlob, setPreviewBlob] = useState<Blob | null>(null);
	const [isPreviewDownloading, setIsPreviewDownloading] = useState<boolean>(false);

	const handlePreviewDocument = useCallback(
		async (_externalParams: ExternalInitRequest) => {
			try {
				setIsPreviewDownloading(true);
				dispatch(messageTrue({message: "Generating preview"}));
				const blob = await previewExternalDirectorId(_externalParams);
				dispatch(messageTrue({message: "Preview generated"}));
				setPreviewBlob(blob);
				if (blob.type === "image/png" || blob.type === "image/jpeg" || blob.type === "image/jpg") {
					props.onComplete && props.onComplete();
					return;
				}
				if (blob.type === "application/pdf") {
					openBlobInNewTab(blob, props.downloadedDocumentName || "Document");
					return;
				}
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsPreviewDownloading(false);
			}
		},
		[dispatch]
	);

	const handleReset = useCallback(() => {
		setPreviewBlob(null);
		setIsPreviewDownloading(false);
	}, []);

	return {
		previewBlob,
		isPreviewDownloading,
		handleReset,
		handlePreviewDocument,
	};
};
export default useExternalDirectorPreview;
