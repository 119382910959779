import React from "react";
import {ToastType} from "../../../../../helpers/AppConstants";

interface Props {
	text: string;
	type: ToastType;
}

function ApplicationStatusTag(props: Props): JSX.Element {
	return (
		<>
			<div
				className={
					`py-2 px-4 rounded-lg w-max text-sm capitalize ` +
					`${props.type === ToastType.ERROR ? "bg-error-backdrop text-error" : ""} ` +
					`${props.type === ToastType.INFORMATION ? "bg-info-backdrop text-info" : ""} ` +
					`${props.type === ToastType.SUCCESS_TOAST ? "bg-success-backdrop text-success" : ""} ` +
					`${props.type === ToastType.WARNING ? "bg-warning-backdrop text-warning" : ""} `
				}
			>
				{props.text}
			</div>
		</>
	);
}

export default ApplicationStatusTag;
