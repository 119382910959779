import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRootState} from "../../../../../redux/rootReducer";
import MakePayment from "./SingleTransfer/MakePayment";
import PaymentDetails from "./SingleTransfer/PaymentDetails";
import {PaymentStageType} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import ProcessingPayment from "./SingleTransfer/ProcessingPayment";
import ReviewPayment from "./SingleTransfer/ReviewPayment";
import {resetAllSingleTransferData} from "../../../../../redux/payments/singleTransfer/slice/singleTransferSlice";

function SingleTransfer(): JSX.Element {
	const dispatch = useDispatch();
	const paymentStage = useSelector((state: IRootState) => state.sendMoney.paymentStage);

	useEffect(() => {
		return () => {
			dispatch(resetAllSingleTransferData());
		};
	}, []);

	return (
		<>
			{paymentStage === PaymentStageType.PAYMENT_DETAILS && <PaymentDetails />}
			{paymentStage === PaymentStageType.REVIEW_PAYMENT && <ReviewPayment />}
			{paymentStage === PaymentStageType.MAKE_PAYMENT && <MakePayment />}
			{paymentStage === PaymentStageType.PROCESS_PAYMENT && <ProcessingPayment />}
		</>
	);
}

export default SingleTransfer;
