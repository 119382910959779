import {setTransferBetweenPurpose} from "../../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import {useCallback} from "react";
import {useDispatch} from "react-redux";

interface useTransferBetweenPurposeInterface {
	handlePurposeChange: (_purpose: string) => void;
}

function useTransferBetweenPurpose(): useTransferBetweenPurposeInterface {
	const dispatch = useDispatch();

	const handlePurposeChange = useCallback((_purpose: string) => {
		dispatch(setTransferBetweenPurpose(_purpose));
	}, []);

	return {
		handlePurposeChange,
	};
}

export default useTransferBetweenPurpose;
