import {Dispatch, SetStateAction, useCallback, useState} from "react";
import {downloadAccountingReceipt, uploadAccountingReceipt} from "../Services/transactionDetailApi";
import {downloadBlob, guessExtension} from "../../../../../../../utils/blob";

import Transaction from "../../../../../../../models/transaction";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import {messageTrue} from "../../../../../../../redux/message/slice/messageSlice";
import {setSingleTransaction} from "../../../../../../../redux/transaction/slice/transactionSlice";
import {useDispatch} from "react-redux";

export interface UseTransactionDetailsAccountingReceiptResponse {
	isBigger: boolean;
	isUploading: boolean;
	handleUploadAccountingReceipt: (transaction: Transaction, file: File) => Promise<void>;

	isDownloading: boolean;
	handlePreviewAccountingReceipt: (transaction: Transaction) => Promise<void>;
	handleDownloadAccountingReceipt: (transaction: Transaction) => Promise<void>;

	showUploadModal: boolean;
	setShowUploadModal: Dispatch<SetStateAction<boolean>>;

	isPreviewDownloading: boolean;
	isPreviewModalOpen: boolean;
	handleOpenPreviewModal: () => void;
	handleClosePreviewModal: () => void;

	receiptBlob: Blob | null;
}

function useTransactionDetailsAccountingReceipt(): UseTransactionDetailsAccountingReceiptResponse {
	const dispatch = useDispatch();

	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [isPreviewModalOpen, setIsPreviewModalOpen] = useState<boolean>(false);
	const [receiptBlob, setReceiptBlob] = useState<Blob | null>(null);
	const [isBigger, setIsBigger] = useState<boolean>(false);
	const [isDownloading, setIsDownloading] = useState<boolean>(false);
	const [isPreviewDownloading, setIsPreviewDownloading] = useState<boolean>(false);
	const [showUploadModal, setShowUploadModal] = useState<boolean>(false);

	const handleUploadAccountingReceipt = useCallback(
		async (transaction: Transaction, file: File) => {
			if (file.size > 5242880) {
				return setIsBigger(true);
			}
			try {
				setIsBigger(false);
				setIsUploading(true);
				const updatedTransaction = await uploadAccountingReceipt(transaction.id, file);
				dispatch(setSingleTransaction(updatedTransaction));
				dispatch(messageTrue({message: "Receipt Uploaded Successfully"}));
				setShowUploadModal(false);
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsUploading(false);
			}
		},
		[dispatch]
	);

	const handleDownloadAccountingReceipt = useCallback(
		async (transaction: Transaction) => {
			if (receiptBlob) {
				downloadBlob(receiptBlob, `receipt.${guessExtension(receiptBlob.type) || "pdf"}`);
				return;
			}
			try {
				setIsDownloading(true);
				const blob = await downloadAccountingReceipt(transaction.id);
				setReceiptBlob(blob);
				downloadBlob(blob, `receipt.${guessExtension(blob.type) || "pdf"}`);
				dispatch(messageTrue({message: "Receipt Downloaded Successfully"}));
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsDownloading(false);
			}
		},
		[dispatch, receiptBlob]
	);

	const handlePreviewAccountingReceipt = useCallback(
		async (transaction: Transaction) => {
			try {
				setIsPreviewDownloading(true);
				dispatch(messageTrue({message: "Generating preview"}));
				const blob = await downloadAccountingReceipt(transaction.id);
				setReceiptBlob(blob);
				setIsPreviewModalOpen(true);
				dispatch(messageTrue({message: "Preview generated"}));
			} catch (err) {
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsPreviewDownloading(false);
			}
		},
		[dispatch]
	);

	const handleOpenPreviewModal = useCallback(() => {
		setIsPreviewModalOpen(true);
	}, []);

	const handleClosePreviewModal = useCallback(() => {
		setIsPreviewModalOpen(false);
	}, []);

	return {
		isBigger,
		isUploading,
		handleUploadAccountingReceipt,

		isDownloading,
		handlePreviewAccountingReceipt,
		handleDownloadAccountingReceipt,

		showUploadModal,
		setShowUploadModal,

		isPreviewDownloading,
		isPreviewModalOpen,
		handleOpenPreviewModal,
		handleClosePreviewModal,

		receiptBlob,
	};
}

export default useTransactionDetailsAccountingReceipt;
