import BankAccount from "../../../../models/bankAccount";
import Card from "../../../../models/card";
import UserAccount from "../../../../models/userAccount";
/*
export interface TransactionInitState {
	updatedTransactionUserGroup: Transaction | null;
	updatedUserAccount: UserAccount | null;
	updatedTransaction: Transaction | null;
	showTransactionDetailCard: boolean;
	transactionDetail: Transaction | null;
}
*/
export interface TransferBetweenDetailInterface {
	originatingAccountId: string;
	recipientAccountId: string;
	originatingCardId: string;
	amount: string;
	purpose: string;
	otp: string;
}

export interface TransferBetweenResponse {
	transactionId: string;
	isApproved: boolean;
	processing: boolean;
	accountsToActivate: Array<string>;
}

export enum TransferBetweenInternalStage {
	PAYMENT_DETAILS_STAGE = 0,
	AMOUNT_PURPOSE_STAGE = 1,
}

export interface TransferBetweenState {
	cardBalances: Map<string, number>;
	transferBetweenDetails: TransferBetweenDetailInterface;
	selectedUserAccount: UserAccount | null;
	selectedCard: Card | null;
	canVerifyRecipientAccountDetails: boolean;
	recipientBankAccount: BankAccount | null;
	transferBetweenResponse: TransferBetweenResponse | null;
	transferBetweenInternalStage: TransferBetweenInternalStage;
}
