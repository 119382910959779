import React, {useEffect, useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";

import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import {IRootState} from "../../../../../../redux/rootReducer";
import {ReactComponent as Lock} from "../../../../../../assets/images/yellowlock.svg";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import TransferBetweenAccountPaymentHeaders from "./TransferBetweenAccountPaymentHeaders";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import formatNumber from "../../../../../../utils/formatNumber";
import getBankName from "../../../../../../helpers/getBankName";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import titleCase from "../../../../../../hooks/titleCase";
import {useMoneyToNumber} from "../../../../../../hooks/useMoneyToNumber";
import useTransferBetweenPurpose from "../../../Hooks/State/SendMoney/TransferBetween/useTransferBetween";

function ReviewPayment(): JSX.Element {
	const dispatch = useDispatch();
	const {isTransferBetweenLoading, transferBetweenAccountResponse, handleTransferBetween} = useTransferBetweenPurpose();
	const selectedCard = useSelector((state: IRootState) => state.transferBetween.selectedCard);
	const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
	const selectedUserAccount = useSelector((state: IRootState) => state.transferBetween.selectedUserAccount);
	const recipientBankAccount = useSelector((state: IRootState) => state.transferBetween.recipientBankAccount);
	const transferBetweenDetails = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails);

	const canApprove = useMemo((): boolean => {
		if (selectedCard) return true;
		if (!userAccountsMeta || !selectedUserAccount) return false;
		const doesAccountExist = userAccountsMeta.some((userAccountMeta) => userAccountMeta.userAccountId === selectedUserAccount.id);
		if (!isNullOrUndefined(doesAccountExist)) {
			const userAccountMeta = userAccountsMeta.find((_el) => _el.userAccountId === selectedUserAccount.id) as UserAccountMeta;
			return userAccountMeta.isApprover
				? userAccountMeta.maxApprovalAmount
					? useMoneyToNumber(transferBetweenDetails.amount) <= userAccountMeta.maxApprovalAmount
					: true
				: false;
		}
		return false;
	}, [selectedCard, userAccountsMeta, selectedUserAccount, transferBetweenDetails]);

	useEffect(() => {
		if (canApprove || !transferBetweenAccountResponse) return;
		dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
	}, [canApprove, transferBetweenAccountResponse]);

	return (
		<>
			<div className="flex h-full w-full flex-col pb-20">
				<div className="flex w-full flex-row">
					<div className="mx-auto flex w-full max-w-md flex-col lg:mx-0">
						<div>
							<TransferBetweenAccountPaymentHeaders />
							<div className="w-full">
								<div className="flex flex-col items-center justify-center">
									<span className="text-sm font-normal text-black-tertiary"> You’re sending </span>
									<div className="flex flex-row">
										<div className="text-4xl font-medium">
											<CurrencyCode />
											{formatNumber(transferBetweenDetails && transferBetweenDetails.amount)}
										</div>
									</div>
								</div>
							</div>
							<div className="flex w-full flex-col items-start justify-start space-y-8 pt-6">
								<div className="box-border w-full flex-col space-y-7 rounded-lg bg-blue-backdrop p-5">
									<div className="flex flex-row justify-between">
										<span className="text-sm font-normal text-black-tertiary">From</span>
										<div className="flex flex-col">
											{selectedUserAccount && (
												<>
													<span className="text-sm text-right">
														{titleCase(selectedUserAccount.bankAccount?.accountName || "")}
													</span>
													<span className="text-right text-sm font-normal">
														{selectedUserAccount.bankAccount?.accountNumber}
													</span>
												</>
											)}
											{selectedCard && (
												<>
													<span className="text-sm text-right">{titleCase(selectedCard.name)}</span>
													<span className="text-right text-sm font-normal">{selectedCard.maskedPan}</span>
												</>
											)}
										</div>
									</div>
									<div className="flex flex-row justify-between">
										<span className="text-sm font-normal text-black-tertiary">To</span>
										<div className="flex flex-col">
											<span className="text-right text-sm">{titleCase(recipientBankAccount?.accountName || "")}</span>
											<span className="text-right text-sm font-normal">
												{titleCase(getBankName(recipientBankAccount?.bankCode))}
											</span>
										</div>
									</div>
									<div className="flex flex-row justify-between">
										<span className="text-sm font-normal text-black-tertiary">Purpose of payment</span>
										<span className="text-right text-sm font-normal">
											{transferBetweenDetails && transferBetweenDetails.purpose}
										</span>
									</div>
								</div>
								<div className=" min-w-min pt-4">
									<div className=" box-border flex w-full flex-row items-center justify-center space-x-2 rounded-lg bg-warning-backdrop px-4">
										<div>
											<Lock />
										</div>
										<p className="py-4 text-left text-sm font-normal text-warning">
											{" "}
											Ensure you verify that the recipient is genuine as payments can not be reversed after approval.
										</p>
									</div>
								</div>
							</div>

							<div className="flex w-full flex-row items-center justify-center space-x-4 pb-10 pt-8">
								<div>
									<ButtonComp
										type="button"
										ripple="light"
										buttonType="secondary"
										color="grey"
										func={() => dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS))}
									>
										<span>Back</span>
									</ButtonComp>
								</div>

								<div>
									<ButtonComp
										type="submit"
										color="blue"
										ripple="light"
										buttonType="primary"
										func={() => {
											canApprove
												? dispatch(setPaymentStage(PaymentStageType.MAKE_PAYMENT))
												: void handleTransferBetween(transferBetweenDetails);
										}}
										isLoading={isTransferBetweenLoading}
									>
										{canApprove ? "Make Payment" : "Initiate Payment"}
									</ButtonComp>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ReviewPayment;
