import {
	resetTransferDetailRecipient,
	setSelectedUserAccount,
	setTransferDetailOriginatingAccount,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRootState} from "../../../../../../../redux/rootReducer";
import UserAccount from "../../../../../../../models/userAccount";
import UserAccountMeta from "../../../../../../../models/userAccountMeta";
import doesUserHaveAccessToAccount from "../../../../../../../helpers/doesUserHaveAccessToAccount";

interface useBulkTransferPayFromInterface {
	accounts: UserAccount[] | undefined;
	selectedAccountId: string | null;
	currentUserAccountMeta: UserAccountMeta | null;
	handleSelectAccount: (_accountId: string) => void;
	handleResetUserAccountMeta: () => void;
	handleSelectAccountUpdatingAccount: (_accountId: string) => void;
}

function useBulkTransferPayFrom(): useBulkTransferPayFromInterface {
	const originatingAccountId = useSelector((state: IRootState) => state.bulkTransfer.transferDetail.originatingAccountId);
	const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
	const accounts = useSelector((state: IRootState) => state.init.main?.companyDetails.accounts);
	const isUpdatingTransferDetail = useSelector((state: IRootState) => state.bulkTransfer.isUpdatingTransferDetail);

	// const [maxApprovalAmount, setMaxApprovalAmount] = useState<number | null>(null);
	const [currentUserAccountMeta, setCurrentUserAccountMeta] = useState<UserAccountMeta | null>(null);

	const dispatch = useDispatch();

	useEffect(() => {
		if (!accounts) return;
		if (accounts.length < 2) {
			if (doesUserHaveAccessToAccount(accounts[0].id)) {
				handleSelectAccount(accounts[0].id);
			}
		}
	}, [accounts]);

	const handleSelectAccount = useCallback(
		(_accountId) => {
			const doesAccountExist = accounts?.some((el) => el.id === _accountId);
			if (doesAccountExist) {
				const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
				const userAccountMeta = userAccountsMeta?.find((_it) => _it.userAccountId === userAccount.id);
				setCurrentUserAccountMeta(userAccountMeta || null);
				dispatch(setTransferDetailOriginatingAccount(_accountId));
				dispatch(setSelectedUserAccount(userAccount));
				// setMaxApprovalAmount(userAccountMeta?.maxApprovalAmount || null);
				// dispatch(setCanApprove(userAccountMeta?.isApprover || false));
				if (userAccount.accountRestrictions.canSendMoneyToSpecificAccounts) dispatch(resetTransferDetailRecipient());
			}
		},
		[accounts, userAccountsMeta, isUpdatingTransferDetail]
	);
	const handleSelectAccountUpdatingAccount = useCallback(
		(_accountId) => {
			const doesAccountExist = accounts?.some((el) => el.id === _accountId);
			if (doesAccountExist) {
				const userAccount = accounts?.find((el) => el.id === _accountId) as UserAccount;
				const userAccountMeta = userAccountsMeta?.find((_it) => _it.userAccountId === userAccount.id);
				setCurrentUserAccountMeta(userAccountMeta || null);
				dispatch(setTransferDetailOriginatingAccount(_accountId));
				dispatch(setSelectedUserAccount(userAccount));
			}
		},
		[accounts, userAccountsMeta, isUpdatingTransferDetail]
	);

	const handleResetUserAccountMeta = useCallback(() => {
		setCurrentUserAccountMeta(null);
	}, []);

	return {
		accounts,
		currentUserAccountMeta,
		selectedAccountId: originatingAccountId,
		handleSelectAccount,
		handleResetUserAccountMeta,
		handleSelectAccountUpdatingAccount,
	};
}

export default useBulkTransferPayFrom;
