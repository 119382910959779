import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import {ReactComponent as EyesIcon} from "../../../../../../assets/svg/General/Eyes/eyes.svg";
import {ReactComponent as MarkIcon} from "../../../../../../assets/svg/Hint/mark-active-green.svg";
import React from "react";
import {ReactComponent as RetryIcon} from "../../../../../../assets/svg/General/Retake/retake.svg";

interface Props {
	text: string;
	primaryButtonText: string;
	isNoPrimaryButton?: boolean;

	handleRedo: () => void;
	handlePreviewDocumentFunc: () => void;
}

function UploadedCard(props: Props): JSX.Element {
	return (
		<div className="flex flex-col items-center justify-center w-full space-y-10">
			<div className="flex flex-col 3xs:flex-row items-center justify-between space-y-4 3xs:space-y-0 w-full h-full 3xs:h-14 py-4 3xs:py-0 px-2 xs:px-6 border border-black-quat rounded-lg space-x-0 3xs:space-x-2">
				<div className="flex flex-row items-center space-x-2 3xs:space-x-4">
					<div className="flex justify-center items-center h-full w-full">
						<MarkIcon />
					</div>
					<span className="text-sm 3xs:text-base whitespace-nowrap">{props.text}</span>
				</div>
				<div className={"flex flex-row justify-start space-x-2  " + `${!props.isNoPrimaryButton ? "divide-x-2 border-black-quin" : ""}`}>
					{!props.isNoPrimaryButton && (
						<div className="border-black-quin">
							<ButtonComp color="blue" buttonType="tertiary" size="md" func={props.handleRedo}>
								{props.primaryButtonText}
								<span className="ml-1">
									<RetryIcon />
								</span>
							</ButtonComp>
						</div>
					)}

					<div className="pl-2 border-black-quin">
						<ButtonComp color="blue" buttonType="tertiary" size="md" func={props.handlePreviewDocumentFunc}>
							Preview
							<span className="ml-1">
								<EyesIcon />
							</span>
						</ButtonComp>
					</div>
				</div>
			</div>
		</div>
	);
}

export default UploadedCard;
