import MetaEntity from "./metaEntity";
import Parsers from "../../utils/parsers";
import {GenericObject} from "../../helpers/types";
import {immerable} from "immer";

export default class Industry extends MetaEntity {
	[immerable] = true;

	constructor(public id: number, public name: string, public requireSCUML: boolean) {
		super(id, name);
	}

	static create(obj: GenericObject): Industry {
		return new Industry(Parsers.number(obj.id), Parsers.string(obj.name), Parsers.boolean(obj.requireSCUML));
	}
}
