import {AccountRestrictionType} from "./accountRestriction.constants";
import BankAccount from "./bankAccount";
import CustomerAccount from "./customerAccount";
import {GenericObject} from "../helpers/types";
import Parsers from "../utils/parsers";
import UserAccount from "./userAccount";
import {immerable} from "immer";
import store from "../redux/store";
import Card from "./card";

export default class AccountRestriction {
	[immerable] = true;

	private constructor(
		public type: AccountRestrictionType,
		// public customerAccounts: CustomerAccount[],
		public customerAccountIds: string[],
		public userAccounts: UserAccount[],
		public userAccountIds: string[],
		public cards: Card[],
		public cardIds: string[]
	) {}

	static create(obj: GenericObject): AccountRestriction {
		// create the customer account array - set in store
		Parsers.classObjectArray(obj.customerAccounts as unknown[], CustomerAccount);

		return new AccountRestriction(
			Parsers.number(obj.type),
			// Parsers.classObjectArray(obj.customerAccounts as unknown[], CustomerAccount),
			Parsers.stringArray(obj.customerAccountIds as unknown[]),
			Parsers.classObjectArray(obj.userAccounts as unknown[], UserAccount),
			Parsers.stringArray(obj.userAccountIds as unknown[]),
			Parsers.classObjectArray(obj.cards, Card),
			Parsers.stringArray(obj.cardIds as unknown[])
		);
	}

	get cannotSendMoney(): boolean {
		return this.type === AccountRestrictionType.CANNOT_SEND_MONEY;
	}

	get canSendMoney(): boolean {
		return !this.cannotSendMoney;
	}

	get canSendMoneyToAnyAccount(): boolean {
		return this.type === AccountRestrictionType.SEND_MONEY_TO_ANY_ACCOUNT;
	}

	get canSendMoneyToSpecificAccounts(): boolean {
		return this.type === AccountRestrictionType.SEND_MONEY_TO_SPECIFIC_ACCOUNTS;
	}

	get numRecipients(): number {
		return this.customerAccountIds.length + this.userAccountIds.length;
	}

	get recipientBankAccounts(): BankAccount[] {
		return (
			this.userAccounts
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				.map((userAccount) => userAccount.bankAccount!)
				// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
				.concat(this.customerAccounts.map((customerAccount) => customerAccount.bankAccount!))
				.filter((account) => !!account)
		);
	}

	get customerAccounts(): CustomerAccount[] {
		const customerAccountMap = store.getState().customerAccount.customerAccounts;
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		return this.customerAccountIds.filter((id) => customerAccountMap.has(id)).map((id) => customerAccountMap.get(id)!);
	}
}
