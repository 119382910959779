import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import {LandingState} from "./landingSlice.types";
import {SignUpCountries} from "../../modules/Landing/Signup/Services/signup.constant";

const initialState: LandingState = {
	country: undefined,
};

export const landingSlice = createSlice({
	name: "landing",
	initialState,
	reducers: {
		//account
		setLandingCountry: (state: LandingState, action: PayloadAction<SignUpCountries>) => {
			state.country = action.payload;
		},

		resetLandingCountry: (state: LandingState) => {
			state.country = undefined;
		},
	},
});

export const {setLandingCountry, resetLandingCountry} = landingSlice.actions;

export default landingSlice.reducer;
