import {
	resetTransferDetail,
	setCanVerifyRecipientAccountDetails,
	setIsUpdatingTransferDetail,
} from "../../../../../../../redux/payments/bulkTransfer/slice/bulkTransferSlice";
import {useCallback, useState} from "react";

// import {abortAllSingleTransferRequests} from "../../../../Services/SendMoney/singleTransferApi";
import {useDispatch} from "react-redux";

export interface UseBulkTransferRecipientModalInterface {
	showRecipientModal: boolean;
	handleShowRecipientModal: () => void;
	handleCloseRecipientModal: () => void;
}

function useBulkTransferRecipientModal(): UseBulkTransferRecipientModalInterface {
	const dispatch = useDispatch();

	const [showRecipientModal, setShowRecipientModal] = useState<boolean>(false);

	const handleCloseRecipientModal = useCallback(() => {
		// abortAllSingleTransferRequests();
		setShowRecipientModal(false);
		dispatch(resetTransferDetail());
		dispatch(setIsUpdatingTransferDetail(false));
		dispatch(setCanVerifyRecipientAccountDetails(false));
	}, []);

	const handleShowRecipientModal = useCallback(() => {
		setShowRecipientModal(true);
	}, []);

	return {
		showRecipientModal,
		handleCloseRecipientModal,
		handleShowRecipientModal,
	};
}

export default useBulkTransferRecipientModal;
