import Currency from "../../../../models/currency";
import CurrencyCode from "../../../General/CurrencyCode";
import {ReactComponent as InflowArrowIcon} from "../../../../assets/svg/DashboardLayout/SearchBar/inflow.svg";
import {ReactComponent as OutflowArrowIcon} from "../../../../assets/svg/DashboardLayout/SearchBar/outflow.svg";
import React from "react";
import {ToastType} from "../../../General/ToastContainer";
import {formatDate} from "../../../../utils/formatDate";
import formatNumber from "../../../../utils/formatNumber";

interface Props {
	date: Date | null;
	name: string;
	secondName?: string;
	currency: Currency | undefined | null;
	amount: number | string;
	isDebit: boolean;
	isFailed: boolean;
	isDeclined: boolean;
	isSuccessful: boolean;
	isProcessing: boolean;
	isCurrentPage: boolean;
	status?: string;
	dataType: string;
	statusType?: ToastType;
	onClick: () => void;
}

function SearchBarFeatureBodyAmount(props: Props): JSX.Element {
	return (
		<>
			<div onClick={props.onClick} data-type={props.dataType}>
				<div
					className={
						"flex cursor-pointer flex-row items-center justify-start space-x-4 px-4 pb-2.5 pt-2.5 hover:bg-grey-backdrop " +
						`${props.isCurrentPage ? "mb-3" : ""}`
					}
					data-type={props.dataType}
				>
					<div className="w-25% pr-1" data-type={props.dataType}>
						<p
							className="max-w-2xs overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-black-tertiary"
							data-type={props.dataType}
						>
							{props.date && formatDate(props.date)}
						</p>
					</div>

					<div
						className={`flex items-center justify-start ` + `${props.secondName ? "w-30% max-w-30%" : "w-55% max-w-55%"}`}
						data-type={props.dataType}
					>
						<p
							className="max-w-3xl overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-black-secondary"
							data-type={props.dataType}
						>
							{props.name}
						</p>
					</div>

					{props.secondName && (
						<div className="flex w-25% items-center justify-start max-w-25%" data-type={props.dataType}>
							<p
								className="max-w-3xl overflow-hidden overflow-ellipsis whitespace-nowrap text-xs text-black-secondary"
								data-type={props.dataType}
							>
								{props.secondName}
							</p>
						</div>
					)}

					<div
						className={
							"flex justify-end space-x-2 w-20% max-w-20% " +
							`${
								props.isDeclined || props.isProcessing
									? "text-warning"
									: props.isSuccessful
									? "text-success"
									: props.isFailed
									? "text-error"
									: "text-black-secondary"
							}`
						}
						data-type={props.dataType}
					>
						<div
							className="max-w-2xs w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-xs capitalize text-right"
							data-type={props.dataType}
						>
							<p className={props.isFailed ? "line-through " : ""} data-type={props.dataType}>
								<CurrencyCode currency={props.currency} data-type={props.dataType} />
								{formatNumber(props.amount)}
							</p>
						</div>

						<div className="flex items-center space-x-2" data-type={props.dataType}>
							{props.isDebit && <OutflowArrowIcon className="fill-current" data-type={props.dataType} />}
							{!props.isDebit && <InflowArrowIcon className="fill-current" data-type={props.dataType} />}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default SearchBarFeatureBodyAmount;
