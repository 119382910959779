import {HintType, ListStyle} from "../../../../../Application/components/element/hint/hint.constant";
import React, {useCallback} from "react";
import {
	ZambiaPaymentMethod,
	ZambiaSinglePaymentInternalStage,
} from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice.types";
import {
	resetAllZambiaSingleTransferData,
	resetZambiaSingleTransferAmount,
	resetZambiaSingleTransferRecipient,
	setZambiaPaymentMethod,
	setZambiaSinglePaymentInternalStage,
} from "../../../../../../../redux/payments/zambia/singleTransfer/slice/singleTransferSlice";
import {useDispatch, useSelector} from "react-redux";

import AccountNumberDropdown from "../../../DropDowns/AccountNumberDropdown";
import {BandFees} from "../../../../../../../helpers/zambiaFeeCalulator";
import BankAccount from "../../../../../../../models/bankAccount";
import BankDropdown from "../../../DropDowns/BankDropdown";
import ButtonComp from "../../../../../../../components/General/Buttons/ButtonComp";
import Card from "../../../../../../../models/card";
import {CompanyHint} from "../../../../../Application/services/nigeria-application/registered/company.types";
import CurrencyCode from "../../../../../../../components/General/CurrencyCode";
import CustomSelect from "../../../../../../../components/General/Dropdown/CustomSelect";
import CustomerAccount from "../../../../../../../models/customerAccount";
import ErrorCard from "../../../Cards/ErrorCard";
import Hint from "../../../../../Application/components/element/hint";
import {IRootState} from "../../../../../../../redux/rootReducer";
import Input from "../../../../../../../components/General/Inputs/Input";
import MessageToasts from "../../../../../../../components/General/MessageToasts/MessageToasts";
import MoneyInput from "../../../../../../../components/General/Inputs/MoneyInput";
import {PaymentStageType} from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {PaymentsType} from "../../../../Hooks/State/SendMoney/payments.constants";
import SinglePaymentHeaders from "./SinglePaymentHeaders";
import TextArea from "../../../../../../../components/General/TextArea/TextArea";
import {ToastType} from "../../../../../../../helpers/AppConstants";
import UserAccount from "../../../../../../../models/userAccount";
import UserAccountDropdown from "../../../DropDowns/UserAccountDropdown";
import ZambiaFee from "../../../../../../../components/General/Fees/ZambiaFee";
import formatNumber from "../../../../../../../utils/formatNumber";
import isNullOrUndefined from "../../../../../../../utils/isNullOrUndefined";
import {setPaymentStage} from "../../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import titleCase from "../../../../../../../hooks/titleCase";
import {useHistory} from "react-router";
import {useMoneyToNumber} from "../../../../../../../hooks/useMoneyToNumber";
import useSingleTransferAccountNumberAutocomplete from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransferAccountNumberAutocomplete";
import useSingleTransferAmount from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransferAmount";
import useSingleTransferPayFrom from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransferPayFrom";
import useSingleTransferPurpose from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransferPurpose";
import useSingleTransferRecipientBankCode from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransferRecipientBankCode";
import useSingleTransferVerifyRecipientAccountDetails from "../../../../Hooks/State/SendMoney/Zambia/SingleTransfer/useSingleTransferVerifyRecipientAccountDetails";
import {zambiaPaymentMethods} from "../../../../Hooks/State/SendMoney/Zambia/zambia-single-transfer.constant";

const TransferHints: CompanyHint[] = [
	{
		index: 0,
		span: <p>Lenco Business is a way for you to send money to other Lenco Business account holders and they&apos;ll receive funds instantly.</p>,
	},
];

function PaymentDetails(): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();

	const selectedUserAccount = useSelector((state: IRootState) => state.zambiaSingleTransfer.selectedUserAccount);
	const singleTransferDetails = useSelector((state: IRootState) => state.zambiaSingleTransfer.singleTransferDetails);
	const singlePaymentInternalStage = useSelector((state: IRootState) => state.zambiaSingleTransfer.singlePaymentInternalStage);

	const bankAccount = useSelector((state: IRootState) => state.init.main?.meta.transferFee.zm?.bankAccount);
	const mobileMoney = useSelector((state: IRootState) => state.init.main?.meta.transferFee.zm?.mobileMoney);
	const lencoMerchant = useSelector((state: IRootState) => state.init.main?.meta.transferFee.zm?.lencoMerchant);

	const {handleAmountChange} = useSingleTransferAmount();
	const {handlePurposeChange} = useSingleTransferPurpose();
	const {handleSelectBank} = useSingleTransferRecipientBankCode();
	const {accounts, selectedAccountId, currentUserAccountMeta, handleSelectAccount} = useSingleTransferPayFrom();
	const {verifyAccountError, verifiedAccount, isVerifyAccountLoading, handleSetVerifiedAccount, handleResetVerifiedAccount} =
		useSingleTransferVerifyRecipientAccountDetails();
	const {telcoImg, isRecipientAutocompleteLoading, suggestedRecipients, handleSelectRecipient, handleAccountNumberChange} =
		useSingleTransferAccountNumberAutocomplete({handleSelectBank, handleSetVerifiedAccount, handleResetVerifiedAccount});

	const accountBalance = selectedUserAccount?.balance;
	const amountWithFee =
		singleTransferDetails.paymentMethod && singleTransferDetails.amount.length > 0
			? BandFees(singleTransferDetails.paymentMethod, useMoneyToNumber(singleTransferDetails.amount)).allFees +
			  (useMoneyToNumber(singleTransferDetails.amount) || 0)
			: null;
	const maxAmount =
		singleTransferDetails.paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER
			? bankAccount?.maxAmount
			: singleTransferDetails.paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS
			? lencoMerchant?.maxAmount
			: singleTransferDetails.paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY
			? mobileMoney?.maxAmount
			: null;
	const minAmount =
		singleTransferDetails.paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER
			? bankAccount?.minAmount
			: singleTransferDetails.paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS
			? lencoMerchant?.minAmount
			: singleTransferDetails.paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY
			? mobileMoney?.minAmount
			: null;

	const isInsufficientFunds =
		selectedUserAccount && !isNullOrUndefined(accountBalance)
			? useMoneyToNumber(singleTransferDetails.amount) > accountBalance || (amountWithFee && amountWithFee > accountBalance)
			: false;
	// const selectedZambiaMobileMoneyOperator =
	// Object.values(ZambiaMobileMoneyOperator).find((val) => val === singleTransferDetails.recipient.bankCode) || undefined;

	const handleBack = useCallback(() => {
		dispatch(setPaymentStage(PaymentStageType.INITIAL));
		dispatch(resetAllZambiaSingleTransferData());
	}, []);

	return (
		<>
			<div className="flex w-full flex-row items-center justify-center lg:items-start lg:justify-between lg:space-x-8">
				<div className="w-full max-w-sm">
					<SinglePaymentHeaders />
					{singlePaymentInternalStage === ZambiaSinglePaymentInternalStage.PAYMENT_DETAILS_STAGE && (
						<div className="flex w-full flex-col justify-between">
							<div className="flex flex-col items-start space-y-4 pt-2 text-base font-normal  md:items-center md:px-0 lg:items-center lg:px-0">
								<div className="flex w-full flex-col space-y-4">
									<UserAccountDropdown
										placeholder="Pay from"
										value={selectedAccountId || ""}
										options={accounts || null}
										onSelect={handleSelectAccount}
										isDisabled={!!(accounts && accounts.length < 2)}
										showBalance
										initiatorCanSelect
									/>
									<CustomSelect
										placeholder="Payment Method"
										value={singleTransferDetails.paymentMethod}
										options={zambiaPaymentMethods.map((item) => ({
											text: item.name,
											value: item.id,
											icon: item.icon,
										}))}
										onSelect={(value) => {
											if (value) {
												dispatch(resetZambiaSingleTransferRecipient());
												dispatch(setZambiaPaymentMethod(value));
												handleResetVerifiedAccount();
												if (value === ZambiaPaymentMethod.LENCO_BUSINESS) {
													handleSelectBank("lenco-till");
												}
											}
										}}
										big
									/>
									{(singleTransferDetails.paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER ||
										singleTransferDetails.paymentMethod === ZambiaPaymentMethod.LENCO_BUSINESS) && (
										<>
											{selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
												<>
													<MessageToasts
														toastMessage={" You can only send to specific recipients"}
														toastType={ToastType.INFORMATION}
													/>
													<AccountNumberDropdown
														data={suggestedRecipients}
														inputValue={singleTransferDetails.recipient.accountNumber}
														placeholder="Select Recipient"
														clickAndClose
														onClickFunc={(e) => {
															if (e instanceof UserAccount || e instanceof CustomerAccount) {
																handleSelectRecipient(e.bankAccount as BankAccount);
															}
															if (e instanceof Card) {
																history.push({
																	pathname: "/payments/make/single",
																	search: `?to=${e.id}`,
																	state: {
																		typeOfTransfer: PaymentsType.FUND_CARD,
																		from: selectedAccountId,
																		to: e.id,
																	},
																});
															}
														}}
														isSearchable={!!suggestedRecipients && suggestedRecipients.length > 4}
														isInputDisabled
														showArrow
													/>
													{!verifyAccountError && verifiedAccount && (
														<Input
															placeholder="Account Name"
															value={titleCase(verifiedAccount.accountName)}
															readOnly
															fullWidth
														/>
													)}
												</>
											)}
											{selectedUserAccount?.accountRestrictions.cannotSendMoney && (
												<MessageToasts
													toastMessage={"You can not send money from this account"}
													toastType={ToastType.ERROR}
												/>
											)}
											{selectedUserAccount &&
												!selectedUserAccount?.accountRestrictions.cannotSendMoney &&
												!selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts && (
													<>
														<AccountNumberDropdown
															data={suggestedRecipients as UserAccount[] | null}
															placeholder={`Account Number or Name`}
															inputValue={singleTransferDetails.recipient.accountNumber}
															isLoading={isRecipientAutocompleteLoading}
															clickAndClose
															onChangeFunc={handleAccountNumberChange}
															onClickFunc={(e) => {
																if (e instanceof UserAccount || e instanceof CustomerAccount) {
																	handleSelectRecipient(e.bankAccount as BankAccount);
																}
																if (e instanceof Card) {
																	history.push({
																		pathname: "/payments/make/single",
																		search: `?to=${e.id}`,
																		state: {
																			typeOfTransfer: PaymentsType.FUND_CARD,
																			from: selectedAccountId,
																			temp: true,
																			to: e.id,
																		},
																	});
																}
															}}
														/>
														{singleTransferDetails.paymentMethod === ZambiaPaymentMethod.BANK_TRANSFER && (
															<BankDropdown
																accountNumber={singleTransferDetails.recipient.accountNumber}
																bankCode={singleTransferDetails.recipient.bankCode}
																onClickFunc={handleSelectBank}
																// withoutLenco
															/>
														)}

														{verifyAccountError && !verifiedAccount && (
															<MessageToasts toastMessage={verifyAccountError.message} toastType={ToastType.ERROR} />
														)}
														{!verifyAccountError && verifiedAccount && (
															<Input
																placeholder="Account Name"
																value={titleCase(verifiedAccount.accountName)}
																readOnly
																fullWidth
															/>
														)}
													</>
												)}
										</>
									)}
									{singleTransferDetails.paymentMethod === ZambiaPaymentMethod.MOBILE_MONEY && (
										<div className="flex w-full flex-col items-start justify-start space-y-4">
											<Input
												placeholder="Mobile Number"
												value={titleCase(singleTransferDetails.recipient.accountNumber)}
												onChange={(value: string) => handleAccountNumberChange(value)}
												appendIcon={
													telcoImg ? (
														<div className="w-16 h-12 pr-4 flex justify-center items-center">
															<img className="h-9  rounded-full border border-black-quin" src={telcoImg} alt="telco" />
														</div>
													) : undefined
												}
												fullWidth
											/>
											{verifyAccountError && !verifiedAccount && (
												<MessageToasts toastMessage={verifyAccountError.message} toastType={ToastType.ERROR} />
											)}
											{!verifyAccountError && verifiedAccount && (
												<Input placeholder="Account Name" value={titleCase(verifiedAccount.accountName)} readOnly fullWidth />
											)}
										</div>
									)}
								</div>
							</div>
							<div className="flex w-full flex-row items-center justify-center space-x-4 pt-16">
								<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={handleBack}>
									<span className="w-full text-center">Back</span>
								</ButtonComp>

								<ButtonComp
									type="submit"
									color="blue"
									ripple="light"
									buttonType="primary"
									disable={
										!!(
											!selectedUserAccount ||
											!singleTransferDetails.paymentMethod ||
											verifyAccountError ||
											!verifiedAccount ||
											selectedUserAccount.accountRestrictions.cannotSendMoney
										)
									}
									isLoading={isVerifyAccountLoading}
									func={() => dispatch(setZambiaSinglePaymentInternalStage(ZambiaSinglePaymentInternalStage.AMOUNT_PURPOSE_STAGE))}
								>
									<div className="flex flex-row items-center justify-center">
										<span className="w-full text-center">Next</span>
									</div>
								</ButtonComp>
							</div>
						</div>
					)}
					{singlePaymentInternalStage === ZambiaSinglePaymentInternalStage.AMOUNT_PURPOSE_STAGE && (
						<div className="flex w-full flex-col justify-between">
							<div className="flex w-full flex-col space-y-4 pt-2">
								<div className="flex w-full flex-col space-y-2">
									<MoneyInput
										placeholder="Amount"
										icon={<CurrencyCode currency={accounts ? accounts[0]?.bankAccount?.currency : undefined} />}
										value={singleTransferDetails.amount || ""}
										onChange={handleAmountChange}
									/>
									{singleTransferDetails.paymentMethod && (
										<div
											className={`${
												singleTransferDetails.amount || singleTransferDetails.amount.length > 1 ? "block" : "hidden"
											}`}
										>
											<ZambiaFee
												value={singleTransferDetails?.amount || ""}
												paymentMethod={singleTransferDetails.paymentMethod}
											/>
										</div>
									)}
								</div>
								<TextArea
									placeholder="Purpose of payment"
									value={singleTransferDetails.purpose}
									onChangeFunc={handlePurposeChange}
									sm={true}
								/>
								{useMoneyToNumber(singleTransferDetails.amount) > 0 &&
									singleTransferDetails.paymentMethod !== ZambiaPaymentMethod.LENCO_BUSINESS &&
									minAmount &&
									useMoneyToNumber(singleTransferDetails.amount) < minAmount && (
										<ErrorCard isZambiaMinAmount zambiaMinAmount={minAmount} />
									)}
								{maxAmount && useMoneyToNumber(singleTransferDetails.amount) > maxAmount && (
									<ErrorCard isZambiaMaxAmount zambiaMaxAmount={maxAmount} />
								)}
								{isInsufficientFunds && selectedUserAccount && !isNullOrUndefined(accountBalance) && (
									<ErrorCard balance={accountBalance} isInsufficientFunds />
								)}
								{currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
									<div className="w-full">
										<MessageToasts
											toastMessage={
												<p>
													You can only send <CurrencyCode />
													{formatNumber(currentUserAccountMeta?.maxApprovalAmount)} or less on this account. Any amount more
													than this will be initiated and need to be approved by an admin.
												</p>
											}
											toastType={ToastType.INFORMATION}
										/>
									</div>
								)}
							</div>
							<div className="flex w-full flex-row items-center justify-center space-x-4 pt-16">
								<ButtonComp
									type="button"
									ripple="light"
									buttonType="secondary"
									color="grey"
									func={() => {
										dispatch(setZambiaSinglePaymentInternalStage(ZambiaSinglePaymentInternalStage.PAYMENT_DETAILS_STAGE));
										dispatch(resetZambiaSingleTransferAmount());
										handlePurposeChange("");
									}}
								>
									<span className="w-full text-center">Back</span>
								</ButtonComp>
								<ButtonComp
									type="submit"
									color="blue"
									ripple="light"
									buttonType="primary"
									disable={
										!singleTransferDetails.purpose ||
										!singleTransferDetails.amount ||
										useMoneyToNumber(singleTransferDetails.amount) === 0 ||
										isInsufficientFunds ||
										(singleTransferDetails.paymentMethod !== ZambiaPaymentMethod.LENCO_BUSINESS &&
											minAmount &&
											useMoneyToNumber(singleTransferDetails.amount) < minAmount) ||
										!!(maxAmount && useMoneyToNumber(singleTransferDetails.amount) > maxAmount)
									}
									func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
								>
									<span className="w-full text-center">Next</span>
								</ButtonComp>
							</div>
						</div>
					)}
				</div>
				{/* {!singleTransferDetails.paymentMethod && ( */}
				<div className="flex justify-start items-center pt-24 mt-1">
					<div className="w-fit">
						<Hint infoText="New update" hintType={HintType.INFO} listStyle={ListStyle.PLAIN} firstList={TransferHints} fullWidth />
					</div>
				</div>
				{/* )} */}
			</div>
		</>
	);
}

export default PaymentDetails;
