import {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {addTransactionComment} from "../Services/transactionDetailApi";
import {errorTrue} from "../../../../../../../redux/error/slice/errorSlice";
import {messageTrue} from "../../../../../../../redux/message/slice/messageSlice";
import {setSingleTransaction} from "../../../../../../../redux/transaction/slice/transactionSlice";
import {useDispatch} from "react-redux";
import {getErrorMessage} from "../../../../../../../utils/getErrorMessage";
import Transaction from "../../../../../../../models/transaction";

export interface UseTransactionDetailsCommentsResponse {
	isSubmitting: boolean;
	handleAddTransactionComment: (transaction: Transaction, commentText: string) => Promise<void>;
	newCommentText: string;
	setNewCommentText: Dispatch<SetStateAction<string>>;
}

function useTransactionDetailsCommentForm(): UseTransactionDetailsCommentsResponse {
	const dispatch = useDispatch();

	const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
	const [isError, setIsError] = useState<boolean>(false);
	const [newCommentText, setNewCommentText] = useState<string>("");

	useEffect(() => {
		if (!isError) return;
		setNewCommentText("");
	}, [isError]);

	const handleAddTransactionComment = useCallback(
		async (transaction: Transaction, commentText: string): Promise<void> => {
			try {
				setIsError(false);
				setIsSubmitting(true);
				const updatedTransaction = await addTransactionComment(transaction.id, commentText);
				dispatch(messageTrue({message: "Comment Added Successfully"}));
				dispatch(setSingleTransaction(updatedTransaction));
				setNewCommentText("");
			} catch (err) {
				setIsError(true);
				dispatch(errorTrue({message: getErrorMessage(err)}));
			} finally {
				setIsSubmitting(false);
			}
		},
		[dispatch]
	);

	return {
		isSubmitting,
		handleAddTransactionComment,
		newCommentText,
		setNewCommentText,
	};
}

export default useTransactionDetailsCommentForm;
