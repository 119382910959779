import {useCallback, useState} from "react";

export interface UseTransactionDetailsPendingApprovalResponse {
	showPendingApprovalModal: boolean;

	handleTransactionApproved: () => void;
	handleTransactionDeclined: () => void;
	handleClosePendingApprovalShowModal: () => void;
	handleTogglePendingApprovalShowModal: () => void;
}

function useTransactionDetailsPendingApproval(): UseTransactionDetailsPendingApprovalResponse {
	const [showPendingApprovalModal, setShowPendingApprovalModal] = useState<boolean>(false);

	const handleTransactionApproved = () => {
		setShowPendingApprovalModal(false);
	};

	const handleTransactionDeclined = () => {
		setShowPendingApprovalModal(false);
	};

	const handleTogglePendingApprovalShowModal = useCallback(() => {
		setShowPendingApprovalModal((prev) => !prev);
	}, []);

	const handleClosePendingApprovalShowModal = useCallback(() => {
		setShowPendingApprovalModal(false);
	}, []);

	return {
		showPendingApprovalModal,
		handleTransactionApproved,
		handleTransactionDeclined,
		handleClosePendingApprovalShowModal,
		handleTogglePendingApprovalShowModal,
	};
}

export default useTransactionDetailsPendingApproval;
