import {
	AllTerminalsStageType,
	SpecificTerminalStageType,
	TerminalFilterTypes,
	TerminalRequestsFilterTypes,
	TerminalsStageType,
	TerminalsState,
} from "./terminalsSlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {PurchaseType, TerminalRequestStatus} from "../../../models/terminal.constants";

import Terminal from "../../../models/terminal";
import TerminalRequest from "../../../models/terminalRequest";
import {TerminalsInitResponse} from "../../../modules/Dashboard/Terminals/Services/terminalsApi.types";

const initialState: TerminalsState = {
	//<===for terminal stages and inner stages===>
	terminalStage: TerminalsStageType.ALL_TERMINALS,
	allTerminalStage: AllTerminalsStageType.INITIAL,
	specificTerminalStage: SpecificTerminalStageType.TERMINAL_TRANSACTIONS,
	specificallySelectedTerminal: null,
	selectedTerminalNameInTerminalStage: null,

	//<===for terminals in general===>
	initMeta: null,
	merchant: null,
	terminals: [],
	initTerminals: [],
	requestedTerminals: [],
	initRequestedTerminals: [],
	initTerminalRequestsListTotal: 0,
	initTerminalRequestsListGroupSize: 0,

	//<===for terminal modals===>
	isSettingUpTerminalModalOpen: false,
	isRequestingTerminalModalOpen: false,

	//<===for terminal filter===>
	isExportTerminalsLoading: false,
	isTerminalPaginationLoading: false,
	terminalListTotal: 0,
	terminalListGroupSize: 0,
	terminalPaginationOffset: 0,
	terminalFilterState: {
		offset: 0,
		query: "",
		groupId: "",
	},
	selectedTerminalFilterState: {
		offset: 0,
		query: "",
		groupId: "",
	},

	//<===for terminal request filter===>
	isTerminalRequestsPaginationLoading: false,
	isExportTerminalRequestsLoading: false,
	terminalRequestsListTotal: 0,
	terminalRequestsListGroupSize: 0,
	terminalRequestsPaginationOffset: 0,
	terminalRequestsFilterState: {
		offset: 0,
		statuses: [],
		purchaseTypes: [],
	},
	selectedTerminalRequestsFilterState: {
		offset: 0,
		statuses: [],
		purchaseTypes: [],
	},
};

export const terminalsSlice = createSlice({
	name: "terminals",
	initialState,
	reducers: {
		//<===for terminal stages and inner stages===>
		setTerminalStage: (state: TerminalsState, action: PayloadAction<TerminalsStageType>) => {
			state.terminalStage = action.payload;
		},
		setSpecificTerminalStage: (state: TerminalsState, action: PayloadAction<SpecificTerminalStageType>) => {
			state.specificTerminalStage = action.payload;
		},
		setAllTerminalStageToInitial: (state: TerminalsState) => {
			state.allTerminalStage = AllTerminalsStageType.INITIAL;
		},
		setAllTerminalStageToSpecificTerminal: (state: TerminalsState, action: PayloadAction<Terminal>) => {
			state.allTerminalStage = AllTerminalsStageType.SPECIFIC_TERMINAL_TRANSACTION;
			state.specificallySelectedTerminal = action.payload;
			state.selectedTerminalNameInTerminalStage = action.payload.name || action.payload.serialNumber;
		},
		resetAllTerminalStageToSpecificTerminal: (state: TerminalsState) => {
			state.specificTerminalStage = SpecificTerminalStageType.TERMINAL_TRANSACTIONS;
			state.specificallySelectedTerminal = null;
			state.selectedTerminalNameInTerminalStage = null;
		},

		setInitTerminalDetails: (state: TerminalsState, action: PayloadAction<TerminalsInitResponse>) => {
			state.initMeta = action.payload.meta;
			state.merchant = action.payload.merchant;
			state.initTerminals = action.payload.terminalsData.terminals;
			state.initRequestedTerminals = action.payload.terminalRequestsData.terminalRequests;
			state.initTerminalRequestsListTotal = action.payload.terminalRequestsData.total;
			state.initTerminalRequestsListGroupSize = action.payload.terminalRequestsData.groupSize;
		},

		//<===for terminals in general===>
		setTerminals: (state: TerminalsState, action: PayloadAction<Terminal[]>) => {
			state.terminals = action.payload;
		},
		setRequestedTerminals: (state: TerminalsState, action: PayloadAction<TerminalRequest[]>) => {
			state.requestedTerminals = action.payload;
		},
		setRequestedTerminal: (state: TerminalsState, action: PayloadAction<TerminalRequest>) => {
			state.requestedTerminals = [action.payload, ...state.requestedTerminals];
			state.initRequestedTerminals = [action.payload, ...state.initRequestedTerminals];
		},

		//<===for terminal modals===>
		setIsSettingUpTerminalModalOpen: (state: TerminalsState, action: PayloadAction<boolean>) => {
			state.isSettingUpTerminalModalOpen = action.payload;
		},
		setIsRequestingTerminalModalOpen: (state: TerminalsState, action: PayloadAction<boolean>) => {
			state.isRequestingTerminalModalOpen = action.payload;
		},

		//<===for terminal filter===>
		setIsExportTerminalsLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
			state.isExportTerminalsLoading = action.payload;
		},
		setIsTerminalPaginationLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
			state.isTerminalPaginationLoading = action.payload;
		},
		setTerminalListTotal: (state: TerminalsState, action: PayloadAction<number>) => {
			state.terminalListTotal = action.payload;
		},
		setTerminalPaginationOffset: (state: TerminalsState, action: PayloadAction<number>) => {
			state.terminalPaginationOffset = action.payload;
		},
		setTerminalListGroupSize: (state: TerminalsState, action: PayloadAction<number>) => {
			state.terminalListGroupSize = action.payload;
		},
		setTerminalFilterState: (state: TerminalsState, action: PayloadAction<TerminalFilterTypes>) => {
			state.terminalFilterState = {...state.terminalFilterState, ...action.payload};
		},
		setTerminalQueryFilter: (state: TerminalsState, action: PayloadAction<string>) => {
			state.selectedTerminalFilterState.query = action.payload;
			state.terminalFilterState.query = action.payload;
		},
		setTerminalGroupIdFilter: (state: TerminalsState, action: PayloadAction<string>) => {
			state.selectedTerminalFilterState.groupId = action.payload;
			state.terminalFilterState.groupId = action.payload;
		},
		setSelectedTerminalFilterState: (state: TerminalsState) => {
			state.selectedTerminalFilterState = {...state.terminalFilterState};
		},
		resetTerminalQueryFilterState: (state: TerminalsState) => {
			state.selectedTerminalFilterState = {...state.selectedTerminalFilterState, query: ""};
			state.terminalFilterState = {...state.terminalFilterState, query: ""};
		},
		resetTerminalGroupIdFilterState: (state: TerminalsState) => {
			state.selectedTerminalFilterState = {...state.selectedTerminalFilterState, groupId: ""};
			state.terminalFilterState = {...state.terminalFilterState, groupId: ""};
		},

		resetTerminalFilterState: (state: TerminalsState) => {
			state.terminalFilterState = {
				offset: 0,
				query: "",
				groupId: "",
			};
			state.selectedTerminalFilterState = {
				offset: 0,
				query: "",
				groupId: "",
			};
		},

		//<===for terminal request filter===>
		setSelectedTerminalRequestsFilterState: (state: TerminalsState) => {
			state.selectedTerminalRequestsFilterState = {...state.terminalRequestsFilterState};
		},
		setIsExportTerminalRequestsLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
			state.isExportTerminalRequestsLoading = action.payload;
		},
		setIsTerminalRequestsPaginationLoading: (state: TerminalsState, action: PayloadAction<boolean>) => {
			state.isTerminalRequestsPaginationLoading = action.payload;
		},
		setTerminalRequestsListTotal: (state: TerminalsState, action: PayloadAction<number>) => {
			state.terminalRequestsListTotal = action.payload;
		},
		updateTerminalRequestsListTotalByOne: (state: TerminalsState) => {
			if (state.initTerminalRequestsListTotal !== 100) {
				state.terminalRequestsListTotal = state.terminalRequestsListTotal + 1;
				state.initTerminalRequestsListTotal = state.initTerminalRequestsListTotal + 1;
			}
		},
		setTerminalRequestsPaginationOffset: (state: TerminalsState, action: PayloadAction<number>) => {
			state.terminalRequestsPaginationOffset = action.payload;
		},
		setTerminalRequestsListGroupSize: (state: TerminalsState, action: PayloadAction<number>) => {
			state.terminalRequestsListGroupSize = action.payload;
		},
		setTerminalRequestsFilterState: (state: TerminalsState, action: PayloadAction<TerminalRequestsFilterTypes>) => {
			state.terminalRequestsFilterState = {...state.terminalRequestsFilterState, ...action.payload};
		},
		setRequestPurchaseTypesFilter: (state: TerminalsState, action: PayloadAction<PurchaseType>) => {
			state.selectedTerminalRequestsFilterState.purchaseTypes = state.selectedTerminalRequestsFilterState.purchaseTypes.filter(
				(_types) => _types !== action.payload
			);
			state.terminalRequestsFilterState.purchaseTypes = state.terminalRequestsFilterState.purchaseTypes.filter(
				(_types) => _types !== action.payload
			);
		},
		setRequestStatusesFilter: (state: TerminalsState, action: PayloadAction<TerminalRequestStatus>) => {
			state.selectedTerminalRequestsFilterState.statuses = state.selectedTerminalRequestsFilterState.statuses.filter(
				(_status) => _status !== action.payload
			);
			state.terminalRequestsFilterState.statuses = state.terminalRequestsFilterState.statuses.filter((_status) => _status !== action.payload);
		},

		resetRequestPurchaseTypesState: (state: TerminalsState) => {
			state.selectedTerminalRequestsFilterState = {...state.selectedTerminalRequestsFilterState, purchaseTypes: []};
			state.terminalRequestsFilterState = {...state.terminalRequestsFilterState, purchaseTypes: []};
		},
		resetRequestStatusesState: (state: TerminalsState) => {
			state.selectedTerminalRequestsFilterState = {...state.selectedTerminalRequestsFilterState, statuses: []};
			state.terminalRequestsFilterState = {...state.terminalRequestsFilterState, statuses: []};
		},
		resetTerminalRequestFilterState: (state: TerminalsState) => {
			state.terminalRequestsFilterState = {
				offset: 0,
				statuses: [],
				purchaseTypes: [],
			};
			state.selectedTerminalRequestsFilterState = {
				offset: 0,
				statuses: [],
				purchaseTypes: [],
			};
		},
		resetAllTerminalRequestData: (state: TerminalsState) => {
			state.requestedTerminals = state.initRequestedTerminals;
			state.terminalRequestsListTotal = state.initTerminalRequestsListTotal;
			state.terminalRequestsListGroupSize = state.initTerminalRequestsListGroupSize;
			state.isTerminalRequestsPaginationLoading = false;
			state.isExportTerminalRequestsLoading = false;
			state.terminalRequestsPaginationOffset = 0;
			state.terminalRequestsFilterState = {
				offset: 0,
				statuses: [],
				purchaseTypes: [],
			};
			state.selectedTerminalRequestsFilterState = {
				offset: 0,
				statuses: [],
				purchaseTypes: [],
			};
		},

		resetAllTerminalData: (state: TerminalsState) => {
			//<===for terminal stages and inner stages===>
			state.terminalStage = TerminalsStageType.ALL_TERMINALS;
			state.allTerminalStage = AllTerminalsStageType.INITIAL;
			state.specificTerminalStage = SpecificTerminalStageType.TERMINAL_TRANSACTIONS;
			state.selectedTerminalNameInTerminalStage = null;

			//<===for terminals in general===>
			state.initMeta = null;
			state.merchant = null;
			state.terminals = [];
			state.initTerminals = [];
			state.initRequestedTerminals = [];

			//<===for terminal modals===>
			state.isSettingUpTerminalModalOpen = false;
			state.isRequestingTerminalModalOpen = false;

			//<===for terminal filter===>
			state.isExportTerminalsLoading = false;
			state.terminalListTotal = 0;
			state.terminalListGroupSize = 0;
			state.terminalPaginationOffset = 0;
			state.terminalFilterState = {
				offset: 0,
				query: "",
				groupId: "",
			};
			state.selectedTerminalFilterState = {
				offset: 0,
				query: "",
				groupId: "",
			};

			//<===for terminal request filter===>
			state.isTerminalRequestsPaginationLoading = false;
			state.isExportTerminalRequestsLoading = false;
			state.terminalRequestsListTotal = 0;
			state.terminalRequestsListGroupSize = 0;
			state.terminalRequestsPaginationOffset = 0;
			state.terminalRequestsFilterState = {offset: 0, statuses: [], purchaseTypes: []};
			state.selectedTerminalRequestsFilterState = {
				offset: 0,
				statuses: [],
				purchaseTypes: [],
			};
		},
	},
});

export const {
	setTerminalStage,
	setSpecificTerminalStage,
	setAllTerminalStageToInitial,
	setAllTerminalStageToSpecificTerminal,
	resetAllTerminalStageToSpecificTerminal,
	setInitTerminalDetails,
	setTerminals,
	setRequestedTerminal,
	setRequestedTerminals,
	setIsSettingUpTerminalModalOpen,
	setIsRequestingTerminalModalOpen,

	//terminal filter
	setIsExportTerminalsLoading,
	setTerminalListTotal,
	setTerminalPaginationOffset,
	setTerminalListGroupSize,
	setTerminalFilterState,
	setSelectedTerminalFilterState,
	setIsTerminalPaginationLoading,
	setTerminalQueryFilter,
	setTerminalGroupIdFilter,
	resetTerminalQueryFilterState,
	resetTerminalGroupIdFilterState,
	resetTerminalFilterState,
	resetAllTerminalData,

	//terminal request filter
	setSelectedTerminalRequestsFilterState,
	setIsTerminalRequestsPaginationLoading,
	setIsExportTerminalRequestsLoading,
	setTerminalRequestsListTotal,
	updateTerminalRequestsListTotalByOne,
	setTerminalRequestsPaginationOffset,
	setTerminalRequestsListGroupSize,
	setTerminalRequestsFilterState,
	setRequestPurchaseTypesFilter,
	setRequestStatusesFilter,
	resetAllTerminalRequestData,
	resetRequestPurchaseTypesState,
	resetRequestStatusesState,
	resetTerminalRequestFilterState,
} = terminalsSlice.actions;

export default terminalsSlice.reducer;
