import {Dispatch, MutableRefObject, SetStateAction, useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {IRootState} from "../../../../../../../redux/rootReducer";
import {MainInitState} from "../../../../../../../redux/init/slice/initSlice.types";
import Transaction from "../../../../../../../models/transaction";
import {hideTransactionDetails} from "../../../../../../../redux/transaction/slice/transactionSlice";
// import {setGlobalActiveDataType} from "../../../../../../../redux/init/slice/initSlice";
import useClickOutside from "../../../../../../../hooks/useClickOutside";

export interface UseTransactionDetailsCardResponse {
	transaction: Transaction | undefined;
	canShowTransaction: boolean;
	handleCloseTransactionDetailCard: () => void;
	canApproveTransaction: boolean;

	showTagRecipientModal: boolean;
	setShowTagRecipientModal: Dispatch<SetStateAction<boolean>>;

	domNode: MutableRefObject<HTMLDivElement | null>;
}

function useTransactionDetailsCard(): UseTransactionDetailsCardResponse {
	const dispatch = useDispatch();

	const canShowTransaction = useSelector<IRootState, boolean>((state) => {
		return !!state.transaction.selectedTransactionId && state.transaction.transactions.has(state.transaction.selectedTransactionId);
	});

	const transaction = useSelector<IRootState, Transaction | undefined>((state) => {
		return state.transaction.transactions.get(state.transaction.selectedTransactionId || "");
	});

	const mainState = useSelector<IRootState, MainInitState | null>((state) => state.init.main);

	const [showTagRecipientModal, setShowTagRecipientModal] = useState<boolean>(false);
	const [canApproveTransaction, setCanApproveTransaction] = useState<boolean>(false);

	const handleCloseTransactionDetailCard = useCallback(() => {
		dispatch(hideTransactionDetails());
		// dispatch(setGlobalActiveDataType(""));
	}, [dispatch]);

	useEffect(() => {
		if (mainState && transaction && transaction.isDebit() && transaction.origination && transaction.origination.userAccount) {
			const user = mainState.companyDetails.user;
			const accountsMeta = mainState.companyDetails.userAccountsMeta;
			const accountMeta = accountsMeta.find((meta) => {
				return meta.id === user.id && meta.userAccountId === transaction.origination?.userAccount?.id;
			});
			if (accountMeta && accountMeta.canApprove()) {
				setCanApproveTransaction(true);
			}
		} else {
			setCanApproveTransaction(false);
		}
	}, [transaction, mainState]);

	useEffect(() => {
		const myDiv = document.getElementById("scroller");
		if (myDiv === null) return;
		myDiv.scrollTop = 0;
	}, [transaction]);

	// const domNode = useRef<HTMLDivElement | null>(null);

	const domNode = useClickOutside(
		() => {
			handleCloseTransactionDetailCard();
		},
		(eventTarget: HTMLElement) => !!(eventTarget.dataset.type !== "transaction" && !eventTarget.dataset.type?.includes("transaction")),
		["mousedown", "keydown"]
	);

	// useEffect(() => {
	// const maybeHandler = (event: MouseEvent | KeyboardEvent) => {
	// const target: Node | null = event.target as Node | null;
	// if (!(target instanceof HTMLElement)) {
	// return;
	// }
	// if (event instanceof MouseEvent) {
	// if (
	// target.dataset.type !== "transaction" &&
	// target.dataset.type !== "transaction-active" &&
	// domNode.current &&
	// !domNode.current.contains(target)
	// ) {
	// handleCloseTransactionDetailCard();
	// }
	// if (target.dataset.type !== "transaction-active" && domNode.current && !domNode.current.contains(target)) {
	// const myDiv = document.getElementById("scroller");
	// if (myDiv === null) return;
	// myDiv.scrollTop = 0;
	// }
	// }
	// if (event instanceof KeyboardEvent) {
	// if (event.key === "Escape") {
	// handleCloseTransactionDetailCard();
	// }
	// if (event.key !== "Escape") {
	// return;
	// }
	// }
	// };
	// document.addEventListener("mousedown", maybeHandler);
	// document.addEventListener("keydown", maybeHandler);
	// return () => {
	// document.removeEventListener("keydown", maybeHandler);
	// document.removeEventListener("mousedown", maybeHandler);
	// };
	// });

	return {
		transaction,
		canShowTransaction,
		handleCloseTransactionDetailCard,
		canApproveTransaction,

		showTagRecipientModal,
		setShowTagRecipientModal,

		domNode,
	};
}

export default useTransactionDetailsCard;
