import {
	inviteLoginData,
	inviteLoginResponse,
	inviteOtpData,
	inviteOtpDataResponse,
	loginData,
	loginDataResponse,
	otpData,
	otpDataResponse,
	verifyInviteLoginData,
	verifyInviteLoginDataResponse,
} from "./LoginApi.types";
import {makeRequest, makeRequestWithSignal} from "../../../../helpers/request/makeRequest";

import Parsers from "../../../../utils/parsers";
import {abortRequest, getAbortControllerSignal} from "../../../../helpers/request/abortControllers";

export enum LoginRequestType {
	OTP = "login.otp",
	INVITE_OTP = "login.invite-otp",
}

export const login = async (data: loginData): Promise<loginDataResponse> => {
	const res = await makeRequest("/accounts/login", data);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		isAuthAppSetup: Parsers.boolean(res.isAuthAppSetup),
		name: Parsers.string(res.name),
		otp: Parsers.boolean(res.otp),
	};
};

export const otp = async (data: otpData): Promise<otpDataResponse> => {
	const signal = getAbortControllerSignal(LoginRequestType.OTP);

	const res = await makeRequestWithSignal("accounts/login/otp", data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		isAuthAppSetup: Parsers.boolean(res.isAuthAppSetup),
		name: Parsers.string(res.name),
		otp: Parsers.boolean(res.otp),
	};
};

export const inviteLogin = async (data: inviteLoginData): Promise<inviteLoginResponse> => {
	const res = await makeRequest("accounts/login/invite", data);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		isAuthAppSetup: Parsers.boolean(res.isAuthAppSetup),
		name: Parsers.string(res.name),
		otp: Parsers.boolean(res.otp),
	};
};

export const inviteOtp = async (data: inviteOtpData): Promise<inviteOtpDataResponse> => {
	const signal = getAbortControllerSignal(LoginRequestType.INVITE_OTP);

	const res = await makeRequestWithSignal("/accounts/login/invite", data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		isAuthAppSetup: Parsers.boolean(res.isAuthAppSetup),
		name: Parsers.string(res.name),
		otp: Parsers.boolean(res.otp),
	};
};

export const verifyInvite = async (data: verifyInviteLoginData): Promise<verifyInviteLoginDataResponse> => {
	const res = await makeRequest(`/accounts/${data.auth}/invite/init`, data);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		firstName: Parsers.string(res.firstName),
		lastName: Parsers.string(res.lastName),
		email: Parsers.string(res.email),
		businessName: Parsers.string(res.businessName),
		phone: Parsers.string(res.phone),
	};
};

export const abortLoginOtp = (): void => {
	abortRequest(LoginRequestType.OTP);
	abortRequest(LoginRequestType.INVITE_OTP);
};
