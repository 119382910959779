import {
	OtpInitRequest,
	OtpInitResponse,
	RecipientAutocompleteRequest,
	RecipientAutocompleteResponse,
	SinglePaymentInitRequest,
	SinglePaymentInitResponse,
	VerifyAccountRequest,
	VerifyAccountResponse,
} from "./singleTransferApi.types";

import BankAccount from "../../../../../models/bankAccount";
import CustomerAccount from "../../../../../models/customerAccount";
import {OtpActivityType} from "../../../../../helpers/AppConstants";
import Parsers from "../../../../../utils/parsers";
import Transaction from "../../../../../models/transaction";
import UserAccount from "../../../../../models/userAccount";
import {makeRequest, makeRequestWithSignal} from "../../../../../helpers/request/makeRequest";
import {abortRequest, getAbortControllerSignal} from "../../../../../helpers/request/abortControllers";
import Card from "../../../../../models/card";

export enum SingleTransferRequestType {
	INIT = "single-transfer.init",
	VERIFY_ACCOUNT = "single-transfer.verify-account",
	RECIPIENT_AUTOCOMPLETE = "single-transfer.recipient-autocomplete",
}

export const singlePaymentInit = async (data: SinglePaymentInitRequest): Promise<SinglePaymentInitResponse> => {
	const signal = getAbortControllerSignal(SingleTransferRequestType.INIT);
	// spread out data to ensure that customerAccountId always has a value
	const requestData: Omit<SinglePaymentInitRequest, "companyId"> = {
		originatingAccountId: data.originatingAccountId,
		recipient: {
			bankAccountId: data.recipient.bankAccountId,
			customerAccountId: data.recipient.customerAccountId || "",
			accountNumber: data.recipient.accountNumber,
			bankCode: data.recipient.bankCode,
		},
		amount: data.amount,
		purpose: data.purpose,
		otp: data.otp,
	};
	const res = await makeRequestWithSignal("/main/payments/initiate", requestData, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		transaction: Parsers.classObjectNonNullable(res.transaction, Transaction),
		isApproved: Parsers.boolean(res.isApproved),
		processing: Parsers.boolean(res.processing),
		accountsToActivate: Parsers.stringArray(res.accountsToActivate),
	};
};

export const verifyAccount = async (data: VerifyAccountRequest): Promise<VerifyAccountResponse> => {
	const signal = getAbortControllerSignal(SingleTransferRequestType.VERIFY_ACCOUNT);
	const res = await makeRequestWithSignal("/main/payments/initiate/verify-account", data, signal);
	// const res = await makeRequest("/main/payments/initiate/verify-account", data);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		bankAccount: Parsers.classObjectNonNullable(res.bankAccount, BankAccount),
	};
};

export const recipientAutocomplete = async (data: RecipientAutocompleteRequest): Promise<RecipientAutocompleteResponse> => {
	const signal = getAbortControllerSignal(SingleTransferRequestType.RECIPIENT_AUTOCOMPLETE);
	const res = await makeRequestWithSignal("/main/payments/initiate/recipients/autocomplete", data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		userAccounts: Parsers.classObjectArray(res.accounts, UserAccount),
		customerAccounts: Parsers.classObjectArray(res.customerAccounts, CustomerAccount),
		cards: Parsers.classObjectArray(res.cards, Card),
	};
};

export const otpInit = async (data: OtpInitRequest): Promise<OtpInitResponse> => {
	const res = await makeRequest("/main/generate-otp", data);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		email: Parsers.string(res.email),
		phone: Parsers.string(res.phone),
	};
};

export async function generateOtp(action: OtpActivityType): Promise<boolean> {
	const res = await makeRequest("/main/generate-otp", {action});
	if (res instanceof Error) {
		throw res;
	}
	return Parsers.boolean(res.success);
}

export function abortSingleTransferRequest(type: SingleTransferRequestType): void {
	abortRequest(type);
}

export function abortAllSingleTransferRequests(): void {
	Object.values(SingleTransferRequestType).forEach((type) => abortRequest(type));
}
