import React, {useEffect, useLayoutEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import FundCardHeaders from "./FundCardHeaders";
import {IRootState} from "../../../../../../redux/rootReducer";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import UserAccountMeta from "../../../../../../models/userAccountMeta";
import formatNumber from "../../../../../../utils/formatNumber";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import useFundCard from "../../../Hooks/State/SendMoney/FundCard/useFundCard";

function ReviewPayment(): JSX.Element {
	const dispatch = useDispatch();

	const {isFundCardLoading, isFundCardResponse, handleFundCard} = useFundCard();

	const fundCardDetails = useSelector((state: IRootState) => state.fundCard.fundCardDetails);
	const userAccountsMeta = useSelector((state: IRootState) => state.init.main?.companyDetails.userAccountsMeta);
	const selectedUserAccount = useSelector((state: IRootState) => state.fundCard.selectedUserAccount);
	const recipientCardDetails = useSelector((state: IRootState) => state.fundCard.recipientCardDetails);

	const [canApprove, setCanApprove] = useState(false);

	useLayoutEffect(() => {
		if (!userAccountsMeta || !selectedUserAccount) return;
		const doesAccountExist = userAccountsMeta.some((userAccountMeta) => userAccountMeta.userAccountId === selectedUserAccount.id);
		if (!isNullOrUndefined(doesAccountExist)) {
			const userAccountMeta = userAccountsMeta.find((_el) => _el.userAccountId === selectedUserAccount.id) as UserAccountMeta;
			if (userAccountMeta.isApprover) {
				setCanApprove(true);
			} else {
				setCanApprove(false);
			}
		}
	}, [userAccountsMeta, selectedUserAccount]);

	useEffect(() => {
		if (canApprove || !isFundCardResponse) return;
		dispatch(setPaymentStage(PaymentStageType.PROCESS_PAYMENT));
	}, [canApprove, isFundCardResponse]);

	return (
		<>
			<div className="flex h-full w-full flex-col pb-20">
				<div className="flex w-full flex-row">
					<div className="mx-auto flex w-full max-w-md flex-col lg:mx-0">
						<div>
							<FundCardHeaders />
							<div className=" w-full ">
								<div className="flex flex-col items-center justify-center ">
									<span className="text-xs font-normal text-black-tertiary"> You&apos;re funding your card with </span>
									<div className="flex flex-row">
										<div className="text-4xl font-medium">
											<CurrencyCode />
											{formatNumber(fundCardDetails && fundCardDetails.amount)}
										</div>
									</div>
								</div>
							</div>
							<div className="flex w-full flex-col items-start justify-start space-y-8 pt-6">
								<div className="box-border w-full flex-col space-y-7 rounded-lg bg-blue-backdrop  p-5">
									<div className="flex flex-row justify-between  ">
										<span className="text-sm font-normal text-black-tertiary"> From</span>
										<div className="flex flex-col">
											<span className="text-sm"> {selectedUserAccount && selectedUserAccount?.bankAccount?.accountName}</span>
											<span className="text-right text-sm font-normal">
												{" "}
												{selectedUserAccount && selectedUserAccount?.bankAccount?.accountNumber}
											</span>
										</div>
									</div>
									<div className="flex flex-row justify-between ">
										<span className="text-sm font-normal text-black-tertiary">To</span>
										<div className="flex flex-col">
											<span className="text-right text-sm ">{recipientCardDetails?.name}</span>
											<span className="text-right  text-sm font-normal ">{recipientCardDetails?.maskedPan}</span>
										</div>
									</div>
									{fundCardDetails && fundCardDetails.purpose && (
										<div className="flex flex-row justify-between">
											<span className=" text-sm font-normal text-black-tertiary ">Purpose of payment</span>
											<span className=" text-right text-sm font-normal ">{fundCardDetails.purpose}</span>
										</div>
									)}
								</div>
							</div>

							<div className="flex w-full flex-row items-center justify-center space-x-4 pb-10 pt-8">
								<div>
									<ButtonComp
										type="button"
										ripple="light"
										buttonType="secondary"
										color="grey"
										func={() => dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS))}
									>
										<span>Back</span>
									</ButtonComp>
								</div>

								<div>
									<ButtonComp
										type="submit"
										color="blue"
										ripple="light"
										buttonType="primary"
										func={() => {
											if (canApprove) {
												dispatch(setPaymentStage(PaymentStageType.MAKE_PAYMENT));
											} else {
												void handleFundCard(fundCardDetails);
											}
										}}
										isLoading={isFundCardLoading}
									>
										{canApprove && "Fund Card"}
										{!canApprove && "Initiate Funding"}
									</ButtonComp>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ReviewPayment;
