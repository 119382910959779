import {
	NigeriaApplicationSectionType,
	NigeriaApplicationStagesType,
	NigeriaRegisteredSections,
	NigeriaUnregisteredSections,
} from "./nigeria-application/nigeria-application.types";
import {
	ZambiaApplicationSectionType,
	ZambiaApplicationStagesType,
	ZambiaRegisteredSections,
	ZambiaUnregisteredSections,
} from "./zambia-application/zambia-application.types";

export enum ApplicationSectionNumberType {
	INITIAL = 0,
	COMPANY = 1,
	SIGNATORY = 2,
	DOCUMENT = 3,
	AGREEMENT = 4,
	REFEREES = 5,

	//unregistered business
	BUSINESS = 1,
	PERSONAL = 2,
	IDENTITY = 3,
}

export enum ApplicationSectionStatusType {
	TODO = 1,
	UNDER_REVIEW = 2,
	REVIEWED = 3,
}

export enum ApplicationBusinessType {
	INITIAL = 0,
	REGISTERED = 1,
	UNREGISTERED = 2,
}

export type ApplicationNote = {
	note: string;
};

//external pages
export enum DirectorPageType {
	PERSONAL = "personal-details",
	NEXT_OF_KIN = "next-of-kin",
	MEANS_OF_ID = "means-of-id",
}

export enum GenericApplicationPageType {
	STATUS = "registration-status",
	REVIEW = "review",
	DONE = "done",
}

export type ApplicationStageType = NigeriaApplicationStagesType | ZambiaApplicationStagesType | undefined;
export type ApplicationSectionType = NigeriaApplicationSectionType | ZambiaApplicationSectionType | GenericApplicationPageType;

export const NigeriaSectionsType = {...NigeriaRegisteredSections, ...NigeriaUnregisteredSections};
export type NigeriaSectionsType = typeof NigeriaSectionsType;

export const ApplicationRegisteredSectionType = {...NigeriaRegisteredSections, ...ZambiaRegisteredSections};
export type ApplicationRegisteredSectionType = typeof ApplicationRegisteredSectionType;

export const ApplicationUnregisteredSectionType = {...NigeriaUnregisteredSections, ...ZambiaUnregisteredSections};
export type ApplicationUnregisteredSectionType = typeof ApplicationUnregisteredSectionType;
