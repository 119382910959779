export enum SignUpStages {
	INITIAL = 1,
	EMAIL_OTP = 2,
	SUCCESSFUL_VERIFICATION = 3,
}

export enum SignUpCountries {
	NIGERIA = "NG",
	ZAMBIA = "ZM",
}
