import {AddMoneySections} from "../../../../Hooks/State/AddMoney/addMoney.constants";
import {IRootState} from "../../../../../../../redux/rootReducer";
import React from "react";
import {useSelector} from "react-redux";

interface Props {
	title?: string;
	subtitle?: string;
}

function AddMoneyHeader(props: Props): JSX.Element {
	const addMoneyFundingType = useSelector((state: IRootState) => state.addMoney.fundingType);
	const addMoneyPaymentStage = useSelector((state: IRootState) => state.addMoney.fundingStage);

	return (
		<>
			<div className="flex w-full flex-col pb-4 pt-8 text-base">
				<span className="text-sm font-medium text-black-secondary md:text-base">
					{(addMoneyFundingType && AddMoneySections[addMoneyFundingType][addMoneyPaymentStage].text) || props.title}
				</span>
				<span className="text-xs text-black-tertiary md:text-sm">
					{(addMoneyFundingType && AddMoneySections[addMoneyFundingType][addMoneyPaymentStage].subtext) || props.subtitle}
				</span>
			</div>
		</>
	);
}

export default AddMoneyHeader;
