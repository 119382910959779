import {FilterDateType, FilterDateTypes} from "../../../components/General/Filter/filter.constant";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";
import {ZambiaPayoutsFilterTypes, ZambiaPayoutsState} from "./zambiaPayoutsSlice.ts.types";

import Transaction from "../../../models/transaction";
import {TransactionStatus} from "../../../models/transaction.constants";

const initialState: ZambiaPayoutsState = {
	zambiaPayoutsList: null,
	zambiaPayoutsDetail: null,
	zambiaCachedPayoutsList: [],

	isCustomModalOpen: false,
	isZambiaPayoutsListEmpty: false,
	isExportZambiaPayoutsLoading: false,
	isExportZambiaPayoutsTrayOpen: false,
	isZambiaPayoutsDetailCardOpen: false,
	isZambiaPayoutsPaginationLoading: false,

	zambiaPayoutsListTotal: 0,
	zambiaPayoutsListGroupSize: 0,
	zambiaPayoutsPaginationOffset: 0,

	zambiaPayoutsSearchQuery: "",

	zambiaPayoutsDate: FilterDateTypes.ALL_TIME,
	selectedZambiaPayoutsDate: FilterDateTypes.ALL_TIME,

	zambiaPayoutsFilterState: {
		query: "",
		offset: 0,
		statuses: [],
		userAccountIds: [],
		date: {
			end: "",
			begin: "",
		},
	},
	selectedZambiaPayoutsFilterState: {
		query: "",
		offset: 0,
		statuses: [],
		userAccountIds: [],
		date: {
			end: "",
			begin: "",
		},
	},
};

export const zambiaPayoutsSlice = createSlice({
	name: "zambiaPayouts",
	initialState,
	reducers: {
		setSelectedZambiaPayoutsDetails: (state: ZambiaPayoutsState, action: PayloadAction<string>) => {
			const payout = state.zambiaPayoutsList?.find((_sub) => _sub.id === action.payload);
			if (payout) {
				state.zambiaPayoutsDetail = payout;
			}
		},
		setIsZambiaPayoutsDetailCardOpen: (state: ZambiaPayoutsState, action: PayloadAction<boolean>) => {
			state.isZambiaPayoutsDetailCardOpen = action.payload;
		},

		setIsAddMoneyDateModalOpen: (state: ZambiaPayoutsState, action: PayloadAction<boolean>) => {
			state.isCustomModalOpen = action.payload;
		},
		setIsExportZambiaPayoutsTrayOpen: (state: ZambiaPayoutsState, action: PayloadAction<boolean>) => {
			state.isExportZambiaPayoutsTrayOpen = action.payload;
		},
		setIsExportZambiaPayoutsLoading: (state: ZambiaPayoutsState, action: PayloadAction<boolean>) => {
			state.isExportZambiaPayoutsLoading = action.payload;
		},

		setIsZambiaPayoutsPaginationLoading: (state: ZambiaPayoutsState, action: PayloadAction<boolean>) => {
			state.isZambiaPayoutsPaginationLoading = action.payload;
		},
		setIsZambiaPayoutsListEmpty: (state: ZambiaPayoutsState, action: PayloadAction<boolean>) => {
			state.isZambiaPayoutsListEmpty = action.payload;
		},
		setZambiaPayoutsDate: (state: ZambiaPayoutsState, action: PayloadAction<FilterDateType>) => {
			if (action.payload.date.begin && action.payload.date.end) {
				state.zambiaPayoutsFilterState = {
					...state.zambiaPayoutsFilterState,
					date: {
						begin: action.payload.date.begin,
						end: action.payload.date.end,
					},
				};
				state.zambiaPayoutsDate = action.payload.name;
			}
		},

		setSelectedZambiaPayoutsFilterState: (state: ZambiaPayoutsState) => {
			state.selectedZambiaPayoutsFilterState = {...state.zambiaPayoutsFilterState};
			state.selectedZambiaPayoutsDate = state.zambiaPayoutsDate;
		},
		// setSelectedTerminalTransactionFilterStateInstant: (
		// state: ZambiaPayoutsState,
		// action: PayloadAction<ZambiaPayoutsFilterTypes>
		// ) => {
		// state.selectedZambiaPayoutsFilterState = {...state.selectedZambiaPayoutsFilterState, ...action.payload};
		// state.zambiaPayoutsFilterState = {...state.zambiaPayoutsFilterState, ...action.payload};
		// },

		setZambiaPayoutsList: (state: ZambiaPayoutsState, action: PayloadAction<Transaction[]>) => {
			state.zambiaPayoutsList = action.payload;
			const uniqueList = new Map<string, Transaction>();
			const updatedArray = [...state.zambiaCachedPayoutsList, ...action.payload];
			updatedArray.forEach((item) => {
				uniqueList.set(item.id, item);
			});
			state.zambiaCachedPayoutsList = Array.from(uniqueList.values());
		},
		setZambiaPayoutsListTotal: (state: ZambiaPayoutsState, action: PayloadAction<number>) => {
			state.zambiaPayoutsListTotal = action.payload;
		},
		setZambiaPayoutsPaginationOffset: (state: ZambiaPayoutsState, action: PayloadAction<number>) => {
			state.zambiaPayoutsPaginationOffset = action.payload;
		},
		setZambiaPayoutsListGroupSize: (state: ZambiaPayoutsState, action: PayloadAction<number>) => {
			state.zambiaPayoutsListGroupSize = action.payload;
		},
		setZambiaPayoutsFilterState: (state: ZambiaPayoutsState, action: PayloadAction<ZambiaPayoutsFilterTypes>) => {
			state.zambiaPayoutsFilterState = {...state.zambiaPayoutsFilterState, ...action.payload};
		},
		setZambiaPayoutsDateFilter: (state: ZambiaPayoutsState) => {
			state.selectedZambiaPayoutsFilterState = {
				...state.selectedZambiaPayoutsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.zambiaPayoutsFilterState = {
				...state.zambiaPayoutsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedZambiaPayoutsDate = FilterDateTypes.ALL_TIME;
			state.zambiaPayoutsDate = FilterDateTypes.ALL_TIME;
		},

		setZambiaPayoutsStatusFilter: (state: ZambiaPayoutsState, action: PayloadAction<TransactionStatus>) => {
			state.selectedZambiaPayoutsFilterState.statuses = state.selectedZambiaPayoutsFilterState.statuses.filter(
				(_status) => _status !== action.payload
			);
			state.zambiaPayoutsFilterState.statuses = state.zambiaPayoutsFilterState.statuses.filter((_status) => _status !== action.payload);
		},
		setZambiaPayoutsUserAccountIdsFilter: (state: ZambiaPayoutsState, action: PayloadAction<string>) => {
			state.selectedZambiaPayoutsFilterState.userAccountIds = state.selectedZambiaPayoutsFilterState.userAccountIds.filter(
				(_userAccountId) => _userAccountId !== action.payload
			);
			state.zambiaPayoutsFilterState.userAccountIds = state.zambiaPayoutsFilterState.userAccountIds.filter(
				(_userAccountId) => _userAccountId !== action.payload
			);
		},
		setSelectedZambiaPayoutsFilterStateInstant: (state: ZambiaPayoutsState, action: PayloadAction<ZambiaPayoutsFilterTypes>) => {
			state.selectedZambiaPayoutsFilterState = {...state.selectedZambiaPayoutsFilterState, ...action.payload};
			state.zambiaPayoutsFilterState = {...state.zambiaPayoutsFilterState, ...action.payload};
		},

		resetZambiaPayoutsList: (state: ZambiaPayoutsState) => {
			state.zambiaPayoutsList = null;
		},
		resetZambiaPayoutsDateFilterState: (state: ZambiaPayoutsState) => {
			state.selectedZambiaPayoutsFilterState = {
				...state.selectedZambiaPayoutsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.zambiaPayoutsFilterState = {
				...state.zambiaPayoutsFilterState,
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedZambiaPayoutsDate = FilterDateTypes.ALL_TIME;
			state.zambiaPayoutsDate = FilterDateTypes.ALL_TIME;
		},
		resetZambiaPayoutsStatusFilterState: (state: ZambiaPayoutsState) => {
			state.selectedZambiaPayoutsFilterState = {...state.selectedZambiaPayoutsFilterState, statuses: []};
			state.zambiaPayoutsFilterState = {...state.zambiaPayoutsFilterState, statuses: []};
		},
		resetZambiaPayoutsUserAccountIdsFilterState: (state: ZambiaPayoutsState) => {
			state.selectedZambiaPayoutsFilterState = {...state.selectedZambiaPayoutsFilterState, userAccountIds: []};
			state.zambiaPayoutsFilterState = {...state.zambiaPayoutsFilterState, userAccountIds: []};
		},
		resetZambiaPayoutsQueryFilterState: (state: ZambiaPayoutsState) => {
			state.selectedZambiaPayoutsFilterState = {...state.selectedZambiaPayoutsFilterState, query: ""};
			state.zambiaPayoutsFilterState = {...state.zambiaPayoutsFilterState, query: ""};
		},
		resetZambiaPayoutsFilterState: (state: ZambiaPayoutsState) => {
			state.zambiaPayoutsFilterState = {
				query: "",
				offset: 0,
				date: {
					begin: "",
					end: "",
				},
				userAccountIds: [],
				statuses: [],
			};
			state.selectedZambiaPayoutsFilterState = {
				query: "",
				offset: 0,
				date: {
					begin: "",
					end: "",
				},
				userAccountIds: [],
				statuses: [],
			};
		},
		resetAllZambiaPayoutsData: (state: ZambiaPayoutsState) => {
			state.zambiaPayoutsDetail = null;
			state.isZambiaPayoutsDetailCardOpen = false;
			state.isExportZambiaPayoutsTrayOpen = false;
			state.zambiaPayoutsList = null;
			state.zambiaPayoutsListTotal = 0;
			state.zambiaPayoutsPaginationOffset = 0;
			state.zambiaPayoutsListGroupSize = 0;
			state.isExportZambiaPayoutsLoading = false;
			state.isZambiaPayoutsListEmpty = false;
			state.zambiaPayoutsSearchQuery = "";
			state.isZambiaPayoutsPaginationLoading = false;
			state.zambiaPayoutsDate = FilterDateTypes.ALL_TIME;
			state.selectedZambiaPayoutsDate = FilterDateTypes.ALL_TIME;
			state.isCustomModalOpen = false;
			state.zambiaPayoutsFilterState = {
				query: "",
				statuses: [],
				offset: 0,
				userAccountIds: [],
				date: {
					begin: "",
					end: "",
				},
			};
			state.selectedZambiaPayoutsFilterState = {
				query: "",
				offset: 0,
				statuses: [],
				userAccountIds: [],
				date: {
					begin: "",
					end: "",
				},
			};
		},
		resetZambiaCachedPayouts: (state: ZambiaPayoutsState) => {
			state.zambiaCachedPayoutsList = [];
		},
	},
});

export const {
	setIsAddMoneyDateModalOpen,
	setZambiaPayoutsDate,
	setZambiaPayoutsList,
	setZambiaPayoutsListTotal,
	setZambiaPayoutsDateFilter,
	setZambiaPayoutsFilterState,
	setIsZambiaPayoutsListEmpty,
	setZambiaPayoutsListGroupSize,
	setZambiaPayoutsUserAccountIdsFilter,
	setZambiaPayoutsStatusFilter,
	setIsExportZambiaPayoutsLoading,
	setSelectedZambiaPayoutsDetails,
	setIsZambiaPayoutsDetailCardOpen,
	setZambiaPayoutsPaginationOffset,
	setIsExportZambiaPayoutsTrayOpen,
	setSelectedZambiaPayoutsFilterState,
	setIsZambiaPayoutsPaginationLoading,
	setSelectedZambiaPayoutsFilterStateInstant,

	resetZambiaPayoutsList,
	resetZambiaCachedPayouts,
	resetAllZambiaPayoutsData,
	resetZambiaPayoutsFilterState,
	resetZambiaPayoutsDateFilterState,
	resetZambiaPayoutsQueryFilterState,
	resetZambiaPayoutsUserAccountIdsFilterState,
	resetZambiaPayoutsStatusFilterState,
} = zambiaPayoutsSlice.actions;

export default zambiaPayoutsSlice.reducer;
