import Clipboard from "../../../../../../../../components/General/Clipboard/Clipboard";
import React from "react";
import titleCase from "../../../../../../../../hooks/titleCase";

interface AddMoneyCardProps {
	accountNumber: string;
	medium: string;
	accountName: string;
	branch?: string;
	swift?: string;
}

function AddMoneyCard(props: AddMoneyCardProps): JSX.Element {
	return (
		<div className="relative box-border flex w-full flex-row items-center justify-between space-x-4 rounded-lg bg-grey-backdrop p-8">
			<div className="flex flex-col">
				<p className="flex flex-row whitespace-nowrap pr-8 text-2xl font-normal text-black">{titleCase(props.accountNumber || "")}</p>
				<p className="text-sm font-normal text-black-secondary">{props.medium}</p>
				<p className="text-sm font-normal text-black-secondary">{props.accountName}</p>
				{props.branch && <p className="text-sm font-normal text-black-secondary">{props.branch}</p>}
				{props.swift && <p className="text-sm font-normal text-black-secondary">{props.swift}</p>}
			</div>

			<div className="flex flex-col items-center justify-center">
				<Clipboard text={props.accountNumber || ""} />
			</div>
		</div>
	);
}

export default AddMoneyCard;
