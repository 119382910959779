import {RecipientActionType, RecipientsState} from "./recipientsSlice.types";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Recipient} from "../../../../modules/Dashboard/Payments/Hooks/State/PendingApproval/useSavedRecipientsState.types";
import produce from "immer";

const initialState: RecipientsState = {
	recipients: new Map<string, Recipient>(),
	pagination: {
		total: 0,
		groupSize: 0,
	},
	action: {
		type: undefined,
		customerAccountId: undefined,
	},
};

export const recipientsSlice = createSlice({
	name: "recipients",
	initialState,
	reducers: {
		setRecipients: (state: RecipientsState, action: PayloadAction<Recipient[]>) => {
			state.recipients = new Map<string, Recipient>(
				action.payload.reduce((acc: Array<[string, Recipient]>, item: Recipient): Array<[string, Recipient]> => {
					acc.push([item.customerAccount.id, item]);
					return acc;
				}, [])
			);
		},
		clearRecipients: (state: RecipientsState) => {
			state.recipients = produce(state.recipients, (draft) => draft.clear());
		},
		updateRecipient: (state: RecipientsState, action: PayloadAction<Recipient>) => {
			state.recipients = produce(state.recipients, (draft) => {
				draft.set(action.payload.customerAccount.id, action.payload);
			});
		},
		removeRecipient: (state: RecipientsState, action: PayloadAction<string>) => {
			state.recipients = produce(state.recipients, (draft) => {
				draft.delete(action.payload);
			});
		},

		updatePagination: (state: RecipientsState, action: PayloadAction<{total: number; groupSize: number}>) => {
			state.pagination = action.payload;
		},

		setRecipientsAction: (state: RecipientsState, action: PayloadAction<{type: RecipientActionType; recipient: Recipient}>) => {
			state.action = produce(state.action, (draft) => {
				draft.type = action.payload.type;
				draft.customerAccountId = action.payload.recipient.customerAccount.id;
			});
		},
		clearRecipientsAction: (state: RecipientsState) => {
			state.action = produce(state.action, (draft) => {
				draft.type = undefined;
				draft.customerAccountId = undefined;
			});
		},
	},
});

export const {setRecipients, clearRecipients, updateRecipient, removeRecipient, updatePagination, setRecipientsAction, clearRecipientsAction} =
	recipientsSlice.actions;

export default recipientsSlice.reducer;
