import React, {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import AccountNumberDropdown from "../../DropDowns/AccountNumberDropdown";
import ButtonComp from "../../../../../../components/General/Buttons/ButtonComp";
import CurrencyCode from "../../../../../../components/General/CurrencyCode";
import ErrorCard from "../../Cards/ErrorCard";
import {IRootState} from "../../../../../../redux/rootReducer";
import MessageToasts from "../../../../../../components/General/MessageToasts/MessageToasts";
import Money from "../../../../../../components/General/Money";
import MoneyInput from "../../../../../../components/General/Inputs/MoneyInput";
import {PaymentStageType} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import TextArea from "../../../../../../components/General/TextArea/TextArea";
import {ToastType} from "../../../../../../helpers/AppConstants";
import TransferBetweenAccountPaymentHeaders from "./TransferBetweenAccountPaymentHeaders";
import isNullOrUndefined from "../../../../../../utils/isNullOrUndefined";
import {resetAllTransferBetweenData} from "../../../../../../redux/payments/transferBetween/slice/transferBetweenSlice";
import {setPaymentStage} from "../../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {useHistory} from "react-router";
import {useMoneyToNumber} from "../../../../../../hooks/useMoneyToNumber";
import useTransferBetweenAccountNumberAutocomplete from "../../../Hooks/State/SendMoney/TransferBetween/useTransferBetweenAccountNumberAutocomplete";
import useTransferBetweenAmount from "../../../Hooks/State/SendMoney/TransferBetween/useTransferBetweenAmount";
import useTransferBetweenPayFrom from "../../../Hooks/State/SendMoney/TransferBetween/useTransferBetweenPayFrom";
import useTransferBetweenPurpose from "../../../Hooks/State/SendMoney/TransferBetween/useTransferBetweenPurpose";

function PaymentDetails(): JSX.Element {
	const dispatch = useDispatch();
	const history = useHistory();
	const payTo = useSelector((state: IRootState) => state.sendMoney.payTo);
	const pageFrom = useSelector((state: IRootState) => state.sendMoney.pageFrom);
	// const isZambia = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isZambia);
	const selectedCard = useSelector((state: IRootState) => state.transferBetween.selectedCard);
	const cardBalances = useSelector((state: IRootState) => state.transferBetween.cardBalances);
	const selectedUserAccount = useSelector((state: IRootState) => state.transferBetween.selectedUserAccount);
	const recipientBankAccount = useSelector((state: IRootState) => state.transferBetween.recipientBankAccount);
	const transferBetweenDetails = useSelector((state: IRootState) => state.transferBetween.transferBetweenDetails);

	const {handleAmountChange} = useTransferBetweenAmount();
	const {handlePurposeChange} = useTransferBetweenPurpose();
	const {getPayToOptions, handleSelectRecipient} = useTransferBetweenAccountNumberAutocomplete();
	const {getPayFromOptions, currentUserAccountMeta, isCardBalanceLoading, handleSelectAccount} = useTransferBetweenPayFrom();

	const isCannotSend = selectedUserAccount?.accountRestrictions.cannotSendMoney;
	const isOnlySendToSpecificAccounts = selectedUserAccount?.accountRestrictions.canSendMoneyToSpecificAccounts;

	const cardBalance = cardBalances.get(selectedCard?.createRequestId || "");
	const accountBalance = selectedUserAccount?.balance;

	const isInsufficientFunds =
		selectedUserAccount && !isNullOrUndefined(accountBalance) ? useMoneyToNumber(transferBetweenDetails.amount) > accountBalance : false;
	const isInsufficientCardFunds =
		selectedCard && !isNullOrUndefined(cardBalance) ? useMoneyToNumber(transferBetweenDetails.amount) > cardBalance : false;

	const handleBack = useCallback(() => {
		if (pageFrom && payTo) {
			history.push({
				pathname: `/${pageFrom}`,
				state: {
					cardId: payTo,
				},
			});
		}
		dispatch(setPaymentStage(PaymentStageType.INITIAL));
		dispatch(resetAllTransferBetweenData());
	}, []);

	return (
		<>
			<div className=" flex w-full flex-col items-center justify-center lg:items-start lg:justify-start ">
				<div className="w-full max-w-sm">
					<TransferBetweenAccountPaymentHeaders />
					<div className="w-full">
						<div className="flex w-full flex-col justify-between">
							<div className="flex min-h-72 flex-col items-start space-y-4 pt-2 text-base font-normal  md:items-center md:px-0 lg:items-center lg:px-0">
								<div className="flex w-full flex-col space-y-4 max-w-sm">
									<MoneyInput
										placeholder="Amount"
										icon={<CurrencyCode />}
										value={transferBetweenDetails.amount || ""}
										onChange={handleAmountChange}
									/>
									<div className="w-full">
										<AccountNumberDropdown
											data={getPayFromOptions}
											inputValue={selectedCard?.name || selectedUserAccount?.accountName || ""}
											placeholder="Pay from"
											onClickFunc={(e) => handleSelectAccount(e.id)}
											isSearchable={!!getPayFromOptions && getPayFromOptions.length > 4}
											isLoading={isCardBalanceLoading}
											isInputDisabled
											clickAndClose
											showBalance
											isPayFrom
											showArrow
										/>
										{selectedUserAccount && !isNullOrUndefined(accountBalance) && (
											<div className="w-full text-left text-xs text-black-tertiary pt-2">
												Available Balance: <Money amount={accountBalance} />
											</div>
										)}
										{selectedCard && !isNullOrUndefined(cardBalance) && (
											<div className="w-full text-left text-xs text-black-tertiary pt-2">
												Available Balance: <Money amount={cardBalance} />
											</div>
										)}
									</div>

									{isOnlySendToSpecificAccounts && (
										<MessageToasts toastMessage="You can only send to specific recipients" toastType={ToastType.INFORMATION} />
									)}
									{isCannotSend && (
										<MessageToasts toastMessage="You can not send money from this account" toastType={ToastType.ERROR} />
									)}
									<AccountNumberDropdown
										data={getPayToOptions}
										inputValue={recipientBankAccount?.accountName || ""}
										placeholder={isOnlySendToSpecificAccounts ? "Select Recipient" : "Pay To"}
										onClickFunc={(e) => handleSelectRecipient(e.id)}
										isSearchable={!!getPayToOptions && getPayToOptions.length > 4}
										isDisabled={isCannotSend}
										isInputDisabled
										clickAndClose
										showArrow
									/>
									<TextArea
										placeholder="Purpose of payment"
										value={transferBetweenDetails.purpose}
										onChangeFunc={handlePurposeChange}
									/>
								</div>
								{/* {isZambia &&
									useMoneyToNumber(transferBetweenDetails.amount) > 0 &&
									useMoneyToNumber(transferBetweenDetails.amount) < 5 && <ErrorCard isZambiaMinAmount />} */}
								{/* {isZambia && useMoneyToNumber(transferBetweenDetails.amount) > 100000 && <ErrorCard isZambiaMaxAmount />} */}
								{currentUserAccountMeta?.maxApprovalAmount && !(currentUserAccountMeta?.maxApprovalAmount === 0) && (
									<ErrorCard maxApprovalAmount={currentUserAccountMeta?.maxApprovalAmount} isApprovalError />
								)}
								{isInsufficientFunds && selectedUserAccount && !isNullOrUndefined(accountBalance) && (
									<ErrorCard balance={accountBalance} isInsufficientFunds />
								)}
								{isInsufficientCardFunds && selectedCard && !isNullOrUndefined(cardBalance) && (
									<ErrorCard balance={cardBalance} isInsufficientFunds />
								)}
							</div>

							<div className="flex w-full flex-row items-center justify-center space-x-4 pb-8 pt-8">
								<ButtonComp type="button" ripple="light" buttonType="secondary" color="grey" func={handleBack}>
									<span>Back</span>
								</ButtonComp>

								<ButtonComp
									type="submit"
									color="blue"
									ripple="light"
									buttonType="primary"
									disable={
										isCannotSend ||
										isInsufficientFunds ||
										isCardBalanceLoading ||
										!recipientBankAccount ||
										isInsufficientCardFunds ||
										!transferBetweenDetails.amount ||
										!transferBetweenDetails.purpose ||
										useMoneyToNumber(transferBetweenDetails.amount) === 0
									}
									func={() => dispatch(setPaymentStage(PaymentStageType.REVIEW_PAYMENT))}
								>
									<span>Next</span>
								</ButtonComp>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PaymentDetails;
