import {AccountInitState, InitState, MainInitCompanyDetailsInterface, MainInitState} from "./initSlice.types";
import {PayloadAction, createSlice} from "@reduxjs/toolkit";

import AccountOpeningProgress from "../../../models/account-opening/progress/accountOpeningProgress";
import Card from "../../../models/card";
import {CompanyInterface} from "../../../helpers/types";
import TransactionCategory from "../../../models/transactionCategory";
import User from "../../../models/user";
import UserAccount from "../../../models/userAccount";
import UserApp from "../../../models/userApp";

// import {handleUpdateAddNewCompany} from "../utils/init.util";

const initialState: InitState = {
	main: null,
	error: null,
	account: null,
	loading: false,
	isLoggedIn: false,
	accountOpening: null,
	mainInitLoading: false,
	isAccountCreated: false,
	selectedCompanyId: null,
	accountInitLoading: false,
	isSwitchingBusiness: false,
	canAccessCards: false,
	canAccessTerminals: false,
	globalActiveDataType: "",
};

export const initSlice = createSlice({
	name: "init",
	initialState,
	reducers: {
		setAccountCreated: (state: InitState, action: PayloadAction<boolean>) => {
			state.isAccountCreated = action.payload;
		},

		initRequest: (state: InitState) => {
			state.loading = true;
			state.accountInitLoading = true;
			state.error = null;
		},
		mainInitRequest: (state: InitState) => {
			state.mainInitLoading = true;
			state.loading = true;
			state.error = null;
		},
		accountInitFailure: (state: InitState, action: PayloadAction<string>) => {
			state.isLoggedIn = false;
			state.loading = false;
			state.accountInitLoading = false;
			state.account = null;
			state.error = action.payload;
		},
		accountInitSuccess: (
			state: InitState,
			action: PayloadAction<{
				account: AccountInitState;
				selectedCompanyId?: string | null;
			}>
		) => {
			state.isLoggedIn = true;
			state.accountInitLoading = false;
			state.account = action.payload.account;
			state.selectedCompanyId = action.payload.selectedCompanyId || null;
			state.error = null;
		},
		mainInitFailure: (state: InitState, action: PayloadAction<string>) => {
			state.mainInitLoading = false;
			state.loading = false;
			state.main = null;
			state.error = action.payload;
		},
		mainInitSuccess: (state: InitState, action: PayloadAction<MainInitState>) => {
			state.mainInitLoading = false;
			state.loading = false;
			state.main = action.payload;
			state.error = null;
			state.accountOpening = null;
		},
		accountOpeningInitFailure: (state: InitState, action: PayloadAction<string>) => {
			state.loading = false;
			state.accountInitLoading = false;
			state.accountOpening = null;
			state.error = action.payload;
		},
		accountOpeningInitSuccess: (state: InitState) => {
			state.loading = false;
			state.accountInitLoading = false;
			// state.accountOpening = action.payload;
			state.error = null;
			state.main = null;
			// state.account = null;
		},
		updateAccountOpeningProgress: (state: InitState, action: PayloadAction<AccountOpeningProgress>) => {
			if (state.accountOpening) {
				state.accountOpening.progress = AccountOpeningProgress.create({...action.payload});
			}
		},
		mainInitCategoriesUpdate: (state: InitState, action: PayloadAction<TransactionCategory>) => {
			if (state.main) {
				state.main.companyDetails.transactionCategories.push(action.payload);
			}
		},
		mainInitAllCardsUpdate: (state: InitState, action: PayloadAction<Card[]>) => {
			if (state.main) {
				state.main.companyDetails.cards = action.payload;
			}
		},
		mainInitUserGroupsUpdate: (state: InitState, action) => {
			if (state.main) {
				state.main.companyDetails.userGroups.push(action.payload);
			}
		},
		mainUpdateUserAuthApp: (state: InitState, action: PayloadAction<UserApp>) => {
			// get the user from state
			const user: User | null = state.main?.companyDetails?.user || null;
			if (user) {
				// set the new data
				user.authApp = action.payload;
			}
		},
		mainUpdateUserMobileApp: (state: InitState, action: PayloadAction<UserApp>) => {
			// get the user from state
			const user: User | null = state.main?.companyDetails?.user || null;
			if (user) {
				// set the new data
				user.mobileApp = action.payload;
			}
		},
		mainAddNewCard: (state: InitState, action: PayloadAction<UserApp>) => {
			// get the user from state
			const user: User | null = state.main?.companyDetails?.user || null;
			if (user) {
				// set the new data
				user.authApp = action.payload;
			}
		},
		mainInitAddCard: (state: InitState, action: PayloadAction<Card>) => {
			if (state.main) {
				state.main.companyDetails.cards.push(action.payload);
			}
		},
		mainInitUpdateCard: (state: InitState, action: PayloadAction<Card>) => {
			if (state.main) {
				const cardIndex = state.main.companyDetails.cards.findIndex((_cardId) => _cardId.id === action.payload.id);
				state.main.companyDetails.cards[cardIndex] = action.payload;
			}
		},

		initReset: (state: InitState) => {
			state.isLoggedIn = false;
			state.main = null;
			state.account = null;
			state.accountOpening = null;
			state.isAccountCreated = false;
			state.loading = false;
			state.error = null;
			state.selectedCompanyId = null;
			state.isSwitchingBusiness = false;
			state.canAccessCards = false;
			state.canAccessTerminals = false;
			state.globalActiveDataType = "";
		},
		setCanAccessCards: (state: InitState, action: PayloadAction<boolean>) => {
			state.canAccessCards = action.payload;
		},
		setCanAccessTerminals: (state: InitState, action: PayloadAction<boolean>) => {
			state.canAccessTerminals = action.payload;
		},
		setGlobalActiveDataType: (state: InitState, action: PayloadAction<string>) => {
			state.globalActiveDataType = action.payload;
		},
		setCompanyId: (state: InitState, action: PayloadAction<string>) => {
			state.selectedCompanyId = action.payload;
		},
		loginSuccess: (state: InitState) => {
			state.isLoggedIn = true;
		},
		mainUpdateUserAccount: (state: InitState, action: PayloadAction<UserAccount>) => {
			// replace UserAccount (action.payload) in state.main.companyDetails.accounts
			if (state.main) {
				const accountIndex = state.main.companyDetails.accounts.findIndex((_accountId) => _accountId.id === action.payload.id);
				state.main.companyDetails.accounts[accountIndex].activationStatus = action.payload.activationStatus;
			}
		},
		mainUpdateUserAccountCallback: (
			state: InitState,
			action: PayloadAction<{
				id: string;
				callback: (account: UserAccount) => void;
			}>
		) => {
			// get the userAccount from state
			const userAccount: UserAccount | undefined = state.main?.companyDetails?.accounts.find((a) => a.id === action.payload.id);
			if (userAccount) {
				action.payload.callback(userAccount);
			}
		},
		mainUpdateCompanyDetails: (
			state: InitState,
			action: PayloadAction<{
				callback: (companyDetails: MainInitCompanyDetailsInterface) => void;
			}>
		) => {
			// get the userAccountMeta from state
			if (state.main) {
				action.payload.callback(state.main.companyDetails);
			}
		},
		isSwitchingBusinessTrue: (state: InitState) => {
			state.isSwitchingBusiness = true;
		},
		isSwitchingBusinessFalse: (state: InitState) => {
			state.isSwitchingBusiness = false;
		},
		addNewCompany: (state: InitState, action: PayloadAction<CompanyInterface>) => {
			if (state.account) state.account.companies = [...state.account.companies, action.payload];
		},
	},
});

export const {
	setAccountCreated,
	initRequest,
	mainInitRequest,
	accountInitFailure,
	accountInitSuccess,
	mainInitFailure,
	mainInitSuccess,
	accountOpeningInitFailure,
	accountOpeningInitSuccess,
	initReset,
	setCompanyId,
	loginSuccess,
	setCanAccessCards,
	setCanAccessTerminals,
	mainInitCategoriesUpdate,
	mainInitAddCard,
	mainInitUpdateCard,
	mainInitAllCardsUpdate,
	mainInitUserGroupsUpdate,
	mainUpdateUserAuthApp,
	mainUpdateUserMobileApp,
	mainUpdateUserAccount,
	mainUpdateUserAccountCallback,
	mainUpdateCompanyDetails,
	updateAccountOpeningProgress,
	isSwitchingBusinessTrue,
	isSwitchingBusinessFalse,
	addNewCompany,
	setGlobalActiveDataType,
} = initSlice.actions;

export default initSlice.reducer;
