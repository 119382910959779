import {
	ActivateCardDTO,
	ActivateCardResponse,
	AddManageCardAccessRequest,
	AddManageCardAccessResponse,
	CardBalanceRequest,
	CardBalanceResponse,
	CardDetailsRequest,
	CardDetailsResponse,
	CardInformationRequest,
	CardInformationResponse,
	CardInitRequest,
	CardInitResponse,
	CardShowCVVRequest,
	CardShowCVVResponse,
	CardsGeneralSettignsResponse,
	CardsListRequest,
	CardsListResponse,
	CreateCardRequestDetailsRequest,
	CreateCardRequestDetailsResponse,
	DeactivateCardRequest,
	DeactivateCardResponse,
	FreezeCardResponse,
	GetCardPinRequest,
	GetCardPinResponse,
	ListCardsRequest,
	ListCardsResponse,
	ListCreateCardRequestRequest,
	ListCreateCardRequestResponse,
	ReassignCardDTO,
	ReassignCardRequestDetailsResponse,
	RemoveManageCardAccessRequest,
	RemoveManageCardAccessResponse,
	RemoveSpendingLimitRequest,
	RemoveSpendingLimitResponse,
	RequestNewCardRequest,
	RequestNewCardResponse,
	TeamMembersWithManageAccessResponse,
	UnFreezeCardResponse,
	UpdateCardPaymentChannelResponse,
	freezeCardDTO,
	udpateCardSpendingLimitDTO,
	unFreezeCardDTO,
	updateCardPaymentChannelDTO,
} from "./cardsApi.types";
import {abortRequest, getAbortControllerSignal} from "../../../../helpers/request/abortControllers";

import Card from "../../../../models/card";
import CardInformation from "../../../../models/cardInformation";
import CardMain from "../../../../models/cardMain";
import CardRequest from "../../../../models/cardRequest";
import {GenericObject} from "../../../../helpers/types";
import Parsers from "../../../../utils/parsers";
import User from "../../../../models/user";
import {makeRequestWithSignal} from "../../../../helpers/request/makeRequest";

export enum CardRequestType {
	INIT = "card.init",
	LIST = "card.list",
	TEAM_MEMBERS_WITH_MANAGE_ACCESS = "card.team-members-with-manage-access",
	REQUEST_NEW_CARD = "card.request-new-card",
	LIST_CREATE_CARD_REQUEST = "card.list-create-card-request",
	CREATE_CARD_REQUEST_DETAILS = "card.create-card-request-details",
	LIST_CARDS = "card.list-cards",
	CARD_DETAILS = "card.card-details",
	CARD_INFORMATION = "card.card-information",
	ACTIVATE_CARD = "card.activate-card",
	GET_CARD_PIN = "card.get-card-pin",
	DEACTIVATE_CARD = "card.deactivate-card",
	CARD_BALANCE = "card.card-balance",
	ADD_CREATE_CARD_ACCESS = "card.add-create-card-access",
	REMOVE_CREATE_CARD_ACCESS = "card.remove-create-card-access",
	REASSIGN_NEW_CARD = "card.reassign",
	CARD_SPENDING_LIMIT = "card.spending-limit",
	REMOVE_CARD_SPENDING_LIMIT = "card.remove-spending-limit",
	CARD_PAYMENT_CHANNEL = "card.payment-channel",
	CARD_FREEZE = "card.freeze",
	CARD_UNFREEZE = "card.unfreeze",
	CARD_GENERAL_SETTINGS = "card.general.settigns",
	CARD_SHOW_CVV = "card.show.cvv",
}

export const cardShowCVV = async (_data: CardShowCVVRequest): Promise<CardShowCVVResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_SHOW_CVV);
	const res = await makeRequestWithSignal("/main/cards/settings/general/ngn-physical/update", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		ngnPhysical: {
			showCvv: Parsers.boolean((res.ngnPhysical as GenericObject).showCvv),
		},
	};
};

export const cardsGeneralSettings = async (): Promise<CardsGeneralSettignsResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_GENERAL_SETTINGS);
	const res = await makeRequestWithSignal("/main/cards/settings/general/details", {}, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		ngnPhysical: {
			showCvv: Parsers.boolean((res.ngnPhysical as GenericObject).showCvv),
		},
	};
};

export const cardsList = async (_data: CardsListRequest): Promise<CardsListResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.INIT);
	const res = await makeRequestWithSignal("/main/cards/list", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		groupSize: Parsers.number(res.groupSize),
		total: Parsers.number(res.total),
		cardsData: Parsers.classObjectArray(res.cardsData, CardMain),
	};
};

export const teamMembersWithManageAccess = async (): Promise<TeamMembersWithManageAccessResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.TEAM_MEMBERS_WITH_MANAGE_ACCESS);
	const res = await makeRequestWithSignal("/main/cards/settings/manage-card-access/details", {}, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		teamMembersWithManageAccess: Parsers.classObjectArray(res.teamMembersWithManageAccess, User),
	};
};

export const cardInit = async (_data: CardInitRequest): Promise<CardInitResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.INIT);
	const res = await makeRequestWithSignal("/main/cards/init", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cards: {
			cards: Parsers.classObjectArray((res.cards as GenericObject).cards, Card),
			groupSize: Parsers.number((res.cards as GenericObject).groupSize),
			total: Parsers.number((res.cards as GenericObject).total),
		},
		createCardRequests: {
			createCardRequests: Parsers.classObjectArray((res.createCardRequests as GenericObject).createCardRequests, CardRequest),
			groupSize: Parsers.number((res.createCardRequests as GenericObject).groupSize),
			total: Parsers.number((res.createCardRequests as GenericObject).total),
		},
		meta: {teamMembersWithCreateAccess: Parsers.classObjectArray((res.meta as GenericObject).teamMembersWithCreateAccess, User)},
	};
};

export const requestNewCard = async (_data: RequestNewCardRequest): Promise<RequestNewCardResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.REQUEST_NEW_CARD);
	const res = await makeRequestWithSignal("/main/cards/create", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const listCreateCardRequest = async (_data: ListCreateCardRequestRequest): Promise<ListCreateCardRequestResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.LIST_CREATE_CARD_REQUEST);
	const res = await makeRequestWithSignal("/main/cards/create-requests/list", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		createCardRequests: Parsers.classObjectArray(res.createCardRequests, CardRequest),
		groupSize: Parsers.number(res.groupSize),
		total: Parsers.number(res.total),
	};
};

export const createCardRequestDetails = async (_data: CreateCardRequestDetailsRequest): Promise<CreateCardRequestDetailsResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CREATE_CARD_REQUEST_DETAILS);
	const res = await makeRequestWithSignal("/main/cards/create-requests/details", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		createCardRequest: Parsers.classObjectNonNullable(res.createCardRequests, CardRequest),
	};
};

export const unFreezeCard = async (_data: unFreezeCardDTO): Promise<UnFreezeCardResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_UNFREEZE);
	const res = await makeRequestWithSignal("/main/cards/unfreeze", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const freezeCard = async (_data: freezeCardDTO): Promise<FreezeCardResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_FREEZE);
	const res = await makeRequestWithSignal("/main/cards/freeze", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const updateCardPaymentChannel = async (_data: updateCardPaymentChannelDTO): Promise<UpdateCardPaymentChannelResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_PAYMENT_CHANNEL);
	const res = await makeRequestWithSignal("/main/cards/channels/update", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const reassignCardRequest = async (_data: ReassignCardDTO): Promise<ReassignCardRequestDetailsResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.REASSIGN_NEW_CARD);
	const res = await makeRequestWithSignal("/main/cards/reassign", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const updateSpendingLimit = async (_data: udpateCardSpendingLimitDTO): Promise<ActivateCardResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_SPENDING_LIMIT);
	const res = await makeRequestWithSignal("/main/cards/spend-limit/update", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const removeSpendingLimit = async (_data: RemoveSpendingLimitRequest): Promise<RemoveSpendingLimitResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.REMOVE_CARD_SPENDING_LIMIT);
	const res = await makeRequestWithSignal("/main/cards/spend-limit/remove", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const listCards = async (_data: ListCardsRequest): Promise<ListCardsResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.LIST_CARDS);
	const res = await makeRequestWithSignal("/main/cards/list", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cards: Parsers.classObjectArray(res.cards, Card),
		groupSize: Parsers.number(res.groupSize),
		total: Parsers.number(res.total),
	};
};

export const cardDetails = async (_data: CardDetailsRequest): Promise<CardDetailsResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_DETAILS);
	const res = await makeRequestWithSignal("/main/cards/details", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		card: Parsers.classObjectNonNullable(res.card, Card),
	};
};

export const cardBalance = async (_data: CardBalanceRequest): Promise<CardBalanceResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_BALANCE);
	const res = await makeRequestWithSignal("/main/cards/balance", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		balance: Parsers.number(res.balance),
	};
};

export const cardInformation = async (_data: CardInformationRequest): Promise<CardInformationResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.CARD_INFORMATION);
	const res = await makeRequestWithSignal("/main/cards/information", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		info: Parsers.classObjectNonNullable(res.info, CardInformation),
	};
};

export const activateCard = async (_data: ActivateCardDTO): Promise<ActivateCardResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.ACTIVATE_CARD);
	const res = await makeRequestWithSignal("/main/cards/activate", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const deactivateCard = async (_data: DeactivateCardRequest): Promise<DeactivateCardResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.DEACTIVATE_CARD);
	const res = await makeRequestWithSignal("/main/cards/deactivate", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		cardData: Parsers.classObjectNonNullable(res.cardData, CardMain),
	};
};

export const getCardPin = async (_data: GetCardPinRequest): Promise<GetCardPinResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.GET_CARD_PIN);
	const res = await makeRequestWithSignal("/main/cards/default-pin", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		defaultPin: Parsers.string(res.defaultPin),
	};
};

export const addManageCardAccess = async (_data: AddManageCardAccessRequest): Promise<AddManageCardAccessResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.ADD_CREATE_CARD_ACCESS);
	const res = await makeRequestWithSignal("/main/cards/settings/manage-card-access/add", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		teamMembersWithManageAccess: Parsers.classObjectArray(res.teamMembersWithManageAccess, User),
	};
};

export const removeManageCardAccess = async (_data: RemoveManageCardAccessRequest): Promise<RemoveManageCardAccessResponse> => {
	const signal = getAbortControllerSignal(CardRequestType.REMOVE_CREATE_CARD_ACCESS);
	const res = await makeRequestWithSignal("/main/cards/settings/manage-card-access/remove", _data, signal);
	if (res instanceof Error) {
		throw res;
	}
	return {
		success: Parsers.boolean(res.success),
		message: Parsers.string(res.message),
		teamMembersWithManageAccess: Parsers.classObjectArray(res.teamMembersWithManageAccess, User),
	};
};

export type createSubAccountRequest = {
	name: string;
	description: string;
};

export function abortCardRequest(type: CardRequestType): void {
	abortRequest(type);
}

export function abortAllCardRequests(): void {
	Object.values(CardRequestType).forEach((type) => abortRequest(type));
}
