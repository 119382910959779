import {PaymentStageType, PaymentType} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice.types";
import {setPaymentStage, setPaymentType} from "../../../../../redux/payments/sendMoney/slice/sendMoneySlice";
import {useDispatch, useSelector} from "react-redux";

import {ReactComponent as BulkTransferIcon} from "../../../../../assets/svg/DashboardLayout/SendMoneyDropdown/BulkTransferIcon.svg";
import {ReactComponent as CardIcon} from "../../../../../assets/svg/Cards/card-receive.svg";
import {IRootState} from "../../../../../redux/rootReducer";
import React from "react";
import SectionCard from "../Cards/SectionCard";
import {ReactComponent as SingleTransferIcon} from "../../../../../assets/svg/DashboardLayout/SendMoneyDropdown/SingleTransferIcon.svg";
import {ReactComponent as TransferBtwAccountsIcon} from "../../../../../assets/svg/DashboardLayout/SendMoneyDropdown/TransferBtwAccountsIcon.svg";

function TypeOfTransfer(): JSX.Element {
	const dispatch = useDispatch();
	const main = useSelector((state: IRootState) => state.init.main);
	const isNigeria = useSelector((state: IRootState) => state.init.main?.companyDetails.company.isNigeria);

	return (
		<>
			<div className="w-full">
				<div className="mx-auto w-full max-w-sm lg:mx-0 ">
					<div className="flex w-full flex-col pb-4 pt-8 text-base">
						<span className="text-sm font-medium text-black-secondary md:text-base ">Choose a type of transfer</span>
						<span className="text-xs text-black-tertiary md:text-sm">Select a mode of transfer</span>
						{/* <span className="md:text-sm text-xs text-black-tertiary">Transfer money with one of the available modes</span> */}
					</div>

					<div className="w-full">
						<div className="flex w-full flex-col justify-center space-y-2 py-2 text-base font-normal sm:justify-start  md:justify-start md:px-0 lg:justify-start   lg:px-0 xl:justify-start 2xl:justify-start ">
							<div className="flex w-full flex-col">
								<div className="w-full space-y-6 ">
									<SectionCard
										text="Make a single transfer"
										icon={
											<>
												<SingleTransferIcon className="stroke-current" />
											</>
										}
										func={() => {
											dispatch(setPaymentType(PaymentType.SINGLE_TRANSFER));
											dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
										}}
									/>
									{main && main.companyDetails.accounts.length > 1 && (
										<SectionCard
											text="Transfer between accounts"
											icon={
												<>
													<TransferBtwAccountsIcon className="stroke-current" />
												</>
											}
											func={() => {
												dispatch(setPaymentType(PaymentType.TRANSFER_BETWEEN_ACCOUNT));
												dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
											}}
										/>
									)}
									{isNigeria && (
										<SectionCard
											text="Make a bulk transfer"
											icon={
												<>
													<BulkTransferIcon className="stroke-current" />
												</>
											}
											func={() => {
												dispatch(setPaymentType(PaymentType.BULK_TRANSFER));
												dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
											}}
										/>
									)}

									{isNigeria && (
										<SectionCard
											text="Fund Card"
											icon={
												<>
													<CardIcon className="stroke-current" />
												</>
											}
											func={() => {
												dispatch(setPaymentType(PaymentType.FUND_CARDS));
												dispatch(setPaymentStage(PaymentStageType.PAYMENT_DETAILS));
											}}
										/>
									)}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default TypeOfTransfer;
